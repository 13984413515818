import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import * as global from '../../includes/global';
import { API_URL } from 'src/environments/api-url';

@Injectable({
  providedIn: 'root'
})
export class ESignService {
  private _currentUser;
  constructor(
    private httpclient: HttpClient,
    // private http: HttpClient,
    private _authService: AuthService,
    private toastr: ToastrService,
    private dashboardService: DashboardService) {
    this._currentUser = this._authService.currentUser;

  }

  getProviders() {
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
    };
    return this.httpclient.post(global.url + API_URL.ESIGN.getProviders, { details: details }).pipe();
    // return this.httpclient.post(global.rrgen + '/getAllDocuments', {details: details}).pipe();
  }
  getProvidersNames(term) {
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
      company_id: this._currentUser.company_id,
      term
    };
    return this.httpclient.post(global.url + API_URL.ESIGN.getProvidersNames, { details: details }).pipe();
    // return this.httpclient.post(global.rrgen + '/getAllDocuments', {details: details}).pipe();
  }
  getAppointmentsOfPatient(payload) {
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
      company_id: this._currentUser.company_id,
      payload
    };
    return this.httpclient.post(global.url + API_URL.ESIGN.getAppointmentsOfPatient, { details: details }).pipe();
    // return this.httpclient.post(global.rrgen + '/getAllDocuments', {details: details}).pipe();
  }
  getSignatures(){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
    };
    return this.httpclient.post(global.url + API_URL.ESIGN.getSignatures, { details: details }).pipe();
  }

  deleteSignature(signatureId){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
      signatureId: signatureId
    };
    return this.httpclient.post(global.url + API_URL.ESIGN.deleteSignature, { details: details }).pipe();
  }

  uploadFiletoSign(provider, files) {  
    return new Promise((resolve, reject) => {
      const formData: any = new FormData();
      const xhr = new XMLHttpRequest();
      formData.append('id', this._currentUser.id);
      formData.append('auth_key', this._currentUser.auth_key);
      formData.append('usertype', this._currentUser.user_type);
      formData.append('provider', provider);

      const counts:any = {};

      for (let i = 0; i < files.length; i++) {
        const {type, sign} = files[i].fileData;
        const signUnsign = sign ? 'sign' : 'unsign';

        if(!counts[signUnsign]) counts[signUnsign] = 1;
               
        formData.append(`${signUnsign}${counts[signUnsign]}`, files[i].file);
        counts[signUnsign]++;
      }
      // formData.append('file', file);
      // formData.append('formType', formType)
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('POST', global.url + API_URL.ESIGN.uploadFiletoSign, true);
      xhr.send(formData);
    });

  }

  saveSignature(provider, file) { 
    return new Promise((resolve, reject) => {
      const formData: any = new FormData();
      const xhr = new XMLHttpRequest();
      formData.append('id', this._currentUser.id);
      formData.append('auth_key', this._currentUser.auth_key);
      formData.append('usertype', this._currentUser.user_type);
      formData.append('provider', provider);
      formData.append('file', file); 
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('POST', global.url + API_URL.ESIGN.saveSignature, true);
      xhr.send(formData);
    });
  }
}
