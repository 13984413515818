import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpErrorResponse
} from '@angular/common/http';
import * as global from '../global';
import {AuthService} from 'src/app/services/auth.service';
import {API_URL} from "../../../environments/api-url";
import _ from 'lodash';
import { CommonService } from 'src/app/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class TranscriptionCreateService {
  data: any;

  public constructor(
      private httpclient: HttpClient,
      private _authService: AuthService,
      private _commonService: CommonService
  ) {
    this.data = this._authService.currentUser;
  }

  create(transcribt, category) {
    return this.httpclient
        .post(global.url + API_URL.NOTE.addTranscription, {transcribtion: transcribt}).pipe();
  }

  updateExisting(transcribt, category) {
    const details = {
      ...this._authService.authObject,

      ...transcribt
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.updateTranscriptionText, {transcribtion: details}).pipe();
  }

  signTranscription(transcribt, category) {
    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_signed: transcribt.is_signed
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.updateTranscriptionStatus, {transcribtion: details}).pipe();
  }
  signAndUploadTranscription(transcribt, category) {
    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_signed: transcribt.is_signed,
      is_completed: transcribt.is_completed,
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.updateTranscriptionStatus, {transcribtion: details}).pipe();
  }

  unSignTranscription(transcribt, category) {
    transcribt.is_signed = 'false';

    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_signed: transcribt.is_signed
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.updateTranscriptionStatus, {transcribtion: details}).pipe();
  }

  completeTranscription(transcribt) {

    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_completed: transcribt.is_completed
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.updateTranscriptionStatus, {transcribtion: details}).pipe();
  }

  publishTranscription(transcribt, category) {
    
    const details = {
      ...this._authService.authObject,

      ...transcribt
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.updateTranscriptionStatus, {transcribtion: details}).pipe();

  }

  /*
  * Publishing Transcription in database directly #mohsin-dev
  */
  unpublishTranscription(transcribt, category) {
    console.log('Unpublishing transcription', category, transcribt);
    transcribt.is_published = 'false';
    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_published: transcribt.is_published
    };
    return this.httpclient.post(global.url + '/updateTranscriptionStatus', {transcribtion: details}).pipe();
  }


  deleteTranscription(transcribt, category) {
    transcribt.is_signed = 'false';

    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_active: transcribt.is_active,
      is_trashed: transcribt.is_trashed,
      note_active_status: transcribt.note_active_status
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.deleteTranscription, {transcribtion: details}).pipe();
  }

  restoreTranscription(transcribt) {
    // transcribt.is_signed = 'false';

    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_active: transcribt.is_active,
      is_trashed: transcribt.is_trashed,
      rounding_sheet_id : transcribt.rounding_sheet_id,
      patient_id_ref : transcribt.patient_id_ref._id
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.restoreTranscription, {transcribtion: details}).pipe();
  }


  createAndUploadDocs(detail) {
    var user = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type
    }
    return this.httpclient.post(global.url + '/createAndUploadDocs', {details: detail, user: user}).pipe();
  }

  getDocFileContent(path) {
    var user = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type
    }
    return this.httpclient.post(global.url + '/getDocFileContent', {path: path, user: user}).pipe();
  }

  getDoctorsList() {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    return this.httpclient
        .post(global.url + API_URL.USER.getDoctorsList, {details: item}).pipe();
  }

  getAllProviders() {
    let item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    return this.httpclient.post(global.url + API_URL.USER.getAllProviders, {details: item});
  }

  getAssociatedDoctorsList(company = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      company_id: <string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    item.company_id = this.data.company_id
    return this.httpclient
        .post(global.url + API_URL.USER.getAssociatedDoctorsList, {details: item}).pipe();
  }
  getRelations(doctor_id,company = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      company_id: <string>null,
      doctor_id:<string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    item.company_id = this.data.company_id;
    item.doctor_id=doctor_id;
    return this.httpclient
        .post(global.url + API_URL.USER.getDoctorRelations, {details: item}).pipe();
  }
  getAssociatedDoctorsListForMA(company = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      company_id: <string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    item.company_id = this.data.company_id;
    console.log('item in service',item);
    return this.httpclient
        .post(global.url + API_URL.USER.getAssociatedDoctorsListForMA, {details: item}).pipe();
  }

  getAssociatedDoctorsListForWoundNurse(wound_nurse_id, company_id = null) {
    var item : any = {};
    item.id = this.data._id;
    item.wound_nurse_id = wound_nurse_id,
    item.company_id = company_id;
    return this.httpclient.post(global.url + API_URL.USER.getAssociatedDoctorsListForWoundNurse, {details: item}).pipe();
  }

  getAssociatedDoctorsListForBO(id, company_id, usertype, company = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      company_id: <string>null
    };
    item.id = id;
    item.company_id = company_id;
    item.usertype = usertype;
    console.log('item',item);
    return this.httpclient
        .post(global.url + API_URL.USER.getAssociatedDoctorsListForBO, {details: item}).pipe();
  }

  getPatients(item) {
    return this.httpclient
        .post(global.url + API_URL.PATIENT.getPatients, {patient: item}).pipe();
  }

  getPatient(item) {
    return this.httpclient
        .post(global.url + API_URL.PATIENT.getPatient, {patient: item}).pipe();
  }

  /*
  * Used to initialize facility select input #mohsin-dev
  */
  getFacilities( doctor_id = null, source = null , company_id = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      doctor_id: <string>null,
      source: <string>null,
      company_id: <string>null
    };

    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    item.doctor_id = doctor_id;
    item.source = source;
    if(company_id) {
      item.company_id = company_id;
    } else {
      item.company_id = this.data.company_id;
    }

    return this.httpclient
      .post( global.url + API_URL.FACILITY.getMyAddress, { address: item }).pipe();
  }

  addendumNote(data) {

    const details = {
      ...this._authService.authObject,

      ...data
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.addendumNote, {details}).pipe();
  }

  getNoteAddendums(data) {

    const details = {
      ...this._authService.authObject,

      ...data
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.getNoteAddendums, {details}).pipe();
  }
  previewNoteAsPCC(data) {

    const details = {
      ...this._authService.authObject,

      ...data
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.previewNoteAsPCC, {details}).pipe();
  }

  deleteDraftedCharge(chargeId,noteId) {
    const details = {
      chargeId,
      noteId
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.deleteDraftedCharge, {details}).pipe();
    
  }

  getFacilitiesByCompanyID(id){
    return this.httpclient.post(global.url + API_URL.FACILITY.getFacilitiesByCompanyID, {id}).pipe();
  }

  getAssocProviders(id){
    return this.httpclient.post(global.url + API_URL.USER.getAssocProviders, {id}).pipe()
  }
  getFiveRecentNotes(filter){
    const clonedData = _.cloneDeep(filter);
    if(filter.creationDate){
        clonedData.creationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.startDate.startOf('day'), true);
        clonedData.creationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.endDate.endOf('day'), true);
    }
    return this.httpclient.post(global.url + API_URL.NOTE.getFiveRecentNotes, { filter: clonedData });
  }
}

