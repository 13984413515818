import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";
import * as global from '../../includes/global';
import { DashboardService } from '../dashboard/dashboard.service';
import * as _ from 'lodash';
import moment from 'moment'
import { CommonService } from 'src/app/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class BillerDashboardService {
  currentUser: any;
  company_id: any;
  constructor(
    private httpclient: HttpClient,
    private _router: Router,
    private _authService: AuthService,
    private _commonService: CommonService,
    private dashboardService: DashboardService) {
    this.currentUser = this._authService.currentUser;

  }
  getProvidersCharges() {
    
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null
    };
    item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.BILLER.getProvidersCharges, { details: item }).pipe();
  }
  getChargeAudit(charge_id) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      charge_id: null
    };
    item.id = this.currentUser._id, 
    item.auth_key = this.currentUser.auth_key, 
    item.usertype = this.currentUser.user_type;
    item.charge_id = charge_id;
    return this.httpclient.post(global.url + API_URL.CHARGE.getChargeAudit, { details: item }).pipe();
  }

  getMissingFacesheets(page, doctor: any = null, companyId = null, filter = {}, charge_id = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      doctorId: doctor,
      companyId : companyId,
      filter : filter,
      page : page
    };
    item.id = this.currentUser._id, 
    item.auth_key = this.currentUser.auth_key, 
    item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.BILLER.getMissingFacesheets, { details: item }).pipe();
  }

  getAddendumReqCount(filter) {
    return this.httpclient.post(global.url + API_URL.BILLER.getAddendumReqCount, { filter }).pipe();
  }

  getPatientsCharges(page, doctor: any = null, companyId = null, filter = {}, charge_id = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      doctorId: doctor,
      filter: filter,
      companyId: companyId,
      charge_id: charge_id,
      page: page
    };
    
    item.id = this.currentUser._id,
      item.auth_key = this.currentUser.auth_key,
      item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.BILLER.getPatientsCharges, { details: item }).pipe();
  }

  getAllPatientsCharges(page, doctor: any = null, companyId = null, filter = {}, charge_id = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      doctorId: doctor,
      filter: filter,
      companyId: companyId,
      charge_id: charge_id,
      page: page
    };
    console.log('item',item);
    
    item.id = this.currentUser._id,
      item.auth_key = this.currentUser.auth_key,
      item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.BILLER.getAllPatientsCharges, { details: item }).pipe();
  }

  getPatientsChargesAdminCharges(page,doctorId, filter = {}) {
    this._commonService.castCompany.subscribe(company => {
      this.company_id = company?._id
    })
    if(!this.company_id){
      this.company_id=this.currentUser.company_id;
    }
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      biller_id: null,
      filter: filter,
      companyId: this.company_id,
      doctorId: doctorId,
      page: page
    };
    if(this.currentUser.user_type=='6'){
      item.biller_id = this.currentUser._id;
    }
    console.log('item',item);
    item.id = this.currentUser._id,
      item.auth_key = this.currentUser.auth_key,
      item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.BILLER.getPatientsChargesAdminCharges, { details: item }).pipe();
  }
  getPatientsChargesAdmin(page, doctor: any = null, companyId = null, filter = {}, charge_id = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      doctorId: doctor,
      filter: filter,
      companyId: companyId,
      charge_id: charge_id,
      page: page
    };
    console.log('item',item);
    
    item.id = this.currentUser._id,
      item.auth_key = this.currentUser.auth_key,
      item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.BILLER.getPatientsChargesAdmin, { details: item }).pipe();
  }

  getBillingComapnyAssociation(providerID: any = null, facilityID = null, screen = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      companyId: <string>null,
      providerID: providerID,
      facilityID: facilityID,
      screen: screen
    };
    
    if(providerID == '' || providerID == null) {
      item.providerID = this.currentUser._id;
    }
    console.log("Current USer: ", this.currentUser)
    item.id = this.currentUser._id,
      item.auth_key = this.currentUser.auth_key,
      item.usertype = this.currentUser.user_type;
      item.companyId = this.currentUser.company_id;
    return this.httpclient.post(global.url + API_URL.BILLING.getBillingComapnyAssociation, { details: item }).pipe();
  }
  getEditChargeDuration() {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      company_id: <string>null, 
      screen: screen
    };  
    item.id = this.currentUser._id,
    item.auth_key = this.currentUser.auth_key,
    item.usertype = this.currentUser.user_type;
    item.company_id = this.currentUser.company_id;
    return this.httpclient.post(global.url + API_URL.COMPANY.getChargeSetting, { data: item }).pipe();
  }

  updatePatientCharges(patientCharges, checked) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      chargeId: patientCharges,
      status: checked
    };
    item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.BILLER.updatePatientCharges, { details: item }).pipe();
  }

  updateCharge(chargeId, updateQuery) {
    return this.httpclient.post(global.url + API_URL.CHARGE.updateCharge, { chargeId, updateQuery  });
  }

  sendDataToBillingCompany(chargeID, billingCompany) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      chargeId: chargeID,
      billingCompany: billingCompany
    };
    item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.CHARGE.sendDataToBillingCompany, { details: item }).pipe();
  }
  deleteChargeClicked(chargeID,) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      chargeID: chargeID,
    };
    item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.BILLER.deleteChargeClicked, { details: item }).pipe();
  }

  getFacilities(doctor: any = null, companyId = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      provider: doctor,
      companyId: companyId
    };
    item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.BILLER.getFacilities, { details: item }).pipe();
  }
  getFacilitiesForProviders( doctor_id = null, source = null , company_id = null) {
    let item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      doctor_id: <string>null,
      source: <string>null,
      company_id: <string>null
    };
    item.doctor_id = doctor_id;
    item.source = source;
    item.company_id = company_id;

    return this.httpclient
      .post( global.url + API_URL.FACILITY.getMyAddress, { address: item }).pipe();
  }
  getCount(doctor: any, filter = {}) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      provider: doctor,
      filter: filter
    };
    item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.BILLER.getCount, { details: item }).pipe();
  }
  getDraftedChargeCount(doctor: any, filter = {}) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      provider: doctor,
      filter: filter
    };
    item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.BILLER.getDraftedChargeCount, { details: item }).pipe();
  }

  generatePDFAndDownload(chargeReport) {
    var details = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      report: chargeReport.toString()
    };

    details.id = this.currentUser._id, details.auth_key = this.currentUser.auth_key, details.usertype = this.currentUser.user_type;
    return this.dashboardService.downloadFile(global.url + API_URL.BILLER.generatePDFAndDownload, details).pipe();
  }

  downlaodCSV(csvData) { 
    const filename = "Summary of Charge Capture"
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  downloadJSONFile(data){
    return this.httpclient.post(global.url + API_URL.BILLER.downloadJSONFile, { details: data }).pipe();
  }

  toggleApproveCharge(charge_id, is_approved) {
    
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      charge_id: <string>null,
      is_approved: <string>null,
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    item.charge_id = charge_id;
    item.is_approved = is_approved;
    return this.httpclient.post(global.url + API_URL.CHARGE.toggleApproveCharge, { details: item }).pipe();
  }
  submitAndApproveCharge(charge_id, status = null, resubmitted_by = null) {
    
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      charge_id: <string>null, 
      status: <string>null, 
      resubmitted_by: <string>null, 
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    item.charge_id = charge_id; 
    item.status = status; 
    item.resubmitted_by = resubmitted_by; 
    return this.httpclient.post(global.url + API_URL.CHARGE.submitAndApproveCharge, { details: item }).pipe();
  }
  deleteAll(charge_ids) {
    var item = {
      charge_ids: [],
    };
    item.charge_ids = charge_ids;
    return this.httpclient.post(global.url + API_URL.CHARGE.deleteAll, { details: item }).pipe();
  }
  approveAll(charge_ids, is_approved) {
    
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      charge_ids: [],
      is_approved: <string>null,
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    item.charge_ids = charge_ids;
    item.is_approved = is_approved;
    return this.httpclient.post(global.url + API_URL.CHARGE.approveAll, { details: item }).pipe();
  }
  SubmitAllandApprove(charge_ids, status, billStatus?) {
    
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      charge_ids: [],
      status: <string>null,
      billStatus: <string>null,
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    item.charge_ids = charge_ids;
    item.status = status;
    if (billStatus || billStatus === "") {
      item.billStatus = billStatus;
    }
    return this.httpclient.post(global.url + API_URL.CHARGE.SubmitAllandApprove, { details: item }).pipe();
  }
  getModifiers() {
    
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.CHARGE.getModifiers, { details: item }).pipe();
  }

  getChargesByFilters(data) {
    const clonedData = _.cloneDeep(data);
    console.log("dataaa clonedData", data.date_of_service.startDate, moment(data.date_of_service.startDate), moment(data.date_of_service.startDate).toObject());
    clonedData.date_of_service.startDate = this._commonService.convertDateToStringforMoment(moment(data.date_of_service.startDate).startOf('day'), true, true);
    clonedData.date_of_service.endDate = this._commonService.convertDateToStringforMoment(moment(data.date_of_service.endDate).endOf('day'), true, true);
    // clonedData.date_of_service.startDate = this._commonService.convertDateToString(clonedData.date_of_service.startDate);
    // clonedData.date_of_service.endDate = this._commonService.convertDateToString(clonedData.date_of_service.endDate);
    const item: any = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      ...clonedData
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.CHARGE.getChargesByFilters, { details: item }).pipe();
  }
  getFlatChargesByFilters(data) {
    const clonedData = _.cloneDeep(data);
    console.log("dataaa clonedData", data,clonedData);
    clonedData.date_of_service.startDate = this._commonService.convertDateToStringforMoment(moment(clonedData.date_of_service.startDate).startOf('day'), true);
    clonedData.date_of_service.endDate = this._commonService.convertDateToStringforMoment(moment(clonedData.date_of_service.endDate).endOf('day'), true);
    const item: any = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      ...clonedData
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.CHARGE.getFlatChargesByFilters, { details: item }).pipe();
  }
  getChargesByFilters_v1(data) {
    const clonedData = _.cloneDeep(data);
    console.log("dataaa clonedData", data,clonedData);
    clonedData.date_of_service.startDate = this._commonService.convertDateToStringforMoment(moment(clonedData.date_of_service.startDate).startOf('day'), true);
    clonedData.date_of_service.endDate = this._commonService.convertDateToStringforMoment(moment(clonedData.date_of_service.endDate).endOf('day'), true);
    // clonedData.date_of_service.startDate = this._commonService.convertDateToString(clonedData.date_of_service.startDate);
    // clonedData.date_of_service.endDate = this._commonService.convertDateToString(clonedData.date_of_service.endDate);
    const item: any = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      ...clonedData
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.CHARGE.getChargesByFilters_v1, { details: item }).pipe();
  }
  getChargeGroupSeparate(charge_id, company_id) {
   console.log("dataaa charge_id in groupedData", charge_id);

    const item: any = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      charge_id,
      company_id
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.CHARGE.getChargeGroupSeparate, { details: item }).pipe();
  }
  
  getChargesByTimePeriodGap(company_id, facility_id) {
    let item: any = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      company_id,
      facility_id
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.CHARGE.getChargesByTimePeriodGap, { details: item }).pipe();
  }
  exportGroupedReportAsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.BILLER.exportGroupedReportAsXLSX, data,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportGroupedReportAsXLSX_DOC(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.BILLER.exportGroupedReportAsXLSX_DOC, data,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportGroupedReportAsXLSX_DOU(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.BILLER.exportGroupedReportAsXLSX_DOU, data,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportFullReportAsXLSX(data, company, shouldSendViaEmail = false, columnsData = []) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    let obj = {
      data: data,
      company: company,
      shouldSendViaEmail: shouldSendViaEmail
    }
    if(columnsData.length > 0) { 
      obj['columnsData'] = columnsData;
    }
    return this.httpclient.post(global.url + API_URL.BILLER.exportFullReportAsXLSX, obj,
      {
        reportProgress: true,
        observe: 'events',
        headers
      }
      );
  }
  generateBatchReportofFacesheets(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    return this.httpclient.post(global.url + API_URL.CHARGE.generateBatchReportofFacesheets, { details: data }, {
      responseType: "blob",
      reportProgress: true,
      observe: 'events',
      headers
  });
  }
}
