<div class="flex items-center justify-center">
    <h1 mat-dialog-title style="color: black">Add New IME Vendor </h1>
</div>
<div mat-dialog-content>
    <form (ngSubmit)="addPayor(form.value)" #form="ngForm">
        <div class="flex flex-col">
            <mat-form-field>
                <input matInput type="text" name="title"
                       #title="ngModel"
                       [(ngModel)]="model.title"
                       placeholder="Title"
                       required>
                <mat-error *ngIf="title.invalid  && (title.dirty || title.touched)">
                    Please enter title
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="text" name="description"
                       #description="ngModel"
                       [(ngModel)]="model.description"
                       placeholder="Description"
                       required>
                <mat-error *ngIf="description.invalid  && (description.dirty || description.touched)">
                    Please enter description
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="text" name="abbreviation"
                       #abbreviation="ngModel"
                       [(ngModel)]="model.abbreviation"
                       placeholder="Abbreviation"
                       required>
                <mat-error *ngIf="abbreviation.invalid  && (abbreviation.dirty || abbreviation.touched)">
                    Please enter abbreviation
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="email" name="email"
                       #email="ngModel"
                       [(ngModel)]="model.email"
                       placeholder="Email"
                       required>
                <mat-error *ngIf="email.invalid  && (email.dirty || email.touched)">
                    Please enter Email
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="text" name="address"
                       #address="ngModel"
                       [(ngModel)]="model.address"
                       placeholder="Address"
                       required>
                <mat-error *ngIf="address.invalid  && (address.dirty || address.touched)">
                    Please enter Address
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center justify-center gap-2">
            <button mat-raised-button
                    class="dn-button-secondary"
                    mat-dialog-close
                    type="button">Cancel</button>
            <button mat-raised-button
                    [disabled]="!form.form.valid"
                    type="submit"                   
                    class="dn-button-primary">{{model._id? 'Update' : 'Save'}}</button>
        </div>
    </form>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
