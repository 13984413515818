export const PATIENT_DOCUMENT_TYPE = {
    faceSheet: 'Face Sheet',
    consent: 'Consent',
    referralOrder: 'Referral Orders',
    diagnosticReport: 'Diagnostic Reports',
    sku: 'Skin Substitute SKUs',
    authorization: 'Authorizations',
    other: 'Other/Misc'
} as const;

export type PatientDocumentType = keyof typeof PATIENT_DOCUMENT_TYPE;
