import { I } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { PCCService } from 'src/app/services/pcc.service';
import { FileExplorerService } from '../../drive/file-explorer/file-explorer.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { API_URL } from '../../../../environments/api-url';
import * as global from '../../global';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-pdf-viewer-pop',
  templateUrl: './pdf-viewer-pop.component.html',
  styleUrls: ['./pdf-viewer-pop.component.css']
})
export class PdfViewerPopComponent implements OnInit {

  file_path: any;
  file: any;
  patient: any;
  loaderId = 'facesheet-loader';
  patientCoverages;
  secondaryIns = '';
  showData: boolean = false;
  isDNPatient: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PdfViewerPopComponent,
    public _authService: AuthService,
    private pccService: PCCService,
    private toastr: ToastrService,
    private fileExplorerService: FileExplorerService,
    private dashboardService: DashboardService,
    private loader: NgxUiLoaderService,
  ) {
  }

  async ngOnInit() {
    this.loadPDF()
    if (this.patient) {
      const patient_id = this.patient._id;
      if (this.patient.source === 'PointClickCare' || this.patient.source === 'MatrixCare' || this.patient.matrix_payload?.coverages?.length > 0) {
        this.isDNPatient = false
      } else {
        this.isDNPatient = true;
      };
      await this.syncCoveragesData(patient_id);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.file_path) {
      this.loadPDF();
    }
  }
  loadPDF(){
    if (this.data && this.data.file_path) {
      this.file_path = this.data.file_path
    }
    if (this.data && this.data.file) {
      this.file = this.data.file
      this.patient = this.file.patient_id
    }
  }
  async downloadFacesheeet() {
    if (this.data && this.data.file) {
      const charge = this.data.file;
      const patient = charge.patient_id;
      if (patient.source === 'PointClickCare') {
        let hasPCCFacesheetData = false;
        const details = {
          ...this._authService.authObject,
          patientId: patient._id,
        }
        const { status, data } = await lastValueFrom(this.pccService.syncCoveragesData({ details })) as any;
        if (status === 200) {
          const _patient = data;
          if ((_patient?.pcc_coverage_data?.Primary || _patient?.pcc_coverage_data?.Secondary || _patient?.pcc_coverage_data?.Tertiary)) {
            hasPCCFacesheetData = true
          }
        }
        if (hasPCCFacesheetData) {
          this.generateFacesheetAndDownloadAsPDF(patient._id);
          return;
        }
      }
      if (patient.source === 'MatrixCare' && patient.matrix_payload?.coverages?.length > 0) {
        this.generateFacesheetForMatrixAndDownloadAsPDF(patient._id);
        return;
      }
      const infoToast = this.toastr.info('Preparing file to download...', 'Please wait', { disableTimeOut: true });
      try {
        const downloadFileResponse: any = await this.fileExplorerService.downLoadAnyFileWithURL(this.data.file.faceSheet.aws_path, this.data.file.faceSheet.title, this.data.file.patient_id?._id).toPromise();
        var url = window.URL.createObjectURL(downloadFileResponse);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = <string>this.data.file.faceSheet.title;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      } catch (error) {
        this.toastr.error('Unable to download file.', 'Failed');
      } finally {
        this.toastr.clear(infoToast.toastId);
      }
    }
  }
  generateFacesheetAndDownloadAsPDF(patient_id) {
    const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    try {
      const params = {
        patient_id
      }
      return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetAndDownloadAsPDF, params).subscribe((res: any) => {
        if (res instanceof HttpResponse) {
          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'facesheet.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.toastr.clear(infoToastr.toastId);
        } else {
          if (res.type === 0) {
          } else if (res.type === 3) {
            const { loaded, total } = res;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
        }
        if (res.status == 200) {
          return true;
        }
      })
    } catch (error) {
      const { message = 'Something went wrong, please try again' } = error;
      this.toastr.error(message);
    } finally {
      this.toastr.clear(infoToastr.toastId);
    }
  }
  generateFacesheetForMatrixAndDownloadAsPDF(patient_id) {
    const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    try {
      const params = {
        source: 'MatrixCare',
        patient_id
      }
      return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetForMatrixAndDownloadAsPDF, params).subscribe((res: any) => {
        if (res instanceof HttpResponse) {
          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'facesheet.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.toastr.clear(infoToastr.toastId);
        } else {
          if (res.type === 0) {
          } else if (res.type === 3) {
            const { loaded, total } = res;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
        }
        if (res.status == 200) {
          return true;
        }
      })
    } catch (error) {
      const { message = 'Something went wrong, please try again' } = error;
      this.toastr.error(message);
    } finally {
      this.toastr.clear(infoToastr.toastId);
    }
  }
  async syncCoveragesData(patientId) {
    this.loader.startLoader(this.loaderId);
    const details = {
      ...this._authService.authObject,
      patientId,
    }
    const { status, data } = await lastValueFrom(this.pccService.syncCoveragesData({ details })) as any;
    if (status === 200) {
      this.patient = data;
      if (this.patient.source == 'MatrixCare') {
        this.patientCoverages = this.patient.matrix_payload.coverages
      }
      this.showData = true;
      this.loader.stopLoader(this.loaderId);
    }
  }
  generateFacesheetAndDownload(patient_id) {
    if (this.patient.source == 'MatrixCare') {
      this.generateFacesheetForMatrixAndDownloadAsPDF(patient_id)
    }
    else {
      this.generateFacesheetAndDownloadAsPDF(patient_id)
    }
  }
  formatInsuranceName(coverage_data, payerRank) {
    if (!coverage_data) {
      return 'N/A';
    }
    const payer = coverage_data[payerRank];
    if (payer) {
      return payer.payerName;
    }
    return 'N/A';
  }
  formatPolicyNumber(coverage_data, payerRank) {
    if (!coverage_data) {
      return 'N/A';
    }
    const payer = coverage_data[payerRank];
    if (payer?.issuer?.planNumber) {
      return payer.issuer.planNumber;
    }
    return 'N/A';
  }
  getPatientGender(gender) {
    if (gender == 'F' || gender == 'FEMALE') {
      return 'FEMALE'
    }
    else if (gender == 'M' || gender == 'MALE') {
      return 'MALE'
    }
    else {
      return 'UNKNOWN'
    }
  }
  getInsuranceCompany(type, field) {
    let obj;
    if (this.patientCoverages) {
      if (type == 'Primary') {
        for (let i = 0; i < this.patientCoverages.length; i++) {
          if (this.patientCoverages[i].insurance_type == 'primary') {
            obj = this.patientCoverages[i] ? this.patientCoverages[i] : null;
          }
          if (obj) break;
        }
        if (field == 'company') {
          return (obj && obj.insurance_company_name) ? obj.insurance_company_name : 'N/A'
        }
        else if (field == 'policy') {
          return (obj && obj.insurance_policy_number) ? obj.insurance_policy_number : 'N/A'
        }
      }
      else if (type == 'Secondary') {
        for (let i = 0; i < this.patientCoverages.length; i++) {
          if (this.patientCoverages[i].insurance_type != 'primary') {
            obj = this.patientCoverages[i] ? this.patientCoverages[i] : null;
          }
          if (obj) break;
        }
        if (field == 'company') {
          this.secondaryIns = (obj && obj.insurance_company_name) ? obj.insurance_company_name : 'N/A'
          console.log("this.secondaryIns: ", this.secondaryIns);
          return (obj && obj.insurance_company_name) ? obj.insurance_company_name : 'N/A'
        }
        else if (field == 'policy') {
          return (obj && obj.insurance_policy_number) ? obj.insurance_policy_number : 'N/A'
        }
      }
      else if (type == 'Tertiary') {
        for (let i = 0; i < this.patientCoverages.length; i++) {
          if (this.patientCoverages[i + 1] && this.patientCoverages[i + 1].insurance_company_name != this.secondaryIns) {
            if (this.patientCoverages[i + 1].insurance_type != 'primary') {
              obj = this.patientCoverages[i + 1] ? this.patientCoverages[i + 1] : null;
            }
            if (obj) break;
          }
          else {
            obj = null
          }
        }
        if (field == 'company') {
          return (obj && obj.insurance_company_name) ? obj.insurance_company_name : 'N/A'
        }
        else if (field == 'policy') {
          return (obj && obj.insurance_policy_number) ? obj.insurance_policy_number : 'N/A'
        }
      }
    }
  }
  ngOnDestroy(){
    this.file_path 
    this.file 
  }
}
