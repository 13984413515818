<main>
    <div [class.container]="!admin && !company">
        <mat-card>
            <mat-card-content class="flex flex-col">

                <div class="flex justify-between items-center gap-5">
                    <div class="flex flex-grow items-center gap-5">
                        <h3 style="width:105px">Patient Census</h3>

                        <mat-form-field *ngIf="!providerIdParam">
                            <mat-label>Providers</mat-label>
                            <mat-select name="provider" #provider="ngModel" [(ngModel)]="filter_provider_id" (ngModelChange)="handleproviderChange(provider)">
                                <mat-option *ngFor="let provider of providers" value="{{provider._id}}">
                                    {{provider.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="flex-grow" style="position: relative;">
                            <mat-form-field style="width: 100%;">
                                <mat-label>Facility</mat-label>
                                <mat-select name="facility" #facility="ngModel" [(ngModel)]="filter_facility_id" (ngModelChange)="handleFilterFacilityChange()">
                                    <input  placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                                    style="color:#000;height: 40px;padding-left: 5px;border: none; width: 100%;" (keydown)="$event.stopPropagation()" />
                                    <mat-option *ngFor="let facility of facilities | searchFacility: searchText" value="{{facility._id}}">
                                        <app-facility-title [facility]="facility"></app-facility-title>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <p style="font-size: 12px;color: red;position: absolute;bottom: -17px;" *ngIf="is2LeggedAuthRequired(filter_facility_id)">
                                This facility requires login into PCC to view data.
                            </p>
                        </div>
                        <ng-container *ngIf="isLoggedInFacility(selectedFacility)">
                            <button class="dn-button-primary" mat-raised-button (click)="initializeActiveCensus(selectedFacility._id)">
                  Initialize Active Census
                </button>
                            <button class="dn-button-primary" mat-raised-button [routerLink]="['/census/active/' + selectedFacility._id]">
                  View Active Census
                </button>
                        </ng-container>
                    </div>
                    <button mat-raised-button class="dn-button-primary" *ngIf="showCreateButton()" (click)="showPatientRoundingSheetDialog()">Create New Patient Census</button>
                    <mat-form-field *ngIf="(currentUser.user_type===global.USER_TYPE.DOCTOR || currentUser.user_type===global.USER_TYPE.SNF_WC_NURSE) && (currentUser.admin_type=='full_admin' || currentUser.admin_type=='system_admin')">
                        <mat-label>Status</mat-label>
                        <mat-select name="status" [(value)]="censusStatus" (selectionChange)="getCensusListByStatus($event.value)">
                            <mat-option value="true">
                                Active
                            </mat-option>
                            <mat-option value="false">
                                Deleted
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field style="width: 100px;" *ngIf="false"
                    >
                  <mat-label>Limit</mat-label>
                  <mat-select name="limit" [value]="showLimit.toString()" (selectionChange)="changeLimit($event.value)">
                    <mat-option value="5">
                      5
                    </mat-option>
                    <mat-option value="7">
                      7
                    </mat-option>
                    <mat-option value="10">
                      10
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                </div>
                <div style="position: relative;">

                    <mat-table class="table-row-hover" [dataSource]="dataSource" *ngIf="dataSource" matSort (matSortChange)="sortData($event)">
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let patientRoundingSheet">
                              <ng-container
                                *ngIf="patientRoundingSheet?.signedNotesCount > patientRoundingSheet?.submittedChargesCount">
                                <img width="10px" src="../../../../assets/img/red_dot.png" alt="">
                              </ng-container>
                              <ng-container
                                *ngIf="patientRoundingSheet?.submittedChargesCount === patientRoundingSheet?.signedNotesCount">
                                <img width="10px" src="../../../../assets/img/green_dot.png" alt="">
                              </ng-container>
                              <ng-container
                                *ngIf="patientRoundingSheet?.signedNotesCount &lt; patientRoundingSheet?.submittedChargesCount">
                                <img width="10px" src="../../../../assets/img/red_dot.png" alt="">
                              </ng-container>
                            </mat-cell>
                          </ng-container>
                        <ng-container matColumnDef="dateOfService">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="dateOfService">Date Of Service</mat-header-cell>
                            <mat-cell *matCellDef="let patientRoundingSheet">{{getDOS(patientRoundingSheet)}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="name">Census Name</mat-header-cell>
                            <mat-cell *matCellDef="let patientRoundingSheet">{{patientRoundingSheet.name}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="facility">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="facility">Facility</mat-header-cell>
                            <mat-cell *matCellDef="let patientRoundingSheet">
                                <app-facility-title [facility]="patientRoundingSheet?.facility" [loginStatus]="false"></app-facility-title>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="date">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="date">Visit Date</mat-header-cell>
                            <mat-cell *matCellDef="let patientRoundingSheet">{{patientRoundingSheet.date | date: global.date_format}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="patients_count_total">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="patient_count">Total Patients</mat-header-cell>
                            <mat-cell *matCellDef="let patientRoundingSheet">
                                {{patientRoundingSheet.patientList.length}}
                            </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="Saved_Signed">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="Saved_Signed">Saved / Signed Notes</mat-header-cell>
                            <mat-cell *matCellDef="let patientRoundingSheet">
                                {{patientRoundingSheet?.savedNotesCount}} / {{patientRoundingSheet?.signedNotesCount}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Draft_Submitted">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="Draft_Submitted">Draft / Submitted Charge</mat-header-cell>
                            <mat-cell *matCellDef="let patientRoundingSheet">
                                {{patientRoundingSheet?.draftChargesCount}} / {{patientRoundingSheet?.submittedChargesCount}}
                            </mat-cell>
                        </ng-container>



                        <ng-container matColumnDef="rehab_docs">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="rehab_docs_count">Rehab Docs</mat-header-cell>
                            <mat-cell *matCellDef="let patientRoundingSheet" style="cursor: pointer;" (click)="viewRehabDocs(patientRoundingSheet._id)">
                                {{patientRoundingSheet.rehab_docs}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="active_census">
                            <mat-header-cell *matHeaderCellDef>Active Census</mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <button *ngIf="element.facility.pcc_facId" class="dn-button-primary" mat-raised-button [routerLink]="['/census/active/' + element.facility._id]">
                    Active Census
                  </button>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="operations">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let patientRoundingSheet;let index=index" class="flex gap-5 items-center">
                                <div class="flex gap-5 items-center" *ngIf="!is2LeggedAuthRequired(patientRoundingSheet.facility._id) && censusStatus==='true'">
                                    <i *ngIf="patientRoundingSheet.patientList?.length>0" class="icon icon-view" matTooltip="View Census" matTooltipPosition="above" (click)="viewClicked(patientRoundingSheet._id)"></i>                                   
                                    <i class="icon icon-delete" matTooltip="Delete Census" matTooltipPosition="above" *ngIf="isOwner(patientRoundingSheet)" (click)="$event.stopPropagation(); deletePatientRoundingSheet(patientRoundingSheet)"></i>
                                </div>
                                <div *ngIf="censusStatus==='false'">
                                    <mat-icon matTooltip="Restore Census" matTooltipPosition="above" style="cursor: pointer;"
                                        (click)="restoreInactiveCensus(patientRoundingSheet, index)">
                                        restore
                                    </mat-icon>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>


                    </mat-table>
                    <div class="flex justify-end" style="margin-top: 10px;">
                        <button mat-raised-button class="dn-button-primary" [disabled]="!pagination?.hasBack" style="margin-right:6px;"
                        (click)="handleBackClick();">Back</button>
                        <span style="margin-top: 8px;">
                            {{(patientRoundingSheets.length > 0 ? pagination?.skip+1 : 0)+' - '+pagination?.noOfensus||0}} out of {{totalCensus||0}}
                        </span>
                        <button mat-raised-button class="dn-button-primary" [disabled]="!pagination?.hasNext"  style="margin-left:6px;"
                        (click)="handleNextClick();">Next</button>

                        <button mat-raised-button class="dn-button-primary" *ngIf="patientRoundingSheets?.length < total&&false" (click)="initPatientRoundingSheets(filter_facility_id, filter_provider_id);">Show all</button>
                    </div>
                    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</main>