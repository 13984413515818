export const API_URL = {
    USER: {
        changeQA: '/user/changeQA',
        changeQAE: '/user/changeQAE',
        changeTrustedTrans: '/user/changeTrustedTrans',
        loadEmailSuggestions: '/user/loadEmailSuggestions',
        getUser: '/user/getUser',
        updateUser: '/user/updateUser',
        emailNotificationStatus: '/user/emailNotificationStatus',
        updateUserPhone: '/user/updateUserPhone',
        getSignatureSetting: '/user/getSignatureSetting',
        saveSignatureSettings: '/user/saveSignatureSettings',
        saveNoteEmailSettings: '/user/saveNoteEmailSettings',
        getUsers: '/user/getUsers',
        getUsersWithBusinessHour: '/user/getUsersWithBusinessHour',
        getUsersByFacility: '/user/getUsersByFacility',
        getUserDetail: '/user/getUserDetail',
        updateAgreement: '/user/updateAgreement',
        getAgreement: '/user/getAgreement',
        getUserTypes: '/user/getUserTypes',
        getRoles: '/user/getRoles',
        assignRole: '/user/assignRole',
        unassignRole: '/user/unassignRole',
        createRelationship: '/user/createRelationship',
        getRelations: '/user/getRelations',
        getAllUsersList: '/user/getAllUsersList',
        getDoctorsList: '/user/getDoctorsList',
        getWoundNurseList: '/user/getWoundNurseList',
        getViewOnlyUsersList: '/user/getViewOnlyUsersList',
        getAdminList: '/user/getAdminList',
        getAllDoctorsList: '/user/getAllDoctorsList',
        getProviderRelations: '/user/getProviderRelations',
        createRelationProvider2Provider: '/user/createRelationProvider2Provider',
        deleteRelationProvider2Provider: '/user/deleteRelationProvider2Provider',
        updateRelationWithAllProviders: '/user/updateRelationWithAllProviders',
        getAssociatedProviders: '/user/getAssociatedProviders',
        getP2PRelation: '/user/getP2PRelation',
        assocProviders: '/user/assocProviders',
        getAssociatedProvidersForTemplates: '/user/getAssociatedProvidersForTemplates',
        getAssociatedDoctorsList: '/user/getAssociatedDoctorsList',
        getDoctorRelations: '/user/getDoctorRelations',
        getAssociatedDoctorsListForMA: '/user/getAssociatedDoctorsListForMA',
        getAssociatedDoctorsListForWoundNurse: '/user/getAssociatedDoctorsListForWoundNurse',
        getAssociatedDoctorsListForBO: '/user/getAssociatedDoctorsListForBO',
        getDoctorsWithRelashionship: '/user/getDoctorsWithRelashionship',
        getTranscriberList: '/user/getTranscriberList',
        getMAList: '/user/getMAList',
        getBOList: '/user/getBOList',
        getManagerList: '/user/getManagerList',
        createRelationProvider2MA: '/user/createRelationProvider2MA',
        createRelationProvider2BO: '/user/createRelationProvider2BO',
        deleteRelationProvider2MA: '/user/deleteRelationProvider2MA',
        deleteRelationProvider2BO: '/user/deleteRelationProvider2BO',
        getMAAssociations: '/user/getMAAssociations',
        getBOAssociations: '/user/getBOAssociations',
        getAssociatedTranscriberList: '/user/getAssociatedTranscriberList',
        getTranscribersAndProviders: '/user/getTranscribersAndProviders',
        getAllProviders: '/user/getAllProviders',
        getAllSameCompanyProviders: '/user/getAllSameCompanyProviders',
        getBillersList: '/user/getBillersList',
        getNursesList: '/user/getNursesList',
        verifyUserCompany: '/user/verifyUserCompany',
        deleteUser: '/user/deleteUser',
        setMipReporting: '/user/setMipReporting',
        setEnabledMips: '/user/setEnabledMips',
        getMipsReportingsAndMeasures: '/user/getMipsReportingsAndMeasures',
        setTabSettings: '/user/setTabSettings',
        setPatientTabSettings: '/user/setPatientTabSettings',
        updateUIPreferences: '/user/updateUIPreferences',
        getTabSettings: '/user/getTabSettings',
        updateTabsOrder: '/user/updateTabsOrder',
        getTabsOrder: '/user/getTabsOrder',
        getPatientTabSettings: '/user/getPatientTabSettings',
        getUserPermission: '/user/getUserPermission',
        setUserPermission: '/user/setUserPermission',
        // getAllBillers: '/user/getAllBillers',
        // createRelationBillerProvider: '/user/createRelationBillerProvider',
        // getBillerRelations: '/user/getBillerRelations',
        getNoteUploadingAndDestMethod: '/user/getNoteUploadingAndDestMethod',
        updatePccNoteUploadingMethod: '/user/updatePccNoteUploadingMethod',
        updateMatrixNoteUploadingMethod: '/user/updateMatrixNoteUploadingMethod',
        saveMatrixPDFDesination: '/user/saveMatrixPDFDesination',
        getAssocProviders: '/user/getAssocProviders'

    },
    NURSE: {
        getAllNurses: '/nurse/getAllNurses',
        createRelationNurseProvider: '/nurse/createRelationNurseProvider',
        removeRelationNurseProvider: '/nurse/removeRelationNurseProvider',
        getNurseRelations: '/nurse/getNurseRelations',
        getAssociatedFacilities: '/nurse/getAssociatedFacilities',
        getAllProviderNotes: '/nurse/getAllProviderNotes'
    },
    BILLER: {
        getAllBillers: '/biller/getAllBillers',
        createRelationBillerProvider: '/biller/createRelationBillerProvider',
        removeRelationBillerProvider: '/biller/removeRelationBillerProvider',
        getBillerRelations: '/biller/getBillerRelations',
        getDoctorsList: '/biller/getDoctorsList',
        getProvidersCharges: '/biller/getProvidersCharges',
        getPatientsCharges: '/biller/getPatientsCharges',
        getAllPatientsCharges: '/biller/getAllPatientsCharges',
        getPatientsChargesAdminCharges: '/biller/getPatientsChargesAdminCharges',
        getPatientsChargesAdmin: '/biller/getPatientsChargesAdmin',
        getFacilities: '/biller/getFacilities',
        generatePDFAndDownload: '/biller/generatePDFAndDownload',
        updatePatientCharges: '/biller/updatePatientCharges',
        deleteChargeClicked: '/biller/deleteChargeClicked',
        getCount: '/biller/getCount',
        getDraftedChargeCount: '/biller/getDraftedChargeCount',
        getMissingFacesheets: '/biller/getMissingFacesheets',
        getAddendumReqCount: '/biller/getAddendumReqCount',
        exportGroupedReportAsXLSX: '/biller/exportGroupedReportAsXLSX',
        exportGroupedReportAsXLSX_DOC: '/biller/exportGroupedReportAsXLSX_DOC',
        exportGroupedReportAsXLSX_DOU: '/biller/exportGroupedReportAsXLSX_DOU',
        exportFullReportAsXLSX: '/biller/exportFullReportAsXLSX',
        downloadJSONFile: '/charge/downloadJSONFile',
        updateBillerPracticeRelation: '/biller/updateBillerPracticeRelation',
        getAssociatedCompanies: '/biller/getAssociatedCompanies'
    },
    ESIGN: {
        getProviders: '/esign/getProviders',
        getProvidersNames: '/esign/getProvidersNames',
        uploadFiletoSign: '/esign/uploadFiletoSign',
        saveSignature: '/esign/saveSignature',
        getSignatures: '/esign/getSignatures',
        deleteSignature: '/esign/deleteSignature',
        saveProccessedFileResponse: '/esign/saveProccessedFileResponse',
        getAppointmentsOfPatient: '/esign/getAppointmentsOfPatient'
    },
    REPORT: {
        getreportData: '/reports/getReportData',
        getReportDataDict: '/reports/getReportDataDict',
        getSpecificNoteData: '/reports/getSpecificNoteData',
    },
    TRAINING: {
        getAudioChunks: '/training/getAudioChunks',
        updateChunkStatus: '/training/updateChunkStatus',
        generateCSV: '/training/generateCSV',
        getProviders: '/training/getProviders',
        getPhrasesCSV: '/training/getPhrasesCSV',
    },
    COMPANIES_CONFIGRATION: {
        setCompaniesConfigration: '/comconfig/setCompaniesConfigration',
        getCompaniesConfigration: '/comconfig/getCompaniesConfigration',
        deleteConfigration: '/comconfig/deleteConfigration',
    },
    VC: {
        generateToken: '/vc/generateToken',
        generateTokenAnonymous: '/vc/generateTokenAnonymous',
        createRoom: '/vc/createRoom',
        getRoom: '/vc/getRoom',
        saveSketchFile: '/vc/saveSketchFile',
        getSignedURLs: '/vc/getSignedURLs',
        addGuest: '/vc/addGuest',
        getGuests: '/vc/getGuests',
        sendGuestInvite: '/vc/sendGuestInvite'
    },
    AUTH: {
        encryptString: '/auth/encryptString',
        decryptString: '/auth/decryptString',
        webEncryptPass: '/auth/webEncryptPass',
        login: '/auth/login',
        getCompanyLogo: '/auth/getCompanyLogo',
        loginWithCognito: '/auth/loginWithCognito',
        getAuthenticationDetails: '/auth/getAuthenticationDetails',
        register: '/auth/register',
        logout: '/auth/logout',
        changeUserActiveStatus: '/auth/changeUserActiveStatus',
        changeChargeStatus: '/auth/changeChargeStatus',
        changeRRGenStatus: '/auth/changeRRGenStatus',
        changeTelemedicineStatus: '/auth/changeTelemedicineStatus',
        changeMatrixPermission: '/auth/changeMatrixPermission',
        changeMatrixStatus: '/auth/changeMatrixStatus',
        changeTATStatus: '/auth/changeTATStatus',
        changeAdminStatus: '/auth/changeAdminStatus',
        changePccStatus: '/auth/changePccStatus',
        updatePassword: '/auth/updatePassword',
        updateProfile: '/auth/updateProfile',
        updateSettings: '/auth/updateSettings',
        updateOtherSettings: '/auth/updateOtherSettings',
        updateUserNotifcationSettings: '/auth/updateUserNotifcationSettings',
        sendVerificationCode: '/auth/sendVerificationCode',
        checkVerificationCode: '/auth/checkVerificationCode',
        getTwoFactorSettingAllow: '/auth/getTwoFactorSettingAllow',
        sendPasswordResetCode: '/auth/sendPasswordResetCode',
        checkPasswordResetCode: '/auth/checkPasswordResetCode',
        checkPasswordResetCodeAndRestPassword: '/auth/checkPasswordResetCodeAndRestPassword',
        sendMobileVerificationCode: '/auth/sendMobileVerificationCode',
        send2FactorVerificationCode: '/auth/send2FactorVerificationCode',
        check2FactorVerificationCode: '/auth/check2FactorVerificationCode',
        updateDeviceToken: '/auth/updateDeviceToken',
        getDeviceToken: '/auth/getDeviceToken',
        updateUserType: '/auth/updateUserType',
        updateAdminType: '/auth/updateAdminType',
        LoginActivity: '/auth/LoginActivity',
        setRecentPccLogin: '/auth/setRecentPccLogin',
        getLoginActivity: '/auth/getLoginActivity',
        checkAuthForAdmin: '/auth/checkAuthForAdmin',
        getCognitoStatus: '/auth/getCognitoStatus',
        checkSession: '/auth/checkSession',
        forgotCognitoPassword: '/auth/forgotCognitoPassword',
        confirmForgotCognitoPassword: '/auth/confirmForgotCognitoPassword',
        changeCognitoPassword: '/auth/changeCognitoPassword',
        validateLogin: '/auth/validateLogin',
        deleteUser: '/auth/deleteUser',
        sendContactUsEmail: '/auth/sendContactUsEmail',
        isUserVerified: '/auth/isUserVerified',
        getUserLoginInfo: '/auth/getUserLoginInfo',
        resendAuthCode: '/auth/resendAuthCode',
        dataExportApi: '/auth/dataExportApi',
    },
    PATIENT: {
        addNewPatient: '/patient/addNewPatient',
        updatePatient: '/patient/updatePatient',
        deletePatient: '/patient/deletePatient',
        getPatient: '/patient/getPatient',
        getPatientDetail: '/patient/getPatientDetail',
        getPatients: '/patient/getPatients',
        getPatientsV2: '/patient/getPatientsV2',
        getPatientsV2OnlyPatient: '/patient/getPatientsV2OnlyPatient',
        getPatientByFacilityId: '/patient/getPatientByFacilityId',
        getMatrixPatientByFacilityId: '/patient/getMatrixPatientByFacilityId',
        getMatrixPatientForMerging: '/patient/getMatrixPatientForMerging',
        getMatrixPatients: '/patient/getMatrixPatients',
        getFacilityPatients: '/patient/getFacilityPatients',
        getFacilityPatientsByProviderId: '/patient/getFacilityPatientsByProviderId',
        getAllPatients: '/patient/getAllPatients',
        generateFacesheetAndDownloadAsPDF: '/patient/generateFacesheetAndDownloadAsPDF',
        generateFacesheetForMatrixAndDownloadAsPDF: '/patient/generateFacesheetForMatrixAndDownloadAsPDF',
        getPatientInfoFromFaceSheet: '/patient/getPatientInfoFromFaceSheet',
        addPatientAllergies: '/patient/addPatientAllergies',
        getPatientOpportunities: '/patient/getPatientOpportunities',
        getPatientAllergies: '/patient/getPatientAllergies',
        getAllergyIntolerances: '/patient/getAllergyIntolerances',
        getPatientConditions: '/patient/getPatientConditions',
        deletePatientAllergies: '/patient/deletePatientAllergies',
        updatePatientAllergies: '/patient/updatePatientAllergies',
        updatePatientDrugHistoryConsent: '/patient/updatePatientDrugHistoryConsent',
        // Patient Medical History
        addNewHeader: '/patient/addNewHeader',
        deleteHeader: '/patient/deleteHeader',
        addNewRow: '/patient/addNewRow',
        deleteRow: '/patient/deleteRow',
        getAllDataset: '/patient/getAllDataset',
        updateHeader: '/patient/updateHeader',
        updateRow: '/patient/updateRow',
        getPatientMedicalHistory: '/patient/getPatientMedicalHistory',
        updatePatientMedicalHistory: '/patient/updatePatientMedicalHistory',
        sendEmailToUsers: '/patient/sendEmailToUsers',
        associatePatients: '/patient/associatePatients',
        getPatientADTHistory: '/patient/getPatientADTHistory',
        getOnePatient_fromCompFacPatient: '/patient/getOnePatient_fromCompFacPatient',
        getPatient_Maping_population: '/patient/getPatient_Maping_population',
        getPatientsByFacilityId_v2: '/patient/getPatientsByFacilityId_v2',
        getmatrixConditionsService: '/patient/getmatrixConditionsService',
        getInsuranceInfo: '/patient/getInsuranceInfo',
        editPatient: '/patient/editPatient',
        createTag: '/patient/createNewTag',
        getAllTags: '/patient/getAllTags',
        updateTag: '/patient/updateTag',
        deleteTag: '/patient/deleteTag',
        fetchTagAssociatedPatients: '/patient/fetchTagAssociatedPatients',
        getPatientFromCompanyFacilityPatient: '/patient/getPatientFromCompanyFacilityPatient',
        exportPatients: '/patient/exportPatients',
    },
    FACILITY: {
        getAllFacilities: '/facility/getAllFacilities',
        addAddress: '/facility/addAddress',
        updateAddress: '/facility/updateAddress',
        getAddress: '/facility/getAddress',
        deleteAddress: '/facility/deleteAddress',
        getMyAddress: '/facility/getMyAddress',
        getCompanyFacilities: '/facility/getCompanyFacilities',
        getFacilities: '/facility/getFacilities',
        getAllFacilitiesForMerging: '/facility/getAllFacilitiesForMerging',
        getAllProvidersAssociatedToFacility: '/facility/getAllProvidersAssociatedToFacility',
        getAllFacilitiesProviderTemplateAssoc: '/facility/getAllFacilitiesProviderTemplateAssoc',
        updateFacilityProviderTempalteAssoc: '/facility/updateFacilityProviderTempalteAssoc',
        getAllProviderAssociatedFacilities: '/facility/getAllProviderAssociatedFacilities',
        getAssociatedFacilityOfTranscriber: '/facility/getAssociatedFacilityOfTranscriber',
        getAssociatedFacilityOfMedicalAssistant: '/facility/getAssociatedFacilityOfMedicalAssistant',
        getAssociatedFacilityOfBusinessOfficer: '/facility/getAssociatedFacilityOfBusinessOfficer',
        updateFacilityProviders: '/facility/updateFacilityProviders',
        updateFacilityTemplates: '/facility/updateFacilityTemplates',
        updtaeFacilityNurseAssociation: '/facility/updtaeFacilityNurseAssociation',
        updateFacilityCompany: '/facility/updateFacilityCompany',
        getFilteredFacilities: '/facility/getFilteredFacilities',
        getFacilitiesByCompanyID: '/facility/getFacilitiesByCompanyID',
        getFacilitySignedNotes: '/facility/getFacilitySignedNotes',
        refreshDocumentCategories: '/facility/refreshDocumentCategories',
    },
    AUDIO: {
        addAudio: '/audio/addAudio',
        checkAudioTitle: '/audio/checkAudioTitle',
        checkAudioNote: '/audio/checkAudioNote',
        sendNewDictaionRequestEmail: '/audio/sendNewDictaionRequestEmail',
        updateAudioInfo: '/audio/updateAudioInfo',
        updateAudioStatus: '/audio/updateAudioStatus',
        multiSelectChangeDictationStatus: '/audio/multiSelectChangeDictationStatus',
        deleteRestoreAudio: '/audio/deleteRestoreAudio',
        dictationDeleteRestore: '/audio/dictationDeleteRestore',
        getAudioDetail: '/audio/getAudioDetail',
        getAudios: '/audio/getAudios',
        getAudiosByPatientId: '/audio/getAudiosByPatientId',
        getDictations: '/audio/getDictations',
        getTrashedAudios: '/audio/getTrashedAudios',
        addReply: '/audio/addReply',
        getAudioReplies: '/audio/getAudioReplies',
        addTitle: '/audio/addTitle',
        deleteTitle: '/audio/deleteTitle',
        getMyTitles: '/audio/getMyTitles',
        getAudio: '/audio/getAudio',
        getAudioChunks: '/audio/getAudioChunks',
        updateAudioChunk: '/audio/updateAudioChunk',
        getDictationList: '/audio/getDictationList',
        getCountGroup: '/audio/getCountGroup',
        dictationCount: '/audio/dictationCount',
    },
    TEMPLATE: {
        addNewTemplate: '/template/addNewTemplate',
        updateTemplate: '/template/updateTemplate',
        deleteTemplate: '/template/deleteTemplate',
        getMyTemplates: '/template/getMyTemplates',
        getTemplateById: '/template/getTemplateById',
        getTemplatesByFacilityId: '/template/getTemplatesByFacilityId',
        getTemplates: '/template/getTemplates',
        decryptText: '/template/decryptText',
        getSystemTemplatesByFacilityId: '/template/getSystemTemplatesByFacilityId',
        getFollowUpTemplateByFacilityId: '/template/getFollowUpTemplateByFacilityId',
        getInitialTemplatesByFacilityIds: '/template/getInitialTemplatesByFacilityIds',
        getSystemDefinedTemplates: '/template/getSystemDefinedTemplates',
        addNoteBuilder: '/template/addNoteBuilder',
        getNoteBuilder: '/template/getNoteBuilder',
        getDkeys: '/template/getDkeys',
        applyNoteBuilder: '/template/applyNoteBuilder',
        addPhrase: '/template/addPhrase',
        getPhrases: '/template/getPhrases',
        lockTemplate: '/template/lockTemplate',
        showOrHideTemplates: '/template/showOrHideTemplates',
        getTemplateFacilityAssociations: '/template/getTemplateFacilityAssociations',
        updateTemplateAssociation: '/template/updateTemplateAssociation'
    },
    DOCUMENT: {
        createFolder: '/document/createFolder',
        changeFolderTitle: '/document/changeFolderTitle',
        getMyFolder: '/document/getMyFolder',
        getSharedFolder: '/document/getSharedFolder',
        addNewFile: '/document/addNewFile',
        uploadFile: '/document/uploadFile',
        downloadFile: '/document/downloadFile',
        getMyFiles: '/document/getMyFiles',
        getSharedFiles: '/document/getSharedFiles',
        getSharedFilesAndFolders: '/document/getSharedFilesAndFolders',
        getFilesAndFoldersByParentFolderId: '/document/getFilesAndFoldersByParentFolderId',
        getMyFilesAndFolders: '/document/getMyFilesAndFolders',
        updateItemDescription: '/document/updateItemDescription',
        shareItem: '/document/shareItem',
        getPro: '/document/getPro',
        deleteRestoreItem: '/document/deleteRestoreItem',
        changeParentFolderId: '/document/changeParentFolderId',
        getDriveActivity: '/document/getDriveActivity'
    },
    PATIENTAPPOINTMENT: {
        addNewPatientAppointment: '/patientappointment/addNewPatientAppointment',
        updateAppointment: '/patientappointment/updateAppointment',
        updateAppointmentStatus: '/patientappointment/updateAppointmentStatus',
        getOneAppointment: '/patientappointment/getOneAppointment',
        getAppointments: '/patientappointment/getAppointments',
        deleteAppointments: '/patientappointment/deleteAppointment',

        addNewProviderTemplate: '/patientappointment/addNewProviderTemplate',
        updateAppointmentTemplate: '/patientappointment/updateAppointmentTemplate',
        getAppointmentTemplates: '/patientappointment/getAppointmentTemplates',
        getOneAppointmentTemplate: '/patientappointment/getOneAppointmentTemplate',
        deleteAppointmentTemplate: '/patientappointment/deleteAppointmentTemplate',

        getUserBusinesshour: '/patientappointment/getUserBusinesshour',
        saveUserBusinesshour: '/patientappointment/saveUserBusinesshour',
        saveSelectedUserInBusinesshour: '/patientappointment/saveSelectedUserInBusinesshour',

    },
    ORDER: {
        addNewOrderSet: '/order/addNewOrderSet',
        updateOrderSet: '/order/updateOrderSet',
        addNewOrder: '/order/addNewOrder',
        updateOrder: '/order/updateOrder',
        getOrders: '/order/getOrders',
        deleteOrderSet: '/order/deleteOrderSet',
        deleteOrder: '/order/deleteOrder',
        savePatientOrder: '/order/savePatientOrder',
        getPatientOrders: '/order/getPatientOrders',
        generateOrderPDF: '/order/generateOrderPDF',


    },
    NOTE: {
        addTranscription: '/note/addTranscription',
        deleteTranscription: '/note/deleteTranscription',
        restoreTranscription: '/note/restoreTranscription',
        updateTranscriptionStatus: '/note/updateTranscriptionStatus',
        updateTranscriptionText: '/note/updateTranscriptionText',
        multiSelectChangeTranscriptionStatus: '/note/multiSelectChangeTranscriptionStatus',
        toggleNoteLock: '/note/toggleNoteLock',
        checkNoteQA: '/note/checkNoteQA',
        getTranscriptionById: '/note/getTranscriptionById',
        getNoteById: '/note/getNoteById',
        setNoteLock: '/note/setNoteLock',
        getMobileTranscription: '/note/getMobileTranscription',
        generatePDFAndDownload: '/note/generatePDFAndDownload',
        generatePDFsAndEmail: '/note/generatePDFsAndEmail',
        getEmails: '/note/getEmails',
        generatePDFNote: '/note/generatePDFNote',
        downloadBatchNotes: '/note/downloadBatchNotes',
        getDraftNoteByUserId: '/note/getDraftNoteByUserId',
        saveNote: '/note/saveNote',
        saveDraftNote: '/note/saveDraftNote',
        deleteMultipleTrashedNotes: '/note/deleteMultipleTrashedNotes',
        saveMobileNote: '/note/saveMobileNote',
        submitForReviewNote: '/note/submitForReviewNote',
        coSignNote: '/note/coSignNote',
        assignNoteManually: '/note/assignNoteManually',
        revertNote: '/note/revertNote',
        signNote: '/note/signNote',
        signNoteV2: '/note/signNoteV2',
        uploadNote: '/note/uploadNote',
        uploadNoteV2: '/note/uploadNoteV2',
        strikeOutNote: '/note/strikeOutNote',
        getTeamNotes: '/note/getTeamNotes',
        getTeamNotesForTranscribers: '/note/getTeamNotesForTranscribers',
        getAllAssociatedTranscriptions: '/note/getAllAssociatedTranscriptions',
        getTranscriptionsByPatientId: '/note/getTranscriptionsByPatientId',
        add_note_comment: '/note/add_note_comment',
        get_note_comments: '/note/get_note_comments',
        getNotes: '/note/getNotes',
        getNotes_v1: '/note/getNotes_v1',
        getNotes_single: '/note/getNotes_single',
        noteCounts: '/note/noteCounts',
        getUserNotes: '/note/getUserNotes',
        getNotesFullReport: '/note/getNotesFullReport',
        getNoteAudit: '/note/getNoteAudit',
        getNoteTimeLogs: '/note/getNoteTimeLogs',
        saveTimeLogs: '/note/saveTimeLogs',
        addendumNote: '/note/addendumNote',
        saveAddendumNote: '/note/saveAddendumNote',
        uploadAddendumNote: '/note/uploadAddendumNote',
        getNoteAddendums: '/note/getNoteAddendums',
        previewNoteAsPCC: '/note/previewNoteAsPCC',
        getCountGroup: '/note/getCountGroup',
        getNoteBuilderDictionary: '/note/getNoteBuilderDictionary',
        getNoteBuilderDictionaryAdmin: '/note/getNoteBuilderDictionaryAdmin',
        getAllPhrasesByProviderId: '/note/getAllPhrasesByProviderId',
        saveNoteBuilderDictionary: '/note/saveNoteBuilderDictionary',
        saveNoteBuilderDictionaryAdmin: '/note/saveNoteBuilderDictionaryAdmin',
        updateNoteBuilderDictionary: '/note/updateNoteBuilderDictionary',
        deleteFromNoteBuilderDictionary: '/note/deleteFromNoteBuilderDictionary',
        getFollowUpNoteAsJson: '/note/getFollowUpNoteAsJson',
        getFollowUpWoundsAsJson: '/note/getFollowUpWoundsAsJson',
        updateNoteEditLock: '/note/updateNoteEditLock',
        generateMultiplePDF: '/note/generateMultiplePDF',
        encrypted_zip_file: '/note/encrypted_zip_file',
        generateEncryptedPDFsAndEmail: '/note/generateEncryptedPDFsAndEmail',
        getsavedSignedCount: '/note/getsavedSignedCount',
        exportGroupedReportAsXLSX: '/note/exportGroupedReportAsXLSX',
        exportFullReportAsXLSX: '/note/exportFullReportAsXLSX',
        generatePDFsLinksAndEmail: '/note/generatePDFsLinksAndEmail',
        deleteMultipleUnsignedNotes: '/note/deleteMultipleUnsignedNotes',
        generateBatchNotesPDF: '/note/generateBatchNotesPDF',
        updateNoteTemplateId: '/note/updateNoteTemplateId',
        getFiveRecentNotes: '/note/getFiveRecentNotes'
    },
    ROUTES: {
        uploadFile: '/routes/uploadFile',
        createPDFandUpload: '/routes/createPDFandUpload',
        generatePDF: '/routes/generatePDF',
        downloadAsPDF: '/routes/downloadAsPDF',
        convertMp4ToMp3Anddownload: '/routes/convertMp4ToMp3Anddownload',
        downloadMP4File: '/routes/downloadMP4File',
        multiDownloadDictationsByIds: '/routes/multiDownloadDictationsByIds',
        createAndUploadDocs: '/routes/createAndUploadDocs',
        getDocFileContent: '/routes/getDocFileContent',
        docxToPdfUpload: '/routes/docxToPdfUpload',
        importPatientFile: '/routes/importPatientFile',
        getNotificationOperations: '/routes/getNotificationOperations',
        getAllNotificationOperations: '/routes/getAllNotificationOperations',
        updateNotificationOperation: '/routes/updateNotificationOperation',
        // getAllNotifications: '/routes/getAllNotifications',
        // getNotifications: '/routes/getNotifications',
        // getUnseenNotificationsCount: '/routes/getUnseenNotificationsCount',
        // makeNotificationsSeen: '/routes/makeNotificationsSeen',
        getActivityLogs: '/routes/getActivityLogs',
        getGoogleToken: '/routes/getGoogleToken',
        googleDrive: '/routes/googleDrive',
        getFiles: '/routes/getFiles',
        downloadGoogleFile: '/routes/downloadGoogleFile',
        getGoogleFile: '/routes/getGoogleFile'

    },
    RRGEN: {
        getAllDocumentPages: '/rrgen/getAllDocumentPages',
        getAllDocuments: '/rrgen/getAllDocuments',
        images: '/rrgen/images',
        generateReport: '/rrgen/generateReport',
        saveProccessedDocFileResponse: '/rrgen/saveProccessedDocFileResponse',
        uploadFilesAndStartProcess: '/rrgen/uploadFilesAndStartProcess',
        addDocumentPage: '/rrgen/addDocumentPage',
        generatePDFAndDownload: '/rrgen/generatePDFAndDownload',
        updateCoverInfo: '/rrgen/updateCoverInfo',
        getCoverInfo: '/rrgen/getCoverInfo',
        updateDocumentPage: '/rrgen/updateDocumentPage',
        addNewPage: '/rrgen/addNewPage',
        saveOrUpadateSummary: '/rrgen/saveOrUpadateSummary',
        previewReport: '/rrgen/previewReport',
        perPageGenerateReport: '/rrgen/perPageGenerateReport',
        lockAndUnlockDocument: '/rrgen/lockAndUnlockDocument',
        paginteDocument: '/rrgen/paginteDocument',
    },
    CHARGE: {
        hl7Charge: '/charge/hl7Charge',
        addCharge: '/charge/addCharge',
        updateCharge: '/charge/updateCharge',
        getCharges: '/charge/getCharges',
        getCharge: '/charge/getCharge',
        getICDs: '/charge/getICDs',
        compareICDs: '/charge/compareICDs',
        getRecentCharge: '/charge/getRecentCharge',
        getrecentMip: '/charge/getrecentMip',
        getCPTs: '/charge/getCPTs',
        getCompanyCPTs: '/charge/getCompanyCPTs',
        setFavoriteICDs: '/charge/setFavoriteICDs',
        setFavoriteCPTs: '/charge/setFavoriteCPTs',
        getFavoriteICDs: '/charge/getFavoriteICDs',
        getFavoriteCPTs: '/charge/getFavoriteCPTs',
        // addToFavoriteCharges: '/charge/addToFavoriteCharges',
        // getFavoriteCharges: '/charge/getFavoriteCharges',
        rearrangeFavoriteCharges: '/charge/rearrangeFavoriteCharges',
        applyColorToFavouriteCPT: '/charge/applyColorToFavouriteCPT',
        // removeFromFavourite: '/charge/removeFromFavourite',
        createCategory: '/charge/createCategory',
        updateCategoryRealtion: '/charge/updateCategoryRealtion',
        getCategoryRealtion: '/charge/getCategoryRealtion',
        deleteCategoryRealtion: '/charge/deleteCategoryRealtion',
        updateCategory: '/charge/updateCategory',
        getCategories: '/charge/getCategories',
        setICDCategorisedCharge: '/charge/setICDCategorisedCharge',
        setCPTCategorisedCharge: '/charge/setCPTCategorisedCharge',
        getICDCategorisedCharges: '/charge/getICDCategorisedCharges',
        getCPTCategorisedCharges: '/charge/getCPTCategorisedCharges',
        removeICDCategorisedCharge: '/charge/removeICDCategorisedCharge',
        removeCPTCategorisedCharge: '/charge/removeCPTCategorisedCharge',
        toggleApproveCharge: '/charge/toggleApproveCharge',
        submitAndApproveCharge: '/charge/submitAndApproveCharge',
        getChargeAudit: '/charge/getChargeAudit',
        approveAll: '/charge/approveAll',
        SubmitAllandApprove: '/charge/SubmitAllandApprove',
        deleteAll: '/charge/deleteCharges',
        getModifiers: '/charge/getModifiers',
        getChargesByFilters: '/charge/getChargesByFilters',
        getFlatChargesByFilters: '/charge/getFlatChargesByFilters',
        getChargesByFilters_v1: '/charge/getChargesByFilters_v1',
        getChargeGroupSeparate: '/charge/getChargeGroupSeparate',
        getChargesAdminDashboard: '/charge/getChargesAdminDashboard',
        addICD: '/charge/addICD',
        getICDSuggestions: '/charge/getICDSuggestions',
        getChargesReport: '/charge/getChargesReport',
        getCensusChargesReport: '/charge/getCensusChargesReport',
        getChargesCount: '/charge/getChargesCount',
        getChargesByTimePeriodGap: '/charge/getChargesByTimePeriodGap',
        sendDataToBillingCompany: '/charge/sendDataToBillingCompany',
        deleteDraftedCharge: '/charge/deleteDraftedCharge',
        generateBatchReportofFacesheets: '/charge/generateBatchReportofFacesheets',
        getAdminChargesReport: '/charge/getAdminChargesReport',
        downloadLosReport: '/charge/downloadLosReport',
        getIpaReport: '/charge/getIpaReportData',
        downloadIpaReport: '/charge/downloadIpaReport',
        exportCensusReportAsExcel: '/charge/exportCensusReportAsExcel',
        getCompanyAuditReport: '/charge/getCompanyAuditReport',
    },
    PATIENT_ROUNDING_SHEET: {
        addPatientRoundingSheet: '/patientroundingsheet/addPatientRoundingSheet',
        addPatientRoundingSheet_outPatient: '/patientroundingsheet/addPatientRoundingSheet_outPatient',
        checkIfPatientExistInAssociatedProviderCensus: '/patientroundingsheet/checkIfPatientExistInAssociatedProviderCensus',
        updatePatientRoundingSheet: '/patientroundingsheet/updatePatientRoundingSheet',
        deletePatientRoundingSheet: '/patientroundingsheet/deletePatientRoundingSheet',
        getPatientRoundingSheet: '/patientroundingsheet/getPatientRoundingSheet',
        getPatientRoundingSheetV2: '/patientroundingsheet/getPatientRoundingSheetV2',
        getCensus: '/patientroundingsheet/getCensus',
        getCensusAllPatients: '/patientroundingsheet/getCensusAllPatients',
        getActiveCensus: '/patientroundingsheet/getActiveCensus',
        initializeActiveCensus: '/patientroundingsheet/initializeActiveCensus',
        getPatientRoundingSheets: '/patientroundingsheet/getPatientRoundingSheets',
        getCensusList: '/patientroundingsheet/getCensusList',
        getCensusListWithNotesAndChargesCount: '/patientroundingsheet/getCensusListWithNotesAndChargesCount',
        getRehabDocs: '/patientroundingsheet/getRehabDocs',
        getRehabDocsCount: '/patientroundingsheet/getRehabDocsCount',
        addPatientToPatientRoundingSheet: '/patientroundingsheet/addPatientToPatientRoundingSheet',
        addPatientsToPatientRoundingSheet: '/patientroundingsheet/addPatientsToPatientRoundingSheet',
        removePatientFromPatientRoundingSheet: '/patientroundingsheet/removePatientFromPatientRoundingSheet',
        removeNoteFromPatientRoundingSheet: '/patientroundingsheet/removeNoteFromPatientRoundingSheet',
        updatePatientRoundingSheetSeen: '/patientroundingsheet/updatePatientRoundingSheetSeen',
        // patient notes
        getPatientRoundingSheetComments: '/patientroundingsheet/getPatientRoundingSheetComments',
        addPatientRoundingSheetComment: '/patientroundingsheet/addPatientRoundingSheetComment',
        addCensusComment: '/patientroundingsheet/addCensusComment',
        getCensusComment: '/patientroundingsheet/getCensusComment',
        deleteCensusComment: '/patientroundingsheet/deleteCensusComment',
        editCensusComment: '/patientroundingsheet/editCensusComment',
        getCensusRecentComment: '/patientroundingsheet/getCensusRecentComment',
        getPatientCommentCount: '/patientroundingsheet/getPatientCommentCount',
        getRecentNoteByCensusId: '/patientroundingsheet/getRecentNoteByCensusId',
        updatePatientRoundingSheetComment: '/patientroundingsheet/updatePatientRoundingSheetComment',
        getCensusListWithAllCounts: '/patientroundingsheet/getCensusListWithAllCounts',
        //upload census file
        uploadCensusFile: '/patientroundingsheet/uploadCensusFile',
        saveProccessedDocFileResponse: '/patientroundingsheet/saveProccessedDocFileResponse',
        generateCensusPatientListAndDownloadAsPDF: '/patientroundingsheet/generateCensusPatientListAndDownloadAsPDF',
        generateCensusWoundAndDownloadAsPDF: '/patientroundingsheet/generateCensusWoundAndDownloadAsPDF',

        updatePatientListSortOrder: '/patientroundingsheet/updatePatientListSortOrder',
        //delete all discharge patients
        updatePatientListinRoundingSheet: '/patientroundingsheet/updatePatientListinRoundingSheet',
        updatePatientRoundingSheetInactive: '/patientroundingsheet/updatePatientRoundingSheetInactive',
        addPatientinActiveReason: '/patientroundingsheet/addPatientinActiveReason',
        getCensusAllPatientsMissingVisit: '/patientroundingsheet/getCensusAllPatientsMissingVisit',
        getCensusAllPatientsReasons: '/patientroundingsheet/getCensusAllPatientsReasons',
        deleteAllMovedPatientsinRoundingSheet: '/patientroundingsheet/deleteAllMovedPatientsinRoundingSheet',
        refreshCensusPCCPatients: '/patientroundingsheet/refreshCensusPCCPatients',
        getUserEnabledMips: '/patientroundingsheet/getUserEnabledMips',
        getSelectedMipData: '/patientroundingsheet/getSelectedMipData',
        getPatientMipsStatus: '/patientroundingsheet/getPatientMipsStatus',
        downloadAllCensusData: '/patientroundingsheet/downloadAllCensusData',
        createSkinSubRefferal: '/patientroundingsheet/createSkinSubRefferal'
    },
    CENSUS: {
        getCensusList: '/census/getCensusList',
        getCensusDetail: '/census/getCensusDetail',
        getCensusWoundReport: '/census/getCensusWoundReport',
    },
    REHAB_REPORT: {
        getGoalStatusReport: '/rehabReport/getGoalStatusReport'
    },
    RELEASE: {
        getNotes: '/releaseNotes/getNotes',
        addNotes: '/releaseNotes/addNotes',
        editNote: '/releaseNotes/editNote',
        deleteNote: '/releaseNotes/deleteNote'
    },
    PATIENT_DOCUMENT: {
        uploadPatientDocument: '/patientdocument/uploadPatientDocument',
        uploadDocumentForScrubbing : '/patientdocument/uploadDocumentForScrubbing',
        updatePatientDocument: '/patientdocument/updatePatientDocument',
        tagTextFromPdf : '/patientdocument/tagTextFromPdf',
        getPatientDocument: '/patientdocument/getPatientDocument',
        getPatientDocuments: '/patientdocument/getPatientDocuments',
        getPatientDocumentsAndIntakeForms: '/patientdocument/getPatientDocumentsAndIntakeForms',
        getPatientDocs: '/patientdocument/getPatientDocs',
        deletePatientDoc : '/patientdocument/deletePatientDoc',
        generateViewPdfUrl: '/patientdocument/generateViewPdfUrl',
        getTaggedText : '/patientdocument/getTaggedText',
        removeTaggedText: '/patientdocument/removeTaggedText',
    },
    PATIENT_INTAKE_FORM: {
        savePatientIntakeFormData: '/patientintakeform/savePatientIntakeFormData',
        // getPatientIntakeForm: '/patientintakeform/getPatientIntakeForm',
        getPIFDetail: '/patientintakeform/getPIFDetail',

        sendAuthCodeToPatient: '/patientintakeform/sendAuthCodeToPatient',
        verifyAuthCode: '/patientintakeform/verifyAuthCode',
    },
    DOCTOR_PROFILE: {
        saveProfile: '/doctorprofile/saveProfile',
        getProfile: '/doctorprofile/getProfile',
        saveGeneralDetails: '/doctorprofile/saveGeneralDetails',
        getGeneralDetails: '/doctorprofile/getGeneralDetails',
        saveTimeSchedule: '/doctorprofile/saveTimeSchedule',
        getTimeSchedule: '/doctorprofile/getTimeSchedule',
        getTimeScheduleList: '/doctorprofile/getTimeScheduleList',
        saveFeeStructure: '/doctorprofile/saveFeeStructure',
        getFeeStructure: '/doctorprofile/getFeeStructure',
        saveDoctorDocumentFile: '/doctorprofile/saveDoctorDocumentFile',
        updateDoctorDocumentFile: '/doctorprofile/updateDoctorDocumentFile',
        getDoctorDocumentFile: '/doctorprofile/getDoctorDocumentFile',
        getDoctorDocumentFiles: '/doctorprofile/getDoctorDocumentFiles',
        getDoctorFacilities: '/doctorprofile/getDoctorFacilities',
    },
    COMPANYASSOSIATION: {
        getAllCompaniesWithCompanyAssosiation: '/CompanyCompany/getAllCompaniesWithCompanyAssosiation',
        saveCompanyCompanyRelation: '/CompanyCompany/saveCompanyCompanyRelation',
        updateCompanyAssociation: '/CompanyCompany/updateCompanyAssociation',
    },
    COMPANY: {
        getCompanyUsers: '/company/getCompanyUsers',
        getCompany: '/company/getCompany',
        getCompanyName: '/company/getCompanyName',
        getAllPermissions: '/company/getAllPermissions',
        modifyCompany: '/company/modifyCompany',
        getSignature: '/company/getSignature',
        generateMeetingLink: '/company/generateMeetingLink',
        getAppointmentColumnSetting: '/company/getAppointmentColumnSetting',
        updateAppointmentColumnSetting: '/company/updateAppointmentColumnSetting',
        getCompanyFacilities: '/company/getCompanyFacilities',
        getAssociatedCompanyId: '/company/getAssociatedCompanyId',
        getLegalAuthenticator: '/company/getLegalAuthenticator',
        adduser: '/company/adduser',
        addFacility: '/company/addFacility',
        updateFacility: '/company/updateFacility',
        updateInActiveFacility: '/company/updateInActiveFacility',
        downloadAsXLSX: '/company/downloadAsXLSX',
        exportUsersAsXLSX: '/company/exportUsersAsXLSX',
        updateFacilityContact: '/company/updateFacilityContact',
        getFacilityContacts: '/company/getFacilityContacts',
        addFacilityContact: '/company/addFacilityContact',
        updateCompany: '/company/updateCompany',
        updateFacesheetSetting: '/company/updateFacesheetSetting',
        updateIPASetting: '/company/updateIPASetting',
        updateFacesheetExtactionSetting: '/company/updateFacesheetExtactionSetting',
        updateNoteUploadingMethodSetting: '/company/updateNoteUploadingMethodSetting',
        userCreatePassword: '/company/userCreatePassword',
        createStrongPassword: '/company/createStrongPassword',
        verifyCode: '/company/verifyCode',
        approveFromAdmin: '/company/approveFromAdmin',
        resendConfirmationCode: '/company/resendConfirmationCode',
        deleteFacility: '/company/deleteFacility',
        getPayors: '/company/getPayors',
        get2FacAuth: '/company/get2FacAuth',
        verify2FacAuth: '/company/verify2FacAuth',
        approveUserActivation: '/company/approveUserActivation',
        updatePayorUsers: '/company/updatePayorUsers',
        deletePayor: '/company/deletePayor',
        getAllCompanies: '/company/getAllCompanies',
        getCompanyAssociation: '/company/getCompanyAssociation',
        getCompaniesFacilityAssociation: '/company/getCompaniesFacilityAssociation',
        exportCompaniesFacilityAssociationAsXLSX: '/company/exportCompaniesFacilityAssociationAsXLSX',
        getAllCompaniesWithCount: '/company/getAllCompaniesWithCount',
        getAllAssosiatedCompaniesWithCount: '/company/getAllAssosiatedCompaniesWithCount',
        changeCompanyActiveStatus: '/company/changeCompanyActiveStatus',
        changeCompanyCarryForwardStatus: '/company/changeCompanyCarryForwardStatus',
        getCompanyChargeandSignNoteStatus: '/company/getCompanyChargeandSignNoteStatus',
        getCompanyPermissionForTimeLogs: '/company/getCompanyPermissionForTimeLogs',
        companyChangeForUser: '/company/companyChangeForUser',
        companyChangeForFacility: '/company/companyChangeForFacility',
        ResendEmail: '/company/ResendEmail',
        getPccFacilityActivationNote: '/company/getPccFacilityActivationNote',
        setPccFacilityActivationNote: '/company/setPccFacilityActivationNote',
        saveCompanyImages: '/company/saveCompanyImages',
        saveCompanyModifier: '/company/saveCompanyModifier',
        checkPatientSearchCompanyAcrosss: '/company/checkPatientSearchCompanyAcrosss',
        changeInactiveReasonStatus: '/company/changeInactiveReasonStatus',
        viewEligibilityStatus: '/company/viewEligibilityStatus',
        getEligibilityStatus: '/company/getEligibilityStatus',
        updateCptRelation: '/company/updateCptRelation',
        updateCompanyCptRelationForModifier: '/company/updateCompanyCptRelationForModifier',
        getCompanyCPTRelations: '/company/getCompanyCPTRelations',
        getCensusColumnSetting: '/company/getCensusColumnSetting',
        updateCensusColumnSetting: '/company/updateCensusColumnSetting',
        saveCompanyReportSettings: '/company/saveCompanyReportSettings',
        saveCompanyAppointmentTypes: '/company/saveCompanyAppointmentTypes',
        saveCompanyAppointmentStatus: '/company/saveCompanyAppointmentStatus',
        updateCompanyAppointmentType: '/company/updateCompanyAppointmentType',
        saveChargeSetting: '/company/saveChargeSetting',
        getChargeSetting: '/company/getChargeSetting',
        getCompanyReportSettings: '/company/getCompanyReportSettings',
        getCompanyAppointmentTypes: '/company/getCompanyAppointmentTypes',
        getCompanyAppointmentVisitStatus: '/company/getCompanyAppointmentVisitStatus',
        getDataSetColumnsSetting: '/company/getDataSetColumnsSetting',
        updateDataSetColumnsSettings: '/company/updateDataSetColumnsSettings',
        getProcedureNoteDataSetColumnsSetting: '/company/getProcedureNoteDataSetColumnsSetting',
        updateProcedureNoteDataSetColumnsSettings: '/company/updateProcedureNoteDataSetColumnsSettings',
        getSpellCheckOption: '/company/getSpellCheckOption',
        setSpellCheckOption: '/company/setSpellCheckOption',
        getAllPOS: '/company/getAllPOS',
        updatePOSRelation: '/company/updatePOSRelation',
        updateDefaultPOS: '/company/updateDefaultPOS',
        getCPTPOSRelations: '/company/getCPTPOSRelations',
        getIEFUCodesMapping: '/company/getIEFUCodesMapping',
        updateIEFUCodesMapping: '/company/updateIEFUCodesMapping',
        saveOrganization: '/company/saveOrganization',
        deleteOrganization:'/company/deleteOrganization',
        getAllCompanyOrganizations: '/company/getAllCompanyOrganizations',
        getOrganizationUserRelation: '/company/getOrganizationUserRelation',
        updateOrganizationUserRelation: '/company/updateOrganizationUserRelation',
        copyDataSetfromOtherCompany: '/company/copyDataSetfromOtherCompany',
        getDefaultTabOrders: '/company/getDefaultTabOrders',
        updateDefaultTabOrders: '/company/updateDefaultTabOrders'
    },
    TEMP_ASSOCIATION: {
        getTempAssociationData: '/tempAssociation/getTempAssociationData',
    },
    PHRASE: {
        addPhrase: '/phrase/addPhrase',
        exportPhrasesAsXlxs: '/phrase/exportPhrasesAsXlxs',
        updatePhrase: '/phrase/updatePhrase',
        updatePhraseAssociations: '/phrase/updatePhraseAssociations',
        getPhrases: '/phrase/getPhrases',
        getDynamicPhrases: '/phrase/getDynamicPhrases',
        getDynamicPhrases_old: '/phrase/getDynamicPhrases_old',
        getWoundPhrases: '/phrase/getWoundPhrases',
        getWoundPhrases_old: '/phrase/getWoundPhrases_old',
        resolveWoundPhrase: '/phrase/resolveWoundPhrase',
        getProcedurePhrases: '/phrase/getProcedurePhrases',
        getProcedurePhrases_old: '/phrase/getProcedurePhrases_old',
        resolveProcedurePhrase: '/phrase/resolveProcedurePhrase',
        getAssociatedPhrases: '/phrase/getAssociatedPhrases',
        getPhraseAssociations: '/phrase/getPhraseAssociations',
        deletePhrase: '/phrase/deletePhrase',
        createRelationProvider2Phrase: '/phrase/createRelationProvider2Phrase',
        deleteRelationProvider2Phrase: '/phrase/deleteRelationProvider2Phrase',
        EditPhrase: '/phrase/EditPhrase',
        getPhrasesById: '/phrase/getPhrasesById',
        getPhraseByIdAndKey: '/phrase/getPhraseByIdAndKey',
        copyPhrase: '/phrase/copyPhrase',
        resolveAllergiesPhrase: '/phrase/resolveAllergiesPhrase',
        resolvePatientMedicalHistoryPhrase: '/phrase/resolvePatientMedicalHistoryPhrase',
        getUserDefinedCompanyPhrases: '/phrase/getUserDefinedCompanyPhrases',
        resolveWoundProcedurePhrase: '/phrase/resolveWoundProcedurePhrase'
    },
    NEWS: {
        getNews: '/news/getNews',
        addNews: '/news/addNews',
        updateNews: '/news/updateNews',
        deleteNews: '/news/deleteNews',
    },
    MIPS: {
        getQuestionCounts: '/mips/getQuestionCounts',
        getAllMips: '/mips/getAllMips',
        saveMipsSetting: '/mips/saveMipsSetting',
        enableCompanyMips: '/mips/enableCompanyMips',
        updateNurseCensusSettings: '/mips/updateNurseCensusSettings',
        updateNursePatientsSettings: '/mips/updateNursePatientsSettings',
        addQuestion: '/mips/addQuestion',
        updateQuestion: '/mips/updateQuestion',
        updateMIPSQuestion: '/mips/updateMIPSQuestion',
        getQuestions: '/mips/getQuestions',
        deleteQuestion: '/mips/deleteQuestion',
        calculateDenominator: '/mips/calculateDenominator',
        updateQuestionsSortOrder: '/mips/updateQuestionsSortOrder',
        saveQuestionnareResults: '/mips/saveQuestionnareResults',
        draftQuestionnareResults: '/mips/draftQuestionnareResults',
        getDraftQuestionnareResults: '/mips/getDraftQuestionnareResults',
        getQuestionnareResultsForPhrases: '/mips/getQuestionnareResultsForPhrases',
        getQuestionnareResults: '/mips/getQuestionnareResults',
        UploadMIPSDoc: '/mips/UploadMIPSDoc',
        getMipsDetails: '/mips/getMipsDetails',
        getMipReportMipGrouped: '/mips/getMipReportMipGrouped',
        getProviderGroupedMip: '/mips/getProviderGroupedMip',
        getP360Response: '/mips/getP360Response',
    },
    FEEDBACK: {
        getFeedback: '/feedback/getFeedback',
        addFeedback: '/feedback/addFeedback',
        deleteFeedback: '/feedback/deleteFeedback',
        updateFeedback: '/feedback/updateFeedback',
        acceptanceStatus: '/feedback/acceptanceStatus',
    },
    TICKET: {
        addTicket: '/ticket/addTicket',
        getTickets: '/ticket/getTickets',
        changeStatus: '/ticket/changeStatus',
        postComment: '/ticket/postComment',
        getComments: '/ticket/getComments',
        deleteComment: '/ticket/deleteComment',
        editComment: '/ticket/editComment',
        deleteTickets: '/ticket/deleteTickets',
        editTicket: '/ticket/editTicket',
        filterStatus: '/ticket/filterStatus',
        filterStatusUser: '/ticket/filterStatusUser',
        changePriority: '/ticket/changePriority',
        changePriorityUser: '/ticket/changePriorityUser',
        changeUserType: '/ticket/changeUserType',
        filterUserName: '/ticket/filterUserName',
        filtering: '/ticket/filtering'
    },
    AWS: {
        getAwsSignedUrl: '/aws/getAwsSignedUrl'
    },
    PCC: {
        goToLoginPCCScreen: '/pcc/goToLoginPCCScreen'
    },
    BILLING: {
        getBillingCompanies: '/billingcompany/getBillingCompanies',
        getDNBillingAssociation: '/billingcompany/getDNBillingAssociation',
        createBillingCompanyAssociation: '/billingcompany/createBillingCompanyAssociation',
        deleteRelation: '/billingcompany/deleteRelation',
        getBillingComapnyAssociation: '/billingcompany/getBillingComapnyAssociation',
        updateProviderExternalId: '/billingcompany/updateProviderExternalId',
        updateFacilityExternalId: '/billingcompany/updateFacilityExternalId'
    },
    WOUND: {
        addNewHeader: '/wound/addnewHeader',
        updateHeader: '/wound/updateHeader',
        updateDataSet: '/wound/updateDataSet',
        deleteHeader: '/wound/deleteHeader',
        addNewRow: '/wound/addnewRow',
        updateRow: '/wound/updateRow',
        deleteRow: '/wound/deleteRow',
        updateRowPositions: '/wound/updateRowPositions',
        getAllDataset: '/wound/getAllDataset',
        getWounds: '/wound/getWounds',
        updateWoundData: '/wound/updateWoundData',
        getChildWounds: '/wound/getChildWounds',
        uploadWoundImages: '/wound/uploadWoundImages',
        getAllWoundsParentStatesCount: '/wound/getAllWoundsParentStatesCount',
        getParentWoundsCount: '/wound/getParentWoundsCount',
        deleteWoundOtherImages: '/wound/deleteWoundOtherImages',
        getWoundTags: '/wound/getWoundTags',
        addWoundTag: '/wound/addWoundTag',
        updateWoundTag: '/wound/updateWoundTag',
        getWoundEtiologies: '/wound/getWoundEtiologies',
        addWoundEtiology: '/wound/addWoundEtiology',
        updateWoundEtiology: '/wound/updateWoundEtiology',
        getWoundEtiologyGroups: '/wound/getWoundEtiologyGroups',
        getWoundEtiologyGroupsWithTags: '/wound/getWoundEtiologyGroupsWithTags',
        addWoundEtiologyGroup: '/wound/addWoundEtiologyGroup',
        updateWoundEtiologyGroups: '/wound/updateWoundEtiologyGroups',
        getWoundEtiologyGroupDetails: '/wound/getWoundEtiologyGroupDetails',
        getWoundEtiologyGroupDetailsICDs: '/wound/getWoundEtiologyGroupDetailsICDs',
        addWoundEtiologyGroupDetails: '/wound/addWoundEtiologyGroupDetails',
        updateWoundEtiologyGroup: '/wound/updateWoundEtiologyGroup',
        updateWoundEtiologyGroupDetails: '/wound/updateWoundEtiologyGroupDetails',
        getWoundDiagnosis: '/wound/getWoundDiagnosis',
        addWoundDiagnosis: '/wound/addWoundDiagnosis',
        updateWoundDiagnosis: '/wound/updateWoundDiagnosis',
        getWoundGraphData: '/wound/getWoundGraphData',
        getProcedureTypes: '/wound/getProcedureTypes',
        addProcedureType: '/wound/addProcedureType',
        updateProcedureType: '/wound/updateProcedureType',
        getProcedureDatasets: '/wound/getProcedureDatasets',
        addProcedureDataset: '/wound/addProcedureDataset',
        updateProcedureDataset: '/wound/updateProcedureDataset',
        getPeriodicWoundReport: '/wound/getPeriodicWoundReport',
        woundHealingRateReport: '/wound/woundHealingRateReport',
        exportWoundPeriodicTreatmentReportAsXLSX: '/wound/exportWoundPeriodicTreatmentReportAsXLSX',
        exportVisitReportAsXLSX: '/wound/exportVisitReportAsXLSX',
        exportQapiReportAsXLSX: '/wound/exportQapiReportAsXLSX',
        exportQapiReportV2AsXLSX: '/wound/exportQapiReportV2AsXLSX',
        exportDebridementReportAsXLSX: '/wound/exportDebridementReportAsXLSX',
        exportWoundHealingRateReportAsXLSX: '/wound/exportWoundHealingRateReportAsXLSX',
        exportMissedFacilityReportAsXLSX: '/wound/exportMissedFacilityReportAsXLSX',
        exportDnsReportAsXLSX: '/wound/exportDnsReportAsXLSX',
        getDnsHealingReportV2: '/wound/getDnsHealingReportV2',
        getPostRoundsReport: '/wound/getPostRoundsReport',
        exportDnsHealingReportV2AsXLSX: '/wound/exportDnsHealingReportV2AsXLSX',
        exportPostRoundsReport: '/wound/exportPostRoundsReport',
        exportDnsHealingReportAsXLSX: '/wound/exportDnsHealingReportAsXLSX',
        exportDnsHealingReportAsPDF: '/wound/exportDnsHealingReportAsPDF',
        getQapiReportV2: '/wound/getQapiReportV2',
        getUWHTabularReport: '/wound/getUWHTabularReport',
        getUWHTabularReportV2: '/wound/getUWHTabularReportV2',
        exportUWHTabularReportV2AsXLSX: '/wound/exportUWHTabularReportV2AsXLSX',
        getDnsReportV2: '/wound/getDnsReportV2',
        exportWounUWHTabularReportAsXLSX: '/wound/exportWounUWHTabularReportAsXLSX',
        exportDischargePatientReportAsXLSX: '/wound/exportDischargePatientReportAsXLSX',
        getDnsReport: '/wound/getDnsReport',
        getDnsHealingReport: '/wound/getDnsHealingReport',
        getHpOperationalReport: '/wound/getHpOperationalReport',
        getPressureWoundReport: '/wound/getPressureWoundReport',
        getSummaryPressureReport: '/wound/getSummaryPressureReport',
        getQapiReport: '/wound/getQapiReport',
        getVisitReport: '/wound/getVisitReport',
        getMissedFacilityReport: '/wound/getMissedFacilityReport',
        getWoundDebridementReport: '/wound/getWoundDebridementReport',
        getDischargedPatientReport: '/wound/getDischargedPatientReport',
        generateDnsWeeklyExcelReport: '/wound/generateDnsWeeklyExcelReport',
        getDnsWeeklyReportJobStatus: '/wound/getDnsWeeklyReportJobStatus',
        getDnsWeeklyReport: '/wound/getDnsWeeklyReport',
        downloadDnsWeeklyReport: '/wound/downloadDnsWeeklyReport',
        deleteDnsWeeklyReportJob: '/wound/deleteDnsWeeklyReportJob',
        getWoundNotes: '/wound/getWoundNotes',
        exportWoundNotesReportAsXLSX: '/wound/exportWoundNotesReportAsXLSX',
        exportDnsReportV2AsXLSX: '/wound/exportDnsReportV2AsXLSX',
        exportQuarterlyQapiReportAszip: '/wound/exportQuarterlyQapiReportAszip',
        addNewUWHCode: '/wound/addNewUWHCode',
        deleteUWHCode: '/wound/deleteUWHCode',
        getAllUWHCode: '/wound/getAllUWHCode',
        addNewUWHCodewithCPTCode: '/wound/addNewUWHCodewithCPTCode',
        getProcedureTypeswithCPTRelation: '/wound/getProcedureTypeswithCPTRelation',
        addNewProcedureCodeRelation: '/wound/addNewProcedureCodeRelation',
        removeProcedureCodeRelation: '/wound/removeProcedureCodeRelation',
        addNewCPTCode: '/wound/addNewCPTCode',
        exportAsPdf: '/wound/exportAsPdf',
        generateZipReportForAllWounds: '/wound/generateZipReportForAllWounds',
        addDotphraseDate: '/wound/addDotphraseDate',
        removeDotphraseDate: '/wound/removeDotphraseDate',
        getProviderWounds: '/wound/getProviderWounds',
        generateReportForAllWounds: '/wound/generateReportForAllWounds',
        getSystemDefinedPhraseTemplates: '/wound/getSystemDefinedPhraseTemplates',
        saveSystemDefinedPhraseTemplate: '/wound/saveSystemDefinedPhraseTemplate',
        getSystemDefinedPhraseTemplateByPhraseKey: '/wound/getSystemDefinedPhraseTemplateByPhraseKey',
        getQapiReportCount: '/wound/getQapiReportCount',
        getKentVisitReport: '/wound/getKentVisitReport',
        exportChargesReportAsXLSX: '/wound/exportChargesReportAsXLSX',
        generateReportandUploadtoPCC: '/wound/generateReportandUploadtoPCC',
        downloadGroupWoundReport: '/wound/downloadGroupWoundReport',
        getAllWoundDataforGroupReport: '/wound/getAllWoundDataforGroupReport',
        generateSingleReportandUploadtoPCC: '/wound/generateSingleReportandUploadtoPCC',
        changeMultipleWoundsState: '/wound/changeMultipleWoundsState',
        moveWoundToCensus: '/wound/moveWoundToCensus',
        generateWoundReportUploadToPcc: '/wound/generateWoundReportUploadToPcc',
        generateWoundReportUploadToMatrix: '/wound/generateWoundReportUploadToMatrix',
        addNewWoundAssessment: '/wound/addNewWoundAssessment',
        uploadWoundReportsinBulk: '/wound/uploadWoundReportsinBulk',
        checkWoundDatesToSignNote: '/wound/checkWoundDatesToSignNote',
        downloadWoundImagesZip: '/wound/downloadWoundImagesZip',
        fetchWounds: '/wound/fetchWounds',
        exportPressureWoundReportAsXLSX: '/wound/exportPressureWoundReportAsXLSX',
        exportCustomWoundReportAsXLSX: '/wound/exportCustomWoundReportAsXLSX',
        exportWoundExceptionReportAsXLSX: '/wound/exportWoundExceptionReportAsXLSX',
        getWoundExceptionsReport: '/wound/getWoundExceptionsReport',
        getPatientsForWoundExceptionsReport: '/wound/getPatientsForWoundExceptionsReport',
        getDaysToRemissionReport: '/wound/getDaysToRemissionReport',
        getWoundOutcomeReport: '/wound/getWoundOutcomeReport',
        exportHpOperationalReportAsXLSX: '/wound/exportHpOperationalReportAsXLSX',
        getPatientswithActiveWounds: '/wound/getPatientswithActiveWounds',
        cloneWound: '/wound/cloneWound',
        getWoundfromParentSchema: '/wound/getWoundfromParentSchema',
        lockWoundManually: '/wound/lockWoundManually',
        addWound: '/wound/addWound',
        getDaysToRemissionAugmentation: '/wound/getDaysToRemissionAugmentation',
        swapColumns: '/wound/swapColumns',
        copyAllDatasetfromHP: '/wound/copyAllDatasetfromHP',
        updateEtiologiesOrder: '/wound/updateEtiologiesOrder',
    },
    WOUND_ENTERPRISE_REPORT: {
        addWoundEnterpriseReport: '/woundEnterpriseReport/addWoundEnterpriseReport',
        getWoundEnterpriseReportById: '/woundEnterpriseReport/getWoundEnterpriseReportById',
        updateWoundEnterpriseReport: '/woundEnterpriseReport/updateWoundEnterpriseReport',
        deleteWoundEnterpriseReport: '/woundEnterpriseReport/deleteWoundEnterpriseReport',
        getWoundEnterpriseReports: '/woundEnterpriseReport/getWoundEnterpriseReports',
    },
    EMAIL_TEMPLATE: {
        createEmailTemplate: '/emailTemplate/createEmailTemplate',
        updateEmailTemplate: '/emailTemplate/updateEmailTemplate',
        deleteEmailTemplate: '/emailTemplate/deleteEmailTemplate',
        getEmailTemplate: '/emailTemplate/getEmailTemplate',
        sendEmailTemplate: '/emailTemplate/sendEmailTemplate',
        getEmail: '/emailTemplate/getEmail',
        updateEmail: '/emailTemplate/updateEmail',
        deleteEmail: '/emailTemplate/deleteEmail',
    },
    CPT: {
        getAllCPTs: '/cpt/getAllCPTs',
        getICDs: '/cpt/getAllICDs',
        addRelation: "/cpt/addRelation",
        removeRelation: "/cpt/removeRelation",
        getCPTsForTemplates: '/cpt/getCPTsForTemplates',
        getAllCompanyCPTs: '/cpt/getAllCompanyCPTs',
        createCptTemplateRelation: '/cpt/createCptTemplateRelation',
        deleteCptTemplateRelation: '/cpt/deleteCptTemplateRelation',
        updateCpt: '/cpt/updateCpt'
    },
    ICD: {
        updateIcd: '/charge/updateICD',
        icdProviderAssociation: '/charge/icdProviderAssociation',
        findProviderAssociatedIcds: '/charge/findProviderAssociatedIcds',
        removeIcdProviderAssociation: '/charge/removeIcdProviderAssociation',
        getSuperAdminAssociatedIcds: '/charge/getSuperAdminAssociatedIcds'
    },
    DATA_SET: {
        addNewHeader: '/dataset/addnewHeader',
        updateHeader: '/dataset/updateHeader',
        deleteHeader: '/dataset/deleteHeader',
        addNewRow: '/dataset/addnewRow',
        updateRow: '/dataset/updateRow',
        deleteRow: '/dataset/deleteRow',
        getAllDataset: '/dataset/getAllDataset'
    },
    QUERY_REPORT: {
        createGeneralReport: '/queryReport/createGeneralReport',
        getGeneralReport: '/queryReport/getGeneralReport',
        getQueryReport: '/queryReport/getQueryReport',
        createQueryReport: '/queryReport/createQueryReport',
        updateQueryReport: '/queryReport/updateQueryReport',
        updateGeneralReport: '/queryReport/updateGeneralReport',
        deleteQueryReport: '/queryReport/deleteQueryReport',
        executeQueryReport: '/queryReport/executeQueryReport',
    },
    ELASTIC_SEARCH: {
        searchICDCode: "/elasticSearch/searchICDCode",
        searchCPTCode: "/elasticSearch/searchCPTCode",
        searchICDCodewithPagination: "/elasticSearch/searchICDCodewithPagination"
    },
    MATRIXCARE_MIRTH: {
        saveMatrixCarePatients: "matrixCare/saveMatrixCarePatients",
        getCCD: '/matrixCare/getCCD',
        matrixPatientExtract: '/matrixCare/matrixPatientExtract',
        hl7data: '/matrixCare/hl7data',
        mergeMatrixPatient: '/matrixCare/mergeMatrixPatient',
        importMatrixPatient: '/matrixCare/importMatrixPatient'
    },
    SIGMACARE_MIRTH: {
        saveSigmaCarePatients: "sigmaCare/saveSigmaCarePatients"
    },
    MERGE_PATIENTS: {
        searchThePatient: "/mergePatients/searchThePatient",
        searchThePatientOnly: "/mergePatients/searchThePatientOnly",
        mergeThePatient: "/mergePatients/mergeThePatient",
        mergeThePatientData: "/mergePatients/mergeThePatientData",
        revertThePatient: "/mergePatients/revertThePatient",
        getMergingAudit: "/mergePatients/getMergingAudit",
        searchThePatientById: "/mergePatients/searchThePatientById"
    },
    MERGE_FACILITY: {
        searchTheFacility: "/mergeFacility/searchTheFacility",
        mergeTheFacility: "/mergeFacility/mergeTheFacility",
        revertTheFacility: "/mergeFacility/revertTheFacility",
        getMergingAudit: "/mergeFacility/getMergingAudit",
        mergeTheFacilitySingle: "/mergeFacility/mergeTheFacilitySingle",
        revertTheFacilitySingle: "/mergeFacility/revertTheFacilitySingle",
        mergeTheFacilitySingle_v2: "/mergeFacility/mergeTheFacilitySingle_v2",
        revertTheFacilitySingle_v2: "/mergeFacility/revertTheFacilitySingle_v2",
        mergeTheFacilitySingle_v2_PCC2Matrix: "/mergeFacility/mergeTheFacilitySingle_v2_PCC2Matrix",
        revertTheFacilitySingle_v2_PCC2Matrix: "/mergeFacility/revertTheFacilitySingle_v2_PCC2Matrix",
        mergeTheFacilitySingle_v2_pcc2dn: "/mergeFacility/mergeTheFacilitySingle_v2_pcc2dn",
        revertTheFacilitySingle_v2_pcc2dn: "/mergeFacility/revertTheFacilitySingle_v2_pcc2dn",
        mergeTheFacilitySingle_v2_pcc2pcc: "/mergeFacility/mergeTheFacilitySingle_v2_pcc2pcc",
        revertTheFacilitySingle_v2_pcc2pcc: "/mergeFacility/revertTheFacilitySingle_v2_pcc2pcc",
        revertTheFacilitySingle_v2_Dn2Matrix: "/mergeFacility/revertTheFacilitySingle_v2_Dn2Matrix",
        mergeTheFacilitySingle_v2_Dn2Matrix: "/mergeFacility/mergeTheFacilitySingle_v2_Dn2Matrix",
        mergePaientAndInactive: "/mergeFacility/mergePaientAndInactive",
        getExistingPatientFromPcc: "/mergeFacility/getExistingPatientFromPcc",
        getExistingDuplicateWithInitialNote: "/mergeFacility/getExistingDuplicateWithInitialNote",
        getDuplicateNamePatients: "/mergeFacility/getDuplicateNamePatients",
        mergePatientsDataForNameChange: "/mergeFacility/mergePatientsDataForNameChange",

    },
    ERROR_LOGS: {
        getAllErrorLogs: '/logs/getAllErrorLogs',
        getSelectedLogs: '/logs/getSelectedLogs'
    },
    ICD_SUGGESTION: {
        getICDSuggestions: '/icdSuggestion/getICDSuggestions',
        uploadNote: '/icdSuggestion/uploadNote',
    },
    FACE_SHEET: {
        uploadFaceSheet: '/faceSheet/uploadFaceSheet',
        deleteFaceSheet: '/faceSheet/deleteFaceSheet',
        getFacesheet: '/faceSheet/getFacesheet'
    },
    CERT: {
        uploadXML: '/certifications/uploadXML',
        extractPatientFromXML: '/certifications/extractPatientFromXML',

        uploadFilesForCertification: '/certifications/uploadFilesForCertification',
        extractData: '/certifications/extractData',
        checkData: '/certifications/checkData',
        getMIPs: '/certifications/getMIPs',
    },
    COMMUNICATION: {
        addCommunicationComment: '/communication/addCommunicationComment',
        getCommunicationComment: '/communication/getCommunicationComment',
        deleteCommunicationComment: '/communication/deleteCommunicationComment',
        editCommunicationComment: '/communication/editCommunicationComment',
    },
    CENSUS_CALENDER: {
        getCensuses: '/census_calender/getCensuses',
    },
    CENSUS_RECURRENCE: {
        getRecurrentCensuses: '/census_recurrrence/getRecurrentCensuses',
        addNewRecurrentCensuses: '/census_recurrrence/addNewRecurrentCensuses',
        updateRecurrentCensuses: '/census_recurrrence/updateRecurrentCensuses',
        updatePatientinCensuses: '/census_recurrrence/updatePatientinCensuses',
        addNewRecurrentCensusesWithParent: '/census_recurrrence/addNewRecurrentCensusesWithParent'
    },
    NOTIFICATION: {
        getAllNotifications: '/notifications/getAllNotifications',
        getNotifications: '/notifications/getNotifications',
        getUnseenNotificationsCount: '/notifications/getUnseenNotificationsCount',
        makeNotificationsSeen: '/notifications/makeNotificationsSeen',
        getLastNotifications: '/notifications/getLastNotifications',
        addNotification: '/notifications/addNotification'
    },
    THERAPY: {
        getCensusDetailsReport: '/therapy/getCensusDetailsReport',
        getGoalStatusReport: '/therapy/getGoalStatusReport',
        getWeeklyFunctionalStatusReport: '/therapy/getWeeklyFunctionalStatusReport',
        getTensReport: '/therapy/getTensReport',
        getPatientTensReport: '/therapy/getPatientTensReport',
        getPatientEvalReport: '/therapy/getPatientEvalReport',
    },
    FACILITY_SETTING: {
        addOne: '/facilitySetting/addOne',
        updateById: '/facilitySetting/updateById',
        getById: '/facilitySetting/getById',
    },
    SnfWcNurse: {
        getAssociatedSnfWcNurses: '/snfWcNurse/getAssociatedSnfWcNurses',
        createSnfWcNurseRelation: '/snfWcNurse/createSnfWcNursesRelation',
        createManySnfWcNurseRelations: '/snfWcNurse/createManySnfWcNurseRelations',
        deleteManySnfWcNurseRelations: '/snfWcNurse/deleteManySnfWcNurseRelations',
        deleteSnfWcNurseRelation: '/snfWcNurse/deleteSnfWcNursesRelation',
    },
    WoundNurse: {
        getWoundNurseProviderRelations: '/woundNurse/getWoundNurseProviderRelations',
        updateWoundNurseProviderRelation: '/woundNurse/updateWoundNurseProviderRelation',
        getWoundNurseFacilityAssociations: '/woundNurse/getWoundNurseFacilityAssociations',
        getWoundNurseFacilityRelations: '/woundNurse/getWoundNurseFacilityRelations',
        updateWoundNurseFacilitiesRelation: '/woundNurse/updateWoundNurseFacilitiesRelation',
        getWoundNurseFacilities: '/woundNurse/getWoundNurseFacilities',
    },
    viewOnlyUser: {
        getViewOnlyUsersProviderRelation: '/viewOnlyUser/getViewOnlyUsersProviderRelation',
        updateViewOnlyUsersProviderRelation: '/viewOnlyUser/updateViewOnlyUsersProviderRelation',
        getAssociatedProvidersListForViewOnlUsers: '/viewOnlyUser/getAssociatedProvidersListForViewOnlUsers'
    },
    SnfWcNurseFacilities: {
        getAssociatedSnfWcNurseFacilities: '/snfWcNurseFacilities/getAssociatedSnfWcNurseFacilities',
        createSnfWcNurseFacilitiesRelation: '/snfWcNurseFacilities/createSnfWcNurseFacilitiesRelation',
        deleteSnfWcNurseFacilitiesRelation: '/snfWcNurseFacilities/deleteSnfWcNurseFacilitiesRelation'
    },
    GoogleAuthenticator: {
        setupGoogle2FA: '/google2FA/setupGoogle2FA',
        verifyGoogle2FA: '/google2FA/verifyGoogle2FA',
        disableGoogle2FA: '/google2FA/disableGoogle2FA'
    },
    DERM: {
        addNewDataSet: '/derm/addNewDataSet',
        getAllDataSet: '/derm/getAllDataSet',
        updateDataSet: '/derm/updateDataSet'
    },
    MERGE_FACILITIES: {
        searchTheFacilityById: "/mergeFacility/searchTheFacilityById",
        mergeTheFacilityData: "/mergeFacility/mergeTheFacilityData",
        revertTheFacilityData: "/mergeFacility/revertTheFacilityData",
    },
    MOVE_WOUND: {
        getWoundById: "/moveWound/getWoundById",
        getAllCensus: "/moveWound/getAllCensus",
        getAllPatientsByCensusId: "/moveWound/getAllPatientsByCensusId",
        moveWoundToPatient: "/moveWound/moveWoundToPatient",
    },
    CUSTOM_WOUND_REPORT: {
        addCustomWoundReport: '/customWoundReport/addCustomWoundReport',
        getCustomWoundReport: '/customWoundReport/getCustomWoundReport',
        updateCustomWoundReport: '/customWoundReport/updateCustomWoundReport'
    },
    EXTRACTED_NOTE_TEXT: {
        getExtractedNoteText: '/extractedNoteText/getExtractedNoteText',
        addNoteTextSearchHistory: '/extractedNoteText/addNoteTextSearchHistory',
        getNoteTextSearchHistory: '/extractedNoteText/getNoteTextSearchHistory',
    },
    CONSENT: {
        updateConsentForm: '/consent/updateConsentForm',
        getConsentForms: '/consent/getConsentForms',
        createConsentForm: '/consent/createConsentForm',
        deleteConsentForm: '/consent/deleteConsentForm',
        createConsentFormAuditAndUpload: '/consent/createConsentFormAuditAndUpload',
        getConsentAudit: '/consent/getConsentAudit',
    },
    PROCEDURE_NOTES: {
        getProcedureNoteDataset: '/procedureNotes/getProcedureNoteDataset',
        addProcedureNoteDataset: '/procedureNotes/addProcedureNoteDataset',
        updateProcedureNoteDataset: '/procedureNotes/updateProcedureNoteDataset',
        deleteProcedureNoteDataset: '/procedureNotes/deleteProcedureNoteDataset',
        getProcedureNotesData: '/procedureNotes/getProcedureNotesData',
        addProcedureNotesData: '/procedureNotes/addProcedureNotesData',
        updateProcedureNotesData: '/procedureNotes/updateProcedureNotesData',
        deleteProcedureNotesData: '/procedureNotes/deleteProcedureNotesData',
        getCalculatedProcedureCPTs: '/procedureNotes/getCalculatedProcedureCPTs'
    },
};
