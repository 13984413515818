import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as global from '../global';
import { API_URL } from "../../../environments/api-url";
import { AuthService } from "../../services/auth.service";
import { BehaviorSubject } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { DashboardService } from '../dashboard/dashboard.service';
@Injectable({
    providedIn: 'root',
})
export class CensusListService {
    private filterProviderId = new BehaviorSubject<string>("");
    castFilterProviderId = this.filterProviderId.asObservable();
    private filterFacilityId = new BehaviorSubject<string>("");
    castFilterFacilityId = this.filterFacilityId.asObservable();

    currentUser: any;
    companySide: boolean=false;
    constructor(private httpclient: HttpClient,
        private commonService: CommonService,
        private authService: AuthService,
        private dashboardService: DashboardService) {
        this.currentUser = this.authService.currentUser;
        if(window.location.pathname.includes('company')){
            this.companySide = true}
    }

    changeFilterProviderId(providerId) {
        this.filterProviderId.next(providerId)
    }

    changeFilterFacilityId(facilityId) {
        this.filterFacilityId.next(facilityId)
    }

    addPatientRoundingSheet(details) {
        details.company_id = this.currentUser.company_id;
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.addPatientRoundingSheet, { details }).pipe()
    }

    updatePatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.updatePatientRoundingSheet, { details }).pipe()
    }

    deletePatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.deletePatientRoundingSheet, { details }).pipe()
    }

    getUserEnabledMips(userId) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getUserEnabledMips, { providerId: userId }).pipe()
    }

    getSelectedMipData(censusData, company_id, providerId, mipData) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getSelectedMipData, { censusData, company_id, providerId, mipData }).pipe()
    }

    getPatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getPatientRoundingSheet, { details }).pipe()
    }

    getPatientRoundingSheets(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getPatientRoundingSheets, { details }).pipe()
    }
    getCensusList(facility_id?, provider_id?, limit?, only_pcc = false) {
        const details = {
            ...this.authService.authObject,

            facility_id,
            provider_id,
            limit,
            only_pcc
        }
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getCensusList, { details }).pipe()
    }
    getCensusListWithAllCounts(facility_id?, provider_id?, limit?, only_pcc = false, censusStatus='true') {
        const details = {
            ...this.authService.authObject,

            facility_id,
            provider_id,
            limit,
            only_pcc,
            censusStatus,
        }
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getCensusListWithAllCounts, { details }).pipe()
    }

    getCensusListWithNotesAndChargesCount(facility_id?, provider_id?, limit?, only_pcc = false, status = "true", skip?) {
        let company_id=null;
        if(!this.companySide){
             company_id=this.currentUser.company_id;
        }
        const details = {
            ...this.authService.authObject,

            facility_id,
            provider_id,
            limit,
            only_pcc,
            company_id: company_id,
            status,
            skip
        }
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getCensusListWithNotesAndChargesCount, { details }).pipe()
    }
    getRehabDocs(census_id) {
        const details = {
            ...this.authService.authObject,

            census_id,
        }
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getRehabDocs, { details }).pipe()
    }
    getRehabDocsCount(census_id) {
        const details = {
            ...this.authService.authObject,

            census_id,
        }
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getRehabDocsCount, { details }).pipe()
    }
    addPatientToPatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.addPatientToPatientRoundingSheet, { details }).pipe()
    }

    removePatientFromPatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.removePatientFromPatientRoundingSheet, { details }).pipe()
    }

    updatePatientRoundingSheetSeen(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.updatePatientRoundingSheetSeen, { details }).pipe()
    }
    getChargeCountData(details) {
        return this.httpclient.post(global.url + API_URL.CHARGE.getChargesCount, { details }).pipe();
    }
    getsavedSignedCount(details) {
        return this.httpclient.post(global.url + API_URL.NOTE.getsavedSignedCount, { details }).pipe();
    }

    // charge services
    addCharge(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.addCharge,
                { details: details });
    }
    getCharges(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getCharges,
                { details: details });
    }
    getCharge(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getCharge,
                { details: details });
    }
    addICD(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.addICD,
                { details: details });
    }
    getICDs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getICDs,
                { details: details }).pipe(
                    map((response: any) => {
                        if (response.status === 200) {
                            response.data.array.map(icd => {
                                if (icd.end_date) {
                                    const [month, day, year] = icd.end_date.split("/");
                                    if (moment(`${year}-${month}-${day}`).isBefore(moment())) {
                                        icd.isExpired = true;
                                    }
                                }
                                return icd;
                            });
                            response.data.array = response.data.array.filter(icd => icd.isExpired == undefined)
                            return response;
                        }
                    })
                );
                ;
    }
    getCPTs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getCPTs,
                { details: details });
    }
    setFavoriteICDs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.setFavoriteICDs,
                { details: details });
    }
    setFavoriteCPTs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.setFavoriteCPTs,
                { details: details });
    }
    getFavoriteICDs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getFavoriteICDs,
                { details: details });
    }
    getFavoriteCPTs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getFavoriteCPTs,
                { details: details });
    }

    downloadGroupWoundReport(census, isZip){
        let currUser: any = JSON.parse(localStorage.getItem("currentUser"));
        console.log("currUser", currUser);
        // const headers = new HttpHeaders();
        // headers.append('Access-Control-Allow-Headers', 'Content-Length');
        // headers.append('Access-Control-Expose-Headers', 'Content-Length');
        // headers.append('Content-Type', 'application/octet-stream');
        const data = {
            ...this.authService.authObject,
            census,
            state: 'active',
            userId: currUser._id,
            company_id: currUser.company_id,
            isDownload: true, isZip
        };
        return this.httpclient.post(global.url + API_URL.WOUND.downloadGroupWoundReport, data );
    }
    downloadAllCensusData(census_id, date_of_service, patientList, timeZone){
        let currUser: any = JSON.parse(localStorage.getItem("currentUser"));
        const data = {
            census_id,
            date_of_service,
            patientList,
            timeZone,
            company_id: currUser.company_id
        };
        return this.dashboardService.downloadFile(global.url + API_URL.PATIENT_ROUNDING_SHEET.downloadAllCensusData, data).pipe();
    }
}
