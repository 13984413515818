import { Injectable } from '@angular/core';
import * as global from '../includes/global';
import moment from 'moment'
import { KeyValue } from '@angular/common';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import isValid from 'is-valid-path';
import { Buffer } from 'buffer';

interface TimeZone {
    text: string;
    value: string;
}
@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private companySelected = new BehaviorSubject(null);
    public castCompany = this.companySelected.asObservable();

    private facilitySelected = new BehaviorSubject(null);
    public castFacility = this.facilitySelected.asObservable();

    private draftedCharge = new BehaviorSubject(null);
    public castDraftedCharge = this.draftedCharge.asObservable();

    private savedICD = new BehaviorSubject(null);
    public castSavedICD = this.savedICD.asObservable();

    private savedCPT = new BehaviorSubject(null);
    public castSavedCPT = this.savedCPT.asObservable();

    public updatedUsername = new Subject();
    public castUpdatedUsername = this.updatedUsername.asObservable();

    private patientHeaderMenu = new Subject<any[]>();
    public castPatientHeaderMenu: Observable<any[]> = this.patientHeaderMenu.asObservable();

    private notificationSettings = new Subject<boolean>();
    public castNotificationSettings: Observable<boolean> = this.notificationSettings.asObservable();

    private selectedUserType = new BehaviorSubject(null);
    public castselectedUserType = this.selectedUserType.asObservable();
    private readonly patientMenuStorageKey = 'customPatientMenuItems';
    formattedTimezones: TimeZone[] = [];
    constructor() {
        this.formattedTimezones = moment.tz.names().map(zone => {
            const offset = moment.tz(zone).utcOffset() / 60;
            const offsetSign = offset >= 0 ? '+' : '';
            const formattedOffset = `${offsetSign}${offset}:00`;
            return {
                text: `(UTC${formattedOffset}) ${zone}`,
                value: zone
            };
        });
    }

    setSeletedUserType(user_type) {
        this.selectedUserType.next(user_type);
        console.log('user_type', user_type);
    }
    sanitizeInput(input: any) {
        const objectIdPattern = /^[0-9a-fA-F]{24}$/;
        if (!objectIdPattern.test(input)) {
            console.log('Invalid Input ID');
            return false;
        }
        return input;
    }
    sanitizeObjectInput(object: any, arrayOfValuesToCheck: any[]) {
        const objectIdPattern = /^[0-9a-fA-F]{24}$/;
        const sanitizedDetails = {};
        for (const key in object) {
            if (arrayOfValuesToCheck.includes(key) && !objectIdPattern.test(object[key])) {
                sanitizedDetails[key] = false;
                console.log('Invalid Input ID');
            } else {
                sanitizedDetails[key] = object[key];
            }
        }
        return sanitizedDetails;
    }
    isValidPath(input: any) {
        if (!isValid(input)) {
            console.log('Invalid Input');
            return false;
        }
        return input;
    }

    setUpdatedUsername(updatedUser) {
        this.updatedUsername.next(updatedUser);
    }
    setPatientHeaderMenu(menuItems: any[]): void {
        this.patientHeaderMenu.next(menuItems);
    }
    setNotificationSettings(): void {
        this.notificationSettings.next(true);
    }
    getUserProfilePic() {
        return this.updatedUsername ? this.updatedUsername : 'https://www.w3schools.com/howto/img_avatar.png';
    }

    setDraftedCharge(charge) {

        this.draftedCharge.next(charge);
    }
    setSavedICD(charge) {

        this.savedICD.next(charge);
    }
    setSavedCPT(charge) {
        this.savedCPT.next(charge);

    }


    setCompanySelected(company) {
        this.companySelected.next(company);

    }

    setFacilitySelected(folderId) {
        this.facilitySelected.next(folderId);

    }

    currentDate() {
        return moment.utc().toDate();
    }

    attachTimeToDate(date, dateTime?) {

        const dateStr = moment(date).format('YYYY-MM-DD');
        const timeStr = (dateTime ? moment(dateTime) : moment()).format('HH:mm');

        let newDate = moment(dateStr);
        const time = moment(timeStr, 'HH:mm');

        newDate.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: time.get('second')
        });
        return newDate.toDate();
    }
    cleanTranscriptionTitle(str) {
        let result = str;
        const pattern = /^(\d+)_(.*)/;
        const regexExec = pattern.exec(str);

        if (regexExec && regexExec.length > 1 && regexExec[2].length) {
            result = regexExec[2];
        }
        return result.replace(/_/g, ' ');
    }

    getUserFullName(user) {
        return user ? user.first_name + ' ' + user.last_name : '';
    }

    getPatientFullName(patient) {
        let patientName = patient.first_name;
        if (patient.middle_name) {
            patientName += ' ' + patient.middle_name;
        }
        patientName += ' ' + patient.last_name;
        return patientName;
    }

    getPatientFullNameFormat2(patient) {
        let patientName = patient.last_name + ', ';
        patientName += patient.first_name;
        if (patient.middle_name && patient.middle_name.length) {
            patientName += ' ' + patient.middle_name[0].toUpperCase() + '.';
        }
        return patientName;
        // if (patientName.length > 15)
        //     { 
        //         let patientTitle = '';
        //         patientTitle = patientName.substr(0,15);
        //         return patientTitle+'...'
        //     } 
        //     else { 
        //         return patientName;
        //     } 
    }

    getFacilityTitle(facility) {
        return facility ? facility.title : '';
    }

    dateRangeToString(d1: moment.Moment, d2: moment.Moment) {
        const dateFormat = 'MMM DD, YYYY';
        if (d1 && d2 && d1.isValid() && d2.isValid()) {
            const formatD1 = d1.format(dateFormat);
            const formatD2 = d2.format(dateFormat);
            return formatD1 == formatD2 ? formatD1 : `${formatD1} - ${formatD2}`;
        }
    }

    flattenObjectToArray(ob: Object): Array<any> {
        let arr: Array<any> = [];
        Object.keys(ob).forEach(function (key) {
            arr = [...arr, ...ob[key]];
        });
        return arr;
    };

    keyDescOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
        let valueA: any = a.key;
        let valueB: any = b.key;
        const momentA = moment(new Date(valueA));
        const momentB = moment(new Date(valueB));
        if (momentA.isValid() && momentB.isValid()) {
            valueA = momentA.toDate().getTime();
            valueB = momentB.toDate().getTime();
            return valueB - valueA;
        }
        return valueA > valueB ? -1 : (valueB > valueA ? 1 : 0);
    };
    keyAscOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
        let valueA: any = a.key;
        let valueB: any = b.key;
        const momentA = moment(new Date(valueA));
        const momentB = moment(new Date(valueB));
        if (momentA.isValid() && momentB.isValid()) {
            valueA = momentA.toDate().getTime();
            valueB = momentB.toDate().getTime();
            return valueA - valueB;
        }
        return valueA > valueB ? 1 : (valueB > valueA ? -1 : 0);
    };

    showCount(count: number, max: number = 99) {
        return (count > max) ? `${max}+` : count;
    }
    toTitleCase(str) {
        str = str || '';
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    dateObjToString_calenderFormat(date_obj) {

        let { year, month, day, hour = 12, minute = 0 } = date_obj;
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minute < 10) {
            minute = '0' + minute;
        }
        // let str = `${year}-${month}-${day}T${hour}:${minute}:00.000Z`;
        // let str = `${year}-${month}-${day}T01:00:00.000Z`;
        let str = `${year}-${month}-${day}T01:00`;



        return str;
    }

    dateObjToString_format2(date_obj, shouldAppendTime = true) {

        let { year, month, day, hour = 0, minute = 0 } = date_obj;
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minute < 10) {
            minute = '0' + minute;
        }
        let str = `${year}-${month}-${day}`;
        if (shouldAppendTime) {
            str += ` ${hour}:${minute}`;
        }
        return str;
    }

    dateObjToString(date_obj, shouldAppendTime = true) {

        let { year, month, day, hour = 0, minute = 0 } = date_obj;
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minute < 10) {
            minute = '0' + minute;
        }
        let str = `${month}/${day}/${year}`;
        if (shouldAppendTime) {
            str += ` ${hour}:${minute}`;
        }
        return str;
    }
    dateObjToDate(date_obj) {
        return moment({
            year: date_obj.year,
            month: date_obj.month - 1,
            day: date_obj.day,
            hour: date_obj.hour,
            minute: date_obj.minute
        }).toDate();
    }
    isValidDateObj(date_obj) {
        if (date_obj) {
            let { year, month, day, hour, minute } = date_obj;
            year = parseInt(year);
            month = parseInt(month);
            day = parseInt(day);
            // check if any is NaN
            if (isNaN(year) || isNaN(month) || isNaN(day)) {
                return false;
            }
        }
        return true;
    }
    convertDateToStringforMoment(date, shouldAppendTime = false, shouldAppendTimezone = false, isCptExpiryDate = false) {
        const days = date.date();
        const months = date.month() + 1;
        const years = date.year();
        const hours = date.hour();
        const minutes = date.minute();
        const seconds = date.second();
        let month = months.toString();
        let day = days.toString();
        let hr = hours.toString();
        let min = minutes.toString();
        let sec = seconds.toString();
        if (month.length === 1) {
            month = "0" + month;
        }
        if (day.length === 1) {
            day = "0" + day;
        }
        if (hr.length === 1) {
            hr = "0" + hr;
        }
        if (min.length === 1) {
            min = "0" + min;
        }
        if (sec.length === 1) {
            sec = "0" + sec;
        }
        let dateString;
        if (isCptExpiryDate)
            dateString = `${month}/${day}/${years}`;
        else
            dateString = `${years}-${month}-${day}`;

        if (shouldAppendTime) {
            dateString = `${years}-${month}-${day} ${hr}:${min}:${sec}`;
        }
        if (shouldAppendTimezone) {
            dateString = `${years}-${month}-${day} ${hr}:${min}:${sec} ${date.format("Z")}`;
        }
        return dateString;
    }
    splitMomentToObj(momentObj) {
        const { date: days, months, years, hours, minutes, seconds } = momentObj.toObject();
        const month = months + 1;
        return {
            year: years,
            month,
            day: days,
            hour: hours,
            minute: minutes
        };
    }
    setLocalStorageItem(componentName: string, obj: any = {}) {
        const existingObj = JSON.parse(localStorage.getItem(`dn-${componentName}`)) || {};
        const concatenatedObj = { ...existingObj, ...obj };
        localStorage.setItem(`dn-${componentName}`, JSON.stringify(concatenatedObj));
    }
    getLocalStorageItem(componentName: string) {
        return JSON.parse(localStorage.getItem(`dn-${componentName}`)) || null;
    }
    removeLocalStorageItem(componentName: string) {
        localStorage.removeItem(`dn-${componentName}`);
    }

    sortArray(arr: any[], key: string) {
        return arr.sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()));
    }
    validateComment(comment) {
        // Check if the comment contains special characters, including spaces
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<> \n\\\/\[\]\-~_`]/.test(comment);

        // Check if the comment contains alphabets or numbers
        const hasAlphabetsOrNumbers = /[a-zA-Z0-9]/.test(comment);

        // If the comment contains special characters (including spaces), it should also contain alphabets or numbers to be valid
        // If there are no special characters (including spaces), the comment is considered valid
        return hasSpecialChar ? hasAlphabetsOrNumbers : true;
    }
    generateUniqueString(userCompany) {
        if (userCompany.indexOf(' ') >= 0) {
            var matches = userCompany.match(/\b(\w)/g);
            var acronym = matches.join('');
            // return acronym.toLowerCase() + Math.floor(100000 + Math.random() * 900000);
            // return acronym.toLowerCase() + Date.now().toString().slice(-6);
            return acronym.toLowerCase() + Date.now().toString();
        } else {
            return userCompany.toLowerCase() + Date.now().toString().slice(-6);
        }
    }
    getPatientMenu(): any[] {
        const dataString = localStorage.getItem(this.patientMenuStorageKey);
        return dataString ? JSON.parse(dataString) : [];
    }

    setPatientMenu(data: any[]): void {
        const dataString = JSON.stringify(data);
        localStorage.setItem(this.patientMenuStorageKey, dataString);
    }
    updateObject(updatedObject: any): void {
        const data = this.getPatientMenu();
        const index = data.findIndex(obj => obj._id === updatedObject._id);
        if (index !== -1) {
            data[index] = updatedObject;
            this.setPatientMenu(data);
        }
    }
    removeObject(objectId: string): void {
        const data = this.getPatientMenu();
        const index = data.findIndex(obj => obj._id === objectId);
        if (index !== -1) {
            data.splice(index, 1);
            this.setPatientMenu(data);
            this.setPatientHeaderMenu(data)
        }
    }
    addObjectIfNotExists(newObject: any): void {
        const data = this.getPatientMenu();
        const exists = data.some(obj => obj._id === newObject._id);
        if (!exists) {
            data.push(newObject);
            this.setPatientMenu(data);
            this.setPatientHeaderMenu(data)
        }
    }
    getLastDOSFromMenuArray(): string {
        const data = this.getPatientMenu();
        if (data.length > 0) {
          const lastEntry = data[data.length - 1];
          return lastEntry.date_obj;
        } else {
          return null; // Return an empty string if the array is empty
        }
    }
    getLastProviderFromMenuArray(): string {
        const data = this.getPatientMenu();
        if (data.length > 0) {
          const lastEntry = data[data.length - 1];
          return lastEntry.provider_id;
        } else {
          return ''; // Return an empty string if the array is empty
        }
    }
    getLastFacilityFromMenuArray(): string {
        const data = this.getPatientMenu();
        if (data.length > 0) {
          const lastEntry = data[data.length - 1];
          return lastEntry.facility_id;
        } else {
          return ''; // Return an empty string if the array is empty
        }
    }

    convertToBlobAndDownload(buffer, title: string, fileExtension: string) {
        const type = {
            pdf: 'application/pdf',
            zip: 'application/zip',
        };
        // const uint8Array = new Uint8Array(buffer);
        // const dataString: any = new TextDecoder('utf-8').decode(uint8Array);
        const blob = new Blob([Buffer.from(buffer)], { type: type[fileExtension] });
        // const blob = new Blob([dataString], { type: type[fileExtension] });
        const pdfUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `${title}.${fileExtension}`;
        link.click();
    }
    sortProviderDropdown(providers){
        providers.sort((a,b) => {
          if(a.first_name.toLowerCase() < b.first_name.toLowerCase()) { return -1; }
          if(a.first_name.toLowerCase() > b.first_name.toLowerCase()) { return 1; }
        });
      }
    getDifferenceOfDates(sDate, eDate, differenceUnit) {
        const startDate = moment(new Date(sDate));
        const endDate = moment(new Date(eDate));
        let difference = endDate.diff(startDate, differenceUnit);
        return difference;
    }

    downloadFile(url, filename) {
        var a = document.createElement( 'a' );
        document.body.appendChild( a );

        a.setAttribute( 'style', 'display: none' );
        a.href = url;
        a.download = filename;

        a.click();
        window.URL.revokeObjectURL( url );
        a.remove();
    }
    getFormattedTimeZones(): TimeZone[] {
        return this.formattedTimezones;
    }
}
