import {NotifyType} from "./notify-type";

export class Appointment {
    public _id: string;
    public id: string;
    public title: string;
    public start: Date;
    public startTime: string;
    public allDay: boolean;
    public admin_id: string;
    public patient_id: any;
    public provider_id: any;
    public facility_id: any;
    public payor_id: any;
    public title_id: any;
    public is_video: boolean;
    public notify_time: number;
    public notify_type: NotifyType;
    public dos: Date = new Date();
    public tos: Date;
    
    public patient_email: string;
    public guest_emails: [string];
    public created_at?:Date;

    public email_patient_intake_form?:boolean;
    
    constructor() {
    }
}
