import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-alphabetic-index-bar',
  templateUrl: './alphabetic-index-bar.component.html',
  styleUrls: ['./alphabetic-index-bar.component.css']
})
export class AlphabeticIndexBar implements OnInit {
  indexes:Array<string>;

  @Output('onIndexButtonClick') onIndexButtonClick = new EventEmitter();
  @Input('activeIndex') activeIndex:string = 'All';
  constructor() { 
    this.indexes = ['All','A', 'B', 'C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
  }

  ngOnInit() {
  }

  handleIndexButtonClick(index) {
    console.log('handleIndexButtonClick', {
      index
    });
    
    this.activeIndex = index;
    this.onIndexButtonClick.emit(index);
  }
}
