import { Component, OnInit, Input, OnDestroy, Optional, ChangeDetectionStrategy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PatientDocumentService } from './patient-document-list.service';
import { ToastrService } from 'ngx-toastr';
import * as global from '../global'
import { MatDialog } from '@angular/material/dialog';
import { PatientDocumentsUploadDialog } from './patient-documents-upload-dialog/patient-documents-upload-dialog.component'
import { ImageEditorComponent } from '../note-sidebar/wound-care-widget/image-editor/image-editor.component';
import { FileExplorerService } from '../drive/file-explorer/file-explorer.service';
import { PdfViewerPopComponent } from './pdf-viewer-pop/pdf-viewer-pop.component';
import { AuthService } from 'src/app/services/auth.service';
import { FaceSheetService } from '../patient-detail/facesheet/face-sheet.service';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { result } from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription, lastValueFrom } from 'rxjs';
import { WoundCareWidgetService } from '../note-sidebar/wound-care-widget/wound-care-widget.service';
import { PATIENT_DOCUMENT_TYPE, PatientDocumentType } from '../../classes/patient-document.model';
import { UPLOAD_DOCUMENT_TYPE } from 'src/app/classes/document-upload.model';

// import { UserCallService } from 'src/app/components/twilio/user-call/user-call.service';
@Component({
  selector: 'app-patient-documents-list',
  templateUrl: './patient-documents-list.component.html',
  styleUrls: ['./patient-documents-list.component.css'],
})

export class PatientDocumentsListComponent implements OnInit, OnDestroy {
  global = global;
  @Input() patient: any;
  patientDocuments = [];
  file_path: any;
  pdf_file_path:any;
  file_index:number=-1;
  files_length:number=0;
  displayedColumns = ['document_title', 'type', 'uploaded_at', 'operations'];
  PATIENT_DOCUMENT_TYPE = PATIENT_DOCUMENT_TYPE;
  dataSource: any;

  activePayload;

  view: '' | 'patient-intake-form' | 'patient-document-view' = '';

  subscriptions = [];

  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  patient_side: Boolean=false;
  loadingFile: boolean=false;
  selectedFileFormat: any;
  currentUser: any;
  loaderId: string = "app-patient-documents-list";
  isTeleHealthReport: boolean = false;
  telehealthReportSubscription: Subscription;
  documentTypes = Object.entries(PATIENT_DOCUMENT_TYPE);
  constructor(
    private patientDocumentService: PatientDocumentService,
    private _faceSheetService: FaceSheetService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private fileExplorerService: FileExplorerService,
    private authService: AuthService,
    private loader: NgxUiLoaderService,
    private _woundCareWidgetService: WoundCareWidgetService
    // private userCallService: UserCallService
  ) {
    if(window.location.pathname.includes('patient')){
      this.patient_side=true;
    }else{
      this.patient_side=false;
    }
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.authService.currentUser;
    this.subscriptions.push(this._woundCareWidgetService.isTeleHealthReport$.subscribe(value => {
      this.isTeleHealthReport = value;
      if (this.isTeleHealthReport)
        this.patient_side = true;
    }));
    if (this.patient) {
      await this.initPatientDocuments()
    }
    //   this.subscriptions.push(this.userCallService.subViewUpdates.subscribe((data:any) => {
    //     // this.view = data.subView;

    //     // if(this.view === 'patient-intake-form') {
    //     //     this.activePayload = this.userCallService.patientIntakeFormId;
    //     // } 
    //     if(this.userCallService.patientIntakeFormId) {
    //       this.view = 'patient-intake-form';
    //       this.activePayload = this.userCallService.patientIntakeFormId;
    //     } else if(this.userCallService.patientDocument) {
    //       this.view = 'patient-document-view';
    //       this.activePayload = this.userCallService.patientDocument;
    //     } 
    //     console.log({
    //       'this.view': this.view,
    //       'this.activePayload':  this.activePayload
    //     });

    // }));

  }
  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      console.log('subscription.unsubscribe');

      subscription.unsubscribe();
    }
  }

  getFileType(type: string) {
    return UPLOAD_DOCUMENT_TYPE[type];
  }

  async initPatientDocuments() {
    this.loader.startLoader(this.loaderId);
    const company_id = this.authService.currentUser.company_id;
    const response: any = await lastValueFrom(this.patientDocumentService.getPatientDocumentsAndIntakeForms(this.patient._id, company_id));
    if (response.status === 200) {
      this.patientDocuments = response.data.array;
      this.getFileFormat(this.patientDocuments);
      this.dataSource = new MatTableDataSource(this.patientDocuments);
      this.files_length = this.patientDocuments?.length || 0;
    } else {
      this.toastr.error('Something went wrong please try again.')
    }
    this.loader.stopLoader(this.loaderId);
  }
  getItemIcon(title) {
    const extension = title.substr(title.lastIndexOf('.') + 1);
    const filetypeIconsPath = '../../../assets/img/filetypes';

    return (`${filetypeIconsPath}/${extension}.svg`);
  }
  viewPDF(element, index?) {
    this.file_index=index;
    this.loadingFile=true;
    if(element.aws_path && element?.aws_path.includes("http")){
      this.file_path = element.aws_path;
      this.selectedFileFormat=element.fileFormat;

    this.loadingFile=false;

      if(this.patient_side){
        this.dialog.open(PdfViewerPopComponent, {
          width: '50vw',
          height: '95vh',
          data: {
            file_path: this.file_path
          }
        });
      }
    }else{
      this.patientDocumentService.generateViewPdfUrl(element).subscribe((response: any) => {
        this.loadingFile=false;
        if (response.status === 200) {
          this.patientDocuments.forEach((doc)=> {
            if(doc._id === element._id){
              doc.aws_path = response.document.aws_path;
              doc.title = response.document.title;
              this.file_path = response.document.aws_path;
            }
          });
          this.getFileFormat(this.patientDocuments);
          this.selectedFileFormat=element.fileFormat;
          this.dataSource = new MatTableDataSource(this.patientDocuments);
          if(this.patient_side){
            this.dialog.open(PdfViewerPopComponent, {
              width: '50vw',
              height: '95vh',
              data: {
                file_path: this.file_path
              }
            });
          }
        } else {
          this.toastr.error('Something went wrong please try again.')
        }
      }, () => this.toastr.error('Something went wrong please try again.')
      )
    }
  }
  async downLoadPdfFiles(element) {
    console.log(element);
    if (element.aws_path && element?.aws_path.includes("http")) {
      await this.downloadFile(element);
    } else {
      this.patientDocumentService.generateViewPdfUrl(element).subscribe(async (response: any) => {
        this.loadingFile = false;
        if (response.status === 200) {
          this.patientDocuments.forEach((doc) => {
            if (doc._id === element._id) {
              doc.aws_path = response.document.aws_path;
              doc.title = response.document.title;
              this.pdf_file_path = response.document.aws_path;
            }
          });
          this.getFileFormat(this.patientDocuments);
          this.selectedFileFormat = element.fileFormat;
          this.dataSource = new MatTableDataSource(this.patientDocuments);
          // if (this.patient_side) {
          //   this.dialog.open(PdfViewerPopComponent, {
          //     width: '50vw',
          //     height: '95vh',
          //     data: {
          //       file_path: this.file_path
          //     }
          //   });
          // }
          await this.downloadFile(element);
        } else {
          this.toastr.error('Something went wrong please try again.')
        }
      }, () => this.toastr.error('Something went wrong please try again.')
      )
    }
  }
  async downloadFile(element) {
    const infoToast = this.toastr.info('Preparing file to download...', 'Please wait', { disableTimeOut: true });
    try {
      const downloadFileResponse: any = await this.fileExplorerService.downLoadAnyFile(element.aws_path, element.title).toPromise();
      var url = window.URL.createObjectURL(downloadFileResponse);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = <string>element.title;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      this.toastr.error('Unable to download file.', 'Failed');
    } finally {
      this.toastr.clear(infoToast.toastId);
    }
  }

  showPatientsDialog(typeOfDocument: PatientDocumentType) {
    const dialogRef = this.dialog.open(
      PatientDocumentsUploadDialog,
      { width: '80%', data: { patient: this.patient } }
    );
    dialogRef.beforeClosed().subscribe(async (fileInput: any) => {
      if (fileInput) {
        this.loader.startLoader(this.loaderId);

        const [file] = fileInput.files;
        const infoToast = this.toastr.info('Uploading Document...', 'Please wait')

        try {
          const company_id = this.authService.currentUser.company_id;
          if (typeOfDocument === "faceSheet") {
            this._faceSheetService.uploadFaceSheet(file, this.patient._id, company_id).subscribe((response: any) => {
              if (response.data) {
                this.initPatientDocuments();
              }
              else {
                console.error(response.data.status, response.data.message);
                this.toastr.error(response.data.message, 'Failed');
              }
            });
          }
          else {
            this.patientDocumentService.uploadPatientDocument(file, this.patient._id, company_id, typeOfDocument).subscribe((response: any) => {
              if (response.data) {
                this.initPatientDocuments()
              }
              else {
                this.toastr.error(response.data.message, 'Failed');
              }
            });
          }
        } catch (error) {
          this.toastr.error('Something went wrong please try again', 'Failed');
        } finally {
          this.loader.stopLoader(this.loaderId);
          this.toastr.clear(infoToast.toastId);
        }
      }
    });
  }
  goToPatientIntakeForm(patientIntakeForm) {

  }
  getFileFormat(docs) {
    if (docs.length > 0) {
      for (const doc of docs) {
        const lastIndexOfDot = doc.title.lastIndexOf('.')
        if (lastIndexOfDot >= 0) {
          doc.fileFormat = doc.title.substr(lastIndexOfDot + 1).toLowerCase();
        }
      }
    }
  }
  viewPatientIntakeForm(patientIntakeFormId) {
    // this.userCallService.patientIntakeFormId = patientIntakeFormId;
    // this.userCallService.subViewUpdateData = 'patient-intake-form';
  }
  viewPatientDocument(patientDocument) {
    // this.userCallService.patientDocument = patientDocument;
    // this.userCallService.subViewUpdateData = 'patient-document-view';
  }
  handlePreviousClick(){
    const newIndex=this.file_index-1;
    const element=this.patientDocuments[newIndex];
    if(newIndex>=0&&newIndex<=(this.files_length-1)){
      // if(element.fileFormat === 'pdf' || element.fileFormat === 'txt'){
        this.viewPDF(element, newIndex);
      // }
    }
  }
  handleNextClick(){
    const newIndex=this.file_index+1;
    const element=this.patientDocuments[newIndex];
    if(newIndex>=0&&newIndex<=(this.files_length-1)){
      // if(element.fileFormat === 'pdf' || element.fileFormat === 'txt'){
        this.viewPDF(element, newIndex);
      // }
    }
  }
  handleGoBackClick() {
    this.view = '';
    // this.userCallService.patientIntakeFormId = null;
    // this.userCallService.patientDocument = null;
    this.file_index=0;
    this.file_path=null;
    this.activePayload = null;
  }
  viewImageDoc(element, index?) {
    if(this.patient_side){
      if(element.aws_path && element?.aws_path.includes("http")){
        this.selectedFileFormat=element.fileFormat;
        this.file_path = element.aws_path;
      this.loadingFile=false;
          const dialogRef = this.dialog.open(ImageEditorComponent, {
            width: 'auto',
            data: {
              src: element.aws_path,
              editor: false
            }
          });
        
      }else{
        this.patientDocumentService.generateViewPdfUrl(element).subscribe((response: any) => {
          this.loadingFile=false;
          if (response.status === 200) {
            this.patientDocuments.forEach((doc)=> {
              if(doc._id === element._id){
                doc.aws_path = response.document.aws_path;
                doc.title = response.document.title;
                this.file_path = response.document.aws_path;
              }
            });
            this.getFileFormat(this.patientDocuments);
            this.selectedFileFormat=element.fileFormat;
  
            this.dataSource = new MatTableDataSource(this.patientDocuments);
            const dialogRef = this.dialog.open(ImageEditorComponent, {
              width: 'auto',
              data: {
                src: element.aws_path,
                editor: false
              }
            });
          } else {
            this.toastr.error('Something went wrong please try again.')
          }
        }, () => this.toastr.error('Something went wrong please try again.')
        )
      }

    }else{
      this.viewPDF(element, index);
    }
  }
  deletePatientDoc(index, patientDoc) {
    if (patientDoc.patient_id) {
      let dialogRef = this.dialog.open(DialogConfirmationComponent,{
       data: {
        message: 'Are you sure you want to delete?'
       } 
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === "true") {
          this._faceSheetService.deleteFaceSheet(patientDoc._id).subscribe((response: any) => {
            if (response.status === 200) {
              this.deleteFromTable(index);
              this.toastr.success("Face Sheet Deleted", 'Success');
            }
            else {
              this.toastr.error(response.data.message, 'Failed');
            }
          });
        }
    })
    }
    else {
      let dialogRef = this.dialog.open(DialogConfirmationComponent, {
        data : {
          message: 'Are you sure you want to delete?'
        }
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result === "true") {
          const response = await lastValueFrom(this.patientDocumentService.updatePatientDocument({ _id: patientDoc._id }, { is_active: false }));
          if (response['status'] === 200) {
            this.deleteFromTable(index);
            this.toastr.success("Patient Document Deleted", 'Success');
          }
          else {
            this.toastr.error(response['data']['message'], 'Failed');
          }
        }
      });
    }
  }
  deleteFromTable(index) {
    this.dataSource.data = [];
    this.patientDocuments.splice(index, 1);
    this.dataSource.data = this.patientDocuments;
    this.files_length--;

  }
  getDocTitle(title){
    if(title.includes("sftp")){
      let titleObj = title.split("/");
      return titleObj[titleObj.length -1];
    }else{
      return title;
    }
  }
}
