export interface IPatientCoverage {
    ins_order? : Number,
    tin? : String,
    insured_id?: String,
    insured_name?: String,
    insured_address ?: String ,
    insured_relation_to_patient?: String ,
    insured_dob?: String,
    insurance_company_id?: String,
    insurance_company_name?: String, 
    insurance_company_address?: String ,
    insurance_company_phone_number?: String , 
    group_number?: String,
    group_name ?: String ,
    insured_group_employer_id?: String , 
    insured_group_employer_name?: String ,
    insurance_carrier?: String,
    insurance_holder_name?: String,
    insurance_policy_number?: String, 
    insurance_plan_id?: String ,
    plan_type?: String,
    plan_effective_date?: String | DateObject,
    plan_expiration_date?:  String | DateObject,
    identifier?: String,
    alternate_id?: String,
  }
   export function setCoverageValues(patientCoverage: IPatientCoverage): IPatientCoverage {
    patientCoverage.ins_order = 1;
    patientCoverage.tin= '';
    patientCoverage.insured_id = '';
    patientCoverage.insured_name = '';
    patientCoverage.insured_relation_to_patient= '';
    patientCoverage.insured_address= '';
    patientCoverage.insured_dob = null;
    patientCoverage.insurance_plan_id = '';
    patientCoverage.insurance_company_address = '';
    patientCoverage.insurance_company_phone_number = '';
    patientCoverage.group_name = '';
    patientCoverage.insured_group_employer_id = '';
    patientCoverage.insured_group_employer_name = '';
    patientCoverage.plan_effective_date = '';
    patientCoverage.plan_expiration_date = '';
    patientCoverage.plan_type = '';
    patientCoverage.insurance_carrier = '';
    patientCoverage.insurance_holder_name = '';
    patientCoverage.insurance_policy_number = '';
    patientCoverage.insurance_company_id = '';
    patientCoverage.insurance_company_name = '';
    patientCoverage.group_number = '';
    patientCoverage.group_name = '';
    patientCoverage.identifier = '';
    patientCoverage.alternate_id = '';
      return patientCoverage;
  }
  
  interface DateObject {
    year: Number | String,
    month:  Number | String,
    day:  Number | String,
  }
  