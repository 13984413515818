import { Component, Inject, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientDocumentService } from '../patient-document-list.service';

// UPLOAD FILE DIALOG
export interface PatientDocumentsUploadDialogData {
  patient
}

@Component({
  selector: 'app-patient-documents-upload-dialog',
  templateUrl: './patient-documents-upload-dialog.component.html',
  styleUrls: ['./patient-documents-upload-dialog.component.css']
})
export class PatientDocumentsUploadDialog implements OnInit {
  patient;
  emptyError=false;
  constructor(
    public dialogRef: MatDialogRef<PatientDocumentsUploadDialog>,
    @Inject(MAT_DIALOG_DATA) public data: PatientDocumentsUploadDialogData,
    private patientDocumentListService: PatientDocumentService) {
    if (data) {
      this.patient = data.patient
    }
  }

  ngOnInit(): void {
  }
}
