import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';
import { API_URL } from 'src/environments/api-url';
import * as global from '../../includes/global';

@Injectable({
  providedIn: 'root'
})
export class DoctorProfileService {

  constructor(private http: HttpService) { }

  getProfile(filter, projection) {
    const details = {
      filter,
      projection
    }
    return this.http
        .post(global.url + API_URL.DOCTOR_PROFILE.getProfile, details);
  }
  saveProfile(data,supervisorProviderDetails) {
    const details = {
      ...data,
      supervisorProviderDetails
    }
    return this.http
        .post(global.url + API_URL.DOCTOR_PROFILE.saveProfile, details);
  }
  getGeneralDetails(filter, projection) {
    const details = {
      filter,
      projection
    }
    return this.http
        .post(global.url + API_URL.DOCTOR_PROFILE.getGeneralDetails, details);
  }
  saveGeneralDetails(data) {
    const details = {
      ...data
    }
    return this.http
        .post(global.url + API_URL.DOCTOR_PROFILE.saveGeneralDetails, details);
  }
  getTimeSchedule(filter, projection) {
    const details = {
      filter,
      projection
    }
    return this.http
        .post(global.url + API_URL.DOCTOR_PROFILE.getTimeSchedule, details);
  }
  getTimeScheduleList(filter) {
    const details = {
      filter
    }
    return this.http
        .post(global.url + API_URL.DOCTOR_PROFILE.getTimeScheduleList, details);
  }
  saveTimeSchedule(data) {
    const details = {
      ...data
    }
    return this.http
        .post(global.url + API_URL.DOCTOR_PROFILE.saveTimeSchedule, details);
  }
  getFeeStructure(filter, projection) {
    const details = {
      filter,
      projection
    }
    return this.http
        .post(global.url + API_URL.DOCTOR_PROFILE.getFeeStructure, details);
  }
  saveFeeStructure(data) {
    const details = {
      ...data
    }
    return this.http
        .post(global.url + API_URL.DOCTOR_PROFILE.saveFeeStructure, details);
  }
  getDoctorFacilities(filter, projection){
    const details = {
      filter,
      projection
    }
    return this.http.post(global.url + API_URL.DOCTOR_PROFILE.getDoctorFacilities , details)
  }

}