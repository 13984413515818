<h3>{{editPatient ? "Edit" : "Add New"}} Patient</h3>
<br />
<div class="flex flex-col gap-2">
  <app-patient-add-form
  [selectedDoctorId]="selectedDoctorId"
  [selectedFacilityId]="selectedFacilityId"
  [showPhoneField]="showPhoneField"
  [showEmailField]="showEmailField"
  (onSaveClick)="onSaveClick($event)"
  (onCancelClick)="onCancelClick($event)"
  ></app-patient-add-form>
  
  <div class="top-right-close-btn">
      <button mat-mini-fab mat-dialog-close ><mat-icon >cancel</mat-icon></button>
    </div>
</div>