<div class="flex justify-center items-center">
    <h1 mat-dialog-title style="color: black">Add New Signature </h1>
</div>
<div mat-dialog-content>
    <form (ngSubmit)="addSignature(file_uploader)" #form="ngForm">
        <div  class="flex flex-col" style="margin-bottom: 20px">

            <mat-form-field class="flex-1" appearance="fill">
                <mat-label>Select Provider</mat-label>
                <mat-select required [(ngModel)]="selectedProviderAdd" [ngModelOptions]="{standalone: true}">
                    <mat-option (click)="selectProvider()">--none--</mat-option>
                    <mat-option *ngFor="let provider of providers" [value]="provider"  (click)="selectProvider(provider)">
                        {{provider.first_name}} {{provider.last_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <input hidden (change)="file_uploader_btn.nativeElement.blur()"  accept=".pdf" type="file" id="file_uploader" #file_uploader />
            {{file_uploader.value && file_uploader.value}}
            <button #file_uploader_btn  class="dn-button-primary" mat-raised-button (click)="file_uploader.click()"
            style="width: fit-content; align-self: center; margin-bottom: 20px;margin-top: 10px"> Add Signature </button>

        </div>
        <div class="flex flex-row justify-center items-center gap-2">
            <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="button">Cancel</button>
            <button mat-raised-button
                    [disabled]="!form.form.valid"
                    type="submit"                 
                    class="dn-button-primary"> Save </button>
        </div>
    </form>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
