import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { TitleService } from "src/app/services/title.service";
import { ToastrService } from "ngx-toastr";

// CREATE NEW / RENAME FOLDER DIALOG
export interface NoteTypeDialogData {
    // action:String // create, rename 
    title:String
  }
  @Component({
    selector: 'note-type-dialog',
    templateUrl: './note-type-dialog.component.html',
    styleUrls: ['./note-type-dialog.css']
  })
  export class NoteTypeDialog {
    title:String="";
    constructor(
      public dialogRef: MatDialogRef<NoteTypeDialog>,
      @Inject(MAT_DIALOG_DATA) public data: NoteTypeDialogData,
      private titleService:TitleService,
      private toastr:ToastrService) {
        if(data && data.title) {
          this.title = data.title;
        }      
      }

      addTitle() {
        this.titleService.addNewTitle(this.title).subscribe((response:any) => {
          this.dialogRef.close(response.data);
        }, () => this.toastr.error('Something went wrong', 'Failed'))
      }
  }
