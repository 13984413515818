<div class="flex flex-col gap-2 w-full" style="height: auto; margin: top 30px; margin: bottom 10px;">
  <div class="flex flex-col gap-2 w-full" style=" margin: bottom 6px;">


    <div *ngIf="from_patient||from_pcc_patient" class="flex w-full">

      <div class="flex w-2/5">
        <mat-label style=" padding-right: 5px; align-self: center;">DOS:
          {{date_obj?.month+'/'+date_obj?.day+'/'+date_obj?.year}}</mat-label>

      </div>

      <div class="flex w-3/5">
        <mat-form-field [ngStyle]="{display: true ? 'block' : 'none'}" class="flex-grow">
          <input matInput type="text" name="numberOfWeeks" placeholder="Census Name" required [(ngModel)]="census_name">

          <mat-error *ngIf="!selectedWeeks">Please select valid number </mat-error>
        </mat-form-field>
      </div>

    </div>




    <div class="flex w-full">
      <div class="flex w-2/5">
        <mat-label style=" padding-right: 20px; align-self: center;">Repeat every week for </mat-label>
      </div>
      <div class="flex w-3/5">
        <mat-form-field [ngStyle]="{display: true ? 'block' : 'none'}" class="flex-grow">
          <mat-select name="numberOfWeeks" placeholder="Number of Weeks" required [(value)]="default_active"
            (selectionChange)="handleCensusRecurrence($event.value)">


            <mat-option *ngFor="let number of weeks" [value]="number">
              {{number}}
            </mat-option>

          </mat-select>
          <mat-error *ngIf="!selectedWeeks">Please select valid number </mat-error>
        </mat-form-field>
      </div>
    </div>



  </div>

  <div class="flex flex-col gap-2 w-full" style="margin-bottom:12px;">

    <div *ngFor="let Weekitem of recurrenceMap; let weekindex=index" class="flex gap-2" style="flex:95%;">
      <span style="align-self: center; width:72px"> Week {{weekindex+1}}</span>

      <div class="flex gap-2 w-full" *ngIf="Weekitem.status=='active'">
        <div class="flex gap-5 w-full" *ngFor="let Dayitem of Weekitem.week; let dayindex=index">
          <button type="button" style="cursor: pointer; border-radius: 22px; padding: 10px 14px; font-size:15px;"
            [disabled]="!Dayitem.is_active" [ngStyle]="{
                    backgroundColor: Dayitem.selected ?'cornflowerblue':  isSelectedDay(Dayitem.date_obj)?'cornflowerblue':'whitesmoke', 
                                color: Dayitem.selected ? 'white': 'black' }"
            (click)="dayofWeekSelected(weekindex, dayindex)">{{Dayitem.day}}<br>
            <p style="font-size:12px; margin:-2px; padding-top:2px;">{{Dayitem?.date_obj?.month+'/'+Dayitem?.date_obj?.day||'-'}}</p>
          </button>
        </div>
      </div>
    </div>


  </div>

  <div *ngIf="!from_pcc_patient" class="flex w-full justify-end items-bottom">
    <button style="margin-top:14px !important" mat-raised-button class="dn-button-primary"
      (click)="handleSubmit($event)">Save</button>

  </div>

  <div *ngIf="from_pcc_patient" class="flex w-full justify-end items-bottom">
    <button style="margin-top:14px !important" mat-raised-button class="dn-button-primary"
      (click)="handleSubmit($event, true)">Save</button>

  </div>

  <div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close (click)="handleCancel($event)">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>