import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { TemplateExplorerService } from '../../templates/template-explorer/template-explorer.service';
import { TranscriptionCreateService } from '../../transcription-create/transcription-create.service';
import * as global from '../../global';
import moment from 'moment'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { SnfWcNurseAssociationService } from '../../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-admin-side-templates',
  templateUrl: './admin-side-templates.component.html',
  styleUrls: ['./admin-side-templates.component.css']
})
export class AdminSideTemplatesComponent implements OnInit {
  selectedProvider: any;
  selectedFacility: any;
  company_id: any;
  templatesLoaded: Boolean = false;
  systemSideCreate: Boolean = false;
  providers: any = [];
  facilities: any = [];
  allTemplates: any = [];
  global: any = global;
  searchTextProvider: any;
  searchText: any;
  loaderId = 'loader-01';
  templateTypes = [
    {
      type: 'system_defined',
      label: 'System Defined'
    },
    {
      type: 'user_defined',
      label: 'User Defined'
    },
  ];
  is_locked: boolean = false;
  selectedType: any = this.templateTypes[0].type;
  cpts: any;
  routingToTemplateCreateComponent: boolean = false;
  company_type: any = 'Wound';
  constructor(
    public services: UserTypesRelationshipService,
    public route: ActivatedRoute,
    public router: Router,
    private _transcriptionCreateService: TranscriptionCreateService,
    protected templateExplorerService: TemplateExplorerService,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    protected dialog: MatDialog,
    private commonService: CommonService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private userService: UserService,


  ) { }

  ngOnInit(): void {
    if (window.location.pathname.includes('company')) {
      const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
      if(currentCompany) {
        this.company_type = currentCompany.currentCompanyType;
      }
      this.company_id = this.route.parent.snapshot.params.id;
      const templateType = localStorage.getItem('templateType');
      if(templateType){
        if (templateType == 'user_defined') {
          this.selectedType = 'user_defined';
        }
        else {
          this.initTemplatesSystemDefined()
        }
      }
      if(this.selectedType == 'user_defined') {
        this.initProviders();
      }else {
        this.initTemplatesSystemDefined()
      }
    }
    else {
      this.company_id = ''
    }
  }
  sortFirstNameVise(arrayTosort){
      let users=arrayTosort;
      let result=users.sort(function(a, b){
          if(a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) { return -1; }
          if(a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) { return 1; }
          return 0;
      })
      return result;
  }
  async initProviders() {
    // this.loader.startLoader('loader-01');
    if (this.company_type === global.COMPANY_TYPE.SNF) {
      const filter = {
        is_activated: 'true',
        user_type: global.USER_TYPE.SNF_WC_NURSE,
        company_id: this.company_id
      }
      const projection = {
        'account_type': 1,
        'first_name': 1,
        'last_name': 1,
        'title': 1,
        full_name: 1
      }
      const usersResponse: any = await lastValueFrom(this.userService.getUsers(filter, projection));
      if (usersResponse.status == 200) {
        this.providers = usersResponse.data;
        this.providers = this.sortFirstNameVise(this.providers);
        this.selectedProvider = this.providers[0]?._id;
        this.initFacilities(this.selectedProvider);
      }
    } else {
      await this.services.getDoctorsList('true', this.company_id).subscribe((response: any) => {
        if (response.status == 200) {
          this.providers = response.data;
          this.providers = this.sortFirstNameVise(this.providers);
          const previousSeletedProvider = localStorage.getItem('providerSelected');
          if (previousSeletedProvider) {
            this.selectedProvider = previousSeletedProvider;
          } else {
            this.selectedProvider = this.providers[0]?._id;
          }

          // 
          this.initFacilities(this.selectedProvider);
        }
      });
    }
  }
  getUpdationDate(template) {
    let newDate = moment(template.updation_date).tz('America/Los_Angeles').format('DD-MMM-YYYY h:mm A');
    return newDate;
    // if (template.updation_date_string) {
    //   // return template.updation_date_string;
    //   let splitDate = template.updation_date_string.split('-');
    //   let month = splitDate[1];
    //   let day = splitDate[2];
    //   let year = splitDate[0];
    //   let newDate = month + '/' + day + '/' + year;
    //   return newDate;
    // }else {
    //   // return template.updation_date.substr(0,10);
    //   let newDate = template.updation_date.substr(0,10);
    //   let splitDate = newDate.split('-');
    //   let month = splitDate[1];
    //   let day = splitDate[2];
    //   let year = splitDate[0];
    //   let formattedDate = month + '/' + day + '/' + year;
    //   return formattedDate;
    // }
  }
  getCreationDate(template) {
    let newDate = moment(template.creation_date).tz('America/Los_Angeles').format('DD-MMM-YYYY h:mm A');
    return newDate;
    // if (template.creation_date_string) {
    //   // return template.creation_date_string;
    //   let splitDate = template.updation_date_string.split('-');
    //   let month = splitDate[1];
    //   let day = splitDate[2];
    //   let year = splitDate[0];
    //   let newDate = month + '/' + day + '/' + year;
    //   return newDate;
    // }else {
    //   let newDate = template.creation_date.substr(0,10);
    //   let splitDate = newDate.split('-');
    //   let month = splitDate[1];
    //   let day = splitDate[2];
    //   let year = splitDate[0];
    //   let formattedDate = month + '/' + day + '/' + year;
    //   return formattedDate;
    // }
  }

  isPCCFacility(facility_id) {
    for(const facility of this.facilities) {
      if(facility_id === facility._id) {
        if(facility.pcc_facId) {
          return true;
        }
      }
    }
  }
  
  async initFacilities(provider) {
    if(this.company_type === global.COMPANY_TYPE.SNF) {
      const filter = {
        associated_snf_wc_nurse_ids: this.selectedProvider,
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1,
        pcc_orgUuid: 1
      };
      const facilitiesResponse : any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if(facilitiesResponse.status == 200) {
        this.facilities = facilitiesResponse.data;
        this.selectedFacility = this.facilities[0]?._id;
        this.initTemplates();
      }
    } else {
      await this._transcriptionCreateService.getFacilities(provider).subscribe(async (response: any) => {
        if (response.status == 200) {
          this.facilities = response.data.array;
          const previousSelectedFacility = localStorage.getItem('facilitySelected');
          if(previousSelectedFacility){
            this.selectedFacility = previousSelectedFacility;
          }else {
            this.selectedFacility = this.facilities[0]?._id;
          }
          
          this.initTemplates();
        }
      });
    }
  }
  async initTemplates() {
    await this.templateExplorerService.getTemplatesByFacilityId(this.selectedFacility, this.selectedProvider, this.selectedType).subscribe((response: any) => {
      if (response.status == 200) {
        this.allTemplates = response.data;
        this.templatesLoaded = true;
        // this.loader.stopLoader('loader-01');
      }
    })
  }
  async initTemplatesSystemDefined() {
    // this.loader.startLoader('loader-01');
    console.log("this.company_id: ", this.company_id);

    await this.templateExplorerService.getSystemDefinedTemplates(this.company_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.allTemplates = response.data;
        // this.loader.stopLoader('loader-01');
        this.templatesLoaded = true;
      }
    })
  }

  onItemSelectProvider(provider) {
    localStorage.setItem('providerSelected',provider);
    this.initFacilities(this.selectedProvider);
  }
  onItemSelectFacility(facility) {
    localStorage.setItem('facilitySelected',facility);
    this.initTemplates()
  }
  onItemSelectType(type) {
    this.selectedType = type;
    localStorage.setItem('templateType',this.selectedType);
    if (this.selectedType == 'user_defined') {
      this.initProviders()
    }
    else {
      this.initTemplatesSystemDefined()
    }
  }

  ngOnDestroy() {
    if (!this.routingToTemplateCreateComponent) {
      localStorage.removeItem("facilitySelected");
      localStorage.removeItem("providerSelected");
      localStorage.removeItem("templateType");
  }
  }
  addNewTemplate() {
    // this.router.navigateByUrl(`/company/${this.company_id}/template-create`, { relativeTo: this.route })
    const routerMapParams: any = { companyId: this.company_id, provider: this.selectedProvider, facility: this.selectedFacility };
    
    this.routingToTemplateCreateComponent = true;
    this.router.navigate([`/company/${this.company_id}/template-create`, routerMapParams])
  }
  onEditClick(row) {
    if (this.company_id) {
      const routerMapParams: any = { companyId: this.company_id, id: row._id };
      this.routingToTemplateCreateComponent = true;
      this.router.navigate([`/company/${this.company_id}/template-create`, routerMapParams])
    }
    else {
      const routerMapParams: any = { companyId: this.company_id, provider: this.selectedProvider, facility: this.selectedFacility, tempID: row._id };
      this.routingToTemplateCreateComponent = true;
      this.router.navigate([`/company/${this.company_id}/template-create`, routerMapParams])
    }
  }
  onViewClick(row) {
    // console.log("ran");
    // this.templateExplorerService.setProviderId(this.selectedProvider);
    const routerMapParams: any = { companyId: this.company_id, provider: this.selectedProvider, facility: this.selectedFacility, id: row._id, template_type: 'user_defined' };
    // this.router.navigate(['template-create', { id: row._id }]);
    this.routingToTemplateCreateComponent = true;
    this.router.navigate([`/company/${this.company_id}/template-create`, routerMapParams]);
  }

  async onDeleteClick(row) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: 'Are you sure you want to permanently delete this template?'
      }
    });
    let confirmed = "false";
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
      await this.templateExplorerService.deleteTemplate(row._id).subscribe((response: any) => {
        if (response.status == 200) {
          if(this.selectedType == 'user_defined'){
            this.toastr.success("Deleted SuccessFully");
            this.initTemplates();
          }else{
            this.initTemplatesSystemDefined()
          }
        }
      })
    }
  })
  }

  onLockClick(row, val) {
    console.log('row', row, 'val', val);
    this.templateExplorerService.lockTemplate(row._id, val).subscribe((response: any) => {
      if (response.status == 200) {
        console.log('locked success');
        this.initTemplatesSystemDefined();
      }
    });
  }
}
