import {Component, OnInit} from '@angular/core';
import {NotificationsService} from './notifications.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {PusherService} from '../../services/pusher-service';
import * as global from "../../includes/global";
import { data } from 'jquery';
import { async } from '@angular/core/testing';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
    currentUser: any;

    isOpened: boolean = false;
    notifications: any[] = [];
    unseenNotificationsCount: number = 0;
    notificationsLoaded: boolean = false;

    constructor(
        private _notificationsService: NotificationsService,
        private _toastr: ToastrService,
        private _router: Router,
        private _authService: AuthService,
        private pusherService: PusherService) {
        // const currentUser = localStorage.getItem('currentUser');
        const currentUser = this._authService.currentUser;
        if (currentUser) {
            // this.currentUser = JSON.parse(currentUser);
            this.currentUser = currentUser;
        }
        // this.initUnseenNotificationsCount();
        // this.pusherService.dictation_notify.subscribe(data => {
        //     this.initUnseenNotificationsCount();
        // });
        // this.pusherService.note_notify.subscribe( data => {
        //     this.initUnseenNotificationsCount();
        // });
    }

    ngOnInit() {
        this.pusherService.newReleaseNotficationReceive.subscribe((data)=>{
            this.unseenNotificationsCount += 1;
        })
        this.initUnseenNotificationsCount();

    }

    initUnseenNotificationsCount() {
        this.unseenNotificationsCount = 0;
        this._notificationsService.getUnseenNotificationsCount().subscribe((response: any) => {
            if (response.status === 200) {
                this.unseenNotificationsCount = response.count;
            } else {
                this._toastr.error(response.message, 'Failed');
            }
        });
    }

    openURL(notification){
        console.log("notification : ", notification);
        if (notification.routeUrl && notification.recipient_type !== 'version') {
            let url = global.base_url + notification.routeUrl;

            const URL = this._router.serializeUrl(this._router.createUrlTree([notification.routeUrl]));
            window.open(URL, '_blank');

            // this._router.navigateByUrl('/dashboard', {skipLocationChange: true})
            // .then(() => {
            //     this._router.navigateByUrl(notification.routeUrl);
            // });

        }
        else if (notification.recipient_type == 'notification') {
            const URL = global.base_url + `/notifications-settings`;
            window.open(URL, '_blank');
        }
        else if (notification.recipient_type == 'biller_addendum_req') {
            let notificationDescription = notification.description.split(":");
            let data = {startDate: notificationDescription[0], endDate: notificationDescription[1], provider: notificationDescription[2], facility: notificationDescription[3]}
            const routerMapParams: any = { category: 'charges', data: data };
            this._router.navigate(['/dashboard'])
            this._notificationsService.setChargeNotificationData(routerMapParams);
        }
        else {
            const URL = global.base_url + '/app-release';
            window.open(URL, '_blank');
        }
    }

   async initNotifications() {
        this.notifications = [];
        this.notificationsLoaded = true;
       await this.lastNotifications()
       this.makeUnseenNotificationsSeen();
       if (this.notifications.length == 0) {
           this._notificationsService.getNotifications().subscribe(async (response: any) => {
               if (response.status === 200) {
                   this.notifications = response.notification;

               } else {
                   this._toastr.error(response.message, 'Failed');
               }

           });
       }
    }

    makeUnseenNotificationsSeen() {
        const unseenNotifications = this.notifications.filter(n => n.seenBy.indexOf(this.currentUser._id) === -1);
        if (unseenNotifications.length > 0) {
            this._notificationsService.makeNotificationsSeen(unseenNotifications.map(n => n._id)).subscribe((seenResponse: any) => {
                if (seenResponse.status === 200) {
                    // unseenNotifications.forEach(notification => {
                    //   notification.seenBy.push(this.currentUser._id);
                    // });

                    this.unseenNotificationsCount = 0;
                    this.notificationsLoaded = false;
                }
            });
        } else {
            this.lastNotifications();
        }
    }

    async lastNotifications() {
            let response = await lastValueFrom(this._notificationsService.getLastNotifications())
            if (response.status === 200) {
                this.notifications = response.notification;
                this.notificationsLoaded = false;
            } else {
                this._toastr.error(response.message, 'Failed');
            }
    }

    menuOpened() {
        this.isOpened = true;
        this.initNotifications();
    }

    menuClosed() {
        this.isOpened = false;
        this.initUnseenNotificationsCount();
    }

    handleNotificationClick(notification) {
        this._router.navigateByUrl('/dashboard', {skipLocationChange: true})
            .then(() => {
                this._router.navigateByUrl(notification.routeUrl);
            });
    }
}
