<h1>Send Email</h1>
<div class="flex justify-between items-center">
    <mat-form-field class="field-item">
        <mat-label>User type</mat-label>
        <mat-select [(ngModel)]="user_type" name="user_type" required>
            <mat-option value="1">
                Provider
            </mat-option>
         
            <mat-option value="nurse">
                Nurse
            </mat-option>
          
            <mat-option value="medical_assistant">
                Medical Assistant
            </mat-option>
        </mat-select>
        <mat-error *ngIf="!user_type">Please select user type</mat-error>
    </mat-form-field>

    <button mat-raised-button class="dn-button-primary" mat-dialog-close
            (click)="sendEmail()">
            Send
        </button>
</div>

