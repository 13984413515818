<div class="flex flex-col gap-2">
    <br />
    <span style="margin-bottom: 10px; display: contents; text-align: center;">
        <h3>Export Notes</h3>
    </span>
    <mat-form-field class="example-chip-list" [class.isFocused]="!isInputFocused" >
        <mat-label style="font-size: 10px;">Export Notes to...</mat-label>
        <mat-chip-grid #chipList aria-label="Fruit selection">
          <mat-chip *ngFor="let email of emails" [selectable]="selectable"
                   [removable]="removable" (removed)="removeMail(email)">
            {{email}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input placeholder="New email..."
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="addEmail($event)"
                 (focus)="toggleFocus(true)" 
           (blur)="toggleFocus(false)">
        </mat-chip-grid>
      </mat-form-field>
      <button  class="theme-btn" mat-raised-button (click)="sendEmail()" >Send</button> 

</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
