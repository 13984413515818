<b [innerHtml]="title" style="text-align: center;display: block;"></b>
<div *ngIf="showCheckBox" style="display:flex;justify-content: center;">
    <mat-checkbox [(ngModel)]="checked" class="example-margin">I am Sure!</mat-checkbox>
</div>
<mat-dialog-actions style="justify-content: center;">
    <button mat-raised-button class="dn-button-secondary" mat-dialog-close mat-dialog-close="false">No</button>
    <button mat-raised-button class="dn-button-primary" [disabled]="checkDisable()" mat-dialog-close
        mat-dialog-close="true">Yes</button> &nbsp;&nbsp;
</mat-dialog-actions>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>