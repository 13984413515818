import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../includes/global';
import { API_URL } from 'src/environments/api-url';
import { map } from 'rxjs/operators';
import { async } from '@angular/core/testing';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ReportGenerateService {
    [x: string]: any;
    private _currentUser;
    groupedData: any = {};


    constructor(
        private httpclient: HttpClient,
        // private http: HttpClient,
        private _authService: AuthService,
        private toastr: ToastrService,
        private dashboardService: DashboardService) {
        this._currentUser = this._authService.currentUser;

    }

    getAllDocumentPages(document_id) {
        const details = {
            id: this._currentUser._id,
            auth_key: this._currentUser.auth_key,
            usertype: this._currentUser.user_type,
            document_id
        };
        // return this.httpclient.post(global.app_base_url + '/getAllDocumentPages', {details: details}).pipe();
        return this.httpclient.post(global.url + API_URL.RRGEN.getAllDocumentPages, { details: details }).pipe(map((response: any) => {
            if (response.data && Array.isArray(response.data.array)) {
                for (let documentPage of response.data.array) {
                    if (Array.isArray(documentPage.date)) {

                        documentPage.date = documentPage.date.map(date => {
                            if (date) {
                                return date;
                                // return new Date(date);
                            }
                            return date;
                        });
                    }
                    if (documentPage.selected.date) {
                        documentPage.selected.date = documentPage.selected.date;
                        // documentPage.selected.date = new Date(documentPage.selected.date);
                    }
                }
            }
            return response;
        }));
    }
    getAllDocuments() {
        const details = {
            id: this._currentUser._id,
            auth_key: this._currentUser.auth_key,
            usertype: this._currentUser.user_type,

        };
        return this.httpclient.post(global.url + API_URL.RRGEN.getAllDocuments, { details: details }).pipe(); 
    } 

    paginteDocument(pageNo, filter=null) {
        var item  = {
            id: this._currentUser._id,
            auth_key: this._currentUser.auth_key,
            usertype: this._currentUser.user_type,
            pageNo,
            filter,
        };
        return this.httpclient.post(global.url + API_URL.RRGEN.paginteDocument, { details: item }).pipe(); 
    }
    async uploadFiles(docFile, coverFile, comapny) {
        // const details = {
        //     id: this._currentUser._id,
        //     auth_key: this._currentUser.auth_key,
        //     usertype: this._currentUser.user_type,
        //     docFile,
        //     coverFile,
        // };
        // return this.httpclient.post(global.rrgen + '/uploadFilesAndStartProcess', details).pipe()
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            // formData.append(type, file);
            /** In Angular 5, including the header Content-Type can invalidate your request */
            formData.append('id', this._currentUser.id);
            formData.append('auth_key', this._currentUser.auth_key);
            formData.append('usertype', this._currentUser.user_type);
            formData.append('docFile', docFile);
            formData.append('coverFile', coverFile);
            formData.append('insurance_comapny', comapny)
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.open('POST', global.url + API_URL.RRGEN.uploadFilesAndStartProcess, true);
            xhr.send(formData);
        });
    }
    previewReport(detail) {
        const details = {
            id: this._currentUser._id,
            auth_key: this._currentUser.auth_key,
            usertype: this._currentUser.user_type,
            ...detail
        }
        return this.httpclient.post(global.url + API_URL.RRGEN.previewReport, { details: details }).pipe();
    }

    generateReport(reportDocument) {
        const details = {
            id: this._currentUser._id,
            auth_key: this._currentUser.auth_key,
            usertype: this._currentUser.user_type,
            ...reportDocument
        }
        // return this.httpclient.post(global.rrgen + '/generateReport', {details: details}).pipe();
        return this.httpclient.post(global.url + API_URL.RRGEN.generateReport, { details: details }).pipe();
    }

    perPageGenerateReport(reportDocument) {
        const details = {
            id: this._currentUser._id,
            auth_key: this._currentUser.auth_key,
            usertype: this._currentUser.user_type,
            ...reportDocument
        }

        return this.httpclient.post(global.url + API_URL.RRGEN.perPageGenerateReport, { details: details }).pipe();
        // return this.httpclient.post(global.url + API_URL.RRGEN.per)
    }

    _generatePDFAndDownload(details) {
        return this.dashboardService.downloadFile(global.url + API_URL.RRGEN.generatePDFAndDownload, details)
    }
    generatePDFAndDownload(details) {
        const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
        try {
            // const params = { 
            //     document_id : this.document_id
            // }
            return this.dashboardService.downloadFile(global.url + API_URL.RRGEN.generatePDFAndDownload, details).subscribe((res: any) => {
                if (res instanceof HttpResponse) {

                    // this.reportGenerateService.generatePDFAndDownload(details).subscribe((res: any) => {
                    //     if (res instanceof HttpResponse) {
                    var url = window.URL.createObjectURL(res.body);
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = 'summary.pdf';
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                } else {
                    if (res.type === 0) {
                    } else if (res.type === 3) {
                        const { loaded, total } = res;
                        const progress = Math.round(loaded / total * 100);
                        if (progress > 0) {
                        }
                    }
                }
                if (res.status == 200) {
                    return true;
                }
            })
            //     }
            // })
            this.toastr.clear(infoToastr.toastId);
        } catch (error) {
            const { message = 'Something went wrong, please try again' } = error;
            this.toastr.error(message);
        } finally {
            this.toastr.clear(infoToastr.toastId);
        }
        // return this.dashboardService.downloadFile(global.url + API_URL.RRGEN.generatePDFAndDownload,details)
    }

    summaryReport(array) {
        array.sort(function (a, b) {
            return parseInt(a.group.page_no) - parseInt(b.group.page_no)
        });
        let rows = [];
        for (let item of array) {
            const { group } = item;
            const row = {};
            for (let groupItem of group) {
                let date = new Date(groupItem.date).toLocaleDateString();
                if (!row[groupItem.provider]) {
                    row[groupItem.provider] = {
                        ...groupItem,
                        page_no: [groupItem.page_no],
                        date: item._id.date,
                        note_type: item._id.note_type,
                    }
                } else {
                    row[groupItem.provider] = {
                        ...row[groupItem.provider],
                        page_no: row[groupItem.provider].page_no.concat(groupItem.page_no),
                        impression: (row[groupItem.provider].impression == '-' || row[groupItem.provider].impression == '') ? groupItem.impression : row[groupItem.provider].impression,
                        date: item._id.date,
                        note_type: item._id.note_type,
                    }
                }
                row[groupItem.provider].page_no.sort(function (a, b) {
                    return parseInt(a) - parseInt(b);
                });
            }
            rows.push(row);
        }
        for (let i in rows) {
            rows[i] = Object.values(rows[i])[0];
            rows[i].page_no = rows[i].page_no.join(', ')
        }
        // grouping by provider
        const groupsByProvider = {};
        for (let row of rows) {
            if (!Array.isArray(groupsByProvider[row.provider])) {
                groupsByProvider[row.provider] = [];
            }
            groupsByProvider[row.provider].push(row)
        }
        return groupsByProvider;
    }

    summaryReportCMC(array) { 
        // array.sort(function (a, b) {
        //     return parseInt(a.group.page_no) - parseInt(b.group.page_no)
        // });
        // console.log(" Arrays after sorting based on page no: ", array);
        let rows = [];
        for (let item of array) { 
            const { group } = item; 
            const row = {};
            for (let groupItem of group) {  
                let date;
                if (groupItem.date && groupItem.date != '' && groupItem.date != "Invalid Date") {
                    date = new Date(groupItem.date).toLocaleDateString();
                }
                else {
                    date = ""
                } 
                //note types in following if condition don't have a through case
                if (item._id.note_type.toLowerCase().indexOf("mri".toLowerCase()) !== -1 || item._id.note_type.toLowerCase().indexOf("x-rays".toLowerCase()) !== -1 || item._id.note_type.toLowerCase().indexOf("ct-scan".toLowerCase()) !== -1 || item._id.note_type.toLowerCase().indexOf("NCS/EMG".toLowerCase()) !== -1 || item._id.note_type.toLowerCase().indexOf("IME".toLowerCase()) !== -1 || item._id.note_type.toLowerCase().indexOf("Procedure note".toLowerCase()) !== -1   ) { 
                    row[groupItem.note_type] = {
                        ...groupItem,
                        page_no: [groupItem.page_no],
                        date: [date],
                        provider: item._id.provider,
                        note_type: item._id.note_type,
                        // impression: (row[groupItem.note_type].impression == '-' || row[groupItem.note_type].impression == '') ? groupItem.impression : row[groupItem.note_type].impression,
                    } 
                }
                //for "through" note types
                else {  
                    if (!row[groupItem.note_type]) {
                        row[groupItem.note_type] = {
                            ...groupItem,
                            page_no: [groupItem.page_no],
                            date: [date],
                            provider: item._id.provider,
                            note_type: item._id.note_type, 
                        }
                    } else {
                        row[groupItem.note_type] = {
                            ...row[groupItem.note_type],
                            page_no: row[groupItem.note_type].page_no.concat(groupItem.page_no),
                            date: row[groupItem.note_type].date.concat(date),
                            impression: (row[groupItem.note_type].impression == '-' || row[groupItem.note_type].impression == '') ? groupItem.impression : row[groupItem.note_type].impression,
                            // date: item._id.date,
                            note_type: item._id.note_type,
                        }
                    }
                }
                //sorting by date in a group created
                // row[groupItem.note_type].date.sort(function (a, b) {
                //     return parseInt(a) - parseInt(b);
                // }); 
            } 
            rows.push(row);
        }

        for (let i in rows) {
            rows[i] = Object.values(rows[i])[0]; 
            rows[i].date = rows[i].date.join(', ') 
        } 
        
        // //sorting by date
        rows.sort(function (a, b) {
            let firstVal = a.date.split(", ");
            let secVal = b.date.split(", ");
            firstVal = new Date(firstVal[0]);
            secVal = new Date(secVal[0]);

            var keyA = firstVal,
                keyB = secVal; 
            if (keyA == "Invalid Date" && keyB) {
                return -1;
            }
            if (keyA && keyB == "Invalid Date") {
                return 1;
            }
            if (keyA < keyB) {
                return 1;
            }
            if (keyA > keyB) {
                return -1;
            }
            return 0;
        });
        // console.log("after sorting based on date: ", rows);
        
        //uncomment if want forms on top and and rest notes based on date
        //pushing form on top
        // let sort1;
        // rows.sort(function (a, b) {
        //     var keyA = a.note_type,
        //         keyB = b.note_type;
        //     if (keyA.includes("form") != -1 && keyB) {
        //         return -1;
        //     }
        //     if (keyA && keyB.includes("form") != -1) {
        //         return 1;
        //     }
        //     return 0;
        // }); 
        
        //comment if want forms on top and and rest notes based on date
        var order = [
            "mri".trim().toLowerCase(),
            "ctcsan".trim().toLowerCase(),
            "ct-scan".trim().toLowerCase(), 
            "ct scan".trim().toLowerCase(), 
            "x-rays".trim().toLowerCase(), 
            "xrays".trim().toLowerCase(), 
            "x-ray".trim().toLowerCase(), 
            "xray".trim().toLowerCase(), 
            "procedure".trim().toLowerCase(), 
            "Procedure note".trim().toLowerCase(), 
            "IME report".trim().toLowerCase(), 
            "EC-4NARR form".trim().toLowerCase(),
            "Varience coordination form".trim().toLowerCase(),
            "MG-2 form".trim().toLowerCase(),
            "MG-2.1 form".trim().toLowerCase(),
            "C-4 AMR".trim().toLowerCase(),
            "C-4 AUTH form".trim().toLowerCase(), 
            "C-4.3 form".trim().toLowerCase(), 
            "C-4.2 form".trim().toLowerCase(), 
            "C-4 form".trim().toLowerCase(), 
            "C-3 form".trim().toLowerCase(), 
            "form".trim().toLowerCase()];
        
        //comment if want forms on top and and rest notes based on date
        rows.sort(function(a,b) {
            let e1 = a.note_type.trim().toLowerCase();
            let e2 = b.note_type.trim().toLowerCase(); 
              return order.indexOf(e2) - order.indexOf(e1 );
        }); 

        // if to group based on NoteType then replace page_image_url with note_type
        // if to group based on NoteType then replace page_image_url with note_type
        const groupsByNoteType = {};
        for (let row of rows) { 
            
            if (!Array.isArray(groupsByNoteType[row.page_image_url])) {
                groupsByNoteType[row.page_image_url] = [];
            }
            groupsByNoteType[row.page_image_url].push(row)
        } 
        
        //sorting alphabatically
        // let ordered = {};
        // Object.keys(groupsByNoteType).sort().forEach(function (key) {
        //     ordered[key] = groupsByNoteType[key];
        // });

        return groupsByNoteType;
    }

    updateCoverInfo(coverInfo) {
        return this.httpclient.post(global.url + API_URL.RRGEN.updateCoverInfo, coverInfo).pipe();
    }

    addNewPage(newPage) {
        const details = {
            id: this._currentUser._id,
            auth_key: this._currentUser.auth_key,
            usertype: this._currentUser.user_type,
            ...newPage
        };
        return this.httpclient.post(global.url + API_URL.RRGEN.addNewPage, { details: details }).pipe();
    }
    generateSummaryCMCFormat(groupedData, coverData) {
        let template = ``;
        let doi = "";
        let dob = "";
        let doa = "";

        if (coverData.claiment_doi) {
            doi = new Date(coverData.claiment_doi).toLocaleDateString();
        }
        if (coverData.claiment_dob) {
            dob = new Date(coverData.claiment_dob).toLocaleDateString();
        }
        if (coverData.claiment_doa) {
            doa = new Date(coverData.claiment_doa).toLocaleDateString();
        }
        template = `<div style="padding: 15px; line-height:20px; font-family: serif;font-size: 13px;"> 
            
                <p >
                    <strong>Claim Type: </strong> ${coverData.claim_type}
                <br>
                    <strong>Carrier: </strong> ${coverData.carrier}
                    <br>
                    <strong>Claimant: </strong> ${coverData.claiment_name}
                    <br>
                    <strong>DOB: </strong> ${dob}
                    <br>
                    <strong>Client File#: </strong> ${coverData.client_file}
                    <br>
                    <strong>UniMex File#: </strong> ${coverData.UniMex_file}
                    <br>
                    <strong>DOA: </strong> ${doa}
                    <br>
                    <strong>Employer: </strong> ${coverData.employer}
                    <br>
                    <strong>WCB File#: </strong> ${coverData.wcb_file}
                </p> 
             
        <div>` 

        for (let key in groupedData) { 
            if (key) {
                for (let row of groupedData[key]) { 
                    let result = "";
                    let dateFrom = "";
                    let dateTo = "";
                    let allDates = [];
                    let dates = [];

                    if (row.date) {
                        allDates = row.date.split(", ");
                        //distincting dates
                        const distinct = (value, index, self) => {
                            return self.indexOf(value) === index;
                        }
                        dates = allDates.filter(distinct);

                        dates.sort(function (a, b) {
                            let firstVal = new Date(a);
                            let secVal = new Date(b);

                            var keyA = firstVal,
                                keyB = secVal;
                            // Compare the 2 dates
                            if (keyA < keyB) {
                                return -1;
                            }
                            if (keyA > keyB) {
                                return 1;
                            }
                            return 0;
                        });
                        dateFrom = dates[0];
                        if (dates.length > 1) {
                            dateTo = dates[dates.length - 1];
                        }
                    }
                    template += `<ul>`
                    let groupName = "";
                    if (row.note_type) {
                        groupName = row.note_type.toLowerCase().trim();
                    }
                    if (groupName.indexOf("form") !== -1) {
                        if (row.page_no.length > 1) {
                            template += `<li>${row.note_type}s</li>`
                        } else {
                            template += `<li>${row.note_type}</li>`
                        }
                    }
                    else if (groupName.indexOf("mri") !== -1 || groupName.indexOf("x-ray") !== -1 || groupName.indexOf("ct-scan") !== -1 || groupName.indexOf("NCS/EMG") !== -1 || groupName.indexOf("IME") !== -1 || groupName.indexOf("Procedure note") !== -1 || groupName.indexOf("Procedure Note") !== -1 ) {
                        if (row.exam) {
                            // if(allDates.length > 1){
                            //     for(let date in allDates){

                            //     }
                            // }
                            result = row.note_type + " " + row.exam + " dated " + allDates[0];
                        }
                        else {
                            result = row.note_type + " dated " + allDates[0];
                        }
                    }
                    else {
                        if (row.exam) {
                            result = row.note_type + " " + row.exam;
                        }
                        else {
                            result = row.note_type;
                        }
                        if (dateFrom && !dateTo) {
                            result = result + " dated " + dateFrom;
                        }
                        else if (dateFrom && dateTo) {
                            result = result + "s dated " + dateFrom + " through " + dateTo;
                        }
                        else if (dateFrom && dateTo) {
                            result = result + " dated " + dateFrom + " through " + dateTo + " by " + row.provider;
                        }
                    }
                    if (groupName.indexOf("form") === -1 && (groupName.indexOf("mri") >= 0 || groupName.indexOf("x-ray") >= 0 || groupName.indexOf("ct-scan") >= 0)) {
                        if (row.provider) {
                            template += `<li>${result} submitted by ${row.provider}</li> `
                            if (row.impression.trim() !== '' && row.impression.trim() !== '-') {
                                template += `<ul><li>${row.impression}</li></ul><br>`
                            }
                        }
                        else {
                            template += `<li>${result}</li>`
                            if (row.impression.trim() !== '' && row.impression.trim() !== '-') {
                                template += `<ul><li>${row.impression}</li></ul><br>`
                            }
                        }
                    }
                    else if(groupName.indexOf("form") === -1 ){
                        if (row.provider) {
                            template += `<li>${result} by ${row.provider}</li> `
                            if (row.impression.trim() !== '' && row.impression.trim() !== '-') {
                                template += `<ul><li>${row.impression}</li></ul><br>`
                            }
                        }
                        else {
                            template += `<li>${result}</li>`
                            if (row.impression.trim() !== '' && row.impression.trim() !== '-') {
                                template += `<ul><li>${row.impression}</li></ul><br>`
                            }
                        }
                    }

                    template += `</ul>`

                }
            }
        }
        template += `</div>`
        return template;
    }

    perPageGenerateSummaryCMCFormat(groupedData) {

        let template = ``;
        let doi = "";
        let dob = "";
        let doa = "";

        // template = `<div style="padding: 15px; line-height:20px; font-family: serif;font-size: 13px;">     
        // <div>`

        for (let key in groupedData) {
            if (key) {
                for (let row of groupedData[key]) {
                    let result = "";
                    let dateFrom = "";
                    let dateTo = "";
                    let allDates = [];
                    let dates = [];

                    if (row.date) {
                        allDates = row.date.split(", ");
                        //distincting dates
                        const distinct = (value, index, self) => {
                            return self.indexOf(value) === index;
                        }
                        dates = allDates.filter(distinct);

                        dates.sort(function (a, b) {
                            let firstVal = new Date(a);
                            let secVal = new Date(b);

                            var keyA = firstVal,
                                keyB = secVal;
                            // Compare the 2 dates
                            if (keyA < keyB) {
                                return -1;
                            }
                            if (keyA > keyB) {
                                return 1;
                            }
                            return 0;
                        });
                        dateFrom = dates[0];
                        if (dates.length > 1) {
                            dateTo = dates[dates.length - 1];
                        }
                    }
                    // template  += `<li></li>`
                    let groupName = "";
                    if (row.note_type) {
                        groupName = row.note_type.toLowerCase().trim();
                    }
                    if (groupName.indexOf("form") !== -1) {
                        if (row.page_no.length > 1) {
                            template += `<li>${row.note_type}s</li> <br> `
                        } else {
                            template += `<li>${row.note_type}</li> <br>`
                        }
                    }
                    else if (groupName.indexOf("mri") !== -1 || groupName.indexOf("x-ray") !== -1 || groupName.indexOf("ct-scan") !== -1) {
                        if (row.exam) {
                            result = row.note_type + " " + row.exam + " dated " + allDates[0];
                        }
                        else {
                            result = row.note_type + " dated " + allDates[0];
                        }
                    }
                    else {
                        if (row.exam) {
                            result = row.note_type + " " + row.exam;
                        }
                        else {
                            result = row.note_type;
                        }
                        if (dateFrom && !dateTo) {
                            result = result + " dated " + dateFrom;
                        }
                        else if (dateFrom && dateTo) {
                            result = result + " dated " + dateFrom + " through " + dateTo;
                        }
                        else if (dateFrom && dateTo) {
                            result = result + " dated " + dateFrom + " through " + dateTo + " by " + row.provider;
                        }
                    }
                    if (groupName.indexOf("form") === -1) {
                        if (row.provider) {
                            template += `<li>${result} by ${row.provider}</li> `
                            if (row.impression.trim() !== '' && row.impression.trim() !== '-') {
                                template += `<ul><li>${row.impression}</li></ul><br> `
                            }
                            else {
                                template += `<br>`
                            }
                        }
                        else {
                            template += `<li>${result}</li> `
                            if (row.impression.trim() !== '' && row.impression.trim() !== '-') {
                                template += `<ul><li>${row.impression}</li></ul><br> `
                            }
                            else {
                                template += `<br>`
                            }
                        }
                    }
                    template += ` </ul>`

                }
            }
        }
        template += `</div>`
        return template;
    }

    generateSummaryDemarcoFormat(groupedData) {
        let template;
        const doi = new Date(this.claiment_doi).toLocaleDateString();
        const dob = new Date(this.claiment_dob).toLocaleDateString();
        template = `<div style="padding-top: 4px;"> 
        <div style=""> 
                <p fxlayout="" fxlayoutalign="space-between baseline" fxlayoutgap="10px"
                    style="flex-direction: row; box-sizing: border-box;  max-height: 100%; align-items: baseline; justify-content: space-between;">
                    <strong style="margin-right: 10px;">RECORD OUTLINE :</strong>${this.claiment_name}</p>
                <p fxlayout="" fxlayoutalign="space-between baseline" fxlayoutgap="10px"
                    style="flex-direction: row; box-sizing: border-box;  max-height: 100%; align-items: baseline; justify-content: space-between;">
                    <strong style="margin-right: 10px;">AIS: </strong>${doi}</p>
                <p fxlayout="" fxlayoutalign="space-between baseline" fxlayoutgap="10px"
                    style="flex-direction: row; box-sizing: border-box; max-height: 100%; align-items: baseline; justify-content: space-between;">
                    <strong style="margin-right: 10px;">DOI: </strong>${dob}</p>
        </div>`;
        for (let key in groupedData) {
            template += `  <div>
        <div>${key} </div> `;
            for (let value of groupedData[key]) {
                // let date = new Date(value.date);
                let date = value.date.toLocaleDateString();
                template += `<div>${date} - ${value.note_type}`;
                if (value.exam === '' || value.exam === 'undefined' || value.exam === '-' || !value.exam) {
                    template += ` pg. ${value.page_no}</div>`
                }
                else {
                    template += ` ${value.exam} pg. ${value.page_no}</div>`;
                }
                if (value.impression.trim() !== '' && value.impression.trim() !== '-') {
                    template += `<div>${value.impression}</div>`
                }
            }
            template += `<br/><br/>
          </div> `
        }
        template += `</div>`

        return template;
    }

    saveOrUpadateSummary(summary, docId) {
        const details = {
            id: this._currentUser._id,
            auth_key: this._currentUser.auth_key,
            usertype: this._currentUser.user_type,
            document_id: docId,
            summary: summary
        }
        return this.httpclient.post(global.url + API_URL.RRGEN.saveOrUpadateSummary, { details: details }).pipe();
    }

    lockAndUnlockDocument(lock, docId){ 
        let details:any;
        if(lock){
            details = {
                lockedBy: this._currentUser._id,
                auth_key: this._currentUser.auth_key,
                usertype: this._currentUser.user_type,
                locked: lock, 
                document_id: docId,
            }
        }
        else{
            details = {
                lockedBy: '',
                auth_key: this._currentUser.auth_key,
                usertype: this._currentUser.user_type,
                locked: lock, 
                document_id: docId,
            }
        }
        return this.httpclient.post(global.url + API_URL.RRGEN.lockAndUnlockDocument, { details: details }).pipe();
    }
}
