<div class="carousel-container">
    <h2 *ngIf="title">{{title}}</h2>
    <div class="top-right-close-btn">
        <button mat-mini-fab mat-dialog-close>
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
    <div class="carousel" *ngIf="images && images.length">
        <img [src]="images[currentIndex]" alt="Image preview">
    </div>
    <div class="carousel" *ngIf="!images && !images.length">
        No image found.
    </div>
    <button (click)="previousImage()" class="nav-button left">&lt;</button>
    <button (click)="nextImage()" class="nav-button right">&gt;</button>
</div>