<div class="flex items-center justify-center">
    <h1 mat-dialog-title style="color: black">Add New Appointment </h1>
</div>
<div mat-dialog-content>
    <form (ngSubmit)="addAppointment(form.value)" #form="ngForm">
        <div class="flex flex-col">
            <mat-form-field>
                <mat-label>Select Facility</mat-label>
                <mat-select name="facility"
                            required
                            #facility="ngModel"
                            [(ngModel)]="model.facility_id">
                    <mat-option *ngFor="let f of facilities"
                                value="{{f._id}}">{{f.title}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="facility.invalid  && (facility.dirty || facility.touched)">
                    Please select facility
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Select patient</mat-label>
                <mat-select name="patient"
                            required
                            #patient="ngModel"
                            [(ngModel)]="model.patient_id">
                    <mat-option *ngFor="let p of patients"
                                value="{{p._id}}">{{p.first_name + ' ' + p.last_name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="patient.invalid  && (patient.dirty || patient.touched)">
                    Please select patient
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Select payor</mat-label>
                <mat-select name="payor"
                            required
                            #payor="ngModel"
                            [(ngModel)]="model.payor_id">
                    <mat-option *ngFor="let pay of payors"
                                value="{{pay._id}}">{{pay.abbreviation}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="payor.invalid  && (payor.dirty || payor.touched)">
                    Please select payor
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Provider</mat-label>
                <input matInput
                       name="doctor"
                       disabled
                       [(ngModel)]="provider">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Notification Type</mat-label>
                <mat-select name="notify_type"
                            required
                            #notify_type="ngModel"
                            multiple
                            [(ngModel)]="model.notify_type">
                    <mat-option value="email">Email</mat-option>
                    <mat-option value="push">Push</mat-option>
                    <mat-option value="phone">Phone</mat-option>
                </mat-select>
                <mat-error *ngIf="notify_type.invalid  && (notify_type.dirty || notify_type.touched)">
                    Please select one option
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Notification Time</mat-label>
                <mat-select name="notify_time"
                            required
                            #notify_time="ngModel"
                            [(ngModel)]="model.notify_time">
                    <mat-option value="30">30 minutes earlier</mat-option>
                    <mat-option value="30">15 minutes earlier</mat-option>
                    <mat-option value="30">5 minutes earlier</mat-option>
                </mat-select>
                <mat-error *ngIf="notify_time.invalid  && (notify_time.dirty || notify_time.touched)">
                    Please select one option
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Add Conference</mat-label>
                <mat-select name="video"
                            required
                            #video="ngModel"
                            [(ngModel)]="model.is_video">
                    <mat-option value="true">Yes</mat-option>
                    <mat-option value="false">No</mat-option>
                </mat-select>
                <mat-error *ngIf="video.invalid  && (video.dirty || video.touched)">
                    Please select one option
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput
                       [matDatepicker]="picker"
                       name="dos" 
                       #dos="ngModel"
                       [(ngModel)]="model.dos"
                       placeholder="Choose a date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="dos.invalid  && (dos.dirty || dos.touched)">
                    Please select date of service
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput [hidden]="true">
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center justify-center gap-2">
            <button mat-raised-button
                    class="dn-button-secondary"
                    mat-dialog-close
                    type="button">Cancel</button>
            <button mat-raised-button
                    [disabled]="!form.form.valid"
                    type="submit"
                    class="dn-button-primary">Save</button>
        </div>
    </form>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
