import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../../../../includes/global';
import { API_URL } from "../../../../../environments/api-url";
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FacilitySettingsService {
  currentUser: any;
  constructor(private httpclient: HttpClient,
    private _authService: AuthService) {
    this.currentUser = this._authService.currentUser;
  }

  getFacilitySettings(facilityId, companyId) {
    return this.httpclient.post(global.url + API_URL.FACILITY_SETTING.getById, { facilityId, companyId });
  }
  updateFacilitySettings(facilitySettingId, configuration) {
    return this.httpclient.post(global.url + API_URL.FACILITY_SETTING.updateById, { facilitySettingId, configuration });
  }
  addFacilitySettings(facilityId, companyId) {
    return this.httpclient.post(global.url + API_URL.FACILITY_SETTING.addOne, { facilityId, companyId });
  }
}

