<form class="patient-rounding-sheet-form flex flex-col gap-5" style="margin:auto"
  (submit)="handleSubmit($event)">
  <h3>Update Patient Census</h3>

  <mat-form-field class="flex-grow">
    <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="name" required>
    <mat-error *ngIf="!name">Please enter name</mat-error>
  </mat-form-field>

  <ng-container *ngIf="date_obj&&!doesHaveData()">
    <app-date-picker-widget [date]="formatDateObjToString(date_obj)" (dateEvent)="handleDateChange($event)"></app-date-picker-widget>
  </ng-container>

  <ng-container *ngIf="!date_obj&&!doesHaveData()">
    <mat-form-field>
      <input matInput [matDatepicker]="picker" (click)="picker.open()" [disabled]="" [min]="minDate" name="date"
        placeholder="Visit Date" [value]="serviceDate" (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" required>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="!date">Please enter date </mat-error>
    </mat-form-field>
  </ng-container>
 
  <div *ngIf="doesHaveData()" class="flex gap-5">


    <div class="flex gap-1 w-full">
      <mat-form-field class="w-1/3" style="color: black !important;">
        <mat-label>Select Year *</mat-label>
        <input matInput readonly [value]="getYear(date_obj)">
      </mat-form-field>
      <mat-form-field class="w-1/3" style="color: black !important;">
            <mat-label>Select Month *</mat-label>
            <input matInput readonly [value]="getMonth(date_obj)">
      </mat-form-field>
      <mat-form-field class="w-1/3" style="color: black !important;">
        <mat-label>Select Day *</mat-label>
        <input matInput readonly [value]="getDay(date_obj)">
      </mat-form-field>
    </div>
  </div>

  <mat-form-field *ngIf="shouldShowOrgsDropdown">
    <mat-label>Organizations</mat-label>
    <mat-select name="org" [(ngModel)]="selectedOrg" (selectionChange)="onChangeOrganization($event)">
      <mat-option *ngFor="let org of orgs" value="{{org.organization_id._id}}">
        {{org.organization_id.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <div class="flex justify-end items-center  gap-5">
    <button mat-raised-button [disabled]="oldData.name === name && oldData.date === date" class="dn-button-primary" type="submit">Update</button>
  </div>
</form>
<div class="top-right-close-btn">
  <button mat-mini-fab mat-dialog-close>
    <mat-icon>cancel</mat-icon>
  </button>
</div>