import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AppointmentDataService } from '../appointment/service/data.service';
import * as global from '../../includes/global';  
import { AddPayorComponent } from '../appointment';
import { ToastrService } from 'ngx-toastr';
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";


@Component({
  selector: 'app-insurance-company',
  templateUrl: './insurance-company.component.html',
  styleUrls: ['./insurance-company.component.css']
})
export class InsuranceCompanyComponent implements OnInit {

  displayedColumns: string[] = [];
  // currentUser: any; 
  loading: Boolean = false;
  dataSource: any;
  companies: any;
  public addInsuranceCompanyDialogRef: any; 

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true} as any) paginator: MatPaginator;
  constructor(
    public appointmentDataService: AppointmentDataService,
    // private addPayorComponent: AddPayorComponent,
    public _authService:AuthService,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) { 
    this.displayedColumns = ['name','abbreviation', 'email', 'description', 'address','operations'];
      // this.currentUser = this._authService.currentUser;
  }

  ngOnInit() {
   this.initData();
  }
  applyFilter(filterValue: string) { 
    if(filterValue){
      this.dataSource.filter = filterValue.trim().toLowerCase();
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
          return (data.abbreviation.toLowerCase().includes(filter) || data.title.toLowerCase().includes(filter) || data.email.toLowerCase().includes(filter)) ;
      };
    }
    else{
      this.initData();
    }
  }
  onAddNewClick() { 
      this.addInsuranceCompanyDialogRef = this.dialog.open(AddPayorComponent, {width: '500px'}); 
      // console.log("this.addInsuranceCompanyDialogRef: ", this.addInsuranceCompanyDialogRef);
      this.addInsuranceCompanyDialogRef.afterClosed().subscribe((payor ) => {
        if (payor) {
            this.initData();
        }
    });
      // if( this.addInsuranceCompanyDialogRef){
        // this.initData();
      // }
  }
  onEditClick(payor){ 
    this.addInsuranceCompanyDialogRef = this.dialog.open(AddPayorComponent,{data: payor, width: '500px'}); 
  }
  onDeleteClick(payor){
    if (!confirm('Are you sure you want to delete?')) {
      return;
    }
    this.appointmentDataService.deletePayor(payor).subscribe((response:any)=>{
      if(response.status === 200){
        this.toastr.success("Payor has been deleted", 'success');
        this.initData();
      }
      else{
        this.toastr.error("Something went wrong", 'error');
      }
    })
  }

  initData()
  {
    this.loading = true;
    this.appointmentDataService.getPayors().subscribe((response: any) => {
        if (response.status === 200) {
            this.companies = response.data.array; 
            this.dataSource = new MatTableDataSource(this.companies);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }
        this.loading = false;
    });
  }

}

