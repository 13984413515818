import { AfterViewInit, Component, OnChanges, SimpleChanges, HostListener, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FacilityDialog } from 'src/app/shared/popup-dialogs/facility-dialog/facility.dialog';
import { FacilityAddDialog, IFacility } from 'src/app/shared/popup-dialogs/facility-add-dialog/facility-add.dialog';
import { OnInit } from '../patient-list/patient-list.component';
import { FacilityService } from 'src/app/services/facility.service';
import { LocationStrategy } from '@angular/common';
import * as global from '../global';
import { InsuranceCompanyComponent } from '../../components/insurance-company/insurance-company.component'
import { AuthService } from 'src/app/services/auth.service';
import { PCCService } from 'src/app/services/pcc.service';
import { ESignComponent } from 'src/app/components/e-sign/e-sign/e-sign.component';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { ChargesCatagoriesDialogComponent } from 'src/app/shared/popup-dialogs/charges-catagories/charges-catagories-dialog/charges-catagories-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { AddNewsComponent } from '../Notification-bar/notification-bar/add-news/add-news/add-news.component';
import { CertificationComponent } from '../certification/certification.component';
import { NewsServiceService } from '../Notification-bar/notification-bar/news-service.service';
import { OpenReleaseDialogComponent } from '../../includes/open-release-dialog/open-release-dialog.component';
import { OpenSettingDialogComponent } from '../../includes/open-setting-dialog/open-setting-dialog.component';
import { API_URL } from 'src/environments/api-url';
import { HttpClient } from '@angular/common/http';
import { FeedbackComponent } from '../feedback/feedback/feedback.component';
import { lastValueFrom } from 'rxjs';
import { LoginService } from '../login/login.service';
import { CompanyLogoAndMongogramService } from 'src/app/services/company-logo-and-mongogram.service';
import { PreviousRouteService } from 'src/app/services/previous-route.service';

// import {  }

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    global = global;
    public facilityDialogRef;
    public addNewsDialogRef;
    public chargesCatagoriesDialogComponent;
    public insuranceCompanyDialogRef;
    public eSignComponent;
    currentUser: any;
    show_outpatient_module:boolean = false;
    // Added activeUrls to determine on which page menu should activate  #mohsin-dev
    adminUserMenu: any;
    transAdminUserMenu: any;
    companyUserMenu: any;
    snfCompanyUserMenu: any;
    normalUserMenu: any;
    woundNurseMenu: any;
    menus: any;
    hasCompany: Boolean = false;
    companyData: any;
    companyPath: Boolean = false;
    company_id: any;
    adminRoute: boolean | string = false;
    companyRoute: boolean | string = false;
    showNews = true;
    currentCompanyName: any = "";
    currentCompanyType: any = "";
    newsString: any = '';
    company;
    addressSubscribtion: any;
    updatedUserNameSubscription: any;
    nursingMenu: {}[];
    companylogoPath: any;
    defaultImg: string;
    companyCode: any = "";
    companylogoPathSubscription: any;
    companylogoPathAfterLogin: any;
    currentCompanyId: any;
    previousUrl: string;
    patientList:any = [
    ]
    isNurseCensusActive: Boolean = false;
    isNursePatientsActive: Boolean = false;
    frontEndMenu: Boolean = false;
    screenWidth: number;
    numberOfMenuItems: number;
    public constructor(
        private router: Router,
        public loginService: LoginService,
        private dialog: MatDialog,
        private facilityService: FacilityService,
        private authService: AuthService,
        private pccService: PCCService,
        private route: ActivatedRoute,
        private companyService: CompanyService,
        private commonService: CommonService,
        private userService: UserService,
        private newsService: NewsServiceService,
        private httpclient: HttpClient,
        private companyLogoAndMonogramService: CompanyLogoAndMongogramService,
        private renderer: Renderer2,
        private previousRouteService: PreviousRouteService
    ) {
        // this.newsService.getNews().subscribe((res:any)=>{
        //     if(res.news?.length > 0){
        //         res.news.forEach(element => {
        //             this.newsString += '-'+ element.news + "  ";
        //           });
        //         this.showNews = true;
        //     }
        //     else{
        //         this.newsString = 'Updated'
        //         this.showNews = true;
        //     }
        // })
        this.currentUser = this.authService.currentUser;
        if (this.currentUser.product_owner) {
            this.adminUserMenu = [
                { link: ['/admin/dashboard'], title: 'Dashboard', activeUrls: ['/admin/dashboard'] },
                { link: ['/admin/activity'], title: 'Activity', activeUrls: ['/admin/activity'] },
                { link: ['/admin/user-management'], title: 'User Management', activeUrls: ['/admin/user-management', '/admin/doctor-profile', '/admin/biller-profile'] },
                { link: ['/admin/relationship'], title: ['Associations'], activeUrls: ['/admin/relationship'] },
                // {link: ['/report-generate'], title: 'RR-Gen', activeUrls: ['/report-generate', '/report-detail']},
                { link: ['/admin/notifications-settings'], title: ['Notifications'], activeUrls: ['/admin/notification-settings'] },
                { link: ['/admin/training-system'], title: 'Training System', activeUrls: ['/admin/training-system'] },
                // {link: ['/admin/reports'], title: 'Report', activeUrls: ['/admin/reports']},
            ];
        }else{
            this.adminUserMenu = [
                { link: ['/admin/dashboard'], title: 'Dashboard', activeUrls: ['/admin/dashboard'] },
                { link: ['/admin/activity'], title: 'Activity', activeUrls: ['/admin/activity'] },
                { link: ['/admin/user-management'], title: 'User Management', activeUrls: ['/admin/user-management', '/admin/doctor-profile', '/admin/biller-profile'] },
                { link: ['/admin/relationship'], title: ['Associations'], activeUrls: ['/admin/relationship'] },
                // {link: ['/report-generate'], title: 'RR-Gen', activeUrls: ['/report-generate', '/report-detail']},
                { link: ['/admin/training-system'], title: 'Training System', activeUrls: ['/admin/training-system'] },
                // {link: ['/admin/reports'], title: 'Report', activeUrls: ['/admin/reports']},
            ];
        }


        this.companyPath = window.location.pathname.includes('company');
        if (this.companyPath) {
            if (this.route.snapshot.params.companyId) {
                this.currentCompanyId = this.route.snapshot.params.companyId
            }
            this.company_id = this.route.snapshot.params.id;
            if (!this.company_id) {
                // this.company_id = this.route.snapshot.paramMap.get('id');
                this.company_id = this.route.parent.snapshot.params.id;
            }
            
        }
        this.companyUserMenu = [
            // {link: [`/company/${company_id}/profile`], title: 'Company Profile', activeUrls: [`/company/${company_id}/profile`]},
            // {link: ['/admin/dashboard'], title: 'Dashboard old', activeUrls: ['/admin/dashboard']},
            { link: [`/company/${this.company_id}/dashboard`], title: 'Dashboard', activeUrls: [`/company/${this.company_id}/dashboard`] },
            { link: [`/company/${this.company_id}/user-management`], title: 'Users', activeUrls: [`/company/${this.company_id}/user-management`] },
            { link: [`/company/${this.company_id}/facilities`], title: 'Facilities', activeUrls: [`/company/${this.company_id}/facilities`] },
            { link: [`/company/${this.company_id}/templates`], title: 'Templates', activeUrls: [`/company/${this.company_id}/templates`] },
            { link: [`/company/${this.company_id}/relationship`], title: ['Associations'], activeUrls: [`/company/${this.company_id}/relationship`] },
            { link: [`/company/${this.company_id}/patients`], title: ['Patients'], activeUrls: [`/company/${this.company_id}/patients`] },
            // {link: [`/company/${this.company_id}/reports`], title: 'Reports', activeUrls: [`/company/${this.company_id}/reports`]},
            // { link: [`/company/${this.company_id}/manage-cpt-codes`], title: 'Manage CPTs/ICDs', activeUrls: [`/company/${this.company_id}/manage-cpt-codes`, `/company/${this.company_id}/manage-cpt-codes`] },
            { link: [`/company/${this.company_id}/phrase-component`], title: 'Phrases', activeUrls: [`/company/${this.company_id}/phrase-component`] },
            // {link: [`/company/${company_id}/activity`], title: 'Activity', activeUrls: [`/company/${company_id}/activity`]},
            { link: [`/company/${this.company_id}/mips-dashboard`], title: 'MIPS', activeUrls: [`/company/${this.company_id}/mips-dashboard`] },
            { link: [`/company/${this.company_id}/reports`], title: 'Reports', activeUrls: [`/company/${this.company_id}/reports`] },
            { link: [`/company/${this.company_id}/scheduler`], title: 'Scheduler', activeUrls: [`/company/${this.company_id}/scheduler`] },
            { link: [`/company/${this.company_id}/order`], title: 'Orders', activeUrls: [`/company/${this.company_id}/order`] }
        ];

        if (global.base_url != 'https://prelive-woundcare.doctornow.io' && global.base_url != 'https://woundcare.doctornow.io')
            this.companyUserMenu.push({ link: [`/company/${this.company_id}/manage-cpt-codes`], title: 'Manage CPTs/ICDs', activeUrls: [`/company/${this.company_id}/manage-cpt-codes`, `/company/${this.company_id}/manage-cpt-codes`]});
        
        this.snfCompanyUserMenu = [
            { link: [`/company/${this.company_id}/dashboard`], title: 'Dashboard', activeUrls: [`/company/${this.company_id}/dashboard`] },
            { link: [`/company/${this.company_id}/user-management`], title: 'Users', activeUrls: [`/company/${this.company_id}/user-management`] },
            { link: [`/company/${this.company_id}/facilities`], title: 'Facilities', activeUrls: [`/company/${this.company_id}/facilities`] },
            { link: [`/company/${this.company_id}/templates`], title: 'Templates', activeUrls: [`/company/${this.company_id}/templates`] },
            { link: [`/company/${this.company_id}/relationship`], title: ['Associations'], activeUrls: [`/company/${this.company_id}/relationship`] },
            { link: [`/company/${this.company_id}/patients`], title: ['Patients'], activeUrls: [`/company/${this.company_id}/patients`] },
            { link: [`/company/${this.company_id}/phrase-component`], title: 'Phrases', activeUrls: [`/company/${this.company_id}/phrase-component`] },
            { link: [`/company/${this.company_id}/scheduler`], title: 'Scheduler', activeUrls: [`/company/${this.company_id}/scheduler`] }
        ];
        
        // if(this.currentUser?.additional_user_role == global.USER_TYPE.CNP) {
        //     this.companyUserMenu = this.companyUserMenu.filter(f => f.title != 'Charges');
        // }
        this.transAdminUserMenu = [
            { link: [`/company/${this.company_id}/dashboard`], title: 'Dashboard', activeUrls: [`/company/${this.company_id}/dashboard`] },
            { link: [`/company/${this.company_id}/relationship`], title: 'Associations', activeUrls: [`/company/${this.company_id}/relationship`] },
        ];
        this.normalUserMenu = [
            {
                link: ['/dashboard'],
                title: 'Dashboard',
                activeUrls: ['/dashboard', '/transcription-detail', '/transcription-create', '/transcription-view']
            },
            { link: ['/patients'], title: 'Patients', activeUrls: ['/patients', '/patient-add', '/patient-detail'] },
            // {link: ['/patient-rounding-sheets'], title: 'Census', activeUrls: ['/patient-rounding-sheets']},
            { link: ['/census'], title: 'Census', activeUrls: ['/census'] },
            { link: ['/drive'], title: 'Smart Drive', activeUrls: ['/drive'] },
            // {link: ['/report-generate'], title: 'RR-Gen', activeUrls: ['/report-generate', '/report-detail']},
            { link: ['/appointment-calandar'], title: 'Appointments', activeUrls: ['/appointment'] },
            { link: ['/appointment'], title: 'Calendar', activeUrls: ['/appointment'] },

            // {link: ['/feedback'], title: 'Feedback', activeUrls: ['/feedback']},
            // {link: ['/biller-dashboard'], title: 'Charge Capture', activeUrls: ['/biller-dashboard']}

        ];
        this.woundNurseMenu = [
            {
                link: ['/dashboard'],
                title: 'Dashboard',
                activeUrls: ['/dashboard', '/transcription-detail', '/transcription-create', '/transcription-view']
            },
            // { link: ['/census'], title: 'Census', activeUrls: ['/census'] },
             { link: ['/patients'], title: 'Patients', activeUrls: ['/patients', '/patient-add', '/patient-detail'] },
        ];
        this.nursingMenu = [
            { link: [`/nursing/dashboard`], title: 'Dashboard', activeUrls: [`/nursing/dashboard`] },
        ]
        this.userService.verifyUserCompany(this.currentUser.company_id).subscribe((response: any) => {
            if (response.status == 200) {
                this.isNurseCensusActive = response.data.enable_nurse_census
                this.isNursePatientsActive = response.data.enable_nurse_patients
                if (this.isNurseCensusActive) {
                    this.nursingMenu.push({ link: ['/census'], title: 'Census', activeUrls: ['/census'] },
                    )
                }
            }
        })
        this.menus = [];


        this.currentUser = this.authService.currentUser;
        if (this.currentUser.user_type === global.USER_TYPE.DOCTOR && this.currentUser.charge_status == true && this.currentUser?.additional_user_role != 'cnp') {
            this.normalUserMenu
                // .push({link: ['/provider-charge'], title: 'Charge', activeUrls: ['/provider-charge']})
                .push({ link: ['/biller-dashboard'], title: 'Charge Capture', activeUrls: ['/biller-dashboard'] });
        }
        this.normalUserMenu = this.normalUserMenu.filter(f => f.title != 'Calendar');
        //
        if (!this.authService.currentUser) {
            // if not login, stop and redirect to login #mohsin-dev
            localStorage.setItem('currentUser', 'null');
            window.location.href = '/login';
            //   this.router.navigate(['/login']);
            return;
        }
    }
    openFeedback() {
        this.dialog.open(FeedbackComponent, {
            height: '78%',
            width: '55%'
        });
    }
    newsExist(event) {
        if (event) {
            this.showNews = true;
        }
        else {
            this.showNews = false;
        }
    }
    setCompany(company) {
        this.commonService.setCompanySelected(company);
    }
    checkAuthForAdmin(param?) {
        // let optionalParam = null;
        // param ? optionalParam = param : null;
        this.authService.checkAuthForAdmin().subscribe((response: any) => {
            if (response.status === 200) {
                let url;
                if (param) {
            console.log("this.params", param);
                    url = this.router.serializeUrl(
                        this.router.createUrlTree([`/admin-login`, { params: param }])
                    );
                }
                else {
                    url = this.router.serializeUrl(
                        this.router.createUrlTree([`/admin-login`])
                    );
                }
                window.open(url, '_blank', "height=640,width=960,toolbar=yes,menubar=yes,scrollbars=yes,location=yes, titlebar=yes, status=no, directories=yes");
            }
            else if (response.status === 403) {
                this.router.navigate(['/403']);
            }
        });
    }

    async onClick(menu) {
        if (this.companyPath) {
            if (menu.title == 'Charges') {
                this.onChargeCatagotyClick()
            }
            else {
                this.router.navigate(menu.link)
            }
        }
        else {
            this.router.navigate(menu.link)
        }
    }


    initMenus() {
        if (this.currentCompanyType.includes("Wound")) {
            this.companyUserMenu.push(
                { link: [`/company/${this.company_id}/wound-derm`], title: 'Wound/DERM', activeUrls: [`/company/${this.company_id}/wound-derm`] }
            )
        };
        if(this.currentCompanyType === global.COMPANY_TYPE.SNF) {
            this.snfCompanyUserMenu.push(
                { link: [`/company/${this.company_id}/wound-derm`], title: 'Wound/DERM', activeUrls: [`/company/${this.company_id}/wound-derm`] }
            );
        }
        
        if (this.router.url.indexOf('/admin') === 0) {
            this.adminRoute = this.router.url;
        } else if (this.router.url.indexOf('/company') === 0) {
            this.companyRoute = this.router.url;
        }
       
        if (this.adminRoute) {
            this.menus = this.adminUserMenu;
        } else if (this.companyRoute && this.currentCompanyType != global.COMPANY_TYPE.SNF) {
            if (this.currentUser.admin_type == "full_admin" || this.currentUser.admin_type == "system_admin") {
                this.menus = this.companyUserMenu;
            } else {
                this.menus = this.transAdminUserMenu;
            }
        } else if (this.currentUser.user_type == 'nurse') {
            this.menus = this.nursingMenu;
        } else if(this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
            this.menus = this.woundNurseMenu;
        }else if(this.currentCompanyType === global.COMPANY_TYPE.SNF) {
            this.menus = this.snfCompanyUserMenu;
        } else {
            this.menus = this.normalUserMenu;
            this.frontEndMenu = true;
            if (this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER || this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER) {
                this.menus = this.menus.filter(menu => menu.title != 'Smart Drive');
            }
        }
        if(!this.show_outpatient_module ||  (this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER)){
            this.menus = this.menus.filter(menu => menu.title != 'Scheduler');
            this.menus = this.
            menus.filter(menu => menu.title != 'Appointments');
        }
        
    }
    async getCompanName() {
        let companyId = this.company_id;
        if (this.currentCompanyId) companyId = this.currentCompanyId;
        let response = await this.companyService.getCompanyName(companyId).toPromise();
        if (response.status == 200 && response.data) {
            console.log('response', response);
            this.currentCompanyName = response.data.name;
            this.currentCompanyType = response.data.company_type
            this.show_outpatient_module = response.data.show_outpatient_module
        }

    }
    ngOnDestroy(): void {
        if (this.addressSubscribtion) {
            this.addressSubscribtion.unsubscribe();
        }
        if (this.updatedUserNameSubscription) {
            this.updatedUserNameSubscription.unsubscribe();
        }
    }
    removePatientFromHeader(id){
        this.commonService.removeObject(id)
    }
    // updateContainerPadding(paddingValue: string): void {
    //     const containerElement = document.querySelector('.container');
    //     console.log('updateContainerPadding', paddingValue)
    //     const updatedPaddingValue = paddingValue + ' !important';
    //     if (containerElement) {
    //         console.log('fater', paddingValue)
    //       this.renderer.setStyle(containerElement, 'padding-top', paddingValue);
    //     }
    //   }
    updateContainerPadding(applyPadding: boolean): void {
        const containerElement = document.querySelector('.container');
      
        if (containerElement) {
          if (applyPadding) {
            containerElement.classList.add('container-important');
          } else {
            containerElement.classList.remove('container-important');
          }
        }
      }
    async ngOnInit() {
        this.previousUrl = this.previousRouteService.getPreviousUrl();
        this.route.url.subscribe(urlSegments => {
            const routeUrl = '/' + urlSegments.map(segment => segment.path).join('/');
            const isPatientDetail = /^\/patient-detail\/\w+$/.test(routeUrl);
            if ((routeUrl === '/appointment-calandar' || (isPatientDetail &&this.previousUrl === '/appointment-calandar')) || (this.previousUrl === '/appointment-calandar' && isPatientDetail)) {
                
              this.patientList = this.commonService.getPatientMenu();
              this.commonService.castPatientHeaderMenu.subscribe(menuItems => {
                this.patientList = menuItems;
                this.updateContainerPadding(this.patientList.length > 0);
              });
              this.updateContainerPadding(this.patientList.length > 0);
            }
        });
        await this.verifyUserCompanySync()
        await this.getCompanyLogo();
        this.updatedUserNameSubscription = this.commonService.castUpdatedUsername.subscribe((name: any) => {
            this.currentUser.first_name = name.first_name;
            this.currentUser.last_name = name.last_name;
            this.currentUser.title = name.title;
        });
        if (this.isCompanyRoute()) {
            await this.getCompanName();
        }
        // console.log("this.", this.currentUser);
        this.adjustMenuItems();

        if (this.currentUser.product_owner) {
            console.log('this.currentUser?.product_owner === true', this.currentUser);
            this.companyUserMenu.unshift(
                { link: [`/company/${this.currentUser.company_id}/manage-companies`], title: 'Practices', activeUrls: [`/manage-companies`] }, 
                { link: [`/company/${this.company_id}/notifications-settings`], title: 'Notifications', activeUrls: [`/company/${this.company_id}/notifications-settings`] },
            );
            this.snfCompanyUserMenu.unshift(
                { link: [`/company/${this.currentUser.company_id}/manage-companies`], title: 'Practices', activeUrls: [`/manage-companies`] }, 
                { link: [`/company/${this.company_id}/notifications-settings`], title: 'Notifications', activeUrls: [`/company/${this.company_id}/notifications-settings`] },
            );
            // this.companyUserMenu.push({ link: [`/company/${this.company_id}/general-report`], title: 'Reports', activeUrls: [`/company/${this.company_id}/general-report`] });
            console.log('this.companyUserMenu', this.companyUserMenu);

        }
        if(this.currentUser?.product_owner===true || this.currentUser?.admin_type === "full_admin" || this.currentUser?.admin_type === "system_admin"){
            this.companyUserMenu.push(
                { link: [`/company/${this.company_id}/merge`], title: 'Merge', activeUrls: [`/company/${this.company_id}/merge`] },
            )
        }
        // if (this.currentUser?.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
            // let index = this.normalUserMenu.indexOf({ link: ['/appointment'], title: 'Calendar', activeUrls: ['/appointment'] });
            // this.normalUserMenu.splice(index, 1);
        // }
        
        // console.log('this.show_outpatient_module',this.show_outpatient_module)
        this.initMenus();
        this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                const { url, urlAfterRedirects } = e;
                if (urlAfterRedirects.indexOf('/admin') === 0) {
                    this.adminRoute = url;
                } else if (urlAfterRedirects.indexOf('/admin') !== 0) {
                    this.adminRoute = false;
                }
                if (urlAfterRedirects.indexOf('/company') === 0) {
                    this.companyRoute = url;
                } else if (urlAfterRedirects.indexOf('/company') !== 0) {
                    this.companyRoute = false;
                }
               
                if (this.adminRoute) {
                    this.menus = this.adminUserMenu;
                } else if (this.companyRoute && this.currentCompanyType != global.COMPANY_TYPE.SNF) {
                    // this.menus = this.companyUserMenu;
                    if (this.currentUser.admin_type == "full_admin" || this.currentUser.admin_type == "system_admin") {
                        this.menus = this.companyUserMenu;
                    } else {
                        this.menus = this.transAdminUserMenu;
                    }
                } else if (this.currentUser.user_type == 'nurse') {
                    this.menus = this.nursingMenu;
                } else if(this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE ){
                    this.menus = this.woundNurseMenu;
                } else if(this.currentCompanyType === global.COMPANY_TYPE.SNF) {
                    this.menus = this.snfCompanyUserMenu;
                }
                else {
                    this.menus = this.normalUserMenu;
                }
                if (this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER || this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER) {
                    this.menus = this.menus.filter(menu => menu.title != 'Smart Drive');
                }
                if(!this.show_outpatient_module ||  (this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER)){
                    this.menus = this.menus.filter(menu => menu.title != 'Scheduler');
                    this.menus = this.
                    menus.filter(menu => menu.title != 'Appointments');
                }
            }
        });

        // check if user is doctor and has a facility otherwise forcefully show add facility dialog
        if (this.currentUser && this.currentUser.user_type == 1) {
            let facilities;
            this.addressSubscribtion = this.facilityService.castFacilities.subscribe(async _facilities => {
                if (Array.isArray(_facilities)) {
                    facilities = _facilities;
                }
                else {
                    const response: any = await this.facilityService.getMyFacilities().toPromise();
                    if (response.status === 200) {
                        facilities = response.data.array;
                    }
                }
                //commented with ref to https://doctornow.atlassian.net/browse/CFF-57
                // if (Array.isArray(facilities) && facilities.length === 0 && this.currentUser.has_pcc_account) {
                //     const facilityAddDialogRef = this.dialog.open(FacilityAddDialog, {
                //         width: '500px',
                //         disableClose: true,
                //         data: {disableClose: true}
                //     });
                //     facilityAddDialogRef.afterClosed().subscribe((facility: IFacility) => {
                //         if (facility) {
                //             this.dialog.open(FacilityDialog, {width: '80%'});
                //         }
                //     });
                // }
            })

        }

        // if (this.currentUser.account_type === 'company') {
        //     this.companyService.castCompany.subscribe(company => this.company = company);
        // }
        let profilePic;
        this.commonService.updatedUsername.subscribe(profile => {
            profilePic = profile;
            this.currentUser.profile_pic = profilePic?.profile_pic;
        });


        
    }
    async verifyUserCompanySync() {
        if (this.currentUser?.company_id) {
            try {
            const response: any = await this.userService.verifyUserCompany(this.currentUser.company_id).toPromise();
            if (response.status == 200) {
                console.log(response.data.show_outpatient_module, 'company');
                this.show_outpatient_module = response.data?.show_outpatient_module;
                if (response.data.user_id == this.currentUser._id) {
                this.hasCompany = true;
                this.companyData = response.data;
                // localStorage.setItem('company_id', JSON.stringify(this.companyData._id));
                }
            }
            } catch (error) {
            // Handle error
            }
        }
        console.log('this.show_outpatient_module', this.show_outpatient_module);
    }
    async getCompanyLogo() {
        // console.log('resss');
        const companyCode = localStorage.getItem('companyCode');
        console.log('companyCodecompanyCode',companyCode);
        this.companyLogoAndMonogramService.castCompanyLogo.subscribe((res:any)=>{
            if(res){
                console.log('resss',res);
                this.companylogoPath = res;
            }else{
                console.log('else res',res);
                this.defaultImg = "../../../assets/img/DN.png";
            }
            if(!res && companyCode != '' && companyCode != undefined) {
                this.loginService.getCompanyLogo(this.companyCode, this.currentUser.company_id).subscribe((res: any) => {
                    if (res.status = 200) {
                        console.log('hellloooooooooo',res);
                        localStorage.setItem('companyCode',res.data[0].company_code);
                        if (res.data[0].monogram_aws_path) {
                            this.companylogoPath = res.data[0].monogram_aws_path;
                            this.companyLogoAndMonogramService.setCompanyMonoGram(this.companylogoPath);
                        }
                        if(res.data[0].logo_aws_path){
                            this.companylogoPathAfterLogin = res.data[0].logo_aws_path;
                            this.companyLogoAndMonogramService.setCompanyLogo(this.companylogoPathAfterLogin);
                        }
                        if (!res.data[0].monogram_aws_path) {
                            console.log('../../../assets/img/DNlogo-new.png'); 
                            this.defaultImg = "../../../assets/img/DNlogo-new.png";
                        }
                    } else {
                        this.defaultImg = "../../../assets/img/DNlogo-new.png";
                    }
                });
            }
        });
    }

    /*
    * Check if route current to  activeUrls of menu #mohsin-dev
    */
    isActive(menu) {
        const currentUrl = this.router.url;
        for (let url of menu.activeUrls) {
            if ((currentUrl.indexOf(url) !== -1)) {
                return true;
            }
        }
        return false;
    }

    logout() {
        Promise.all([
            lastValueFrom(this.authService.LoginActivity({ operation: "logout", platform: "web" })),
            lastValueFrom(this.authService.logoutUser())
        ]);

        this.authService.redirectAfterLogout();

        // this.companyCode = localStorage.getItem('companyCode');
        // if (this.companyCode) {
        //     this.router.navigateByUrl(`/login/${this.companyCode}`);
        // } else {
        //     this.router.navigateByUrl('/login')
        // }
        // localStorage.removeItem('companyCode');
        // this.authService.cleanupLogout();
        
        // setTimeout(() => {
        //     window.location.href = '/login';
        // });
    }
    addNews() {
        this.addNewsDialogRef = this.dialog.open(AddNewsComponent, { width: '80%' })
    }
    certClicked() {
        this.addNewsDialogRef = this.dialog.open(CertificationComponent, { width: '40%', height: '40%' })
    }

    onFacilityClick() {
        this.facilityDialogRef = this.dialog.open(FacilityDialog, { width: '80%' });
    }
    onInsuranceCompanyClick() {
        this.insuranceCompanyDialogRef = this.dialog.open(InsuranceCompanyComponent, { width: '80%' });
    }
    onRRGenClick() {
        this.router.navigateByUrl('/report-generate')
    }
    onChargeCatagotyClick() {
        // this.chargesCatagoriesDialogComponent = this.dialog.open(ChargesCatagoriesDialogComponent,
        //     {
        //         width: '80%',
        //         data: {
        //             company_id: this.company_id
        //         }
        //     })
        this.router.navigateByUrl(`/company/${this.company_id}/manage-cpt-codes`);
    }
    onCompanyProfileClick() {
        this.router.navigateByUrl(`/company/${this.company_id}/profile`);
    }
    onReport() {
        if (this.companyPath) {
            this.router.navigateByUrl(`/company/${this.currentUser.company_id}/reports`);
        }
        else {
            this.router.navigateByUrl('/admin/reports');
        }
    }
    eSignClick() {
        this.eSignComponent = this.dialog.open(ESignComponent, { width: '80%' })
    }
    chargeClick() {
        this.router.navigate(['/manage-charge']);
    }

    isAdminRoute() {
        return this.router.url.indexOf('/admin') === 0
    }

    isCompanyRoute() {
        return this.router.url.indexOf('/company') === 0
    }

    isCurrentUserManager() {
        return parseInt(this.currentUser.user_type) >= 3
    }
    isCurrentUseraNurse() {
        return (this.currentUser.user_type == 'nurse')
    }
    isCurrentUserBiller() {
        return (this.currentUser.user_type == 'biller' || this.currentUser.user_type == 6)
    }
    isShowMenu(menu) {
        const excludeMenus = ['/appointment', '/patient-rounding-sheets'];
        if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER && excludeMenus.includes(menu.link[0])) {
            return false;
        }
        return true;
    }
    get user_welcome_str() {
        let str = 'Welcome';
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));

        // if(this.currentUser.account_type !== 'company') {
        str += ` ${currentUser.first_name} ${currentUser.last_name}`.slice(0, 25);
        // } else {
        //     str += ` ${this.company?.name || ''}`
        // }
        if ((this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) && this.currentUser.account_type !== 'company') {
            str += `, ${currentUser.title}`
        }
        return str;
    }
    openReleaseDialog(addNotes) {
        this.router.navigateByUrl(`/company/${this.currentUser.company_id}/app-release`);

        // const dialogRef = this.dialog.open(OpenReleaseDialogComponent, {
        //     width: '800px',
        //     data: addNotes
        //   });
        //   dialogRef.afterClosed().subscribe(async (addNotes) => {
        //       console.log('addnotes',addNotes);
        //     if (addNotes) {
        //       this.addNotes(addNotes).subscribe((response: any) => {
        //         if (response.status == 200) {
        //           console.log('response', response);
        //         }
        //       });
        //     } else {
        //       console.log('something went wrong!');
        //     }

        //   });
    }
    addNotes(note) {
        console.log('addReleasenote/add');
        const details = {
            ...this.authService.authObject,
            createdBy: this.currentUser._id,
            note
        }
        return this.httpclient
            .post(global.url + API_URL.RELEASE.addNotes, { details: details }).pipe();
    }
    onRowClick(patient_id) {
		this.router.navigate([`patient-detail/${patient_id}`]);
	}
    onSettingClick(){
         const dialogRef = this.dialog.open(OpenSettingDialogComponent, {
            width: '120vw',
            height: '90vh',
            data: this.company_id
          });
    }
    notificationSettings(){
        this.commonService.setNotificationSettings()
    }

    @HostListener('window:resize', ['$event'])  
    onResize(event) {  
      this.adjustMenuItems();
    }  

    adjustMenuItems() {
        this.screenWidth = window.innerWidth;  

        if (this.screenWidth < 1400) {
            this.numberOfMenuItems = 8;
        } else  if (this.screenWidth < 1600) {
            this.numberOfMenuItems = 10;
        } else  if (this.screenWidth < 1800) {
            this.numberOfMenuItems = 12;
        } else  if (this.screenWidth < 2000) {
            this.numberOfMenuItems = 14;
        } else {
            this.numberOfMenuItems = this.menus.length;
        }
    }
}
