import { Component, ElementRef, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { WoundCareWidgetService } from "src/app/includes/note-sidebar/wound-care-widget/wound-care-widget.service";
import { PatientAddService } from "src/app/includes/patient-add/patient-add-service";
import { AuthService } from "src/app/services/auth.service";
export interface WoundImageSnapshotDialogData {
    wound: any;
    uploadType: "camera" | "computer";
}
@Component({
    selector: "app-camera-capture",
    templateUrl: "./camera-capture.component.html",
    styleUrls: ["./camera-capture.component.css"]
})
export class CameraCaptureComponent implements OnDestroy {
    wound: any;
    image: any;
    uploadType: string;
    selectedImages: any[] = [];
    WIDTH = 640;
    HEIGHT = 480;
    @ViewChild("video")
    public video: ElementRef;
    @ViewChild("canvas")
    public canvas: ElementRef;
    captures: string[] = [];
    error: any;
    isCaptured: boolean;
    constructor(
        public dialogRef: MatDialogRef<CameraCaptureComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private sanitizer: DomSanitizer,
        private authService: AuthService,
        private woundCareWidgetService: WoundCareWidgetService,
        private toastr: ToastrService,
        private _patientAddServie: PatientAddService,
    ) {
        if (data) {
            this.wound = data.wound;
            this.uploadType = data.uploadType;
        }
    }
    async ngAfterViewInit() {
        await this.setupDevices();
    }
    ngOnDestroy(): void {
        if (this.video.nativeElement.srcObject) {
            this.video.nativeElement.srcObject.getTracks().forEach(track => {
                track.stop();
            });
        }
    }
    async setupDevices() {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true
                });
                if (stream) {
                    this.video.nativeElement.srcObject = stream;
                    this.video.nativeElement.play();
                    this.error = null;
                } else {
                    this.error = "You have no output video device";
                }
            } catch (e) {
                this.error = e;
            }
        }
    }
    capture() {
        this.drawImageToCanvas(this.video.nativeElement);
        this.captures.push(this.canvas.nativeElement.toDataURL("image/png"));
        this.isCaptured = true;
        this.canvas.nativeElement.toBlob((image) => {
            const file = new File([image], `image-${Date.now()}.png`, { type: 'image/png' });
            this.selectedImages.push(file);
        }, 'image/png');
    }
    drawImageToCanvas(image: any) {
        this.canvas.nativeElement
            .getContext("2d")
            .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
    }
    removeImage(image) {
        const index = this.captures.indexOf(image);
        if (index > -1) {
            this.captures.splice(index, 1);
            this.selectedImages.splice(index, 1);
        }
    }
    imagePreviewUrl(image) {
        const src = URL.createObjectURL(image);
        return this.sanitizer.bypassSecurityTrustUrl(src);
    }
    save() {
        this.dialogRef.close(this.selectedImages);
    }
}
