import { Injectable } from '@angular/core';
import { Subject, lastValueFrom } from 'rxjs';
import { PCCService } from "./pcc.service";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../includes/global';
import { API_URL } from '../../environments/api-url';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { CommonService } from './common.service';
import idleTimer from 'idle-timer';
import * as LogRocket from 'logrocket';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';


type LoginSource = 'portal' | 'login.doctornow.io' | 'sso';
@Injectable({
    providedIn: "root"
})
export class AuthService {
    private _currentUser: any;
    private sessionInterval: any;
    public onUserAuthChange: Subject<any> = new Subject<any>();
    private _loginSource: LoginSource;
    global = global;

    private logoutTimer:any;

    unauthRoutes = [
        "/auth",
        "/login",
        "/register",
        "/forgot-password",
        "/reset-password",
        "/contact-us",
    ];

    constructor(
        private httpclient: HttpClient,
        private router: Router,
        private toastr: ToastrService,
        private httpClient: HttpClient,
        private _commonService: CommonService,
        private _route: ActivatedRoute,
        private dialogRef: MatDialog
    ) {
        try {
            const currentUser = localStorage.getItem("currentUser");
            this._currentUser = JSON.parse(currentUser);
            this.onUserAuthChange.next(this._currentUser);
            this.router.events.subscribe(this.handleRouteChange.bind(this));
            
        } catch (error) {
            this._currentUser = null;
        }
    }

    handleRouteChange(next) {
        if (next instanceof NavigationEnd) {
            console.log("Checking if unauth route", next.url);
            if (this.unauthRoutes.some(url => next.url.startsWith(url))) {
                console.log("Unauth route, clearing session interval");
                this.clearCheckSessionInterval();
                if(this.logoutTimer) {
                    this.logoutTimer.destroy();
                    this.logoutTimer = null;
                }
            } else if (!this.sessionInterval) {
                console.log("Subscribing to user auth change");
                if(this._currentUser) {
                    this.handleUserAuthChange.bind(this)(this._currentUser)
                }
                this.onUserAuthChange.subscribe(this.handleUserAuthChange.bind(this));
            }
        }
    }


    handleUserAuthChange(currentUser) {
        if (currentUser && !this.sessionInterval) {
            if (global.ENVIRONMENT !== global.LOCAL || true) {
                console.log("Auth route, setting session interval");
                this.setCheckSessionInterval();
            }
        } else {
            this.clearCheckSessionInterval();
        }

        
        if(global.ENVIRONMENT !== global.LOCAL) {
            if(currentUser && !this.logoutTimer) {
                this.logoutTimer = this.setupIdleLogoutTimer();
            } else if(this.logoutTimer) {
                this.logoutTimer.destroy();
                this.logoutTimer = null;
            }
        }
    }

    setCheckSessionInterval() {
        this.sessionInterval = setInterval(() => {
            this.checkSession();
        }, 10 * 60 * 1000);
    }
    clearCheckSessionInterval() {
        if (this.sessionInterval) clearInterval(this.sessionInterval);
        this.sessionInterval = null;
    }
    async checkSession() {
        try {
            let response: any = await this.httpClient
                .post(global.url + API_URL.AUTH.checkSession, {})
                .toPromise();
            console.log("checking session status : ", response.status);
            if (response.status != 200) {
                console.log("session expired now");
                
                this.clearCheckSessionInterval();
                this.toastr.clear();
                this.toastr.error("Your session expired");
                this.cleanupLogout();
                window.location.href = "/login";
            }
        } catch (error) {
        }
    }

    setupIdleLogoutTimer() {
        const localStorageKey = 'scheduledIdleLogoutAt';
    
        const updateLastActivity = () => {
            localStorage.setItem(localStorageKey, moment().add(global.LOGOUT_IDLE_TIME, "minute").toISOString());
        };
    
        const handleIdle = () => {
            console.log("logoutTimer > You're idle!");
            const logoutTimerReturnURL = window.location.href.replace(window.location.origin, '');
            window.stop();
            Promise.all([
                lastValueFrom(this.LoginActivity({ operation: "logout", platform: "web" })),
                lastValueFrom(this.logoutUser())
            ]);
    
            this.redirectAfterLogout({
                logoutTimerReturnURL
            });
            this.logoutTimer = null;
        };
    
        const checkIdleStatus = () => {
            let lastActivity = moment(localStorage.getItem(localStorageKey));
            if(!lastActivity.isValid()) {
                lastActivity = moment();
            }
            if (moment().isAfter(lastActivity)) {
                console.log("You're idle! Logging you out for security.");
                handleIdle();
            }
        };
    
        // Attach event listeners
        ['load', 'mousemove', 'scroll', 'keypress'].forEach(event => {
            document.addEventListener(event, updateLastActivity);
        });
    
        // Initial setup
        updateLastActivity();
        const interval = setInterval(checkIdleStatus, 1000);
    
        return {
            destroy: () => {
                // Remove event listeners
                ['load', 'mousemove', 'scroll', 'keypress'].forEach(event => {
                    document.removeEventListener(event, updateLastActivity);
                });
                clearInterval(interval);
            }
        };
    }
    
    triggerLogoutTimer() {
        const timer = idleTimer({
            // function to fire after idle
            callback: () => {
                console.log("logoutTimer > You're idle!");
                const logoutTimerReturnURL = window.location.href.replace(window.location.origin, '');
                window.stop();  
                Promise.all([
                    lastValueFrom(this.LoginActivity({ operation: "logout", platform: "web" })),
                    lastValueFrom(this.logoutUser())
                ])

                this.redirectAfterLogout({
                    logoutTimerReturnURL
                });
                this.logoutTimer = null;
            },
            // function to fire when active
            activeCallback: () => {
                console.log('logoutTimer > Activated logout timer')
            },
            // Amount of time in milliseconds before becoming idle. default 60000
            idleTime: global.LOGOUT_IDLE_TIME * 60 * 1000
          });
          return timer;
    }
    set currentUser(user) {
        this._currentUser = user;
        localStorage.setItem("currentUser", JSON.stringify(user));
        this.onUserAuthChange.next(user);
        if(this.currentUser?._id){
            let userID = this.currentUser._id.toString() 
            let res = LogRocket.identify(userID, {
            // name: this.currentUser, // Optional: You can provide user-related metadata.
            email: this.currentUser.email
            }); 
        }
    }


    get currentUser() {
        return this._currentUser;
    }

    public get userType() {
        const u = JSON.parse(localStorage.getItem("currentUser"));
        return u?.user_type;
    }

    public get authObject() {
        const u = JSON.parse(localStorage.getItem("currentUser"));
        if (u) {
            const auth = {
                id: u?._id,
                usertype: u?.user_type,
                auth_key: u?.auth_key,
                is_activated: u?.is_activated,
                token: u.token // AWS Cognito Token
            };
            return auth;
        } else return;
    }

    public getToken() {
        const tokenStr = localStorage.getItem("token");
        try {
            if (tokenStr) {
                const token = JSON.parse(tokenStr);
                return token.idToken;
            } else if (this.authObject && this.authObject.auth_key) {
                return this.authObject.auth_key.endsWith(".WEB")
                    ? this.authObject.auth_key
                    : this.authObject.auth_key.endsWith(".WEB");
            }
        } catch (error) {
            return null;
        }
    }

    public setToken(token) {
        try {
            if (token) {
                const tokenStr = JSON.stringify(token);
                localStorage.setItem("token", tokenStr);
                return token;
            }
        } catch (error) {
            return null;
        }
    }

    async logout() {
        localStorage.removeItem("currentUser");
    }

    logoutUser() {
        // this.toastr.info('Session expired', 'Logging out');
        // if (this.currentUser.pcc_auth) {
        //     try {
        //         await this.pccService.revokeAuth().toPromise();
        //     } catch (error) {
        //
        //         console.log(error.toString());
        //     }
        // }
        // this.currentUser = null;
        // this.onUserAuthChange.next(null);
        // const keysToRemoveExcludes = ['remembered_email'];
        // let i = 0;
        // let key = localStorage.key(0);
        // while (key !== null) {
        //     if (keysToRemoveExcludes.indexOf(key) === -1) {
        //         localStorage.removeItem(key);
        //     }
        //     i++;
        //     key = localStorage.key(i);
        // }
        //
        //
        // i = 0;
        // key = sessionStorage.key(0);
        // while (key !== null) {
        //     sessionStorage.removeItem(key);
        //     i++;
        //     key = sessionStorage.key(i);
        // }
        //
        // setTimeout(() => {
        //     window.location.href = '/login';
        // });

        return this.httpclient
            .post(global.url + API_URL.AUTH.logout, {
                details: this.authObject
            })
            .pipe();
    }

    cleanupLogout() {
        this.currentUser = null;
        this.onUserAuthChange.next(null);
        // localStorage.removeItem('currentUser');
        // localStorage.removeItem('currentUserAuth');
        // localStorage.clear();
        // this.router.navigate(['/login']);
        const keysToRemoveExcludes = ["remembered_email"];
        
        Object.keys(localStorage).forEach((key) => {
            if (keysToRemoveExcludes.indexOf(key) === -1) {
                localStorage.removeItem(key);
            }
        });

        // let i = 0;
        // let key = localStorage.key(0);
        // while (key !== null) {
        //     if (keysToRemoveExcludes.indexOf(key) === -1) {
        //         localStorage.removeItem(key);
        //     }
        //     i++;
        //     key = localStorage.key(i);
        // }
        localStorage.clear();
        // localStorage.removeItem("iOReQA");
        // localStorage.removeItem("dictFilters");
        // localStorage.removeItem("tranFilters");
        // localStorage.removeItem("noteDateFilter");
        // localStorage.removeItem("dateFilter");
        // localStorage.removeItem("showPrimaryFilter");
        // localStorage.removeItem("is_checked");
        // localStorage.removeItem("google_auth");
        // localStorage.removeItem("patient_list_provider");
        // localStorage.removeItem("patient_list_facility");
        // localStorage.removeItem("drive_facility_folder_id");
        // localStorage.removeItem("drive_facility_folder");
        // localStorage.removeItem("censusPatientSort");
        // localStorage.removeItem("token");
        // localStorage.removeItem("previousUrl");
        // localStorage.removeItem("customPatientMenuItems");
        // localStorage.removeItem("prev_user_type");
        // localStorage.removeItem('companyCode');
        // localStorage.removeItem('companyLogoPath');
        // this._commonService.removeLocalStorageItem("admin-charge-capture");
        // this._commonService.removeLocalStorageItem("admin-Dashboard");
        // i = 0;
        // key = sessionStorage.key(0);
        // while (key !== null) {
        //     sessionStorage.removeItem(key);
        //     i++;
        //     key = sessionStorage.key(i);
        // }

        Object.keys(sessionStorage).forEach((key) => {
            sessionStorage.removeItem(key);
        });
    }

    redirectToHome() {
        let location;
        // if(this.currentUser.user_type == "0"){
        //   this.router.navigate(['/admin']);
        // }else{
        //   this.router.navigate(['/dashboard']);
        // }

        // if (this.currentUser.user_type === '6') {
        //     this.router.navigate(['/biller-notes']);
        // }
        // else if (this.currentUser.user_type === '3' ||
        //     this.currentUser.user_type === '4' || this.currentUser.user_type === '5') {
        //     this.router.navigate(['/appointment']);
        // } else if (this.currentUser.user_type == 'nurse') {
        //     this.router.navigate(['/nursing']);
        // }
        // else if (this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
        //     this.router.navigate(['/census']);
        // }
        // else {
        //     this.router.navigate(['/dashboard']);
        // }

        const logoutTimerReturnURL = this._route.snapshot.queryParams.logoutTimerReturnURL;

        switch (true) {
            // case this.currentUser.user_type == "0":
            //     location = '/admin';
            //     break;
            case !!(logoutTimerReturnURL?.trim().length): 
                location = logoutTimerReturnURL
                break;
            case this.currentUser.user_type == "6":
                location = "/biller-charges";
                break;
            case this.currentUser.user_type === "3" ||
                this.currentUser.user_type === "4" ||
                this.currentUser.user_type === "5":
                location = "/appointment";
                break;
            case this.currentUser.user_type === "nurse":
                location = "/nursing";
                break;
            default:
                location = "/dashboard";
                break;
        }
        // this.router.navigate(['/dashboard']);

        // redirect and clear cache
        if([global.LOCAL].includes(global.ENVIRONMENT)) {
            this.router.navigate([location]);
        } else {
            this.hardReload(location);
        }
        this.router.navigate([location]);
    }

    hardReload(location: string = "") {
        // ref: https://stackoverflow.com/questions/55127650/location-reloadtrue-is-deprecated

        const $form = document.createElement("form");
        $form.method = "POST";
        $form.action = location;
        document.body.appendChild($form);
        $form.submit();
    }

    async getClientDeviceDetails() {
        const fpPromise = FingerprintJS.load();

        // Get the visitor identifier when you need it.
        const fp = await fpPromise;
        const result = await fp.get();
        return result;
    }

    setOtherUserSettings(otherSettings) {
        const oldOtherSettings = this.currentUser.other_settings || {};
        const newOtherSettings = Object.assign(oldOtherSettings, otherSettings);
        const details = {
            ...this.authObject,
            otherSettings: newOtherSettings
        };
        return this.httpclient
            .post(global.url + API_URL.AUTH.updateOtherSettings, { details })
            .pipe(
                tap((response: any) => {
                    // this.currentUser = {
                    //     ...this.currentUser,
                    //     other_settings: response.data.other_settings
                    // }
                    this.currentUser.other_settings =
                        response.data.other_settings;
                })
            );
    }
    LoginActivity(data) {
        const details = {
            ...this.authObject,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.AUTH.LoginActivity, { details: details })
            .pipe();
    }
    setRecentPccLogin(data) {
        const details = {
            ...this.authObject,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.AUTH.setRecentPccLogin, {
                details: details
            })
            .pipe();
    }
    getLoginActivity() {
        const details = {
            ...this.authObject
        };
        return this.httpclient
            .post(global.url + API_URL.AUTH.getLoginActivity, {
                details: details
            })
            .pipe();
    }
    dataExportApi() {
        return this.httpclient
            .post(global.url + API_URL.AUTH.dataExportApi, {})
            .pipe();
    }
    checkAuthForAdmin() {
        const details = {
            ...this.authObject
        };
        return this.httpclient
            .post(global.url + API_URL.AUTH.checkAuthForAdmin, {
                details: details
            })
            .pipe();
    }
    get loginSource() {
        const loginSource = localStorage.getItem("loginSource");
        if (loginSource) {
            this._loginSource = loginSource as LoginSource;
            return this._loginSource;
        }
    }
    set loginSource(_loginSource: LoginSource) {
        if(!_loginSource) {
            _loginSource = 'portal';
        }
        this._loginSource = _loginSource;
        localStorage.setItem("loginSource", _loginSource);
    }

    redirectAfterLogout(queryParams?:Record<string,string>) {
        let pathName;
        const companyPath = window.location.pathname.includes('company');
        if (companyPath) {
            pathName = '/admin-login'
            // this.router.navigateByUrl('/admin-login')
        } else {
            if(this.loginSource === 'sso') {
                // this.cleanupLogout();
                setTimeout(() => {
                    window.location.href = global.SSO_LOGIN_URL;
                })
            } else {
                const companyCode = localStorage.getItem('companyCode');
                if (companyCode) {
                    pathName = `/login/${companyCode}`
                    // this.router.navigateByUrl(`/login/${companyCode}`);
                } else {
                    pathName = `/login`
                    // this.router.navigateByUrl('/login')
                }
                localStorage.removeItem('companyCode');
                // this.cleanupLogout();
            }

          

            
        }

        // close all mat dialogs if any
        this.dialogRef.closeAll();

        if(queryParams) {
            const searchParams = new URLSearchParams(queryParams);
            pathName += '?' + searchParams.toString()
        }
        this.router.navigateByUrl(pathName)

        // close all mat dialogs if any
        this.dialogRef.closeAll();

        if(queryParams) {
            const searchParams = new URLSearchParams(queryParams);
            pathName += '?' + searchParams.toString()
        }
        this.cleanupLogout();
        this.router.navigateByUrl(pathName)

    }

    
    canUploadNoteToPCCUsingTwoLegged(userIds) {
        const user = this.currentUser;
        const allowedPCCTwoLegged = user.permissions.find(p => p.type ==='user' && p.key === 'allowed_pcc_two_legged_note_upload');
        if(!allowedPCCTwoLegged) return false;

        const isNoteOwner = userIds.indexOf(user._id) > -1;
        if(allowedPCCTwoLegged && isNoteOwner) {
            return true;
        }
        
        const isAdmin = user.admin_type === 'full_admin';
        if(allowedPCCTwoLegged && isAdmin) {
            return true;
        }

        return false;
    }

    
    canUploadReportToPCCUsingTwoLegged() {
        const user = this.currentUser;
        const allowedPCCTwoLegged = user.permissions.find(p => p.type ==='user' && p.key === 'allowed_pcc_two_legged_report_upload');
        if(!allowedPCCTwoLegged) return false;

        return true;
    }

    setCurrentUser(currentUser): void {
        localStorage.setItem("currentUser", JSON.stringify(currentUser));
        this._currentUser = currentUser;
        this.onUserAuthChange.next(this._currentUser);
    }
}
