import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FacilityService } from 'src/app/services/facility.service';
import { PatientRoundingSheetService } from '../patient-rounding-sheet.service';
import * as global from '../../global'
import moment from 'moment'
import { DashboardService } from '../../dashboard/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { OranizationService } from '../../organization-dialog/oranization.service';
import { lastValueFrom } from 'rxjs';

export interface UpdatePatientRoundingSheetDialogData {
  pusherDataPayload: any
}
export interface filter_facility_id {
  filter_facility_id: string
}


@Component({
  selector: 'app-update-patient-rounding-sheet-dialog',
  templateUrl: './update-patient-rounding-sheet-dialog.html',
  styleUrls: ['./update-patient-rounding-sheet-dialog.css']
})
export class UpdatePatientRoundingSheetDialog
  implements OnInit {
  global = global;
  currentUser: any;
  currentUserDetails;
  name: string;
  companySide: Boolean
  date: Date
  minDate = moment(new Date()).subtract(7, 'day').toDate();
  oldData: any
  timezone: any;
  serviceDate: any;
  date_obj;
  @ViewChild('censusSelect') censusSelect;
  shouldShowOrgsDropdown: boolean = false;
  orgs: any;
  organization: any;
  selectedOrg: any;

  constructor(
    public dialogRef: MatDialogRef<UpdatePatientRoundingSheetDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: any,


    private _authService: AuthService,
    private _patientRoundingSheetService: PatientRoundingSheetService,
    private toastr: ToastrService,
    private organizationService: OranizationService

  ) {
    this.currentUser = this._authService.currentUser;
    this.currentUserDetails = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }
    // this.date = data.date;
    this.name = data.name;
    this.timezone = data.time_zone;
    this.date_obj = data.date_obj;

    // if (data.facility.facility.pcc_timeZone) {
    //   this.newName = moment(new Date(this.name).toLocaleString('en-US', { timeZone: data.facility.facility.pcc_timeZone })).format("MMM D, YYYY");
    // }
    // else if (data.facility.facility.timeZone) {
    //   this.newName = moment(new Date(this.name).toLocaleString('en-US', { timeZone: data.facility.facility.timeZone })).format("MMM D, YYYY");
    // }
    // else {
    //   this.newName = moment(new Date(this.name).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format("MMM D, YYYY");
    // }
    // if (!this.newName.includes('Invalid date')) {
    //   this.name = this.newName;
    // }
    this.oldData = this.data
  }

  async ngOnInit() {
    if (window.location.pathname.includes('company') && window.location.pathname.includes('provider_id')) {
      console.log("path", window.location.pathname);
      this.companySide = true;
    }
    if(this.currentUser.user_type === global.USER_TYPE.DOCTOR || this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
      await this.initOrgs();
    }
    this.initializeDate();
    if(this.data.organization) {
      this.selectedOrg = this.data.organization._id;
      this.organization = this.data.organization;
    }
  }

  async initOrgs() {
    let isProvider = this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const populate = { organization_id: 'title tin' }
    const result: any = await lastValueFrom(this.organizationService.getOrganizationUserRelation({ user_ids: { $in: [isProvider ? this.currentUser._id : this.data.provider_id] }, is_active: true }, populate));
    if (result.status == 200) {
      this.orgs = result.data;
      if (Array.isArray(this.orgs) && this.orgs.length > 0) {
        this.shouldShowOrgsDropdown = true;
      }
    }
  }

  onChangeOrganization($event) {
    const index = this.orgs.findIndex((og)=> og.organization_id._id.toString() === $event.value.toString());
    this.selectedOrg = $event.value;
    this.organization = this.orgs[index].organization_id;
  }

  initializeDate(){ 
      // let timezone = patientRoundingSheet.facility.pcc_timeZone? patientRoundingSheet.facility.pcc_timeZone: patientRoundingSheet.facility.timeZone;
      this.serviceDate = moment(this.date).utc().tz(this.timezone)
      this.date = this.serviceDate;
      //   return serviceDate;
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>){
    this.serviceDate = event.value
    this.date = this.serviceDate 
    return;
  }

  doesHaveData(){
    // console.log("censusSelect", this.data);
    if(this.data.submit>0||this.data.draft>0||this.data.saved>0||this.data.signed>0){
      return true;
    }
    else return false;
  }
  handleDateChange(date) {
    const [year, month, day] = date.split('-');

    this.date_obj = {
      year: parseInt(year),
      month: parseInt(month),
      day: parseInt(day)
    }
  }
  UpdatePatientRoundingSheet() {
    this.attachTimeToDate();
    // if (this.newName.includes('Invalid date')) {
      const details : any = {
        ...this.currentUserDetails,
        _id: this.data.rounding_sheet_id,
        name: this.name,
        date: moment(this.date).toDate(),
        date_obj: this.date_obj,
      }
      if(this.organization) {
        details.organization = this.organization;
      }
      const infoToast = this.toastr.info('Updating Patient Census', 'Please wait', { disableTimeOut: true });
      this._patientRoundingSheetService.updatePatientRoundingSheet(details).subscribe((response: any) => {
        if (response.status === 200) {
          this.toastr.clear(infoToast.toastId)
          this.dialogRef.close('Updated');
        }
      },
        () => this.toastr.error('Something went wrong updating rounding sheet', 'Failed'),
        // () => {
        //   console.log("this")
        // this.toastr.clear(infoToast.toastId)
        // this.dialogRef.close()
        // }

      )
    // } else {
    //   const details = {
    //     ...this.currentUserDetails,
    //     _id: this.data.rounding_sheet_id,
    //     name: moment(this.date).toDate(),
    //     date: moment(this.date).toDate()
    //   }
    //   const infoToast = this.toastr.info('Updating Patient Census', 'Please wait', { disableTimeOut: true });
    //   this._patientRoundingSheetService.updatePatientRoundingSheet(details).subscribe((response: any) => {
    //     if (response.status === 200) {
    //       this.toastr.clear(infoToast.toastId)
    //       this.dialogRef.close('Updated');
    //     }
    //   },
    //     () => this.toastr.error('Something went wrong updating rounding sheet', 'Failed'),
    //     // () => {
    //     //   console.log("this")
    //     // this.toastr.clear(infoToast.toastId)
    //     // this.dialogRef.close()
    //     // }

    //   )
    // }
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.date || !this.name) return;
    // !this.name ||
    this.UpdatePatientRoundingSheet()
  }

  attachTimeToDate() {

    let clientOffSet = moment(this.date).utcOffset();
    let facOffSet = moment().tz(this.timezone).utcOffset();
    // let facOffSet = moment(aa).utcOffset();

    // console.log('111111', moment(event.value).utc().utcOffset()); 
    // console.log('222222', moment(facOffSet).utcOffset());
    let gValue, lValue;
    if (clientOffSet >= facOffSet) {
      gValue = clientOffSet;
      lValue = facOffSet;
    }
    else {
      gValue = facOffSet;
      lValue = clientOffSet;
    }
    // let diffBetweenOffset = 300 - -300 = 300 + 300 = 600
    // console.log("clientOffSet: ", clientOffSet);
    // console.log("facOffSet: ", facOffSet);

    let diffBetweenOffset = gValue - lValue
    // console.log("diffBetweenOffset: ", diffBetweenOffset);
    let newDate; 
    // newDate = moment(event.value).add(diffBetweenOffset, 'minutes')
    if (diffBetweenOffset >= 0) {
      if (clientOffSet < 0 && facOffSet < 0) {
        newDate = moment(this.date).subtract(diffBetweenOffset, 'minutes')
      }
      else {
        newDate = moment(this.date).add(diffBetweenOffset, 'minutes')
      }
    }
    else {
      newDate = moment(this.date).add(diffBetweenOffset, 'minutes')
    }
    this.date = newDate;


    // const dateStr = moment(this.date).format('YYYY-MM-DD');
    // const timeStr = moment().format('HH:mm');


    // let date = moment(dateStr);
    // const time = moment(timeStr, 'HH:mm');


    // date.set({
    //   hour: time.get('hour'),
    //   minute: time.get('minute'),
    //   second: time.get('second')
    // });
    // this.date = date.toDate();
    // // console.log('attachTimeToDate', this.date);

  }

  getYear(date_obj){
    if(!date_obj) return '';
    return date_obj.year;
  }

  getMonth(date_obj){
    if(!date_obj) return '';
    return date_obj.month < 10 ? `0${date_obj.month}` : date_obj.month;
    
  }

  getDay(date_obj){
    if(!date_obj) return '';
    return date_obj.day < 10 ? `0${date_obj.day}` : date_obj.day;
    
  }

  getDate(date: any) {
    return date
  }

  formatDateObjToString(date_obj) {
    const year = date_obj.year;
    const month = date_obj.month < 10 ? `0${date_obj.month}` : date_obj.month;
    const day = date_obj.day < 10 ? `0${date_obj.day}` : date_obj.day;
    return `${year}-${month}-${day}`;
  }

}
