import {NgModule} from '@angular/core';
import {HeaderComponent} from '../includes/header/header.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NotificationsComponent} from '../includes/notifications/notifications.component';
// import {FlexLayoutModule} from '@angular/flex-layout';
import { PageEditFormComponent } from '../components/report_generate/page-edit-form/page-edit-form.component';
import { ReportViewComponent } from '../components/report_generate/report-view/report-view.component';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import { PccWidgetComponent } from '../includes/pcc-widget/pcc-widget.component';
import { MaterialModule } from '../components/material.module';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {MatMenuModule} from "@angular/material/menu";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {PatientDocumentsListComponent} from '../includes/patient-documents-list/patient-documents-list.component'
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import {PatientIntakeFormComponent} from '../components/twilio/patient-intake-form-page/patient-intake-form/patient-intake-form.component'
import {AlphabeticIndexBar} from '../includes/alphabetic-index-bar/alphabetic-index-bar.component'
import {MatRadioModule} from "@angular/material/radio";
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { FacilityTitleComponent } from '../shared/facility-title/facility-title.component';
import { PatientNameComponent } from '../shared/patient-name/patient-name.component';
import { InputWithDropdownComponent } from '../includes/input-with-dropdown/input-with-dropdown.component';
import { CensusListComponent } from '../includes/census-list/census-list.component';
import { BillerDashboardComponent } from '../includes/biller-dashboard/biller-dashboard/biller-dashboard.component';
import { BillerHeaderComponent } from '../includes/biller-header/biller-header/biller-header.component';
import { TemplateExplorerComponent } from '../includes/templates/template-explorer/template-explorer.component';
// import {NgxLoadingModule, ngxLoadingAnimationTypes} from 'ngx-loading';
import { NotificationBarComponent } from '../includes/Notification-bar/notification-bar/notification-bar.component';
import { CensusListV1Component } from '../includes/census-list-v1/census-list-v1.component';
import { SearchFacilityPipe,SearchProviderPipe,SearchTemplatePipe,SearchCompanyPipe,OrderByPipe, SearchYearPipe,SearchDNPatientPipe, SearchPccPatientPipe, searchCPTCodePipe,HighlightWordsPipe  } from '../filter.pipe';
import { PatientAddFormComponent } from '../includes/patient-add-form/patient-add-form.component';
import { PatientAddFormDialogComponent } from '../includes/patient-add-form/patient-add-form-dialog/patient-add-form-dialog.component';
import { AdminSideTemplatesComponent } from '../includes/adminSide-templates/admin-side-templates/admin-side-templates.component';
import { DatePickerWidgetComponent } from '../includes/date-picker-widget/date-picker-widget.component';
import { BusinessOfficerHeaderComponent } from '../includes/business-officer-header/business-officer-header.component';
import { CensusListV2Component } from '../includes/census-list-v2/census-list-v2.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CapitalizeFirstDirective } from '../capitalize-first.directive';
import { UserPermissionComponent } from '../components/user-permission/user-permission.component';
import { GoogleAuthenticatorComponent } from '../includes/profile/google-authenticator/google-authenticator.component';
import { TemplateExpression } from '../shared/pipes/template-expression';
import { DragDropModule } from '@angular/cdk/drag-drop';
const config = {
    // animationType: ngxLoadingAnimationTypes.circle,
    primaryColour: '#ffffff',
    secondaryColour: '#ccc',
    tertiaryColour: '#ffffff',
    backdropBorderRadius: '3px'
};


@NgModule({
    declarations: [HeaderComponent, NotificationsComponent, PageEditFormComponent, ReportViewComponent, UserPermissionComponent,
        PccWidgetComponent, PatientDocumentsListComponent,InputWithDropdownComponent, DatePickerWidgetComponent, TemplateExpression
        // ,PatientIntakeFormComponent
        , AlphabeticIndexBar,FacilityTitleComponent, PatientNameComponent, CensusListComponent, CensusListV2Component, PatientAddFormComponent,PatientAddFormDialogComponent, CensusListV1Component, AdminSideTemplatesComponent,
        BillerDashboardComponent,BillerHeaderComponent, BusinessOfficerHeaderComponent, TemplateExplorerComponent,NotificationBarComponent,SearchFacilityPipe,SearchProviderPipe,SearchTemplatePipe,SearchCompanyPipe,OrderByPipe, SearchDNPatientPipe, SearchPccPatientPipe, SearchYearPipe,searchCPTCodePipe,CapitalizeFirstDirective, GoogleAuthenticatorComponent,HighlightWordsPipe],

    imports: [RouterModule, CommonModule, MatMenuModule, MaterialModule,MatCheckboxModule,MatProgressSpinnerModule,CKEditorModule,NgxDocViewerModule,
         MatToolbarModule, MatIconModule, MatButtonModule, 
        //  FlexLayoutModule, 
         NgxUiLoaderModule,
         NgMultiSelectDropDownModule, FormsModule, ReactiveFormsModule,MatAutocompleteModule,NgxDaterangepickerMd.forRoot({format: 'MMM DD, YYYY',}),
         MatRadioModule, PdfViewerModule,DragDropModule
        //  NgxLoadingModule.forRoot(config),
        //  CountdownTimerModule.forRoot()
        ],

    exports: [HeaderComponent, NotificationsComponent, PageEditFormComponent, UserPermissionComponent,MatCheckboxModule, DatePickerWidgetComponent, NgxDaterangepickerMd,
        ReportViewComponent,NgxUiLoaderModule,PccWidgetComponent,NgMultiSelectDropDownModule, FormsModule, ReactiveFormsModule, PatientDocumentsListComponent,PatientAddFormComponent,PatientAddFormDialogComponent
        // ,PatientIntakeFormComponent
        , AlphabeticIndexBar, FacilityTitleComponent, PatientNameComponent,InputWithDropdownComponent, CensusListComponent,CensusListV2Component, CensusListV1Component,
        BillerDashboardComponent,BillerHeaderComponent, BusinessOfficerHeaderComponent, TemplateExplorerComponent,NotificationBarComponent, SearchFacilityPipe,SearchProviderPipe,SearchTemplatePipe,SearchCompanyPipe,OrderByPipe,SearchDNPatientPipe, SearchPccPatientPipe,SearchYearPipe, searchCPTCodePipe,
        GoogleAuthenticatorComponent,HighlightWordsPipe, TemplateExpression],

    providers: []
})
export class LayoutModule {
}
