import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../global';
import { API_URL } from "../../../environments/api-url";
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PatientDocumentService {
  currentUser: any;
  constructor(private httpclient: HttpClient, private authService: AuthService) {
    this.currentUser = authService.currentUser
  }

  uploadDocumentForScrubbing(file, patient_id, company_id, typeOfDocument) {

    const payload = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      title: file.name,
      patient_id,
      company_id,
      typeOfDocument
    };

    const formData: any = new FormData();
    formData.append('file', file);

    for (let key of Object.keys(payload)) {
      formData.append(key, payload[key]);
    }
    return this.httpclient.post(global.url + API_URL.PATIENT_DOCUMENT.uploadDocumentForScrubbing, formData).pipe();
  }

  getTaggedText(patient_id, company_id) {
    const details = {
      patient_id: patient_id,
      company_id: company_id
    }
    return this.httpclient.post(global.url + API_URL.PATIENT_DOCUMENT.getTaggedText, { details });
  }

  removeTaggedText(document_id, text) {
    const details = {
      document_id: document_id,
      text: text
    }
    return this.httpclient.post(global.url + API_URL.PATIENT_DOCUMENT.removeTaggedText, { details });
  }

  tagTextFromPdf(document_id, patient_id, text) {
    const details = {
      document_id: document_id,
      patient_id: patient_id,
      text: text,
      company_id: this.currentUser.company_id
    }
    return this.httpclient.post(global.url + API_URL.PATIENT_DOCUMENT.tagTextFromPdf, { details });
  }

  uploadPatientDocument(file: File, patient_id: string, company_id: string, typeOfDocument: string) {

      const payload = {
        id: this.currentUser._id,
        auth_key: this.currentUser.auth_key,
        usertype: this.currentUser.user_type,
        title: file.name,
        patient_id,
        company_id,
        typeOfDocument
      };

      const formData: any = new FormData();
      formData.append('file', file);

      for (let key of Object.keys(payload)) {
        formData.append(key, payload[key]);
      }
    return this.httpclient.post(global.url + API_URL.PATIENT_DOCUMENT.uploadPatientDocument, formData).pipe();
  }


  getPatientDocument(patient_document_id) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      patient_document_id
    }
    return this.httpclient
      .post(global.url + API_URL.PATIENT_DOCUMENT.getPatientDocument, { details });
  }
  getPatientDocuments(patient_id) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      patient_id
    }
    return this.httpclient
      .post(global.url + API_URL.PATIENT_DOCUMENT.getPatientDocuments, { details });
  }
  getPatientDocumentsAndIntakeForms(patient_id, company_id) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      patient_id,
      company_id
    }
    return this.httpclient
      .post(global.url + API_URL.PATIENT_DOCUMENT.getPatientDocumentsAndIntakeForms, { details });
  }

  getPatientDocs(patient_id, company_id) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      patient_id,
      company_id
    }
    return this.httpclient
      .post(global.url + API_URL.PATIENT_DOCUMENT.getPatientDocs, { details });
  }
  deletePatientDoc(document_id) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      document_id
    }
    return this.httpclient
      .post(global.url + API_URL.PATIENT_DOCUMENT.deletePatientDoc, { details });
  }

  updatePatientDocument(filter, updateQuery) {
    return this.httpclient
      .post(global.url + API_URL.PATIENT_DOCUMENT.updatePatientDocument,
        {
          filter,
          updateQuery
        });
  }
  generateViewPdfUrl(patienteDocument){
    const payload = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      ...patienteDocument
    };
    return this.httpclient.post(global.url + API_URL.PATIENT_DOCUMENT.generateViewPdfUrl, {patienteDocument: payload}).pipe();
  }
}
