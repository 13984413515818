import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router,ParamMap } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PCCService } from 'src/app/services/pcc.service';
import { PusherService } from 'src/app/services/pusher-service';
import {eventTypes} from '../../constants/pcc_webhook_constants'
// import {PatientImporterDialog} from '../patient-rounding-sheets/patient-importer-dialog/patient-importer-dialog';
import { ToastrService } from 'ngx-toastr';
import {MatDialog} from "@angular/material/dialog";
import * as global from '../global';
import { FacilityService } from 'src/app/services/facility.service';
import { PccWidgetService } from './pcc-widget.service';
import { NoteEditorService } from '../note-editor/note-editor.service';
import { lastValueFrom } from 'rxjs';
import { SnfWcNurseAssociationService } from '../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';

@Component({
  selector: 'app-pcc-widget',
  templateUrl: './pcc-widget.component.html',
  styleUrls: ['./pcc-widget.component.css']
})
export class PccWidgetComponent implements OnInit {

  public pccToken;
  public currentUser;
  public pccUserinfo;
  public inprogress = false;

  public roleDialogRef;

  public facilities:any[] = [];
  selectedFacility: any;
  selected: string;
  saveNoteStatus: boolean = false;
  note: any;
  constructor(
      private _authService:AuthService, 
      public _pccService:PCCService, 
      // private _pusherService:PusherService, 
      // private _toastr:ToastrService,
      // private dialog: MatDialog,
      private facilityService:FacilityService,
      private _route: ActivatedRoute,
      private pccWidgetService: PccWidgetService,
      private router: Router,
      private noteEditorService: NoteEditorService,
      private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    ) {
    this.currentUser = this._authService.currentUser;
    this.inprogress = this._pccService.inprogress;
    this.pccUserinfo = this._pccService.pccUserinfo;
    this._pccService.inprogress$.subscribe((inprogress:boolean) => {
      this.inprogress = inprogress;
    })
    this._pccService.pccUserinfo$.subscribe((pccUserinfo:any) => {
      this.pccUserinfo = pccUserinfo;
    });
    // Moved to pcc service
    // this.subscribeWebhookEvents();
    
   }
  // subscribeWebhookEvents() {
  //   this._pusherService.subscribePCCWebhookNotificationChannel(this.currentUser._id, Object.values(eventTypes))
      
  //   this._pusherService.pcc_webhook_notify.subscribe((pusherData:any) => {
  //     console.log(pusherData);
      
  //     if(pusherData) {
  //       this._pccService.webhookNotficationReceive.next(pusherData);
  //       this._toastr.info(pusherData.payload.message, pusherData.eventType)
  //       // .onTap.subscribe(() => {
  //       //   this.handleWebhookNotificationToastClick(pusherData)
  //       // })
  //     }
  //   });
  // }
  ngOnInit() {

    // this._pccService.pccUserinfoChange.subscribe((pccUserinfo:any) => {
    //   if(pccUserinfo) {
    //     this._pccService.subscribeWebhook().subscribe((response) => {
    //     })
    //   }
    // }) 
    // this.showPatientImporterDialog({})
    this.initFacilities();
    // console.log(window.location);
    
    this._pccService.pccUserinfo$.subscribe(() => {
      if(this.pccUserinfo) {
        this.initFacilities();
      }
    });
  }


  
  async initFacilities() {
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      const filter = {
        associated_snf_wc_nurse_ids: this.currentUser._id,
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1,
        pcc_orgUuid: 1
      };

      const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (response.status === 200 && response.data.length > 0) {
        this.facilities = response.data;
      }
    } else {
    
      this.facilityService.castFacilities.subscribe(facilities => {
        if(facilities) {
          this.facilities = facilities.filter(f => f.pcc_facId); 
          console.log(this.facilities); 
          // facilities.map(f=> {
          //   if(f.pcc_facId && !this.facilities.includes(f)) {
          //     this.facilities.push(f);
          //   }
          // });   
        }
      });
      // console.log(this.facilities);

      // if(this.facilities?.length > 1){
      //   this.selectedFacility = this.facilities[0];
      //   this.selected = "first";
      //   this.facilities.splice(0,1);
      // }
    }
  }

 async handlePccBtnClick() {
    if(!this.pccUserinfo && window.location.href.includes("note-editor/")) {
    this.pccWidgetService.castSaveNoteStatus.subscribe(status => {
      console.log("status",status);
      this.saveNoteStatus = status;
    });
if(this.saveNoteStatus === true) {
    this.pccWidgetService.castNote.subscribe(note => {
      // console.log("note",note);
      this.note = note;
    });
    if(this.note.is_signed !== "true") {
    const res: any = await this.noteEditorService.saveNote(this.note).toPromise();
    console.log(res.data);
    await this.router.navigate([`/note-editor/${res.data.noteId}`], { replaceUrl: true });
    }
  }
  if(!this.pccUserinfo) {
      this._pccService.goToLoginPCCScreen();
    }

}
else {
if(!this.pccUserinfo) {
      this._pccService.goToLoginPCCScreen();
    }
}

  }

  // handleWebhookNotificationToastClick(pusherData) {
  //   console.log('handleWebhookNotificationToastClick',pusherData)
  //   switch(pusherData.event_type) {
  //     case eventTypes.PATIENT_ADMIT:
  //       this.showPatientImporterDialog(pusherData.payload)
  //   }
  // }
  
  // showPatientImporterDialog(pusherDataPayload) {
  //   console.log('showPatientImporterDialog');
    
  //   this.roleDialogRef = this.dialog.open(PatientImporterDialog, {width: '50%', data: {pusherDataPayload, action: 'import'}});
  // }

  facilityNames() {
    return this.facilities.map(f => f.title).join(', ');
  }
  defaultFacility() {
    return this.facilities.find(fac => this._pccService.isDefaultLoggedInFacility(fac))
  }
 
  handleLogoutPCC(e) {
    e.preventDefault();
    e.stopPropagation();
    this._pccService.logoutPCC();
  }
}
