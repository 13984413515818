import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

// DELETE CONFIRMATION DIALOG
export interface DeleteConfirmationDialogData {
    title:String
  }
  @Component({
    selector: 'delete-confirmation-dialog',
    templateUrl: './delete-confirmation-dialog.html',
    styleUrls: ['./drive.component.css']
  })
  export class DeleteConfirmationDialog {
    constructor(
      public dialogRef: MatDialogRef<DeleteConfirmationDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DeleteConfirmationDialogData
      ) {      
      }
  }
