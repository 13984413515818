import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Template} from './templates.component'
import * as global from '../global';
import {AuthService} from "src/app/services/auth.service";
import {API_URL} from "../../../environments/api-url";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class TemplatesService {
    currentUser: any = null;
    userInfo: any = {};


    constructor(private httpclient: HttpClient,
                private _authService: AuthService) {
        // const currentUser = localStorage.getItem('currentUser');
        const currentUser = this._authService.currentUser;
        if (currentUser) {
            // this.currentUser = JSON.parse(currentUser);
            this.currentUser = currentUser;
            if (this.currentUser) {
                this.userInfo.id = this.currentUser._id;
                this.userInfo.usertype = this.currentUser.user_type;
                this.userInfo.auth_key = this.currentUser.auth_key;
                this.userInfo.company_id = this.currentUser.company_id;
            }
        }
    }

    
    getTemplateById(template_id) {
        const payload = {
            ...this.userInfo,
            template_id
        };
        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.getTemplateById, {template: payload}).pipe();
    }

    getMyTemplates(userId?) {
        let payload = {
            ...this.userInfo
        };
        if(userId) {
            payload.userId = userId;
        }
        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.getMyTemplates, {template: payload}).pipe();
    }
    
    getTemplatesByFacilityId(facility_id?, owner_id?, shouldDecryptText = true) {
      const payload = {
        ...this.userInfo,
        facility_id, 
        owner_id,
        shouldDecryptText
      };

      return this.httpclient
          .post(global.url + API_URL.TEMPLATE.getTemplatesByFacilityId, {template: payload}).pipe();
    }
    getSystemTemplatesByFacilityId(facility_id, owner_id?, shouldDecryptText = true) {
      const payload = {
        ...this.userInfo,
        facility_id, 
        owner_id,
        shouldDecryptText
      };
      return this.httpclient
          .post(global.url + API_URL.TEMPLATE.getSystemTemplatesByFacilityId, {template: payload}).pipe();
    }
    getFollowUpTemplateByFacilityId(facility_id, owner_id?) {
      const payload = {
        ...this.userInfo,
        facility_id, 
        owner_id
      };
      return this.httpclient
          .post(global.url + API_URL.TEMPLATE.getFollowUpTemplateByFacilityId, {template: payload}).pipe();
    }

    getInitialTemplatesByFacilityIds( facility_ids ) {
        const payload = {
            ...this.userInfo,
            facility_ids,
            owner_id: this.userInfo.id
        };

        return this.httpclient
            .post( global.url + API_URL.TEMPLATE.getInitialTemplatesByFacilityIds, { template: payload });
    }

    addNewTemplate(template: Template, company_id= null, type = null) {
        const payload = {
            ...this.userInfo,
            ...template,
            company_id,
            template_type: type
        };
        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.addNewTemplate, {template: payload}).pipe();
    }

    updateTemplate(template: Template) {
        const payload = {
            ...this.userInfo,
            template_id: template._id,
            ...template
        };
        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.updateTemplate, {template: payload}).pipe();
    }

    deleteTemplate(template: Template) {
        const payload = {
            ...this.userInfo,
            template_id: template._id,
            is_deleted: template.is_deleted
        };
        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.deleteTemplate, {template: payload}).pipe();
    }

    /**
     * note builder services
     */
    addPhrase(data) {

        data.user_id = this.userInfo.id;
        const payload = {
            ...this.userInfo,
            data
        };
        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.addPhrase, {template: payload}).pipe();
    }
    getPhrases() {

        const payload = {
            ...this.userInfo
        };
        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.getPhrases, {template: payload}).pipe();
    }
    addNoteBuilder(data) {
        const payload = {
            ...this.userInfo,
            data
        };
        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.addNoteBuilder, {template: payload}).pipe();
    }
    getNoteBuilderList() {
        const payload = {
            ...this.userInfo
        };
        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.getNoteBuilder, {template: payload}).pipe();
    }
    getDkeys(data) {
        const payload = {
            ...this.userInfo,
            data
        };
        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.getDkeys, payload).pipe();
    }
    applyNoteBuilder(data) {
        const payload = {
            ...this.userInfo,
            data
        };

        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.applyNoteBuilder, payload).pipe();
    }
    
  previewNoteAsPCC(data) {

    const details = {
      ...this._authService.authObject,

      ...data
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.previewNoteAsPCC, {details}).pipe();
  }
  
  cleanTemplateText(templateText) {
        let cleanedTemplateText = templateText.replace(/&nbsp;/g, ' ');
        
        // Remove junk tags from template
        const $tmp = document.createElement('div');
        $tmp.innerHTML = cleanedTemplateText;

        // remove tags that have no content
        while(true) {
            const $spans = Array.from($tmp.querySelectorAll('span'));
            let isChanged = false;
            for (const $span of $spans) {
            if($span.innerText.trim().length == 0) {
                $span.remove();
                isChanged = true;
            }
            }
            if (!isChanged) {
            break;
            }
        }
        cleanedTemplateText = $tmp.innerHTML;
        return cleanedTemplateText;
    }
    getTemplateFacilityAssociations(filter, payload?) {
        return this.httpclient.post(global.url + API_URL.TEMPLATE.getTemplateFacilityAssociations, { filter, payload: payload || {} });
    }
    updateTemplateAssociation(filter, query) {
        return this.httpclient.post(global.url + API_URL.TEMPLATE.updateTemplateAssociation, { filter, query });
    }

    getTemplates(filter, project, populate = null) {
        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.getTemplates, { filter, project, populate });
    }

    decryptText(encryptedText) {
        return this.httpclient
            .post(global.url + API_URL.TEMPLATE.decryptText, { encryptedText });
    }
}
