<app-biller-header *ngIf="currentUser.user_type == 6 || currentUser.user_type == global.USER_TYPE.BILLER">
</app-biller-header>
<app-header
    *ngIf="(currentUser.user_type != 6 || currentUser.user_type != global.USER_TYPE.BILLER) && !admin && isBillerDashboard">
</app-header>
<div style=" flex-direction: row; box-sizing: border-box; display: inherit !important;">
    <div [ngClass]="(currentUser.user_type == '6')?'  grid-container':'grid-container-provider'"
        [class.container]="!admin" [loaderId]="loaderId" ngxUiLoaderBlurred>

        <div *ngIf="currentUser.user_type == 6" class="grid-item "
            style="overflow-y: auto;flex-direction: column;box-sizing: border-box;display: flex;min-width: max-content;">

            <div class="flex" style="background: white; padding-left: 10px;padding-right: 10px; border-radius: 10px;">
                <mat-form-field appearance="fill" style="align-self: flex-end;">
                    <mat-label>Search Provider by Name</mat-label>
                    <input matInput (keydown)="applyFilter2($event.target.value)" [(ngModel)]="filters.provider">
                </mat-form-field>

            </div>
            <div style="height: 3px;"></div>
            <div class="flex" style="overflow: auto;  background: white; height: calc(100vh - 211px)">
                <table mat-table [dataSource]="dataSource2">
                    <ng-container matColumnDef="provider">
                        <th mat-header-cell *matHeaderCellDef>Provider</th>
                        <td mat-cell *matCellDef="let element" style="width: 20% ;cursor: pointer;"
                            (click)="$event.preventDefault();prividerClicked(element)">
                            {{getProviderName(element)}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"
                        [ngClass]="{'highlighted':isSlected(row)}"></tr>
                </table>
            </div>
        </div>


        <div class="grid-item" style="overflow-y: auto;flex-direction: column;box-sizing: border-box;"
            [ngClass]="admin? 'user-side-charges':'user-side-charges'">
            <div class="flex gap-5" 
                
                [ngClass]="{'billerFilters' : isBillerDashboard, 'dashboardFilters' : !isBillerDashboard }">
                <div class="flex items-center gap-2" style="flex: 40%; max-width: 75%;">
                    <mat-form-field appearance="fill" style="max-width: 138px;">
                        <mat-label>Charge Status</mat-label>
                        <mat-select [(ngModel)]="filters.chargeType"
                            (selectionChange)="changeChargeType(); initProviderSide()">
                            <mat-option value='draft'>Drafted ({{draftCount}})</mat-option>
                            <mat-option value='submit'>Submitted ({{submitCount}})</mat-option>
                            <mat-option value='billed'>Billed ({{billedCount}})</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" style="max-width: 145px;" *ngIf="filters.chargeType === 'billed'">
                        <mat-label>Bill Status</mat-label>
                        <mat-select [(ngModel)]="filters.billStatus"
                            (selectionChange)="onChangeBillStatus($event)">
                            <mat-option value='all'>All</mat-option>
                            <mat-option value=''>None</mat-option>
                            <mat-option value='billed'>Billed</mat-option>
                            <mat-option value='coded'>Coded</mat-option>
                            <mat-option value='addendum_req'>Addendum REQ</mat-option>
                            <mat-option value='on_hold'>On Hold</mat-option>
                            <mat-option value='missed_visit'>Missed Visit</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Search Patient by Name</mat-label>
                        <input matInput (keyup)="applyFilterPatient($event.target.value)"
                            [(ngModel)]="filters.patient">
                    </mat-form-field>
                    <input *ngIf="firstDoctor" class="mat-like-input" type="text" [(ngModel)]="filters.dos" ngxDaterangepickerMd style="width: 200px;"
                        [locale]="{applyLabel: 'ok', format: 'MM/DD/YYYY'}" placeholder="Date of Service"
                        (datesUpdated)="changeCreationDate($event)" class="mat-like-input" [ranges]="preDateRanges"
                        [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                        [showClearButton]="false" />
                    <mat-form-field>
                        <mat-label *ngIf="currentUser.user_type !== global.USER_TYPE.SNF_WC_NURSE">Providers</mat-label>
                        <mat-label *ngIf="currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE">SNF WC Nurses</mat-label>
                        <mat-select name="provider" #provider="ngModel" [(ngModel)]="filter_provider_id"
                        (ngModelChange)="handleproviderChange(provider)">
                        <input  placeholder="Search" [(ngModel)]="searchTextProvider"
                            [ngModelOptions]="{standalone: true}" style="color:#000;height: 40px;padding-left: 5px; border: none; width: 100%;" />
                        <mat-option *ngFor="let provider of providersList | searchProvider : searchTextProvider"
                            value="{{provider._id}}">
                            {{provider.first_name}} {{provider.last_name}} {{provider.title}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" [ngStyle]="{'width': !isBillerDashboard ? '200px' : 'null'}">
                        <mat-label>Select by Facility</mat-label>
                        <mat-select [(ngModel)]="filters.facility" (selectionChange)="applyFilterFacility($event)">
                            <input  placeholder="Search" [(ngModel)]="searchText"
                                style="height: 40px;padding-left: 5px; border: none; width: 100%;" (keydown)="$event.stopPropagation()">
                            <mat-option [value]="selected">--All--</mat-option>
                            <mat-option placeholder="Facility"
                                *ngFor="let facility of allFacilities | searchFacility : searchText"
                                [value]="facility._id">
                                <app-facility-title [facility]="facility"></app-facility-title>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" [ngStyle]="{'width': !isBillerDashboard ? '174px' : 'null'}">
                        <mat-label>Face Sheet</mat-label>
                        <mat-select value="all" (selectionChange)="applyFaceSheetFilter($event.value)"
                            [(ngModel)]="faceSheetFilter">
                            <mat-option value="all">All</mat-option>
                            <mat-option value="upload">Missing Facesheet</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex justify-end items-center gap-2" style="flex: 60%; min-width: auto;">
                    <button class="dn-button-primary" mat-raised-button (click)="clearFilter()">
                        <span class="small-fs"> Clear Filter</span>
                    </button>
                    <button class="dn-button-primary" mat-raised-button (click)="deleteAll()"
                        *ngIf="chargesIDs.length > 0 && filters.chargeType == 'draft'">Delete
                    </button>
                    <button class="dn-button-primary" mat-raised-button (click)="captureCharges()"
                        *ngIf="filters.chargeType == 'submit'"><span class="small-fs"> Generate Report</span>
                    </button>
                    <button class="dn-button-primary" mat-raised-button (click)="submitAll()"
                        *ngIf="chargesIDs.length > 0 && filters.chargeType == 'draft'">Submit
                    </button>
                </div>
            </div>


            <div style="height: 3px;"></div>

            <div class="flex gap-2"
                style="overflow: auto; background: white; margin-top: 5px;height: calc(100vh - 170px); border-radius: 10px;"
                [ngClass]="admin? 'adminStyle': ''">
                <table *ngIf="!loading" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                    style="align-self: flex-start;">

                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-checkbox #myCheckbox (change)="selectChargeAll($event);"></mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style="width: 50px; padding-left: 10px; text-align: center; cursor: pointer;">
                            <mat-checkbox
                                (change)="selectCharge(element, $event);" [checked]="selectedCharge(element)" [disabled]="currentUser.portal_type === global.PORTAL_TYPE.NON_WC ? element.disabled : false">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="checkBox">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed</th>
                        <td mat-cell *matCellDef="let element"
                            style="padding-left: 10px; text-align: center; cursor: pointer;">
                            <mat-checkbox (change)="changeCheckBox(element, $event);"
                                [checked]="checkBoxValue(element)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="visit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="position: fixed; white-space: nowrap !important;">Date
                            of Service</th>
                        <td mat-cell *matCellDef="let element" style="cursor: pointer;">{{
                            getVisit(element)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="position: fixed;">Visit Date</th>
                        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                            {{getVisitDate(element )}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="create_time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="position: fixed;">
                            Created At</th>
                        <td mat-cell *matCellDef="let element" style="width: 15% ; cursor: pointer;">
                            {{element.createdAt | date : 'MM/dd/yyyy h:mm a'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="patient_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="position: fixed;">Name
                        </th>
                        <td mat-cell *matCellDef="let element" style="cursor: pointer;" (click)=goToPatientDetailScreen(element);>
                            {{getPatientName(element)}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Facility</th>
                        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                            <app-facility-title [facility]="element.facility_id_ref"></app-facility-title>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="face_sheet">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Face Sheet</th>
                        <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                            <div class="flex gap-1">
                                <button mat-icon-button matTooltip="Upload Face Sheet"
                                    (click)="handleUploadFacesheetClick(element, index)">
                                    <mat-icon>file_upload</mat-icon>
                                </button>

                                <button mat-icon-button matTooltip="Downlaod Face Sheet"
                                    [disabled]="!downloadFaceSheetActive(element, index)"
                                    (click)="handleDownloadFacesheetClick(element, index)">
                                    <mat-icon>file_download</mat-icon>
                                </button>

                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="audit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Audit</th>
                        <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                            <div style="color: blue;" (click)="auditClicked(element)">View</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="cpt_code">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>CPT</th>
                        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                            <div>
                                <span>{{element.cptString}}</span>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="icd_code">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ICD10</th>
                        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                            <div>
                                <span>{{element.icdString}}</span>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="biller_note">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap !important;">Biller Note</th>
                        <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                            <div *ngIf="element.note!=''" style="color: blue;" (click)="viewBillerNote(element.note)">
                                view</div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="operation">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                            <button mat-raised-button class="dn-button-primary"
                                *ngIf="element.patient_id?.pcc_patientId"
                                [routerLink]="['/patient-detail/' + element.patient_id._id + '/facesheet']">Facesheet</button>

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="approve">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;"></th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer; ">

                            <mat-form-field appearance="fill" style="align-self: flex-end; margin-right: 5px;"
                                *ngIf="billingComapny != null && billingComapny.length > 1 ">
                                <mat-label>Resend to Billing Company</mat-label>
                                <mat-select value="all"
                                    (selectionChange)="billingCompanyselection($event.value, element)">
                                    <mat-option *ngFor="let billing of billingComapnies(element) " [value]="billing">
                                        {{billing.billingCompanyId.name}}
                                    </mat-option>
                                    <mat-option value="">Send to All</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-raised-button class="dn-button-primary" style="margin-right: 5px;"
                                *ngIf="billingComapny != null && billingComapny.length == 1"
                                (click)="billingCompanyselection(billingComapny[0], element)">{{getValue(billingComapny[0])}}</button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="note">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
                            <span *ngIf="element.note_id && element.note_id.is_signed === 'false'"
                                matTooltip="View Note" matTooltipPosition="above" class="material-icons"
                                (click)="viewNote(element)">
                                description
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
                            <i matTooltip="view" matTooltipPosition="above" class="icon icon-view"
                                (click)="patientClicked(element)"></i>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
                            <i matTooltip="Edit" matTooltipPosition="above" class="icon icon-edit"
                                (click)="editChargeClicked(element)"
                                *ngIf="checkVoidCensus(element?.rounding_sheet_id) && checkDuration(element)"></i>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
                            <i matTooltip="Delete" matTooltipPosition="above" class="icon icon-delete"
                                (click)="deleteChargeClicked(element)"
                                *ngIf="(element.is_approved !== 'true' && element.is_approved !== true) && checkVoidCensus(element?.rounding_sheet_id)"></i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>