import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import * as global from '../includes/global';
import { API_URL } from "../../environments/api-url";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import moment from 'moment'
import * as _ from 'lodash';
import { CommonService } from './common.service';
import { timeout } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class WoundService {


  currentUser: any;
  constructor(private httpclient: HttpClient,
    private _authService: AuthService,
    private _commonService: CommonService,
  ) {
    this.currentUser = this._authService.currentUser;
  }
  addNewHeaderinWoundDataSet(header_name: any, multiselect_enabled, set, user_type, company_id = this.currentUser.company_id) {
    var data = {
      owner_id: this.currentUser._id,
      company_id,
      header: header_name,
      multiselect_enabled: multiselect_enabled,
      dataSet: set,
      user_type
    }
    return this.httpclient.post(global.url + API_URL.WOUND.addNewHeader, { data: data }).pipe();
  }
  removeHeaderfromWoundDataSet(_id) {
    return this.httpclient.post(global.url + API_URL.WOUND.deleteHeader, { data: _id }).pipe();
  }

  getDaysToRemissionAugmentation(filter, project) {
    return this.httpclient.post(global.url + API_URL.WOUND.getDaysToRemissionAugmentation, { filter, project });
  }

  addNewRowinWoundDataSet(id: any, row_item: any) {
    var data = {
      _id: id,
      item: row_item
    };
    return this.httpclient.post(global.url + API_URL.WOUND.addNewRow, { data: data }).pipe();
  }
  removeRowfromWoundDataSet(header_id, row_id) {
    var data = {
      header_id: header_id,
      row_id: row_id
    };
    return this.httpclient.post(global.url + API_URL.WOUND.deleteRow, { data: data }).pipe();
  }
  getAllDatasetinCompany(set, userType = null, company_id = this.currentUser.company_id) {
    var data = {
      owner_id: this.currentUser._id,
      company_id,
      dataSet: set,
      userType
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getAllDataset, { data: data }).pipe();

  }
  updateHeader(new_header = null, multiselect_enabled = null, header_id, company_id = this.currentUser.company_id) {
    var data = {
      header_id: header_id,
      owner_id: this.currentUser._id,
      company_id,
      new_header: new_header,
      multiselect_enabled: multiselect_enabled
    }
    return this.httpclient.post(global.url + API_URL.WOUND.updateHeader, { data: data }).pipe();
  }
  updateDataSet(filter, data) {
    return this.httpclient.post(global.url + API_URL.WOUND.updateDataSet, { filter, data }).pipe();
  }
  updateRowinDataset(header_id, row_id, data_type, value, dropdownTitle = null, company_id = this.currentUser.company_id) {
    var data = {
      header_id: header_id,
      owner_id: this.currentUser._id,
      company_id,
      row_id: row_id,
      type: data_type,
      dropdownTitle: dropdownTitle,
      value: value
    }
    return this.httpclient.post(global.url + API_URL.WOUND.updateRow, { data: data }).pipe();
  }

  getWoundTags(company_id) {
    var data = {
      company_id
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getWoundTags, { data: data }).pipe();
  }

  addWoundTag(company_id, label) {
    var data = {
      company_id,
      label
    }
    return this.httpclient.post(global.url + API_URL.WOUND.addWoundTag, { data: data }).pipe();
  }

  updateWoundTag(woundTagId, label?, is_deleted?) {
    var data = {
      woundTagId,
      label,
      is_deleted
    }
    return this.httpclient.post(global.url + API_URL.WOUND.updateWoundTag, { data: data }).pipe();
  }
  getWoundEtiologies(company_id, search?, limit?) {
    var data = {
      company_id,
      search,
      limit
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getWoundEtiologies, { data: data }).pipe();
  }
  addWoundEtiology(company_id, label?) {
    var data = {
      company_id,
      label,
    }
    return this.httpclient.post(global.url + API_URL.WOUND.addWoundEtiology, { data: data }).pipe();
  }
  updateWoundEtiology(woundEtiologyId, label?, is_deleted?) {
    var data = {
      woundEtiologyId,
      label,
      is_deleted
    }
    return this.httpclient.post(global.url + API_URL.WOUND.updateWoundEtiology, { data: data }).pipe();
  }


  getWoundEtiologyGroups(etiology_id) {
    var data = {
      etiology_id
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getWoundEtiologyGroups, { data: data }).pipe();
  }

  getWoundEtiologyGroupsWithTags(etiology_id) {
    var data = {
      etiology_id
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getWoundEtiologyGroupsWithTags, { data: data }).pipe();
  }


  addWoundEtiologyGroup(etiology_id, title) {
    var data = {
      etiology_id, title
    }
    return this.httpclient.post(global.url + API_URL.WOUND.addWoundEtiologyGroup, { data: data }).pipe();
  }

  updateWoundEtiologyGroup(woundEtiologyGroupId, title?, is_deleted?) {
    var data = {
      woundEtiologyGroupId, title, is_deleted
    }
    return this.httpclient.post(global.url + API_URL.WOUND.updateWoundEtiologyGroup, { data: data }).pipe();
  }



  getWoundEtiologyGroupDetails(group_id) {
    var data = {
      group_id
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getWoundEtiologyGroupDetails, { data: data }).pipe();
  }

  getWoundEtiologyGroupDetailsICDs(group_id, tags) {
    var data = {
      group_id,
      tags
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getWoundEtiologyGroupDetailsICDs, { data: data }).pipe();
  }

  addWoundEtiologyGroupDetails(group_id, icd_id, tags?) {
    var data = {
      group_id, icd_id, tags
    }
    return this.httpclient.post(global.url + API_URL.WOUND.addWoundEtiologyGroupDetails, { data: data }).pipe();
  }
  updateWoundEtiologyGroupDetails(woundEtiologyGroupDetailsId, icd_id?, tags?, is_deleted?) {
    var data = {
      woundEtiologyGroupDetailsId, icd_id, tags, is_deleted
    }
    return this.httpclient.post(global.url + API_URL.WOUND.updateWoundEtiologyGroupDetails, { data: data }).pipe();
  }

  getWoundDiagnosis(wound_id) {
    var data = {
      wound_id
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getWoundDiagnosis, { data: data }).pipe();
  }
  addWoundDiagnosis(
    wound_id,
    etiology_id,
    selected_data
  ) {
    var data = {
      wound_id,
      etiology_id,
      selected_data,
    }
    return this.httpclient.post(global.url + API_URL.WOUND.addWoundDiagnosis, { data: data }).pipe();
  }
  updateWoundDiagnosis(
    wound_id,
    etiology_id,
    selected_data,
    is_deleted?
  ) {
    var data = {
      wound_id,
      etiology_id,
      selected_data,
      is_deleted
    }
    return this.httpclient.post(global.url + API_URL.WOUND.updateWoundDiagnosis, { data: data }).pipe();
  }

  getProcedureTypes(company_id) {
    var data = {
      company_id
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getProcedureTypes, { data: data }).pipe();
  }
  addProcedureType(company_id, title) {
    var data = {
      company_id, title
    }
    return this.httpclient.post(global.url + API_URL.WOUND.addProcedureType, { data: data }).pipe();
  }
  updateProcedureType(procedure_type_id, title?, is_deleted?) {
    var data = {
      procedure_type_id, title, is_deleted
    }
    return this.httpclient.post(global.url + API_URL.WOUND.updateProcedureType, { data: data }).pipe();
  }
  getProcedureDatasets(procedure_type_id) {
    var data = {
      procedure_type_id
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getProcedureDatasets, { data: data }).pipe();
  }
  addProcedureDataset(procedure_type_id, category, size, code_1, code_2, code_3) {
    var data = {
      procedure_type_id, category, size, code_1, code_2, code_3
    }
    return this.httpclient.post(global.url + API_URL.WOUND.addProcedureDataset, { data: data }).pipe();
  }
  updateProcedureDataset(procedure_dataset_id, updateDataObj) {
    var data = {
      procedure_dataset_id, updateDataObj
    }
    return this.httpclient.post(global.url + API_URL.WOUND.updateProcedureDataset, { data: data }).pipe();
  }
  getPeriodicWoundReport(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.getPeriodicWoundReport, { details: data }).pipe();
  }
  getUWHTabularReport(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.getUWHTabularReport, { data: data }).pipe();
  }
  getDischargedPatientReport(data) {
    data.company_id = this.currentUser.company_id;
    return this.httpclient.post(global.url + API_URL.WOUND.getDischargedPatientReport, { details: data }).pipe();
  }
  exportQapiReportAsXLSX(data, docType = null) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    const clonedData = _.cloneDeep(data);
    clonedData.docType = docType;
    clonedData.creationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.startDate.startOf('day'), true);
    clonedData.creationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.endDate.endOf('day'), true);

    return this.httpclient.post(global.url + API_URL.WOUND.exportQapiReportAsXLSX, clonedData,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportWoundPeriodicTreatmentReportAsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.WOUND.exportWoundPeriodicTreatmentReportAsXLSX, data,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportVisitReportAsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.WOUND.exportVisitReportAsXLSX, data,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportDnsReportAsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.exportDnsReportAsXLSX, clonedData,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportDnsHealingReportAsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    let responseOption = {};
    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    if (clonedData.exportOption === "download") {
      responseOption = {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers,
      }
    }
    return this.httpclient.post(global.url + API_URL.WOUND.exportDnsHealingReportAsXLSX, clonedData, responseOption);
  }

  exportDnsHealingReportAsPDF(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.exportDnsHealingReportAsPDF, clonedData,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }

  exportDebridementReportAsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.WOUND.exportDebridementReportAsXLSX, data,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportWoundHealingRateReportAsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.WOUND.exportWoundHealingRateReportAsXLSX, data,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportMissedFacilityReportAsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.WOUND.exportMissedFacilityReportAsXLSX, data,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportWounUWHTabularReportAsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.WOUND.exportWounUWHTabularReportAsXLSX, data,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportDischargePatientReportAsXLSX(data) {
    data.company_id = this.currentUser.company_id;
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.WOUND.exportDischargePatientReportAsXLSX, data,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  woundHealingRateReport(filter, monthNo = null) {
    const details = {
      ...filter,
      monthNo
    }
    return this.httpclient.post(global.url + API_URL.WOUND.woundHealingRateReport, { details }).pipe();
  }
  getDnsReport(data) {
    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getDnsReport, { details: clonedData }).pipe();
  }
  getDnsHealingReport(data) {
    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getDnsHealingReport, { details: clonedData }).pipe();
  }
  getHpOperationalReport(data) {
    const clonedFilter = JSON.parse(JSON.stringify(data));
    // clonedFilter.updationDate.startDate = this._commonService.convertDateToStringforMoment(moment(clonedFilter.updationDate.startDate).startOf('day'), true, true);
    // clonedFilter.updationDate.endDate = this._commonService.convertDateToStringforMoment(moment(clonedFilter.updationDate.endDate).endOf('day'), true, true);
    return this.httpclient.post(global.url + API_URL.WOUND.getHpOperationalReport, { details: clonedFilter }).pipe();
  }
  getQapiReport(data, pageNo) {
    const clonedData = _.cloneDeep(data);
    clonedData.pageNo = pageNo;
    // clonedData.creationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.startDate.startOf('day'), true);
    // clonedData.creationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getQapiReport, { details: clonedData }).pipe();
  }
  getVisitReport(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.getVisitReport, { details: data }).pipe();
  }

  getKentVisitReport(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.getKentVisitReport, { details: data }).pipe();
  }

  getMissedFacilityReport(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.getMissedFacilityReport, { details: data }).pipe();
  }
  getWoundDebridementReport(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.getWoundDebridementReport, { details: data }).pipe();
  }
  generateDnsWeeklyExcelReport(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.generateDnsWeeklyExcelReport, data);
  }
  getDnsWeeklyReport(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.getDnsWeeklyReport, data);
  }
  downloadDnsWeeklyReport(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.downloadDnsWeeklyReport, data);
  }
  getDnsWeeklyReportJobStatus(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.getDnsWeeklyReportJobStatus, data);
  }
  deleteDnsWeeklyReportJob(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.deleteDnsWeeklyReportJob, data);
  }
  getWoundNotes(data) {
    const clonedData = _.cloneDeep(data);
    clonedData.updated_date.startDate = this._commonService.convertDateToStringforMoment(moment(clonedData.updated_date.startDate).startOf('day'), true, true);
    clonedData.updated_date.endDate = this._commonService.convertDateToStringforMoment(moment(clonedData.updated_date.endDate).endOf('day'), true, true);
    return this.httpclient.post(global.url + API_URL.WOUND.getWoundNotes, { details: clonedData });
  }
  getDateFromWeekNo(week: number, year: string, isStartOfWeek: boolean) {
    // return moment().year(year).week(week).toDate();
    // if(week > 1){
    //   week++;
    // }
    if (isStartOfWeek) {
      return moment(year).add(week, 'weeks').startOf('week').toDate();
    } else {
      return moment(year).add(week, 'weeks').endOf('week').toDate();
    }
  };
  exportWoundNotesReportAsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.WOUND.exportWoundNotesReportAsXLSX, data,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportQuarterlyQapiReportAszip(payload) {
    return this.httpclient.post(global.url + API_URL.WOUND.exportQuarterlyQapiReportAszip, payload);
  }

  ///HP Em Codes Routes
  addNewUWHCode(details) {
    return this.httpclient.post(global.url + API_URL.WOUND.addNewUWHCode, { details: details }).pipe();
  }
  deleteUWHCode(details) {
    return this.httpclient.post(global.url + API_URL.WOUND.deleteUWHCode, { details: details }).pipe();
  }
  getAllUWHCode(details) {
    return this.httpclient.post(global.url + API_URL.WOUND.getAllUWHCode, { details: details }).pipe();
  }
  addNewUWHCodewithCPTCode(details) {
    return this.httpclient.post(global.url + API_URL.WOUND.addNewUWHCodewithCPTCode, { details: details }).pipe();
  }
  getProcedureTypeswithCPTRelation(details) {
    return this.httpclient.post(global.url + API_URL.WOUND.getProcedureTypeswithCPTRelation, { details: details }).pipe();
  }
  addNewProcedureCodeRelation(details) {
    return this.httpclient.post(global.url + API_URL.WOUND.addNewProcedureCodeRelation, { details: details }).pipe();
  }
  removeProcedureCodeRelation(details) {
    return this.httpclient.post(global.url + API_URL.WOUND.removeProcedureCodeRelation, { details: details }).pipe();
  }
  addNewCPTCode(details) {
    return this.httpclient.post(global.url + API_URL.WOUND.addNewCPTCode, { details: details }).pipe();
  }
  getSystemDefinedPhraseTemplates(details) {
    return this.httpclient.post(global.url + API_URL.WOUND.getSystemDefinedPhraseTemplates, { details: details }).pipe();
  }
  saveSystemDefinedPhraseTemplate(details) {
    return this.httpclient.post(global.url + API_URL.WOUND.saveSystemDefinedPhraseTemplate, { details: details }).pipe();
  }
  getQapiReportCount(date_range, facility, type = null, countOf = null, facilities) {
    // date_range.startDate = this._commonService.convertDateToStringforMoment(date_range.startDate, true);
    // date_range.endDate = this._commonService.convertDateToStringforMoment(date_range.endDate, true);
    const details = {
      ...this._authService.authObject,
      creationDate: date_range,
      facilityId: facility,
      companyId: this.currentUser.company_id,
      type,
      countOf,
      facilities
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getQapiReportCount, details).pipe();
  }

  getSystemDefinedPhraseTemplateByPhraseKey(phrase_key) {
    const details = {
      ...this._authService.authObject,
      phrase_key: phrase_key
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getSystemDefinedPhraseTemplateByPhraseKey, { details: details }).pipe();
  }

  cloneWound(wound_id: any, note_date_obj = null) {
    const details = {
      ...this._authService.authObject,
      wound_id: wound_id,
      note_date_obj
    }
    return this.httpclient.post(global.url + API_URL.WOUND.cloneWound, { data: details }).pipe();
  }

  generateZipReportForAllWounds(idArray, pid) {
    let currUser: any = JSON.parse(localStorage.getItem("currentUser"));
    let details = [];
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    for (let id of idArray) {
      let data = {
        id: id,
        patient_id: pid,
        userId: currUser._id,
        company_id: currUser.company_id,
        uploadFile: true
      }
      details.push(data);
    }
    return this.httpclient.post(global.url + API_URL.WOUND.generateZipReportForAllWounds, details,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }

  exportWoundAsPdf(id, latest_child_wound_id = null, patient) {
    let currUser: any = JSON.parse(localStorage.getItem("currentUser"));
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    var data = {
      id: id,
      latest_child_wound_id,
      patient_id: patient._id,
      patient,
      userId: currUser._id,
      company_id: currUser.company_id,
      uploadFile: true
    }
    return this.httpclient.post(global.url + API_URL.WOUND.exportAsPdf, data,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }

  getProviderWounds(id) {
    return this.httpclient.post(global.url + API_URL.WOUND.getProviderWounds, { id }).pipe()
  }

  getAdminChargesReport(filter) {
    return this.httpclient.post(global.url + API_URL.CHARGE.getAdminChargesReport, { filter }).pipe()
  }

  getUWHTabularReportV2(data) {
    const clonedData = _.cloneDeep(data);
    clonedData.creationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.startDate.startOf('day'), true);
    clonedData.creationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getUWHTabularReportV2, { data: clonedData });
  }

  exportChargesReportAsXLSX(filter, data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    return this.httpclient.post(global.url + API_URL.WOUND.exportChargesReportAsXLSX, { filter, data },
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }

  getDnsReportV2(data) {
    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getDnsReportV2, { details: clonedData });
  }
  exportDnsReportV2AsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.exportDnsReportV2AsXLSX, clonedData,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportUWHTabularReportV2AsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    const clonedData = _.cloneDeep(data);
    clonedData.creationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.startDate.startOf('day'), true);
    clonedData.creationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.exportUWHTabularReportV2AsXLSX, clonedData,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  getQapiReportV2(data, pageNo) {
    const clonedData = _.cloneDeep(data);
    clonedData.pageNo = pageNo;
    clonedData.creationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.startDate.startOf('day'), true);
    clonedData.creationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getQapiReportV2, { details: clonedData });
  }
  exportQapiReportV2AsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    const clonedData = _.cloneDeep(data);
    clonedData.creationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.startDate.startOf('day'), true);
    clonedData.creationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.endDate.endOf('day'), true);

    return this.httpclient.post(global.url + API_URL.WOUND.exportQapiReportV2AsXLSX, clonedData,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }

  getDnsHealingReportV2(data) {
    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getDnsHealingReportV2, { details: clonedData }).pipe();
  }

  getPostRoundsReport(data) {
    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(moment(clonedData.updationDate.startDate).startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(moment(clonedData.updationDate.endDate).endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getPostRoundsReport, { details: clonedData }).pipe();
  }
  exportPostRoundsReport(payload) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    const clonedData = _.cloneDeep(payload);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.exportPostRoundsReport, clonedData, {
      responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
    });
  }

  exportDnsHealingReportV2AsXLSX(data) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    const clonedData = _.cloneDeep(data);
    let responseOption = {};
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    if (clonedData.exportOption === "download") {
      responseOption = {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers,
      }
    }
    return this.httpclient.post(global.url + API_URL.WOUND.exportDnsHealingReportV2AsXLSX, clonedData, responseOption);
  }

  updateRowPositions(header_id, row_data) {
    var data = {
      header_id: header_id,
      row_data: row_data
    }
    return this.httpclient.post(global.url + API_URL.WOUND.updateRowPositions, { data: data }).pipe();
  }

  changeMultipleWoundsState(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.changeMultipleWoundsState, { data: data }).pipe();
  }
  moveWoundToCensus(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.moveWoundToCensus, { data: data }).pipe();
  }



  generateWoundReportUploadToPcc(payload) {
    let currentUserDateTime = moment(new Date()).format('MM/DD/YYYY hh:mm A');

    return this.httpclient.post(global.url + API_URL.WOUND.generateWoundReportUploadToPcc, {
      ...this._authService.authObject,
      currentUserDateTime: currentUserDateTime.toString(),
      ...payload,
    }).pipe()
  }

  generateWoundReportUploadToMatrix(payload) {
    let currentUserDateTime = moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.httpclient.post(global.url + API_URL.WOUND.generateWoundReportUploadToMatrix, {
      ...this._authService.authObject,
      currentUserDateTime: currentUserDateTime.toString(),
      ...payload,
      tz
    }).pipe()
  }
  uploadWoundReportsinBulk(data) {
    return this.httpclient.post(global.url + API_URL.WOUND.uploadWoundReportsinBulk, { details: data }).pipe();
  }
  getPressureWoundReport(data) {
    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getPressureWoundReport, { details: clonedData });
  }
  getSummaryPressureReport(data) {
    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getSummaryPressureReport, { details: clonedData });
  }
  checkWoundDatesToSignNote(data) {
    const details = {
      ...this._authService.authObject,
      company_id: this._authService.currentUser.company_id,
      patientIds: data
    }
    return this.httpclient.post(global.url + API_URL.WOUND.checkWoundDatesToSignNote, { details: details });

  }

  fetchWounds(filter, project, pageNo) {
    const clonedData = _.cloneDeep(filter);
    if (filter.last_assessment_date_obj.startDate && filter.last_assessment_date_obj.endDate) {
      clonedData.last_assessment_date_obj.startDate = this._commonService.convertDateToStringforMoment(filter.last_assessment_date_obj.startDate.startOf('day'), true);
      clonedData.last_assessment_date_obj.endDate = this._commonService.convertDateToStringforMoment(filter.last_assessment_date_obj.endDate.endOf('day'), true);
    }
    return this.httpclient.post(global.url + API_URL.WOUND.fetchWounds, { filter: clonedData, project, pageNo });
  }

  exportPressureWoundReportAsXLSX(data) {
    const headers = new HttpHeaders();
    // headers.append('Access-Control-Allow-Headers', 'Content-Length');
    // headers.append('Access-Control-Expose-Headers', 'Content-Length');
    // headers.append('Content-Type', 'application/octet-stream');
    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);
    let responseOption = {};
    if (clonedData.exportOption === "download") {
      responseOption = {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers,
      }
    }
    return this.httpclient.post(global.url + API_URL.WOUND.exportPressureWoundReportAsXLSX, clonedData, responseOption);
  }

  exportCustomWoundReportAsXLSX(filter, project, columns) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    const clonedData = _.cloneDeep(filter);
    if (filter.last_assessment_date_obj.startDate && filter.last_assessment_date_obj.endDate) {
      clonedData.last_assessment_date_obj.startDate = this._commonService.convertDateToStringforMoment(filter.last_assessment_date_obj.startDate.startOf('day'), true);
      clonedData.last_assessment_date_obj.endDate = this._commonService.convertDateToStringforMoment(filter.last_assessment_date_obj.endDate.endOf('day'), true);
    }
    return this.httpclient.post(global.url + API_URL.WOUND.exportCustomWoundReportAsXLSX, { filter: clonedData, project, columns },
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }

  exportWoundExceptionReportAsXLSX(payload: any) {
    const filter = _.cloneDeep(payload);
    filter.updationDate.startDate = this._commonService.convertDateToStringforMoment(filter.updationDate.startDate.startOf('day'), true);
    filter.updationDate.endDate = this._commonService.convertDateToStringforMoment(filter.updationDate.endDate.endOf('day'), true);

    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.WOUND.exportWoundExceptionReportAsXLSX, { filter },
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }

  exportHpOperationalReportAsXLSX(data: any) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    const clonedData = _.cloneDeep(data);
    clonedData.updationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.startDate.startOf('day'), true);
    clonedData.updationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.updationDate.endDate.endOf('day'), true);

    return this.httpclient.post(global.url + API_URL.WOUND.exportHpOperationalReportAsXLSX, { details: clonedData },
      {
        reportProgress: true,
        observe: 'events',
        headers
      });
  }

  getWoundExceptionsReport(payload) {
    const filter = _.cloneDeep(payload);
    filter.updationDate.startDate = this._commonService.convertDateToStringforMoment(filter.updationDate.startDate.startOf('day'), true);
    filter.updationDate.endDate = this._commonService.convertDateToStringforMoment(filter.updationDate.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getWoundExceptionsReport, { filter });
  }

  getPatientsForWoundExceptionsReport(payload) {
    return this.httpclient.post(global.url + API_URL.WOUND.getPatientsForWoundExceptionsReport, { filter: payload });
  }

  getDaysToRemissionReport(payload) {
    const filter = _.cloneDeep(payload);
    filter.last_assessment_date_obj.startDate = this._commonService.convertDateToStringforMoment(filter.last_assessment_date_obj.startDate.startOf('day'), true);
    filter.last_assessment_date_obj.endDate = this._commonService.convertDateToStringforMoment(filter.last_assessment_date_obj.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getDaysToRemissionReport, filter);
  }

  getWoundOutcomeReport(payload) {
    const filter = _.cloneDeep(payload);
    filter.last_assessment_date_obj.startDate = this._commonService.convertDateToStringforMoment(filter.last_assessment_date_obj.startDate.startOf('day'), true);
    filter.last_assessment_date_obj.endDate = this._commonService.convertDateToStringforMoment(filter.last_assessment_date_obj.endDate.endOf('day'), true);
    return this.httpclient.post(global.url + API_URL.WOUND.getWoundOutcomeReport, filter);
  }

  getPatientswithActiveWounds(facility_id, patientIds, company_id) {
    const data = {
      ...this._authService.authObject,
      facility_id,
      patientIds,
      company_id
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getPatientswithActiveWounds, { details: data });

  }
  getWoundfromParentSchema(wound_id) {
    // getWoundfromParentSchema
    const data = {
      ...this._authService.authObject,
      wound_id
    }
    return this.httpclient.post(global.url + API_URL.WOUND.getWoundfromParentSchema, { details: data }).toPromise();
  }

  lockWoundManually(wound_id) {
    // getWoundfromParentSchema
    const data = {
      ...this._authService.authObject,
      wound_id
    }
    return this.httpclient.post(global.url + API_URL.WOUND.lockWoundManually, { details: data }).toPromise();
  }

  addWound(payload) {
    return this.httpclient.post(global.url + API_URL.WOUND.addWound, { data: payload }).pipe();
  }
  swapColumnswithOther(mainColumn, swapColumn){
    const payload = {
      ...this._authService.authObject,
      mainColumn,
      swapColumn
    }
    return this.httpclient.post(global.url + API_URL.WOUND.swapColumns, { details: payload }).pipe()
  }
  copyAllDatasetfromHP(company_id, user_type){
    const payload = {
      ...this._authService.authObject,
      company_id,
      user_type
    }
    return this.httpclient.post(global.url + API_URL.WOUND.copyAllDatasetfromHP, { details: payload }).pipe()
  }
  updateEtiologiesOrder(data,company_id){
    const payload = {
      data,
      company_id
    }
    return this.httpclient.post(global.url + API_URL.WOUND.updateEtiologiesOrder, { details: payload }).pipe()
  }
}
