import { FeedbackService } from './../feedback.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  file: any = false;
  isImage: boolean = true;
  comment:string = "";
  name: string ="";
  url: string="";
  issue_type: string = "comment";
  isLoading: boolean = false;
  imageError: boolean = false;
  videoError: boolean = false;
  constructor(private service: FeedbackService,private toastr: ToastrService, private authService:AuthService,private router: Router) { }

  ngOnInit(): void {
  this.isLoading = false;
  
  }

  validateFile(file) {
    var video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {

        window.URL.revokeObjectURL(video.src);
      // console.log(video.duration);
        if (video.duration > 30) {
            this.videoError = true;
            return;
        }
        else{
          this.videoError = false;
        }

    }

    video.src = URL.createObjectURL(file);
}
  
  onSelectFile(e) {
    if(e.target.files){
      if(e.target.files[0].size > 2000000 && e.target.files[0].type.includes("image")) { 
      this.imageError = true; 
      this.videoError = false;
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
      this.url = event.target.result;
    }
    }
    else if(e.target.files[0].type.includes("video")){
      this.imageError = false;
      this.validateFile(e.target.files[0]);
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
      this.url = event.target.result;
      this.url = "";
    }
    } 
      
     else if(e.target.files[0].type.includes("image")){
       this.imageError = false;
       this.videoError = false;
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
      this.url = event.target.result;
    }
  }
    else{
      this.file = e.target.files[0];
      this.isImage = false;
    }
  }
  }

  onSubmit(feedbackForm,media) {
    if(feedbackForm.value.comment === "") {
      alert("Fill all fields!!!");
      return;
    }
    if(this.imageError){
      return;
    }
    if(this.videoError){
      return;
    }
    this.isLoading = true;
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('text', feedbackForm.value.comment);
    formData.append('company_id',this.authService.currentUser.company_id);
    formData.append('id',this.authService.currentUser._id);
    formData.append('user_id',this.authService.currentUser._id);
    formData.append('name',this.authService.currentUser.first_name+" "+this.authService.currentUser.last_name);
    formData.append('issue_type',this.issue_type);
    this.service.insertData(formData).subscribe((response:any) => {
      if(response.status === 200){
        this.toastr.success("Feedback Submitted");
        this.onCancel(media);
        this.isLoading = false;
      }
      else{
        this.toastr.error("Something went wrong");
        this.isLoading = false;
      }
    });
  }
  onCancel(media) {
    this.imageError = false;
    this.videoError = false;
    this.file = false;
    media.value = null;
    this.comment = "";
    this.url = "";
    this.issue_type = "comment"
  }
  onClear(media) {
    media.value = null;
    this.file = false;
    this.imageError = false;
    this.videoError = false;
  }
  goBack() {
    this.router.navigateByUrl("/dashboard");
  }
}
