<div class="relative">
  <form class="patient-rounding-sheet-form flex flex-col gap-4" style="margin:auto" 
  (submit)="handleSubmit($event)">
    <h3>Create Patient Census</h3>

  <app-date-picker-widget (dateEvent)="handleDateChange($event)" [date]="getDate()"></app-date-picker-widget>
  
  <mat-form-field class="flex">
    <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="name" required>
    <mat-error *ngIf="!name">Please enter name</mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="shouldShowOrgsDropdown">
    <mat-label>Organizations</mat-label>
    <mat-select name="org" (selectionChange)="onChangeOrganization($event)">
      <mat-option *ngFor="let org of orgs" [value]="org">
        {{org.organization_id.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>
    <mat-form-field>
      <mat-select name="facility" required [(ngModel)]="facility" (ngModelChange)="fetchCensusList($event)"
        placeholder="Facility">
        <input  placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
          class="census-facility" (keydown)="$event.stopPropagation()" />
        <mat-option *ngFor="let facility of facilities | searchFacility: searchText" value="{{facility._id}}">
          <app-facility-title [facility]="facility"></app-facility-title>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!facility">Please select facility </mat-error>
    </mat-form-field>
  
    <div class="flex items-baseline gap-2">
      <mat-checkbox name="video" #video="ngModel" [(ngModel)]="isCopyPatients">
        Copy patients from existing Census
      </mat-checkbox>
      &nbsp;&nbsp;
      <div class="flex flex-col flex-1">
        <mat-form-field class="flex" [ngStyle]="{display: isCopyPatients ? 'block' : 'none'}">
          <mat-select [class.user-select-menu]="selectedUser && selectedUser.is_activated !== 'true'" name="provider" placeholder="Provider" required [(ngModel)]="selected" #provider
            (ngModelChange)="handleCopyPatients($event)">
            <mat-option (onSelectionChange)="handleUserChange($event, currentUser)" [class.text-gray]="currentUser.is_activated !== 'true'" *ngIf="currentUser.user_type===global.USER_TYPE.DOCTOR || currentUser.user_type===global.USER_TYPE.SNF_WC_NURSE && !companySide" [value]="currentUser._id">
              {{currentUser.first_name}} {{currentUser.last_name}}
            </mat-option>
            <mat-option
              *ngIf="(currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT || currentUser.user_type===global.USER_TYPE.TRANSCRIBER || companySide) && currentProvider"
              [value]="currentProvider._id" (onSelectionChange)="handleUserChange($event, currentProvider)" [class.text-gray]="currentProvider.is_activated !== 'true'">
              {{currentProvider?.first_name}} {{currentProvider?.last_name}}
            </mat-option>
            <mat-option (onSelectionChange)="handleUserChange($event, provider)" [class.text-gray]="provider.is_activated !== 'true'" *ngFor="let provider of providers" [value]="provider._id">
              {{provider.first_name}} {{provider.last_name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="!provider">Please select Provider </mat-error>
        </mat-form-field>
      </div>  
      <div class="flex flex-col flex-1">
        <mat-form-field [ngStyle]="{display: isCopyPatients ? 'block' : 'none'}">
          <mat-select name="patientRoundingSheet" [(ngModel)]="patientRoundingSheet" placeholder="Patient Census" 
          #censusSelect>
            <mat-option *ngFor="let patientRoundingSheet of filteredRoundingSheets()" [value]="patientRoundingSheet">
              {{patientRoundingSheet.name}} {{getCensusDate(patientRoundingSheet)}}
            </mat-option>

          </mat-select>
          <mat-error *ngIf="!facility">Please select facility </mat-error>
          <mat-error *ngIf="filteredRoundingSheets().length === 0">No Census in this facility</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="isCopyPatients" class="flex items-baseline gap-2">
      <div class="flex" style="width: 40%;"></div>
      <div class="flex">
        <mat-checkbox name="voidcheck" #voidcheck="ngModel" [(ngModel)]="isvoidcheck">
          Void Census
        </mat-checkbox>
      </div>
    </div>



    <div *ngIf="!(data?.isPatientImport) && isNursingCompany" class="flex items-baseline gap-2">
      <mat-checkbox class="flex" name="Recurrence" #Recurrence="ngModel" [(ngModel)]="isRecurrentCensus" 
      (change)="showDialog($event)">
        Recurrent Census
      </mat-checkbox>
    </div>


    <div class="flex justify-end items-center">
    <button mat-raised-button class="dn-button-primary" type="submit" 
    [disabled]="disableCreateButton"
    >Create</button>

  </div>
  </form>
</div>
<div class="top-right-close-btn">
  <button mat-mini-fab mat-dialog-close>
    <mat-icon>cancel</mat-icon>
  </button>
</div>