<ng-template [ngIf]="video != null">
    <h2 mat-dialog-title>{{ video.title }}</h2>
    <div mat-dialog-content>
        <video
            controls
            controlsList="nodownload"
            style="width: 100%; min-width: 750px; max-width: 100%;"
            #videoRef
        >
            <source [src]="video.url" [type]="video.type" />
            Your browser does not support the video tag.
        </video>
    </div>
</ng-template>
    <div class="top-right-close-btn">
        <button mat-mini-fab mat-dialog-close>
            <mat-icon>cancel</mat-icon>
        </button>
    </div>