import { PipeTransform, Pipe } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Recording } from './includes/dashboard/dashboard.component';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({
  name: 'recordingFilter'
})

export class RecordingFilterPipe implements PipeTransform {
  transform(recordings: Recording[], recordingSearchQuery: string): Recording[] {
    // check if search term in undefined
    if (recordingSearchQuery === null || recordingSearchQuery === undefined) {
      return recordings;
    }

    return recordings.filter(recording =>
      recording.audio_title.toLowerCase().indexOf(recordingSearchQuery.toLowerCase()) !== -1);
  }
}



@Pipe({
  name: 'recordingStatusFilter'
})

export class RecordingStatusFilterPipe implements PipeTransform {
  // transform(category: any[], searchTerm: any[]): any[] {

  //   if (!searchTerm || searchTerm.length === 0) {
  //     return category;
  //   }
  //   return category.filter(cat => searchTerm.includes(cat.name));

  transform(recordings: Recording[], recordingStatusQuery: string): Recording[] {
    // check if search term in undefined
    // modelcategorySearchQuery = ['cat2'];

    if (!recordingStatusQuery || recordingStatusQuery.length === 0) {
      return recordings;
    }

    // return recordings.filter(recording => recordingStatusQuery.includes(recording.status));

    return recordings.filter(recording =>
      recording.status.toLowerCase().indexOf(recordingStatusQuery.toLowerCase()) !== -1);
  }

}

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({
  name: 'searchDNPatient'
})
export class SearchDNPatientPipe implements PipeTransform {
  transform(items: any[], searchTxt: string): any[] {
    if (Array.isArray(items) && !searchTxt) return items;
    return items?.filter(item =>
      (item.first_name.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1) || item.last_name.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1);
  }
}

@Pipe({
  name: 'searchPccPatient'
})
export class SearchPccPatientPipe implements PipeTransform {
  transform(items: any[], searchTxt: string): any[] {
    if (Array.isArray(items) && !searchTxt) return items;
    return items?.filter(item =>
      (item.firstName.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1) || item.lastName.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1);
  }
}

@Pipe({
  name: 'doctorNameFilter'
})

export class DoctorNameFilterPipe implements PipeTransform {
  transform(doctors: any, doctorNameSearchQuery: string): any {
    // check if search term in undefined
    if (doctorNameSearchQuery === undefined) {
      return doctors;
    }

    return doctors.filter(doctor =>
      doctor.name.toLowerCase().indexOf(doctorNameSearchQuery.toLowerCase()) !== -1);
  }
}

@Pipe({
  name: 'searchProvider'
})
export class SearchProviderPipe implements PipeTransform {
  transform(items: any[], searchTxt: string): any[] {
    // console.log('items',items);
    if (!items) return;
    if (Array.isArray(items) && !searchTxt) return items;
    return items.filter(item =>
      item.first_name.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1 || item.last_name.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1);
  }
}

@Pipe({
  name: 'searchTemplate'
})
export class SearchTemplatePipe implements PipeTransform {
  transform(items: any[], searchTxt: string): any[] {
    // console.log('items',items);
    if(!items) return;
    if (Array.isArray(items) && !searchTxt) return items;
    return items.filter(item =>
      item.title.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1 );
  }
}
@Pipe({
  name: 'searchFacility'
})
export class SearchFacilityPipe implements PipeTransform {
  transform(items: any[], searchTxt: string): any[] {
    if (Array.isArray(items) && !searchTxt) return items;
    return items?.filter(item =>
      (item.title.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1));
  }
}

@Pipe({
  name: 'searchCompany'
})
export class SearchCompanyPipe implements PipeTransform {
  transform(items: any[], searchTxt: string): any[] {
    if (!items || !items.length) return items;
    if (!searchTxt || !searchTxt.length) return items;
    return items.filter(item =>
      item.name.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1);
  }
}
@Pipe({
  name: 'searchYear'
})
export class SearchYearPipe implements PipeTransform {
  transform(items: any[], searchTxt: string): any[] {
    if (Array.isArray(items) && !searchTxt) return items;
    return items.filter(item =>
      item.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1);
  }
}
@Pipe({
  name: 'searchCPTCode'
})
export class searchCPTCodePipe implements PipeTransform {
  transform(items: any[], searchTxt: string): any[] {
    if (Array.isArray(items) && !searchTxt) return items;
    return items?.filter(item =>
      item.code.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1 || item.description.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1);
  }
}



@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    const pattern = /\(.*?\)|\[\[.*?\]\]/g; // Pattern to match the contents inside the brackets
    return array.sort((a, b) => {
      const aName = a[field].replace(pattern, '').trim().toLowerCase();
      const bName = b[field].replace(pattern, '').trim().toLowerCase();
      return aName.localeCompare(bName);
    });
  }
}

@Pipe({
  name: 'highlightWords'
})
export class HighlightWordsPipe implements PipeTransform {
  transform(value: string, wordsToHighlight: string[]): string {
    if (!value || !wordsToHighlight || wordsToHighlight.length === 0) {
      return value;
    }
    console.log("idhg", wordsToHighlight);
    const regex = new RegExp(wordsToHighlight.join('|'), 'gi');
    return value.replace(regex, match => `<span class="highlight-words">${match}</span>`);
  }
}