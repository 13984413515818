import { Injectable } from '@angular/core';
// import * as io from 'socket.io-client';
import * as global from '../includes/global';

@Injectable({
  providedIn: 'root'
})

export class SocketService {

    // socket: SocketIOClient.Socket = io(global.socket_io_base_url);
    addActivityLog(values: ActivityLogValues) {
        values.platform = 'Web';
        values.ip_address = '';
        values.location = '';
        // this.socket.emit('addActivityLog', JSON.stringify(values))
        // this.socket.on('connect', function() {
        // })
    }

}


interface ActivityLogValues {
    id:string // userId
    screen:string
    operation:string
    datetime?:string
    platform?:string
    ip_address?:string
    location?:string
}
