<mat-spinner *ngIf="isLoading; else elseBlock" style="margin: 150px; margin-left: 250px; "></mat-spinner>
<ng-template #elseBlock>
    <form #feedbackForm="ngForm" (ngSubmit)="onSubmit(feedbackForm,media)" class="flex flex-col">
        <h1 style="text-align: center;">Submit Your Feedback</h1>
        <br>
        <div class="flex items-center" style="height: 35px; gap: 137px;">
            <h5 style="width: 12%;">Issue Type</h5>
            <mat-form-field>
                <mat-label>Issue Type</mat-label>
                <mat-select name="issue_type" #selected id=""
                [(ngModel)]="issue_type">
                <mat-option value="comment">Comment</mat-option>
                <mat-option value="bug">Bug</mat-option>
                <mat-option value="Enhancement Request">Enhancement Request</mat-option>
            </mat-select>
              </mat-form-field> 
        </div>
        <br> <br>
        <div class="flex items-center" style="height: 35px; gap: 180px;">
            <textarea style="height: 14vh; width: 100%;" name="comment"
                [placeholder]="issue_type==='comment'? 'Add a comment... ':((issue_type|titlecase) + ' detail')"
                [(ngModel)]="comment"></textarea>
        </div>
        <br> <br> <br>
        <div class="flex flex-row items-center" style="height: 35px; gap: 135px;">
            <h5>Screenshot</h5>
            <div class="w-1/2">
                <input style="max-width: 161px;" type="file" #media accept="image/*,video/*"
                    (change)="onSelectFile($event)">
                <button *ngIf="file!=false" mat-raised-button color="warn" type="button" (click)="onClear(media)">Clear</button>
            </div>
            <div class="flex justify-start items-end">
                <button mat-raised-button color="primary" type="submit">Submit</button>
            </div>
        </div>
        <br>
        <div *ngIf="imageError" style="color: red;text-align: center;"><small>Image size is greater than 2MB</small></div>
        <div *ngIf="videoError" style="color: red;text-align: center;"> <small>Video length is greater than 30 sec</small> </div>
    </form>
</ng-template>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>