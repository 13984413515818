import {
    ViewEncapsulation,
    Component,
    OnInit,
    DoCheck,
    AfterViewInit,
    Inject,
    ViewChild,
    ChangeDetectorRef,
    OnDestroy,
    Input
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateExplorerService } from './template-explorer.service';
import { ToastrService } from 'ngx-toastr';
import { CreateNewFolderDialog } from '../../drive/create-new-folder-dialog.component'
import { DeleteConfirmationDialog } from '../../drive/delete-confirmation-dialog.component'
import { WatchHelpVideoDialog } from '../../drive/watch-help-video-dialog.component';
import { ShareItemDialog } from '../../drive/share-item-dialog.component'
// import {UploadTemplateDialog} from '../../drive/upload-template-dialog.component'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ITemplate, IFolder, IFacility } from '../models'
import { DashboardService } from '../../dashboard/dashboard.service';
import * as global from '../../global';
// import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
// import { DragulaService } from 'ng2-dragula';
import { TranscriptionCreateService } from '../../transcription-create/transcription-create.service';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { MatMenuTrigger } from "@angular/material/menu";
import { CommonService } from 'src/app/services/common.service';
import { ProviderProviderAssociationService } from '../../provider-provider-association/provider-provider-association.service';
import { TemplatesService } from '../templates.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { lastValueFrom } from 'rxjs';
import { SnfWcNurseAssociationService } from '../../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';

// import { DROPZONE_CONFIG, DropzoneComponent } from 'ngx-dropzone-wrapper';
// import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

// const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
//  // Change this to your upload POST address:
//   url: global.url_documents + '/uploadTemplate',
//   maxTemplatesize: 50,
//   clickable:false
// };

@Component({
    selector: 'app-template-explorer',
    templateUrl: './template-explorer.component.html',
    styleUrls: ['./template-explorer.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe],
    // styleUrls: ['./template-explorer.component.css', '../../../../../node_modules/dropzone/dist/min/dropzone.min.css'],
    // providers: [

    //   {
    //     provide: DROPZONE_CONFIG,
    //     useValue: DEFAULT_DROPZONE_CONFIG
    //   }
    // ]
})
export class TemplateExplorerComponent implements OnInit, DoCheck, AfterViewInit, OnDestroy {
    isSharedTemplateExplorer: boolean = false;
    global: any = global;
    displayedColumns: Array<string> = ['title', 'creation_date', 'updation_date'];
    displayedColumnsSystem: Array<string> = ['title', 'creation_date', 'updation_date','updated_by', 'active_inactive'];
    displayedColumnsMy: Array<string> = ['title', 'creation_date','created_by_ref', 'updation_date','updated_by', 'active', 'type'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatSort) systemSort: MatSort;
    @Input() template_type = "";
    // dataSource:MatTableDataSource<ITemplate|IFolder>;
    dataSource: MatTableDataSource<ITemplate | IFacility>;
    dataSource2: MatTableDataSource<ITemplate | IFacility>;
    systemDataSource: MatTableDataSource<ITemplate | IFacility>;
    // allTemplatesOrFacilities:Array<ITemplate|IFolder> = [];
    allTemplatesOrFacilities: Array<ITemplate | IFacility> = [];
    allSystemTemplatesOrFacilities: Array<ITemplate | IFacility> = [];

    routingToTemplateCreateComponent: Boolean = false;
    selectedIndex: Number = 0;
    companyId;
    folders: Array<IFolder> = [];
    facilities: Array<IFacility> = [];
    templates: Array<ITemplate> = [];
    templatesLoaded: Boolean = false;
    navigating: Boolean = false;
    adminSide: Boolean = false;
    export: Boolean = false;
    historyStack: Array<IFolder> = [];
    historyStackTop: IFolder = null;
    currentUser: any;
    loading: boolean = false;
    header_title: string = "My Templates";
    inFolder: boolean = false;
    inFileRow: boolean = true;
    parent_folder_id: string = "";
    myTemplates: boolean = false;

    selectedItem: ITemplate | IFolder = null;
    view_type: string = 'LIST'; // GRID LIST

    showTemplateSidebar: boolean = false;
    isEditingDescription: boolean = false;
    userTypeForTemplates: string = '';

    @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
    contextMenuPosition = { x: '0px', y: '0px' };
    isChecked = true;
    // DRAGULA_FILES_GROUP = 'DRAGULA_FILES_GROUP';

    // public droppedTemplates: NgxFileDropEntry[] = [];

    providers: any[] = [];
    provider: any;
    associatedProviders: any = [];
    associatedProviderTemplates: any = [];
    isTemplateActive: Boolean = true;
    company_id: string;
    constructor(
        protected dialog: MatDialog,
        protected templateExplorerService: TemplateExplorerService,
        private _templatesService: TemplatesService,
        protected toastr: ToastrService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected dashboardService: DashboardService,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _date_pipe: DatePipe,
        protected _transcriptionCreateService: TranscriptionCreateService,
        protected _dashboardService: DashboardService,
        protected commonService: CommonService,
        private _snfWcNurseAssociationService: SnfWcNurseAssociationService

        // private dragulaService: DragulaService
    ) {
    }

    async ngOnInit() {
        
        const currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
            this.currentUser = JSON.parse(currentUser);
        }
        if (window.location.pathname.includes('admin') || window.location.pathname.includes('company')) {
            let userId = this.route.snapshot.paramMap.get('provider_id');
            let result: any = await this.templateExplorerService.getPro(userId).toPromise();
            this.company_id = this.currentUser.company_id;
            this.provider = result.data;
            this.adminSide = true;
            if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE)
            await this.getAssociatedSnfWcNurses();
        else
            this.getP2PRelation();
        }
        else if (this.currentUser.user_type === global.USER_TYPE.DOCTOR) {
            this.provider = this.currentUser;
            this.getP2PRelation();
        }

        else if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
            this.provider = this.currentUser;
            await this.getAssociatedSnfWcNurses();
        }

        else if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER) {
            await this.initProviders();
            this.provider = this.providers[0];
        }else if(this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT){
            await this.initProvidersForMA();
            this.provider = this.providers[0];
        } else {
            this.provider = this.currentUser;
        }
        this.templateExplorerService.setProviderId(this.provider);

        const facilities = await this.fetchFacilities();
        this.initializeFacilities(facilities);

        //To show templates after returning from create-template page
        if (localStorage.getItem("drive_facility_folder_id") && localStorage.getItem("drive_facility_folder") !== null) {
            const folder_id = localStorage.getItem("drive_facility_folder_id");
            const folder: ITemplate = JSON.parse(localStorage.getItem("drive_facility_folder"));
            await this.onFolderDoubleClick(folder, folder_id);
        }

        if (this.adminSide) {
            this.userTypeForTemplates = this.provider.user_type;
        } else {
            this.userTypeForTemplates = this.currentUser.user_type;
        }
    }

    ngDoCheck() {
        const historyStackLength = this.historyStack.length;
        if (historyStackLength > 0) {
            this.historyStackTop = this.historyStack[historyStackLength - 1];
        } else {
            this.historyStackTop = null;
        }
    }

    ngOnDestroy() {
        if (!this.routingToTemplateCreateComponent) {
            localStorage.removeItem("drive_facility_folder_id");
            localStorage.removeItem("drive_facility_folder");
        }
    }

    async initProviders() {
        const doctorsResponse: any = await this.templateExplorerService.getAssociatedDoctorsList(this.currentUser.company_id).toPromise();
        if (doctorsResponse.status == 200) {
            this.providers = doctorsResponse.data;
        }
    }

    async initProvidersForMA() {
        const doctorsResponse: any = await this.templateExplorerService.getAssociatedDoctorsListForMA(this.currentUser.company_id).toPromise();
        if (doctorsResponse.status == 200) {
            this.providers = doctorsResponse.data;
        }
    }

    addNewTemplate() {
        if (window.location.pathname.includes('admin') || window.location.pathname.includes('company')) {
            let routerMapParams: any;
            if (this.template_type != "") routerMapParams = { companyId: this.company_id, provider: this.route.snapshot.paramMap.get('provider_id'), template_type: this.template_type };
            else routerMapParams = { companyId: this.company_id, provider: this.route.snapshot.paramMap.get('provider_id') };
            this.routingToTemplateCreateComponent = true;
            this.router.navigate([`/company/${this.company_id}/template-create`, routerMapParams])
        }
        else{
            this.router.navigate([`/template-create`])
        }
    }

    getP2PRelation() {
        this._dashboardService.getP2PRelation(null, this.provider._id).subscribe((response: any) => {
            if (response.status === 200) {
                this.associatedProviders = response.data.assoc_provider_id;
            }
        });
    }
    async getAssociatedSnfWcNurses() {
        if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
            const filter = {
                snf_wc_nurse_id: this.currentUser._id,
                company_id: this.currentUser.company_id
            };
            const project = {
                first_name: 1,
                last_name: 1,
                title: 1,
                company_id: 1
            };
            const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getAssociatedSnfWcNurses(filter, project));
            if (response.status === 200) {
                this.associatedProviders = response.data.associated_snf_wc_nurse_ids || [];
            }
        }
    }
    ngAfterViewInit() {
        // if(!this.isSharedTemplateExplorer && !this.dragulaService.find(this.DRAGULA_FILES_GROUP)) {

        //     this.dragulaService.createGroup(this.DRAGULA_FILES_GROUP, {
        //       revertOnSpill:true,
        //       accepts: (el, target, source, sibling) => {
        //         const targetItem = this.folders.find(f => f._id === target.id);

        //         return source.id !== target.id && (!!targetItem || target.classList.contains('empty'));
        //       }
        //     });
        //     this.dragulaService.drag(this.DRAGULA_FILES_GROUP).subscribe(e => {
        //       e.el.classList.add('on-drag');
        //     })
        //     this.dragulaService.dragend(this.DRAGULA_FILES_GROUP).subscribe(e => {
        //       e.el.classList.remove('on-drag');
        //     })
        //     this.dragulaService.drop(this.DRAGULA_FILES_GROUP).subscribe((e:any) => {
        //       this.navigating = true;

        //       const {id: targetId} = e.target;
        //       const {id: sourceId} = e.source;


        //       const item = this.allTemplatesAndFolders.find(f => f._id === sourceId);

        //       this.folders = this.folders.filter(f => f._id !== sourceId);
        //       this.templates = this.templates.filter(f => f._id !== sourceId);
        //       this.allTemplatesAndFolders = [...this.folders, ...this.templates];

        //       e.el.style.display = 'none';
        //       this.changeParentFolderId(item,targetId);

        //     });
        // }
        // setTimeout(async () => {
        //     const facilities = await this.fetchFacilities();
        //     this.initializeFacilities(facilities);

        //     // const dropZoneDiv:HTMLElement = document.querySelector('.ngx-template-drop__drop-zone');
        //     // dropZoneDiv.classList.add('dismis-template-selection');
        // });
    }

    // ngOnDestroy(): void {
    //     this.toastr.clear()
    // }

    ownedItem(item: ITemplate | IFolder) {
        return item.owner_id === this.currentUser._id
    }

    initializeFacilities(facilities) {


        facilities.sort((a, b) => a.title > b.title ? 1 : -1);
        this.allTemplatesOrFacilities = facilities;
        console.log("this.allTemplatesOrFacilities", this.allTemplatesOrFacilities);


        setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.allTemplatesOrFacilities);
            this.dataSource.sort = this.sort;
            this._changeDetectorRefs.detectChanges();
        });
        // this._changeDetectorRefs.detectChanges();
        this.templatesLoaded = true;
    }

    initializeTemplates(templates) {

        templates.sort((a, b) => a.title > b.title ? 1 : -1);
        this.allTemplatesOrFacilities = templates;


        setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.allTemplatesOrFacilities);
            this.dataSource.sort = this.sort;
            this._changeDetectorRefs.detectChanges();
        });
        // this._changeDetectorRefs.detectChanges();
        this.templatesLoaded = true;
    }
    initializeSystemTemplates(templates) {

        templates.sort((a, b) => a.title > b.title ? 1 : -1);
        this.allSystemTemplatesOrFacilities = templates;


        setTimeout(() => {
            this.systemDataSource = new MatTableDataSource(this.allSystemTemplatesOrFacilities);
            this.systemDataSource.sort = this.systemSort;
            this._changeDetectorRefs.detectChanges();
        });
        // this._changeDetectorRefs.detectChanges();
        this.templatesLoaded = true;
    }

    /*
    initializeTemplatesAndFolders(templatesAndFolders) {

     let folders:Array<IFolder> = [];
     let templates:Array<ITemplate> = [];

     folders = templatesAndFolders.folders;
     folders.sort((a, b) => a.title > b.title ? 1 : -1);

     templates = templatesAndFolders.templates;
     templates.sort((a, b) => a.title > b.title ? 1 : -1);



     this.folders = folders;
     this.templates = templates;
     this.allTemplatesAndFolders = [...folders, ...templates];
     setTimeout(() => {
       this.dataSource = new MatTableDataSource(this.allTemplatesAndFolders);
       this.dataSource.sort = this.sort;
       this._changeDetectorRefs.detectChanges();
     });
       // this._changeDetectorRefs.detectChanges();
     this.templatesLoaded = true;
    }
   */

    async fetchFacilities() {
        return new Promise(async (resolve, reject) => {
            try {
                //  this.loading = true;
                this.navigating = true;
                // if(item) this.historyStack.push(item);

                //  const templatesAndfoldersResponse:any = await this.templateExplorerService.getMyTemplatesAndFolders(item ? item._id : '').toPromise();

                let response: any = {};
                // if (this.currentUser.user_type == global.USER_TYPE.DOCTOR) {
                //     response = await this._transcriptionCreateService.getFacilities(this.currentUser._id).toPromise();
                // } else {
                //     response = await this._dashboardService.getAssociatedFacilityOfTranscriber().toPromise();
                // }
                   if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
                    const filter = {
                        associated_snf_wc_nurse_ids: this.currentUser._id,
                    };
                    const nurseProjection = {
                        first_name: 1,
                        last_name: 1,
                        title: 1,
                    };
                    const facilityProjection = {};
                    response = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
                    if (response.status === 200) {
                        response.data.array = response.data;
                    }
                }
                else {
                    response = await this._transcriptionCreateService.getFacilities(this.provider._id).toPromise();
                }
                if (response.status === 200) {
                    resolve(response.data.array);
                } else {
                    reject(response.message)
                }

            } catch (err) {
                reject(err);
            } finally {
                this.navigating = false;
            }

        })
    }

    async fetchTemplates(facility) {
        const facility_id = typeof facility === 'string' ? facility : facility._id;
        //  this.loading = true;
        return new Promise(async (resolve, reject) => {
            try {
                this.navigating = true;
                // if(item) this.historyStack.push(item);

                //  const templatesAndfoldersResponse:any = await this.templateExplorerService.getMyTemplatesAndFolders(item ? item._id : '').toPromise();

                let response: any = await this.templateExplorerService.getTemplatesByFacilityId(facility_id, this.provider._id).toPromise();
                if (response.status === 200) {
                    console.log('this response', response);
                    response.data.forEach(template => {
                        if (template.template_type == 'user_defined') {
                            if(template.hidden_for.length == 0){
                                template.is_active = true;
                                console.log('template.hidden_for.length == 0')
                            }
                            template.hidden_for.forEach(element => {
                                if(element == this.currentUser._id){
                                    template.is_active = false;
                                }else{
                                    template.is_active = true;
                                    console.log('element == this.currentUser._id')
                                }
                            });
                            // console.log('temp user defined', this.templatess);
                        }
                    });
                    resolve(response.data);
                } else {
                    reject(response.message)
                }

            } catch (err) {
                reject(err);
            } finally {
                this.navigating = false;
            }

        })
    }
    async fetchSystemTemplates(facility) {
        const facility_id = typeof facility === 'string' ? facility : facility._id;
        //  this.loading = true;
        return new Promise(async (resolve, reject) => {
            try {
                this.navigating = true;
                // if(item) this.historyStack.push(item);

                //  const templatesAndfoldersResponse:any = await this.templateExplorerService.getMyTemplatesAndFolders(item ? item._id : '').toPromise();

                let response: any = await this.templateExplorerService.getSystemTemplatesByFacilityId(facility_id).toPromise();
                if (response.status === 200) {
                    console.log('response system templates', response);
                    response.data.forEach(template => {
                        if (template.template_type == 'system_defined') {
                           if(template.hidden_for.length == 0){
                               template.is_active = true;
                           }
                           template.hidden_for.forEach(element => {
                               if(element == this.currentUser._id){
                                   template.is_active = false;
                               }else{
                                   template.is_active = true;
                               }
                           });
                        }
                    });
                    resolve(response.data);
                } else {
                    reject(response.message)
                }

            } catch (err) {
                reject(err);
            } finally {
                this.navigating = false;
            }

        })
    }

    //  async fetchTemplatesAndFolders(item:IFolder=null) {
    //   //  this.loading = true;
    //    return new Promise(async (resolve,reject) => {
    //      try {
    //       this.navigating = true;
    //       if(item) this.historyStack.push(item);

    //        const templatesAndfoldersResponse:any = await this.templateExplorerService.getMyTemplatesAndFolders(item ? item._id : '').toPromise();

    //        if(templatesAndfoldersResponse.status === 200) {
    //          this.initializeTemplatesAndFolders(templatesAndfoldersResponse.data.array);
    //        }

    //       resolve(null);
    //     } catch(err) {
    //       reject(err);
    //     } finally {
    //       // this.loading = false;
    //       this.navigating = false;
    //      };
    //    })
    //  }


    isEmpty() {
        return this.allTemplatesOrFacilities.length === 0;
    }

    async upOneLevel() {
        if (this.navigating) return;
        this.historyStack.pop(); // discard current folder
        // this.fetchTemplatesAndFolders(this.historyStack.pop()); // pop the previous folder
        const facilities = await this.fetchFacilities();
        this.initializeFacilities(facilities);
        this.selectedItem = null;
        this.inFolder = false;
        this.selectedIndex = 0;
    }

    onFolderClick(folder: IFolder) {
        if (this.navigating) return;
        // uncomment
        this.toggleTemplateSelection(folder);
        // this.fetchTemplatesAndFolders(folder);
    }

    onTemplateClick(template: ITemplate) {
        if (this.navigating) return;
        // uncomment
        this.toggleTemplateSelection(template);
        // this.downloadTemplate(template);

    }

    // uncomment
    onTemplateDoubleClick(template: ITemplate) {
        // this.downloadTemplate(template); 
        this.routingToTemplateCreateComponent = true;
        if(this.company_id) {
            this.router.navigate(['template-create', { id: template._id, company_id :this.company_id }]);
        }else {
            this.router.navigate(['template-create', { id: template._id }]);
        }
        this.selectedItem = null;
    }

    onSystemTemplateDoubleClick(template: ITemplate) {
        console.log('template to open', template);
        if (this.currentUser.company_id) {
            this.export = true;
            this.routingToTemplateCreateComponent = true;
            this.router.navigate(['template-create', { id: template._id, driveSide: 'true', companyId: this.currentUser.company_id }]);
            // console.log('temp id', template._id);
            // console.log('template_type', template.template_type);
            // console.log('user', this.currentUser);
        } else {
            this.router.navigate(['template-create', { id: template._id }]);
            this.routingToTemplateCreateComponent = true;

        }

    }

    async onFolderDoubleClick(folder: ITemplate, _id) {
        console.log(folder);
        // this.fetchTemplatesAndFolders(folder);
        // this.router.navigate(['drive/templates'], {
        //     queryParams: {
        //       folder: folder._id
        //     },
        //     queryParamsHandling: 'merge',
        //   });
        // window.history.replaceState(null, null, "drive/templates?"+folder._id);
        localStorage.setItem('drive_facility_folder_id', folder._id);
        localStorage.setItem('drive_facility_folder', JSON.stringify(folder));
        this.historyStack.push(folder);
        const templates = await this.fetchTemplates(folder._id);
        this.initializeTemplates(templates);
        const systemtemplates = await this.fetchSystemTemplates(folder._id);

        this.commonService.setFacilitySelected(folder._id);
        // console.log('subscribing', folder._id);

        this.initializeSystemTemplates(systemtemplates);
        if (this.associatedProviders[0] && this.historyStack.length > 0) {
            this.getAssociatedProviderTemplate(this.associatedProviders[0]._id, this.historyStack[0]._id);
        }
        this.selectedItem = null;
        this.inFolder = true;
    }

    toggleTemplateSelection(thisItem: ITemplate | IFolder) {
        // const index = this.selectedTemplates.indexOf(item);
        // if(index === -1) {
        //   item.selected = true;
        //   this.selectedTemplates.push(item);
        // } else {
        //   item.selected = false;
        //   this.selectedTemplates.splice(index,1);
        // }

        this.selectedItem = thisItem;
        if (this.selectedItem.selected) {
            this.selectedItem.selected = false;
            this.selectedItem = null;
        } else {
            this.allTemplatesOrFacilities.forEach((item: ITemplate | IFacility) => {
                item.selected = (item === this.selectedItem);
            });
            // this.getDriveActivity(this.selectedItem);
        }
    }

    dismisTemplateSelection() {
        if (this.selectedItem) {
            this.selectedItem.selected = false;
            this.selectedItem = null;
            this.showTemplateSidebar = false;
        }
        // this.allTemplatesAndFolders.filter((item:ITemplate|IFolder) => {
        //   item.selected = false;
        // });
    }

    onTemplateContainerClick(e) {
        const classList = Array.from(e.target.classList);
        if (classList.includes('dismis-template-selection') || classList.includes('mat-figure')) {
            this.dismisTemplateSelection();
        }
    }

    toggleTemplatesView() {
        this.dismisTemplateSelection();
        if (this.view_type === 'GRID') {
            this.view_type = "LIST";
        } else {
            this.view_type = "GRID";
        }
    }

    watchHelpVideo( videoId ) {
        const dialogRef = this.dialog.open(WatchHelpVideoDialog, {
            // width: '500px',
            data: { videoId }
        });

        dialogRef.afterClosed().subscribe( result => {
            console.log(result);
        });
    }

    onItemContextMenu(event: MouseEvent, item: ITemplate | IFolder) {
        // event.preventDefault();
        // this.contextMenuPosition.x = event.clientX + 'px';
        // this.contextMenuPosition.y = event.clientY + 'px';
        // this.contextMenu.menuData = { 'item': item };
        // this.contextMenu.openMenu();
        // uncomment
        // if(item !== this.selectedItem) this.toggleTemplateSelection(item);
    }

    onRenameItemClick(contextMenuData: IFolder) {
        const dialogRef = this.dialog.open(CreateNewFolderDialog, {
            width: '500px',
            data: { action: 'rename', title: contextMenuData.title }
        });
        dialogRef.afterClosed().subscribe((title) => {
            if (!title) return;
            this.renameFolder(contextMenuData, title);
        })
    }

    onShareItemClick(contextMenuData: ITemplate | IFolder) {
        // const dialogRef = this.dialog.open(ShareItemDialog, {width: '500px', data: {item: contextMenuData}});
        // dialogRef.afterClosed().subscribe( (emails:String[]) => {
        //   if(!emails) return;
        //   this.shareItem(contextMenuData, emails);
        // })
    }

    onDeleteItemClick(contextMenuData: ITemplate | IFolder) {
        const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
            width: '500px',
            data: { title: (<ITemplate>contextMenuData).title || (<IFolder>contextMenuData).title }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) return;
            // if((<ITemplate>contextMenuData).template_text) {
            //   this.deleteTemplate(<ITemplate>contextMenuData);
            // } else {
            //   this.deleteFolder(<IFolder>contextMenuData);
            // }
            this.deleteItem(contextMenuData);
        })
    }

    onDownloadTemplateClick(contextMenuData: ITemplate) {
        this.downloadTemplate(contextMenuData);
    }

    async shareItem(item: ITemplate | IFolder, emails: String[]) {
        // const infoToast = this.toastr.info('Processing...', 'Please wait', {disableTimeOut:true});
        // let item_type:String;
        // if((<ITemplate>item).template_text) {
        //   item_type = 'template';
        // } else {
        //   item_type = 'folder';
        // }
        //
        // const shareItemResponse:any = await this.templateExplorerService.shareItem(item._id,emails, item_type).toPromise();

        // if(shareItemResponse.status === 200) {
        //   // sending emails to newly added emails
        //   const emailSubject = `${this.currentUser.first_name} ${this.currentUser.last_name} shared ${item.title}`;
        //   const emailBody = `<strong>${this.currentUser.first_name} ${this.currentUser.last_name}</strong> has shared ${item_type} <strong>${item.title}</strong> with you. <a href="https://doctornow.io">Click Here</a> to view.`;
        //   for(let email of emails) {
        //     if(!item.shared_by_email.includes(email)) {
        //       var emailDetails = {
        //         email: email,
        //         text:emailBody,
        //         subject:emailSubject
        //       };
        //       await this.dashboardService.sendNewDictaionRequestEmail(emailDetails).toPromise();
        //     }
        //   }

        //   await this.reload();
        // } else {
        //   this.toastr.error(shareItemResponse.message, 'Failed');
        // }
        // this.toastr.clear(infoToast.toastId);
    }

    async downloadTemplate(template: ITemplate) {
        const infoToast = this.toastr.info("Preparing template to download...", 'Please wait', { disableTimeOut: true });
        try {
            const downloadTemplateResponse: any = await this.templateExplorerService.downloadTemplate(template._id).toPromise();
            var url = window.URL.createObjectURL(downloadTemplateResponse);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = <string>template.title;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element

        } catch (error) {
            this.toastr.error("Unable to download template.", "Failed");
        } finally {
            this.toastr.clear(infoToast.toastId);
            this.toastr.success("Downloaded Successfully","Success")
        }
    }

    async createNewFolder(folder: any) {
        // return new Promise( async (resolve, reject) => {
        //   const infoToast = this.toastr.info('Creating New Folder...', 'Please wait', {disableTimeOut:true, positionClass:'toast-bottom-right'});
        //   const createFolderResponse:any = await this.templateExplorerService.createNewFolder(folder).toPromise();
        //   if(createFolderResponse.status === 200) {
        //     await this.reload();
        //     resolve(createFolderResponse.data);
        //   } else {
        //     this.toastr.error(createFolderResponse.message, 'Failed');
        //     reject(createFolderResponse.message);
        //   }
        //   this.toastr.clear(infoToast.toastId);
        // });
    }

    // async deleteFolder(folder:IFolder) {
    //   const infoToast = this.toastr.info('Deleting Folder...', 'Please wait', {disableTimeOut:true});
    //   const deleteFolderResponse:any = await this.templateExplorerService.deleteFolder(folder._id).toPromise();
    //   if(deleteFolderResponse.status === 200) {
    //     await this.reload();
    //   } else {
    //     this.toastr.error(deleteFolderResponse.message, 'Failed');
    //   }
    //   this.toastr.clear(infoToast.toastId);
    // }
    async deleteTemplate(template: ITemplate) {
        const message = 'Are you sure you want to delete template?'
        const dialogBoxValue = await this.dashboardService.DeleteConfirmationDialog(message);
        if(dialogBoxValue === 'true') {
        const infoToast = this.toastr.info('Deleting Template...', 'Please wait', { disableTimeOut: true });
        const deleteTemplateResponse: any = await this.templateExplorerService.deleteTemplate(template._id).toPromise();
        if (deleteTemplateResponse.status === 200) {
            await this.reload();
            this.toastr.success("Deleted SucessFully")
        } else {
            this.toastr.error(deleteTemplateResponse.message, 'Failed');
        }
        this.toastr.clear(infoToast.toastId);
        }else{
        () => this.toastr.error('Something went wrong while removing template', 'Failed')
        }
 }

    async deleteItem(item: ITemplate | IFolder) {
        // const infoToast = this.toastr.info('Deleting...', 'Please wait', {disableTimeOut:true});
        // const item_type = (<ITemplate> item).template_text ? 'template' : 'folder';
        // const deleteItemResponse:any = await this.templateExplorerService.deleteItem(item._id, item_type).toPromise();
        // if(deleteItemResponse.status === 200) {
        //   await this.reload();
        // } else {
        //   this.toastr.error(deleteItemResponse.message, 'Failed');
        // }
        // this.toastr.clear(infoToast.toastId);
    }

    async renameFolder(folder: IFolder, new_folder_title) {
        // const infoToast = this.toastr.info('Renaming Folder', 'Please wait', {disableTimeOut:true});
        // const renameFolderResponse:any = await this.templateExplorerService.renameFolder(folder._id, new_folder_title).toPromise();
        // if(renameFolderResponse.status === 200) {
        //   await this.reload();
        // } else {
        //   this.toastr.error(renameFolderResponse.message, 'Failed');
        // }
        // this.toastr.clear(infoToast.toastId);
    }

    async changeParentFolderId(item: IFolder, parent_folder_id) {
        //  const item_type = 'folder';
        //   const changeParentFolderIdResponse:any = await this.templateExplorerService.changeParentFolderId(item._id, parent_folder_id, item_type).toPromise();
        //   if(changeParentFolderIdResponse.status === 200) {
        //     await this.reload();
        //   } else {
        //     this.toastr.error(changeParentFolderIdResponse.message, 'Failed');
        //   }
    }

    async uploadTemplate(templateBlob: any, templateData: ITemplate = null) {
        // const parent_folder_id = this.historyStackTop === null ? '' : this.historyStackTop._id;

        // const template:ITemplate = templateData ? templateData : {
        //   _id: '',
        //   parent_folder_id: parent_folder_id,
        //   title: templateBlob.name,
        //   creation_date: this._date_pipe.transform(new Date().toString(), global.date_time_format),
        //   updation_date: this._date_pipe.transform(new Date().toString(), global.date_time_format),
        //   owner_id: this.currentUser._id,
        //   owner_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
        //   mime_type: templateBlob.type,
        //   shared_by_email: [],
        //   is_deleted: 'false',
        //   template_text: null // will be replaced with aws template path
        // }

        // const infoToast = this.toastr.info(templateBlob.name, 'Uploading Template', {tapToDismiss:false, disableTimeOut:true,positionClass:'toast-bottom-right'});
        // const uploadTemplateResponse:any = await this.templateExplorerService.uploadTemplate(template, templateBlob);
        // if(uploadTemplateResponse.status === 200) {
        //   await this.reload();
        // } else {
        //   this.toastr.error(uploadTemplateResponse.message, 'Failed');
        // }
        // this.toastr.clear(infoToast.toastId);

    }


    async reload() {
        new Promise(async (resolve, reject) => {
            try {
                // await this.fetchTemplatesAndFolders(this.historyStack.pop());
                let result;
                if (!this.historyStackTop) {
                    const facilities = await this.fetchFacilities();
                    result = await this.initializeFacilities(facilities);
                } else {
                    const templates = await this.fetchTemplates(this.historyStackTop);
                    result = await this.initializeTemplates(templates);
                }
                this.selectedItem = null;
                resolve(result);
            } catch (error) {
                reject(error);
            }
        })
    }

    onCreateNewFolderClick() {
        // const dialogRef = this.dialog.open(CreateNewFolderDialog, {width: '500px', data: {action:'create'}});
        // dialogRef.afterClosed().subscribe( (title) => {
        //   if(!title) return;
        //   const parent_folder_id = this.historyStackTop === null ? '' : this.historyStackTop._id;
        //   const folder:IFolder = {
        //     _id: '',
        //     parent_folder_id: parent_folder_id,
        //     title:title,
        //     creation_date: this._date_pipe.transform(new Date().toString(), global.date_time_format),
        //     updation_date: this._date_pipe.transform(new Date().toString(), global.date_time_format),
        //     owner_id:this.currentUser._id,
        //     owner_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
        //     // shared_by_email:[],
        //     is_deleted:'false'
        //   };
        //   this.createNewFolder(folder)
        // })
    }

    onUploadTemplateClick() {
        // const dialogRef = this.dialog.open(UploadTemplateDialog, {width: '500px'});
        // dialogRef.afterClosed().subscribe( (templateInput) => {
        //   if(!templateInput || (templateInput && templateInput.templates.length === 0)) return;
        //   const templateBlob:Template = templateInput.templates[0];
        //   this.uploadTemplate(templateBlob);
        // })
    }

    onSharedByMeClick() {
        // this.router.navigate(['shared-with-me'], {relativeTo: this.route})
    }

    getItemIcon(item) {
        if (this.isFile(item)) {
            const { title } = item;
            const extension = title.substr(title.lastIndexOf('.') + 1);
            const templatetypeIconsPath = '../../../assets/img/filetypes';

            return (`${templatetypeIconsPath}/unknown.svg`);
            // return (`${templatetypeIconsPath}/${extension}.svg`);
        } else {
            // folder
            return (`../../../assets/img/folder-icon.png`);
        }
    }

    async updateItemDescription(item, description) {
        this.isEditingDescription = false;
        // this.loading = true;
        item.updation_date = new Date();
        try {
            let item_type;
            if (this.isFile(item)) {
                item_type = 'template';
            } else {
                item_type = 'folder';
            }
            await this.templateExplorerService.updateItemDescription(item._id, description, item_type).toPromise();
        } catch (err) {
            console.error(err)
        } finally {
            // this.loading = false;
        }
    }

    //  async getDriveActivity(item) {
    //     try {
    //      const driveActivityResponse:any = await this.templateExplorerService.getDriveActivity(item._id).toPromise();
    //      if(driveActivityResponse.status === 200) {
    //        item.drive_activity = driveActivityResponse.data.array.map(activity => {
    //          activity.activity_description = activity.activity_description.replace(`${this.currentUser.first_name} ${this.currentUser.last_name}`, 'You').replace(this.currentUser.email, 'You');
    //          return activity;
    //        });
    //      }
    //     } catch(err) {
    //       console.error(err)
    //     } finally {
    //       // this.loading = false;
    //     }
    //   }


    public arrangeIntoTree(paths) {
        // Adapted from http://brandonclapp.com/arranging-an-array-of-flat-paths-into-a-json-tree-like-structure/

        const findWhere = (array, key, value) => {
            // Adapted from https://stackoverflow.com/questions/32932994/findwhere-from-underscorejs-to-jquery
            let t = 0; // t is used as a counter
            while (t < array.length && array[t][key] !== value) {
                t++;
            }
            ; // find the index where the id is the as the aValue

            if (t < array.length) {
                return array[t]
            } else {
                return false;
            }
        }
        var tree = [];

        for (var i = 0; i < paths.length; i++) {
            var path = paths[i];
            var currentLevel = tree;
            for (var j = 0; j < path.length; j++) {
                var part = path[j];

                var existingPath = findWhere(currentLevel, 'name', part);

                if (existingPath) {
                    currentLevel = existingPath.children;
                } else {
                    var newPart = {
                        name: part,
                        children: [],
                    }

                    currentLevel.push(newPart);
                    currentLevel = newPart.children;
                }
            }
        }
        return tree;
    }

    public dropped(templates: any[]) {
        // this.droppedTemplates = templates;

        // // first create directories
        // const paths = [];
        // for (const droppedTemplate of templates) {

        //   if (droppedTemplate.templateEntry.isTemplate) {
        //     const pathSplit = droppedTemplate.templateEntry['fullPath'].split('/');
        //     paths.push([...pathSplit.slice(1, pathSplit.length-1), droppedTemplate.templateEntry]) ; // skip first empty item and last actual item
        //   } else {
        //     // empty directory
        //   }
        // }

        // const pathsTree = this.arrangeIntoTree(paths);
        // this.recusiveTreeUpload(pathsTree, this.historyStackTop ? this.historyStackTop._id : '');

        // // for (const droppedTemplate of templates) {

        // //   // Is it a template?
        // //   if (droppedTemplate.templateEntry.isTemplate) {
        // //     const templateEntry = droppedTemplate.templateEntry as TemplateSystemTemplateEntry;
        // //     templateEntry.template((template: Template) => {

        // //       return;


        // //       // Here you can access the real template
        // //       // this.uploadTemplate(template);
        // //       /**
        // //       // You could upload it like this:
        // //       const formData = new FormData()
        // //       formData.append('logo', template, relativePath)

        // //       // Headers
        // //       const headers = new HttpHeaders({
        // //         'security-token': 'mytoken'
        // //       })

        // //       this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
        // //       .subscribe(data => {
        // //         // Sanitized logo returned from backend
        // //       })
        // //       **/

        // //     });
        // //   } else {
        // //     if(droppedTemplate.templateEntry.isDirectory) {
        // //       const newFolder:IFolder = {
        // //         parent_folder_id: this.historyStackTop ? this.historyStackTop._id : '',
        // //         title: droppedTemplate.templateEntry.name,
        // //         creation_date: this._date_pipe.transform(new Date().toString(), global.date_time_format),
        // //         updation_date: this._date_pipe.transform(new Date().toString(), global.date_time_format),
        // //         owner_id:this.currentUser._id,
        // //         owner_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
        // //         shared_by_email:[],
        // //         is_deleted:'false'
        // //       }
        // //       this.createNewFolder(newFolder);
        // //     }

        // //     // It was a directory (empty directories are added, otherwise only templates)
        // //     // const templateEntry = droppedTemplate.templateEntry as TemplateSystemDirectoryEntry;
        // //   }
        // // }
    }

    public templateOver(event) {
    }

    public templateLeave(event) {
    }

    isFile(item) {
        return Object.prototype.hasOwnProperty.call(item, 'template_text')
    }

    async handleProviderChange() {
        this.templateExplorerService.setProviderId(this.provider);
        this.reload();
    }
    onProviderChange($event) {
        if (this.historyStack.length > 0)
            this.getAssociatedProviderTemplate($event.value._id, this.historyStack[0]._id);

    }
    getAssociatedProviderTemplate(userId, facilityId = null) {
        this._templatesService.getTemplatesByFacilityId(facilityId, userId).subscribe((response: any) => {
            if (response.status === 200) {
                // console.log("assoc templates",response.data);
                this.associatedProviderTemplates = response.data;
                this.dataSource2 = new MatTableDataSource(this.associatedProviderTemplates);
                this.dataSource2.sort = this.sort;
            }
        });
    }
    tabChanged(tabChangeEvent: MatTabChangeEvent) {
        // console.log("changes",tabChangeEvent);
        this.selectedIndex = tabChangeEvent.index;
        if(this.selectedIndex!=0){
            this.myTemplates = true
        }
        else {
            this.myTemplates = false
        }
        this.dismisTemplateSelection();
    }
    toggle(template_id, $event) {
        this.isTemplateActive = $event.checked;
        console.log('this.isTemplateActive', this.isTemplateActive);
        this.showOrHideTemplates(template_id, this.isTemplateActive, this.currentUser._id);
    }
    showOrHideTemplates(template_id, value, userId) {
        this.templateExplorerService.showOrHideTemplates(template_id, value, userId).subscribe((response: any) => {
            if (response.status == 200) {
                console.log('template state updated');
            }
        });
    }
    sortData(sort: Sort) {
        const data = this.allTemplatesOrFacilities.slice();
        this.allTemplatesOrFacilities = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'title': return compare(a.title.toString(), b.title.toString(), isAsc);

                case 'creation_date': return compare(new Date(a.creation_date.toString()).getTime(), new Date(b.creation_date.toString()).getTime(), isAsc);

                case 'updation_date': return compare(new Date(a.updation_date.toString()).getTime(), new Date(b.updation_date.toString()).getTime(), isAsc);

                default: return 0;
            }
        });
        this.dataSource = new MatTableDataSource(this.allTemplatesOrFacilities);
    }

    sortAssociateTemplateData(sort: Sort) {
        const data = this.associatedProviderTemplates.slice();
        //   console.log('sortData', sort);
        //   console.log("date",new Date(data[0].creation_date.toString()).getTime());
        this.associatedProviderTemplates = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'title': return compare(a.title.toString(), b.title.toString(), isAsc);

                case 'creation_date': return compare(new Date(a.creation_date.toString()).getTime(), new Date(b.creation_date.toString()).getTime(), isAsc);

                case 'updation_date': return compare(new Date(a.updation_date.toString()).getTime(), new Date(b.updation_date.toString()).getTime(), isAsc);

                default: return 0;
            }
        });
        this.dataSource2 = new MatTableDataSource(this.associatedProviderTemplates);
    }

    sortSystemTemplateData(sort: Sort) {
        const data = this.allTemplatesOrFacilities.slice();
        this.allTemplatesOrFacilities = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'title': return compare(a.title.toString(), b.title.toString(), isAsc);

                case 'creation_date': return compare(new Date(a.creation_date.toString()).getTime(), new Date(b.creation_date.toString()).getTime(), isAsc);

                case 'updation_date': return compare(new Date(a.updation_date.toString()).getTime(), new Date(b.updation_date.toString()).getTime(), isAsc);

                default: return 0;
            }
        });
        this.systemDataSource = new MatTableDataSource(this.allTemplatesOrFacilities);
    }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
