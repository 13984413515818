import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../includes/global';
import { Subscription, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { API_URL } from "../../environments/api-url";
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserTypesRelationshipService {
    currentUser: any;
    searchQuery: any;

    public constructor(
        private httpclient: HttpClient,
        private _router: Router,
        private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
    }

    getAllUsersList(is_activated = null, company_id = null, pageNo = null, userNameRegExp = null , role = null, addionalUserRole = null) {
        var item: any = {
            id: <string>null,
            auth_key: <string>null,
            is_activated,
            company_id,
        };
        item.id = this.currentUser._id,
            item.auth_key = this.currentUser.auth_key
        if (pageNo) {
            item.pageNo = pageNo;
        }
        if (userNameRegExp) {
            item.userNameRegExp = userNameRegExp;
        }
        if (role) {
            item.role = role;
        }
        if(addionalUserRole) {
            item.addionalUserRole = addionalUserRole;
        }
        
        return this.httpclient.post(global.url + API_URL.USER.getAllUsersList, { details: item }).pipe();
    }

    getDoctorsList(is_activated = null, company_id = null, pageNo = null, userNameRegExp = null , role = null, addionalUserRole = null, usertype = null, shouldFetchAll = false) {
        var item: any = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            is_activated,
            company_id,
            pageNo,
            ...shouldFetchAll && { shouldFetchAll }
        };
        item.id = this.currentUser._id,
            item.auth_key = this.currentUser.auth_key,
            item.usertype = usertype ? usertype : this.currentUser.user_type;
        if (pageNo) {
            item.pageNo = pageNo;
        }
        if (userNameRegExp) {
            item.userNameRegExp = userNameRegExp;
        }
        if (role) {
            item.role = role;
        }
        if(addionalUserRole) {
            item.addionalUserRole = addionalUserRole;
        }
        
        return this.httpclient.post(global.url + API_URL.USER.getDoctorsList, { details: item }).pipe();
    }
    getWoundNurseList(is_activated = null, company_id = null, pageNo = null, userNameRegExp = null , role = null) {
        var item: any = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            is_activated,
            company_id,
        };
        item.id = this.currentUser._id,
            item.auth_key = this.currentUser.auth_key,
            item.usertype = this.currentUser.user_type;
        if (pageNo) {
            item.pageNo = pageNo;
        }
        if (userNameRegExp) {
            item.userNameRegExp = userNameRegExp;
        }
        if (role) {
            item.role = role;
        }
        
        return this.httpclient.post(global.url + API_URL.USER.getWoundNurseList, { details: item }).pipe();
    }

    getViewOnlyUsersList(is_activated = null, company_id = null, pageNo = null, userNameRegExp = null , role = null) {
        var item: any = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            is_activated,
            company_id,
        };
        item.id = this.currentUser._id,
            item.auth_key = this.currentUser.auth_key,
            item.usertype = this.currentUser.user_type;
        if (pageNo) {
            item.pageNo = pageNo;
        }
        if (userNameRegExp) {
            item.userNameRegExp = userNameRegExp;
        }
        if (role) {
            item.role = role;
        }
        
        return this.httpclient.post(global.url + API_URL.USER.getViewOnlyUsersList, { details: item }).pipe();
    }

    getAdminList(is_activated = null, company_id = null) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            is_activated,
            company_id,
        };
        item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
        return this.httpclient.post(global.url + API_URL.USER.getAdminList, { details: item }).pipe();
    }

    getTranscriberList(is_activated = null, company_id = null, pageNo = null, userNameRegExp = null, role = null) {
        var item: any = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            is_activated,
            company_id
        };
        item.id = this.currentUser._id,
            item.auth_key = this.currentUser.auth_key,
            item.usertype = this.currentUser.user_type;
        if (pageNo) {
            item.pageNo = pageNo;
        }
        if (userNameRegExp) {
            item.userNameRegExp = userNameRegExp;
        }
        if (role) {
            item.role = role;
        }
        return this.httpclient.post(global.url + API_URL.USER.getTranscriberList, { details: item }).pipe();
    }

    getMAList(is_activated = null, company_id = null, userNameRegExp = null) {
        var item:any = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            is_activated,
            company_id
        };
        item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
        if (userNameRegExp) {
            item.userNameRegExp = userNameRegExp;
        }
        return this.httpclient.post(global.url + API_URL.USER.getMAList, { details: item }).pipe();
    }


    getBOList(is_activated = null, company_id = null, pageNo = null,userNameRegExp = null, role = null) {
        var item : any = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            is_activated,
            company_id
        };
        item.id = this.currentUser._id, 
        item.auth_key = this.currentUser.auth_key, 
        item.usertype = this.currentUser.user_type;
        if(pageNo){
            item.pageNo = pageNo;
        }
        if(role){
            item.role = role;
        }
        if(userNameRegExp){
            item.userNameRegExp = userNameRegExp;
        }
        return this.httpclient.post(global.url + API_URL.USER.getBOList, { details: item }).pipe();
    }

    createRelationProvider2MA(relationship) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            // company_id: <string>null,
            ...relationship
        }
        item.id = this.currentUser._id,
        item.auth_key = this.currentUser.auth_key,
        item.usertype = this.currentUser.user_type;
        // item.company_id = this.currentUser.company_id
        return this.httpclient.post(global.url + API_URL.USER.createRelationProvider2MA, { details: item }).pipe();
    }

    createRelationProvider2BO(relationship) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            // company_id: <string>null,
            ...relationship
        }
        item.id = this.currentUser._id, 
        item.auth_key = this.currentUser.auth_key, 
        item.usertype = this.currentUser.user_type;
        // item.company_id = this.currentUser.company_id;
        return this.httpclient.post(global.url + API_URL.USER.createRelationProvider2BO, { details: item }).pipe();
    }

    deleteRelationProvider2MA(relationship) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            // company_id: <string>null,

            ...relationship
        }
        item.id = this.currentUser._id, 
        item.auth_key = this.currentUser.auth_key, 
        item.usertype = this.currentUser.user_type;
        // item.company_id = this.currentUser.company_id;
        return this.httpclient.post(global.url + API_URL.USER.deleteRelationProvider2MA, { details: item }).pipe();
    }

    deleteRelationProvider2BO(relationship) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            // company_id: <string>null,

            ...relationship
        }
        item.id = this.currentUser._id, 
        item.auth_key = this.currentUser.auth_key, 
        item.usertype = this.currentUser.user_type; 
        // item.company_id = this.currentUser.company_id;
        return this.httpclient.post(global.url + API_URL.USER.deleteRelationProvider2BO, { details: item }).pipe();
    }

    getMAAssociations(company_id) {
        const details = {
            ...this._authService.authObject,
            createdBy: this.currentUser._id,
            company_id
        }
        return this.httpclient
            .post(global.url + API_URL.USER.getMAAssociations, { details: details }).pipe();
    };

    getBOAssociations(company_id) {
        const details = {
            ...this._authService.authObject,
            createdBy: this.currentUser._id,
            company_id
        }
        return this.httpclient
            .post(global.url + API_URL.USER.getBOAssociations, { details: details }).pipe();
    };

    //All Billers
    getBillersList(company_id = null) {
        var item : any = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            company_id,
        };
        item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type, item.is_activated = "true";
        return this.httpclient.post(global.url + API_URL.USER.getBillersList, { details: item }).pipe();
    }
    getNursesList(company_id = null, pageNo, userNameRegExp = null, role = null) {
        var item : any = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            company_id,
            pageNo
        };
        item.id = this.currentUser._id, 
        item.auth_key = this.currentUser.auth_key, 
        item.usertype = this.currentUser.user_type;
        if(userNameRegExp){
            item.userNameRegExp = userNameRegExp;
        }
        if(role){
            item.role = role;
        }
        item.is_activated = "true";
        return this.httpclient.post(global.url + API_URL.USER.getNursesList, { details: item }).pipe();
    }

    changeQA(params = {}) {
        return this.httpclient.post(global.url + API_URL.USER.changeQA, params);
    }
    changeQAE(params = {}) {
        return this.httpclient.post(global.url + API_URL.USER.changeQAE, params);
    }
    changeTrustedTrans(params = {}) {
        return this.httpclient.post(global.url + API_URL.USER.changeTrustedTrans, params);
    }
    getDoctorsWithRelashionship(company_id = null) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            company_id,
        };
        item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type, item.company_id = company_id;
        return this.httpclient.post(global.url + API_URL.USER.getDoctorsWithRelashionship, { details: item }).pipe(map((response: any) => {
            if (response.status === 200) {

                response.data = response.data.map(data => {
                    const { provider, qa, qa_e, assoc_trancriber_id, trsuted } = data;
                    // console.log("In service",provider);
                    if (typeof provider === 'object') {
                        provider.full_name = `${provider.first_name} ${provider.last_name}`;
                    }
                    if (typeof qa === 'object') {
                        qa.full_name = `${qa.first_name} ${qa.last_name}`;
                    }
                    if (typeof qa_e === 'object') {
                        qa_e.full_name = `${qa_e.first_name} ${qa_e.last_name}`;
                    }
                    if (typeof trsuted === 'object') {
                        trsuted.full_name = `${trsuted.first_name} ${trsuted.last_name}`;
                    }
                    return {
                        ...provider,
                        qa,
                        qa_e,
                        assoc_trancriber_id,
                        trsuted
                    }
                });
            }
            return response;
        }));
    }

    createRelationship(relationship) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null
        };
        item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
        return this.httpclient.post(global.url + API_URL.USER.createRelationship, {
            details: item,
            relationship: relationship
        }).pipe();
    }

    getManagerList(is_activated = null, company_id = null) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            is_activated,
            company_id
        };
        item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
        return this.httpclient.post(global.url + API_URL.USER.getManagerList, { details: item }).pipe();
    }

}
