<div class="flex flex-col gap-5">
  <h3>Add Patients to Census</h3>
  <mat-form-field class="flex-grow" style="position: relative;">
    <mat-chip-list #patientChipList aria-label="Select Patient">
      <mat-chip *ngFor="let patient of patients" [selectable]="true" [removable]="true"
        (removed)="removePatientAsChip(patient)">
        <app-patient-name [patient]="patient"></app-patient-name>
        <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
      </mat-chip>
      <input placeholder="Patient" (focus)="handlePatientInputFocus($event)" placeholder="Select Patient" #patientInput
        [formControl]="patientCtrl" [matAutocomplete]="auto" [matChipInputFor]="patientChipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addPatientAsChip($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedPatientAsChip($event)">
      <mat-option *ngFor="let patient of filteredPatients | async" [value]="patient">
        <app-patient-name [patient]="patient"></app-patient-name>
      </mat-option>
    </mat-autocomplete>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
  </mat-form-field>
  <div class="flex justify-end">
    <button mat-raised-button class="dn-button-primary" [mat-dialog-close]="patients">Done</button>
  </div>
</div>
