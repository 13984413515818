// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: false,
    socket_io_base_url : 'https://app.doctornow.io:8085/',
    base_url : 'https://app.doctornow.io',
    app_base_url : 'https://api-app.doctornow.io/api',
    url : 'https://api-app.doctornow.io/api',
    url_documents : 'https://api-app.doctornow.io/api/document',
    url_template : 'https://api-app.doctornow.io/api/template',
    url_pcc : 'https://api-app.doctornow.io/api/pcc',
    pcc_integration_base_url : 'https://connect.doctornow.io',
    url_patientroundingsheet : 'https://app.doctornow.io/patientroundingsheet',
    url_patientintakeform : 'https://app.doctornow.io/patientintakeform',
    rrgen : 'https://api-app.doctornow.io/api/rrgen',
    PUSHER: {
        API_KEY: '5f8b1b4cb56b52cc4cdb',
        CLUSTER: 'mt1',
        FORCE_TLS: true
    },
    portal_type: "wc",
    ENV: 'APP'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
