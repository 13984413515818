import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import moment from 'moment'
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BillerDashboardService } from '../../biller-dashboard.service';
import * as global from '../../../global';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { DialogConfirmationComponent } from 'src/app/includes/census-patient-list/dialog-confirmation/dialog-confirmation.component';

@Component({
  selector: 'app-charges-popup',
  templateUrl: './charges-popup.component.html',
  styleUrls: ['./charges-popup.component.css']
})
export class ChargesPopupComponent implements OnInit {
  patientData: any;
  codes: any;
  allCodes: any = {};
  companyNotesStatus: Boolean = false;
  submitCharge: BehaviorSubject<boolean> = new BehaviorSubject(false);
  chargesArray: any = []
  currentIndex: any;
  disableSubmit: Boolean = false;
  chargeSubmitted: Boolean = false;
  company_id: any;
  global = global;
  currentUser: any;
  company: any;
  constructor(
    public billerDashboardService: BillerDashboardService,
    private _route: ActivatedRoute,
    public _authService: AuthService,
    private _toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private companyService: CompanyService,
    private dialog: MatDialog,

    // @Inject(MAT_DIALOG_DATA) public arrayOfcharges: any,
  ) {
    this.currentUser = this._authService.currentUser;
    if (this.data.route) {
      if (window.location.href.includes('biller-charges')) {
        this.company_id = this._authService.currentUser.company_id;
      }
      else {
        this.company_id = this.data.route.parent.parent.snapshot.params.id;
      }

    }
  }

  async ngOnInit() {
    await this.getCompany();
    this.patientData = this.data.patient;
    if(!this.patientData?.patient_id){
      this._toastr.error("Patient Id does'nt exist.");
      // return;
    } 
    console.log("charge",this.patientData)
    this.companyNotesStatus = this.data.companyNoteStatus;
    this.chargesArray = this.data.arrayOfcharges;
    this.currentIndex = this.chargesArray.findIndex(charge => charge._id == this.patientData._id);
    if(this.patientData?.cpt_id.length == 0 || this.patientData?.icd_id.length == 0){
      this.disableSubmit = true;
    }
  }

  async getCompany() {
    const filter = {
        _id: this.company_id
    };
    const project = {
        name: 1,
        company_type: 1,
        portal_type: 1
    };
    const { status, data }: any = await lastValueFrom(this.companyService.getCompany(filter, project));
    if (status == 200) {
        this.company = data;
    }
    else if (status === 500) {
        this._toastr.error(data.message, "ERROR");
    }
}

  async submitButtonClicked() {
    if (this.checkToSumbitCharge()) {
      this.disableSubmit = true;
      let chargeId = "";
      let resubmitted_by = this._authService.currentUser._id;
      if (this.patientData) {
        chargeId = this.patientData._id;
      }
      else {
        chargeId = this.chargesArray[this.currentIndex]._id;
      }
      let ch = this.chargesArray.find(a => {
        if (a._id == chargeId) {
          return a
        }
      })
      // console.log("Selected Charge=== ", ch);
      let status = 'resubmit'
      if (ch && ch.status == 'draft') {
        status = 'submit'
      }
      // let status = ch.status
      if(this.company.portal_type === global.PORTAL_TYPE.NON_WC) {
        let isMultipleCharges = false, canSubmitChargeWithMupltipleCpts = false, response: any;
        const res : any = await lastValueFrom(this.companyService.getCompany({ _id: this.currentUser.company_id }, { allow_multiple_cpts: 1 }));
        if(res.status == 200) {
          canSubmitChargeWithMupltipleCpts = res.data.allow_multiple_cpts;
        }
        if (ch.cpt_id && ch.cpt_id.length > 1 && !canSubmitChargeWithMupltipleCpts)
          isMultipleCharges = true;
  
        if (isMultipleCharges) {
          let dialogRef = this.dialog.open(DialogConfirmationComponent, {
            data: {
              message: 'You have selected more than one CPT <br> Are you sure to submit?'
            }
          });
          dialogRef.afterClosed().subscribe(async result => {
            if (result === 'true') {
              response = await lastValueFrom(this.billerDashboardService.submitAndApproveCharge(chargeId, status, resubmitted_by));
              if (response.status == 200) {
                if (this.data.adminSide) {
                  if (this.currentIndex + 1 < this.chargesArray.length) {
                    this.currentIndex = this.currentIndex + 1
                    // this.patientData = this.chargesArray[this.currentIndex]
                    // this.disableSubmit = false;
                    let res: any = await this.billerDashboardService.getPatientsChargesAdmin(null, null, this.company_id, {}, this.chargesArray[this.currentIndex]._id).toPromise()
                    this.patientData = res.data[0];
                    this.disableSubmit = false;
                    this.chargeSubmitted = true;
                  }
      
                }
                else {
                  this.chargeSubmitted = true;
                  this.chargesArray[this.currentIndex].status = "submit";
                  this.submitCharge.next(true)
                  if (this.currentIndex + 1 < this.chargesArray.length) {
                    this.currentIndex = this.currentIndex + 1
                    this.patientData = this.chargesArray[this.currentIndex]
                    this.disableSubmit = false;
                  }
                }
                this._toastr.success("Charge successfully submitted");
              }
            }
            else if (result === 'false') {
              dialogRef.close();
            }
          });
        }
      } else {
        this.billerDashboardService.submitAndApproveCharge(chargeId, status, resubmitted_by).subscribe(async (response: any) => {
          if (response.status == 200) {
            if (this.data.adminSide) {
              if (this.currentIndex + 1 < this.chargesArray.length) {
                this.currentIndex = this.currentIndex + 1
                // this.patientData = this.chargesArray[this.currentIndex]
                // this.disableSubmit = false;
                let res: any = await this.billerDashboardService.getPatientsChargesAdmin(null, null, this.company_id, {}, this.chargesArray[this.currentIndex]._id).toPromise()
                this.patientData = res.data[0];
                this.disableSubmit = false;
                this.chargeSubmitted = true;
              }
  
            }
            else {
              this.chargeSubmitted = true;
              this.chargesArray[this.currentIndex].status = "submit";
              this.submitCharge.next(true)
              if (this.currentIndex + 1 < this.chargesArray.length) {
                this.currentIndex = this.currentIndex + 1
                this.patientData = this.chargesArray[this.currentIndex]
                this.disableSubmit = false;
              }
            }
          }
        })
      }
    }
  }
  async prevButtonClicked() {
    this.disableSubmit = false;
    if (this.currentIndex > 0) {
      this.currentIndex = this.currentIndex - 1;
      if (this.data.adminSide) {
        this.disableSubmit = true;
        let res: any = await this.billerDashboardService.getPatientsChargesAdmin(null, null, this.company_id, {}, this.chargesArray[this.currentIndex]._id).toPromise()
        this.patientData = res.data[0];
        if(this.patientData?.cpt_id.length == 0 || this.patientData?.icd_id.length == 0){
          this.disableSubmit = true;
        }else{
          this.disableSubmit = false;
        }        
      }
      else {
        this.patientData = this.chargesArray[this.currentIndex];
        if(this.patientData?.cpt_id.length == 0 || this.patientData?.icd_id.length == 0){
          this.disableSubmit = true;
        }
      }
    }
  }
  async nextButtonClicked() {
    this.disableSubmit = false;
    if (this.currentIndex + 1 < this.chargesArray.length) {
      this.currentIndex = this.currentIndex + 1;
      if (this.data.adminSide) {
        this.disableSubmit = true;
        let res: any = await this.billerDashboardService.getPatientsChargesAdmin(null, null, this.company_id, {}, this.chargesArray[this.currentIndex]._id).toPromise()
        this.patientData = res.data[0];
        if(this.patientData?.cpt_id.length == 0 || this.patientData?.icd_id.length == 0){
          this.disableSubmit = true;
        }else{
          this.disableSubmit = false;
        }
      }
      else {
        this.patientData = this.chargesArray[this.currentIndex];
        if(this.patientData?.cpt_id.length == 0 || this.patientData?.icd_id.length == 0){
          this.disableSubmit = true;
        }
      }
    }
  }
  checkToSumbitCharge() {
    if (this.patientData.note_id) {
      if (this.companyNotesStatus) {
        if (this.patientData.note_id.is_signed === "true") {
          return true;
        } else {
          this._toastr.info("Note must be signed first before submitting the charges");
          return false;
        }
      } else {
        return true;
      }
    } else {
      if (this.companyNotesStatus) {
        this._toastr.info("Note must be created first before submitting the charges");
        return false;
      } else {
        return true;
      }
    }
  }

  getpatientInfo() {
    let patient = this.patientData
    let patientInfo = patient.patient_first_name+ " " + patient.patient_last_name
    if (patient.patient_dob) {
      let newDate = patient.patient_dob.split('-');
      let [year, month, date] = newDate;
      let formattedDate = `${month}/${date}/${year}`

      patientInfo = patientInfo + "  (" + formattedDate + ")";
    }
    if (patient?.patient_gender.toLowerCase() == 'male') {
      patientInfo = patientInfo + " - M";
    }
    else {
      patientInfo = patientInfo + " - F";
    }
    return patientInfo;
  }

  getCodesLength() {
    let record = this.patientData;
    let icdCode = record.icd_id.length;
    let cptCode = record.cpt_id.length;

    if (icdCode >= cptCode) {
      this.codes = record.icd_id;
      this.allCodes.cpt = record.cpt_id;
      this.allCodes.icd = record.icd_id;
    }
    else {
      this.codes = record.cpt_id;
      this.allCodes.cpt = record.cpt_id;
      this.allCodes.icd = record.icd_id;
    }
    return this.codes;
  }

  getVisitDate(codeIndex) {
    let element = this.patientData;
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (index == 0) { 
      let date = element.visit_date
      if (element) {
        if(element.date_obj){
          // console.log("element.date_obj: ", element.date_obj);
          let {year, month, day, hour, minute} = element.date_obj;
          if(month < 10){
              month = '0' + month;
          }
          if(day < 10){
              day = '0' + day;
          }
          let str = `${month}/${day}/${year}`; 
          return str
        }
        else{
          let timeZone = 'America/New_York'
          if(element.facility_id_ref.pcc_timeZone){
            timeZone = element.facility_id_ref.pcc_timeZone
          }
          else if (element.facility_id_ref.timeZone){
            timeZone = element.facility_id_ref.timeZone
          }
          return moment(new Date(element.visit_date).toLocaleString('en-US', { timeZone: timeZone })).format('MM/DD/YYYY')
        }
        // return moment(new Date(date).toLocaleString('en-US', { timeZone:  'America/Los_Angeles' })).format('MMM DD, YYYY h:mm a')
        // return moment(new Date(date)).format('MMM DD, YYYY h:mm a')
      }
      else {
        return '';
      }
    }
    else {
      return '';
    }
  }

  getProvider(codeIndex) {
    let record = this.patientData;
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (index == 0) {
      return record.owner_id.first_name + " " + record.owner_id.last_name
    }
    else {
      return '';
    }
  }

  getICD(codeIndex) {
    let record = this.patientData;
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (record.icd_id[index] && record.icd_id[index]?.code) {
      return record.icd_id[index].code
    }
    else {
      return '-';
    }
  }
  getCPT(codeIndex) {
    let record = this.patientData;
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (record.cpt_id[index]) {
      return record.cpt_id[index].code
    }
    else {
      return '';
    }
  }

  getICDName(codeIndex) {
    let record = this.patientData;
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (record.icd_id[index] && (record.icd_id[index]?.shortDescription || record.icd_id[index]?.description)) {
      return record.icd_id[index].shortDescription ? record.icd_id[index].shortDescription : record.icd_id[index].description; 
    }
    else {
      return '';
    }
  }
  getCPTName(codeIndex) {
    let record = this.patientData;
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (record.cpt_id[index]) {
      return record.cpt_id[index].description
    }
    else {
      return '';
    }
  }
  checkVoidCensus(){
    if(this.patientData?.rounding_sheet_id?.is_void){
      if(this.patientData?.rounding_sheet_id.is_void === true) return false;
      else return true;
    }else{
      return true
    }
  }
  getAddress(codeIndex) {
    let patient = this.patientData;
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (index == 0) {
      if (patient && patient.facility_id_ref && patient.facility_id_ref.title) {
        return patient.facility_id_ref.title;
      }
      else {
        return '';
      }
    }
  }

}
