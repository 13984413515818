<div style="width: 100%;" class="flex flex-col items-center justify-center gap-1">
    <div class="flex-1" *ngIf="editor" style="text-align: center;">Image Editor</div>
    <div class="flex-1" *ngIf="!editor" style="text-align: center;">Image Viewer</div>

    <div class="flex flex-1 flex-row gap-1">
        <div class="w-3/4">
            <div *ngIf="editor" class="img-container">
                <img #image [src]="imageSource" cross-origin="use-credentials">
            </div>
            <div *ngIf="!editor">
                <img src="{{imageSource}}" style="width: auto;height: 70vh;">
            </div>
        </div>
        <div *ngIf="editor" class="w-1/4 flex-1 flex flex-col gap-1">
            <div class="flex w-2/5">
                <img [src]="imageDestination" class="img-preview">
            </div>
            <div style="display: flex;justify-content: center;">
                <button mat-raised-button class="dn-button-primary" (click)="doneCrop()">Save</button>
            </div>
        </div>
    </div>
    <div *ngIf="editor" class="flex flex-1 flex-row">
        <div class="flex-1 w-3/4" style="display: flex;justify-content: center;">
            <div style="background-color: #1f98d7;border-radius: 10px;width: 75%;">
                <button mat-icon-button (click)="cropperMode('move')" style="color: white;" aria-label="Drag">
                    <mat-icon matTooltip="Move Mode">control_camera</mat-icon>
                </button>
                <button mat-icon-button (click)="cropperMode('crop')" style="color: white;" aria-label="Crop">
                    <mat-icon matTooltip="Crop Mode">crop_free</mat-icon>
                </button>
                <button mat-icon-button (click)="cropImage()" style="color: white;" aria-label="Crop">
                    <mat-icon matTooltip="Crop Image">crop</mat-icon>
                </button>
                <button mat-icon-button (click)="cropperZoom(0.1)" style="color: white;" aria-label="Zoom_in">
                    <mat-icon matTooltip="Zoom in">zoom_in</mat-icon>
                </button>
                <button mat-icon-button (click)="cropperZoom(-0.1)" style="color: white;" aria-label="Zoom_out">
                    <mat-icon matTooltip="Zoom out">zoom_out</mat-icon>
                </button>
                <button mat-icon-button (click)="cropperRotate(-45)" style="color: white;" aria-label="Rotate_left">
                    <mat-icon matTooltip="Rotate left">rotate_left</mat-icon>
                </button>
                <button mat-icon-button (click)="cropperRotate(45)" style="color: white;" aria-label="Rotate_right">
                    <mat-icon matTooltip="Rotate right">rotate_right</mat-icon>
                </button>
                <button class="white-filter" (click)="cropperScaleX()" mat-icon-button
                    matTooltip="Scale horizontal"><img style="width: 25px;"
                        src="assets\icons\left-right.png" /></button>
                <button class="white-filter" (click)="cropperScaleY()" mat-icon-button matTooltip="Scale verticle"><img
                        style="width: 25px;" src="assets\icons\up-down.png" /></button>
                <button mat-icon-button (click)="cropperReset()" style="color: white;" aria-label="Reset">
                    <mat-icon matTooltip="Reset">loop</mat-icon>
                </button>
                <button mat-icon-button (click)="cropperClose()" style="color: white;" aria-label="Clear">
                    <mat-icon matTooltip="Close cropper">close</mat-icon>
                </button>
                <button mat-icon-button (click)="undoMode()" style="color: white;" aria-label="Clear">
                    <mat-icon matTooltip="Undo Action">undo</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>