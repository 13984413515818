<div class="flex flex-col flex-grow">
  <div class="dark-blue-heading flex">
    <div class="flex justify-start items-center gap-2">
      <i (click)="upOneLevel()" *ngIf="historyStack.length > 0 && templatesLoaded" class="icon icon-back"></i>
      <h3 class="template-heading-margin">{{header_title}}
        <ng-container *ngFor="let folderItem of historyStack">
          > {{folderItem.title}}
        </ng-container>
      </h3>
    </div>

    <div class="template-controls-bar flex justify-end items-center">
      <mat-card
        *ngIf="currentUser.user_type === global.USER_TYPE.TRANSCRIBER || currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT"
        style="
            padding-top: 0;
            padding-bottom: 0;
            margin: 0;
            height: 40px;
            overflow: hidden;">
        <mat-card-content>
          <mat-form-field>
            <mat-label>Providers</mat-label>
            <mat-select name="provider" [(ngModel)]="provider" (ngModelChange)="handleProviderChange()">
              <mat-option *ngFor="let p of providers" [value]="p">
                {{p.first_name}} {{p.last_name}}, {{p.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <button mat-mini-fab matTooltip="Reload" (click)="reload()" aria-label="icon-button"
        style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
        <mat-icon>refresh</mat-icon>
      </button>
      <button mat-mini-fab matTooltip="Delete" *ngIf="selectedItem && isFile(selectedItem)"
        (click)="deleteTemplate(selectedItem)" aria-label="icon-button"
        style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
        <mat-icon>delete</mat-icon>
      </button>

      <button mat-mini-fab matTooltip="View as List" *ngIf="view_type !== 'LIST'" (click)="toggleTemplatesView()"
        aria-label="icon-button"
        style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
        <mat-icon>view_list</mat-icon>
      </button>
      <button mat-mini-fab matTooltip="View as Grid" *ngIf="view_type !== 'GRID'" (click)="toggleTemplatesView()"
        aria-label="icon-button"
        style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
        <mat-icon>grid_on</mat-icon>
      </button>

      <button mat-mini-fab matTooltip="Help"
        aria-label="icon-button"
        style="transition: none !important;box-shadow: none !important; background:none !important;"
        [matMenuTriggerFor]="helpMenu"
      >
        <mat-icon>help</mat-icon>
      </button>
      <mat-menu #helpMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="watchHelpVideo('template-headings')">Watch Template Headings</button>
        <button mat-menu-item (click)="watchHelpVideo('template-guide')">Watch Template Guide</button>
      </mat-menu>

      <ng-container *ngIf="adminSide">
        <button mat-raised-button class="dn-button-primary" (click)="addNewTemplate()">
          Create New Template
        </button>
      </ng-container>
    </div>
  </div>




  <div class="template-container-sidebar-wrapper dismis-template-selection flex flex-grow justify-evenly items-stretch">
    <div class="template-container dismis-template-selection flex-grow" #templateContainer
      (click)="onTemplateContainerClick($event)">
      <ng-container *ngIf="templatesLoaded">
        <div *ngIf="!isEmpty() && view_type === 'GRID' && !inFolder"
          class="dismis-template-selection flex flex-wrap" gutterSize="15px" id="templates-grid">
          <ng-container *ngFor="let item of allTemplatesOrFacilities" class="dismis-template-selection">
            <div class="template-folder-item-wrapper dismis-template-selection flex justify-center items-start">
              <div [id]="item._id" class="flex flex-col">
                <ng-container *ngIf="isFile(item)">
                  <div class="flex flex-col justify-center items-center" (dblclick)="onTemplateDoubleClick(item)"
                    (click)="onTemplateClick(item)" (contextmenu)="onItemContextMenu($event, item)"
                    [ngClass]="{'template-folder-item':true, 'template-item':true, selected:item.selected}"
                    [ngStyle]="{'opacity': navigating ? '.6' : '1' }">

                    <span class="item-icon">
                      <img [src]="getItemIcon(item)" onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
                    </span>
                    <span class="item-title">
                      {{item.title.length > 20 ? ((item.title | slice:0:20) + '...') : item.title}}
                    </span>
                  </div>
                </ng-container>

                <ng-container *ngIf="!isFile(item)">
                  <div class="flex flex-col justify-center items-center" (dblclick)="onFolderDoubleClick(item)"
                    (click)="onFolderClick(item, $event)" (contextmenu)="onItemContextMenu($event, item)"
                    [ngClass]="{'template-folder-item':true, 'folder-item':true, selected: item.selected}"
                    [ngStyle]="{'opacity': navigating && (historyStackTop && historyStackTop._id !== item._id) ? '.6' : '1' }">
                    Folder
                    <span class="item-icon">
                      <img src="../../../assets/img/folder-icon.png" />
                    </span>
                    <span class="item-title">
                      {{item.title.length > 20 ? ((item.title | slice:0:20) + '...') : item.title}}
                    </span>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="!inFolder">
          <table *ngIf="view_type === 'LIST'" mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)"
            matSort>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
              <td mat-cell *matCellDef="let element">
                <div>
                  <div class="template-folder-item flex gap-2 justify-start items-center">
                    <span class="item-icon">
                      <img *ngIf="element" [src]="getItemIcon(element)"
                        onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
                    </span>
                    <div>
                      <img *ngIf="element.source === 'PointClickCare' || element.pcc_facId" src="../../../assets/icons/pcc-icon.svg"
                        alt="" style="width: 35px;height: 20px;margin-left: -5px;;vertical-align: middle;">
                      <img *ngIf="element.source === 'MatrixCare' " src="../../../assets/icons/matrix.png" alt=""
                        style="height: 12px;width: 20px;vertical-align: middle;margin-right: 5px;margin-left: 5px;">
                      <img *ngIf="element.source != 'PointClickCare' && !element.pcc_facId &&element.source != 'MatrixCare'"
                        class="dn-icon" src="../../../assets/img/DN.png" alt="">
                      {{element.title}}
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="creation_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Created on </th>
              <td mat-cell *matCellDef="let element">
                {{element.creation_date | date: global.date_time_format}}
              </td>
            </ng-container>
            <ng-container matColumnDef="updation_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated on </th>
              <td mat-cell *matCellDef="let element">
                {{element.updation_date | date: global.date_time_format}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              [ngClass]="{'template-folder-item':true, 'template-item':isFile(row), 'folder-item':!isFile(row), selected:row.selected}"
              (dblclick)="isFile(row) ? onTemplateDoubleClick(row) : onFolderDoubleClick(row)"
              (click)="isFile(row) ? onTemplateClick(row) : onFolderClick(row)"
              (contextmenu)="onItemContextMenu($event, row)"></tr>

          </table>
        </ng-container>
        <mat-tab-group (selectedTabChange)="tabChanged($event)" style="background-color: white; cursor: pointer;"
          *ngIf="inFolder">
          <mat-tab label="My Templates">
            <table *ngIf="view_type === 'LIST'" mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)"
              matSort>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                <td mat-cell *matCellDef="let element">
                  <div>
                    <div class="template-folder-item flex gap-2 justify-start items-center">
                      <span class="item-icon">
                        <img *ngIf="element" [src]="getItemIcon(element)"
                          onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
                      </span>
                      <div>{{element.title | uppercase}}</div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="creation_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created on </th>
                <td mat-cell *matCellDef="let element">
                  {{element?.createdAt | date: global.date_time_format}}
                </td>
              </ng-container>
              <ng-container matColumnDef="created_by_ref">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created by </th>
                <td mat-cell *matCellDef="let element">
                  {{element?.created_by_ref?.first_name}} {{element?.created_by_ref?.last_name}}
                </td>
              </ng-container>
              <ng-container matColumnDef="updation_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated on</th>
                <td mat-cell *matCellDef="let element">
                  {{element?.updatedAt | date: global.date_time_format}}
                </td>
              </ng-container>

              <ng-container matColumnDef="updated_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                <td mat-cell *matCellDef="let element">
                  {{element?.updated_by?.first_name}} {{element?.updated_by?.last_name}}
                </td>
              </ng-container>

              <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                <td mat-cell *matCellDef="let element">
                  <mat-slide-toggle (change)="toggle(element._id,$event)" [(ngModel)]="element.is_active">
                  </mat-slide-toggle>
                </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>
                  Type
                  <mat-icon
                    style="font-size: 15px; width: auto; height: auto; position: relative; top: 3px;"
                    matTooltip="Enable Auto Template Initiation by selecting default templates"
                    matTooltipPosition="left"
                  >info</mat-icon>
                </th>
                <td mat-cell *matCellDef="let element">
                  <span
                    *ngIf="element.for_note_type == null"
                  >--</span>
                  <span
                    *ngIf="element.for_note_type != null"
                    [ngStyle]="element.is_default === true ? {
                      'font-weight': 'bold',
                      'font-size': '13px',
                      'color': '#4e4747'
                    } : {}"
                  >
                    {{ element.for_note_type }}
                    <span
                      *ngIf="element.is_default === true"
                      style="font-style: italic; letter-spacing: 0.5px;"
                    >(Default)</span>
                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsMy"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsMy;"
                [ngClass]="{'template-folder-item':true, 'template-item':isFile(row), 'folder-item':!isFile(row), selected:row.selected}"
                (dblclick)="isFile(row) ? onTemplateDoubleClick(row) : onFolderDoubleClick(row)"
                (click)="isFile(row) ? onTemplateClick(row) : onFolderClick(row)"
                (contextmenu)="onItemContextMenu($event, row)"></tr>

            </table>
            <div style="background-color: #44597d;">
              <div *ngIf="view_type === 'GRID' && selectedIndex === 0" class="flex flex-wrap gap-5"
                style="padding: 25px;">
                <ng-container *ngFor="let item of allTemplatesOrFacilities">
                  <div class="flex flex-col justify-center items-center gap-4"
                    (click)="onTemplateClick(item)" (dblclick)="onTemplateDoubleClick(item)">
                    <img src="../../../../assets/img/filetypes/unknown.svg" alt=""
                      style="height: 70px; cursor: pointer; margin-left: 10px;">
                    <span class="item-title" [ngStyle]="{'font-size': '12px', 'color': adminSide ? 'black' : 'white'}">
                      {{item.title.length > 20 ? ((item.title | slice:0:20) + '...') : item.title}}
                    </span>
                    <div></div> 
                    <div></div>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="System Templates">
            <table *ngIf="view_type === 'LIST'" mat-table [dataSource]="systemDataSource"
              (matSortChange)="sortSystemTemplateData($event)" matSort>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                <td mat-cell *matCellDef="let element">
                  <div>
                    <div class="template-folder-item flex gap-2 justify-start items-center">
                      <span class="item-icon">
                        <img *ngIf="element" [src]="getItemIcon(element)"
                          onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
                      </span>
                      <div>{{element.title | uppercase}}</div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="creation_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created on </th>
                <td mat-cell *matCellDef="let element">
                  {{element?.createdAt | date: global.date_time_format}}
                </td>
              </ng-container>
              <ng-container matColumnDef="updation_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated on </th>
                <td mat-cell *matCellDef="let element">
                  {{element?.updatedAt | date: global.date_time_format}}
                </td>
              </ng-container>

              <ng-container matColumnDef="updated_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated by </th>
                <td mat-cell *matCellDef="let element">
                  {{element?.updated_by?.first_name}} {{element?.updated_by?.last_name}}
                </td>
              </ng-container>

              <ng-container matColumnDef="active_inactive">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Active/Inactive </th>
                <td mat-cell *matCellDef="let element">
                  <mat-slide-toggle (change)="toggle(element._id,$event)" [(ngModel)]="element.is_active">
                  </mat-slide-toggle>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsSystem"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsSystem;"
                [ngClass]="{'template-folder-item':true, 'template-item':isFile(row), 'folder-item':!isFile(row), selected:row.selected}"
                (dblclick)="isFile(row) ? onSystemTemplateDoubleClick(row) : onFolderDoubleClick(row)"
                (click)="isFile(row) ? onTemplateClick(row) : onFolderClick(row)"
                (contextmenu)="onItemContextMenu($event, row)"></tr>

            </table>
            <div style="background-color: #44597d;">
              <div *ngIf="view_type === 'GRID' && selectedIndex === 1" class="flex flex-wrap gap-5"
                style="padding: 25px;">
                <ng-container *ngFor="let item of allSystemTemplatesOrFacilities" class="dismis-template-selection">
                  <div class="flex flex-col justify-center items-center gap-4"
                    (click)="onTemplateClick(item)" (dblclick)="onTemplateDoubleClick(item)"
                    [ngClass]="{'template-folder-item':true, 'template-item':true, selected:item.selected}">
                    <img src="../../../../assets/img/filetypes/unknown.svg" alt=""
                      style="height: 70px; cursor: pointer; margin-left: 10px;">
                    <span class="item-title" [ngStyle]="{'font-size': '12px', 'color': adminSide ? 'black' : 'white'}">
                      {{item.title.length > 20 ? ((item.title | slice:0:20) + '...') : item.title}}
                    </span>
                    <div></div> 
                    <div></div>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Associated Provider Templates" *ngIf="userTypeForTemplates === global.USER_TYPE.DOCTOR">
            <div class="flex justify-end items-center" *ngIf="myTemplates">
              <mat-form-field style="font-weight: bolder;">
                <mat-label *ngIf="currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE">SNF WC Nurses</mat-label>
                <mat-label *ngIf="currentUser.user_type !== global.USER_TYPE.SNF_WC_NURSE">Providers</mat-label>
                <mat-select name="provider" [value]="associatedProviders[0]" (selectionChange)="onProviderChange($event, matMenu)">
                  <mat-option *ngFor="let p of associatedProviders" [value]="p">
                    {{p.first_name}} {{p.last_name}}, {{p.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <table *ngIf="view_type === 'LIST'" mat-table [dataSource]="dataSource2"
              (matSortChange)="sortAssociateTemplateData($event)" matSort>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                <td mat-cell *matCellDef="let element">
                  <div>
                    <div class="template-folder-item flex justify-start items-center gap-2">
                      <span class="item-icon">
                        <img *ngIf="element" [src]="getItemIcon(element)"
                          onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
                      </span>
                      <div>{{element.title}}</div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="creation_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created on </th>
                <td mat-cell *matCellDef="let element">
                  {{element?.createdAt | date: global.date_time_format}}
                </td>
              </ng-container>
              <ng-container matColumnDef="updation_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated on </th>
                <td mat-cell *matCellDef="let element">
                  {{element?.updatedAt | date: global.date_time_format}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{'template-folder-item':true, 'template-item':isFile(row), 'folder-item':!isFile(row), selected:row.selected}"
                (dblclick)="isFile(row) ? onTemplateDoubleClick(row) : onFolderDoubleClick(row)"
                (click)="isFile(row) ? onTemplateClick(row) : onFolderClick(row)"
                (contextmenu)="onItemContextMenu($event, row)"></tr>

            </table>

          </mat-tab>
          <mat-tab label="Associated SNF WC Nurse Templates" *ngIf="userTypeForTemplates === global.USER_TYPE.SNF_WC_NURSE">
            <div class="flex justify-end items-center" *ngIf="myTemplates">
              <mat-form-field style="font-weight: bolder;">
                <mat-label *ngIf="currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE">SNF WC Nurses</mat-label>
                <mat-label *ngIf="currentUser.user_type !== global.USER_TYPE.SNF_WC_NURSE">Providers</mat-label>
                <mat-select name="provider" [value]="associatedProviders[0]" (selectionChange)="onProviderChange($event, matMenu)">
                  <mat-option *ngFor="let p of associatedProviders" [value]="p">
                    {{p.first_name}} {{p.last_name}}, {{p.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <table *ngIf="view_type === 'LIST'" mat-table [dataSource]="dataSource2"
              (matSortChange)="sortAssociateTemplateData($event)" matSort>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                <td mat-cell *matCellDef="let element">
                  <div>
                    <div class="template-folder-item" class="flex gap-2 justify-start items-center">
                      <span class="item-icon">
                        <img *ngIf="element" [src]="getItemIcon(element)"
                          onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
                      </span>
                      <div>{{element.title}}</div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="creation_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created on </th>
                <td mat-cell *matCellDef="let element">
                  {{element?.createdAt | date: global.date_time_format}}
                </td>
              </ng-container>
              <ng-container matColumnDef="updation_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated on </th>
                <td mat-cell *matCellDef="let element">
                  {{element?.updatedAt | date: global.date_time_format}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{'template-folder-item':true, 'template-item':isFile(row), 'folder-item':!isFile(row), selected:row.selected}"
                (dblclick)="isFile(row) ? onTemplateDoubleClick(row) : onFolderDoubleClick(row)"
                (click)="isFile(row) ? onTemplateClick(row) : onFolderClick(row)"
                (contextmenu)="onItemContextMenu($event, row)"></tr>

            </table>

          </mat-tab>
        </mat-tab-group>

        <div *ngIf="view_type === 'GRID' && selectedIndex === 2" class="flex flex-wrap gap-5"
          style="padding: 25px;">
          <ng-container *ngFor="let template of dataSource2.data">
            <div class="flex flex-col justify-center items-start gap-4" style="flex:10%">
              <img src="../../../../assets/img/filetypes/unknown.svg" alt="" style="height: 70px; cursor: pointer;"
                (dblclick)="onTemplateDoubleClick(template)">
              <span [ngStyle]="{'font-size': '12px', 'color': adminSide ? 'black' : 'white'}"
                class="text-overflow">{{template?.title}}</span>
              <div></div> 
              <div></div> 
            </div>
          </ng-container>
        </div>


        <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
          [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
        </div>

        <mat-menu #contextMenu="matMenu">
          <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="onShareItemClick(item)" *ngIf="ownedItem(item)" autofocus>Share</button>
            <button mat-menu-item (click)="onRenameItemClick(item)"
              *ngIf="ownedItem(item) && !isFile(item)">Rename</button>
            <button mat-menu-item (click)="onDownloadTemplateClick(item)" *ngIf="isFile(item)"
              download>Download</button>
            <button mat-menu-item (click)="onDeleteItemClick(item)" *ngIf="ownedItem(item)">Delete</button>
          </ng-template>
        </mat-menu>

        <div class="empty-folder-view flex flex-col justify-center items-center" *ngIf="isEmpty()">
          <img src="../../../assets/img/empty-fodler-icon.svg" />
          <div>No templates or folders</div>
        </div>
      </ng-container>
    </div>
    <div [ngClass]="{'template-sidebar-wrapper':true, 'template-sidebar-active': selectedItem && showTemplateSidebar}">

      <div class="template-sidebar flex flex-col" *ngIf="selectedItem && showTemplateSidebar">
        <div class="template-sidebar-details-item flex flex-col justify-start items-center">
          <img [src]="getItemIcon(selectedItem)" style="height: 80px;"
            onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
          <p style="color:#000; text-align: center; word-break: break-word;">{{selectedItem.title}}</p>
        </div>
        <mat-tab-group>
          <mat-tab label="Details">
            <div class="template-sidebar-details-content flex flex-col gap-5">
              <div class="template-sidebar-details-item flex">
                <div class="template-sidebar-item-label">Type</div>
                <div class="template-sidebar-item-content">{{isFile(selectedItem) ? 'Template' : 'Folder'}}</div>
              </div>
              <div class="template-sidebar-details-item flex">
                <div class="template-sidebar-item-label">Owner</div>
                <div class="template-sidebar-item-content">{{selectedItem.owner_id === currentUser._id ? 'Me' :
                  selectedItem.owner_name}}</div>
              </div>
              <div class="template-sidebar-details-item flex">
                <div class="template-sidebar-item-label">Created on</div>
                <div class="template-sidebar-item-content">{{selectedItem.creation_date | date:
                  global.date_time_format}}</div>
              </div>
              <div class="template-sidebar-details-item flex">
                <div class="template-sidebar-item-label">Last modified on</div>
                <div class="template-sidebar-item-content">{{selectedItem.updation_date | date:
                  global.date_time_format}}</div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>