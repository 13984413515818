<div class="container calender-container">
    <div class="h100 flex flex-row gap-2 justify-between items-stretch" *ngIf="!is_add_view && is_detail_view === false">
        <app-transcriber-appointment-filter></app-transcriber-appointment-filter>
        <mat-card class="h100 flex">
            <mat-card-content class="h100 flex flex-col gap-2">
                <h2 style="color:#000;margin-top:0;margin-bottom:0">
                    Appointments
                </h2>
                <div class="h100-scroll-auto flex flex-row gap-7">
                    <full-calendar
                            #calendar
                            defaultView="dayGridMonth"
                            (dateClick)="handleDateClick($event)"
                            (eventClick)="handleEventClick($event)"
                            [header]="header"
                            [titleFormat]="titleFormat"
                            [weekends]="calendarWeekends"
                            [events]="calendarEvents"
                            [plugins]="calendarPlugins"></full-calendar>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div *ngIf="is_add_view && is_detail_view === false" class="flex flex-col gap-2"
         style="margin-top: 15px">
        <mat-card style="display: grid; place-content: center">
            <mat-card-header>
                <mat-card-title>
                    <div>
                        <h2 style="color: black">Add New Appointment </h2>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content style="margin-left: 50px">
                <div class="flex gap-7">
                    <div>
                        <form (ngSubmit)="addAppointment(form.value)" #form="ngForm">
                            <div class="flex flex-col">
                                <mat-form-field>
                                    <mat-label>Title</mat-label>
                                    <input matInput
                                           name="title"
                                           #title="ngModel"
                                           [(ngModel)]="model.title">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Select Provider</mat-label>
                                    <mat-select name="provider"
                                                required
                                                #provider="ngModel"
                                                [(ngModel)]="model.provider_id">
                                        <mat-option *ngFor="let p of providers"
                                                    value="{{p._id}}">{{p.first_name + ' ' + p.last_name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="provider.invalid  && (provider.dirty || provider.touched)">
                                        Please select provider
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Select Facility</mat-label>
                                    <mat-select name="facility"
                                                required
                                                #facility="ngModel"
                                                [(ngModel)]="model.facility_id">
                                        <mat-option *ngFor="let f of facilities"
                                                    value="{{f._id}}">{{f.title}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="facility.invalid  && (facility.dirty || facility.touched)">
                                        Please select facility
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Select patient</mat-label>
                                    <mat-select name="patient"
                                                required
                                                #patient="ngModel"
                                                [(ngModel)]="model.patient_id">
                                        <mat-option *ngFor="let p of patients"
                                                    value="{{p._id}}">{{p.first_name + ' ' + p.last_name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="patient.invalid  && (patient.dirty || patient.touched)">
                                        Please select patient
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Select payor</mat-label>
                                    <mat-select name="payor"
                                                required
                                                #payor="ngModel"
                                                [(ngModel)]="model.payor_id">
                                        <mat-option *ngFor="let pay of payors"
                                                    value="{{pay._id}}">{{pay.abbreviation}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="payor.invalid  && (payor.dirty || payor.touched)">
                                        Please select payor
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Notification Type</mat-label>
                                    <mat-select name="notify_type"
                                                required
                                                #notify_type="ngModel"
                                                multiple
                                                [(ngModel)]="model.notify_type">
                                        <mat-option value="email">Email</mat-option>
                                        <mat-option value="push">Push</mat-option>
                                        <mat-option value="phone">Phone</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="notify_type.invalid  && (notify_type.dirty || notify_type.touched)">
                                        Please select one option
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Notification Time</mat-label>
                                    <mat-select name="notify_time"
                                                required
                                                #notify_time="ngModel"
                                                [(ngModel)]="model.notify_time">
                                        <mat-option value="30">30 minutes earlier</mat-option>
                                        <mat-option value="30">15 minutes earlier</mat-option>
                                        <mat-option value="30">5 minutes earlier</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="notify_time.invalid  && (notify_time.dirty || notify_time.touched)">
                                        Please select one option
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Add Conference</mat-label>
                                    <mat-select name="video"
                                                required
                                                #video="ngModel"
                                                [(ngModel)]="model.is_video">
                                        <mat-option value="true">Yes</mat-option>
                                        <mat-option value="false">No</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="video.invalid  && (video.dirty || video.touched)">
                                        Please select one option
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput
                                           [matDatepicker]="picker"
                                           name="dos"
                                           #dos="ngModel"
                                           [(ngModel)]="model.dos"
                                           placeholder="Choose a date">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-error *ngIf="dos.invalid  && (dos.dirty || dos.touched)">
                                        Please select date of service
                                    </mat-error>
                                </mat-form-field>

                            </div>
                            <div class="flex justify-center items-center gap-2">
                                <button mat-raised-button
                                        class="dn-button-secondary"
                                        mat-dialog-close
                                        type="button">Cancel</button>
                                <button mat-raised-button
                                        [disabled]="!form.form.valid"
                                        type="submit"
                                        class="dn-button-primary">Save</button>
                            </div>
                        </form>
                    </div>
                    <div class="top-right-close-btn">
                        <button mat-raised-button
                                (click)="is_add_view=!is_add_view"
                                class="dn-button-primary">
                            <mat-icon>keyboard_backspace</mat-icon>
                            Back
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div *ngIf="is_detail_view" style="margin-top: 15px" class="flex flex-col gap-2">
        <app-transcriber-appointment-detail
                (exit)="handleExit()"
                [appointmentId]="appointmentId"></app-transcriber-appointment-detail>
    </div>
</div>
