import { Component, Inject, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

const videos = [
    {
        id: 'system-phrases',
        url: 'https://doctornow-userfiles-mobilehub-211093030.s3.amazonaws.com/OnBoardingVideos/System+Phrases.mp4',
        title: 'System Phrases',
        type: 'video/mp4'
    },
    {
        id: 'template-headings',
        url: 'https://doctornow-userfiles-mobilehub-211093030.s3.amazonaws.com/OnBoardingVideos/Template+Headings.mp4',
        title: 'Template Headings',
        type: 'video/mp4'
    },
    {
        id: 'template-guide',
        url: 'https://doctornow-userfiles-mobilehub-211093030.s3.amazonaws.com/OnBoardingVideos/Templates+Guide.mp4',
        title: 'Templates Guide',
        type: 'video/mp4'
    },
    {
        id: 'census-creation-video',
        url: 'https://doctornow-userfiles-mobilehub-211093030.s3.amazonaws.com/OnBoardingVideos/Census+Creation.mp4',
        title: 'Census Creation',
        type: 'video/mp4'
    },
    {
        id: 'census-view-sample-initial-eval-video',
        url: 'https://doctornow-userfiles-mobilehub-211093030.s3.amazonaws.com/OnBoardingVideos/Initial+Eval+Template.mp4',
        title: 'Sample Initial Evaluation',
        type: 'video/mp4'
    },
    {
        id: 'census-view-followup-video',
        url: 'https://doctornow-userfiles-mobilehub-211093030.s3.amazonaws.com/OnBoardingVideos/Follow+Up+Template.mp4',
        title: 'Sample Follow-Up Template',
        type: 'video/mp4'
    },
    {
        id: 'charge-capture-video',
        url: 'https://doctornow-userfiles-mobilehub-211093030.s3.amazonaws.com/OnBoardingVideos/Charge+Capture.mp4',
        title: 'Charge Capture',
        type: 'video/mp4'
    }
];

export interface WatchHelpVideoDialogData {
    videoId: string | number;
}

@Component({
    selector: 'watch-help-video-dialog',
    templateUrl: './watch-help-video-dialog.html',
    styleUrls: ['./drive.component.css']
})
export class WatchHelpVideoDialog implements OnInit {
    @ViewChild( 'videoRef' ) videoRef: ElementRef;
    video: { url, title, type } = null;

    constructor(
        public dialogRef: MatDialogRef<WatchHelpVideoDialog>,
        @Inject(MAT_DIALOG_DATA) public data: WatchHelpVideoDialogData
    ) {}

    ngOnInit(): void {
        this.video = videos.find( video => ( video.id === this.data.videoId )) ?? null;

        setTimeout(() => {
            if ( this.video == null ) {
                this.dialogRef.close( 'videoId is invalid' );
                return;
            }

            this.videoRef.nativeElement.play();

            setTimeout(()=>{
                this.videoRef.nativeElement.focus();
            }, 500);
        }, 50 );
    }
}
