import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileConversionService {

  constructor() { }
  
  makeFileRequest(userInfo:any,url: string, file: File, filename: string) {
    const extension = file.name.substr(file.name.lastIndexOf('.')+1); // fixed extension for file names with multiple . dots #mohsin-dev
    const type = extension;
    return new Promise((resolve, reject) => {
        const formData: any = new FormData();
        const xhr = new XMLHttpRequest();
        formData.append(type, file);
        /** In Angular 5, including the header Content-Type can invalidate your request */
        formData.append('id', userInfo.id );
        formData.append('auth_key', userInfo.auth_key );
        formData.append('user_type', userInfo.usertype );
        formData.append('type', type );
        formData.append('title', filename);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    reject(xhr.response);
                }
            }
        };
        xhr.open('POST', url, true);
        xhr.send(formData);
    });
  }
}
