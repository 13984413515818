export const UPLOAD_DOCUMENT_TYPE = {
    faceSheet: 'Face Sheet',
    consent: 'Consent',
    referralOrder: 'Referral Orders',
    diagnosticReport: 'Diagnostic Reports',
    sku: 'Skin Substitute SKUs',
    authorization: 'Authorizations',
    other: 'Other/Misc'
} as const;

export type UploadDocumentType = keyof typeof UPLOAD_DOCUMENT_TYPE;

export interface IDocumentUpload {
    _id: string;
    name: string;
    patient_id: string;
    aws_path: string;
    type: UploadDocumentType;
    is_deleted: boolean;
    createdAt: string;
    updatedAt: string;
}
