export class Payor {
    public _id?: string;
    public title: string;
    public description: string;
    public abbreviation: string;
    public email:string;
    public address: string;
    public address_2:string;
    public state:string;
    public city:string;
    public zip:string;
    public phone:string;
    public is_active: boolean;
    public company_id?: boolean;
    
    public contact: {
        first_name: string,
        last_name: string,
        extension: string,
        phone: string,
        position: string,
        email: string
      }
}
