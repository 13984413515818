import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import * as global from '../includes/global';
import { API_URL } from "../../environments/api-url";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import moment from 'moment'
import * as _ from 'lodash';
import { CommonService } from './common.service';
@Injectable({
  providedIn: 'root'
})
export class CensusRecurrentService {


  currentUser: any;
  constructor(private httpclient: HttpClient,
    private _authService: AuthService,
    private _commonService: CommonService,
  ) {
    this.currentUser = this._authService.currentUser;
  }
 

  getRecurrentCensuses() {
    const details = {
        ...this._authService.authObject
    }
    return this.httpclient
        .post(global.url + API_URL.CENSUS_RECURRENCE.getRecurrentCensuses, {details: details}).pipe();
    }


    addNewRecurrentCensuses(filter) {
        const details = {
            ...this._authService.authObject,
            ...filter
        }
        return this.httpclient
            .post(global.url + API_URL.CENSUS_RECURRENCE.addNewRecurrentCensuses, {details: details}).pipe();
    }

    addNewRecurrentCensusesWithParent(filter) {
        const details = {
            ...this._authService.authObject,
            ...filter
        }
        return this.httpclient
            .post(global.url + API_URL.CENSUS_RECURRENCE.addNewRecurrentCensusesWithParent, {details: details}).pipe();
    }
        
    updateRecurrentCensuses() {
    const details = {
        ...this._authService.authObject
    }
    return this.httpclient
        .post(global.url + API_URL.CENSUS_RECURRENCE.updateRecurrentCensuses, {details: details}).pipe();
    }


    updatePatientinCensuses() {
    const details = {
        ...this._authService.authObject
    }
    return this.httpclient
        .post(global.url + API_URL.CENSUS_RECURRENCE.updatePatientinCensuses, {details: details}).pipe();
    }


}