import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../../global';
import { API_URL } from "../../../../environments/api-url";
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class FaceSheetService {
  currentUser: any;
  constructor(private httpclient: HttpClient, private authService: AuthService) {
    this.currentUser = authService.currentUser
  }

  uploadFaceSheet(file, patient_id, company_id) {
    const payload = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      title: file.name,
      patient_id:patient_id,
      company_id:company_id,
    };
    const formData: any = new FormData();
    formData.append('file', file);

    for (let key of Object.keys(payload)) {
      formData.append(key, payload[key]);
    }
    return this.httpclient.post(global.url + API_URL.FACE_SHEET.uploadFaceSheet, formData).pipe();
  }

  deleteFaceSheet(faceSheetId) {
    const payload = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      faceSheetId
    };
    return this.httpclient.post(global.url + API_URL.FACE_SHEET.deleteFaceSheet, payload).pipe();
  }

  getFacesheet(patient_id) {
    const payload = {
      patient_id : patient_id
    }
    return this.httpclient.post(global.url + API_URL.FACE_SHEET.getFacesheet, payload).pipe();
  }
}
