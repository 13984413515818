import { Component, OnDestroy, OnInit, ViewChild,AfterViewInit} from '@angular/core';
import * as global from '../global'
import { PatientListService } from '../patient-list/patient-list-service';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { MatDialog } from '@angular/material/dialog';
import { PatientRoundingSheetDialog } from '../patient-rounding-sheets/create-patient-rounding-sheet-dialog/create-patient-rounding-sheet-dialog';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FacilityService } from 'src/app/services/facility.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MultiplePatientsAddDialog } from '../patient-rounding-sheets/multiple-patients-add-dialog/multiple-patients-add-dialog';
import moment from 'moment'
import { CensusPatientListService } from '../census-patient-list/census-patient-list.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { timingSafeEqual } from 'crypto';
import { CensusListService } from '../census-list/census-list..service';
import { RehabDocsPopupComponent } from 'src/app/shared/popup-dialogs/rehab-docs-popup/rehab-docs-popup/rehab-docs-popup.component';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { debug } from 'console';
import { PCCService } from 'src/app/services/pcc.service';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { CensusNotesServiceService } from '../census-notes/census-notes-service.service';
import { PatientRoundingSheetService } from '../patient-rounding-sheets/patient-rounding-sheet.service';
import { lastValueFrom } from 'rxjs';
import { SnfWcNurseAssociationService } from '../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-census-list-v1',
  templateUrl: './census-list-v1.component.html',
  styleUrls: ['./census-list-v1.component.css']
})
export class CensusListV1Component implements OnInit, OnDestroy {
  global = global;
  currentUser;
  currentUserDetails;
  admin: Boolean = false;
  company: Boolean = false;
 
  patients = [];
  patientRoundingSheets: any = [];
  total: number = 0;
  filter: {facility_id,
            provider_id,
            company_id};
  displayedColumns: string[] = [
    'status',
    'dateOfService',
    'name',
    'facility',
    'patients_count_total',
    'Saved_Signed',
    'Draft_Submitted',
    'operations',
  ];
  // Removed // 'date',    
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  facilities = [];
  providers = [];
  selectedFacility;
  filter_facility_id = "";
  filter_provider_id = "";
  censusStatus: string = 'true';

  private showPatientList: Boolean = false;

  loaderId = 'patient-rounding-sheets-loader';
  inprogress = false;

  showLimit = 7;

  providerIdParam: string;

  pccDefaultFac;
  public pccUserInfo;
  p2pSubscription: any;
  addressSubscription: any;
  FacilitiesSubscription: any;
  clickedRows = new Set<any>();
  company_id: any;
  censuses: any;
  searchText: any;
  pagination:any={};
  totalCensus: any;
  currentProfileUser: any = {};
  isAdminPortal: boolean = false;
  // error2LeggedAuth;

  constructor(
    private _patientListService: PatientListService,
    private _authService: AuthService,
    private _censusListService: CensusListService,
    private censusPatientListServie: CensusPatientListService,
    private _censusNotesServiceService: CensusNotesServiceService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private facilityService: FacilityService,
    private loader: NgxUiLoaderService,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    private _transcriptionCreateService: TranscriptionCreateService,
    private pccService: PCCService,
    private _patientRoundingSheetService: PatientRoundingSheetService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private commonService: CommonService,

  ) {
    this.currentUser = this._authService.currentUser;
    this.currentUserDetails = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }
    if (window.location.pathname.includes('company')) {
      this.isAdminPortal = true;
    }
    this.pagination={
      page:1,
      skip:0,
      noOfensus:0,
      hasNext:false,
      hasBack:false,
      limit:this.showLimit
    }
  }
  ngOnDestroy(): void {
    this._censusListService.changeFilterProviderId(this.filter_provider_id);
    this._censusListService.changeFilterFacilityId(this.filter_facility_id);
    if (this.p2pSubscription) {
      this.p2pSubscription.unsubscribe();
    }
    // if(this.addressSubscription){
    //   this.addressSubscription.unsubscribe();
    // }
    if(this.FacilitiesSubscription){
      this.FacilitiesSubscription.unsubscribe();
    }
  }
  async ngOnInit() {
    await this.getCurrentProfileUser();
    if (window.location.pathname.includes('admin')) {
      this.admin = true;
    }
    else if (window.location.pathname.includes('company')) {
      this.company = true;
    }
    else {
      this.admin = false;
    }
    this.p2pSubscription = this._censusListService.castFilterProviderId.subscribe(filterProviderId => {
      this.filter_provider_id = filterProviderId; 
      // 
    })
    if(this.p2pSubscription){
      this.initProviders();
    }

    if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.snapshot.params.id;
    }

    this.FacilitiesSubscription = this._censusListService.castFilterFacilityId.subscribe(filterFacilityId => {
      if (this.route.snapshot.params.facility_id) {
        this.filter_facility_id = this.route.snapshot.params.facility_id;
      } else {
        this.filter_facility_id = filterFacilityId;
      }
      // console.log(this.filter_facility_id);
      this.resetPagination();
      // 
    });
    if(this.FacilitiesSubscription){
      this.initFacilities(null, this.filter_provider_id);
    }
    this.pccService.defaultFac$.subscribe(fac => {
      this.pccDefaultFac = fac;
    });

    this.pccService.pccUserinfo$.subscribe(value => {
      this.pccUserInfo = value;
      // this.checkPCC2LeggedAuthentication();
    });

    this.route.params.subscribe(() => {
      this.initPatientRoundingSheets(this.filter_facility_id,this.filter_provider_id, this.showLimit)


      // if(this.route.snapshot.params.id){
      //      this.loader.startLoader(this.loaderId);

      //   this._censusListService.getCensusList(this.filter_facility_id, this.filter_provider_id, this.showLimit).subscribe(async (response: any) => {
      //     if (response.status === 200) {
      //       this.patientRoundingSheets = response.data.array;
      //       this.total = response.data.total;
      //       let row = response.data.array.filter(item=>{
      //         return item._id ==this.route.snapshot.params.id
      //       })
            
      //       this.show_selected(row[0])
      //       this.dataSource = new MatTableDataSource(this.patientRoundingSheets);
      //       this.loader.stopLoader(this.loaderId);
            
      //     }
      //   })
     
      // }
      // else{
      //   this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
      // }
    })
  }
  async getCurrentProfileUser() {
    const providerId = this.route.snapshot.params.provider_id;
    let sanitizedproviderId = this.commonService.sanitizeInput(providerId);
    const response: any = await lastValueFrom(this.dashboardService.getUserDetail(sanitizedproviderId));
    if (response.status === 200)
      this.currentProfileUser = response.data.array;
    if (this.currentProfileUser?.user_type == "snf_wc_nurse") this.displayedColumns = this.displayedColumns.filter(f => f != 'Draft_Submitted');
  }
  viewRehabDocs(census_id) {
    let sanitizedcensusId = this.commonService.sanitizeInput(census_id);
    this.dialog.open(RehabDocsPopupComponent, { data: sanitizedcensusId, width: '80%', height: '100vh' })

  }
  changeLimit(value){
    this.showLimit=Number(value);
    this.handleFilterFacilityChange();
  }
  resetPagination(){
    this.pagination={
      page:1,
      skip:0,
      noOfensus:0,
      hasNext:false,
      hasBack:false,
      limit:this.showLimit
    }
  }
  handlePagination(){
    this.pagination.noOfensus=(this.pagination.skip)+this.patientRoundingSheets?.length;
    if(this.pagination.noOfensus<this.totalCensus){
      this.pagination.hasNext=true;
    }else{
      this.pagination.hasNext=false;
    }
    if(this.pagination.noOfensus>this.showLimit){
      this.pagination.hasBack=true;
    }else{
      this.pagination.hasBack=false;
    }
  }

  handleBackClick(){
    this.pagination.page--;
    this.pagination.skip=(this.pagination.page-1)*this.pagination.limit;
    this.pagination.limit=this.showLimit;
    this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
  }
  handleNextClick(){
    this.pagination.page++;
    this.pagination.skip=(this.pagination.page-1)*this.pagination.limit;
    this.pagination.limit=this.showLimit;
    this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
  }
  getNotesCount(census) {
    // console.log("sencusss v11", census);

    let [signedCount, savedCount] = [0, 0];
    if (Array.isArray(census?.notes) && census.notes.length > 0) {
      for (const note of census.notes) {
        if (note?.note_status?.status === "in_progress" || note?.note_status?.status === "internal_qa" || note?.note_status?.status === "external_qa" || note?.note_status?.status === "draft_save" || note?.note_status?.status === "published") {
          savedCount++;
        }
        else if (note?.note_status?.status === "signed" || note?.note_status?.status === "uploaded" || note?.note_status?.status === "not_uploaded") {
          signedCount++;
        }
      }
      return `${savedCount} / ${signedCount}`;
    }
    else {
      return `${savedCount} / ${signedCount}`;
    }
  }
  getChargesCount(census) {
    let [draftCount, submittedCount] = [0, 0];
    if (Array.isArray(census.charges) && census.charges.length > 0) {
      for (const charge of census.charges) {
        if (charge.status === "draft") {
          draftCount++;
        }
        else if (charge.status === "submit") {
          submittedCount++;
        }
      }
      return `${draftCount} / ${submittedCount}`;
    }
    else {
      return `${draftCount} / ${submittedCount}`;
    }
  }

  initPatients() {
    const details = {
      ...this.currentUserDetails
    }
    this._patientListService.getPatients(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.patients = response.data.array
          .filter(patient => patient.is_active === 'true')
      }
    })
  }

  initCensusChargeReport(facility_id?, provider_id?, limit?) {

    let filter={
    facility_id:facility_id,
     provider_id:provider_id,
    company_id : this.company_id
    }
    this._censusNotesServiceService.getCensusChargesReport(filter).subscribe((response: any) => {
      if (response.status === 200) {
        this.censuses = response.data;
        console.log("censuses s",this.censuses);
      }
    });
  }

  initPatientRoundingSheets(facility_id?, provider_id?, limit?) {
    if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT && this.facilities?.length < 1) return;
    if (!facility_id) return;
    let sanitizedfacilityId = this.commonService.sanitizeInput(facility_id);
    let sanitizedproviderId = this.commonService.sanitizeInput(provider_id);
    this.loader.startLoader(this.loaderId);
    this._censusListService.getCensusListWithNotesAndChargesCount(sanitizedfacilityId, sanitizedproviderId, limit, false, this.censusStatus, this.pagination.skip).subscribe(async (response: any) => {
      if (response.status === 200) {
        this.patientRoundingSheets = response.data;
        console.log("this.dataSource v1", this.patientRoundingSheets);
        this.total = response.total;
        this.dataSource = new MatTableDataSource(this.patientRoundingSheets);
        this.totalCensus = response.total;
        this.handlePagination();
        this.sortCensusData({ active: 'dateOfService', direction: 'desc' });
        this.loader.stopLoader(this.loaderId);
      }
    })
  }
  initializeActiveCensus(facility_id) {
    this.loader.startLoader(this.loaderId);

    this.censusPatientListServie.initializeActiveCensus(facility_id).subscribe((response: any) => {
      // console.log(response)
      if (response.status === 200) {
        this.loader.stopLoader(this.loaderId);
        this.toastr.success('Census has been initialized successfully')
      }
    })
  }
  // initFacilities() {
  //   this.facilityService.castFacilities.subscribe((facilities: any) => {
  //     if (Array.isArray(facilities)) {
  //       this.facilities = facilities;

  //     }
  //   });
  // }
  // getFacilitiesbyId() {

  async initFacilities(selectedProvider = null, provider_id?) {
    if (!this.filter_facility_id && this.currentProfileUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentProfileUser.user_type === global.USER_TYPE.TRANSCRIBER
      || this.currentProfileUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      if (this.providers?.length > 0) {
        selectedProvider = this.providers[0]._id;
      }
      else return;
    }
    if (provider_id) selectedProvider = provider_id;
    let sanitizedselectedProvider = this.commonService.sanitizeInput(selectedProvider);
    this.facilities = []
    const filter = {
      associated_snf_wc_nurse_ids: sanitizedselectedProvider,
    };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      pcc_2_legged_authentication: 1,
      source: 1,
      pcc_orgUuid: 1
    };
    if (this.currentProfileUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (response.status === 200 && response.data.length > 0) {
        this.facilities = response.data;
        this.facilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        this.selectedFacility = this.facilities.find(facility => facility._id === this.filter_facility_id);
        this.populateFacilities();
      }
    }
    else {
      this.addressSubscription = this.facilityService.getMyFacilities(null, sanitizedselectedProvider).subscribe((response: any) => {
        if (response.status == 200) {
          this.facilities = response.data.array;
          this.facilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
          this.selectedFacility = this.facilities.find(facility => facility._id === this.filter_facility_id);
          this.populateFacilities();
          if (this.currentProfileUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT)
            this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
        }
      });
    }
  }

  populateFacilities() {
    if (!this.selectedFacility) {
      this.pccService.pccUserinfo$.subscribe(u => {
        if (u) {
          const selectedFacility = this.facilities.find(f => this.isDefaultLoggedInFacility(f));
          if (selectedFacility && this.selectedFacility?._id !== selectedFacility._id) {
            this.selectedFacility = selectedFacility;
            this.filter_facility_id = this.selectedFacility._id;
            this.handleFilterFacilityChange();
          }
        } else {
          this.selectedFacility = this.facilities[0];
          this.filter_facility_id = this.facilities[0]._id;
          // this.selectedFacility = 'All';
          // this.filter_facility_id = '';
          this.handleFilterFacilityChange();
        }
      });
    }
  }
  isDefaultLoggedInFacility(f) {
    return this.pccService.isDefaultLoggedInFacility(f);
  }
  isLoggedInFacility(f) {
    return this.pccService.isLoggedInFacility(f);
  }

  async initProviders() {
    this.providerIdParam = this.route.snapshot.params.provider_id;
    if (this.providerIdParam) {
      this.filter_provider_id = this.providerIdParam;
    }
    if (this.currentProfileUser.user_type == global.USER_TYPE.DOCTOR) {
      let response: any = await this.dashboardService.getP2PRelation(null, this.currentProfileUser._id).toPromise()
      if (response.status == 200) {
        this.populateProviders(response.data.assoc_provider_id);
      }
    }
    else if (this.currentProfileUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
      this.initAssociatedProvidersListForMA();
    }
 
    else if (this.currentProfileUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
      this.initAssociatedNurseList();
    }
    else {
      this.initAssociatedProvidersList();
    }
  }

  initAssociatedNurseList() {
    const filter = {
      snf_wc_nurse_id: this.currentProfileUser._id,
      company_id: this.currentProfileUser.company_id
    };
    const project = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    this._snfWcNurseAssociationService.getAssociatedSnfWcNurses(filter, project)
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.populateProviders(response.data.associated_snf_wc_nurse_ids || []);
          this.initFacilities(null, this.filter_provider_id);
        }
      });
  }
  initAssociatedProvidersList() {
    this.dashboardService
      .getAssociatedDoctorsList(this.currentUser.company_id)
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          if (this.filter_provider_id) {
            this.initFacilities(null, this.filter_provider_id);
          }
          else {
            this.initFacilities();
          }
          this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
          if (!this.filter_provider_id) {
            this.filter_provider_id = this.providers[0]._id;
          }
        }
      });
  }

  initAssociatedProvidersListForMA() {
    this._transcriptionCreateService
      .getAssociatedDoctorsListForMA()
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          if (this.providers?.length > 0) {
            // console.log("sdjlkf", this.providers);
            this.initFacilities();
          }
          // this.providers.unshift(this.currentUser);
          // console.log("providers", this.providers);
          if (this.providers?.length > 0) {
            this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
            if (!this.filter_provider_id) {
              this.filter_provider_id = this.providers[0]._id;
            }
          }
        }
      });
  }
  populateProviders(assoc_provider_id) {
    if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      this.providers = assoc_provider_id;
    }else {
      this.providers = [this.currentUser].concat(assoc_provider_id);
    }

    this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
    if (this.filter_provider_id) {
      let filtered_provider = this.providers.filter(item => item._id == this.filter_provider_id)
      if (filtered_provider.length > 0) {
        this.filter_provider_id = filtered_provider[0]._id;
      }

    }
    if (!this.filter_provider_id) {
      this.filter_provider_id = this.providers[0]._id;
    }
  }

  getPatientsCounts(census, type) {
    const { patientList } = census;
    switch (type) {
      case 'total':
        return patientList.length
      case 'new':
        {
          let count = 0;
          for (const patient of patientList) {
            if (!this.censusPatientListServie.isPatientFollowUp(census, patient)) {
              count++;
            }
          }
          return count;
        }

      case 'followUp':
        {
          let count = 0;
          for (const patient of patientList) {
            if (this.censusPatientListServie.isPatientFollowUp(census, patient)) {
              count++;
            }
          }
          return count;
        }
      case 'discharge':
        {
          let count = 0;
          for (const patient of patientList) {
            if (patient.pcc_payload?.admissionDate) {
              count++;
            }
          }
          return count;
        }
    }
  }
  onPatientSelect($event, patientRoundingSheet) {
    console.log($event, patientRoundingSheet);
    this.addPatientToPatientRoundingSheet(patientRoundingSheet._id, $event._id);

  }
  onSelectAllPatients($event, patientRoundingSheet) {
    console.log($event, patientRoundingSheet);

  }
  onPatientDeSelect($event, patientRoundingSheet) {
    console.log($event, patientRoundingSheet);
    this.removePatientFromPatientRoundingSheet(patientRoundingSheet._id, $event._id);
  }
  showPatientRoundingSheetDialog() {
    const obj = {
      filter_facility_id: this.filter_facility_id,
      filter_provider_id: this.filter_provider_id
    }
    console.log("obj",obj);
    const dialogRef = this.dialog.open(PatientRoundingSheetDialog, {
      data: obj,
      width: '50%'
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      // console.log("this ran");
      // this.initPatientRoundingSheets();
      this.handleFilterFacilityChange();
    });
  }
  showMultiplePatientsAddDialog(patientRoundingSheet) {
    const dialogRef = this.dialog.open(MultiplePatientsAddDialog, { width: '50%', data: { patientRoundingSheet } });
    dialogRef.afterClosed().subscribe(async (patients) => {

      if (Array.isArray(patients)) {
        patientRoundingSheet.patientList = patients;
        // this.initPatientRoundingSheets()
      }
    });
  }

  getDOS(patientRoundingSheet) {
    if (patientRoundingSheet.date_obj) {
      let { year, month, day } = patientRoundingSheet.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return `${month}/${day}/${year}`;
    }
    if (!('pcc_timeZone' in patientRoundingSheet.facility || 'timeZone' in patientRoundingSheet.facility)) {
      patientRoundingSheet.facility.timeZone = 'America/New_York';
    }
    let timezone = patientRoundingSheet.facility.pcc_timeZone ? patientRoundingSheet.facility.pcc_timeZone : patientRoundingSheet.facility.timeZone;
    let serviceDate = moment(patientRoundingSheet.date).utc().tz(timezone).format('MM/DD/YYYY')
    return serviceDate;
  }
  
  addPatientToPatientRoundingSheet(patientRoundingSheetId, patientId) {
    const details = {
      ...this.currentUserDetails,
      patientRoundingSheetId: patientRoundingSheetId,
      patientId: patientId,
    }
    const infoToast = this.toastr.info('Adding', 'Please wait', { disableTimeOut: true });

    this._censusListService.addPatientToPatientRoundingSheet(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success('Patient added Successfully', 'Success');
      }
    },
      () => this.toastr.error('Something went wrong adding patient', 'Failed'),
      () => this.toastr.clear(infoToast.toastId))
  }

  removePatientFromPatientRoundingSheet(patientRoundingSheetId, patientId) {
    const details = {
      ...this.currentUserDetails,
      patientRoundingSheetId: patientRoundingSheetId,
      patientId: patientId,
    }
    const infoToast = this.toastr.info('Removing', 'Please wait', { disableTimeOut: true });

    this._censusListService.removePatientFromPatientRoundingSheet(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success('Patient removed Successfully', 'Success');
      }
    },
      () => this.toastr.error('Something went wrong removing patient', 'Failed'),
      () => this.toastr.clear(infoToast.toastId))
  }


  deletePatientRoundingSheet(patientRoundingSheet) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, { data: { message: `Are you sure you want to delete ${patientRoundingSheet.name}?` } });
    let confirmed = "false";
    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        if (patientRoundingSheet?.savedNotesCount || patientRoundingSheet?.signedNotesCount || patientRoundingSheet?.draftChargesCount || patientRoundingSheet?.submittedChargesCount) {
          this.toastr.warning('There is Note/Charge against this census', 'Cannot Delete');
        } else {
          const details = {
            ...this.currentUserDetails,
            sheetid: patientRoundingSheet._id
          }
          const infoToast = this.toastr.info('Deleting Patient Census', 'Please wait', { disableTimeOut: true });

          this._censusListService.deletePatientRoundingSheet(details).subscribe((response: any) => {
            if (response.status === 200) {
              this.toastr.clear(infoToast.toastId);
              this.toastr.success('Patient Census removed Successfully', 'Success');
              // this.initPatientRoundingSheets();
              this.handleFilterFacilityChange();
            }
          })
        }
      }
    }),
      () => this.toastr.error('Something went wrong removing Patient Census', 'Failed')
  }

  sortData(sort: Sort) {
    const data = this.patientRoundingSheets.slice();
    // console.log('sortData', sort);

    this.patientRoundingSheets = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          const momentDateA = moment(a.name, 'MMM D, YYYY', true);
          const momentDateB = moment(b.name, 'MMM D, YYYY', true);
          return compare(momentDateA.isValid() ? momentDateA.get('milliseconds') : a.name, momentDateB.isValid() ? momentDateB.get('milliseconds') : b.name, isAsc);

        case 'facility': return compare(a.facility?.title, b.facility?.title, isAsc);

        case 'date': return compare(new Date(a.date).getTime(), new Date(b.date).getTime(), isAsc);

        case 'patient_count': return compare(a.patientList.length, b.patientList.length, isAsc);

        case 'patient_count_count': return compare(Number(a.rehab_docs), Number(b.rehab_docs), isAsc);

        case 'patients_count_new': {

          const countA = this.getPatientsCounts(a, 'new');
          const countB = this.getPatientsCounts(b, 'new');
          return compare(countA, countB, isAsc);
        }

        case 'patients_count_followUp': {

          const countA = this.getPatientsCounts(a, 'followUp');
          const countB = this.getPatientsCounts(b, 'followUp');
          return compare(countA, countB, isAsc);
        }

        case 'patients_count_discharge': {

          const countA = this.getPatientsCounts(a, 'discharge');
          const countB = this.getPatientsCounts(b, 'discharge');
          return compare(countA, countB, isAsc);
        }

        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource(this.patientRoundingSheets);
  }

  sortCensusData(sort: Sort) {
    const data = this.patientRoundingSheets.slice();
    data.forEach(element => {
      let day = element.date_obj.day.toString();
      if (day.length == 1) {
        day = '0' + day;
      }
      let month = element.date_obj.month.toString();

      if (month.length == 1) {
        month = '0' + month;
      }
      let year = element.date_obj.year.toString();
      let newDate = `${year}-${month}-${day}`;
      element.newDate = newDate;
    });
    this.patientRoundingSheets = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dateOfService':
          return compare(new Date(a.newDate).getTime(), new Date(b.newDate).getTime(), isAsc);
        // return compare(momentDateA.isValid() ? momentDateA.get('milliseconds') : a.date, momentDateB.isValid() ? momentDateB.get('milliseconds') : b.date, isAsc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource(this.patientRoundingSheets);
  }
  
  getCensusListByStatus(status) {
    this.censusStatus = status;
    this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
  }
  restoreInactiveCensus(census, index) {
    console.log("census", census, index);
    const details = {
      ...this.currentUserDetails,
      _id: census._id,
      name: census.name,
      date: census.date,
      date_obj: census.date_obj,
      is_active: true
    }
    this._patientRoundingSheetService.updatePatientRoundingSheet(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.dataSource.data = [];
        this.patientRoundingSheets.splice(index, 1);
        this.dataSource.data = this.patientRoundingSheets;
      }
    });
  }

  handleFilterFacilityChange() {
    this.resetPagination();
    this.initPatientRoundingSheets(this.filter_facility_id && this.filter_facility_id, this.filter_provider_id, this.showLimit);
    // console.log('census facilty', this.filter_facility_id);
    this.selectedFacility = this.facilities.find(f => f._id === this.filter_facility_id)
    // this.checkPCC2LeggedAuthentication();
  }
  // checkPCC2LeggedAuthentication() {
  //   if(this.selectedFacility && this.selectedFacility.pcc_facId && !this.selectedFacility.pcc_2_legged_authentication) {
  //     if(
  //         // User is Not logged in so cannot access via 2-legged 
  //         (!this.isLoggedIntoPCC()) || 
  //         // User is logged but facility is not logged in so cannot access via 2-legged 
  //         (this.isLoggedIntoPCC() && !this.pccService.isLoggedInFacility(this.selectedFacility))
  //       ) {
  //       this.error2LeggedAuth = 'This facility requires login into PCC to view data.';
  //     }
  //   } else {
  //     this.error2LeggedAuth = '';
  //   }
  // }
  is2LeggedAuthRequired(facilityId) {
    const facility = this.facilities.find(f => f._id === facilityId);
    if (facility && facility.pcc_facId && !facility.pcc_2_legged_authentication) {
      if (!this.isLoggedIntoPCC()) return true;
      if (!this.pccService.isLoggedInFacility(facility)) return true;
    }
    return false;
  }
  handleproviderChange(provider) {
    // console.log("handleproviderChange: ", provider);
    this.initFacilities(null, this.filter_provider_id);
    this.handleFilterFacilityChange();
    // console.log("Provider Changed: ", this.filter_provider_id);

  }
  // providerClicked(pro){
  //   console.log("providerClicked: ", pro);

  // }
  isOwner(census) {
    return this.currentUser._id == census.createdBy
  }
  showCreateButton() {
    if (this.isAdminPortal) return false;
    if (this.currentUser.user_type === global.USER_TYPE.DOCTOR) {
      return this.currentUser.user_type === global.USER_TYPE.DOCTOR
    }
    else if (this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) {
      return this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER
    }
    else if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
      return this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT
    }
  }

  //navigation
  async viewClicked(census_id) {
    let pro;
    let sanitizedproviderIdParam: any = this.commonService.sanitizeInput(this.providerIdParam);
    if (sanitizedproviderIdParam) {
      const userDetailResponse: any = await this.dashboardService.getUserDetail(sanitizedproviderIdParam).toPromise();
      if (userDetailResponse.status == 200) {
        pro = userDetailResponse.data;
      }
    } else {
      pro = this.providers.filter(p => p._id == this.filter_provider_id);
    } 
    let telemedicine = pro[0]?.other_settings?.show_telemedicine || 'false';
    // let telemedicine = "false" 
    if (this.admin) {
      // this.router.navigate([`/census/${census_id}`], { relativeTo: this.route });
      this.router.navigateByUrl(`/admin/census/${census_id};telemedicine=${telemedicine};admin=true`);
      this.showPatientList = true;
    }
    if (this.company) {
      // this.router.navigate([`/census/${census_id}`], { relativeTo: this.route });
      this.router.navigateByUrl(`/company/${this.currentUser.company_id}/census/${census_id};telemedicine=${telemedicine};admin=true`);
      this.showPatientList = true;
    }
    else {
      this.showPatientList = false;
      // let isAssociatedMT = this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER;
      // this.router.navigateByUrl(`/census/${census_id}${isAssociatedMT ? `;showPCCSearchPatient=${isAssociatedMT}` : ''}`);
      this.router.navigateByUrl(`/census/${census_id};telemedicine=${telemedicine}`);
    }
  }
  // ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
  // }

  isLoggedIntoPCC() {
    return !!this.pccUserInfo;
  }
  show_selected(row){
    this.clickedRows.clear()
    this.clickedRows.add(row)
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
