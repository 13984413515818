import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyLogoAndMongogramService {
  private companyMonogram = new BehaviorSubject(null);
  public castCompanyMonogram = this.companyMonogram.asObservable();

  private companyLogo = new BehaviorSubject(null);
  public castCompanyLogo = this.companyLogo.asObservable();

  constructor() { 
    const companyLogoPath = localStorage.getItem('companyLogoPath')
    if(companyLogoPath){
      console.log('companyLogoPath',companyLogoPath);
      this.setCompanyLogo(companyLogoPath);
    }
    console.log('companyLogoPath',companyLogoPath);
  }

  setCompanyMonoGram(companyMonogramPath) {
    console.log('companyMonogramPath',companyMonogramPath);
    this.companyMonogram.next(companyMonogramPath);
  }

  setCompanyLogo(companyLogoPath) {
    console.log('companyLogoPath uploaded new',companyLogoPath);
    this.companyLogo.next(companyLogoPath);
    localStorage.setItem('companyLogoPath',companyLogoPath)
  }
}
