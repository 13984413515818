import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { isArray } from 'util';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";
import { ReportGenerateService } from '../report-generate.service';
// import { EventEmitter } from 'events'; 

@Component({
  selector: 'app-page-edit-form',
  templateUrl: './page-edit-form.component.html',
  styleUrls: ['./page-edit-form.component.css']
})
export class PageEditFormComponent implements OnInit, DoCheck {

  noteTypeControl = new FormControl();
  filteredNoteType: Observable<string[]>;
  providerControl = new FormControl();
  filteredProvider: Observable<string[]>;
  examControl = new FormControl();
  filteredExam: Observable<string[]>;
  dateControl = new FormControl();
  filteredDate: Observable<string[]>;
  carry: Boolean = false;
  carriedDate: any;
  carriedExam: any;
  carriedNoteType: any;
  carriedImpression: any;
  isCarriedImpression: Boolean = false;

  pageId: any;
  addNewDate: boolean = false;
  addNewProvider: boolean = false;
  addNewNoteType: Boolean = false;
  addNewExam: Boolean = false;
  pageOne: Boolean = true;
  pages: any = ['one'];
  dropdownSettings: any = {};
  closeDropdownSelection = false;
  disabled = false;
  row: any = 1;
  pagesRow:any = ['-'];
  Editor = ClassicEditor;
  config = {
    placeholder: 'Type the content here!',
    toolbar: [],
  };
  summaryData: any;
  // private reportGenerateService: ReportGenerateService;
  documentId: any;
  isDisabled = false;

  // @Input() pageItem;
  // @Input() providerList;
  @Input() pageItem: any = {};
  @Input() savedReport: any;
  // @Input() savedReport2: any;
  // @Input() updated: any;
  @Output() onSummaryChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaveClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onaddNewPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageReport: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("providerInput") providerInput: ElementRef;
  @ViewChild("NoteTypeInput") NoteTypeInput: ElementRef;
  @ViewChild("examInput") examInput: ElementRef;
  @ViewChild('editor') editorComponent: CKEditorComponent;
  // @ViewChild("dateInput") dateInput: ElementRef; 

  constructor(
    public route: ActivatedRoute,
    private reportGenerateService: ReportGenerateService,
    // private reportGenerateService: ReportGenerateService,
  ) {  }
  ngDoCheck(): void { 
    if(this.pagesRow.indexOf(this.pageItem.page_no) >= 0){
      // this.addNewDate = false;
    }
    else{
      this.addNewDate = false;
      this.pagesRow.push(this.pageItem.page_no)
      this.pagesRow.splice((this.pagesRow?.length - 2) , 1)
    }
  }

  ngOnInit() { 
    this.summaryData = this.savedReport.summary;
    this.documentId = this.pageItem.document_id;
    this.filteredProvider = this.providerControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterProvider(value))
    );
    this.filteredNoteType = this.noteTypeControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterNoteType(value))
    );
    this.filteredExam = this.examControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterExam(value))
    );
    this.filteredDate = this.dateControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterDate(value))
    );


    this.dropdownSettings = {
      singleSelection: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
  }

  summaryDataChanged(event) {
    // this.savedReport.summary = event;
    // this.saveReport(event);
    // this.onSummaryChange.emit(event);
    // this.saveReport(event, this.pageItem.document_id) 

    // if (this.pageItem.document_id) {
    //   let saveReport = this.reportGenerateService.saveOrUpadateSummary(event, this.pageItem.document_id).subscribe((response: any) => {
    //     if (response.status = 200) {
    //       // this.summaryData = response.data.summary; 
    //     }
    //   });
    // }
  }
  saveReport() {
    this.reportGenerateService.saveOrUpadateSummary(this.savedReport.summary, this.pageItem.document_id).subscribe((response: any) => {
      if (response.status = 200) {
      }
    })
  } 

  private filterProvider(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.pageItem.provider.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.pageItem.provider;
    }
  }
  private filterNoteType(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.pageItem.note_type.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.pageItem.note_type;
    }
  }
  private filterExam(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.pageItem.exam.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.pageItem.exam;
    }
  }
  private filterDate(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.pageItem.date.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.pageItem.date;
    }
  }
  addNewPage(currentPage) {
    // this.pages.push("new");  
    this.onaddNewPage.emit(currentPage);

  }
  changeAddNew(flag) {
    this.addNewProvider = flag;
    if (flag && this.providerInput) {
      setTimeout(() => {
        this.providerInput.nativeElement.focus();
      }, 1000)
    }
  }

  addDate($event) {
    $event.preventDefault();
    this.addNewDate = false;
    this.pageItem.selected.date = '';
  }

  // emitProviderAndDate(element) {
  //   if (element) { 
  //       if (['C-3 form'.toUpperCase().trim(), 'C-4 form'.toUpperCase().trim(),
  //       'C-4.2 formn'.toUpperCase().trim(), 'C-4.3 form'.toUpperCase().trim(),
  //       'C-4 AUTH form'.toUpperCase().trim(), 'MG-2 form'.toUpperCase().trim(), 'C-4 AMR'.toUpperCase().trim(),
  //       'MG-2.1 form'.toUpperCase().trim(), 'Form'.toUpperCase().trim(), 'EC-4NARR form'.toUpperCase().trim(),
  //       'First report of injury'.toUpperCase().trim()].indexOf(element.selected.note_type.toUpperCase()) >= 0) {
  //         this.pageItem.selected.date = "";
  //         this.pageItem.selected.provider = "";
  //       }
  //       else {
  //         this.pageItem.selected.provider = element.provider[0];
  //         if (element.date) {
  //           this.pageItem.selected.date = new Date(element.date[0]);
  //         }
  //         else {
  //           this.pageItem.selected.date = "";
  //         }
  //       } 
  //   }
  // }

  dateSelect($event) {
    $event.preventDefault();
    this.addNewExam = false;
    this.pageItem.selected.exam = '';
  }

  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }

  onSubmit(page) {
  }
  // dateChange(e) {
  //   if (e.value) {
  //     this.pageItem.selected.date = e.value.toString();
  //   }
  // }
  // senetence case
  convertToSentenceCase(impression) {
    var newString = impression.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
    return newString;
  }
  // impressionChanged(imp){
  //   console.log("in ipmression change: ", imp); 
  //     this.pageItem.impression = imp; 
  // }

  isTherapy() {
    if (this.pageItem.selected.note_type) {
      if (this.pageItem.selected.note_type.trim().toUpperCase().includes("Chiropractic therapy".trim().toUpperCase()) ||
        this.pageItem.selected.note_type.trim().toUpperCase().includes("Occupational therapy".trim().toUpperCase()) ||
        this.pageItem.selected.note_type.trim().toUpperCase().includes("Acupuncture therapy".trim().toUpperCase()) ||
        this.pageItem.selected.note_type.trim().toUpperCase().includes("Physical therapy".trim().toUpperCase()) ||
        this.pageItem.selected.note_type.trim().toUpperCase().includes("Physical therapy note".trim().toUpperCase()) ||
        this.pageItem.selected.note_type.trim().toUpperCase().includes("The First report of injury".trim().toUpperCase()) ||
        this.pageItem.selected.note_type.trim().toUpperCase().includes("The subsequent report of injury".trim().toUpperCase()) ||
        this.pageItem.selected.note_type.trim().toUpperCase().includes("C-4 AMR".trim().toUpperCase()) ||
        this.pageItem.selected.note_type.trim().toUpperCase().includes("form".trim().toUpperCase())) {
        this.pageItem.selected.provider = "";
        return true;
      }
      else {
        return false; // in this case we are not showing provider field
      }
    }
  }
  isNote() {
    if (this.pageItem.selected.note_type) {
      if (this.pageItem.selected.note_type.trim().toUpperCase() == "C-4 AMR".toUpperCase() ||
        this.pageItem.selected.note_type.trim().toUpperCase().includes("form".toUpperCase()) ||
        this.pageItem.selected.note_type.trim().toUpperCase().includes("The First report of injury".trim().toUpperCase()) ||
        this.pageItem.selected.note_type.trim().toUpperCase().includes("The subsequent report of injury".trim().toUpperCase())) {
        this.pageItem.selected.date = "";
        return true;
      }
      else {
        return false;
      }
    }
  }
  // noteType(note){
  //   if ((note.selected.note_type.trim().toLowerCase()).includes('form') ||
  //       (note.selected.note_type.trim().toLowerCase()).includes('First report of injury'.trim().toLowerCase()) ||
  //       (note.selected.note_type.trim().toLowerCase()).includes('The subsequent report of injury'.trim().toLowerCase()))
  //       {
  //         note.selected.provider = "";
  //         note.selected.date = '';
  //       }
  //       else{
  //         note.selected.provider = "";
  //         note.selected.date = '';
  //       }
  // }
  propercase(phrase) {
    if (phrase) {
      return phrase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    else {
      return phrase;
    }
  }
  isNoteTypeImaging(noteType) {
    if (noteType) {
      let note = noteType.trim()
      if (note.toUpperCase().trim() == "X-rays".trim().toUpperCase() ||
        note.toUpperCase().trim() == "X rays".trim().toUpperCase() ||
        note.toUpperCase().trim() == "MRI".trim().toUpperCase() ||
        note.toUpperCase().trim() == "CT-scan".trim().toUpperCase() ||
        note.toUpperCase().trim() == "CT scan".trim().toUpperCase() ||
        note.toUpperCase().trim() == "Imaging note".trim().toUpperCase() ||
        note.toUpperCase().trim() == "Procedure note".trim().toUpperCase() ||
        note.toUpperCase().trim() == "NCS/EMG note".trim().toUpperCase()) {
        return true;
      }
      else {
        // this.pageItem.findinds = " ";
        this.pageItem.selected.exam = "";
        return false;
      }
    }
    // }
    // else {
    //   this.pageItem.selected.exam = " ";
    //   return false;
    // } 
  }

  isNoteTypeforPlan(noteType) {
    // if (noteType && noteType.selected && noteType.selected.note_type && noteType.selected.note_type != "Unidentified" && typeof noteType.selected.note_type == "string") {
    // let note = noteType.selected.note_type.trim()
    if (noteType) {
      let note = noteType.trim()
      if (note.toUpperCase().trim() == "Progress note".trim().toUpperCase() ||
        note.toUpperCase().trim() == "Initial evaluation note".trim().toUpperCase() ||
        note.toUpperCase().trim() == "Initial evaluation".trim().toUpperCase()) {
        return false;
      }
      else {
        return false;
      }
    }
    // }
    // else {
    //   return false;
    // }
  }
  isNoteTypeforImpression(noteType) {
    // if (noteType && noteType.selected && noteType.selected.note_type && noteType.selected.note_type != "Unidentified" && typeof noteType.selected.note_type == "string") {
    // let note = noteType.selected.note_type.trim()
    if (noteType) {
      let note = noteType.trim()
      if (note.toUpperCase().trim() == "X-rays".trim().toUpperCase() ||
        note.toUpperCase().trim() == "X rays".trim().toUpperCase() ||
        note.toUpperCase().trim() == "MRI".trim().toUpperCase() ||
        note.toUpperCase().trim() == "CT scan".trim().toUpperCase() ||
        note.toUpperCase().trim() == "CT-scan".trim().toUpperCase() ||
        // note.toUpperCase().trim() == "Progress note".toUpperCase() ||
        // note.toUpperCase().trim() == "Initial evaluation note".toUpperCase() ||
        // note.toUpperCase().trim() == "Initial evaluation".toUpperCase() ||
        note.toUpperCase().trim() == "Imaging note".trim().toUpperCase() ||
        note.toUpperCase().trim() == "Procedure note".trim().toUpperCase() ||
        note.toUpperCase().trim() == "NCS/EMG note".trim().toUpperCase()) {
        return true;
      }
      else {
        this.pageItem.impression = "";
        return false;
      }
    }
    // }
    // else {
    //   this.pageItem.impression = "";
    //   return false;
    // }
  }
  selectionChange(event) {
  }
  toggledropDown() {
    setTimeout(() => {
      this.providerInput.nativeElement.open();
    }, 500)
  }
  dateValue(item) {
    if (item.selected && item.selected.date) {
      item.selected.date = item.selected.date;
      this.pageItem.selected.date = item.selected.date;
      return item.selected.date;
    }
    else
      return "";
  }


  onSelectProvider(provider, pageItem) {
    // this.changeAddNew(true) 
    this.addNewProvider = false;
    this.pageItem.selected.provider = provider;
    pageItem.selected.provider = provider;
    if (provider == "Add New") {
      this.pageItem.selected.provider = "";
      setTimeout(() => {
        this.providerInput.nativeElement.focus();
      }, 200)
    }
  }
  onSelectNote(item: any, pageItem) { 
    if (isArray(item)) {
      item = item[0]
    }
    else {
      item = item;
    }
    this.addNewNoteType = false;
    // this.addNewExam = true; 
    pageItem.selected.note_type = item;
    if (item == "Add New") {
      pageItem.selected.note_type = "";
      setTimeout(() => {
        this.NoteTypeInput.nativeElement.focus();
      }, 200)
    }
    if(pageItem.selected.note_type.trim().toUpperCase().includes('form') ||
      pageItem.selected.note_type.trim().toUpperCase().includes("The First report of injury".trim().toUpperCase()) ||
      pageItem.selected.note_type.trim().toUpperCase().includes("The subsequent report of injury".trim().toUpperCase())) 
       {
      // this.pageItem.selected.date = ""; 
      pageItem.selected.provider = "";
      pageItem.selected.date = "";

    }
    else {
      pageItem.selected.provider = pageItem.provider[0];
      if (pageItem.selected.date) {
        pageItem.selected.date = new Date(pageItem.selected.date);
      }
      else {
        pageItem.selected.date = new Date(pageItem.date[0]);
      }
    }
  }
  onSelectExam(exam, pageItem) {
    this.addNewExam = false;
    this.pageItem.selected.exam = exam;
    if (exam == "Add New") {
      this.pageItem.selected.exam = "";
      setTimeout(() => {
        this.examInput.nativeElement.focus();
      }, 200)
    }
  }

  onSelectDate(date, pageItem) { 
    if (date == "Add New") { 
      // this.pageItem.selected.date = "";
      this.addNewDate = false;
    }
    else {   
      this.addNewDate = false;
      this.pageItem.selected.date =  new Date(date) ;
      pageItem.selected.date = this.pageItem.selected.date; 
      return this.pageItem.selected.date;
    }
  } 

  // noteTypeDate(element){
  //   if (['C-3 form'.toUpperCase().trim(), 'C-4 form'.toUpperCase().trim(),
  //       'C-4.2 formn'.toUpperCase().trim(), 'C-4.3 form'.toUpperCase().trim(),
  //       'C-4 AUTH form'.toUpperCase().trim(), 'MG-2 form'.toUpperCase().trim(), 'C-4 AMR'.toUpperCase().trim(),
  //       'MG-2.1 form'.toUpperCase().trim(), 'Form'.toUpperCase().trim(), 'EC-4NARR form'.toUpperCase().trim(),
  //       'First report of injury'.toUpperCase().trim()].indexOf(element.selected.note_type.toUpperCase()) >= 0) {
  //         // this.pageItem.selected.date = ""; 
  //         this.pageItem.selected.provider = ""; 
  //         this.pageItem.selected.date = "";  

  //       }
  //       else {    
  //         this.pageItem.selected.provider = this.pageItem.selected.provider;
  //           if (this.pageItem.selected.date) {
  //             this.pageItem.selected.date = new Date(this.pageItem.selected.date);
  //             }
  //             else {
  //               this.pageItem.selected.date = " ";
  //             }  
  //       } 
  // }
  // noteTypeProvider(element){  
  //       if (['C-3 form'.toUpperCase().trim(), 'C-4 form'.toUpperCase().trim(),
  //       'C-4.2 formn'.toUpperCase().trim(), 'C-4.3 form'.toUpperCase().trim(),
  //       'C-4 AUTH form'.toUpperCase().trim(), 'MG-2 form'.toUpperCase().trim(), 'C-4 AMR'.toUpperCase().trim(),
  //       'MG-2.1 form'.toUpperCase().trim(), 'Form'.toUpperCase().trim(), 'EC-4NARR form'.toUpperCase().trim(),
  //       'First report of injury'.toUpperCase().trim()].indexOf(element.selected.note_type.toUpperCase()) >= 0) {
  //         // this.pageItem.selected.date = ""; 
  //           return this.pageItem.selected.provider = " ";  
  //       }
  //       else { 
  //           return this.pageItem.selected.provider = this.pageItem.selected.provider;
  //         }

  // }

  convertDate(element, date) {
    this.addNewDate = false;
    if (date.value) {
      // return new Date(date).toLocaleDateString();
      return element.selected.date = date.value;
    }
    else {
      return element.selected.date = date.value;
      // return date;
    }
  }
  addEvent(element, event: MatDatepickerInputEvent<Date>) {
    element.selected.date =  event.value;
    this.pageItem.selected.date = event.value;
    this.addNewDate = false;
    return event.value;
  }

  getDateType() {
    const index = this.pageItem.date.findIndex((date, i) => {
      const dateA = (new Date(date)).toString();
      const dateB = (new Date(this.pageItem.selected.date)).toString();
      return dateA === dateB;
    });

    if (this.pageItem.selected.date === '') {
      return '';
    }

    if (this.pageItem.selected.date && index >= 0) {
      return this.pageItem.date_type[index]
    }
    return '';
  }
  // getExam() {
  //   return this.pageItem.exam;
  // }

  changeCheckBox(element, e) {
    if (element) {
      element.checked = e.checked;
      this.onSaveClick.emit(element);
      this.pageReport.emit(element);
    }
    // this.addNewDate = true;
    // this.addNewProvider = true;
  }
  onItemSelect(item: any) {
    this.addNewDate = false;
    this.addNewProvider = true;
    // this.changeAddNew(true) 
    this.addNewNoteType = true;
    this.addNewExam = true;
    // this.pageItem.selected.exam = item;
  }
  carryOver(item) {
    this.carry = true; 
    this.carriedDate = new Date(item.selected.date).toLocaleDateString();
    this.carriedExam = item.selected.exam;
    this.carriedNoteType = item.selected.note_type;
    this.carriedImpression = item.impression; 
    if(this.carriedImpression && this.carriedImpression != "" && this.carriedImpression != undefined && this.carriedImpression != null){
      this.isCarriedImpression = true;
    }
  }
  pasteCarryOver(item){ 
    this.pageItem.selected.date = new Date(this.carriedDate); 
    this.pageItem.selected.exam = this.carriedExam;
    this.pageItem.selected.note_type = this.carriedNoteType;
    this.pageItem.impression = this.carriedImpression;
    this.carry = false;
    this.isCarriedImpression = false;
  }
}
