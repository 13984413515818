import { Injectable } from '@angular/core';
import * as global from '../includes/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PusherService } from './pusher-service';
import {eventTypes} from '../constants/pcc_webhook_constants'
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import {API_URL} from "../../environments/api-url";
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class PCCService {
 private currentUser:any;
 private userInfo:any;

 
  pccToken$: Subject<string> = new Subject<string>();
  
  public pccUserinfo;  
  pccUserinfo$: Subject<any> = new BehaviorSubject<any>(null);

  public inprogress = false;
  inprogress$: Subject<any> = new Subject<boolean>();

  defaultFac;
  defaultFac$: Subject<any> = new BehaviorSubject<any>(null);

  noteEditorUrl = new BehaviorSubject<any>(false);
  castnoteEditorUrl = this.noteEditorUrl.asObservable();

  webhookNotficationReceive: Subject<any> = new Subject<any>();
  authMode:'APP'|'USER' = 'APP';
  isSubscribedWebhookEvents = false;
  company_id: any;

 constructor(
    private httpclient: HttpClient, 
    private _authService:AuthService, 
    private _route:ActivatedRoute,
    private _router:Router,
    private _pusherService:PusherService,
    private _toastr:ToastrService,
    private commonService: CommonService,
   ) {

    this._authService.onUserAuthChange.subscribe(currentUser => {
      if(JSON.stringify(currentUser) !== JSON.stringify(this.currentUser) && currentUser) {
        this._init();
      }
    });
    this._init();
    this.defaultFac$.subscribe(fac => this.defaultFac = fac);
    this.pccUserinfo$.subscribe(pccUserinfo => this.pccUserinfo = pccUserinfo);
  }

  _init() {
    this.currentUser = this._authService.currentUser;
    if(!this.currentUser) {
      return
    }

    this.userInfo = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }
    if(this.currentUser.has_pcc_account === 'true'){
      if(!this.isSubscribedWebhookEvents) {
        // this.subscribeWebhookEvents(); // Disabled because of DNWC-3520
      }

      
      let pccTokenParam = this.getQueryParam('pcc-token');
      let pccOrgUuidParam = this.getQueryParam('pcc-orgUuid');
      if(pccTokenParam) {
        this.currentUser.pcc_auth = {
          token: pccTokenParam,
          orgUuid: pccOrgUuidParam,
          updatedOn: new Date()
        };
        this._authService.currentUser = this.currentUser;
      } 
      if(this.currentUser.pcc_auth) {
        if(this.currentUser.pcc_auth.token) {
          this.authMode = 'USER';
        } else {
          this.authMode = 'APP';
        }
        this.initToken();
      }
    }

  }
  
  hasPCCOrgUUID() {
    return !!this.currentUser.pcc_auth?.orgUuid
  }

  getQueryParam(paramKey) {
    let pccTokenParam = this._route.snapshot.queryParamMap.get(paramKey);
    // try another way
    if(!pccTokenParam) {
      let matchPCCToken;
      const re = new RegExp(`.*[?&]${paramKey}=([^&]+)`)
      if(matchPCCToken = re.exec(document.location.href)) {
        pccTokenParam = matchPCCToken[1]; 
      }
    }
    return pccTokenParam;
  }

  subscribeWebhookEvents() {
    const _eventTypes = [eventTypes.PATIENT_ADMIT, eventTypes.PATIENT_DISCHARGE, eventTypes.PATIENT_TRANSFER];
    this._pusherService.subscribePCCWebhookNotificationChannel(this.currentUser._id, Object.values(_eventTypes))

    this._pusherService.pcc_webhook_notify.subscribe((pusherData:any) => {      
      if(pusherData) {
        this.isSubscribedWebhookEvents = true;
        this.webhookNotficationReceive.next(pusherData);
        this._toastr.info(pusherData.payload.message, pusherData.eventType)
        // .onTap.subscribe(() => {
        //   this.handleWebhookNotificationToastClick(pusherData)
        // })
      }
    });
  }

  initToken() {
    
    this.inprogress$.subscribe((inprogress:any) => {
        this.inprogress = inprogress;
    });

    this.pccUserinfo$.subscribe((userinfo:any) => {
      if(userinfo) {
        this.pccUserinfo = userinfo;
      }
    })
    
    this.pccToken$.subscribe((pccToken:string) => {
      console.log('this.pccTokenChange.subscribe');
      
      this.inprogress$.next(true); 
      if(pccToken) {
        
        // const {orgUuid : pccOrgUuid} = this.currentUser.pcc_auth;
        // this.saveUserToken(pccToken, pccOrgUuid).subscribe((response:any) => {
        //     if(response.status === 200) {
        //       this.currentUser.pcc_auth = response.data.pcc_auth;
        //       this._authService.currentUser = this.currentUser;
        //       this.getUserInfo().subscribe((response:any) => {
        //         if(response.status === 200) {
        //           this.pccUserinfoChange.next(response.data);
        //         } else {
        //           // console.log('Invalidate pcc token');
        //           this.invalidateToken();
        //         }
        //       })
        //     }
        // }, () => {
        //   console.log('Invalidate pcc token');
        //   // this.invalidateToken();
        // }, () => this.inprogressChange.next(false))

        // 

        this.getUserInfo().subscribe(async (response: any) => {
          
          if (response.status === 200) {
            this.pccUserinfo$.next(response.data);
            const defaultFacId = response.data.defaultFacId;
            const pccOrgUuid = response.data.orgUuid;
            const defaultFacResponse:any = await this.getFacility(defaultFacId, pccOrgUuid).toPromise();
            if(defaultFacResponse.status == 200) {
              this.defaultFac$.next(defaultFacResponse.data);
            }
          } else {
            // console.log('Invalidate pcc token');
            this.invalidateToken();
          }
        }, () => {
          console.log('Invalidate pcc token');
          // this.invalidateToken();
        }, () => this.inprogress$.next(false))
        
      }
    });

    const pccTokenParam = this.getQueryParam('pcc-token')
    if(pccTokenParam) {
      console.log('pccTokenChange.next');
      
      this.pccToken$.next(pccTokenParam);
    } 
    else if(this.currentUser.pcc_auth && this.currentUser.pcc_auth.token) {
      console.log('pccTokenChange.next');
      
      this.pccToken$.next(this.currentUser.pcc_auth.token);
    }
  }
 
  changenoteEditorUrl(url) {
    this.noteEditorUrl.next(url);
  }

  goToLoginPCCScreen() {    
    this.beforeLoginToPCC();
    debugger
    const url = `${this.currentUser.additional_user_role === 'demo_provider' ? 'https://connectdev.doctornow.io' : global.pcc_integration_base_url}/dnow/integration/pcc/api/v1.0/auth/user/login`;
    // const url = `${global.app_base_url}/pcc/goToLoginPCCScreen`;    
    const dn_portal_uri = global.app_base_url.replace(/\/api$/m, '');
    const params = new URLSearchParams({'dn_user_id':this.currentUser._id,'dn_portal_uri': dn_portal_uri})
    window.location.href = `${url}?${params}`;
    // window.location.href = url;
    
    // const url = global.url + API_URL.PCC.goToLoginPCCScreen;
    // window.location.href = url;
  }

  beforeLoginToPCC() {
    this.setRedirectUrl();
  }

  setRedirectUrl(url?) {
    localStorage.setItem('pcc_redirect_url', typeof url === 'string' ? url : this._router.url);
  }
  getRedirectUrl() {
    return localStorage.getItem('pcc_redirect_url');
  }


  saveUserToken(token, orgUuid?) {
    var details ={
      ...this.userInfo,
        dnToken: this.currentUser.auth_key,
        pccToken: token,
        authMode:'USER',
    }
    if(orgUuid) {
      details.pccOrgUuid = orgUuid;
    }
    return this.httpclient.post(global.url_pcc + '/saveUserToken', {details: details}).pipe();
  }

  subscribeWebhook() {
    var details ={
      ...this.userInfo,
      authMode: 'USER',
      dnToken: this.currentUser.auth_key,
      pccToken: this.currentUser.pcc_auth?.token,
      pccOrgUuid: this.currentUser.pcc_auth.orgUuid,
    }
    return this.httpclient.post(global.url_pcc + '/subscribeWebhook', {details: details}).pipe();
  }

  invalidateToken() {
    console.log('invalidating token');
    
    this.saveUserToken('').subscribe(() => {
      if(this.currentUser.pcc_auth) {
        this.currentUser.pcc_auth.token = '';
        this.currentUser.pcc_auth.updatedOn = new Date();
      } else {
        this.currentUser.pcc_auth = {
          token: '',
          updatedOn: new Date()
        }
      }
      this._authService.currentUser = this.currentUser;
      this.pccToken$.next('')
    });
  }


  syncPatientsData(facilityId:string, pccPatients:Record<string, any>[]) {
    var details ={
      ...this.userInfo,
      authMode: 'USER',
      dnToken: this.currentUser.auth_key,
      pccToken: this.currentUser.pcc_auth?.token,
      facilityId,
      pccPatients
    }
    return this.httpclient.post(global.url_pcc + '/syncPatientsData', {details: details}).pipe();
  }
  revokeAuth() {
    var details ={
      ...this.userInfo,
      authMode: 'USER',
      dnToken: this.currentUser.auth_key,
      pccToken: this.currentUser.pcc_auth?.token,
      pccOrgUuid: this.currentUser.pcc_auth.orgUuid
    }
    return this.httpclient.post(global.url_pcc + '/revokeAuth', {details: details}).pipe();
  }

  logoutPCC() {
   this.revokeAuth();
   this.invalidateToken();
   this.pccUserinfo$.next('');
   this.pccUserinfo = null;
   this.revokeAuth().subscribe(result => console.log(result))
  }

  getUserInfo() {
    const details = {
      ...this.userInfo,
      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.currentUser.pcc_auth?.token,
      pccOrgUuid: this.currentUser.pcc_auth.orgUuid
    }
    return this.httpclient.post(global.url_pcc + '/getUserInfo', {details}).pipe();
  }

  
  getFacilities() {
    const details = {
      ...this.userInfo,
      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: this.currentUser.pcc_auth.orgUuid
    }
    const result = this.httpclient.post(global.url_pcc + '/getFacilities', {details}).pipe();
    result.subscribe((response:any) => {
      if(response.status !== 200) {
        // this.invalidateToken();
      }
    })
    return result;
  }
  
  getNotes(pccOrgUuid, pccPatientID, startDate, endDate, page = 1, pageSize = 20, company_id = undefined, provider_id = null) {
    //
    const details = {
      ...this.userInfo,
      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.currentUser.pcc_auth ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid,
      pccPatientID,
      page,
      pageSize,
      company_id,
      provider_id
    }
    const result = this.httpclient.post(global.url_pcc + '/getNotes', {details}).pipe();
    // result.subscribe((response:any) => {
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }

  getNoteTypes(pccOrgUuid, pccFacID, page = 1, pageSize = 20, company_id = undefined) {
    //
    const details = {
      ...this.userInfo,
      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.currentUser.pcc_auth ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid,
      pccFacID,
      page,
      pageSize,
      company_id
    }
    const result = this.httpclient.post(global.url_pcc + '/getNoteTypes', {details}).pipe();
    // result.subscribe((response:any) => {
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }
  
  getNote(pccOrgUuid, pccNoteId) {
    //
    const details = {
      ...this.userInfo,
      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.currentUser.pcc_auth ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid, 
      pccNoteId
    }
    const result = this.httpclient.post(global.url_pcc + '/getNote', {details}).pipe();
    // result.subscribe((response:any) => {
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }
  getPatientsByFacilityID(args) {
    const {
      pccOrgUuid, pccFacID, pccPatientStatus, pccPatientName, page=1, pageSize=20,noPagination,pccPatientID,
      authMode = this.authMode,
    } = args;
    //
    const pccToken = authMode === 'USER' && this.currentUser.pcc_auth ? this.currentUser.pcc_auth.token : null;
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: pccToken,
      pccOrgUuid: pccOrgUuid,
      pccFacID,
      pccPatientStatus,
      pccPatientName,
      page,
      pageSize,
      noPagination,
      pccPatientID
    }
    const result = this.httpclient.post(global.url_pcc + '/getPatients', {details}).pipe();
    // result.subscribe((response:any) => {
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }

  getPatientsByFacilityIDWithRecentNote(args) {
    const {
      pccOrgUuid, pccFacID, pccPatientStatus, pccPatientName = undefined, page=1, pageSize=20,
      authMode = this.authMode,
    } = args;
    //
    const pccToken = authMode === 'USER' && this.currentUser.pcc_auth ? this.currentUser.pcc_auth.token : null;
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: pccToken,
      pccOrgUuid: pccOrgUuid,
      pccFacID,
      pccPatientStatus,
      pccPatientName,
      company_id:this.currentUser.company_id,
      page,
      pageSize,
    }
    const result = this.httpclient.post(global.url_pcc + '/getPatientsWithRecentNote', {details}).pipe();
    // result.subscribe((response:any) => {
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }

  getPatientsByFacilityIDWithOutRecentNote(args) {
    const {
      pccOrgUuid, pccFacID, pccPatientStatus, pccPatientName = undefined, page=1, pageSize=20,
      authMode = this.authMode,
    } = args;
    //
    const pccToken = authMode === 'USER' && this.currentUser.pcc_auth ? this.currentUser.pcc_auth.token : null;
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: pccToken,
      pccOrgUuid: pccOrgUuid,
      pccFacID,
      pccPatientStatus,
      pccPatientName,
      company_id:this.currentUser.company_id,
      page,
      pageSize,
    }
    const result = this.httpclient.post(global.url_pcc + '/getPatientsWithOutRecentNote', {details}).pipe();

    return result;
  }

  getPatient(args) {
    const {
      pccOrgUuid, 
      pccPatientID,
      authMode = this.authMode
    } = args
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,
      pccPatientID
    }
    const result = this.httpclient.post(global.url_pcc + '/getPatient', {details}).pipe();
    // result.subscribe((response:any) => {
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }
  

  getFacility(pccFacID, pccOrgUuid?) {
    let _pccOrgUuid;
    if(pccOrgUuid) {
      _pccOrgUuid = pccOrgUuid
    } else {
      _pccOrgUuid = this.currentUser.pcc_auth.orgUuid
    }
    const details = {
      ...this.userInfo,
      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid,
      pccFacID
    }
    const result = this.httpclient.post(global.url_pcc + '/getFacility', {details}).pipe();
    // result.subscribe((response:any) => {
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }

  
  getObservations(args) {
    const {
      pccOrgUuid,
      pccPatientID,
      pccStartDate = '',
      pccEndDate = '',
      pccLatest = '',
      pccType = '',
      pccPage = '',
      pccPageSize = '',
      authMode = this.authMode} = args;
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,

      pccPatientID,
      
      pccStartDate,
      pccEndDate,
      pccLatest,
      pccType,
      pccPage,
      pccPageSize
    }
    const result = this.httpclient.post(global.url_pcc + '/getObservations', {details}).pipe(map((res:any) => {
      if(res.status === 200) {
        const {data} = res.data;
        const weight = data.find(e => e.type === 'weight');
        const height = data.find(e => e.type === 'height');
        if(weight && height) {
          // 1. Multiply the weight in pounds by 0.45 (the metric conversion factor)
          let s1 = weight.value * 0.45
          // 2. Multiply the height in inches by 0.025 (the metric conversion factor)
          let s2 = height.value *  0.025
          // 3. Square the answer from step 2
          let s3 = Math.pow(s2,2);
          // 4.Divide the answer from step 1 by the answer from step 3
          let s4 = s1/s3; // bmi

          const bmi = {
            type: "bmi", 
            value: (s4).toFixed(2), 
          }
          res.data.data = [
            ...data,
            bmi
          ]
        }
      }
      return res;
    }));
    // result.subscribe((response:any) => {
    //   console.log('/getObservations', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }
  
  getObservation(pccObservationID,
    ) {
    const details = {
      ...this.userInfo,
      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: this.currentUser.pcc_auth.orgUuid,

      pccObservationID
    }
    const result = this.httpclient.post(global.url_pcc + '/getObservation', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getObservation', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }
  
  
  getEpisodesOfCare(pccPatientId,
    pccPayerType,
    pccStatus = '',
    pccPage = '',
    pccPageSize = ''
    ) {
    const details = {
      ...this.userInfo,
      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: this.currentUser.pcc_auth.orgUuid,

      pccPatientId,
      pccPayerType,
      pccStatus,
      pccPage,
      pccPageSize
    }
    const result = this.httpclient.post(global.url_pcc + '/getEpisodesOfCare', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getEpisodesOfCare', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }
  
  getPatientMedications(args) {
    const {
      pccOrgUuid,
      pccPatientId,
      pccFacID,
      pccStatus = '',
      pccPage = '',
      pccPageSize = '',
      authMode = this.authMode
    } = args;
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,

      pccPatientId,
      pccFacID,
      pccStatus,
      pccPage,
      pccPageSize
    }
    const result = this.httpclient.post(global.url_pcc + '/getPatientMedications', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getPatientMedications', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }
  
  getActivitiesOfDailyLiving(args) {
    const {
      pccOrgUuid,
      pccPatientId,
      pccStartDate = '',
      pccEndDate = '',
      pccPage = '',
      pccPageSize = '',
      authMode = this.authMode
    } = args;
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,

      pccPatientId,
      pccStartDate,
      pccEndDate,
      pccPage,
      pccPageSize
    }
    const result = this.httpclient.post(global.url_pcc + '/getActivitiesOfDailyLiving', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getPatientMedications', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }
  
  getActivitiesOfDailyLivingScore(args) {
    const {
      pccOrgUuid,
      pccPatientId,
      pccStartDate = '',
      pccEndDate = '',
      pccPage = '',
      pccPageSize = '',
      authMode = this.authMode
    } = args;
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,

      pccPatientId,
      pccStartDate,
      pccEndDate,
      pccPage,
      pccPageSize
    }
    const result = this.httpclient.post(global.url_pcc + '/getActivitiesOfDailyLivingScore', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getPatientMedications', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }
  
  getPatientCoverages(
      args
    ) {
      const {
        pccOrgUuid,
        pccPatientId,
        pccEffectiveDateTime = '',
        authMode = this.authMode
      } = args;
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,

      pccPatientId,
      pccEffectiveDateTime,
    }
    const result = this.httpclient.post(global.url_pcc + '/getPatientCoverages', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getPatientCoverages', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }
  
  
  getPatientDiagnosticReports(args) {
    const {
    pccOrgUuid,
    pccPatientId,
    pccReportType, 
    pccIssuedDateTime = '', 
    pccPage = '', 
    pccPageSize = '',
    authMode = this.authMode
    } = args
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,

      pccPatientId,
      pccReportType, 
      pccIssuedDateTime,
      pccPage,
      pccPageSize
    }
    const result = this.httpclient.post(global.url_pcc + '/getPatientDiagnosticReports', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getPatientCoverages', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }

  getAdvanceDirectivesConsents(args) {
    const {
      pccOrgUuid,
      pccPatientId,
      status = '', 
      pccPage = '', 
      pccPageSize = '',
      authMode = this.authMode
    } = args;
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,

      pccPatientId,
      status,
      pccPage,
      pccPageSize
    }
    const result = this.httpclient.post(global.url_pcc + '/getAdvanceDirectivesConsents', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getPatientCoverages', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }

  
  getPatientNutritionOrders(args) {
    const {
      pccOrgUuid,
      pccPatientId,
      status = '', 
      orderDateTime = '',
      authMode = this.authMode
    } = args
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,

      pccPatientId,
      
      status,
      orderDateTime,

    }
    const result = this.httpclient.post(global.url_pcc + '/getPatientNutritionOrders', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getPatientCoverages', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }

  
  
  getPatientConditions(args) {
    const {
      pccOrgUuid,
      pccPatientId,
      pccClinicalStatus = '',
      pccPage = '',
      pccPageSize = '',
      authMode = this.authMode
    } = args;
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,

      pccPatientId,
      pccClinicalStatus,
      pccPage,
      pccPageSize
    }
    const result = this.httpclient.post(global.url_pcc + '/getPatientConditions', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getPatientConditions', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }
  
  getAllergyIntolerances(args) {
    const {
      pccOrgUuid,
      pccPatientId,
      pccPage = '',
      pccPageSize = '',
      authMode = this.authMode
    } = args;
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,

      pccPatientId,
      pccPage,
      pccPageSize
    }
    const result = this.httpclient.post(global.url_pcc + '/getAllergyIntolerances', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getAllergyIntolerances', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }
  
  getImmunizations(args) {
    const {
      pccOrgUuid,
      pccPatientId,
      pccPage = '',
      pccPageSize = '',
      authMode = this.authMode
    } = args;
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,

      pccPatientId,
      pccPage,
      pccPageSize
    }
    const result = this.httpclient.post(global.url_pcc + '/getImmunizations', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getImmunizations', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }

  // mutation
  
  importPCCFacilities() {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.currentUser.pcc_auth?.token,
      pccOrgUuid: this.currentUser.pcc_auth.orgUuid
    }
    return this.httpclient.post(global.url_pcc + '/importPCCFacilities', {details}).pipe();
  }
   
  importPCCPatient(pccOrgUuid, pccPatientID, primaryProviderID, facilityID, sync = false) {
     this.commonService.castCompany.subscribe(company=>{
        this.company_id = company?._id
        // 
    })
    if(!this.company_id){
      this.company_id=this.currentUser.company_id
    }
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.currentUser.pcc_auth?.token,
      pccOrgUuid: pccOrgUuid,

      company_id:this.company_id,

      pccPatientID,
      primaryProviderID,
      facilityID,
      sync
    }
 

    return this.httpclient.post(global.url_pcc + '/importPatient', {details}).pipe();
  }

  mergePccPatient(pccPatientID, duplicatePatientID, source_patients, primaryProviderID, facilityID) {
    this.commonService.castCompany.subscribe(company => {
      this.company_id = company?._id
    })
    if (!this.company_id) {
      this.company_id = this.currentUser.company_id
    }
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.currentUser.pcc_auth?.token,
      company_id: this.company_id,
      duplicatePatientID,
      source_patients,
      pccPatientID,
      primaryProviderID,
      facilityID
    }
    return this.httpclient.post(global.url_pcc + '/mergePccPatient', { details }).pipe();

  }

  getPrimaryCarePractitioner(args) {
    const {
    pccOrgUuid,
    pccPatientID,
    authMode = this.authMode
    } = args
    const details = {
      ...this.userInfo,
      authMode: authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: authMode === 'USER' ? this.currentUser.pcc_auth.token : null,
      pccOrgUuid: pccOrgUuid || this.currentUser.pcc_auth?.orgUuid,

      pccPatientID,
    }
    const result = this.httpclient.post(global.url_pcc + '/getPrimaryCarePractitioner', {details}).pipe();
    // result.subscribe((response:any) => {
    //   console.log('/getPatientCoverages', response.data);
      
    //   if(response.status !== 200) {
    //     // this.invalidateToken();
    //   }
    // })
    return result;
  }

  
  importPCCFacilityAndPatient(pccOrgUuid, pccFacId, primaryProviderId, pccPatientId, company_id) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.currentUser.pcc_auth?.token,
      pccOrgUuid,
      pccFacId,
      pccPatientId,
      primaryProviderId,
      company_id
    }
    return this.httpclient.post(global.url_pcc + '/importPCCFacilityAndPatient', {details}).pipe();
  }
  
  getPCCEventNotifications(eventTypes?, facilityIds?) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      timezone,
      eventTypes, 
      facilityIds
    }
    return this.httpclient.post(global.url_pcc + '/getPCCEventNotifications', {details}).pipe();
  }
  updatePCCEventNotificationBy(notificationId, fieldName) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      notificationId,
      fieldName
    }
    return this.httpclient.post(global.url_pcc + '/updatePCCEventNotificationBy', {details}).pipe();
  }
  getPopulatedActivations(page = 1, pageSize= 200) {
    const details = {
      ...this.userInfo,
      authMode: 'APP',
      dnToken: this.currentUser.auth_key,
      pccToken: '',
      page,
      pageSize,
    }
    return this.httpclient.post(global.url_pcc + '/getPopulatedActivations', {details}).pipe();
  }
  importActivations(pccFacs, company_id) {
    const details = {
      ...this.userInfo,
      authMode: this.authMode,
      dnToken: this.currentUser.auth_key,
      pccToken: this.currentUser.pcc_auth ? this.currentUser.pcc_auth.token : null,
      pccFacs,
      company_id
    }
    return this.httpclient.post(global.url_pcc + '/importActivations', {details}).pipe();
  }

  isLoggedInFacility(dnFacility) {
    if(!this.pccUserinfo || !this.currentUser.pcc_auth || !dnFacility) return false;
    if(!dnFacility?.provider_ids_ref) {
      return false;
    }
    if(dnFacility.source !== 'PointClickCare') {
      return false;
    }
    const providerIds = dnFacility.provider_ids_ref.filter(u => !!u).map(u => typeof u === 'string' ? u : u._id);
    let isOwner = providerIds.indexOf(this.currentUser._id) >= 0
    if (!isOwner && this._authService.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE && dnFacility.SNF_WC_NURSES) {
      let nurse_ids = dnFacility.SNF_WC_NURSES.associated_snf_wc_nurse_ids;
      if (nurse_ids && nurse_ids.indexOf(this.currentUser._id) >= 0) isOwner = true
    }

    return isOwner && dnFacility.pcc_orgUuid === this.currentUser.pcc_auth.orgUuid
  }
  isDefaultLoggedInFacility(dnFacility) {
    if(!this.pccUserinfo) return false;
    return String(dnFacility.pcc_facId) === String(this.pccUserinfo.defaultFacId) && this.isLoggedInFacility(dnFacility)
  }

  generateFacilityDeactivationPDFsAndEmail(data) {
    return this.httpclient.post(global.url_pcc + '/generateFacilityDeactivationPDFsAndEmail', data).pipe();
  }

  generateFacilityActivationEmail(data) {
    return this.httpclient.post(global.url_pcc + '/generateFacilityActivationEmail', data).pipe();
  }
  getFacilityActivationDetails(data) {
    return this.httpclient.post(global.url_pcc + '/getFacilityActivationDetails', data).pipe();
  }
  syncCoveragesData(data) {
    return this.httpclient.post(global.url_pcc + '/syncCoveragesData', data).pipe();
  }
  exportPccFacilitiesAsXLSX(filters) {
    const details = {
      ...this.userInfo,
      authMode: 'APP',
      dnToken: this.currentUser.auth_key,
      pccToken: '',
      filters
    }
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url_pcc + '/exportPccFacilitiesAsXLSX', { details },
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
}
