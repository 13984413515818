import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as global from '../global';
import {API_URL} from "../../../environments/api-url";
import {AuthService} from "../../services/auth.service";
import { map } from "rxjs/operators";
import moment from 'moment'
@Injectable({
    providedIn: 'root',
})
export class PatientRoundingSheetService {

    currentUser: any;
    constructor(private httpclient: HttpClient,
                private authService: AuthService) {
        this.currentUser = this.authService.currentUser;
    }

    addPatientRoundingSheet(details) {
        details.company_id = this.currentUser.company_id;
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.addPatientRoundingSheet, {details}).pipe()
    }

    addPatientRoundingSheet_outPatient(details) {
        details.company_id = this.currentUser.company_id;
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.addPatientRoundingSheet_outPatient, {details}).pipe()
    }

    updatePatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.updatePatientRoundingSheet, {details}).pipe()
    }

    deletePatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.deletePatientRoundingSheet, {details}).pipe()
    }

    getPatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getPatientRoundingSheet, {details}).pipe()
    }

    getPatientRoundingSheets(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getPatientRoundingSheets, {details}).pipe()
    }
    refreshCensusPCCPatients(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.refreshCensusPCCPatients, {details}).pipe()
    }
    getCensusList(facility_id?,provider_id?, limit?, only_pcc = false) {
        const details = {
            ...this.authService.authObject,

            facility_id,
            provider_id,
            company_id:this.currentUser.company_id,
            limit,
            only_pcc
        }
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getCensusList, {details}).pipe()
    }
    addPatientToPatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.addPatientToPatientRoundingSheet, {details}).pipe()
    }
    checkIfPatientExistInAssociatedProviderCensus(details) {
        details.companyId = this.currentUser.company_id;
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.checkIfPatientExistInAssociatedProviderCensus, {details}).pipe()
    }
    addPatientsToPatientRoundingSheet(patientRoundingSheetId, patientIds, oldCensusId?) {
        let details: any = {
            ...this.authService.authObject,
            companyId: this.currentUser.company_id,
            patientRoundingSheetId,
            patientIds
        }
        if(oldCensusId) {
            details.oldCensusId = oldCensusId;
        }
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.addPatientsToPatientRoundingSheet, {details}).pipe()
    }

    removePatientFromPatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.removePatientFromPatientRoundingSheet, {details}).pipe()
    }
    removeNoteFromPatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.removeNoteFromPatientRoundingSheet, {details}).pipe()
    }

    updatePatientRoundingSheetSeen(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.updatePatientRoundingSheetSeen, {details}).pipe()
    }
    updatePatientListinRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.updatePatientListinRoundingSheet, {details}).pipe();
    }
    updatePatientRoundingSheetInactive(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.updatePatientRoundingSheetInactive, {details}).pipe()
    }

    // charge services
    addCharge(data = {}, resubmitted_by = null ) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data, 
            resubmitted_by
        }; 
        return this.httpclient
            .post(global.url + API_URL.CHARGE.addCharge,
                {details: details});
    }
    getCharges(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getCharges,
                {details: details});
    }
    getICDs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getICDs,
                {details: details});
    }
    compareICDs(data = []) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            icds: data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.compareICDs,
                {details: details});
    }
    getRecentCharge(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getRecentCharge,
                {details: details});
    }
    getrecentMip(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            company_id: this.currentUser.company_id,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getrecentMip,
                { details: details });
    }
    getCPTs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getCPTs,
                {details: details});
    }
    getCompanyCPTs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getCompanyCPTs,
                {details: details});
    }
    setFavoriteICDs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.setFavoriteICDs,
                {details: details});
    }
    setFavoriteCPTs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.setFavoriteCPTs,
                {details: details});
    }
    getFavoriteICDs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getFavoriteICDs,
                {details: details}).pipe(
                    map((response: any) => {
                        if (response.status === 200) {
                            response.data.array.map(icd => {
                                if (icd.end_date) {
                                    const [month, day, year] = icd.end_date.split("/");
                                    if (moment(`${year}-${month}-${day}`).isBefore(moment())) {
                                        icd.isExpired = true;
                                    }
                                }
                                return icd;
                            });
                            return response;
                        }
                    })
                );
    }
    getFavoriteCPTs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getFavoriteCPTs,
                {details: details});
    }
    //ali
    rearrangeFavoriteCharges(array, filter) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            array, 
            filter
        };  
        return this.httpclient
            .post(global.url + API_URL.CHARGE.rearrangeFavoriteCharges,
                {details: details});
    }
    applyColorToFavouriteCPT(cptsArray, color) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            cptsArray,
            color
        };
        return this.httpclient.post(global.url + API_URL.CHARGE.applyColorToFavouriteCPT, { details: details });
    }

    addCptExpiryStatus(response: any, isShowActiveCpts = true){
        response.map(cpt => {
            if (!cpt.end_date)
                cpt.end_date = "";
            const [month, day, year] = cpt.end_date.split("/");
            if (cpt.end_date != "" && moment(`${year}-${month}-${day}`).isBefore(moment()))
                cpt.status = 'Expired'
            else
                cpt.status = 'Active'
        });

        if (isShowActiveCpts)
            response = response.filter(cpt => cpt.status == 'Active')
        return response;
    }

    createSkinSubRefferal(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.createSkinSubRefferal, {details}).pipe()
    }
    //ali
    // addToFavoriteCharges(data = {}) {
    //     const details = {
    //         id: this.currentUser._id,
    //         auth_key: this.currentUser.auth_key,
    //         usertype: this.currentUser.user_type,
    //         ...data
    //     }; 
    //     return this.httpclient
    //         .post(global.url + API_URL.CHARGE.addToFavoriteCharges,
    //             {details: details});
    // }
    //ali
    // getFavoriteCharges(filter =  null) {
    //     const details = {
    //         id: this.currentUser._id,
    //         auth_key: this.currentUser.auth_key,
    //         usertype: this.currentUser.user_type,
    //         filter
    //     };  
    //     return this.httpclient
    //         .post(global.url + API_URL.CHARGE.getFavoriteCharges,
    //             {details: details});
    // }
    
    //ali
    // removeFromFavourite(data, filter) {
    //     const details = {
    //         id: this.currentUser._id,
    //         auth_key: this.currentUser.auth_key,
    //         usertype: this.currentUser.user_type,
    //         ...data,
    //         filter
    //     };  
    //     return this.httpclient
    //         .post(global.url + API_URL.CHARGE.removeFromFavourite,
    //             {details: details});
    // }
}
