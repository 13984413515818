import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, Input, OnChanges, ElementRef } from '@angular/core';
import * as global from '../global'
import { PatientListService } from '../patient-list/patient-list-service';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { MatDialog } from '@angular/material/dialog';
import { PatientRoundingSheetDialog } from '../patient-rounding-sheets/create-patient-rounding-sheet-dialog/create-patient-rounding-sheet-dialog';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FacilityService } from 'src/app/services/facility.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MultiplePatientsAddDialog } from '../patient-rounding-sheets/multiple-patients-add-dialog/multiple-patients-add-dialog';
import moment from 'moment'
import { CensusPatientListService } from '../census-patient-list/census-patient-list.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { timingSafeEqual } from 'crypto';
import { CensusListService } from './census-list-v2.service';
import { RehabDocsPopupComponent } from 'src/app/shared/popup-dialogs/rehab-docs-popup/rehab-docs-popup/rehab-docs-popup.component';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { debug } from 'console';
import { PCCService } from 'src/app/services/pcc.service';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { UpdatePatientRoundingSheetDialog } from '../patient-rounding-sheets/update-patient-rounding-sheet-dialog/update-patient-rounding-sheet-dialog';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-census-list-v2',
  templateUrl: './census-list-v2.component.html',
  styleUrls: ['./census-list-v2.component.css']
})
export class CensusListV2Component implements OnInit, OnDestroy, AfterViewInit {
  global = global;
  currentUser;
  currentUserDetails;
  admin: Boolean = false;
  company: Boolean = false;
  rouding_sheet_Ids: any = [];
  chargesCountData: any = [];
  notesCountData: any = [];

  patients = [];
  patientRoundingSheets: any = [];
  total: number = 0;
  censusListFetchedOnce: boolean = true;
  facility_charge_capture_enabled = false;
  user_charge_capture_enabled = false;
  displayedColumns: string[] = [
    'dateOfService',
    'name',
    'facility',
    'patients_count_total',
    'saved_signed_notes',
    'draft_submitted_charges',
    'status',
    'operations'
  ];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  facilities = [];
  providers = [];
  selectedFacility;
  filter_facility_id = "";
  filter_provider_id = "";

  private showPatientList: Boolean = false;

  loaderId = 'patient-rounding-sheets-loader';
  inprogress = false;

  showLimit = 5;

  providerIdParam: string;

  pccDefaultFac;
  public pccUserInfo;
  p2pSubscription: any;
  addressSubscription: any;
  FacilitiesSubscription: any;
  clickedRows = new Set<any>();
  isAuthorized = true;
  searchText: any;
  searchTextProvider: any;
  @Input() detect_refresh_child: string

  // error2LeggedAuth;

  constructor(
    private _patientListService: PatientListService,
    private _authService: AuthService,
    private _censusListService: CensusListService,
    private censusPatientListServie: CensusPatientListService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private facilityService: FacilityService,
    private loader: NgxUiLoaderService,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    private _transcriptionCreateService: TranscriptionCreateService,
    private pccService: PCCService,
    private commonService: CommonService,
  ) {
    this.currentUser = this._authService.currentUser;
    this.currentUserDetails = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }
    this.user_charge_capture_enabled = this.currentUser.charge_status;
  }
  ngOnDestroy(): void {
    this._censusListService.changeFilterProviderId(this.filter_provider_id);
    this._censusListService.changeFilterFacilityId(this.filter_facility_id);
    if (this.p2pSubscription) {
      this.p2pSubscription.unsubscribe();
    }
    // if(this.addressSubscription){
    //   this.addressSubscription.unsubscribe();
    // }
    if (this.FacilitiesSubscription) {
      this.FacilitiesSubscription.unsubscribe();
    }
  }
  ngOnInit() {
    if (window.location.pathname.includes('admin')) {
      this.admin = true;
    }
    else if (window.location.pathname.includes('company')) {
      this.company = true;
    }
    else {
      this.admin = false;
    }
    this.p2pSubscription = this._censusListService.castFilterProviderId.subscribe(filterProviderId => {
      this.filter_provider_id = filterProviderId;
      // 
    })
    if (this.p2pSubscription) {
      this.initProviders();
    }

    this.FacilitiesSubscription = this._censusListService.castFilterFacilityId.subscribe(filterFacilityId => {
      this.filter_facility_id = filterFacilityId;
      console.log("filter", this.filter_facility_id);
      
    });
    if (this.FacilitiesSubscription) {
      this.initFacilities(null, this.filter_provider_id);
    }
    this.pccService.defaultFac$.subscribe(fac => {
      this.pccDefaultFac = fac;
    });

    this.pccService.pccUserinfo$.subscribe(value => {
      this.pccUserInfo = value;
      // this.checkPCC2LeggedAuthentication();
    });
    if (this.filter_provider_id && this.filter_facility_id) {

      if (this.route.snapshot.params.id) {


        // if(this.route.snapshot.params.id!='notAuthorized' && this.route.snapshot.params.id!='null'){
        let sanitizedFacility_id = this.commonService.sanitizeInput(this.filter_facility_id);
        let sanitizedProvider_id = this.commonService.sanitizeInput(this.filter_provider_id);
        this._censusListService.getCensusList(sanitizedFacility_id, sanitizedProvider_id, this.showLimit).subscribe(async (response: any) => {
          if (response.status === 200) {
            this.patientRoundingSheets = response.data.array;
            this.total = response.data.total;
            let row = response.data.array.filter(item => {
              return item._id == this.route.snapshot.params.id
            })
            this.show_selected(row[0])
            this.dataSource = new MatTableDataSource(this.patientRoundingSheets);
            // this.loader.stopLoader(this.loaderId);

          }
          else {
            // this.isAuthorized = false
            // this.router.navigateByUrl(`/census/${'notAuthorized'};telemedicine=${"false"}`);
          }
        })
        // }

      }
      else {
        this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
      }
    }
    console.log("this.", this.displayedColumns);
    // })
  }
  viewRehabDocs(census_id) {
    this.dialog.open(RehabDocsPopupComponent, { data: census_id, width: '80%', height: '100vh' })

  }

  initPatients() {
    const details = {
      ...this.currentUserDetails
    }
    this._patientListService.getPatients(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.patients = response.data.array
          .filter(patient => patient.is_active === 'true')
      }
    })
  }
  initPatientRoundingSheets(facility_id?, provider_id?, limit?) {
    // if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT && this.facilities?.length < 1) return;
    this.loader.startLoader(this.loaderId);
    let sanitizedFacility_id = this.commonService.sanitizeInput(facility_id);
    let sanitizedProvider_id = this.commonService.sanitizeInput(provider_id);
    this._censusListService.getCensusList(sanitizedFacility_id, sanitizedProvider_id, limit).subscribe(async (response: any) => {
      if (response.status === 200) {
        this.patientRoundingSheets = response.data;
        this.dataSource = new MatTableDataSource(this.patientRoundingSheets);
      }
      this.loader.stopLoader(this.loaderId);
    })
  }
  initializeActiveCensus(facility_id) {
    this.loader.startLoader(this.loaderId);

    this.censusPatientListServie.initializeActiveCensus(facility_id).subscribe((response: any) => {
      if (response.status === 200) {
        this.loader.stopLoader(this.loaderId);
        this.toastr.success('Census has been initialized successfully')
      }
    })
  }
  initFacilities(selectedProvider = null, provider_id?) {
    if (!this.filter_facility_id && this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) {
      if (this.providers?.length > 0) {
        selectedProvider = this.providers[0]._id;
      }
      else return;
    }
    if (provider_id) selectedProvider = provider_id;
    this.facilities = []
    let sanitizedSelectedProvider = this.commonService.sanitizeInput(selectedProvider);
    this.addressSubscription = this.facilityService.getMyFacilities(null, sanitizedSelectedProvider).subscribe((response: any) => {
      if (response.status == 200) {
        this.facilities = response.data.array;
        this.facilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        this.selectedFacility = this.facilities.find(f => f._id === this.filter_facility_id);
        if (!this.selectedFacility) {
          this.pccService.pccUserinfo$.subscribe(u => {
            if (u) {
              
              const selectedFacility = this.facilities.find(f => this.isDefaultLoggedInFacility(f));
              if (selectedFacility && this.selectedFacility?._id !== selectedFacility._id) {
                this.selectedFacility = selectedFacility;
                this.filter_facility_id = this.selectedFacility._id;
                this.handleFilterFacilityChange();
              }
            } else {
              this.selectedFacility = this.facilities[0];
              this.filter_facility_id = this.facilities[0]._id;
              this.handleFilterFacilityChange();
            }
          });
        } else {
          this.facility_charge_capture_enabled = this.selectedFacility.enabled_charge_capture;
          if ((this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type === global.USER_TYPE.DOCTOR || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) && (!this.facility_charge_capture_enabled || !this.user_charge_capture_enabled)) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('draft_submitted_charges'), 1);
            this.displayedColumns.splice(this.displayedColumns.indexOf('status'), 1);
          }
          else if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
            this.displayedColumns = this.displayedColumns.filter(column => column !== 'saved_signed_notes'&& column !== 'draft_submitted_charges' && column !== 'status');
          }
        }
        if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT)
          this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
      }
    });
  }
  isDefaultLoggedInFacility(f) {
    return this.pccService.isDefaultLoggedInFacility(f);
  }
  isLoggedInFacility(f) {
    return this.pccService.isLoggedInFacility(f);
  }
  async initProviders() {
    this.providerIdParam = this.route.snapshot.params.provider_id;
    if (this.providerIdParam) {
      this.filter_provider_id = this.providerIdParam;
    }
    if (this.currentUser.user_type == global.USER_TYPE.DOCTOR) {
      let response: any = await this.dashboardService.getP2PRelation().toPromise()
      if (response.status == 200) {
        this.providers = [this.currentUser].concat(response.data.assoc_provider_id);

        this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
        if (this.filter_provider_id) {
          let filtered_provider = this.providers.filter(item => item._id == this.filter_provider_id)
          if (filtered_provider) {
            this.filter_provider_id = filtered_provider[0]._id
            return
          }

        }
        if (!this.filter_provider_id) {
          this.filter_provider_id = this.providers[0]._id;
        }
      }
    }

    else if (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
      this.initAssociatedProvidersListForMA();
    }
    // else if (this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
    //   this.initAssociatedProvidersListForBO();
    // }
    else {
      this.initAssociatedProvidersList();
    }
  }
  initAssociatedProvidersList() {
    this.dashboardService
      .getAssociatedDoctorsList(this.currentUser.company_id)
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          if (this.filter_provider_id) {
            this.initFacilities(null, this.filter_provider_id);
          }
          else {
            this.initFacilities();
          }
          this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
          if (!this.filter_provider_id) {
            this.filter_provider_id = this.providers[0]._id;
          }
        }
      });
  }

  initAssociatedProvidersListForMA() {
    this._transcriptionCreateService
      .getAssociatedDoctorsListForMA()
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          if (this.providers?.length > 0) {
            const providerId = this.filter_provider_id || this.providers[0]._id;
            this.initFacilities(null, providerId);
          }
          if (this.providers?.length > 0) {
            this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
            if (!this.filter_provider_id) {
              this.filter_provider_id = this.providers[0]._id;
            }
          }
        }
      });
  }

  // initAssociatedProvidersListForBO() {
  //   this._transcriptionCreateService
  //     .getAssociatedDoctorsListForBO()
  //     .subscribe((response: any) => {
  //       if (response.status === 200) {
  //         this.providers = response.data;
  //         if (this.providers?.length > 0) {
  //           const providerId = this.filter_provider_id || this.providers[0]._id;
  //           this.initFacilities(null, providerId);
  //         }
  //         if (this.providers?.length > 0) {
  //           this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
  //           if (!this.filter_provider_id) {
  //             this.filter_provider_id = this.providers[0]._id;
  //           }
  //         }
  //       }
  //     });
  // }

  getPatientsCounts(census, type) {
    const { patientList } = census;
    switch (type) {
      case 'total':
        return patientList.length
      case 'new':
        {
          let count = 0;
          for (const patient of patientList) {
            if (!this.censusPatientListServie.isPatientFollowUp(census, patient)) {
              count++;
            }
          }
          return count;
        }

      case 'followUp':
        {
          let count = 0;
          for (const patient of patientList) {
            if (this.censusPatientListServie.isPatientFollowUp(census, patient)) {
              count++;
            }
          }
          return count;
        }
      case 'discharge':
        {
          let count = 0;
          for (const patient of patientList) {
            if (patient.pcc_payload?.admissionDate) {
              count++;
            }
          }
          return count;
        }
    }
  }
  onPatientSelect($event, patientRoundingSheet) {
    this.addPatientToPatientRoundingSheet(patientRoundingSheet._id, $event._id);

  }
  onSelectAllPatients($event, patientRoundingSheet) {

  }
  onPatientDeSelect($event, patientRoundingSheet) {
    this.removePatientFromPatientRoundingSheet(patientRoundingSheet._id, $event._id);
  }
  showPatientRoundingSheetDialog() {
    const obj = {
      filter_facility_id: this.filter_facility_id,
      filter_provider_id: this.filter_provider_id,
    }
    const dialogRef = this.dialog.open(PatientRoundingSheetDialog, {
      data: obj,
      width: '50%'
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      this.handleFilterFacilityChange();
    });
  }
  showUpdatePatientRoundingSheetDialog(rounding_sheet_id, name, date, facility) {
    // console.log("This.facility: ", this.filter_facility_id);
    let fac = this.facilities.filter(fac => fac._id == this.filter_facility_id)
    let time_zone = fac[0]?.pcc_timeZone ? fac[0].pcc_timeZone : fac[0].timeZone;
    const obj = {
      rounding_sheet_id: rounding_sheet_id,
      name,
      date,
      time_zone
    }
    const dialogRef = this.dialog.open(UpdatePatientRoundingSheetDialog, {
      data: obj,
      width: '50%'
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      // console.log("asdasdas", data);
      if (data)
        this.handleFilterFacilityChange();
    });
  }
  showMultiplePatientsAddDialog(patientRoundingSheet) {
    const dialogRef = this.dialog.open(MultiplePatientsAddDialog, { width: '50%', data: { patientRoundingSheet } });
    dialogRef.afterClosed().subscribe(async (patients) => {

      if (Array.isArray(patients)) {
        patientRoundingSheet.patientList = patients;
      }
    });
  }
  addPatientToPatientRoundingSheet(patientRoundingSheetId, patientId) {
    const details = {
      ...this.currentUserDetails,
      patientRoundingSheetId: patientRoundingSheetId,
      patientId: patientId,
    }
    const infoToast = this.toastr.info('Adding', 'Please wait', { disableTimeOut: true });

    this._censusListService.addPatientToPatientRoundingSheet(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success('Patient added Successfully', 'Success');
      }
    },
      () => this.toastr.error('Something went wrong adding patient', 'Failed'),
      () => this.toastr.clear(infoToast.toastId))
  }

  removePatientFromPatientRoundingSheet(patientRoundingSheetId, patientId) {
    const details = {
      ...this.currentUserDetails,
      patientRoundingSheetId: patientRoundingSheetId,
      patientId: patientId,
    }
    const infoToast = this.toastr.info('Removing', 'Please wait', { disableTimeOut: true });

    this._censusListService.removePatientFromPatientRoundingSheet(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success('Patient removed Successfully', 'Success');
      }
    },
      () => this.toastr.error('Something went wrong removing patient', 'Failed'),
      () => this.toastr.clear(infoToast.toastId))
  }


  deletePatientRoundingSheet(patientRoundingSheetId) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: 'Are you sure you want to delete this census?'
      }
    });
    let confirmed = "false";
    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        const details = {
          ...this.currentUserDetails,
          sheetid: patientRoundingSheetId
        }
        const infoToast = this.toastr.info('Deleting Patient Census', 'Please wait', { disableTimeOut: true });

        this._censusListService.deletePatientRoundingSheet(details).subscribe((response: any) => {
          if (response.status === 200) {
            this.toastr.success('Patient Census removed Successfully', 'Success');
            this.handleFilterFacilityChange();
          }
        })
      }
    }),
      () => this.toastr.error('Something went wrong removing Patient Census', 'Failed')

  }

  sortData(sort: Sort) {
    const data = this.patientRoundingSheets.slice();

    this.patientRoundingSheets = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          const momentDateA = moment(a.name, 'MMM D, YYYY', true);
          const momentDateB = moment(b.name, 'MMM D, YYYY', true);
          return compare(momentDateA.isValid() ? momentDateA.get('milliseconds') : a.name, momentDateB.isValid() ? momentDateB.get('milliseconds') : b.name, isAsc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource(this.patientRoundingSheets);
  }

  handleFilterFacilityChange() {
    this.displayedColumns = [
      'dateOfService',
      'name',
      'facility',
      'patients_count_total',
      'saved_signed_notes',
      'draft_submitted_charges',
      'status',
      'operations'
    ];
    this.censusListFetchedOnce = true;
    this.initPatientRoundingSheets(this.filter_facility_id && this.filter_facility_id, this.filter_provider_id, this.showLimit);
    // console.log('census facilty', this.filter_facility_id);
    this.selectedFacility = this.facilities.find(f => f._id === this.filter_facility_id);
    this.facility_charge_capture_enabled = this.selectedFacility.enabled_charge_capture;
    if (!this.facility_charge_capture_enabled || !this.user_charge_capture_enabled) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('draft_submitted_charges'), 1);
      this.displayedColumns.splice(this.displayedColumns.indexOf('status'), 1);
    }
  }
  is2LeggedAuthRequired(facilityId) {
    const facility = this.facilities.find(f => f._id === facilityId);
    if (facility && facility.pcc_facId && !facility.pcc_2_legged_authentication) {
      if (!this.isLoggedIntoPCC()) return true;
      if (!this.pccService.isLoggedInFacility(facility)) return true;
    }

    return false;
  }
  handleproviderChange(provider) {
    this.censusListFetchedOnce = true;
    this.initFacilities(null, this.filter_provider_id);
    this.handleFilterFacilityChange();

  }
  isOwner(census) {
    return this.currentUser._id == census.createdBy
  }
  showCreateButton() {
    if (this.currentUser.user_type === global.USER_TYPE.DOCTOR) {
      return this.currentUser.user_type === global.USER_TYPE.DOCTOR
    }
    else if (this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) {
      return this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER
    }
    else if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
      return this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT
    }

  }

  //navigation
  async viewClicked(census_id) {
    localStorage.setItem('hide_census', 'false')
    let pro;
    if (this.providerIdParam) {
      let sanitizedProviderIdParam = this.commonService.sanitizeInput(this.providerIdParam);
      const userDetailResponse: any = await this.dashboardService.getUserDetail(sanitizedProviderIdParam).toPromise();
      if (userDetailResponse.status == 200) {
        pro = userDetailResponse.data;
      }
    } else {
      pro = this.providers.filter(p => p._id == this.filter_provider_id);
    }
    let telemedicine = pro[0]?.other_settings?.show_telemedicine || 'false';
    if (this.admin) {
      this.router.navigateByUrl(`/admin/census-v2/${census_id};telemedicine=${telemedicine}`);
      this.showPatientList = true;
    }
    if (this.company) {
      this.router.navigateByUrl(`/company/${this.currentUser.company_id}/census-v2/${census_id};telemedicine=${telemedicine}`);
      this.showPatientList = true;
    }
    else {
      this.showPatientList = false;
      this.router.navigateByUrl(`/census-v2/${census_id};telemedicine=${telemedicine}`);
    }
  }
  ngAfterViewInit() {
    if (this.dataSource?.sort) {
      this.dataSource.sort = this.sort;
    }
  }
  // ngOnChanges(changes) {
  //   if (changes.detect_refresh_child.previousValue != undefined) {
  //     if (changes.detect_refresh_child.currentValue != changes.detect_refresh_child.previousValue)
  //       this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
  //   }
  // }
  isLoggedIntoPCC() {
    return !!this.pccUserInfo;
  }
  show_selected(row) {
    this.clickedRows.clear()
    this.clickedRows.add(row)
  }
  async getChargesCountData() {
    let details = {
      rounding_sheet_id: this.rouding_sheet_Ids,
    };
    const res: any = await this._censusListService.getChargeCountData(details).toPromise();
    if (res["status"] == 200) {
      this.chargesCountData = res.data
    }
  }
  async getNotesCounttData() {
    let details = {
      rounding_sheet_id: this.rouding_sheet_Ids,
    };
    const res: any = await this._censusListService.getsavedSignedCount(details).toPromise();
    if (res["status"] == 200) {
      this.notesCountData = res.data
    }
  }
  getChargecount(id: any): any {
    let index = this.chargesCountData.findIndex(x => x._id === id);
    return this.chargesCountData[index].draftCharges + " / " + this.chargesCountData[index].submitCharges;
  }
  getNoteCount(id: any): any {
    let index = this.chargesCountData.findIndex(x => x._id === id);
    return this.notesCountData[index].unSignedNotes + " / " + this.notesCountData[index].SignedNotes;
  }
  getStatus(id: any): any {
    let index = this.chargesCountData.findIndex(x => x._id === id);
    let index2 = this.notesCountData.findIndex(x => x._id === id);
    if (this.chargesCountData[index].submitCharges === this.notesCountData[index2].SignedNotes) {
      return 'green';
    }
    else if (this.chargesCountData[index].submitCharges < this.notesCountData[index2].SignedNotes) {
      return 'red';
    }
    else if (this.chargesCountData[index].submitCharges > this.notesCountData[index2].SignedNotes) {
      return 'yellow';
    }
  }
  getCensusNameDate(patientRoundingSheet: any) {
    let newName = null;
    if (patientRoundingSheet.facility.pcc_timeZone) {
      newName = moment(new Date(patientRoundingSheet.name).toLocaleString('en-US', { timeZone: patientRoundingSheet.facility.pcc_timeZone })).format("MMM D, YYYY");
    }
    else if (patientRoundingSheet.facility.timeZone) {
      newName = moment(new Date(patientRoundingSheet.name).toLocaleString('en-US', { timeZone: patientRoundingSheet.facility.timeZone })).format("MMM D, YYYY");
    }
    else {
      newName = moment(new Date(patientRoundingSheet.name).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format("MMM D, YYYY");
    }
    if (!newName.includes('Invalid date')) {
      return newName;
    } else {
      return patientRoundingSheet.name;
    }
  }
  getDOS(patientRoundingSheet) {
    // console.log("getdos", patientRoundingSheet)
    if (!('pcc_timeZone' in patientRoundingSheet.facility || 'timeZone' in patientRoundingSheet.facility)) {
      patientRoundingSheet.facility.timeZone = 'America/New_York';
    }
    // console.log('pcc_timeZone' in patientRoundingSheet.facility || 'timeZone' in patientRoundingSheet.facility)
    let timezone = patientRoundingSheet.facility.pcc_timeZone ? patientRoundingSheet.facility.pcc_timeZone : patientRoundingSheet.facility.timeZone;
    let serviceDate = moment(patientRoundingSheet.date).utc().tz(timezone).format('MM/DD/YYYY')
    return serviceDate;
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
