<div class="flex flex-col gap-2"> 
    <div *ngIf="patientData?.patient_id" id="chargeCapture" style="width: 100%; background: white">
            <div style="display: flex;  width: 100%;">
                <div style="width: 100%; display: flex; align-self: center;;padding-left: 5px;">
                    <div style="font-weight: bold;font-size: 12px; width: 100%;"> {{getpatientInfo()}}
                    <br *ngIf="patientData?.cpt_id.length == 0 || patientData?.icd_id.length == 0"><span style="color: red" *ngIf="patientData?.cpt_id.length == 0 || patientData?.icd_id.length == 0">Charge are not allowed to submit due to missing CPT/ICD10</span></div>
                </div> 
                <div style="display:flex; margin-bottom: 5px; padding-right: 25px;z-index: 999;" class="gap-1" *ngIf="!company_id ">
                    <button mat-raised-button style="color: black; margin-bottom: 3px;" class="dn-button-primary"
                    (click)="prevButtonClicked()" [disabled]="currentIndex == 0"> < </button>
                    <button mat-raised-button style="color: black; margin-bottom: 3px;" class="dn-button-primary"
                    (click)="submitButtonClicked()" [disabled]="disableSubmit"
                    *ngIf="patientData.status == 'draft' && patientData.owner_id._id === _authService.currentUser._id && checkVoidCensus() && (_authService.currentUser.user_type != 6 || _authService.currentUser.user_type != global.USER_TYPE.BILLER)" >Submit</button>
                    <button mat-raised-button style="color: black; margin-bottom: 3px;" class="dn-button-primary"[disabled]="true"
                    *ngIf="!checkVoidCensus()" >Void</button>
                    <button mat-raised-button style="color: black; margin-bottom: 3px;" class="dn-button-primary"
                    (click)="nextButtonClicked()" [disabled]="currentIndex == chargesArray.length-1"> > </button>
                </div>
                <div style="display:flex; margin-bottom: 5px; padding-right: 25px;z-index: 999;" class="gap-1" *ngIf="company_id && (_authService.currentUser.user_type != 6 || _authService.currentUser.user_type != global.USER_TYPE.BILLER)">     
                    <button mat-raised-button style="color: black; margin-bottom: 3px;" class="dn-button-primary"
                    (click)="prevButtonClicked()" [disabled]="currentIndex == 0"> < </button>
                    <button mat-raised-button style="color: black; margin-bottom: 3px;" class="dn-button-primary"
                    (click)="submitButtonClicked()" [disabled]="disableSubmit"
                    *ngIf="data.adminSide && patientData.status == 'submit' && checkVoidCensus() " >Resubmit</button>
                    <button mat-raised-button style="color: black; margin-bottom: 3px;" class="dn-button-primary"[disabled]="true"
                    *ngIf="!checkVoidCensus()" >Void</button>
                    <button mat-raised-button style="color: black; margin-bottom: 3px;" class="dn-button-primary"
                    (click)="nextButtonClicked()" [disabled]="currentIndex == chargesArray.length-1"> > </button>
                </div>
            </div>
            <table style="border: 1px solid black;border-collapse: collapse;">
                <tr style="background-color: #cedbdf; width: 100%;font-size: 12px;">
                    <th style="width: 6%;border: 1px solid #959cb6;border-collapse: collapse;">Visit Date</th>
                    <th style="width: 17%;border: 1px solid #838691;border-collapse: collapse;">Provider</th>
                    <th style="width: 5%;border: 1px solid #959cb6;border-collapse: collapse;">CPT's</th>
                    <th style="width: 20%;border: 1px solid #959cb6;border-collapse: collapse;">CPT Description</th>
                    <th style="width: 5%;border: 1px solid #959cb6;border-collapse: collapse;">ICD's</th>
                    <th style="width: 20%;border: 1px solid #959cb6;border-collapse: collapse;">ICD10 Description</th>
                    <th style="width: 20%;border: 1px solid #959cb6;border-collapse: collapse;">Facility</th>
                </tr>
                <tr *ngFor="let i of getCodesLength()" style="border: 0em; border-color: white;font-size: 11px;">
                    <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getVisitDate(i)}}</td>
                    <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getProvider(i)}} </td>
                    <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getCPT(i)}}</td>
                    <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getCPTName(i)}}</td>
                    <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getICD(i)}}</td>
                    <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getICDName(i)}}</td>
                    <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getAddress(i)}}</td>
                </tr>
            </table>
    </div>



</div>


<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>