import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { GoogleAuthenticator2FAService } from 'src/app/services/googleAuthenticator2FA.service';

@Component({
  selector: 'app-google-authenticator',
  templateUrl: './google-authenticator.component.html',
  styleUrls: ['./google-authenticator.component.css']
})
export class GoogleAuthenticatorComponent implements OnInit {
  google2faData = {
    dataURL: ''
    };
  authcode: string = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _google2FA: GoogleAuthenticator2FAService,
    private dialogRef: MatDialogRef<GoogleAuthenticatorComponent>,
    private _toastr: ToastrService
  ) { }

  ngOnInit(): void {
    if(this.data){
      this.google2faData.dataURL = this.data.dataURL
    }
  }
  async verifyAuth() {
    const response:any = await lastValueFrom(this._google2FA.verifyGoogle2FA(this.authcode,true));
    if(response.status === 200){
      this._toastr.success(response.message,"Success");
      this.dialogRef.close({
        google_auth_enabled: true
      });
    }else{
      this._toastr.error(response.message,"Error")
    }
  }
  async closeDialog(){
    await lastValueFrom(this._google2FA.verifyGoogle2FA("", true))
    this.dialogRef.close({google_auth_enabled: false});
  }
}
