import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { API_URL } from "../../../environments/api-url";
import * as global from '../../includes/global';
import * as _ from 'lodash';
import moment from 'moment'
import { CommonService } from 'src/app/services/common.service';
@Injectable({
  providedIn: 'root'
})
export class CensusNotesServiceService {
  currentUser: any;

  constructor(
    private httpclient: HttpClient,
    private _router: Router,
    private _authService: AuthService,
    private dashboardService: DashboardService,
    private _commonService: CommonService,
  ) {
    this.currentUser = this._authService.currentUser;
  }

  getCensusChargesReport(data) {
    const clonedData = _.cloneDeep(data);
    clonedData.date_of_service.startDate = this._commonService.convertDateToStringforMoment(clonedData.date_of_service.startDate.startOf('day'), true);
    clonedData.date_of_service.endDate = this._commonService.convertDateToStringforMoment(clonedData.date_of_service.endDate.endOf('day'), true);
    const item: any = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      ...clonedData
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.CHARGE.getCensusChargesReport, { details: item }).pipe();
  }

  exportCensusReportAsExcel(data, type, companyType = "") {
    const clonedData = _.cloneDeep(data);
    clonedData.date_of_service.startDate = this._commonService.convertDateToStringforMoment(clonedData.date_of_service.startDate.startOf('day'), true);
    clonedData.date_of_service.endDate = this._commonService.convertDateToStringforMoment(clonedData.date_of_service.endDate.endOf('day'), true);
    const item: any = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      type,
      companyType,
      ...clonedData
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;

    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    return this.httpclient.post(global.url + API_URL.CHARGE.exportCensusReportAsExcel, { details: item },{
      responseType: "blob",
      reportProgress: true,
      observe: 'events',
      headers
    });
  }
  
  getCensusWoundReport(data) {
    const clonedData = _.cloneDeep(data);
    clonedData.date_of_service.startDate = this._commonService.convertDateToStringforMoment(moment(clonedData.date_of_service.startDate).startOf('day'), true, true);
    clonedData.date_of_service.endDate = this._commonService.convertDateToStringforMoment(moment(clonedData.date_of_service.endDate).endOf('day'), true, true);
    const item: any = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      ...clonedData
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.CENSUS.getCensusWoundReport, { details: item }).pipe();
  }
}
