<div class="flex flex-col" style="position: relative;">
    <mat-card class="filter-input-card flex" style="height: 53px;">
        <input type="text" [placeholder]="placeholder" [value]="value" class="filter-input-field flex-grow"
        (input)="inputChange.emit($event)"
        />
        <button mat-icon-button (click)="toggleDropdown()" *ngIf="!noDropdown">
            <mat-icon>{{dropdownActive ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
        </button>
      </mat-card>
      <mat-card 
      class="dropdown-content"
      *ngIf="dropdownActive"
      [ngStyle]="{display:dropdownActive ? 'block' : 'none'}"
      >
    <ng-content></ng-content>
    </mat-card>
</div>