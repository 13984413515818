import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as global from '../../global';
import { AuthService } from "src/app/services/auth.service";
import { API_URL } from "../../../../environments/api-url";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class TemplateExplorerService {
  currentUser: any = null;
  userInfo: any = {};


  private provider = new BehaviorSubject(null);
  public castProvider = this.provider.asObservable();

  constructor(private httpclient: HttpClient,
    private _authService: AuthService) {
    // const currentUser = localStorage.getItem('currentUser');
    const currentUser = this._authService.currentUser;
    if (currentUser) {
      // this.currentUser = JSON.parse(currentUser);
      this.currentUser = currentUser;
      if (this.currentUser) {
        this.userInfo.id = this.currentUser._id;
        this.userInfo.usertype = this.currentUser.user_type;
        this.userInfo.auth_key = this.currentUser.auth_key;
        this.userInfo.company_id = this.currentUser.company_id;
      }
    }
  }
  
  setProviderId(provider) {
    this.provider.next(provider);
  }
  
  getAssociatedDoctorsList(company = null) {
    const payload = {
      ...this.userInfo,
    };
    return this.httpclient
      .post(global.url + API_URL.USER.getAssociatedDoctorsList, { details: payload }).pipe();
  }
  
  getAssociatedDoctorsListForMA(company = null) {
    const payload = {
      ...this.userInfo,
    };
    return this.httpclient
      .post(global.url + API_URL.USER.getAssociatedDoctorsListForMA, { details: payload }).pipe();
  }
  
  getTemplatesByFacilityId(facility_id?, owner_id?, type = null, shouldDecryptText = true) {
    const payload = {
      ...this.userInfo,
      facility_id,
      owner_id,
      type,
      shouldDecryptText
    };
    return this.httpclient
      .post(global.url + API_URL.TEMPLATE.getTemplatesByFacilityId, { template: payload }).pipe();
  }
  getSystemTemplatesByFacilityId(facility_id, shouldDecryptText = true) {
    const payload = {
      ...this.userInfo,
      facility_id,
      shouldDecryptText
    };
    return this.httpclient
      .post(global.url + API_URL.TEMPLATE.getSystemTemplatesByFacilityId, { template: payload }).pipe();
  }
  getSystemDefinedTemplates(company_id = null) {
    const payload = {
      ...this.userInfo,
      company_id,
    };
    return this.httpclient
      .post(global.url + API_URL.TEMPLATE.getSystemDefinedTemplates, { template: payload }).pipe();
  }


  deleteTemplate(template_id) {
    const payload = {
      ...this.userInfo,
      template_id,
      is_deleted: 'true',
      updation_date: new Date()
    };
    return this.httpclient.post(global.url + API_URL.TEMPLATE.deleteTemplate, { template: payload }).pipe();
  }

  lockTemplate(template_id, val) {
    const payload = {
      ...this.userInfo,
      template_id,
      is_deleted: 'true',
      updation_date: new Date(),
      val
    };
    return this.httpclient.post(global.url + API_URL.TEMPLATE.lockTemplate, { template: payload }).pipe();
  }

  downloadTemplate(template_id) {
    const payload = {
      ...this.userInfo,
      email: this.currentUser.email,
      template_id,
    };
    return this.httpclient.post(global.url + '/downloadTemplate', { template: payload }, {
      responseType: 'blob'
    }).pipe();
  }

  updateItemDescription(item_id, description, item_type) {
    const payload = {
      ...this.userInfo,
      item_id,
      description,
      item_type,
      updation_date: new Date()
    };
    return this.httpclient.post(global.url + API_URL.DOCUMENT.updateItemDescription, { item: payload }).pipe();
  }
  getPro(provider_id) {
    const payload = {
      ...this.userInfo,
      provider_id
    };
    return this.httpclient.post(global.url + API_URL.DOCUMENT.getPro, { item: payload }).pipe();
  }

  getAllCPTs() {
    const payload = {
      ...this.userInfo,
    };
    return this.httpclient.post(global.url + API_URL.CPT.getCPTsForTemplates, { details: payload }).pipe();
  }
  
  getAllCompanyCPTs(companyId) {
    const payload = {
      companyId,
      ...this.userInfo,
    };
    return this.httpclient.post(global.url + API_URL.CPT.getAllCompanyCPTs, { details: payload }).pipe();
  }

  createCptTemplateRelation(template_id, CPT_ids) {
    const payload = {
      ...this.userInfo,
      template_id,
      CPT_ids
    };
    return this.httpclient.post(global.url + API_URL.CPT.createCptTemplateRelation, { details: payload }).pipe();
  }

  deleteCptTemplateRelation(template_id, CPT_ids) {
    const payload = {
      ...this.userInfo,
      template_id,
      CPT_ids
    };
    return this.httpclient.post(global.url + API_URL.CPT.deleteCptTemplateRelation, { details: payload }).pipe();
  }

  showOrHideTemplates(template_id, value, userId) {
    const payload = {
      ...this.userInfo,
      template_id,
      value,
      userId
    };
    return this.httpclient.post(global.url + API_URL.TEMPLATE.showOrHideTemplates, { details: payload }).pipe();
  }
}

