import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as global from '../global';
import {API_URL} from "../../../environments/api-url";
import {AuthService} from "../../services/auth.service";
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CensusListService {
    private filterProviderId = new BehaviorSubject<string>("");
    castFilterProviderId = this.filterProviderId.asObservable();
    private filterFacilityId = new BehaviorSubject<string>("");
    castFilterFacilityId = this.filterFacilityId.asObservable();
    
    currentUser: any;
    constructor(private httpclient: HttpClient,
                private authService: AuthService) {
        this.currentUser = this.authService.currentUser;
    }

    changeFilterProviderId(providerId) {
        this.filterProviderId.next(providerId)
    }

    changeFilterFacilityId(facilityId) {
        this.filterFacilityId.next(facilityId)
    }

    addPatientRoundingSheet(details) {
        details.company_id = this.currentUser.company_id;
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.addPatientRoundingSheet, {details}).pipe()
    }

    updatePatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.updatePatientRoundingSheet, {details}).pipe()
    }

    deletePatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.deletePatientRoundingSheet, {details}).pipe()
    }

    getPatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getPatientRoundingSheet, {details}).pipe()
    }

    getPatientRoundingSheets(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getPatientRoundingSheets, {details}).pipe()
    }
    getCensusList(facility_id?,provider_id?, limit?, only_pcc = false) {
        const details = {
            ...this.authService.authObject,

            facility_id,
            provider_id,
            limit,
            only_pcc,
            company_id: this.currentUser.company_id,
        }
        return this.httpclient.post(global.url + API_URL.CENSUS.getCensusList, {details}).pipe()
    }
    getCensusListWithNotesAndChargesCount(facility_id?,provider_id?, limit?, only_pcc = false) {
        const details = {
            ...this.authService.authObject,

            facility_id,
            provider_id,
            limit,
            only_pcc,
            company_id: this.currentUser.company_id,
        }
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getCensusListWithNotesAndChargesCount, {details}).pipe()
    }
    getRehabDocs(census_id) {
        const details = {
            ...this.authService.authObject,

            census_id, 
        }
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getRehabDocs, {details}).pipe()
    }
    getRehabDocsCount(census_id) {
        const details = {
            ...this.authService.authObject,

            census_id, 
        }
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getRehabDocsCount, {details}).pipe()
    }
    addPatientToPatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.addPatientToPatientRoundingSheet, {details}).pipe()
    }

    removePatientFromPatientRoundingSheet(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.removePatientFromPatientRoundingSheet, {details}).pipe()
    }

    updatePatientRoundingSheetSeen(details) {
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.updatePatientRoundingSheetSeen, {details}).pipe()
    }
    getChargeCountData(details) {
        return this.httpclient.post(global.url + API_URL.CHARGE.getChargesCount, {details}).pipe();
    }
    getsavedSignedCount(details){
         return this.httpclient.post(global.url + API_URL.NOTE.getsavedSignedCount, {details}).pipe();
    }

    // charge services
    addCharge(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.addCharge,
                {details: details});
    }
    getCharges(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getCharges,
                {details: details});
    }
    getCharge(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getCharge,
                {details: details});
    }
    addICD(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.addICD,
                {details: details});
    }
    getICDs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getICDs,
                {details: details});
    }
    getCPTs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getCPTs,
                {details: details});
    }
    setFavoriteICDs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.setFavoriteICDs,
                {details: details});
    }
    setFavoriteCPTs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.setFavoriteCPTs,
                {details: details});
    }
    getFavoriteICDs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getFavoriteICDs,
                {details: details});
    }
    getFavoriteCPTs(data = {}) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            ...data
        };
        return this.httpclient
            .post(global.url + API_URL.CHARGE.getFavoriteCPTs,
                {details: details});
    }
}
