import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { LoginService } from '../login/login.service';
import * as global from '../../includes/global';


@Component({
  selector: 'app-business-officer-header',
  templateUrl: './business-officer-header.component.html',
  styleUrls: ['./business-officer-header.component.css']
})
export class BusinessOfficerHeaderComponent implements OnInit {
  currentUser: any;
  menus = [];
  updatedUserNameSubscription: any;
  defaultImg: string;
  global = global;
  companylogoPath: any;
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private commonService: CommonService,
    public loginService: LoginService,
  ) {
    this.currentUser = this._authService.currentUser;
    this.menus = [
      { link: ['/patients'], title: 'Patients', activeUrls: ['/patients', '/patient-add', '/patient-detail'] },
      { link: ['/census'], title: 'Census', activeUrls: ['/census'] },
    ];
  }

  async ngOnInit() {
    await this.getCompanyLogo();
    if (this.currentUser.user_type != global.USER_TYPE.BUSINESS_OFFICER) {
      localStorage.removeItem('currentUser');
      setTimeout(() => {
        window.location.href = '/login';
      });
    }
    this.updatedUserNameSubscription = this.commonService.castUpdatedUsername.subscribe((name: any) => {
      this.currentUser.first_name = name.first_name;
      this.currentUser.last_name = name.last_name;
      this.currentUser.title = name.title;
    });
  }

  async getCompanyLogo() {
    this.loginService.getCompanyLogo(null, this.currentUser.company_id).subscribe((res: any) => {
      if (res.status = 200) {
        if (res.data[0].logo_aws_path) {
          this.companylogoPath = res.data[0].logo_aws_path;
        } else {
          this.defaultImg = "../../../assets/img/DN.png";
        }
      } else {
        this.defaultImg = "../../../assets/img/DN.png";
      }
    });
  }

  ngOnDestroy(): void {
    if (this.updatedUserNameSubscription) {
      this.updatedUserNameSubscription.unsubscribe();
    }
  }
  isActive(menu) {
    const currentUrl = this._router.url;
    for (let url of menu.activeUrls) {
      if ((currentUrl.indexOf(url) !== -1)) {
        return true;
      }
    }
    return false;
  }
  navigateToURL(menu) {
    return this._router.navigate(menu.link)
  }
  logout() {
    localStorage.removeItem('currentUser');
    setTimeout(() => {
      window.location.href = '/login';
    });
  }


}
