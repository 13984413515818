<div *ngIf="facility" class="flex items-center justify-start gap-2">
    <img src="../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacility(facility)" class="pcc-icon" alt="">
    <img src="../../../assets/icons/matrix.png" *ngIf="isMatrixFacility(facility)" 
    style="height: 12px;
        width: 20px;  
        margin-right: 5px;
        vertical-align: middle;" alt="">
    <img src="../../../assets/img/DN.png" *ngIf="isDNFacility(facility)" class="dn-icon" alt="">
    <span class="title">{{facility.title}}</span>
    <ng-container *ngIf="loginStatus && isLoggedIntoPCC() && isPCCFacility(facility) && isCensusTab">
        <div id="loggedin" style="color: #97cc04; font-size: 12px;" *ngIf="isLoggedInFacility(facility)">&nbsp;(Logged In)</div>
        <div id="loggedout" style=" color: #999; font-size: 12px;" *ngIf="!isLoggedInFacility(facility)">&nbsp;(Logged Out)</div>
    </ng-container>
</div>