<h1 mat-dialog-title>Add Note Type</h1>
<form (submit)="title.length > 0 ? addTitle() : false" class="note-type-form">
    <div mat-dialog-content>
        <mat-form-field class="full-width">
            <input matInput placeholder="Note Type" name="title"  [(ngModel)]="title" required>
        </mat-form-field>
    </div>
    <div mat-dialog-actions class="flex justify-between items-center gap-5">
      <button mat-raised-button class="dn-button-primary" type="submit">Add</button>
      <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="reset">Cancel</button>
    </div>
</form>