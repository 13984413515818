import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { PatientAddService } from '../patient-add/patient-add-service';

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.css']
})
export class SendEmailDialogComponent implements OnInit {
  user_type: any;
  currentUser: any;

  constructor(private authService: AuthService,
    private _patientAddServie: PatientAddService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    console.log('data',this.data);
  }

  sendEmail(){
    let details : any = {};
    details.user_type = this.user_type;
    details.company_id = this.currentUser.company_id;
    details.first_name = this.data.first_name;
    details.last_name = this.data.last_name;
    details.facility_id_ref = this.data.facility_id_ref;
    this._patientAddServie.sendEmailToUsers(details).subscribe((res:any)=>{
      if(res.status == 200){
        console.log('res',res);
      }
    })
  }
}
