import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from '@angular/platform-browser';
import Cropper from "cropperjs";
import DOMPurify from 'dompurify';

interface Undo {
  mode: String,
  value: any
};
@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.css']
})
export class ImageEditorComponent implements OnInit {
  @ViewChild("image", { static: false })
  public imageElement: ElementRef;
  public imageSource: any;
  public imageDestination: any;
  scaleX = false;
  scaleY = false;
  cropFirst = false;
  undo: Undo[];
  editor: Boolean = false;
  private cropper: Cropper;
  public constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ImageEditorComponent>,
    private _sanitizer: DomSanitizer) {
    this.editor = this.data.editor;
    if(this.editor){
      this.imageSource = this.data.src.src;
    }else{
      this.imageSource = this.data.src;
    }
    this.imageDestination = "";

  }
  async ngOnInit() {
    this.undo = [];
  }
  ngAfterViewInit(): void {
    if (this.editor) {
      this.cropper = new Cropper(this.imageElement.nativeElement, {
        viewMode: 1,
        crop: async () => {
          const canvas = this.cropper.getCroppedCanvas();
          this.imageDestination = canvas.toDataURL("image/png");
        }
      });
    }
  }
  cropperMode(mode) {
    this.cropper.setDragMode(mode);
  }
  cropperZoom(value) {
    if (this.undo.length == 9) {
      this.undo.shift();
    }
    this.undo.push({
      mode: "Zoom",
      value: this.cropper.getCanvasData()
    });
    this.cropper.zoom(value);
  }
  async cropperRotate(value) {
    if (this.undo.length == 9) {
      this.undo.shift();
    }
    this.undo.push({
      mode: "Rotate",
      value: this.cropper.getImageData()
    });
    this.cropper.rotate(value);
  }
  async cropImage() {
    if (this.undo.length == 9) {
      this.undo.shift();
    }
    if (this.cropFirst == false) {
      let base = await this.getBase64(this.data.src);
      this.undo.push({
        mode: "Crop",
        value: base
      });
      this.cropFirst = true;
    } else {
      this.undo.push({
        mode: "Crop",
        value: this.imageDestination
      });
    }
    this.cropper.replace(this.imageDestination);
  }
  cropperScaleX() {
    if (this.undo.length == 9) {
      this.undo.shift();
    }
    this.undo.push({
      mode: "Scale",
      value: this.cropper.getImageData()
    });
    if (!this.scaleX) {
      this.cropper.scaleX(-1);
      this.scaleX = true;
    } else {
      this.cropper.scaleX(1);
      this.scaleX = false;
    }
  }
  cropperScaleY() {
    if (this.undo.length == 9) {
      this.undo.shift();
    }
    this.undo.push({
      mode: "Scale",
      value: this.cropper.getImageData()
    });
    if (!this.scaleY) {
      this.cropper.scaleY(-1);
      this.scaleY = true;
    } else {
      this.cropper.scaleY(1);
      this.scaleY = true;
    }
  }
  cropperClose() {
    this.cropper.clear();
  }
  cropperReset() {
    this.cropper.reset();
  }

  async doneCrop() {
    const base64Response = await fetch(this.imageDestination);
    const blob = await base64Response.blob();
    var file = new File([blob], "edit_image");
    file['src'] = DOMPurify.sanitize(window.URL.createObjectURL(blob));
    var data = {
      editedImage: file,
      edit: true
    }
    this.dialogRef.close(data);
  }
  undoMode() {
    if (this.undo.length > 0) {
      let undoTask = this.undo.pop();
      if (undoTask.mode == "Zoom") {
        this.cropper.setCanvasData(undoTask.value);
      } else if (undoTask.mode == "Crop") {
        this.cropper.replace(undoTask.value)
      } else {
        this.cropper.setData(undoTask.value);
      }
    }
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}