import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { truncate } from 'fs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { DoctorProfileService } from 'src/app/services/Modules/doctorProfile.service';
import { UserService } from 'src/app/services/Modules/user.service';

@Component({
  selector: 'app-user-permission',
  templateUrl: './user-permission.component.html',
  styleUrls: ['./user-permission.component.css']
})
export class UserPermissionComponent implements OnInit {
  allPermissions: any;
  displayedColumns: string[] = ['code', 'title', 'operation'];
  dataSource: any = new MatTableDataSource([])
  dataSourceUser: any = new MatTableDataSource([]);
  currentUser: any;
  companyId: any;


  @Input('user') user;
  constructor(private doctorProfileService: DoctorProfileService,
    private authService: AuthService,
    private companyService: CompanyService,
    private loader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private userService: UserService,
  ) {

    this.currentUser = this.authService.currentUser;
    this.companyId = this.route.parent.parent.snapshot.params.id;

  }

  async ngOnInit(): Promise<void> {

    // let filterP={
    //   title:'Soul capture',
    //   code:'SP-8',
    //   type:'User',
    //   description:'Permission to user to see in his/her soul',
    //   company_id:this.companyId
    // }

    //  await this.userService.setUserPermission(filterP).subscribe((response: any) => {
    //   if (response.status === 200) {
    //     console.log("responseee set: ", response.data);
    //   }
    // });


    console.log("userrrr", this.user);


  }

  removePermissionCode(element) {

    let previousCodes = this.user.permission;
    previousCodes = previousCodes.filter((item) => { return item != element.code })


    const data = {
      _id: this.user._id,
      permission: previousCodes
    }

    this.userService.updateUser(data).subscribe((response: any) => {
      console.log("element : remve", response.data.permission);

      this.user.permission = response.data.permission;
      this.dataSourceUser.data = this.mapCodes(this.user.permission);


    })

  }
  addPermissionCode(element) {

    let previousCodes = this.user.permission;
    previousCodes.push(element.code);

    const data = {
      _id: this.user._id,
      permission: previousCodes
    }

    this.userService.updateUser(data).subscribe((response: any) => {
      console.log("element : add", response.data.permission);

      this.user.permission = response.data.permission;
      this.dataSourceUser.data = this.mapCodes(this.user.permission);

    })


  }
  checkPermissionCode(element) {
   
    if (this.user.permission.findIndex((item) => { return item == element.code }) >= 0)
      return true;
    else
      return false;
  }

  mapCodes(codesArray) {
    return this.allPermissions.filter((item)=>{return codesArray.includes(item.code)})

  }


}
