import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewsServiceService } from './news-service.service';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.css']
})
export class NotificationBarComponent implements OnInit {
  allNews: any = [];
  news_string: any = '<ul>';
  @Output() newsExist = new EventEmitter<boolean>();
  @Input() newsString:any;
  updates: Boolean = true;
  constructor(
    private service: NewsServiceService,
  ) { }

  async ngOnInit() { 
    await this.getNews();
  }

  async getNews(){
    await this.service.getNews().subscribe((response:any)=>{
      if(response.status == 200){ 
        this.allNews = response.news;
        // 
        if(this.allNews.length > 0){ 
          this.newsExist.emit(true)
        }
        else{
          this.newsExist.emit(false)
        }
        this.allNews.forEach(element => {
          // this.news_string += '-'+ element.news + "  ";
          this.news_string += '<li>'+ element.news + "</li>";

        }); 
        this.news_string += '</ul>' 
      }
    })
  }
  
  newsChanged(){
    
    this.service.castNewsChanged.subscribe(res => {
      if(res){
        this.getNews()
      }
    })
  }

}
