import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-input-with-dropdown',
  templateUrl: './input-with-dropdown.component.html',
  styleUrls: ['./input-with-dropdown.component.css']
})
export class InputWithDropdownComponent implements OnInit, OnDestroy {
  @Input() dropdownActive = false;
  @Input() noDropdown = false;
  @Input() placeholder = '';
  @Input() value = '';
  @Output() inputChange = new EventEmitter();
  @Output() onDropdownChange = new EventEmitter();

// listenFunc will hold the function returned by "renderer.listen"
// listenFunc: Function;

// globalListenFunc will hold the function returned by "renderer.listenGlobal"
globalListenFunc: Function;

  constructor(elementRef: ElementRef, renderer: Renderer2) { 
    // We cache the function "listen" returns
    // this.listenFunc = renderer.listen(elementRef.nativeElement, 'click', (event) => {
    //     // Do something with 'event'
    // });

    if(!this.noDropdown) {
        // We cache the function "listenGlobal" returns
        this.globalListenFunc = renderer.listen('document', 'click', (event) => {
    // Do something with 'event'
    if (!this.isDescendant(elementRef.nativeElement, event.target)) {
        this.hideDropdown();
    }
});
    }
  }
  
 private isDescendant(parent, child) {
    var node = child.parentNode;

    const descendantClasses = ['cdk-overlay-container', 'mat-option', 'mat-calendar-body-cell', 'mat-button-wrapper'];
    while (node != null) {

      for (let descendantClass of descendantClasses) {
        if (node == parent || (typeof node.className === 'string' && node.className.indexOf(descendantClass) >= 0)) {
            return true;
        }
      }
        node = node.parentNode;
    }
    return false;
}

  ngOnInit() {
  }

  ngOnDestroy() {
    // Removs "listenGlobal" listener
    if(this.globalListenFunc) {
      this.globalListenFunc();
    }
  }

  showDropdown() {
    // this.dropdownActive = true;
    this.onDropdownChange.emit(true)
  }

  hideDropdown() {
    // this.dropdownActive = false;
    this.onDropdownChange.emit(false)

  }

  toggleDropdown() {
      // this.dropdownActive = !this.dropdownActive;
      this.onDropdownChange.emit(!this.dropdownActive)
  }
}
