import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as global from '../global';
import {API_URL} from "../../../environments/api-url";
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import * as _ from 'lodash';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class PatientListService {
    currentUser: any;
    company_id: any;

    constructor(private httpclient: HttpClient, private authService:AuthService,
    private commonService: CommonService
    ) {
    this.currentUser = this.authService.currentUser;

    }

    deletePatient(payload) {
        return this.httpclient
            .post(global.url + API_URL.PATIENT.deletePatient, {patient: payload}).pipe();
    }
    updatePatient(payload) {
        const clonedData = _.cloneDeep(payload);
        clonedData.lastAdmitDate = this.commonService.convertDateToStringforMoment(moment(clonedData.lastAdmitDate));

        return this.httpclient
            .post(global.url + API_URL.PATIENT.updatePatient, {patient: clonedData}).pipe();
    }
    getPatient(payload) {

        this.commonService.castCompany.subscribe(company => {
            this.company_id = company?._id
          })
      
          if(!this.company_id){
            this.company_id=this.currentUser.company_id;
          }

        let detail={
            company_id:this.company_id,
            ...payload
        }

        return this.httpclient
            .post(global.url + API_URL.PATIENT.getPatient, {patient: detail}).pipe();
    }
    getOnePatient_fromCompFacPatient(payload) {
        return this.httpclient
            .post(global.url + API_URL.PATIENT.getOnePatient_fromCompFacPatient, payload).pipe();
    }
    getPatient_Maping_population(payload) {
        return this.httpclient
            .post(global.url + API_URL.PATIENT.getPatient_Maping_population, payload).pipe();
    }
    getPatientDetail(filter, projection?, populate?) {
        const patient = {
            ...this.authService.authObject,
            filter,
            projection,
            populate
        }

        return this.httpclient
            .post(global.url + API_URL.PATIENT.getPatientDetail, {patient}).pipe();
    }

    getPatientList(payload, page=null, filter=null) { 
        if(page){
            payload.page = page;
        }
        if(filter){
            payload.filter = filter;
        }
        return this.httpclient
            .post(global.url + API_URL.PATIENT.getPatients, {patient: payload }).pipe();
    }
    getPatientsByFacilityId(payload, page=null, filter=null) { 
        if(page){
            payload.page = page;
        }
        if(filter){
            payload.filter = filter;
        }
        return this.httpclient
            .post(global.url + API_URL.PATIENT.getPatientByFacilityId, {patient: payload }).pipe();
    }
    getMatrixPatients(payload, page=null, filter=null) { 
        if(page){
            payload.page = page;
        }
        if(filter){
            payload.filter = filter;
        }
        return this.httpclient
            .post(global.url + API_URL.PATIENT.getMatrixPatients, {patient: payload }).pipe();
    }
    getMatrixPatientByFacilityId(payload, page=null, filter=null) { 
        if(page){
            payload.page = page;
        }
        if(filter){
            payload.filter = filter;
        }
        return this.httpclient
            .post(global.url + API_URL.PATIENT.getMatrixPatientByFacilityId, {patient: payload }).pipe();
    }
    getPatientsByFacilityId_v2(payload, page=null, filter=null, company) { 
        if(page){
            payload.page = page;
        }
        if(filter){
            payload.filter = filter;
        }
        if(company){
            payload.company_id = company;
        }else{
            payload.company_id =this.currentUser.company_id
        }
        return this.httpclient
            .post(global.url + API_URL.PATIENT.getPatientsByFacilityId_v2, {patient: payload }).pipe();
    }

    getPatients(payload, page=null, filter=null) { 
        if(page){
            payload.page = page;
        }
        if(filter){
            payload.filter = filter;
        }
        return this.httpclient
            .post(global.url + API_URL.PATIENT.getPatients, {patient: payload }).pipe();
    }
    getMatrixPatientForMerging(filter) { 
        const payload={
            patient:filter,
            ...this.authService.authObject
        }
        return this.httpclient
            .post(global.url + API_URL.PATIENT.getMatrixPatientForMerging, payload).pipe();
    }
    getPatientsV2(companyId, facilityId=undefined, providerId=undefined, searchText = '', dob = undefined, page = undefined, index = undefined, mrnNo = undefined, pcc_mrnNo = undefined, patient_ssn = undefined,searchByFirstName = false) { 
        const payload = {
            companyId,
            facilityId,
            providerId,
            searchText,
            dob,
            page,
            index,
            mrnNo,
            pcc_mrnNo,
            patient_ssn,
            searchByFirstName,
        }

        return this.httpclient
            .post(global.url + API_URL.PATIENT.getPatientsV2, {details: payload }).pipe();
    }
    getPatientsV2OnlyPatient(companyId, facilityId=undefined, providerId=undefined, searchText = '', dob = undefined, page = undefined, index = undefined, mrnNo = undefined, pcc_mrnNo = undefined) { 
        const payload = {
            companyId,
            facilityId,
            providerId,
            searchText,
            dob,
            page,
            index,
            mrnNo,
            pcc_mrnNo
        }

        return this.httpclient
            .post(global.url + API_URL.PATIENT.getPatientsV2OnlyPatient, {details: payload }).pipe();
    }
    getFacilityPatients(facility_id, patientName=null) {
        const patient = {
            ...this.authService.authObject,
            facility_id,
            patientName
        }

        return this.httpclient
            .post(global.url + API_URL.PATIENT.getFacilityPatients, {patient}).pipe();
    }
    associatePatients(provider, facility, selectedPatients, companyId) {
        const patient = {
            ...this.authService.authObject,
            provider,
            facility,
            selectedPatients,
            companyId
        }

        return this.httpclient
            .post(global.url + API_URL.PATIENT.associatePatients, {patient}).pipe();
    }
    getAllPatients(payload) {
        return this.httpclient.post(global.url + API_URL.PATIENT.getAllPatients, {patient: payload});
    }
    getProviders(company_id = null, facility_id = null){
      const details = {
        ...this.authService.authObject,
        company_id: company_id, 
        facility_id: facility_id
      };
      return this.httpclient.post(global.url + API_URL.FACILITY.getAllProvidersAssociatedToFacility, {details: details});
    }
    getInsuranceInfo(patient_id){
      const details = {
        ...this.authService.authObject,
        patient_id: patient_id, 
      };
      return this.httpclient.post(global.url + API_URL.PATIENT.getInsuranceInfo, {details: details});
    }
    getPatientFromCompanyFacilityPatient(filter, project, pageNo = 0, dateRangeFilter = null) {
        return this.httpclient.post(global.url + API_URL.PATIENT.getPatientFromCompanyFacilityPatient, { filter, project, pageNo, dateRangeFilter });
    }
    exportPatients(patientIds) {
        const details = {
          ...this.authService.authObject,
          patientIds
        };
        return this.httpclient.post(global.url + API_URL.PATIENT.exportPatients, details);
    }
}
