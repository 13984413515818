export const pusherChannels = {
    PCC_WEBHOOK_NOTIFICATION_CHANNEL: 'PCC_WEBHOOK_NOTIFICATION_CHANNEL',
    userTypeNotification:'USER_TYPE_NOTIFICATION'
}
export const releaseChannels = {
    NEW_RELEASE_CHANNEL: 'NEW_RELEASE_CHANNEL',

}

export const eventTypes = {
        // ADT01:
        PATIENT_ADMIT:'patient.admit',
        PATIENT_READMIT:'patient.readmit',
        PATIENT_CANCELADMIT:'patient.cancelAdmit',
        PATIENT_DISCHARGE:'patient.discharge',
        PATIENT_CANCELDISCHARGE:'patient.cancelDischarge',
        PATIENT_TRANSFER:'patient.transfer',
        PATIENT_CANCELTRANSFER:'patient.cancelTransfer',
        PATIENT_UPDATERESIDENTINFO:'patient.updateResidentInfo',
    
        // ADT02
        PATIENT_LEAVE:'patient.leave',
        PATIENT_RETURNFROMLEAVE:'patient.returnFromLeave',
        PATIENT_CANCELLEAVE:'patient.cancelLeave',
        PATIENT_CANCELRETURNFROMLEAVE:'patient.cancelReturnFromLeave',
}