import * as _ from 'lodash';
import * as moment from 'moment-timezone';
export interface IPatient {
  _id: string,
  first_name: string,
  middle_name: string,
  last_name: string,
  date_of_birth: string,
  gender: string,
  facility_id: string | any,
  facility_id_ref: string | any,
  primary_doctor_id: string,
  doctor_id_ref: string | any,
  is_active: 'true' | 'false',
  address: string,

  appointment_status: '' | 'no_show' | 'seen' | 'cancel',

  email: string,

  phone: string,

  // pcc fields
  last_admit_date_obj: any;
  last_discharge_date_obj: any;
  last_room_num: Date;
  last_admit_date: Date;
  last_discharge_date: Date;
  adtRecords: any[],
  pcc_payload: any,
  matrix_payload: any,
  pcc_coverage_data: any,
  pcc_patientId: string, // field added from pcc
  pcc_primaryPractitioner: any, // field added from pcc
  pcc_primaryDiagnosis: any, // field added from pcc
  source: string // value can be 'portal'|'pcc', is the info that tells from where patient is added

  patient_id: string;
  name: string;
  payorInformation:any;
  insurances:any;
}

export class Patient implements IPatient {
  _id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  date_of_birth: string;
  date_of_birth_d: string;
  gender: string;
  facility_id: string | any;
  facility_id_ref: any;
  primary_doctor_id: string;
  doctor_id_ref: any;
  is_active: 'true' | 'false';
  address: string;
  appointment_status: '' | 'no_show' | 'seen' | 'cancel';
  email: string;
  phone: string;
  adtRecords: any[];
  adtRecord: any;
  last_admit_date_obj: any;
  last_discharge_date_obj: any;
  last_room_num: Date;
  last_admit_date: Date;
  last_discharge_date: Date;
  last_patient_status: Record<string, {
    facility_id: string,
    pcc_facId: string,
    status: string,
  }>
  pcc_payload: any;
  matrix_payload
  pcc_coverage_data: any;
  pcc_patientId: string;
  pcc_primaryPractitioner: any;
  pcc_primaryDiagnosis: any;
  source: string;

  patient_id: string;
  name: string;
  payorInformation: any;
  insurances: any;
  non_pcc_payerName: string;

  constructor(patient) {
    for (const key in patient) {
      if (Object.prototype.hasOwnProperty.call(patient, key)) {
        const element = patient[key];
        this[key] = element;
      }
    }
  }
  isPCCPatient() {
    return !!this.pcc_patientId;
  }
  hasADTRecords() {
    if (_.isArray(this.adtRecords)) {
      return this.adtRecords.length > 0;
    }
    return false;
  }
  getRecentPatientADTRecord() {
    return this.adtRecords[0];
  }
  getAdmissionDate() {
    // return this.last_admission_date || this.pcc_payload?.admissionDate
    if (this.last_admit_date_obj) {
      let year = this.last_admit_date_obj.year;
      let month = this.last_admit_date_obj.month;
      let day = this.last_admit_date_obj.day;
      if (month < 10) { month = '0' + month; }
      if (day < 10) { day = '0' + day; }
      return (month + '/' + day + '/' + year);
    } else if (this.last_admit_date) {
      return moment.tz(this.last_admit_date, "UTC").format('MM/DD/YYYY');
    } else {
      return this.pcc_payload?.admissionDate ? moment.tz(this.pcc_payload?.admissionDate, "UTC").format('MM/DD/YYYY') : '';
    }
  }
  getDischargeDate() {
    // return this.adtRecords?.find(adt => adt.actionCode === 'DD' || adt.actionCode === 'DHOME')?.effectiveDateTime;
    // return this.last_discharge_date || this.pcc_payload?.dischargeDate;
    // return this.last_discharge_date;
    if (this.last_discharge_date_obj) {
      let year = this.last_discharge_date_obj.year;
      let month = this.last_discharge_date_obj.month;
      let day = this.last_discharge_date_obj.day;
      if (month < 10) { month = '0' + month; }
      if (day < 10) { day = '0' + day; }
      return (month + '/' + day + '/' + year);
    } else if (this.last_discharge_date) {
      return moment.tz(this.last_discharge_date, "UTC").format('MM/DD/YYYY');
    } else {
      return this.pcc_payload?.dischargeDate ? moment.tz(this.pcc_payload?.dischargeDate, "UTC").format('MM/DD/YYYY') : '';
    }
  }

  getPatientStatus(facility) {
    let lastPatientStatusForFacility;

    if (Array.isArray(this.last_patient_status) && this.last_patient_status.length > 0) {
      // find the facility object
      lastPatientStatusForFacility = this.last_patient_status.find((item) => item.facility_id.toString() === facility._id.toString());
    }
    if (lastPatientStatusForFacility) {
      return lastPatientStatusForFacility.status;
    } else {
      return false;
    }
  }
  getPatientLocation(this) {
    if (this.last_room_num) return this.last_room_num;
    const adt = this.adtRecord;
    const pcc_payload = this.pcc_payload;
    let location = '';
    if (this?.source == 'PointClickCare') {
      if (pcc_payload && pcc_payload.floorDesc) {
        if (pcc_payload.floorDesc) {
          location += pcc_payload.floorDesc;
        }
        if (pcc_payload.roomDesc) {
          location += ' ' + pcc_payload.roomDesc;
        }
        if (pcc_payload.bedDesc) {
          location += ' - ' + pcc_payload.bedDesc;
        }
      } else if (adt) {
        if (adt.floorDesc) {
          location += adt.floorDesc;
        }
        if (adt.roomDesc) {
          location += ' ' + adt.roomDesc;
        }
        if (adt.bedDesc) {
          location += ' - ' + adt.bedDesc;
        }
      }
    }
    else {
      if (this?.non_pcc_payload) {
        location = this?.non_pcc_payload?.room_no || '';
      }
    }
    return location;
  }
  getPrimaryAndSecondaryMatrixPayors(patient) {

    if (!patient || !patient.matrix_payload || !patient.matrix_payload.coverages || patient.matrix_payload.coverages.length === 0) {
      return {
        primary_payor: (patient && patient.insurances && patient.insurances.p_insurance_carrier) ? patient.insurances.p_insurance_carrier : '',
        secondary_payor: ''
      };
    }

    const primaryCoverage = patient.matrix_payload.coverages.find(coverage => coverage.insurance_type === 'primary');
    const secondaryCoverage = patient.matrix_payload.coverages.find(coverage => coverage.insurance_type !== 'primary');

    return {
      primary_payor: (primaryCoverage && primaryCoverage.insurance_company_name) ? primaryCoverage.insurance_company_name : (patient.matrix_payload.coverages[0] && patient.matrix_payload.coverages[0].insurance_company_name) ? patient.matrix_payload.coverages[0].insurance_company_name : '',
      secondary_payor: (secondaryCoverage && secondaryCoverage.insurance_company_name) ? secondaryCoverage.insurance_company_name : ''
    };
  }
  getPayor(rank: 'primary'|'secondary' = 'primary') {
    switch (this.source) {
        case "PointClickCare":
            return this.getPointClickCarePayor(rank);
        case "MatrixCare":
            return this.getMatrixCarePayor(rank);
        default:
        if (rank === 'primary') {
          if (this.payorInformation && this.payorInformation.p_insurance_carrier) {
            return this.payorInformation?.p_insurance_carrier ? this.payorInformation?.p_insurance_carrier : this.non_pcc_payerName || 'N/A';
          } else {
            return this.insurances?.p_insurance_carrier ? this.insurances?.p_insurance_carrier : this.non_pcc_payerName || 'N/A';
          }
        } else if (rank === 'secondary') {
          if (this.payorInformation && this.payorInformation.s_insurance_carrier) {
            return this.payorInformation?.s_insurance_carrier || 'N/A';
          } else {
            return this.insurances?.s_insurance_carrier ? this.insurances?.s_insurance_carrier : 'N/A';
          }
        }
    }
}

getPointClickCarePayor(rank: 'primary' | 'secondary') {
  const rankType = rank === 'primary' ? 'Primary' : 'Secondary';
  const pccCoverageData = this.pcc_coverage_data;
  const adtRecord = this.adtRecord;
  if (pccCoverageData) {
    if (Array.isArray(pccCoverageData.payers) && pccCoverageData.payers.length > 0) {
      const primaryPayor = pccCoverageData.payers.find(
        (payer: any) => payer && typeof payer.payerRank === 'string' && payer.payerRank === rankType
      );
      if (primaryPayor && typeof primaryPayor.payerName === 'string') {
        return primaryPayor.payerName;
      }
    }
    if (pccCoverageData[rankType] && typeof pccCoverageData[rankType].payerName === 'string') {
      return pccCoverageData[rankType].payerName;
    }
  }
  if (adtRecord && rankType == 'Primary' && typeof adtRecord.payerName === 'string') {
    return adtRecord.payerName;
  }
  return "N/A";
}


getMatrixCarePayor(rank: 'primary' | 'secondary') {    
if (this.matrix_payload?.coverages?.length > 0) {
    if (rank === 'primary') {
        const primaryCoverage = this.matrix_payload.coverages.find(
            (coverage) => coverage.insurance_type === rank
        );
        return primaryCoverage ? primaryCoverage.insurance_company_name : this.matrix_payload.coverages[0].insurance_company_name;
    } else {
        for (const coverage of this.matrix_payload.coverages) {
            if (coverage.insurance_type !== 'primary') {
                return coverage.insurance_company_name;
            }
        }
        return 'N/A';
    }
} 

if (rank === 'primary' && this.insurances) {
    return this.insurances.p_insurance_carrier;
}

return 'N/A';
}
  getSecondaryPayor() {
    if (this.pcc_coverage_data?.payers) {
      if (Array.isArray(this.pcc_coverage_data?.payers)) {
        const primaryPayor = this.pcc_coverage_data.payers.find(
          (payer) => payer.payerRank === "Secondary"
        );
        if (primaryPayor) {
          return primaryPayor.payerName;
        }
      } else {
        return "";
      }
    } else {
      const payer = this.pcc_coverage_data["Secondary"];
      if (payer) {
        return payer.payerName;
      }
      return "N/A";
    }
  }


}

export const insured_patient_relation = [
  { id: '18', value: 'Self' },
  { id: '01', value: 'Spouse' },
  { id: '19', value: 'Child' },
  { id: '20', value: 'Employee' },
  { id: '21', value: 'Unknown' },
  { id: '39', value: 'Organ Donor' },
  { id: '40', value: 'Cadaver Dono' },
  { id: '53', value: 'Life Partner ' },
  { id: 'G8', value: 'Other Relationship' },
]
export const insurance_order = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: 5 }
]
