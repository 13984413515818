<div class="mat-dialog-header flex justify-end items-start">

    <div class="flex justify-center gap-8">
        <div class="pagination flex justify-end items-center">
        </div>
        <span class="material-symbols-outlined mat-dialog-close-icon" (click)="closeDialog()">cancel</span>
    </div>
</div>
<div>
    <h5 style="border-bottom: 1px solid #a8a8a8; padding-bottom: 5px;">Setup TFA</h5>
    <span style="text-align: center;display: flex;justify-content: center;align-items: center;flex-direction: column;">
        <p>Scan the QR code or enter the secret key in Google Authenticator</p>
        <img [src]="google2faData.dataURL" alt="" class="img-thumbnail" style="display:block;margin:auto">
        <mat-form-field style="width: 70%;">
            <input matInput name="authcode" type="number" #iauthcode="ngModel" maxlength="6"
                placeholder="Enter the Auth Code" id="authcode" autocomplete="off" [(ngModel)]="authcode" required>
        </mat-form-field>
        <button class="theme-btn" (click)="verifyAuth()" mat-raised-button class="dn-button-primary">Verify Auth</button>
    </span>
</div>