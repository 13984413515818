import { Component, OnInit, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { FacilityAddDialog, IFacility, FacilityAddDialogData } from '../facility-add-dialog/facility-add.dialog';
import { FacilityService } from '../../../services/facility.service';
import * as usa_states from '../facility-add-dialog/usa_states';
import { PCCService } from 'src/app/services/pcc.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { CompanyService } from 'src/app/services/Modules/company.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { lastValueFrom } from 'rxjs';
import { SnfWcNurseAssociationService } from 'src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import * as global from "../../../includes/global";
export interface FacilityDialogData {
}

@Component({
    selector: 'app-facility-dialog',
    templateUrl: './facility.dialog.html',
    styleUrls: ['./facility.dialog.css']
})
export class FacilityDialog implements OnInit {
    loading = false;
    usa_states = usa_states.default;
    displayedColumns: string[] = [];
    dataSource: any = new MatTableDataSource([]);
    facilities: Array<IFacility> = [];
    currentUser: any;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;

    constructor(
        private facilityService: FacilityService,
        public _authService: AuthService,
        private userService: UserService,
        private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
        public pccService: PCCService
    ) {
        this.displayedColumns = ['facility', 'is_active', 'address', 'city', 'state', 'zip_code', 'note_email'];
        this.currentUser = this._authService.currentUser
    }

    getCheckStatus(element) {
        if (this.currentUser?.notes_email_facilities) {
            if (this.currentUser?.notes_email_facilities?.findIndex((e) => { return e?.toString() == element?._id?.toString() }) >= 0) {
                return true;
            }
        }
        return false;
    }

    ngOnInit() {
        this.initFacilities();
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.dataSource.filterPredicate = function (data, filter: string): boolean {
            return data.title.toLowerCase().includes(filter);
        };
    }
    async onNoteEmailStatus(ele, $event) {
        let notes_email_facilities = this.currentUser?.notes_email_facilities || [];
        notes_email_facilities = notes_email_facilities.filter((e) => { return e?.toString() != ele?._id?.toString() });

        if ($event.checked) {
            notes_email_facilities.push(ele?._id);
        }
        this.currentUser.notes_email_facilities = notes_email_facilities;

        let response: any = await this.userService.saveNoteEmailSettings(notes_email_facilities).toPromise()
        if (response.status == 200) {
            let notes_email_facilities = response.data.notes_email_facilities;
            this._authService.currentUser = this.currentUser = {
                ...this.currentUser,
                notes_email_facilities: notes_email_facilities
            }
        }
    }




    async initFacilities() {
        this.loading = true;
        if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
            const filter = {
                associated_snf_wc_nurse_ids: this.currentUser._id,
            };
            const nurseProjection = {
                first_name: 1,
                last_name: 1,
                title: 1,
            };
            const facilityProjection = {
                title: 1,
                pcc_facId: 1,
                source: 1,
                address: 1,
                city: 1,
                zip_code: 1,
                state: 1,
                email_notification_status: 1,
                email_notification_ids: 1,
                notes_email_facilities: 1,
                is_active: 1,
                pcc_2_legged_authentication: 1
            };
            const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
            if (response.status === 200) {
                this.populateData(response.data);
            }
            this.loading = false;
        }
        else {
            this.facilityService.getMyFacilities(null, null, true).subscribe((response: any) => {
                if (response.status === 200) {
                    this.populateData(response.data.array);
                }
                this.loading = false;
            });
        }
    }

    populateData(facilities) {
        this.facilities = facilities;
        this.dataSource.data = this.facilities;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    onDeleteClick(facility: IFacility) {
        if (!confirm('Are you sure you want to delete?')) {
            return;
        }

        this.loading = true;
        this.facilityService.deleteFacility(facility).subscribe((response: any) => {
            if (response.status === 200) {
                this.initFacilities();
            }
        })
    }

    importPCCFacilities() {
        this.loading = true;
        this.pccService.importPCCFacilities().subscribe((response: any) => {
            if (response.status === 200) {
                this.initFacilities();
            }
        }, () => { }, () => { this.loading = false })
    }

    isImportFacilitiesButtonVisible() {
        if (this.currentUser.has_pcc_account == 'true') {
            if (this.currentUser.pcc_auth && this.currentUser.pcc_auth.token) {
                return true;
            }
        }
        return false;
    }
}
