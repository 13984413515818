import { Component, OnInit, Input } from '@angular/core';
import { PCCService } from 'src/app/services/pcc.service';

@Component({
  selector: 'app-facility-title',
  templateUrl: './facility-title.component.html',
  styleUrls: ['./facility-title.component.css'],
})
export class FacilityTitleComponent implements OnInit {
  @Input('loginStatus') loginStatus = true;
  @Input('facility') facility;
  @Input('defaultSource') defaultSource:string;
  @Input('isCensusTab') isCensusTab: boolean;
  // pccDefaultFac;
  pccUserInfo;

  constructor(
    private pccService:PCCService
  ) {}

  ngOnInit(): void {
    this.pccService.pccUserinfo$.subscribe(pccUserInfo => {
      this.pccUserInfo = pccUserInfo;
    });
    // this.pccService.defaultFac$.subscribe(pccDefaultFac => {
    //   this.pccDefaultFac = pccDefaultFac;
    // });
  }

  isPCCFacility(facility) {
      return facility.source === 'PointClickCare' || facility.pcc_facId || this.defaultSource === 'PointClickCare';
  }
  isMatrixFacility(facility) {
      return facility.source === 'MatrixCare';
  }
  isDNFacility(facility) {
    return facility.source != 'PointClickCare' && facility.source != 'MatrixCare' && !facility.pcc_facId && this.defaultSource != 'PointClickCare'&& facility.source != 'All';
  }
  
  isLoggedInFacility(facility:any) {
    return this.pccService.isLoggedInFacility(facility)
  }

  isLoggedIntoPCC() {
    return !!this.pccUserInfo;
  }
}
