import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as global from '../../global';
import moment from 'moment'
import { AuthService } from "src/app/services/auth.service";

@Injectable({
    providedIn: 'root',
  })
  export class FileExplorerService {
    currentUser:any=null;
    userInfo:any = {};
    
    constructor(private httpclient: HttpClient,
      private _authService: AuthService) {
      const currentUser = this._authService.currentUser;
      if(currentUser) {
        this.currentUser = currentUser;
        
        if(this.currentUser) {
          this.userInfo.id = this.currentUser._id;
          this.userInfo.usertype = this.currentUser.user_type;
          this.userInfo.auth_key = this.currentUser.auth_key;
        }
      }
    }
    getFilesAndFoldersByParentFolderId(parent_folder_id) {
      const payload = {
        ...this.userInfo,
        parent_folder_id
      };
      return this.httpclient.post(global.url_documents + '/getFilesAndFoldersByParentFolderId', {payload: payload}).pipe();
    }
    getSharedFilesAndFolders(email) {
      const payload = {
        ...this.userInfo,
        email
      };
      return this.httpclient.post(global.url_documents + '/getSharedFilesAndFolders', {payload: payload}).pipe();
    }

    getMyFilesAndFolders(parent_folder_id) {
      const payload = {
        ...this.userInfo,
        parent_folder_id
      };
      return this.httpclient.post(global.url_documents + '/getMyFilesAndFolders', {payload: payload}).pipe();
    }

    getFolders(parent_folder_id) {
      const payload = {
        ...this.userInfo,
        parent_folder_id
      };
      return this.httpclient.post(global.url_documents + '/getMyFolder', {folder: payload}).pipe();
    }

    // getSharedFolders(email) {
    //   const payload = {
    //     ...this.userInfo,
    //     email
    //   };
    //   return this.httpclient.post(global.url_documents + '/getSharedFolder', {folder: payload}).pipe();
    // }


    getFiles(parent_folder_id) {
      const payload = {
        ...this.userInfo,
        parent_folder_id
      };
      return this.httpclient.post(global.url_documents + '/getMyFiles', {folder: payload}).pipe();
    }

    // getSharedFiles(email) {
    //   const payload = {
    //     ...this.userInfo,
    //     email
    //   };
    //   return this.httpclient.post(global.url_documents + '/getSharedFiles', {folder: payload}).pipe();
    // }

    createNewFolder(folder) {
      const payload = {
        ...this.userInfo,
        ...folder,
        drive_name: 'drive-rrgen'
      };
      return this.httpclient.post(global.url_documents + '/createFolder', {folder: payload}).pipe();
    }

    // deleteFolder(folder_id) {
    //   const payload = {
    //     ...this.userInfo,
    //     folder_id,
    //     is_deleted: 'true',
    //     updation_date: new Date()
    //   };
    //   return this.httpclient.post(global.url_documents + '/deleteRestoreFolder', {folder: payload}).pipe();
    // }

    // deleteFile(file_id) {
    //   const payload = {
    //     ...this.userInfo,
    //     file_id,
    //     is_deleted: 'true',
    //     updation_date: new Date()
    //   };
    //   return this.httpclient.post(global.url_documents + '/deleteRestoreFile', {file: payload}).pipe();
    // }
    deleteItem(item_id, item_type) {
      const payload = {
        ...this.userInfo,
        item_id,
        item_type,
        is_deleted: 'true',
        updation_date: moment(new Date()).format(global.moment_date_time_format)
      };
      return this.httpclient.post(global.url_documents + '/deleteRestoreItem', {item: payload}).pipe();
    }

    downloadFile(file_id) {
      const payload = {
        ...this.userInfo,
        email:this.currentUser.email,
        file_id,
      };
      return this.httpclient.post(global.url_documents + '/downloadFile', {file: payload},{
        responseType: 'blob'
      }).pipe();
    }
    downLoadAnyFile(file_path,file_name){
      const payload = {
        ...this.userInfo,
        file_path,
        file_name
      };
      return this.httpclient.post(global.url_documents + '/downLoadAnyFile', {details: payload},{
        responseType: 'blob'
      }).pipe();
    }
    
    downLoadAnyFileWithURL(file_path,file_name,patient_id){
      const payload = {
        ...this.userInfo,
        file_path,
        file_name,
        patient_id
      };
      return this.httpclient.post(global.url_documents + '/downLoadAnyFileWithURL', {details: payload},{
        responseType: 'blob'
      }).pipe();
    }
    renameFolder(folder_id, title) {
      const payload = {
        ...this.userInfo,
        folder_id,
        title,
        updation_date: moment(new Date()).format(global.moment_date_time_format)
      };
      return this.httpclient.post(global.url_documents + '/changeFolderTitle', {folder: payload}).pipe();
    }

    updateItemDescription(item_id, description, item_type) {
      const payload = {
        ...this.userInfo,
        item_id,
        description,
        item_type,
        updation_date: moment(new Date()).format(global.moment_date_time_format)
      };
      return this.httpclient.post(global.url_documents + '/updateItemDescription', {item: payload}).pipe();
    }
    updateFileDescription(file_id, description) {
      const payload = {
        ...this.userInfo,
        file_id,
        description,
        updation_date: moment(new Date()).format(global.moment_date_time_format)
      };
      return this.httpclient.post(global.url_documents + '/updateFileDescription', {file: payload}).pipe();
    }
    uploadFile(file, fileBlob) {
      // return new Promise((resolve, reject) => {
          const payload = {
            ...this.userInfo,
            ...file
          };
          const formData: any = new FormData();
          // const xhr = new XMLHttpRequest();
          formData.append('file',fileBlob);
          formData.append('drive_name','drive-main');

          for(let key of Object.keys(payload)) {
            formData.append(key, payload[key]);
          }
          // xhr.onreadystatechange = function () {
          //     if (xhr.readyState === 4) {
          //         if (xhr.status === 200) {
          //             resolve(JSON.parse(xhr.response));
          //         } else {
          //             reject(xhr.response);
          //         }
          //     }
          // };
          // xhr.open('POST', global.url_documents + '/uploadFile', true);
          // xhr.withCredentials = true;
          // const token = this._authService.getToken();
          // xhr.setRequestHeader('Authorization', `Bearer ${token}`);
          // xhr.send(formData);

          return this.httpclient.post(global.url_documents + '/uploadFile', formData);
      // });
    }

    // shareFile = (file_id, emails) => {
    //   const payload = {
    //     ...this.userInfo,
    //     file_id,
    //     shared_by_email: emails,
    //     updation_date:new Date()
    //   };
    //   return this.httpclient.post(global.url_documents + '/shareFile', {file: payload}).pipe();
    // }

    // shareFolder = (folder_id, emails) => {
    //   const payload = {
    //     ...this.userInfo,
    //     folder_id,
    //     shared_by_email: emails,
    //     updation_date:new Date()
    //   };
    //   return this.httpclient.post(global.url_documents + '/shareFolder', {folder: payload}).pipe();
    // }

    shareItem = (item_id, emails, item_type) => {
      const payload = {
        ...this.userInfo,
        item_id,
        item_type,
        shared_by_email: emails,
        updation_date:moment(new Date()).format(global.moment_date_time_format)
      };
      return this.httpclient.post(global.url_documents + '/shareItem', {item: payload}).pipe();
    }

    changeParentFolderId = (item_id, parent_folder_id, item_type) => {
      const payload = {
        ...this.userInfo,
        item_id,
        item_type,
        parent_folder_id,
        updation_date:moment(new Date()).format(global.moment_date_time_format)
      };
      return this.httpclient.post(global.url_documents + '/changeParentFolderId', {item: payload}).pipe();
    }

    getDriveActivity = (item_id) => {
      const payload = {
        ...this.userInfo,
        item_id
      };
      return this.httpclient.post(global.url_documents + '/getDriveActivity', {drive: payload}).pipe();
    }
  }