import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatSelectModule } from "@angular/material/select";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MatNativeDateModule, MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
// import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
const config = {
    // animationType: ngxLoadingAnimationTypes.circle,
    primaryColour: '#ffffff',
    secondaryColour: '#ccc',
    tertiaryColour: '#ffffff',
    backdropBorderRadius: '3px',
};
import { OverlayModule } from '@angular/cdk/overlay';

// import { AmazonMedicalTranscribeComponent } from './amazon-medical-transcribe/amazon-medical-transcribe/amazon-medical-transcribe.component';
// import { SidebarVideoCallComponent } from './sidebar-videoCall/sidebar-video-call/sidebar-video-call.component';
// import { CommentsComponent } from './sidebar-addCharges/comments/comments/comments.component';

// import { AddSignatureComponent } from './e-sign/add-signature/add-signature/add-signature.component'; 

@NgModule({
    declarations: [],
    imports: [
        MatAutocompleteModule,
        MatPaginatorModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatCardModule,
        MatButtonModule,
        MatSelectModule,
        // NgxLoadingModule.forRoot(config),
        MatIconModule,
        MatTooltipModule,
        MatTabsModule,
        MatGridListModule,
        MatDatepickerModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatExpansionModule,
        MatDialogModule,
        MatListModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatDatepickerModule,
        // MatMomentDateModule,
        MatNativeDateModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatMenuModule,
        MatBottomSheetModule,
        // NgxLoadingModule.forRoot(config),
        OverlayModule
    ],
    exports: [
        // MatMomentDateModule,
        MatNativeDateModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatMenuModule,
        MatBottomSheetModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        FormsModule,
        MatSnackBarModule,
        MatInputModule,
        MatListModule,
        MatTableModule,
        MatSortModule,
        MatExpansionModule,
        MatCardModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatButtonModule,
        MatTabsModule,
        MatSelectModule,
        MatIconModule,
        MatToolbarModule,
        MatGridListModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatChipsModule,
        // NgxLoadingModule,
        OverlayModule
    ],
    providers: []
})
export class MaterialModule {
}
