
  <app-header *ngIf="!admin && !company"></app-header>
  <main>
    <div [class.container]="!admin && !company">
      <mat-card>
        <mat-card-content class="flex flex-col">

          <div class="flex justify-between items-center gap-5">
            <div class="flex flex-grow items-center gap-5">
              <h3 style="width:105px">Patient Census</h3>

              <mat-form-field *ngIf="!providerIdParam">
                <mat-label>Providers</mat-label>
                <mat-select name="provider" #provider="ngModel" [(ngModel)]="filter_provider_id"
                  (ngModelChange)="handleproviderChange(provider)">
                  <input matInput placeholder="Search" [(ngModel)]="searchTextProvider"
                    [ngModelOptions]="{standalone: true}" style="color:#000;height: 40px;padding-left: 5px;" />
                  <mat-option *ngFor="let provider of providers | searchProvider : searchTextProvider"
                    value="{{provider._id}}">
                    {{provider.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div class="flex-grow" style="position: relative;">
                <mat-form-field style="width: 100%;">
                  <mat-label>Facility</mat-label>
                  <mat-select name="facility" #facility="ngModel" [(ngModel)]="filter_facility_id"
                    (ngModelChange)="handleFilterFacilityChange()">
                    <input matInput placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                      style="color:#000;height: 40px;padding-left: 5px;" (keydown)="$event.stopPropagation()" />
                    <mat-option *ngFor="let facility of facilities | searchFacility: searchText"
                      value="{{facility._id}}">
                      <app-facility-title [facility]="facility"></app-facility-title>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <p style="font-size: 12px;color: red;position: absolute;bottom: -17px;"
                  *ngIf="is2LeggedAuthRequired(filter_facility_id)">
                  This facility requires login into PCC to view data.
                </p>
              </div>
              <ng-container *ngIf="isLoggedInFacility(selectedFacility)">
                <button class="dn-button-primary" mat-raised-button
                  (click)="initializeActiveCensus(selectedFacility._id)">
                  Initialize Active Census
                </button>
                <button class="dn-button-primary" mat-raised-button
                  [routerLink]="['/census/active/' + selectedFacility._id]">
                  View Active Census
                </button>
              </ng-container>
            </div>
            <button mat-raised-button class="dn-button-primary" *ngIf="showCreateButton()"
              (click)="showPatientRoundingSheetDialog()">Create New Patient Census</button>
          </div>
          <div style="position: relative;">
            <mat-table class="table-row-hover" [dataSource]="dataSource" *ngIf="dataSource" matSort
              (matSortChange)="sortData($event)">
              <ng-container matColumnDef="dateOfService">
                <mat-header-cell *matHeaderCellDef mat-sort-header="dateOfService">Date Of Service</mat-header-cell>
                <mat-cell *matCellDef="let patientRoundingSheet">{{getDOS(patientRoundingSheet)}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header="name">Census Name</mat-header-cell>
                <mat-cell *matCellDef="let patientRoundingSheet">{{patientRoundingSheet.name}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="facility">
                <mat-header-cell *matHeaderCellDef mat-sort-header="facility">Facility</mat-header-cell>
                <mat-cell *matCellDef="let patientRoundingSheet">
                  {{patientRoundingSheet?.facility?.title}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="date">Visit Date</mat-header-cell>
                <mat-cell *matCellDef="let patientRoundingSheet">{{patientRoundingSheet.date | date:
                  global.date_format}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="patients_count_total">
                <mat-header-cell *matHeaderCellDef mat-sort-header="patient_count">Total Patients</mat-header-cell>
                <mat-cell *matCellDef="let patientRoundingSheet">
                  {{patientRoundingSheet?.patientList?.length}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="draft_submitted_charges">
                <mat-header-cell *matHeaderCellDef>Draft/Submitted Charges</mat-header-cell>
                <mat-cell *matCellDef="let patientRoundingSheet">
                  {{patientRoundingSheet?.draftChargesCount}} / {{patientRoundingSheet?.submittedChargesCount}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="saved_signed_notes">
                <mat-header-cell *matHeaderCellDef>Saved/Signed Notes</mat-header-cell>
                <mat-cell *matCellDef="let patientRoundingSheet">
                  {{patientRoundingSheet?.savedNotesCount}} / {{patientRoundingSheet?.signedNotesCount}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let patientRoundingSheet">
                  <ng-container
                    *ngIf="patientRoundingSheet?.signedNotesCount > patientRoundingSheet?.submittedChargesCount">
                    <img width="10px" src="../../../../assets/img/red_dot.png" alt="">
                  </ng-container>
                  <ng-container
                    *ngIf="patientRoundingSheet?.submittedChargesCount === patientRoundingSheet?.signedNotesCount">
                    <img width="10px" src="../../../../assets/img/green_dot.png" alt="">
                  </ng-container>
                  <ng-container
                    *ngIf="patientRoundingSheet?.signedNotesCount &lt; patientRoundingSheet?.submittedChargesCount">
                    <img width="10px" src="../../../../assets/img/red_dot.png" alt="">
                  </ng-container>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="rehab_docs">
                <mat-header-cell *matHeaderCellDef mat-sort-header="rehab_docs_count">Rehab Docs</mat-header-cell>
                <mat-cell *matCellDef="let patientRoundingSheet" style="cursor: pointer;"
                  (click)="viewRehabDocs(patientRoundingSheet._id)">
                  {{patientRoundingSheet.rehab_docs}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="active_census">
                <mat-header-cell *matHeaderCellDef>Active Census</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                  <button *ngIf="element.facility.pcc_facId" class="dn-button-primary" mat-raised-button
                    [routerLink]="['/census/active/' + element.facility._id]">
                    Active Census
                  </button>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="operations">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let patientRoundingSheet">
                  <div class="flex gap-5 items-center"
                    *ngIf="!is2LeggedAuthRequired(patientRoundingSheet.facility._id)">
                    <i class="icon icon-view" matTooltip="View Census" matTooltipPosition="above"
                      (click)="viewClicked(patientRoundingSheet._id)"></i>

                    <mat-icon style="cursor: pointer;" matTooltip="Edit Census Name" matTooltipPosition="above"
                      *ngIf="isOwner(patientRoundingSheet) || currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT || currentUser.user_type===global.USER_TYPE.BUSINESS_OFFICER;"
                      (click)="$event.stopPropagation(); showUpdatePatientRoundingSheetDialog(patientRoundingSheet._id,patientRoundingSheet.name,patientRoundingSheet.date,patientRoundingSheet)">
                      edit</mat-icon>
                    <i class="icon icon-delete" matTooltip="Delete Census" matTooltipPosition="above"
                      *ngIf="isOwner(patientRoundingSheet)"
                      (click)="$event.stopPropagation(); deletePatientRoundingSheet(patientRoundingSheet._id)"></i>
                  </div>
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <div class="flex flex-row justify-end">
              <button mat-raised-button class="dn-button-primary" *ngIf="patientRoundingSheets.length < total"
                (click)="initPatientRoundingSheets(filter_facility_id, filter_provider_id);">Show all</button>
              <button mat-raised-button class="dn-button-primary"
                *ngIf="patientRoundingSheets.length > 0 && censusListFetchedOnce"
                (click)="initPatientRoundingSheets(filter_facility_id, filter_provider_id); censusListFetchedOnce=false">Show
                all</button>
            </div>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </main>


  <mat-card *ngIf="false" class="example-card">
    <div class="flex flex-col gap-2">
      <mat-form-field *ngIf="!providerIdParam" style="width: 100%;">
        <mat-label>Providers</mat-label>
        <mat-select name="provider" #provider="ngModel" [(ngModel)]="filter_provider_id"
          (ngModelChange)="handleproviderChange(provider)">
          <input matInput placeholder="Search" [(ngModel)]="searchTextProvider" [ngModelOptions]="{standalone: true}"
            style="color:#000;height: 40px;padding-left: 5px;" />
          <mat-option *ngFor="let provider of providers | searchProvider : searchTextProvider" value="{{provider._id}}">
            {{provider.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="width: 100%;">
        <mat-label>Facility</mat-label>
        <mat-select name="facility" #facility="ngModel" [(ngModel)]="filter_facility_id"
          (ngModelChange)="handleFilterFacilityChange()">
          <input matInput placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
            style="color:#000;height: 40px;padding-left: 5px;" (keydown)="$event.stopPropagation()" />
          <mat-option *ngFor="let facility of facilities | searchFacility: searchText" value="{{facility._id}}">
            <app-facility-title [facility]="facility"></app-facility-title>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="isLoggedInFacility(selectedFacility)">
        <button style="margin-left: auto !important;width: 100% !important;margin-bottom: 10px;"
          class="dn-button-primary" mat-raised-button (click)="initializeActiveCensus(selectedFacility._id)">
          Initialize Active Census
        </button>
        <button style="margin-left: auto !important;width: 100% !important;margin-bottom: 10px;"
          class="dn-button-primary" mat-raised-button [routerLink]="['/census/active/' + selectedFacility._id]">
          View Active Census
        </button>
      </ng-container>
      <button mat-raised-button class="dn-button-primary" *ngIf="showCreateButton()"
        style="margin-left: auto !important;width: 100% !important;" (click)="showPatientRoundingSheetDialog()">Create
        New
        Patient Census</button>

      <div
        [ngClass]="{'scroll_table_class1':isLoggedInFacility(selectedFacility),'scroll_table_class':!isLoggedInFacility(selectedFacility)}">
        <table mat-table *ngIf="dataSource" (matSortChange)="sortData($event)" [dataSource]="dataSource" matSort
          class="mat-elevation-z8">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Census Name</th>
            <td mat-cell (click)="viewClicked(patientRoundingSheet._id)" style="cursor: pointer;overflow: hidden;"
              *matCellDef="let patientRoundingSheet"><span
                [class.white-text-selected]="clickedRows.has(patientRoundingSheet)" style="display: flex;">
                <p>{{patientRoundingSheet.name}}</p>
                <mat-icon style="position: sticky;
          left: 83%;
          padding-top: 10px;width: 17px;" matTooltip="Delete Census" matTooltipPosition="above"
                  *ngIf="isOwner(patientRoundingSheet)"
                  (click)="$event.stopPropagation(); deletePatientRoundingSheet(patientRoundingSheet._id)">delete
                </mat-icon>
              </span></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr (click)="show_selected(row)" [class.demo-row-is-clicked]="clickedRows.has(row)" mat-row
            *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <div class="flex flex-row justify-end">
          <button style="margin-top: 10px;" mat-raised-button class="dn-button-primary"
            *ngIf="patientRoundingSheets.length < total"
            (click)="initPatientRoundingSheets(filter_facility_id, filter_provider_id);">Show all</button>
        </div>
      </div>

      <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    </div>
  </mat-card>