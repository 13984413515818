import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ESignService } from '../../e-sign.service';
import { ToastrService } from 'ngx-toastr'; 
import { eSign } from 'src/app/classes/e_sign';

@Component({
  selector: 'app-add-signature',
  templateUrl: './add-signature.component.html',
  styleUrls: ['./add-signature.component.css']
})
export class AddSignatureComponent implements OnInit {
  selectedProviderAdd:any = {};
  provider:any;
  public model = new eSign(); 
  loading: Boolean = false;
  // eSignService:ESignService;
  providers:any = [];
  @ViewChild('file_uploader') file_uploader: ElementRef; 
  constructor(
    public dialogRef: MatDialogRef<AddSignatureComponent>,  
    // public insuranceCompanyComponent :InsuranceCompanyComponent,
    private eSignService: ESignService,
    private toastr: ToastrService,

        @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
     
   }

  ngOnInit() { 
    this.initData();
  }

  initData(){
    this.loading = true;
    this.eSignService.getProviders().subscribe((response:any)=> { 
      if(response.status == 200){ 
        this.providers = [...response.data]
        // response.data.map(async provider => {
        //   await this.providers.push(provider)
        // })
        // this.providers = response.data;
      } 
      this.loading = false;
    })
  }
  addSignature(file){
    const [signatureFile] = file.files; 
    const provider = this.selectedProviderAdd._id;
    const signFile =  signatureFile;
    this.file_uploader.nativeElement.value = '';
    this.selectedProviderAdd  = '';
    this.eSignService.saveSignature(provider, signFile ).then((response:any) =>{
      if(response.status == 200){
        this.file_uploader.nativeElement.value = '';
        this.toastr.success('Signature added successfully', 'Success');
        this.initData();
      } 
    })
  } 

  selectProvider(provider = null){
    if(provider){
      this.selectedProviderAdd = provider;
      return provider;  
    } 
    else{
      this.selectedProviderAdd = '';
      return '';
    }
  }
}
