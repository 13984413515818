import {Injectable} from '@angular/core';
import Pusher, { Channel } from "pusher-js";

import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, Subject } from "rxjs";

import {pusherChannels as pccChannels, eventTypes as pccEventTypes, releaseChannels as releaseChannels,} from '../constants/pcc_webhook_constants';
import { AuthService } from './auth.service';

const REHAB_REPORTS_RESULT_NOTIFICATION_CHANNEL = 'REHAB_REPORTS_RESULT_NOTIFICATION_CHANNEL';
const APPOINTMENTS_NOTIFICATION_CHANNEL = 'APPOINTMENTS_NOTIFICATION_CHANNEL';

@Injectable({
    providedIn: 'root'
})
export class PusherService {

    public currentUser: any;
    public note: Channel;
    public newReleaseNotficationReceive: Subject<any> = new Subject<any>();
    public pusher: Pusher;

    private new_note = new BehaviorSubject({name: 'note'});
    public note_notify = this.new_note.asObservable();

    private new_dictation = new BehaviorSubject({name: 'dictation'});
    public dictation_notify = this.new_dictation.asObservable();

    private new_pcc_webhook = new BehaviorSubject(null);
    public pcc_webhook_notify = this.new_pcc_webhook.asObservable();
    
    private new_rehab_reports = new BehaviorSubject(null);
    public new_rehab_reports_notify = this.new_rehab_reports.asObservable();

    private user_type = new BehaviorSubject(null);
    public user_type_notify = this.user_type.asObservable();

    constructor(
        private authService: AuthService
    ) {
        this.currentUser = this.authService.currentUser || {};
        this.pusher = new Pusher(environment.PUSHER.API_KEY, {
            cluster: environment.PUSHER.CLUSTER
        });
        const note = this.pusher.subscribe('NOTE_CREATION');
        note.bind('new-note', (data) => {
            this.new_note.next(data);
        });
        const dictationChannel = this.pusher.subscribe('NEW_DICTATION');
        dictationChannel.bind('new-dictation', (data) => {
            this.new_dictation.next(data);
        });
    }

    appointmentUpdates() {
        return new Observable(obs => {
            const appointmentsChannel = this.pusher.subscribe(`${APPOINTMENTS_NOTIFICATION_CHANNEL}`);
            appointmentsChannel.bind(`appointment_status_updated`, (data) => {
                obs.next({ event: 'appointment_status_updated', data });
            });
        });
    }

    subscribeChannels() {
        
        this.note = this.pusher.subscribe('NOTE_CREATION');
        this.note.bind('new-note', (data) => {
            
            this.new_note.next(data);
        });
        const dictationChannel = this.pusher.subscribe('NEW_DICTATION');
        dictationChannel.bind('new-dictation', (data) => {
            
            this.new_dictation.next(data);
        });
    }

    testPusher() {
        const channel = this.pusher.subscribe('my-channel');
    }

    subscribeToRehabReportsNotificationChannel = (doc_id, eventTypes) => {
        const channel = this.pusher.subscribe(`${REHAB_REPORTS_RESULT_NOTIFICATION_CHANNEL}.${doc_id}`);

        for(let event_type of eventTypes) {
            
            channel.bind(event_type, (data) => {
                this.new_rehab_reports.next({
                    event_type,
                    payload: data
                });
            });
        }
        return channel;
    }

    subscribePCCWebhookNotificationChannel = (user_id, eventTypes) => {
        const channel = this.pusher.subscribe(`${pccChannels.PCC_WEBHOOK_NOTIFICATION_CHANNEL}.${user_id}`);

        for(let event_type of eventTypes) {
            
            channel.bind(event_type, (data) => {
                this.new_pcc_webhook.next({
                    event_type,
                    payload: data
                });
            });
        }
        return channel;
    }

    getNoteCreationChannel() {
        const channel = this.pusher.subscribe('NOTE_CREATION');
        return channel;
    }

    getDictationCreationChannel() {
        const channel = this.pusher.subscribe('NEW_DICTATION');
        return channel;
    }

    subscribeVersionChannels() {
        const channel = this.pusher.subscribe('version-update');
        channel.bind('version-event', (data) => {
            console.log(data);
        });
    }

    subscribeNewReleaseChannel = (eventTypes) => {
        const channel = this.pusher.subscribe(`${releaseChannels.NEW_RELEASE_CHANNEL}_${this.currentUser.user_type}`);
        for(let event_type of eventTypes){
            channel.bind(event_type, (data) => {
                this.user_type.next({
                    event_type,
                    payload: data
                })
            })
        }
        return channel;
    }
}
