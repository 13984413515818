<div>
    <div class="close-dialog-btn">
        <span class="material-symbols-outlined mat-dialog-close-icon" mat-dialog-close>cancel</span>
    </div>
    <mat-tab-group>
        <mat-tab label="Customized Tabs Setting">
            <mat-card>
                <div class="parent-container">
                    <div *ngFor="let tabKey of tab_keys">
                        <div class="heading p-2">{{tab_names[tabKey]}}</div>
                        <div class="note-sidebar flex" >
                            <div cdkDropList cdkDropListOrientation="vertical" class="tabs-list flex flex-col justify-start"
                              (cdkDropListDropped)="drop($event,tabKey)">
                              <ng-container *ngFor="let tab of customized_tab[tabKey]; let index = index">
                                <div class="flex">
                                    <div class="count">
                                        {{ index+1 }}
                                    </div>
                                    <div class="tabs-group tabs-ripple-container customization" cdkDrag matRipple>
                                    {{tab}}
                                    </div>
                                </div>
                              </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </mat-tab>
        <mat-tab label="Charge">
            <mat-card>
                <div class="parent-container">
                    <mat-checkbox (change)="updateComapny('should_show_census_charges_count',$event.checked)"
                        [(ngModel)]="shouldShowCharges"> Show Charges for the user
                    </mat-checkbox>
                </div>
            </mat-card>
        </mat-tab>

    </mat-tab-group>
</div>
<ngx-loading [show]="loading"></ngx-loading>
