import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as global from '../global';
import {API_URL} from "../../../environments/api-url";
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PatientAddService {
    currentUser: any;
    company_id: any;
 
    constructor(private httpclient: HttpClient,
    private _authService:AuthService, 
        private commonService: CommonService,) {
            // this._authService.onUserAuthChange.subscribe(currentUser => {
            // });
            this.currentUser = this._authService.currentUser;
    }

    addNewPatient(patient) {
        this.commonService.castCompany.subscribe(company=>{
            this.company_id = company?._id
            // 
        })
        
        if(!this.company_id){
            this.company_id=this.currentUser.company_id
          }
          if(!patient.company_id)
            patient.company_id=this.company_id;
        
        console.log('add patient in service',patient);
        return this.httpclient
            .post(global.url + API_URL.PATIENT.addNewPatient, patient).pipe();
    }

    updatePatient(patient) {
        console.log('update patient in service 1',patient);

        this.commonService.castCompany.subscribe(company=>{
            this.company_id = company?._id
        })
        if(!this.company_id){
            this.company_id=this.currentUser.company_id
          }
          patient.company_id=this.company_id;

        console.log('update patient in service',patient);
        return this.httpclient
            .post(global.url + API_URL.PATIENT.updatePatient, {patient}).pipe();
    }

    getFacilities(item) {

        this.commonService.castCompany.subscribe(company => {
            this.company_id = company?._id
          })
      
          if(!this.company_id){
            this.company_id=this.currentUser.company_id;
          }
          let address={
            ...item,
            company_id:this.company_id
          }
        return this.httpclient
            .post(global.url + API_URL.FACILITY.getMyAddress, {address: address }).pipe();
    }
    getAssociatedDoctorsList(item) {
        return this.httpclient
            .post(global.url + API_URL.USER.getAssociatedDoctorsListForMA, {details: item}).pipe();
    }
    getAssociatedDoctorsListforTrans(item) {
        return this.httpclient
            .post(global.url + API_URL.USER.getAssociatedDoctorsList, {details: item}).pipe();
    }
    getPatientInfoFromFaceSheet(data) {
        return this.httpclient
            .post(global.url + API_URL.PATIENT.getPatientInfoFromFaceSheet, data).pipe();
    }
    sendEmailToUsers(details){
        return this.httpclient
        .post(global.url + API_URL.PATIENT.sendEmailToUsers, details).pipe();
    }
    editPatient(filter, updateQuery, updatePatientsQuery = []) {
        return this.httpclient.post(global.url + API_URL.PATIENT.editPatient, { filter, updateQuery, updatePatientsQuery  }).pipe(
            map((response: any) => {
              if (response.status == 200) {
                if (response.data.patientList) {
                  response.data.patientList.map(patient => {
                    if (patient.tags_id_ref_detail) {
                      const [first_tag_name, second_tag_name] = patient.tags_id_ref_detail.map(patient_tag => patient_tag.name);
                      const [first_tag_color, second_tag_color] = patient.tags_id_ref_detail.map(patient_tag => patient_tag.color);
                        patient.first_tag_name = first_tag_name ? first_tag_name : "";
                        patient.second_tag_name = second_tag_name ? second_tag_name : "";
                        patient.first_tag_color = first_tag_color ? first_tag_color : "";
                        patient.second_tag_color = second_tag_color ? second_tag_color : "";
                    }
                  })
                }
              }
              return response;
            })
        )
    }
}
