import {Component, OnInit} from '@angular/core';
import {AppointmentDataService} from '../../service/data.service';
import {ToastrService} from 'ngx-toastr';
import {FacilityService} from '../../../../services/facility.service';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-add-attribute',
    templateUrl: './add-attribute.template.html'
})
export class AddAttributeComponent implements OnInit {
    facilities = [];
    attributes = [];
    title = '';
    facility_id = '';
    attribute_id = '';
    isNew = 'true';

    constructor(public dialogRef: MatDialogRef<AddAttributeComponent>,
                public service: AppointmentDataService,
                private facilityService: FacilityService,
                private toastr: ToastrService) {
    }

    addAttribute(attribute) {
        
        this.dialogRef.close();
        if (this.isNew === 'true') {
            this.addNewAttribute();
        } else {
            this.assignFacilityAttribute();
        }
    }

    addNewAttribute() {
        this.service.addAppointmentAttribute({title: this.title}).subscribe(
            data => {
                
                this.service.assignFacilityAttribute({attribute_id: data.data._id, facility_id: this.facility_id}).subscribe(data => {
                    
                    this.toastr.success('Attribute added successfully', 'Success');
                    this.service.updateData = true;
                });
            },
            error => {
                
                this.toastr.error('something went wrong', 'Error');
            }
        );
    }

    assignFacilityAttribute() {
        
        this.service.assignFacilityAttribute({attribute_id: this.attribute_id, facility_id: this.facility_id}).subscribe(data => {
            
            this.toastr.success('Attribute added successfully', 'Success');
            this.service.updateData = true;
        });
    }

    ngOnInit(): void {
        this.facilityService.getMyFacilities().subscribe(data => {
            
            // @ts-ignore
            this.facilities = data.data.array;
        }, error => {
            this.toastr.error('something went wrong', 'Error');
        });
        this.service.getUserAttributes().subscribe(data => {
            
            this.attributes = data.data.array;
        }, error => {
            this.toastr.error('something went wrong', 'Error');
        });
    }
}
