import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'; 
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CertificationService } from './certification.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.css']
})
export class CertificationComponent implements OnInit {
  selectedFiles: FileList;
  censusFile:any; 
  @ViewChild('file_uploader') file_uploader: ElementRef;
  currentUser:any
  selectedXMLFiles = [];
  otherFiles = [];
  showButtons = true;
  extractButton = true;
  haveXMLs = false;
  progressInfos: any[] = [];
  message: string[] = [];
  fileInfos?: Observable<any>;
  haveData = false;
  mips;
  addMoreData = false;
  generateReport = false;
  loading = true;
  selectedMip;
  constructor(
    public dialogRef: MatDialogRef<CertificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private authService: AuthService,
    private certificationService: CertificationService,
  ) { 
    this.currentUser = this.authService.currentUser;
  }

  async ngOnInit() {
    this.loading = true;
    let result:any = await this.certificationService.checkData().toPromise();
    if(result.status == 200){
      console.log('We have XMLs-----------')
      this.haveData = result.data > 0 ? true: false;
      if(result.data > 0 ){
        this.haveData = true
        console.log('this.haveData: ', this.haveData)
        console.log('add more data: ', this.addMoreData)
        await this.getMIPs();
      }
      else
        this.haveData = false;
    }
    this.loading = false
  }
  async getMIPs(){
    let res:any = await this.certificationService.getMIPs().toPromise();
    if(res.status == 200){
      this.mips = res.data;
    }
    this.loading = false;
  }
  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
  
    if (file) {
      this.certificationService.uploadFilesForCertification(file).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            const msg = 'Uploaded the file successfully: ' + file.name;
            this.message.push(msg);
            // this.fileInfos = this.certificationService.getFiles();
          }
        },
        (err: any) => {
          this.progressInfos[idx].value = 0;
          const msg = 'Could not upload the file: ' + file.name;
          this.message.push(msg);
          // this.fileInfos = this.certificationService.getFiles();
        });
    } 
  }

  generateReportClicked(){
    console.log('generate report clicked: ', this.selectedMip)
  }
  selectFiles(event) { 
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        if(this.selectedFiles[i].name?.includes('.xml')){
          this.selectedXMLFiles.push(this.selectedFiles[i])
        }
        else{
          this.otherFiles.push(this.selectedFiles[i])
        } 
      }
    }
    console.log("this.selectedXMLFiles : ", this.selectedXMLFiles)
    console.log("this.otherFiles : ", this.otherFiles)
  }
  uploadClicked(){
    if (this.selectedXMLFiles) {
      for (let i = 0; i < this.selectedXMLFiles.length; i++) {
        this.upload(i, this.selectedXMLFiles[i]);
      }
    }
    this.showButtons = false;
  }
 
  cancelClicked(){
    this.dialogRef.close();
  }
  getFolderInfo(){
    // console.log("File Name: ", this.selectedFiles)
    if(this.selectedFiles){
      // if(this.selectedXMLFiles.length == 1){
      //   return `Uploaded folder contains one file named: ${this.selectedFiles[0].name}`;
      // }
      // else if(this.selectedFiles.length > 0){
      //   // return this.selectedFiles[0].name + ' +' + (Number(this.selectedFiles.length)-1) + ' more' ; 
      // }
      return `Uploaded folder contains ${this.selectedFiles.length} file/s in total`;
    }
    else{ 
      return "No files selected yet."
    }
  }

  async extractDataClicked(){
    console.log('extractDataClicked ---------');
    this.extractButton = false;
    let res:any = await this.certificationService.extractData().toPromise()
    if(res.status == 200){
      console.log('Information extracted')
      this.dialogRef.close();
    }

  }

  getXMLFilesInfo(){
    if(this.selectedXMLFiles){
      if(this.selectedXMLFiles.length == 1){
        return `Uploaded folder contains one XML file named: ${this.selectedXMLFiles[0].name}`;
      }
      else if(this.selectedXMLFiles.length > 1){
        return `Uploaded folder contains ${this.selectedXMLFiles.length} XML file/s`; 
      }
    }
  }
  getOtherFilesInfo(){
    if(this.otherFiles){
      if(this.otherFiles.length == 1){
        return `Uploaded folder contains one non-XML file named: ${this.otherFiles[0].name}`;
      }
      else if(this.otherFiles.length > 1){
        return `Uploaded folder contains ${this.otherFiles.length} non-XML file/s`; 
      }
    }
  }

}
