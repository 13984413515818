import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import moment from 'moment'
import * as global from '../global';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import {API_URL} from "../../../environments/api-url";
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
  })
  export class NotificationsService {
      currentUser:any;
    lastNotification
    chargeNotificationData$ = new BehaviorSubject<any>({});
    castChargeNotificationData = this.chargeNotificationData$.asObservable();

      newReleaseNotficationReceive: Subject<any> = new Subject<any>();
      constructor(private httpclient: HttpClient, private authService:AuthService) {
        this.currentUser = this.authService.currentUser;
        
      }

    getNotifications() {  
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type
        }
        return this.httpclient
            .post(global.url + API_URL.NOTIFICATION.getNotifications, {details: details}).pipe(map((response:any) => {
            if(response.status === 200) {
                response.notification = this.sortDescOrder(response.notification);
            }
            return response;
        }));
    }

    getUnseenNotificationsCount() {  
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type
        }
        return this.httpclient
            .post(global.url + API_URL.NOTIFICATION.getUnseenNotificationsCount, {details: details}).pipe(map((response:any) => {
            return response;
        }));
    }

    makeNotificationsSeen(notificationIds) {  
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            notificationIds
        }
        return this.httpclient
            .post(global.url + API_URL.NOTIFICATION.makeNotificationsSeen, {details: details}).pipe(map((response:any) => {
                return response;
            }));
        }

    
    getAllNotifications(recipient_type?) {  
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type
        }
        if (recipient_type)
            details['recipient_type'] = recipient_type;
        return this.httpclient
            .post(global.url + API_URL.NOTIFICATION.getAllNotifications, {details: details}).pipe(map((response:any) => {
            if(response.status === 200) {
                response.notification = this.sortDescOrder(response.notification);
            }
            return response;
        }));
    }

    getLastNotifications() {  
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type
        }
        return this.httpclient
            .post(global.url + API_URL.NOTIFICATION.getLastNotifications, {details: details}).pipe(map((response:any) => {
            if(response.status === 200) {
                response.notification = this.sortDescOrder(response.notification);
            }
            return response;
        }));
    }

    sortDescOrder(notifications) {
        return notifications.slice().sort(function(a, b) {

            let valueA:any = a.createdOn;
            let valueB:any = b.createdOn;
            const momentA = moment(new Date(valueA));
            const momentB = moment(new Date(valueB));
            if(momentA.isValid() && momentB.isValid()) {
              valueA = momentA.toDate().getTime();
              valueB = momentB.toDate().getTime();
              return valueB - valueA;
            }
            return 0;
          })
    }

    addNotification(payload) {  
        return this.httpclient
            .post(global.url + API_URL.NOTIFICATION.addNotification, {data: payload})
    }

    setChargeNotificationData(data){
        this.chargeNotificationData$.next(data)
    }

  }
