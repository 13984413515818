<div *ngIf="!loading">
  <div *ngIf="haveData && !addMoreData && !generateReport">
    <h2 style="display: flex; flex-direction: row; place-content: center; padding: 20px;">Generate Report for Certification</h2>
    <div style="display: flex; flex-direction: row; place-content: center; padding: 20px;">
      <button style="margin-right: 20px;" *ngIf="!selectedFiles" #file_uploader_btn class="dn-button-primary" mat-raised-button (click)="addMoreData = true">Add More Data</button>
      <button *ngIf="!selectedFiles" #file_uploader_btn class="dn-button-primary" mat-raised-button (click)="generateReport = true">Generate Report</button>
    </div>
    <br>
  </div>

  <div *ngIf="generateReport && !addMoreData" style="display: flex; flex-direction: column; padding: 20px;">
    <mat-form-field>
      <mat-label>Select MIP</mat-label>
      <mat-select name="mip"
                  required 
                  [(ngModel)]="selectedMip">
          <mat-option *ngFor="let mip of mips"
                      value="{{mip._id}}">{{mip.measure_title}}
          </mat-option>
      </mat-select>
  </mat-form-field>
  <div style="display: flex; flex-direction: row; place-content: center; padding: 20px;" >
    <button style="margin-left: 10px;" class="dn-button-secondary" mat-raised-button (click)="cancelClicked()" >Cancel</button>
    <button style="margin-left: 10px;" class="dn-button-primary" mat-raised-button (click)="generateReportClicked()" >Generate Report</button>
  </div>

  </div>

  <div *ngIf="!haveData || addMoreData" style="display: flex; flex-direction: column;"> 
      <h2 style="align-self: center;">Upload Patients Document Folder</h2>
      <br> 
      <br>
      <div style="align-self: center;margin-bottom: 10px">
          <input (change)="selectFiles($event)" webkitdirectory directory multiple hidden type="file" id="file_uploader"
              #file_uploader  [(ngModel)]='censusFile'/>
          {{getFolderInfo()}}
          <br>
          <span *ngIf="selectedXMLFiles.length > 0" style="color: green">{{getXMLFilesInfo()}}</span>
          <br>
          <span *ngIf="otherFiles.length > 0" style="color:red" >{{getOtherFilesInfo()}}</span>
          <button *ngIf="!selectedFiles" #file_uploader_btn class="dn-button-primary" mat-raised-button (click)="file_uploader.click()">Add
              Folder</button>
      </div>
      <div *ngIf="message.length" class="alert alert-secondary my-3" role="alert">
        <ul *ngFor="let msg of message; let i = index">
          <li>{{ msg }}</li>
        </ul>
      </div>
      <div style="align-self: flex-end;" *ngIf="showButtons">
          <button style="margin-left: 10px;" class="dn-button-secondary" mat-raised-button (click)="cancelClicked()" >Cancel</button>
          <button style="margin-left: 10px;" class="dn-button-primary" mat-raised-button (click)="uploadClicked()"  [disabled]="!selectedFiles">Upload</button>
      </div>
      <div style="align-self: flex-end;" *ngIf="(message.length == selectedXMLFiles.length) && !showButtons" >
          <button style="margin-left: 10px;" class="dn-button-secondary" mat-raised-button (click)="cancelClicked()" >Cancel</button> 
          <button style="margin-left: 10px;" class="dn-button-primary" mat-raised-button (click)="extractDataClicked()" [disabled]="!extractButton">Extract Data</button>
      </div>
  </div>

  <div class="top-right-close-btn">
      <button mat-mini-fab mat-dialog-close>
          <mat-icon>cancel</mat-icon>
      </button>
  </div>
</div>