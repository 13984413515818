import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import moment from 'moment'
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BillerDashboardService } from '../../biller-dashboard.service';
import * as global from '../../../global';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-audit-popup',
  templateUrl: './audit-popup.component.html',
  styleUrls: ['./audit-popup.component.css']
})
export class AuditPopupComponent implements OnInit {
  charge_id;
  auditData;
  loading = true;
  hasData = true;
  creationData ={
    action: "",
    date:  null, 
    performer: ""
  };
  operations = []; 
  highestLengt;
  displayColumns = ['creationDate', 'updationDate', 'submissonDate', 'resubmissionDate', 'actionFrom', ]
  constructor(
    public billerDashboardService: BillerDashboardService,
    private _route: ActivatedRoute,
    public _authService: AuthService,
    private _toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    console.log("this.data--------: ", this.data)
    this.charge_id = this.data;
    // this.patientData = this.data.patient; 
    this.getChargeAudit();
  }
  getAuditData(i){ 
    let op = ''
    if(i.operation == 'create'){
      op = 'Created'
    }
    else if(i.operation == 'update'){
      op = 'Updated'
    }
    else if(i.operation == 'submit'){
      op = 'Submitted'
    }
    else if(i.operation == 'resubmit'){
      op = 'Resubmitted'
    }
    else if(i.operation == 'opm'){
      op = 'Data sent to OPM'
    }
    else if(i.operation == 'care_cloud'){
      op = 'Data sent to Care Cloud'
    }
    else  if(i.operation == 'billed'){
      op = 'Billed'
    }
    else if(i.operation.toLowerCase() == 'parp'){
      op = 'Data sent to PARP'
    }
    else if(i.operation.toLowerCase() == 'hp'){
      op = 'Data sent to HP'
    }
    else if(i.operation.toLowerCase() == 'CMD-ADT'.toLocaleLowerCase()){
      op = 'ADT data sent to CMD'
    }
    else if(i.operation.toLowerCase() == 'CMD-DFT'.toLocaleLowerCase()){
      op = 'DFT data sent to CMD'
    }
    else if(i.operation.toLowerCase() == 'Coronis-DFT'.toLocaleLowerCase()){
      op = 'DFT data sent to Coronis Health'
    }
    else if (i.operation == 'missed_visit') {
        op = 'Missed Visit';
    }
    else if (i.operation == 'on_hold') {
        op = 'On Hold';
    }
    return `${op} by ${i.performed_by}`
  }
  getAuditDate(i){
    return  `${moment(i.date).format('MM/DD/yyyy h:mm a')}` 
  }
  async getChargeAudit(){
    // this._toastr.info("Getting Info...")
    this.loading = true;
    let res:any = await this.billerDashboardService.getChargeAudit(this.charge_id).toPromise();
    if(res.status == 200){
      this.auditData = res.data
      if(this.auditData){
        for (let i = 0; i < this.auditData.operations.length; i++) {
          // const element = array[i];
          this.operations.unshift(this.auditData.operations[i]);
        } 
      }
    
    this.loading = false; 
    }
    if(this.operations && this.operations.length <= 0){
      this.hasData = false;
      this._toastr.success("No information availbale")
    }
  } 
}
