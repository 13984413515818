import { Component,Inject, OnInit, ViewChild, EventEmitter} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PatientAddFormComponent} from '../../patient-add-form/patient-add-form.component'

// UPLOAD FILE DIALOG
export interface PatientAddDialogData {
  currentUser:any,
  currentPatient:any,
  selectedFacilityId:string,
  selectedDoctorId:string,
  showPhoneField:boolean;
  showEmailField:boolean;
}

@Component({
  selector: 'app-patient-add-dialog',
  templateUrl: './patient-add-dialog.component.html',
  styleUrls: ['./patient-add-dialog.component.css']
})
export class PatientAddDialog implements OnInit {
  @ViewChild(PatientAddFormComponent) patientAddFormComponent:PatientAddFormComponent;
  editPatient = !!localStorage.getItem('editPatient');
  selectedDoctorId:string;
  selectedFacilityId:string;
  showPhoneField: boolean = false;
  showEmailField: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<PatientAddDialog>,
    @Inject(MAT_DIALOG_DATA) public data: PatientAddDialogData) { 
      if(data) {
        this.selectedDoctorId = data.selectedDoctorId,
        this.selectedFacilityId = data.selectedFacilityId
        this.showPhoneField = data.showPhoneField,
        this.showEmailField = data.showEmailField
      }
    }
    
    ngOnInit(): void {
    }

    onSaveClick($event) {
      const onSuccess = new EventEmitter();
      onSuccess.subscribe(($event) => {
        this.dialogRef.close($event);
      })
      this.patientAddFormComponent.savePatient($event, onSuccess);
    }
    onCancelClick($event) {
        this.dialogRef.close($event);
    }
}
