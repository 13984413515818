<div class="flex flex-col gap-2 facility-padding-class">
    <br />
    <div class="flex justify-between items-start gap-2">
        <mat-form-field class="flex-grow">
            <input type="text" matInput (keyup)="applyFilter($event.target.value)"
                placeholder="Filter facility by name" />
            <mat-icon matSuffix color="primary">search</mat-icon>
        </mat-form-field>
    </div>

    <div>
        <button mat-raised-button class="dn-button-primary" *ngIf="isImportFacilitiesButtonVisible()"
            (click)="importPCCFacilities()">Import
            PointClickCare Facilities</button>
    </div>

    <div style="max-height: 300px; overflow: auto;">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="facility">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Facility </th>
                <td mat-cell *matCellDef="let element">
                    <app-facility-title [facility]="element"></app-facility-title>
                </td>
            </ng-container>
            <ng-container matColumnDef="is_active">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                    Active/inActive
                </th>
                <td mat-cell *matCellDef="let element">
                    <span>
                        <mat-slide-toggle [checked]="element.is_active" [disabled]='true'></mat-slide-toggle>
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="address">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Address </th>
                <td mat-cell *matCellDef="let element">
                    {{element.address}}</td>
            </ng-container>
            <ng-container matColumnDef="city">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> City </th>
                <td mat-cell *matCellDef="let element">
                    {{element.city}}</td>
            </ng-container>
            <ng-container matColumnDef="state">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> State </th>
                <td mat-cell *matCellDef="let element"> {{element.state > 0 ? usa_states[element.state]?.abbreviation :
                    ''}} </td>
            </ng-container>
            <ng-container matColumnDef="zip_code">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Zip Code </th>
                <td mat-cell *matCellDef="let element"> {{element.zip_code}} </td>
            </ng-container>
            <ng-container matColumnDef="operations">
                <th class="header-data" mat-header-cell *matHeaderCellDef></th>
                <ng-container *matCellDef="let element">
                    <td mat-cell style="max-width: 120px" class="buttons-div">
                        <div class="flex items-center gap-5 operation-buttons buttons-div">
                        </div>
                    </td>
                </ng-container>
            </ng-container>

            <ng-container matColumnDef="note_email">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                    Notes Email
                </th>
                <td mat-cell *matCellDef="let element">
                    <span>
                        <mat-slide-toggle (change)="onNoteEmailStatus(element,$event)"
                            [checked]="getCheckStatus(element)">
                        </mat-slide-toggle>
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
</div>