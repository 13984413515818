import {Component, Inject, OnInit} from '@angular/core';
import {Payor} from '../../../classes/payor';
import {AppointmentDataService} from '../service/data.service';
import {ToastrService} from 'ngx-toastr';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
// import { InsuranceCompanyComponent } from '../../insurance-company/insurance-company.component';

@Component({
    selector: 'app-add-payor',
    templateUrl: './add-payor.template.html'
})
 

export class AddPayorComponent implements OnInit {
    public model = new Payor();
    updating:Boolean = false;
    constructor(
        public dialogRef: MatDialogRef<AddPayorComponent>,  
        public service: AppointmentDataService,
        // public insuranceCompanyComponent :InsuranceCompanyComponent,
        private toastr: ToastrService,

        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (data) {
            this.model = data;
            this.updating = true;
        }
    }

    addPayor(payor) {
        this.dialogRef.close();
        if (this.updating) {
            payor._id = this.data._id;
            this.service.updatePayors(payor).subscribe((response: any) => {
                if(response.status === 200){
                    this.toastr.success('Payor Updated successfully', 'Success');
                    this.service.getPayors(); 
                }
                else{
                    this.toastr.error('something went wrong', 'Error');
                }
            });
        }
        else{
            this.service.addPayor(payor).subscribe(
                data => {
                    this.toastr.success('Payor added successfully', 'Success');
                    this.service.getPayors(); 
                },
                error => {
                    this.toastr.error('something went wrong', 'Error');
                }
            );
        }
    }

    ngOnInit(): void {
    }
}
