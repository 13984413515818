<div class="flex flex-col gap-2" >
    <br />
    <span style="margin-bottom: 10px; display: contents; text-align: center;">
        <h3>Add News</h3>
    </span>
    <div class="flex justify-between items-start gap-2">
        <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>Enter news</mat-label>
            <textarea  matInput type="text" [(ngModel)]="newsModel"> </textarea>
        </mat-form-field> 

        <button *ngIf="create && !update" class="theme-btn" mat-raised-button (click)="addNews()"
            [disabled]="!newsModel || selectedNews">Add</button>
        <button *ngIf="!create && update" class="theme-btn" mat-raised-button (click)="updateNews()"
            [disabled]="!newsModel || !selectedNews">Update</button>

    </div>
 
    <div [loaderId]="loaderId" ngxUiLoaderBlurred>
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="title">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container > News </th>
                <td mat-cell *matCellDef="let element">
                    {{element.news | slice: 0:200}}{{element.news && element.news.length > 200 ? ' . . .' : ''}} </td>
            </ng-container> 
 
            <ng-container matColumnDef="operations">
                <th class="header-data" mat-header-cell *matHeaderCellDef style="width: 10%;"></th>
                <ng-container *matCellDef="let element">
                    <td mat-cell style="max-width: 120px" class="buttons-div">
                        <div class="operation-buttons buttons-div" class="flex flex-inline justify-start items-center gap-5">
                            <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
                                (click)="onEditClick(element)"></i>

                            <i class="icon icon-delete" matTooltip="Delete" matTooltipPosition="above"
                                (click)="onDeleteClick(element)"></i>
                        </div>
                    </td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
        <ngx-ui-loader [hasProgressBar]="false" [loaderId]="loaderId"></ngx-ui-loader>
    </div>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>