import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CompanyService } from 'src/app/services/Modules/company.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-open-setting-dialog',
  templateUrl: './open-setting-dialog.component.html',
  styleUrls: ['./open-setting-dialog.component.css']
})
export class OpenSettingDialogComponent implements OnInit {
  customized_tab: any;
  loading: Boolean = false;
  tab_keys: String[];
  tab_names = {
    diagnosis: 'Defalut Diagnosis Tab',
    charges: 'Default Capture Tab'
  }
  companyId: any;
  shouldShowCharges: boolean = false;
  company: {
    should_show_census_charges_count: boolean;
  };
  constructor(public dialogRef: MatDialogRef<OpenSettingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public company_id: any,
    private companyService: CompanyService,
    private _toaster: ToastrService,
  ) {
    if (company_id) {
      this.companyId = company_id;
    }
  }

  async ngOnInit() {
    await this.getCompanyPermissions();
    this.loading = true
    const response: any = await this.companyService.getDefaultTabOrders(this.company_id).toPromise()
    this.loading = false
    this.customized_tab = response.data.company_defined_tabs_order
    this.tab_keys = Object.keys(this.customized_tab)
  }
  drop(event: CdkDragDrop<string[]>, tabKey) {
    moveItemInArray(this.customized_tab[tabKey], event.previousIndex, event.currentIndex);
    this.companyService.updateDefaultTabOrders(this.company_id, this.customized_tab).subscribe((data: any) => {
      if (data.status === 200) {
        this._toaster.success("Defualt Tabs Updated");
      }
    })
  }
  async getCompanyPermissions() {
    const filter = {
      _id: this.companyId
    };
    const project = {
      should_show_census_charges_count: 1
    };
    const { status, data } = await lastValueFrom(this.companyService.getCompany(filter, project));
    if (status == 200) {
      this.company = data;
      this.shouldShowCharges = this.company.should_show_census_charges_count;
    }
    else if (status === 500) {
      this._toaster.error(data.message, "ERROR");
    }
  }
  updateComapny(field, value) {
    let data = {
      _id: this.companyId,
      [field]: value
    }
    this.companyService.updateCompany(data).subscribe((response: any) => {
      if (response.status == 200) {
        this._toaster.success(response.message, 'Success');
      } else {
        this._toaster.error(response.message, 'Failed');
      }
    })
  }
}
