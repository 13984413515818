<main>
  <div >
    <mat-card>
      <mat-card-content class="flex flex-col">
        <div class="flex justify-between items-center gap-5">
          <div class="flex grow items-center gap-5">
            <h3 style="width:105px">Patient Census</h3>
            <mat-form-field *ngIf="!providerIdParam">
              <mat-label>{{ currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE || (currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER && currentUser.company_type === global.COMPANY_TYPE.SNF) ? 'SNF WC Nurses' : 'Providers' }}</mat-label>
              <mat-select name="provider" [class.user-select-menu]="selectedUser && selectedUser.is_activated !== 'true'" #provider="ngModel" [(ngModel)]="filter_provider_id">
                <input placeholder="Search" [(ngModel)]="searchTextProvider"
                  [ngModelOptions]="{standalone: true}" class="user-dropdown-input" style="border: none; width: 100%;"/>
                <mat-option (onSelectionChange)="handleUserChange($event, provider)" [class.text-gray]="provider.is_activated !== 'true'" *ngFor="let provider of providers | searchProvider : searchTextProvider"
                  value="{{provider._id}}">
                  {{provider.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            
            <div class="grow" style="position: relative;">
              <mat-form-field style="width: 100%;">
                <mat-label>Facility</mat-label>
                <div class="flex items-center">
                  
                    <img src="../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacility(filter_facility_id)" width="20px" height="20px" alt="">
                    <img src="../../../assets/icons/matrix.png" *ngIf="isMatrixFacility(filter_facility_id)" alt=""
                    style="height: 12px;
                        width: 20px;  
                        margin-right: 5px;
                        vertical-align: middle;">
                  
                  <mat-select name="facility" #facility="ngModel" [(ngModel)]="filter_facility_id"
                    (ngModelChange)="handleFilterFacilityChange()">
  
                    <input  placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                      style="color:#000;height: 40px;padding-left: 5px; ; position: sticky; top: 0; z-index:1000; background-color: lightgray; border: none; width: 100%;" (keydown)="$event.stopPropagation()" />
                    <mat-option *ngFor="let facility of facilities | searchFacility: searchText" value="{{facility._id}}">
                      <app-facility-title [facility]="facility" [isCensusTab] = "true"></app-facility-title>
                    </mat-option>
                  </mat-select>
                </div>
              </mat-form-field>
              <p style="font-size: 12px;color: red;position: absolute;bottom: -17px;"
                *ngIf="is2LeggedAuthRequired(filter_facility_id)">
                This facility requires login into PCC to view data.
              </p>
            </div>
            <ng-container *ngIf="isLoggedInFacility(selectedFacility)">
              <button class="dn-button-primary" mat-raised-button
                (click)="initializeActiveCensus(selectedFacility._id)">
                Initialize Active Census
              </button>
              <button class="dn-button-primary" mat-raised-button
                [routerLink]="['/census/active/' + selectedFacility._id]">
                View Active Census
              </button>
            </ng-container>
          </div>
          <button mat-raised-button class="dn-button-primary" *ngIf="showCreateButton()" style="white-space: nowrap;"
            (click)="showPatientRoundingSheetDialog()">Create New Patient Census</button>
          <button
            mat-mini-fab
            matTooltip="Watch Census Creation Video"
            aria-label="icon-button"
            style="transition: none !important;box-shadow: none !important; background:none !important; color: inherit;"
            (click)="watchHelpVideo('census-creation-video')"
          >
            <mat-icon>help</mat-icon>
          </button>
          <mat-form-field
            *ngIf="currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT||currentUser.user_type===global.USER_TYPE.TRANSCRIBER">
            <mat-label>Status</mat-label>
            <mat-select name="status" [(value)]="censusStatus" (selectionChange)="getCensusListByStatus($event.value)">
              <mat-option value="true">
                Active
              </mat-option>
              <mat-option value="false">
                Deleted
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="width: 100px;" *ngIf="false"
            >
          <mat-label>Limit</mat-label>
          <mat-select name="limit" [value]="showLimit.toString()" (selectionChange)="changeLimit($event.value)">
            <mat-option value="5">
              5
            </mat-option>
            <mat-option value="7">
              7
            </mat-option>
            <mat-option value="10">
              10
            </mat-option>
          </mat-select>
        </mat-form-field>
        </div>
        <div style="position: relative;">
          <mat-table [dataSource]="dataSource" *ngIf="dataSource" matSort (matSortChange)="sortData($event)">
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                <ng-container
                  *ngIf="patientRoundingSheet?.signedNotesCount > patientRoundingSheet?.submittedChargesCount">
                  <img width="10px" src="../../../../assets/img/red_dot.png" alt="">
                </ng-container>
                <ng-container
                  *ngIf="patientRoundingSheet?.submittedChargesCount === patientRoundingSheet?.signedNotesCount">
                  <img width="10px" src="../../../../assets/img/green_dot.png" alt="">
                </ng-container>
                <ng-container
                  *ngIf="patientRoundingSheet?.signedNotesCount &lt; patientRoundingSheet?.submittedChargesCount">
                  <img width="10px" src="../../../../assets/img/red_dot.png" alt="">
                </ng-container>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="dateOfService">
              <mat-header-cell *matHeaderCellDef mat-sort-header="dateOfService">Date Of Service</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">{{getDOS(patientRoundingSheet)}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header="name">Census Name</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">{{patientRoundingSheet.name}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="facility">
              <mat-header-cell *matHeaderCellDef mat-sort-header="facility">Facility</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                <app-facility-title [facility] = "patientRoundingSheet?.facility" [isCensusTab] = "true"></app-facility-title>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef mat-sort-header="date">Visit Date</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">{{patientRoundingSheet.date | date:
                global.date_format}}
              </mat-cell>     
            </ng-container>
            <ng-container matColumnDef="patients_count_total">
              <mat-header-cell *matHeaderCellDef mat-sort-header="patient_count">Total Patients</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                {{getActivePatientsCount(patientRoundingSheet)}}
              </mat-cell> 
            </ng-container>
            <ng-container matColumnDef="draft_submitted_charges">
              <mat-header-cell *matHeaderCellDef>Draft/Submitted Charges</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                {{patientRoundingSheet?.draftChargesCount}} / {{patientRoundingSheet?.submittedChargesCount}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="remaining_charges">
              <mat-header-cell *matHeaderCellDef>Pending Charges</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                {{patientRoundingSheet?.remainingCharges}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="saved_signed_notes">
              <mat-header-cell *matHeaderCellDef>Saved/Signed Notes</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                {{patientRoundingSheet?.savedNotesCount}} / {{patientRoundingSheet?.signedNotesCount}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="remaining_notes">
              <mat-header-cell *matHeaderCellDef>Pending Notes</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                {{patientRoundingSheet?.remainingNotes}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="rehab_docs">
              <mat-header-cell *matHeaderCellDef mat-sort-header="rehab_docs_count">Rehab Docs</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet" style="cursor: pointer;"
                (click)="viewRehabDocs(patientRoundingSheet._id)">
                {{patientRoundingSheet.rehab_docs}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="active_census">
              <mat-header-cell *matHeaderCellDef>Active Census</mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element">
                <button *ngIf="element.facility.pcc_facId" class="dn-button-primary" mat-raised-button
                  [routerLink]="['/census/active/' + element.facility._id]">
                  Active Census
                </button>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="operations">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <ng-container *matCellDef="let patientRoundingSheet; let index=index">
                <mat-cell mat-cell *ngIf="!is2LeggedAuthRequired(patientRoundingSheet.facility?._id)">
                  <div *ngIf="censusStatus==='true'" class="operation-buttons flex flex-row items-center gap-4">
                    <i class="icon icon-view" matTooltip="View Census" matTooltipPosition="above"
                      (click)="viewClicked(patientRoundingSheet._id)"></i>
                    <i class="icon icon-edit" style="cursor: pointer;" matTooltip="Edit Census Name" matTooltipPosition="above"
                      *ngIf="(checkVoidCensus(patientRoundingSheet)) &&  (isOwner(patientRoundingSheet) || currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT || currentUser.user_type===global.USER_TYPE.BUSINESS_OFFICER)"
                      (click)="$event.stopPropagation(); showUpdatePatientRoundingSheetDialog(
                                                                                          patientRoundingSheet._id,patientRoundingSheet.name,patientRoundingSheet.date,patientRoundingSheet.date_obj,patientRoundingSheet?.draftChargesCount,patientRoundingSheet?.submittedChargesCount,patientRoundingSheet?.savedNotesCount,patientRoundingSheet?.signedNotesCount,patientRoundingSheet.facility?.timeZone, patientRoundingSheet.createdBy, patientRoundingSheet?.organization)">
                    </i>
                    <i class="icon icon-delete" matTooltip="Delete Census" matTooltipPosition="above"
                      *ngIf="(checkVoidCensus(patientRoundingSheet)) && isOwner(patientRoundingSheet) || currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT || currentUser.user_type===global.USER_TYPE.TRANSCRIBER"
                      (click)="$event.stopPropagation(); deletePatientRoundingSheet(patientRoundingSheet)"></i>
                    <mat-icon *ngIf="checkVoidCensus(patientRoundingSheet) || currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT" matTooltip="Export Notes" style="cursor: pointer;" [matMenuTriggerFor]="sendMenu">send</mat-icon>
                    <mat-menu #sendMenu="matMenu" xPosition="before">
                      <button mat-menu-item (click)="$event.stopPropagation(); downloadAll(patientRoundingSheet._id)">Download Progress Notes</button>
                      <button mat-menu-item (click)="exportNote(patientRoundingSheet)">Send to Email (Progress Notes)</button>
                      <button mat-menu-item (click)="downloadGroupReport(patientRoundingSheet, false)">Download Sorted Group Wound Report</button>
                      <button mat-menu-item (click)="downloadGroupReport(patientRoundingSheet, true)">Download Zip Group Wound Report</button>
                      <button mat-menu-item *ngIf="configuration.wound_assessment_report_submission_type === 'pcc'" (click)="uploadWoundReportsinBulk(patientRoundingSheet)">Bulk Upload Wound Reports to PCC</button>
                      <button mat-menu-item (click)="downloadAllCensusData(patientRoundingSheet)">Download All Census Data</button>
                    </mat-menu>
                    <i *ngIf="!checkVoidCensus(patientRoundingSheet)">
                      <img width="22px" src="../../../../assets/icons/void_census.png" alt="">
                    </i>
                  </div>
                    <div *ngIf="censusStatus==='false'">
                      <mat-icon
                        *ngIf="currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT||currentUser.user_type===global.USER_TYPE.TRANSCRIBER"
                        matTooltip="Restore Census" matTooltipPosition="above" style="cursor: pointer;"
                        (click)="restoreInactiveCensus(patientRoundingSheet, index)">
                        restore
                      </mat-icon>
                    </div>
                </mat-cell>
              </ng-container>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="applyRowColors(row)"></mat-row>
          </mat-table>
          <div class="flex justify-end" style="margin-top: 10px;">

            <button mat-raised-button class="dn-button-primary" [disabled]="!pagination?.hasBack" style="margin-right:6px;"
            (click)="handleBackClick();">Back</button>
            <span style="margin-top: 8px;">
              {{(patientRoundingSheets.length > 0 ? pagination?.skip+1 : 0)+' - '+pagination?.noOfensus||0}} out of {{totalCensus||0}}
            </span>
            <button mat-raised-button class="dn-button-primary" [disabled]="!pagination?.hasNext"  style="margin-left:6px;"
            (click)="handleNextClick();">Next</button>


            <button mat-raised-button class="dn-button-primary" *ngIf="patientRoundingSheets.length < total&&false"
              (click)="initPatientRoundingSheets(filter_facility_id, filter_provider_id);">Show all</button>
            <button mat-raised-button class="dn-button-primary"
              *ngIf="patientRoundingSheets.length > 0 && censusListFetchedOnce &&false"
              (click)="initPatientRoundingSheets(filter_facility_id, filter_provider_id); censusListFetchedOnce=false">Show
              all</button>

              <button mat-raised-button class="dn-button-primary" *ngIf="patientRoundingSheets.length > 5 && !censusListFetchedOnce &&false"
                (click)="cutCensusShort();">Show less</button>
          </div>
          <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</main>


<mat-card *ngIf="false" class="example-card">
  <div class="flex flex-col gap-3">
    <mat-form-field *ngIf="!providerIdParam" style="width: 100%;">
      <mat-label>Providers</mat-label>
      <mat-select name="provider" #provider="ngModel" [(ngModel)]="filter_provider_id"
        (ngModelChange)="handleproviderChange(provider)">
        <input placeholder="Search" [(ngModel)]="searchTextProvider" [ngModelOptions]="{standalone: true}"
          style="color:#000;height: 40px;padding-left: 5px; border: none; width: 100%;" />
        <mat-option *ngFor="let provider of providers | searchProvider : searchTextProvider" value="{{provider._id}}">
          {{provider.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>Facility</mat-label>
      <mat-select name="facility" #facility="ngModel" [(ngModel)]="filter_facility_id"
        (ngModelChange)="this.displayedColumns()">
        <input matInput placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
          style="color:#000;height: 40px;padding-left: 5px;" (keydown)="$event.stopPropagation()" />
        <mat-option *ngFor="let facility of facilities | searchFacility: searchText" value="{{facility._id}}">
          <app-facility-title [facility]="facility" [isCensusTab] = "true"></app-facility-title>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="isLoggedInFacility(selectedFacility)">
      <button style="margin-left: auto !important;width: 100% !important;margin-bottom: 10px;" class="dn-button-primary"
        mat-raised-button (click)="initializeActiveCensus(selectedFacility._id)">
        Initialize Active Census
      </button>
      <button style="margin-left: auto !important;width: 100% !important;margin-bottom: 10px;" class="dn-button-primary"
        mat-raised-button [routerLink]="['/census/active/' + selectedFacility._id]">
        View Active Census
      </button>
    </ng-container>
    <button mat-raised-button class="dn-button-primary" *ngIf="showCreateButton()"
      style="margin-left: auto !important;width: 100% !important;" (click)="showPatientRoundingSheetDialog()">Create
      New
      Patient Census</button>

    <div
      [ngClass]="{'scroll_table_class1':isLoggedInFacility(selectedFacility),'scroll_table_class':!isLoggedInFacility(selectedFacility)}">
      <table mat-table *ngIf="dataSource" (matSortChange)="sortData($event)" [dataSource]="dataSource" matSort
        class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Census Name</th>
          <td mat-cell (click)="viewClicked(patientRoundingSheet._id)" style="cursor: pointer;overflow: hidden;"
            *matCellDef="let patientRoundingSheet"><span
              [class.white-text-selected]="clickedRows.has(patientRoundingSheet)" style="display: flex;">
              <p>{{patientRoundingSheet.name}}</p>
              <mat-icon style="position: sticky;
          left: 83%;
          padding-top: 10px;width: 17px;" matTooltip="Delete Census" matTooltipPosition="above"
                *ngIf="isOwner(patientRoundingSheet)"
                (click)="$event.stopPropagation(); deletePatientRoundingSheet(patientRoundingSheet._id)">delete
              </mat-icon>
            </span></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr (click)="show_selected(row)" [class.demo-row-is-clicked]="clickedRows.has(row)" mat-row
          *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
      <div class="flex justify-end">
        <button style="margin-top: 10px;" mat-raised-button class="dn-button-primary"
          *ngIf="patientRoundingSheets.length < total"
          (click)="initPatientRoundingSheets(filter_facility_id, filter_provider_id);">Show all</button>
      </div>
    </div>

    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
  </div>
</mat-card>