import { Injectable } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {

  private previousUrl: string = '';

  constructor(private router: Router) {
    this.router.events
        .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
        .subscribe((events: RoutesRecognized[]) => {
      // Update the previous route when navigation ends
      this.previousUrl = events[0].urlAfterRedirects;
      // Save the previous route in localStorage to persist it on refresh
      localStorage.setItem('previousUrl', events[0].urlAfterRedirects);
    });

    // Get the previous route from localStorage on application load
    const storedPreviousUrl = localStorage.getItem('previousUrl');
    this.previousUrl = storedPreviousUrl || '/';
  }

  // Function to get the previous route
  public getPreviousUrl(): string {
    return this.previousUrl;
  }
}
