import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AudioTranscription, DoctorTranscription} from '../includes/transcription-create/transcriptionModel';
import * as global from '../includes/global';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {API_URL} from "../../environments/api-url";

@Injectable({
    providedIn: 'root'
})
export class TranscriptionService {

    currentUser: any;

    constructor(private httpclient: HttpClient,
                private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
    }

    checkNoteQA(params = {}): Observable<any> {
        return this.httpclient.post(global.url + API_URL.NOTE.checkNoteQA, params);
    }

    toggleNoteLock(params): Observable<any> {
        // @ts-ignore
        // @ts-ignore

        const details = {
            id: this.currentUser._id,
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key,
            note_id: params.note_id,
            is_locked: params.is_locked
        };
        return this.httpclient.post(global.url + API_URL.NOTE.toggleNoteLock, {details});
    }

    get_transcription_by_id(_id) {
        const details = {
            id: this.currentUser._id,
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key,
            _id: _id
        };
        return this.httpclient.post(global.url + API_URL.NOTE.getTranscriptionById, {details: details}).pipe();
    }
}
