<div class="flex gap-5 justify-between items-start">
  <div class="flex flex-col leading-4" style="line-height: 1;">
    <div class="pcc-button flex items-center"  (click)="handlePccBtnClick()">
      <img src="../../../assets/icons/pcc-icon.svg"/>
      <span class="flex-grow" *ngIf="!(!inprogress && pccUserinfo)">
        Sign in with PointClickCare
      </span>
      <span class="flex-grow" *ngIf="!inprogress && pccUserinfo">
        Signed in as ({{pccUserinfo.name}})
      </span>
      <mat-icon *ngIf="!inprogress && pccUserinfo"
       class="pcc-logout" 
      matTooltip="PCC Logout"
      (click)="handleLogoutPCC($event)">logout</mat-icon>
    </div>
    <div class="pcc-facilities-bar" 
      matTooltipPosition="below"  *ngIf="!inprogress && pccUserinfo && defaultFacility()">
        {{ defaultFacility().title }}
      </div>
  </div>
</div>
