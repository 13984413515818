<div>
    <!-- <h1 mat-dialog-title style="text-align: center;">{{wound.createdAt | date: 'MM/dd/yyyy'}}</h1> -->
    <h2 mat-dialog-title style="font-size: 16px;">Capture & Upload Wound Image</h2>

    <div class="main-container">
        <div class="video-container">
            <div>
                <video #video id="video" width="100%" height="auto" class="video" autoplay></video>
                <canvas style="display: none" #canvas id="canvas" class="canvas"></canvas>
            </div>

            <div class="button-container">
                <button mat-button class="capture-btn" (click)="capture()">
                    <mat-icon>camera_alt</mat-icon> Capture
                </button>
                <button mat-button class="capture-btn dn-button-primary" (click)="save()" *ngIf="captures.length > 0">
                    <mat-icon>cloud_upload</mat-icon> Save
                </button>
            </div>
        </div>
        <div class="sidebar-container">
            <ng-container *ngFor="let image of captures; let i = index">
                <div class="image-container">
                    <img src="{{ image }}" alt="Image {{ i + 1 }}" class="captured-image" />
                    <button mat-icon-button class="remove-image-btn" (click)="removeImage(image)">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>

