<div class="flex flex-col gap-2">
    <div style="width: 100%; background: white">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="doc_name">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> File Name </th>
                <td mat-cell *matCellDef="let element">
                    {{element.file_name}}</td>
            </ng-container>
            <ng-container matColumnDef="doc_type">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> File Type </th>
                <td mat-cell *matCellDef="let element">
                    {{element.fileType}}</td>
            </ng-container>
            <ng-container matColumnDef="provider">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Provider </th>
                <td mat-cell *matCellDef="let element">
                    {{element.provider_id_ref.first_name}} {{element.provider_id_ref.last_name}}, {{element.provider_id_ref.title}}
                </td>
            </ng-container>
            <ng-container matColumnDef="facility">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Facility </th>
                <td mat-cell *matCellDef="let element">
                    <app-facility-title [facility]="element.facility_id_ref"></app-facility-title>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
</div>


<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
