import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse,} from '@angular/common/http';
import {IFacility} from '../shared/popup-dialogs/facility-add-dialog/facility-add.dialog';
import * as global from '../includes/global';
import {AuthService} from './auth.service';
import {API_URL} from "../../environments/api-url";

@Injectable({
    providedIn: 'root',
})
export class TitleService {
    currentUser: any;


    constructor(private httpclient: HttpClient,
                private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
    }

    getMyTitles(doctor_id = '') {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            doctor_id: doctor_id || this.currentUser._id
        };

        return this.httpclient.post(global.url + API_URL.AUDIO.getMyTitles, {title: details}).pipe();

    }

    // getAllFacilities() {
    //     const details = {
    //         id: this.currentUser._id,
    //         auth_key: this.currentUser.auth_key,
    //         usertype: this.currentUser.user_type
    //     };
    //     return this.httpclient.post(global.url + API_URL.FACILITY.getAllFacilities, {address: details});
    // }

    addNewTitle(title) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            title
        };

        return this.httpclient.post(global.url + API_URL.AUDIO.addTitle, {title: details}).pipe();
    }

    // updateFacility(faculty: IFacility) {
    //     const {_id, ...facultyData} = faculty;

    //     const details = {
    //         id: this.currentUser._id,
    //         auth_key: this.currentUser.auth_key,
    //         usertype: this.currentUser.user_type,

    //         address_id: _id,
    //         ...facultyData,
    //     };

    //     return this.httpclient.post(global.url + API_URL.FACILITY.updateAddress, {address: details}).pipe();
    // }

    // deleteFacility(faculty: IFacility) {
    //     const {_id} = faculty;

    //     const details = {
    //         id: this.currentUser._id,
    //         auth_key: this.currentUser.auth_key,
    //         usertype: this.currentUser.user_type,

    //         address_id: _id,
    //     };

    //     return this.httpclient.post(global.url + API_URL.FACILITY.deleteAddress, {address: details}).pipe();
    // }

}
