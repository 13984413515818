import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../global';
import { API_URL } from "../../../environments/api-url";

@Injectable({
  providedIn: 'root'
})
export class CertificationService {
  currentUser:any;
  constructor(
    authService: AuthService,
    private httpclient: HttpClient,
  ) {
    this.currentUser = authService.currentUser
  }
  
  uploadFilesForCertification(files: File): Observable<HttpEvent<any>> {
    const payload = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      // title: file.name, 
      company_id: this.currentUser.company_id,
    };
    const formData: FormData = new FormData();
    formData.append('files', files);

    for (let key of Object.keys(payload)) {
      formData.append(key, payload[key]);
    }

    const req = new HttpRequest('POST', global.url + API_URL.CERT.uploadFilesForCertification, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.httpclient.request(req);
  }

  // getFiles(): Observable<any> {
  //   return this.httpclient.get(`${this.baseUrl}/files`);
  // }

  extractData(){
    const payload = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      // title: file.name, 
      company_id: this.currentUser.company_id,
    };
    return this.httpclient.post(global.url + API_URL.CERT.extractData, {details: payload}).pipe()
  }
  checkData(){
    const payload = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      // title: file.name, 
      company_id: this.currentUser.company_id,
    };
    return this.httpclient.post(global.url + API_URL.CERT.checkData, {details: payload}).pipe()
  }
  getMIPs(){
    const payload = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      // title: file.name, 
      company_id: this.currentUser.company_id,
    };
    return this.httpclient.post(global.url + API_URL.CERT.getMIPs, {details: payload}).pipe()
  }

}
