import { Component, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { FileConversionService } from 'src/app/services/file-conversion.service';
import * as global from '../../global';
import { ToastrService } from 'ngx-toastr';
import { PatientAddService } from '../../patient-add/patient-add-service';
import { Router } from '@angular/router';
import { SocketService } from 'src/app/services/socket.service';
import { AuthService } from 'src/app/services/auth.service';
import * as usaStates from 'src/app/shared/popup-dialogs/facility-add-dialog/usa_states';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment'
import { CompanyService } from 'src/app/services/Modules/company.service';
import { SnfWcNurseAssociationService } from '../../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { CommonService } from 'src/app/services/common.service';
import { IPatientCoverage, setCoverageValues } from 'src/app/shared/interfaces/patient.coverages.interface';
import { insurance_order, insured_patient_relation } from 'src/app/classes/patient';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-patient-add-form-dialog',
  templateUrl: './patient-add-form-dialog.component.html',
  styleUrls: ['./patient-add-form-dialog.component.css'],
  providers: [DatePipe]
})

export class PatientAddFormDialogComponent implements OnInit {
  searchState: any;
  loading: boolean = false;
  // data members for add pateint form #mohsin-dev
  patient_id: string = "";
  full_name: string = "";
  first_name: string = "";
  race: string = "";
  patient_ssn: string = "";
  mrn: string = "";
  middle_name: string = "";
  last_name: string = "";
  email: string = "";
  phone: string = "";
  date_of_birth: any = null;
  last_admit_date: any = moment().toDate();
  inTake_date: any = moment().toDate();
  p_insurance_carrier: string = "";
  p_insurance_holder_name: string = "";
  p_insurancePolicyNumber: string = "";
  p_insuranceGroupNumber: string = "";
  
  s_insurance_carrier: string = "";
  s_insurance_holder_name: string = "";
  s_insurancePolicyNumber: string = "";
  s_insuranceGroupNumber: string = "";

  refer_provider_first_name = '';
  refer_provider_last_name = '';
  refer_provider_phone_number = '';
  refer_provider_fax_number = '';
  refer_provider_location = '';
  refer_provider_address = '';

  coverages: IPatientCoverage[] = []; 
  coveragesList = new MatTableDataSource([]) ;
  displayedColumnsInurance: string[] = ['ins_order', 'insurance_type', 'tin','insurance_plan_id','insured_group_employer_id','remove_ins'];
  newCoverage: IPatientCoverage;
  addInsuranceLabel = 'Add Insurance';
  plan_effective_date = null;
  plan_expiration_date = null;
  insured_patient_relation = insured_patient_relation;
  insurance_order = insurance_order;

  city: string = "";
  zip_code: string = "";
  address: string = "";
  state: string = "";
  responsible_party: string = "";
  responsible_party_contact: string = "";
  census_id: string = "";
  hospice_notes: string = "";
  VA_notes: string = "";
  
  isHospice: Boolean = false;
  isVA: Boolean = false;
  
  startDate: Date = new Date(1940, 0, 1);
  gender: string = "";
  currentUser: any;
  facilities: Array<any>;
  doctors: Array<any>;
  pcpFirstName: string = "";
  pcpLastName: string = "";
  pcpPhoneNumber: string = "";
  pcpFaxNumber: string = "";
  pcpTitle : string = "";
  pcpCredentials : string = "";
  facility_patient_mrn : string = '';
  @Input() selectedDoctorId: string = "";
  @Input() selectedFacilityId: string = "";
  @Input() showPhoneField: boolean = false;
  @Input() showEmailField: boolean = false;

  @Output() patientAdded = new EventEmitter<any>();




  isNew: boolean = true;
  patientToAdd: any = [];
  minDate = new Date(1900, 0, 1);
  maxDate = new Date();
  selectDate: Boolean = false;
  dataFromcencus: any = "";
  @Output() onSaveClick: EventEmitter<any> = new EventEmitter();
  @Output() onCancelClick: EventEmitter<any> = new EventEmitter();

  phoneCleaned = '';
  usaStates = usaStates.default;
  patientRoundingSheets: any[] = [];
  races = [
    { value: 'American Indian/Alaska Native' },
    { value: 'Asian' },
    { value: 'Black/African American' },
    { value: 'Native Hawaiian' },
    { value: 'White' },

  ]
  fileName: string = "";
  showRemoveFileButton: boolean = false;
  file: any;
  userCompany: any;
  date_of_birth_string: any;
  faceSheet: any;
  diagnosisArray: any;
  payerInformation = [];
  allergiesArray: any;
  constructor(
    private _fileConversionService: FileConversionService,
    private _toastr: ToastrService,
    private _patientAddServie: PatientAddService,
    private _router: Router,
    private _location: Location,
    private _date_pipe: DatePipe,
    private _socketService: SocketService,
    private _authService: AuthService,
    private _patientRoundingSheets: PatientRoundingSheetService,
    @Inject(MAT_DIALOG_DATA) public data: PatientAddFormDialogComponent,
    private dialogRef: MatDialogRef<PatientAddFormDialogComponent>,
    private companyService: CompanyService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private commonService :CommonService
  ) {

    // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUser = this._authService.currentUser;
    this.usaStates.shift();
  }
  async ngOnInit() {
    this.dataFromcencus = this.data;
    this.newCoverage = {} as IPatientCoverage;
    this.newCoverage = setCoverageValues(this.newCoverage)
    if (this.data) {
      this.selectedFacilityId = this.dataFromcencus.facility_id;
      this.selectedDoctorId = this.dataFromcencus.createdBy;
      console.log("this.selectedFacilityId : ", this.selectedFacilityId, this.selectedDoctorId);
      this.census_id = this.dataFromcencus.census_id;
      this._patientRoundingSheets.getCensusList(this.selectedFacilityId, this.selectedDoctorId||this.currentUser._id).subscribe((response: any) => {
        if (response.status === 200) {
          this.patientRoundingSheets = response.data.array;
        }
      });
    }
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.setItem('currentUser', 'null');
      this._router.navigate(['/login']);
      return;
    }
    this.getCompanyName();
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      doctor_id: <string>null,
      company_id: <string>null
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    item.company_id = this.currentUser.company_id;
    // for doctor
    if (this.currentUser.user_type == 1) {
      this.selectedDoctorId = this.currentUser._id;
    }
    if (this.selectedDoctorId) {
      this.initializeFacilitiesDropdown(this.selectedDoctorId);
    }


    // this._patientAddServie.getAssociatedDoctorsList(item).subscribe((response: any) => {
    //   if (response.status === 200) {
    //     // this.doctors = response.data.array; // array
    //     this.doctors = response.data; // array
    //   }
    // })

    if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER) { 
      if(this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER ){
        this._patientAddServie.getAssociatedDoctorsListforTrans(item).subscribe((response: any) => {
          if (response.status === 200) {
            // this.doctors = response.data.array; // array
            this.doctors = response.data; // array
          }
        });
      }else{
        this._patientAddServie.getAssociatedDoctorsList(item).subscribe((response: any) => {
          if (response.status === 200) {
            // this.doctors = response.data.array; // array
            this.doctors = response.data; // array
          }
        });
      }
    }

    // If editing exisitng
    const editPatient = localStorage.getItem('editPatient');
    if (editPatient) {

      const patientInfo = JSON.parse(editPatient)
      this.initPatient({ name: `${patientInfo.first_name} ${patientInfo.middle_name} ${patientInfo.last_name}`, ...patientInfo })
      this.patient_id = patientInfo._id;
      this.selectedDoctorId = patientInfo.primary_doctor_id;
      this.selectedFacilityId = patientInfo.facility_id;

      this.initializeFacilitiesDropdown(this.selectedDoctorId);

      localStorage.removeItem('editPatient');
      this.isNew = false;
    }


    if (typeof (this.last_admit_date) != 'string') {
      this.last_admit_date = moment(this.last_admit_date).format('YYYY-MM-DD');
    }
    if (typeof (this.inTake_date) != 'string') {
      this.inTake_date = moment(this.inTake_date).format('YYYY-MM-DD');
    }
  }
  /*
  * Extract text from PDF file and set to field values #mohsin-dev
  */

  importPatientFile(event) {
    var user = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }


    // ACTIVITY LOG
    this._socketService.addActivityLog({
      id: this.currentUser._id,
      screen: 'Patient Add',
      operation: `Import PDF Patient API Call`,
      datetime: this._date_pipe.transform(new Date(), global.date_time_format)
    })

    const [file] = <Array<File>>event.target.files;
    if (!file) return;
    var filename = file.name;
    const infoToastr = this._toastr.info('Importing patient...', "Please wait", { disableTimeOut: true });
    this._fileConversionService.makeFileRequest(user, global.url + '/importPatientFile', file, filename).then((result: any) => {
      if (result.status == 200) {
        if (this.initPatient(result.data.patient_info)) {
          this._toastr.success("Patient imported successfully", "Success");
        } else {
          this._toastr.error("No patient information found", "Failed")
        }
      } else {
        this._toastr.error(result.data.message, "Failed")
        console.error(result.message)
      }
      this._toastr.clear(infoToastr.toastId);
    }, console.error);
  }

  async getCompanyName() {
    const companyName = await this.companyService.getCompanyName(this.currentUser.company_id).toPromise()
    this.userCompany = companyName.data.name;
    this.mrn = this.commonService.generateUniqueString(this.userCompany);
  }

  initPatient(patient_info) {
    let isUpdated = false;
    const { name: full_name = "",
      first_name = "",
      race = "",
      middle_name = "",
      last_name = "",
      email,
      phone,
      patient_ssn,
      primary_practitioner_first_name,
      primary_practitioner_last_name,
      pcpPhoneNumber,
      pcpFaxNumber,
      pcpTitle,
      pcpCredentials,
      mrn,
      insurance,
      city,
      zip_code,
      address,
      state,
      responsible_party,
      responsible_party_contact,
      hospice_notes,
      isHospice,
      census_id,
      isVA,
      VA_notes,
      date_of_birth,
      date_of_birth_string,
      inTake_date,
      last_admit_date,
      gender,      
      refer_provider_first_name ,
      refer_provider_last_name ,
      refer_provider_phone_number,
      refer_provider_fax_number ,
      refer_provider_location ,
      refer_provider_address ,
    } = patient_info;

    this.full_name = full_name;

    if (first_name && last_name) {
      this.first_name = first_name;
      this.middle_name = middle_name;
      this.last_name = last_name;
      isUpdated = true;
    } else if (full_name) {
      const nameSplit = full_name.split(' ');
      if (nameSplit.length === 1) {
        this.first_name = full_name;
        isUpdated = true;
      } else if (nameSplit.length === 2) {
        this.first_name = nameSplit[0];
        this.last_name = nameSplit[1];
        isUpdated = true;
      } else if (nameSplit.length >= 3) {
        this.first_name = nameSplit[0];
        this.middle_name = nameSplit[1];
        this.last_name = nameSplit[2];
        isUpdated = true;
      }
    }
    if (race) {
      this.race = race;
    }

    if (patient_ssn) {
      this.patient_ssn = patient_ssn;
    }
    if (mrn) {
      this.mrn = mrn;
    }
    if (email) {
      this.email = email;
    }

    if (phone) {
      this.phone = phone;
    }

    if (isUpdated) {
      this.address = address;
      isUpdated = true;
    }
    if (date_of_birth_string) {
      this.date_of_birth = moment(date_of_birth_string);
      isUpdated = true;
    }
    if (inTake_date) {
      this.inTake_date = new Date(inTake_date);
      isUpdated = true;
    }
    if (last_admit_date) {
      this.last_admit_date = new Date(last_admit_date);
      isUpdated = true;
    }

    if (gender) {
      this.gender = gender;
      isUpdated = true;
    }
    if (insurance) {
      this.p_insurance_carrier = insurance.p_insurance_carrier;
      this.p_insurance_holder_name = insurance.p_insurance_holder_name;
      this.p_insurancePolicyNumber = insurance.p_insurancePolicyNumber;
      this.p_insuranceGroupNumber = insurance.p_insuranceGroupNumber;

      this.s_insurance_carrier = insurance.s_insurance_carrier;
      this.s_insurance_holder_name = insurance.s_insurance_holder_name;
      this.s_insurancePolicyNumber = insurance.s_insurancePolicyNumber;
      this.s_insuranceGroupNumber = insurance.s_insuranceGroupNumber;
      this.coverages = insurance.coverages ? insurance.coverages : [];
      this.coveragesList.data = this.coverages;
      this.newCoverage.ins_order = this.coverages.length+1;
    }
    if(refer_provider_first_name){
      this.refer_provider_first_name = refer_provider_first_name
    }
    if(refer_provider_last_name){
      this.refer_provider_last_name = refer_provider_last_name
    }
    if(refer_provider_phone_number){
      this.refer_provider_phone_number = refer_provider_phone_number
    }
    if(refer_provider_fax_number){
      this.refer_provider_fax_number = refer_provider_fax_number
    }
    if(refer_provider_location){
      this.refer_provider_location = refer_provider_location
    }
    if(refer_provider_address){
      this.refer_provider_address = refer_provider_address
    }
    if (city) {
      this.city = city;
      isUpdated = true;
    }
    if (zip_code) {
      this.zip_code = zip_code;
      isUpdated = true;
    }
    if (email) {
      this.email = email;
      isUpdated = true;
    }
    if (address) {
      this.address = address;
      isUpdated = true;
    }
    if (state) {
      console.log('stateeeeeeee', state);
      this.state = state;
      isUpdated = true;
    }
    if (responsible_party) {
      this.responsible_party = responsible_party;
      isUpdated = true;
    }
    if (responsible_party_contact) {
      this.responsible_party_contact = responsible_party_contact;
      isUpdated = true;
    }
    if (hospice_notes) {
      this.hospice_notes = hospice_notes;
      isUpdated = true;
    }
    if (census_id) {
      this.census_id = census_id;
      isUpdated = true;
    }
    if (primary_practitioner_first_name) {
      this.pcpFirstName = primary_practitioner_first_name;
      isUpdated = true;
    }

    if (primary_practitioner_last_name) {
      this.pcpLastName = primary_practitioner_last_name;
      isUpdated = true;
    }
    if(pcpPhoneNumber){
      this.pcpPhoneNumber = pcpPhoneNumber;
      isUpdated = true;
    }
    if(pcpFaxNumber){
      this.pcpFaxNumber = pcpFaxNumber;
      isUpdated = true;
    }
    if(pcpTitle){
      this.pcpTitle = pcpTitle;
      isUpdated = true;
    }
    if(pcpCredentials){
      this.pcpCredentials = pcpCredentials;
      isUpdated = true;
    }
    console.log('patientInfo', patient_info);
    return isUpdated;
  }

  /**
   * Saving (adding/updating generic) patient to db #mohsin-dev
   */
  savePatient(event, onSuccess?) {
    console.log('event', event);
    this.dataChanged(false);
    event.preventDefault();
    //if (!this.first_name || !this.last_name || !this.last_name ||  !this.selectedFacilityId || !this.currentUser._id) {
    //  return;
    //}
    // extract and format date if date object
    // const dateObject = new Date(String(this.date_of_birth));
    // if(String(this.date_of_birth) === dateObject.toString()) {
    //   const month = (`0${dateObject.getMonth()+1}`).slice(-2); // prepend 0 if single digit
    //   const date = (`0${dateObject.getDate()}`).slice(-2); // prepend 0 if single digit
    //   this.date_of_birth = `${dateObject.getFullYear()}-${month}-${date}`;
    // }
    // let formatted_date_of_birth;
    let formatted_inTake_date;
    let formatted_last_admit_date;
    // if (this.date_of_birth) {
    //   formatted_date_of_birth = this._date_pipe.transform(this.date_of_birth, global.date_format);
    // }
    if (this.date_of_birth) {
      let dateString = "";
      let year = new Date(this.date_of_birth).getFullYear();
      let month = new Date(this.date_of_birth).getMonth();
      let day = new Date(this.date_of_birth).getDate();
      dateString = `${month + 1}/${day}/${year}`
      this.date_of_birth_string = dateString;
      console.log('date_of_birth_string', this.date_of_birth_string);
      // this.date_of_birth_string = this._date_pipe.transform(this.date_of_birth, global.date_format);
    }
    if (this.inTake_date) {
      // formatted_inTake_date = this._date_pipe.transform(this.inTake_date, global.date_format);
      formatted_inTake_date = new Date(this.inTake_date).toISOString();
    }
    if (this.last_admit_date) {
      // formatted_last_admit_date = this._date_pipe.transform(this.last_admit_date, global.date_format);
      formatted_last_admit_date = new Date(this.last_admit_date).toISOString();
    }

    const patient: any = {
      // userInfo
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      company_id: this.currentUser.company_id,
      first_name: this.first_name,
      middle_name: this.middle_name,
      last_name: this.last_name,
      race: this.race,
      patient_ssn: this.patient_ssn,
      mrn: this.mrn,
      email: this.email,
      phone: this.phone,
      date_of_birth: this.date_of_birth,
      inTake_date: formatted_inTake_date,
      last_admit_date: formatted_last_admit_date,
      p_insurance_carrier: this.p_insurance_carrier,
      p_insurance_holder_name: this.p_insurance_holder_name,
      p_insurancePolicyNumber: this.p_insurancePolicyNumber,
      p_insuranceGroupNumber: this.p_insuranceGroupNumber,
      s_insurance_carrier: this.s_insurance_carrier,
      s_insurance_holder_name: this.s_insurance_holder_name,
      s_insurancePolicyNumber: this.s_insurancePolicyNumber,
      s_insuranceGroupNumber: this.s_insuranceGroupNumber,
      coverages : this.coverages,
      city: this.city,
      zip_code: this.zip_code,
      address: this.address,
      state: this.state,
      responsible_party: this.responsible_party,
      responsible_party_contact: this.responsible_party_contact,
      hospice_notes: this.hospice_notes,
      census_id: this.census_id,
      isHospice: this.isHospice,
      isVA: this.isVA,
      VA_notes: this.VA_notes,
      gender: this.gender,
      facility_id: this.selectedFacilityId,
      facility_id_ref: this.selectedFacilityId,
      primary_doctor_id: this.selectedDoctorId,
      is_active: 'true',
      date_of_birth_string: this.date_of_birth_string,
      pcpFirstName: this.pcpFirstName,
      pcpLastName: this.pcpLastName,
      pcpPhoneNumber: this.pcpPhoneNumber,
      pcpFaxNumber: this.pcpFaxNumber,
      pcpTitle : this.pcpTitle,
      pcpCredentials : this.pcpCredentials,
      refer_provider_first_name : this.refer_provider_first_name,
      refer_provider_last_name : this.refer_provider_last_name,
      refer_provider_phone_number : this.refer_provider_phone_number,
      refer_provider_fax_number : this.refer_provider_fax_number,
      refer_provider_location : this.refer_provider_location,
      refer_provider_address : this.refer_provider_address,
      facility_patient_mrn: this.facility_patient_mrn
    }

    if (this.file) {
      patient.faceSheet = this.file;
    }

    if (this.isNew) {
      this.addNewPatient(patient, onSuccess);
    } else {
      patient.patient_id = this.patient_id;
      this.updateExistingPatient(patient, onSuccess);
    }
  }
  /**
   * Update exiting patient to db #mohsin-dev
   */
  updateExistingPatient(patient, onSuccess?) {
    // ACTIVITY LOG
    this._socketService.addActivityLog({
      id: this.currentUser._id,
      screen: 'Patient Add',
      operation: `Update Patient API Call`,
      datetime: this._date_pipe.transform(new Date(), global.date_time_format)
    })
    const infoToastr = this._toastr.info('Updating patient...', "Please wait");
    this._patientAddServie.updatePatient(patient).subscribe((response: any) => {
      if (response.status === 200) {
        this._toastr.success(response.message, "Success");
        if (onSuccess) onSuccess.emit(patient);
      } else {
        this._toastr.error(response.message, "Failed");
      }
    }, console.error, () => this._toastr.clear(infoToastr.toastId))
  }
  /**
   * Add new patient to db #mohsin-dev
   */
  addNewPatient(patient, onSuccess?) {
    console.log('patien to add', patient);
    // ACTIVITY LOG
    patient.coverages = JSON.stringify(patient.coverages)
    const formData = new FormData();
    for (const key in patient) {
      formData.append(key, patient[key]);
    }

    this._socketService.addActivityLog({
      id: this.currentUser._id,
      screen: 'Patient Add',
      operation: `Add Patient API Call`,
      datetime: this._date_pipe.transform(new Date(), global.date_time_format)
    })
    const infoToastr = this._toastr.info('Adding new patient...', "Please wait");
    this._patientAddServie.addNewPatient(
      formData
      // phone: this.phoneCleaned
    ).subscribe(async(response: any) => {
      if (response.status === 200) {
        if(response.exists) {
          this._toastr.error(response.message, "error");
          this.dialogRef.close({exists : true,patient: response.data});
        }else {
          this._toastr.success(response.message, "Success");
          if (onSuccess) {
            console.log('response in patient add dialog', response);
            this.patientToAdd.push(response.data._id)
            this.handleAddToCensus(this.census_id, this.patientToAdd);
            onSuccess.emit(response.data);
          } else if (this.dataFromcencus) {
            this.patientToAdd.push(response.data._id)
            // await this.handleAddToCensus(this.census_id, this.patientToAdd);
            this._patientRoundingSheets.addPatientsToPatientRoundingSheet(this.census_id, this.patientToAdd)
              .subscribe((response: any) => {
                if (response.status === 200) {
                  this._toastr.success(response.message);
                  this.patientAdded.emit()
                  this.dialogRef.close(response.data);
                  console.log('yess');
                }
              })
          }
        }
      } else {
        this._toastr.error(response.message, "Failed");
      }
    }, console.error, () => this._toastr.clear(infoToastr.toastId))
  }

  async handleAddToCensus(census_id, patient_ids) {
    this._patientRoundingSheets.addPatientsToPatientRoundingSheet(this.census_id, patient_ids)
      .subscribe((response: any) => {
        if (response.status === 200) {
          this._toastr.success(response.message);
        }
      })
  }

  // generateUniqueString() {
  //   if (this.userCompany.indexOf(' ') >= 0) {
  //     var matches = this.userCompany.match(/\b(\w)/g);
  //     var acronym = matches.join('');
  //     // this.mrn = acronym.toLowerCase() + Math.floor(100000 + Math.random() * 900000);
  //     // this.mrn = acronym.toLowerCase() + Date.now().toString().slice(-6);
  //     this.mrn = acronym.toLowerCase() + Date.now().toString();

  //   } else {
  //     this.mrn = this.userCompany.toLowerCase() + Date.now().toString().slice(-6);
  //   }
  // }

  handleDoctorChange(event) {
    this.initializeFacilitiesDropdown(this.selectedDoctorId);
  }

  initializeFacilitiesDropdown(doctor_id) {
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      const filter = {
        associated_snf_wc_nurse_ids: this.currentUser._id,
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      this.loading = true;
      this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection).subscribe((facilitiesResponse: any) => {
        if (facilitiesResponse.status === 200) {
          facilitiesResponse.data.array = facilitiesResponse.data;
          this.facilities = facilitiesResponse.data.array;
          this.facilities = this.facilities.filter(f => f.source != 'PointClickCare' && f.source != 'MatrixCare');
          if (!this.selectedFacilityId) {
            this.selectedFacilityId = this.facilities[0]?._id || null;
          }
          this.getPatientRoundingSheets();
        }
        this.loading = false;
      });
    } else {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      doctor_id: <string>null
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;

    item.doctor_id = doctor_id;
    this.loading = true;
    this._patientAddServie.getFacilities(item).subscribe((response: any) => {
      if (response.status === 200) {
        this.facilities = response.data.array.filter(facility => !facility.pcc_facId);
        this.facilities = this.facilities.filter(facility => facility.source != 'PointClickCare' && facility.source != 'MatrixCare');
        if(!this.selectedFacilityId){
          this.selectedFacilityId = this.facilities[0]?._id||null;
        }
        this.getPatientRoundingSheets();
      }
      this.loading = false;
    });
    }
  }

  goBack() {
    this._location.back();
  }

  keyPressAlphanumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/^[a-zA-Z0-9]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }

  dataChanged(selectedDate) {
    if (!selectedDate) {

      this.selectDate = false;
    }
    else {

      this.selectDate = true;
    }
  }
  handlePhoneModelChange(value,field) {
    let cleaned = value.replace(/\D/g, '');
    console.log(cleaned);

    if (cleaned.length === 0) {
      cleaned = '';
    } else if (cleaned.length <= 3) {
      cleaned = cleaned.replace(/^(\d{0,3})/, '($1)');
    } else if (cleaned.length <= 6) {
      cleaned = cleaned.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (cleaned.length <= 10) {
      cleaned = cleaned.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      cleaned = cleaned.substring(0, 10);
      cleaned = cleaned.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    setTimeout(() => {

      this.phone = cleaned;
      this.phoneCleaned = cleaned;
      if(field == 'phone') {
        this.phone = cleaned;
        this.phoneCleaned = cleaned;
      } else if(field == 'refer_provider_phone_number') {
        this.refer_provider_phone_number = cleaned;
        this.phoneCleaned = cleaned;
      } 
    })
  }
  allowOnlyText(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (event.charCode == 45 || event.charCode == 32) {
      return true;
    }
    if (charCode > 90 && charCode < 97) {
      return false;
    }

    if (charCode < 65 || charCode > 122) {
      return false;
    }
    return true;
  }

  toggleHospice($event) {
    console.log('$event', $event);
    $event.source.checked = this.isHospice;
  }

  toggleVA($event) {
    console.log('$event', $event);
    $event.source.checked = this.isVA;
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  getPatientRoundingSheets() {
    if (this.currentUser.user_type == '1') {
      this._patientRoundingSheets.getCensusList(this.selectedFacilityId, this.currentUser._id).subscribe((response: any) => {
        if (response.status === 200) {
          this.patientRoundingSheets = response.data.array;
          console.log("this patient", this.patientRoundingSheets);
        }
      });
    }else{
      if(this.selectedDoctorId){
        this._patientRoundingSheets.getCensusList(this.selectedFacilityId, this.selectedDoctorId).subscribe((response: any) => {
          if (response.status === 200) {
            this.patientRoundingSheets = response.data.array;
            const currentCensus=this.dataFromcencus.census_id;
            if((this.patientRoundingSheets.findIndex((e)=>{return e._id?.toString()==currentCensus?.toString()}))<0){
              this.census_id=this.patientRoundingSheets[0]?._id;
            }
          }
        });
      }
    }
  }

  onSelectFile(e) {
    this.fileName = e.target.files[0].name;
    this.file = e.target.files[0];
    this.showRemoveFileButton = true;
    this.getPatientInfoFromFaceSheet();
  }

  getPatientInfoFromFaceSheet() {
    if (this.file) {
      const infoToast = this._toastr.info("Extracting Patient Info", "Please Wait ...", {
        disableTimeOut: true
      });
      this.loading = true;
      const formData = new FormData();
      formData.append("faceSheet", this.file);
      this._patientAddServie.getPatientInfoFromFaceSheet(formData).subscribe((response: any) => {
        console.log("extracted data: ", response);
        if (response.status === 200) {
          this._toastr.clear(infoToast.toastId);
          this.faceSheet = response.data;
          
          const { resident_info: residentInfo, diagnosis_information : diagnosisInformation, payer_information : payerInformation, other_information : otherInformation, care_providers : careProviders } = this.faceSheet;
          this.diagnosisArray = diagnosisInformation;
          this.allergiesArray = otherInformation;
          this.payerInformation.push(payerInformation);
          this.populateFormFields(residentInfo,payerInformation,careProviders);
          this._toastr.success("Face Sheet uploaded", "Success");
          this.loading = false;
        }else{
          this.loading = false;
          this._toastr.clear(infoToast.toastId);  
          // this._toastr.success('FaceSheet Uploaded.', "Success!");
          this._toastr.info('file uploaded no data being extracted.', "info!");
        }
      }, err => {
        this.loading = false;
        this._toastr.clear(infoToast.toastId);
        console.log("ERROR", err);
      });
    }
  }

  populateFormFields(residentInfo,payerInformation,careProviders) {
    if (residentInfo) {
      if (residentInfo.dob) {
        residentInfo.dob = moment(residentInfo.dob).format('YYYY-MM-DD');
        this.date_of_birth = residentInfo.dob;
      }
      if (residentInfo.patient_name) {
        if (residentInfo.patient_name.includes(",")) {
          const fullPatientName = residentInfo.patient_name.replace(",", "").split(" ");
          if(this.first_name == '') {
            this.first_name = fullPatientName[1];
          }
          if(this.last_name == '') {
            this.last_name = fullPatientName[0];
          }
        }
        else {
          if(this.last_name == '') {
            this.last_name = residentInfo.patient_name;
          }
        }
      }
      if (residentInfo.gender.toLowerCase() === 'f' || residentInfo.gender.toLowerCase() === 'female') {
        if(this.gender === ''){
          this.gender = "Female";
        }
      }
      else if (residentInfo.gender.toLowerCase() === 'm' || residentInfo.gender.toLowerCase() === 'male') {
        if(this.gender === ''){
          this.gender = "Male";
        }
      }
      const hasRace = this.races.map(race => race.value).includes(residentInfo.race);
      if (residentInfo.race && !hasRace) {
        if(this.race == ''){
          this.races.push({ value: residentInfo.race });
          this.race = residentInfo.race || "";
        }
      }
      if(this.patient_ssn == ''){
        this.patient_ssn = residentInfo.ss_no || "";
      }
      if(residentInfo.admission_date){
        this.last_admit_date=moment(residentInfo.admission_date).format('YYYY-MM-DD');
      }
      if(residentInfo.phone_no) {
        if(this.phone == '') {
          this.phone = residentInfo.phone_no;
        }
      }
      if(this.address == '') {
        this.address = residentInfo.prev_address;
      }
      //payer Information
      if(payerInformation.primary_payer) {
        if(this.p_insurance_carrier == '') {
          this.p_insurance_carrier = payerInformation.primary_payer.name ? payerInformation.primary_payer.name : '';
        }
      }
      if(payerInformation.secondary_payer) {
        if(this.s_insurance_carrier == '') {
          this.s_insurance_carrier = payerInformation.secondary_payer.name ? payerInformation.secondary_payer.name : '';
        }
      }
      if(careProviders.cell_no) {
        if(this.pcpPhoneNumber == '') {
          this.pcpPhoneNumber = careProviders.cell_no ? careProviders.cell_no : '';
        }
      }
      if(careProviders.fax) {
        if(this.pcpFaxNumber == '') {
          this.pcpFaxNumber = careProviders.fax ? careProviders.fax : '';
        }
      }
      if(careProviders.provider) {
        if(this.pcpFirstName == '') {
          this.pcpFirstName = careProviders.provider ? careProviders.provider : '';
        }
      }
    }
  }

  onDeselectFile(e) {
    this.fileName = "";
    this.file = null;
    this.showRemoveFileButton = false;
    e.value = "";
  }
  dateOfBirth($event) {
    this.date_of_birth = $event;
  }

  admissionDate($event) {
    this.last_admit_date = $event;
  }

  intakeDate($event) {
    this.inTake_date = $event;
  }
  setDateObjCoverage(field:string){
    if(field == 'plan_effective_date') {
      const selectedDate = moment(this.plan_effective_date); 
       this.newCoverage.plan_effective_date = this.commonService.splitMomentToObj(selectedDate);
    }
    if(field == 'plan_expiration_date') {
      const selectedDate = moment(this.plan_expiration_date); 
      this.newCoverage.plan_expiration_date = this.commonService.splitMomentToObj(selectedDate);
   }
  }
  addCoverage() {
    const existingCoverageIndex = this.coverages.findIndex(coverage => coverage.ins_order === this.newCoverage.ins_order);
    if (existingCoverageIndex !== -1) {
      this.coverages[existingCoverageIndex] = { ...this.newCoverage };
    } else {
      this.coverages.push({ ...this.newCoverage });
    }
    this.addInsuranceLabel = 'Add Insurance'
    this.clearNewCoverage();
    this.coveragesList.data = this.coverages;
    this.newCoverage.ins_order = this.coverages.length+1;
  }
  removeCoverage(index: number) {
    if (index >= 0 && index < this.coverages.length) {
      this.coverages.splice(index, 1); 
      this.coverages.forEach((coverage, idx) => {
        coverage.ins_order = idx + 1;
    });
    this.coveragesList.data = [...this.coverages]; 
    this.newCoverage.ins_order = this.coverages.length+1;
    }
  }
  
  // Function to clear newCoverage object
  clearNewCoverage() {
    this.newCoverage = setCoverageValues(this.newCoverage);
    this.plan_effective_date = null;
    this.plan_expiration_date = null;
    this.newCoverage.ins_order = this.coverages.length +1;
   }
  selectCoverage(insOrder: number) {
  const selectedCoverage :any = this.coverages.find(coverage => coverage.ins_order === insOrder);
  if (selectedCoverage) {
    if (selectedCoverage.plan_effective_date) {
      let year = selectedCoverage.plan_effective_date.year;
      let month = selectedCoverage.plan_effective_date.month;
      let day = selectedCoverage.plan_effective_date.day;
      if (month < 10) { month = '0' + month; }
      if (day < 10) { day = '0' + day; }
      this.plan_effective_date = (year + '-' + month + '-' + day);
    }
    if (selectedCoverage.plan_expiration_date) {
      let year = selectedCoverage.plan_expiration_date.year;
      let month = selectedCoverage.plan_expiration_date.month;
      let day = selectedCoverage.plan_expiration_date.day;
      if (month < 10) { month = '0' + month; }
      if (day < 10) { day = '0' + day; }
      this.plan_expiration_date = (year + '-' + month + '-' + day);
    }
    this.newCoverage = { ...selectedCoverage };
    this.addInsuranceLabel = 'Edit Insurance'
  }
  }
  getInsuranceType(insOrder: number): string {
    switch (insOrder) {
      case 1: return 'Primary';
      case 2: return 'Secondary';
      case 3: return 'Tertiary';
      default: return 'Other';
    }
  }
  
  insuredDateOfBirth (value) {
    this.newCoverage.insured_dob = value;
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.coveragesList.data, event.previousIndex, event.currentIndex);
    this.coverages.forEach((coverage, index) => {
      coverage.ins_order = index + 1;
    });
    this.coveragesList.data = this.coverages;
  } 
}
