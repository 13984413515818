<div class="container">
    <main style="display: flex; flex-direction: column;">
        <div class="search-block theme-form flex justify-start items-center gap-2"
            style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">
            <div class="flex justify-start items-center gap-4" style="width: 100% !important;">

                <mat-form-field style="color: black !important;" class="flex-grow">
                    <mat-label>Select Template Type</mat-label>
                    <mat-select [(ngModel)]="selectedType" name="type">
                        <mat-option *ngFor="let tempType of templateTypes" [value]="tempType.type"
                            (click)="onItemSelectType(tempType.type);">
                            {{tempType.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="color: black !important;" class="flex-grow" *ngIf="selectedType=='user_defined'">
                    <mat-label *ngIf="company_type != global.COMPANY_TYPE.SNF">Select Provider</mat-label>
                    <mat-label *ngIf="company_type === global.COMPANY_TYPE.SNF">Select SNF WC NURSE</mat-label>
                    <mat-select [(ngModel)]="selectedProvider" name="status" required>
                        <input  placeholder="Search" [(ngModel)]="searchTextProvider"
                            [ngModelOptions]="{standalone: true}" style="color:#000;height: 40px;padding-left: 5px; border: none; white-space: nowrap;" />
                        <mat-option *ngFor="let provider of providers | searchProvider : searchTextProvider"
                            [value]="provider._id" (click)="onItemSelectProvider(provider._id);">
                            {{provider.full_name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="color: black !important;" class="flex-grow" *ngIf="selectedType=='user_defined'">
                    <mat-label>Select Facility</mat-label>
                    <div class="flex justify-start items-center">
                        <img src="../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacility(selectedFacility)" width="20px" height="20px"
                            alt="">
                        <mat-select [(ngModel)]="selectedFacility" name="facility">
                            <input  placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                                style="color:#000;height: 40px;padding-left: 5px; border: none; white-space: nowrap;" (keydown)="$event.stopPropagation()" />
                            <mat-option *ngFor="let facility of facilities | searchFacility: searchText" [value]="facility._id"
                                (click)="onItemSelectFacility(facility._id);">
                                <app-facility-title [facility]="facility"></app-facility-title>
                            </mat-option>
                        </mat-select>
                    </div>
                </mat-form-field>

                <div *ngIf="selectedType == 'system_defined'">
                    <button class="dn-button-primary" mat-raised-button (click)="addNewTemplate()"
                        style="margin-left: 15px;">
                        Add New Template
                    </button>
                </div>
            </div>
        </div>

        <div style="margin-top: 20px; overflow: auto;">
            <div class="white-box flex gap-2" style="margin-bottom: 5px; width: 100%;height: 52px;">
                <div style="width: 100%; display: flex;align-items: center;">
                    <span style="width: 40%; text-align: start;margin-left: 10px;font-weight: 500" class="title">
                        Title
                    </span>
                    <span style="width: 20%; text-align: start;font-weight: 500" class="title">
                        Created on
                    </span>
                    <span style="width: 20%; text-align: start;font-weight: 500" class="title">
                        Last updated on
                    </span>
                    <span style="width: 20%; text-align: start;font-weight: 500" class="title">
                        Last Updated By
                    </span>
                </div>
            </div>
            <ng-container *ngFor="let row of allTemplates; let i = index" style="display: flex;flex-direction: column;">
                <div class="even-row" [ngClass]="(i%2 == 0)?'even-row':'odd-row'" *ngIf="row.is_deleted != 'true'">
                    <div class="title" style="width: 40%;margin-left: 10px">{{row.title | uppercase}}</div>
                    <div class="title" style="width: 20%;">
                        {{getCreationDate(row)}}
                    </div>
                    <div class="title" style="width: 20%;">
                        {{getUpdationDate(row)}}
                    </div>
                    <div class="title" style="width: 10%;">{{row?.updated_by?.first_name}} {{row?.updated_by?.last_name}}</div>
                    <div class="title" style="width: 3%;">
                        <i style="margin-right: 10px;  align-self: center;" class="icon icon-edit" matTooltip="Edit"
                            *ngIf="row.template_type != 'user_defined' " matTooltipPosition="above"
                            (click)="onEditClick(row)"></i>
                    </div>
                    <div class="title" style="width: 3%;" *ngIf="selectedType == 'user_defined'">
                        <i style="margin-right: 10px;  align-self: center;" class="icon icon-edit" matTooltip="View"
                            matTooltipPosition="above" (click)="onViewClick(row)"></i>
                    </div>
                    <div class="title" style="width: 3%;">
                        <i style="margin-right: 10px;  align-self: center;" class="icon icon-delete" matTooltip="Delete"
                            matTooltipPosition="above" (click)="onDeleteClick(row)"></i>
                    </div>
                    <div class="title" style="width: 3%;" *ngIf="row.is_locked && selectedType == 'system_defined'">
                        <mat-icon style="color: #989898; cursor: pointer;" (click)="onLockClick(row,false)">lock
                        </mat-icon>
                    </div>
                    <div class="title" style="width: 3%;" *ngIf="!row.is_locked && selectedType == 'system_defined'">
                        <mat-icon style="color: #989898; cursor: pointer;" (click)="onLockClick(row,true)">lock_open
                        </mat-icon>
                    </div>
                </div>
            </ng-container>
        </div>
    </main>
</div>