import { Component, DoCheck, Input, OnInit, ViewChild } from '@angular/core';
import { BillerDashboardService } from '../biller-dashboard.service';
import { BillerProviderAssociationService } from '../../biller-provider-association/biller-provider-association.service';
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table"
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import moment from "moment";
import { FormGroup, FormControl } from '@angular/forms';
import { ChargesPopupComponent } from '../charges-popUp/charges-popup/charges-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from "ngx-toastr";
import { CommonService } from 'src/app/services/common.service';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as global from "../../global"
import { ViewNoteDialog } from '../../transcription-create/view-note-dialog/view-note-dialog.component';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { PatientDocumentsUploadDialog } from '../../patient-documents-list/patient-documents-upload-dialog/patient-documents-upload-dialog.component';
import { FaceSheetService } from '../../patient-detail/facesheet/face-sheet.service';
import { PdfViewerPopComponent } from '../../patient-documents-list/pdf-viewer-pop/pdf-viewer-pop.component';
import swal from 'sweetalert';
import { AuditPopupComponent } from '../audit-popup/audit-popup/audit-popup.component';
import { lastValueFrom } from 'rxjs';
import { PCCService } from 'src/app/services/pcc.service';
import { FileExplorerService } from '../../drive/file-explorer/file-explorer.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { API_URL } from '../../../../environments/api-url'
import { HttpResponse } from '@angular/common/http';
import { SnfWcNurseAssociationService } from '../../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { NotificationsService } from '../../notifications/notifications.service';


@Component({
  selector: 'app-biller-dashboard',
  templateUrl: './biller-dashboard.component.html',
  styleUrls: ['./biller-dashboard.component.css']
})
export class BillerDashboardComponent implements OnInit {

  displayedColumns = ['select', 'visit', 'time', 'create_time', 'patient_name', 'location',
    //  'operation',
    'audit', 'biller_note',
    'view', 'edit']
  displayedColumns2 = ['provider']
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('myCheckbox') myCheckbox: MatCheckbox;
  dataSource: any;
  dataSource2: any;
  loading: Boolean = true;
  firstDoctor: any;
  dataLoaded: Boolean = false;
  // loaderId1 = 'loader-01';
  currentUser: any;
  picker: any;
  filterDropdownActive = false;
  dropdownSettings: any = {};
  closeDropdownSelection = true;
  disabled = false;
  facilities: any = [];
  allFacilities: any;
  selectedFacility: any;
  dataToPass: any;
  selectedProvider: any;
  selectedpatients: any;
  completedCharges: any;
  allPatients: any;
  providers: any;
  selectedDOV: any;
  patientNameModel: any = '';
  DOSModel: any = '';
  facilityModel: any = '';
  Modelfacility: any;
  selectedProviderRow: any;
  chargesPopup: any;
  auditPopup: any;
  filter: Boolean = false;
  timeoutId: any;
  filters = {
    patient: '',
    dos: {
      startDate: moment(moment().subtract(14, 'days').toLocaleString()).format('MM/DD/YYYY'),
      endDate: moment(moment().toLocaleString()).format('MM/DD/YYYY')
    },
    facility: '',
    provider: '',
    facesheetFilter: 'all',
    // chargeType: 'submit'
    chargeType: 'draft',
    billStatus : 'all'
    // is_archived: false
  }
  loaderId = 'app-biller-dashboard';
  filterChargesPatient: any = [];
  filterChargesDate: any = [];
  filterChargesFacility: any = [];
  calenderDate: any;
  admin: Boolean = false;
  chargesIDs = [];
  selectedChargesToSubmit = [];
  selectedChargesWithUnsignedNote = [];
  selectedChargesMissingCPTICD = [];
  selectedChargesOfVoidCensus = [];
  selectedChargesWithoutNote = [];
  selectAllCheck = false;
  timeUp = false;
  chargesResponse: any;
  draftCount: any;
  submitCount: any;
  billedCount: any;
  companyId: any = "";
  searchText: any;
  current_provider_id: any;
  submitChargeFlag: any;
  checkbox_status = false;
  selectedBillingCompanies = []
  selectedChargeToSend = ""
  billingComapny;
  searchTextProvider: any;
  public pagination: {
    pageNo: number,
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };
  global = global;
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  currentDate = moment(new Date());
  signedNotesStatus: any;
  selected = '';
  isBillerDashboard: boolean = false;
  canSubmitChargeWithMupltipleCpts: boolean = false;

  @Input() chargeStatus: string;
  @Input() faceSheetStatus: string;
  @Input() billStatus: string;
  @Input() providerFromCensus: string;
  faceSheetFilter: string = 'all';
  filter_provider_id = "";
  providersList: any[];
  editingCharge: boolean = false;
  generateReport: boolean = false;
  charge_edit_duration = 0;
  canEdit = false;
  chargeCaptureNotificationData: any = {};
  isChargeCaptureNotificationData: boolean = false;
  notification_response: any;

  constructor(
    public billerDashboardService: BillerDashboardService,
    public billerProviderAssociationService: BillerProviderAssociationService,
    private _router: Router,
    private loader: NgxUiLoaderService,
    private dialog: MatDialog,
    private _faceSheetService: FaceSheetService,
    private _route: ActivatedRoute,
    private commonService: CommonService,
    // public loader1: NgxUiLoaderService,
    private _authService: AuthService,
    private toaster: ToastrService,
    private _company: CompanyService,
    private chargesPopupComponent: ChargesPopupComponent,
    private auditPopupComponent: AuditPopupComponent,
    private pccService: PCCService,
    private toastr: ToastrService,
    private fileExplorerService: FileExplorerService,
    private dashboardService: DashboardService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _notificationsService: NotificationsService,
    private companyService: CompanyService,


  ) {
    this.currentUser = this._authService.currentUser;
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 0
    };

  }
  // ngDoCheck(): void {
  //   let value = this.chargesPopupComponent.submitCharge.getValue()
  //   if(value){
  //     console.log("value of submitCharge is: ", value);

  //   }
  // }

  async initProviders() {
    if (this.currentUser.user_type === global.USER_TYPE.DOCTOR || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      let response: any;
      const filter = {
        snf_wc_nurse_id: this.currentUser._id,
        company_id: this.currentUser.company_id
      };
      const project = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      if (this.current_provider_id) {
        const userDetail: any = await this.dashboardService.getUserDetail(this.current_provider_id).toPromise();
        if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
          response = await lastValueFrom(this._snfWcNurseAssociationService.getAssociatedSnfWcNurses(filter, project));
          if (response.status === 200) {
            response.data.assoc_provider_id = response.data.associated_snf_wc_nurse_ids || [];
          }
        }
        else {
          response = await lastValueFrom(this.dashboardService.getP2PRelation(null, this.current_provider_id));
        }
        if (response.status == 200) {
          this.providersList = [userDetail.data.array].concat(response.data.assoc_provider_id);
          const isProviderId = localStorage.getItem('filter_provider_id');
          if (!isProviderId) {
            this.filter_provider_id = this.current_provider_id;
          }
        }
      } else {
        if (this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
          response = await lastValueFrom(this._snfWcNurseAssociationService.getAssociatedSnfWcNurses(filter, project));
          if (response.status === 200) {
            response.data.assoc_provider_id = response.data.associated_snf_wc_nurse_ids || [];
          }
        }
        else {
          response = await lastValueFrom(this.dashboardService.getP2PRelation());
        }
        if (response.status == 200) {
          this.providersList = [this.currentUser].concat(response.data.assoc_provider_id);
          const isProviderId = localStorage.getItem('filter_provider_id');
          if (!isProviderId) {
            this.providersList.forEach((element) => {
              if (element._id == this.currentUser._id) {
                this.filter_provider_id = element._id;
                this.firstDoctor = this.filter_provider_id;
              }
            });
          }
        }
      }
    }
    if(this.providerFromCensus) {
      this.firstDoctor = this.providerFromCensus;
      this.filter_provider_id = this.providerFromCensus;
    }
  }

  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }
  getValue(element) {
    if (element.billingCompanyId?.name) {
      return `Send to ${element.billingCompanyId.name}`
    } else {
      return '';
    }
  }

  auditClicked(element) {
    let charge_id = element._id;
    this.auditPopup = this.dialog.open(AuditPopupComponent, { data: charge_id, width: '40%', maxHeight: "40%" })
    this.auditPopup.afterClosed().subscribe(async result => {
      // let value = this.chargesPopupComponent.submitCharge.getValue()
      // let value = this.chargesPopupComponent.chargeSubmitted;
      // if(this.submitChargeFlag){
      //   
      // dialogSubscription.unsubscribe()
      // this.initProviderSide()
      // }
    });
  }
  async sendToBilling() {
    // console.log("Sending to Billing Company: ",this.selectedBillingCompanies );
    // console.log("Sending to Billing Company: ",element );
    this.toaster.info("Sending Data")
    let response: any = await this.billerDashboardService.sendDataToBillingCompany(this.selectedChargeToSend, this.selectedBillingCompanies).toPromise();
    if (response.status == 200) {
      // console.log("Data Uploaded")
      this.selectedBillingCompanies = [];
      this.selectedChargeToSend = ""
      this.toaster.success('Data Uploaded')
    }
  }
  getVisitDate(element) {
    // console.log("Element----: ", element)
    if (element.date_obj) {
      // console.log("element.date_obj: ", element.date_obj);
      let { year, month, day, hour, minute } = element.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      let str = `${month}/${day}/${year}`;
      return str
    }
    else {
      let timeZone = 'America/New_York'
      if (element.facility_id_ref?.pcc_timeZone) {
        timeZone = element.facility_id_ref.pcc_timeZone
      }
      else if (element.facility_id_ref?.timeZone) {
        timeZone = element.facility_id_ref.timeZone
      }
      return moment(new Date(element.visit_date).toLocaleString('en-US', { timeZone: timeZone })).format('MM/DD/YYYY')
    }
  }

  async getCompanySettingForSubmittingCharge() {
    const res : any = await lastValueFrom(this.companyService.getCompany({_id : this.currentUser.company_id},{allow_multiple_cpts:1}));
    if(res.status == 200) {
      this.canSubmitChargeWithMupltipleCpts = res.data.allow_multiple_cpts;
    }
  }

  async ngOnInit() {
    if(this.currentUser.portal_type === global.PORTAL_TYPE.NON_WC) {
      await this.getCompanySettingForSubmittingCharge();
    }
    if (this._router.url.indexOf('/dashboard') > -1) {
      this.isBillerDashboard = false;
      if (this._route.snapshot.queryParams.providerId) {
        this.firstDoctor = this._route.snapshot.queryParams.providerId;
        // this.filter_provider_id = this._route.snapshot.queryParams.providerId;
        // this.filters.provider = this._route.snapshot.queryParams.providerId;
      }
      // this.getCount(true);
    } else {
      this.isBillerDashboard = true;
    }
    if (this.chargeStatus) {
      this.filters.chargeType = this.chargeStatus;
      this.faceSheetFilter = this.faceSheetStatus;
      this.filters.facesheetFilter = this.faceSheetStatus;
    }
    if(this.billStatus) {
      this.filters.billStatus = this.billStatus;
    }
    const isPatientName = localStorage.getItem('filter_patient_name');
    if (isPatientName) {
      this.filters.patient = isPatientName;
    }
    const statusCharges = localStorage.getItem('selectedFilter-biller-dashboard');
    if (statusCharges!==null) {
      this.filters.chargeType = statusCharges;
    }
    const isChargesDOS = JSON.parse(localStorage.getItem('filter_charges_dos'));
    if (isChargesDOS) {
      this.filters.dos = {
        startDate: moment(moment(isChargesDOS.startDate).toLocaleString()).format('MM/DD/YYYY'),
        endDate: moment(moment(isChargesDOS.endDate).toLocaleString()).format('MM/DD/YYYY')
      }
    }
    const isProviderId = localStorage.getItem('filter_provider_id');
    if (isProviderId) {
      this.filter_provider_id = isProviderId;
      this.firstDoctor = isProviderId;
    }
    const isFacilityId = localStorage.getItem('filter_facility_id');
    if (isFacilityId) {
      this.filters.facility = isFacilityId;
    }
    const isFacesheetFilter = localStorage.getItem('filter_facesheet');
    if (isFacesheetFilter) {
      this.filters.facesheetFilter = isFacesheetFilter;
      this.faceSheetFilter = isFacesheetFilter;
    }

    this.notification_response = this._notificationsService.castChargeNotificationData.subscribe((data) => {   // if provider is already on dashboard screen, "chargeCaptureNotificationData" helps to navigates to addendum screen of dashboard when click on charge capture notifictaion which is sent by biller
      this.chargeCaptureNotificationData = data;
      if (this.chargeCaptureNotificationData.data) {
        this.isChargeCaptureNotificationData = true;
        this.filters.chargeType = 'billed';
        this.filters.billStatus = 'addendum_req';
        this.filters.dos.startDate = this.chargeCaptureNotificationData.data.startDate;
        this.filters.dos.endDate = this.chargeCaptureNotificationData.data.endDate;
        this.filter_provider_id = this.chargeCaptureNotificationData.data.provider;
        this.filters.facility = this.chargeCaptureNotificationData.data.facility;
        this.applyFilterFacility({value: this.chargeCaptureNotificationData.data.facility})
      }
  });

    this._notificationsService.setChargeNotificationData({});
    let res:any = await lastValueFrom(this._company.getCompanyChargeandSignNoteStatus(this.currentUser.company_id) as any)
    // .subscribe((res: any) => {
      if (res.status == 200) {
        this.signedNotesStatus = res.data[0].should_sign_note;
      }
    // });
    this.dataSource = new MatTableDataSource([]);
    if (this.filters.chargeType == 'submit') {
      this.displayedColumns = ['visit', 'time', 'create_time', 'patient_name', 'location', 'face_sheet', 'audit', 'biller_note', 'cpt_code', 'icd_code', 
      // 'timer', 
      'view', 'edit', 'delete',
        // 'operation'
      ]
    }
    else if (this.filters.chargeType == 'draft') {
      this.displayedColumns = ['select', 'visit', 'time', 'create_time', 'patient_name', 'location', 'face_sheet', 'audit', 'cpt_code', 'icd_code', 'biller_note', 'note', 'view', 'edit', 'delete']
    }
    if (window.location.pathname.includes('company') || window.location.pathname.includes('admin')) {
      this.admin = true;
      this.companyId = this._route.parent.parent.snapshot.params.id;
      this._route.snapshot.paramMap.get('provider_id') ? this.current_provider_id = this._route.snapshot.paramMap.get('provider_id') : null;
    }
    else {
      this.admin = false;
      this.companyId = this._authService.currentUser.company_id;
    }
    if (!this.firstDoctor) {
      this.firstDoctor = this._authService.currentUser._id;
      this.selectedProvider = this.currentUser;
      if (this.admin) {
        this.firstDoctor = this.current_provider_id;
      }
    }
    
    // this.toaster.info('Fetching Charge Captures...')
    this.loading = true
    this.dropdownSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    // this.loader.startLoader('loader');
    this.dataSource2 = new MatTableDataSource([]);
    this.dataSource2.sort = this.sort;
    this.dataSource2.paginator2 = this.paginator2;
    await this.getEditChargeDuration()
    await this.initProviders();
    await this.getFacilitiess();
    // await this.getBillingComapnyAssociation(this.firstDoctor)
    if (this.currentUser.user_type == 6) {
      this.displayedColumns.push('checkBox')
      await this.initBillerSide()
    }
    else {
      // await this.initProviderSide();
    }
    // console.log(this.displayedColumns);
  }

  initBillerSide() {
    this.billerDashboardService.getProvidersCharges().subscribe((response: any) => {
      if (response.status == 200) {
        this.providers = response.data;
        if (response.data.length > 0) {
          this.selectedProviderRow = response.data[0]._id;
          this.firstDoctor = response.data[0]._id;
          this.selectedProvider = response.data[0];
        }
        this.dataSource2.data = response.data;
        // this.loader.stopLoader('loader')
      }
      if (this.firstDoctor) {
        this.getPatientCharges(null, this.firstDoctor);
      }
      // this.billerDashboardService.getFacilities(this.firstDoctor).subscribe((response: any) => {
      //   if (response.status == 200) {
      //     this.allFacilities = response.data;
      //   }
      // })
      // this.loading = false;
    })
  }

  async handleproviderChange(provider_id) {
    localStorage.setItem('filter_provider_id', provider_id.value);
    this.firstDoctor = provider_id.value;
    await this.getFacilitiess();
  }
  getLengthDraft() {
    // if(this.filters.chargeType == 'draft'){
    //   return this.selectedpatients?.length;
    // }
    // else{
    //   return this.chargesResponse?.draftCount;
    // }
    return this.chargesResponse?.draftCount;
  }

  getLengthSubmitted() {
    // if(this.filters.chargeType == 'submit'){
    //   return this.selectedpatients?.length;
    // }
    // else{  
    //   return this.chargesResponse?.submittedCount;
    // }
    return this.chargesResponse?.submittedCount;
  }
  async initProviderSide(page = 1) {
    this.dataLoaded = false;
    this.loading = true;
    await this.getBillingComapnyAssociation(this.firstDoctor);
    await this.getCount();
    await this.getPatientCharges(1, this.firstDoctor)

    // this.loading = false;
  }

  async getEditChargeDuration(){  
    let response:any = await lastValueFrom(this.billerDashboardService.getEditChargeDuration() as any)
    if(response.status == 200){
      this.charge_edit_duration = response.charge_edit_duration;
    } 
  }

  async getBillingComapnyAssociation(doctor = null) {
    // this.firstDoctor = 
    if (this.filters.chargeType == "submit") {
      let screen = 'biller-dashboard'
      let response: any = await this.billerDashboardService.getBillingComapnyAssociation(doctor, this.filters.facility).toPromise()
      if (response.status == 200) {
        if (response.data && response.data.length > 0) {
          let dataa = response.data;
          dataa = dataa.filter(data => { if (data.billingCompanyId) return data });
          let coms = [];
          this.billingComapny = [];
          dataa.forEach(com => { 
              if(com.association_type == 'single'){
                let index = coms.findIndex((i => i.billingCompanyId.name === com.billingCompanyId.name))
                if(index < 0){
                  coms.push(com)
                } 
              }
              else if(com.association_type == 'all'){ 
                let index = coms.findIndex((i => i.billingCompanyId.name === com.billingCompanyId.name))
                if(index < 0){
                  coms.push(com)
                } 
              }
              if(this.filters.facility == ''){
                this.billingComapny = dataa;
              }
              else{
                this.billingComapny = coms; 
              }
            });
        }
        if (this.billingComapny && this.billingComapny.length > 0) {
          if (!this.displayedColumns.includes('approve')) {
            this.displayedColumns.push('approve')
          }
        } else {
          if (this.displayedColumns.includes('approve')) {
            this.displayedColumns = this.displayedColumns.filter(column => column != 'approve')
          }
        }
      }
    }
    else {
      return;
    }
  }

  async getFacilitiess() {
    let response: any
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      const filter = {
        associated_snf_wc_nurse_ids: this.firstDoctor,
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        source: 1,
        pcc_2_legged_authentication: 1
      };
      response = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (response.status === 200) {
        response.data.array = response.data;
      }
    }
    else {
      response = await lastValueFrom(this.billerDashboardService.getFacilitiesForProviders(this.firstDoctor,null, this.companyId));
      // this.loader.stopLoader('loader');
    }
    if (response.status == 200) {
      this.allFacilities = response.data.array;
      const isFacilityId = localStorage.getItem('filter_facility_id');
      if (!isFacilityId) {
        this.filters.facility = '';
      }
      this.facilities = this.allFacilities.map(facility => {
        return facility.title;
      });
      await this.getBillingComapnyAssociation(this.firstDoctor)
      // this.initProviderSide(); 
      await this.getCount(true);


      this.getPatientCharges(1, this.firstDoctor)
    }
    // });
  }
  async getCountBiller() {
    let doctorId;
    if (this._route.snapshot.queryParams.providerId) {
      doctorId = this._route.snapshot.queryParams.providerId;
    } else {
      doctorId = this._authService.currentUser._id;
    }
    if (this.admin) {
      doctorId = this.current_provider_id;
    }
    let newFilter = JSON.parse(JSON.stringify(this.filters));
    newFilter.facesheetFilter = 'all';
    let res: any = await this.billerDashboardService.getCount(doctorId, newFilter).toPromise()
    if (res.status == 200) {
      this.chargesResponse = res;

      this.submitCount = res.submittedCount;
      this.draftCount = res.draftCount;
      this.billedCount = res.billedCount;

    }
  }
  async getCount(updateAll = true) {
    let doctorId = this.firstDoctor;
    if (this.admin) {
      doctorId = this.firstDoctor;
    }
    let res: any = await this.billerDashboardService.getCount(doctorId, this.filters).toPromise()
    if (res.status == 200) {
      this.chargesResponse = res;
      if ( (this.filters.facesheetFilter == 'all' && updateAll) || this.filters.facesheetFilter == 'upload') {     // $&&updateAll
        console.log('res.........................',res);
        
        this.submitCount = res.submittedCount;
        this.draftCount = res.draftCount;
        this.billedCount = res.billedCount;

      }
      if (this.filters.chargeType == 'submit') {
        this.pagination.totalCount = Number(res.submittedCount);
      }
      else if (this.filters.chargeType == 'billed') {
        this.pagination.totalCount = Number(res.billedCount);
      }
      else {
        this.pagination.totalCount = Number(res.draftCount);
      }
      // this.initPagination();
    }

    // })
  }
  changeChargeType() {
    localStorage.setItem("selectedFilter-biller-dashboard", this.filters.chargeType);
    this.chargesIDs = [];
    // this.filters.facility = '';
    // this.filters.patient = '';
    // this.filters.dos = '';

    if (this.filters.chargeType == 'submit') {
      this.displayedColumns = ['visit', 'time', 'create_time', 'patient_name', 'location', 'face_sheet', 'audit', 'cpt_code', 'icd_code', 'biller_note',
      //  'timer',
        // 'operation',
        'view', 'edit', 'delete']
        this.setFilterBillStatus();
    }
    if (this.currentUser.user_type == '6') {
      this.displayedColumns.push('checkBox')
    }
    if (this.filters.chargeType == 'draft') {
      this.displayedColumns = ['select', 'visit', 'time', 'create_time', 'patient_name', 'location', 'face_sheet', 'audit', 'cpt_code', 'icd_code', 'biller_note', 'note', 'view', 'edit', 'delete']
      this.setFilterBillStatus();
    }
  }

  async onChangeBillStatus($event) {
    this.filters.billStatus = $event.value;
    await this.getCount();
    await this.getPatientCharges(this.pagination.pageNo, this.firstDoctor);
  }

  setFilterBillStatus(){
    this.filters.billStatus = 'all';
  }
  editChargeClicked(charge) {
    // console.log("charge---------------: ", charge);
    this.editingCharge = true;
    let date_obj;
    if (charge.date_obj) {
      // console.log("charge.date_obj: ", charge.date_obj);
      let { year, month, day, hour, minute } = charge.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      let str = `${month}-${day}-${year}`;
      if (hour && minute) {
        if (minute < 10) {
          minute = `0${minute}`
        }
        if (hour < 10) {
          hour = `0${hour}`
        }
        str += ` ${hour}:${minute}`
      }
      date_obj = str;
    }
    let obj = {
      cpts: charge.cpt_id,
      icds: charge.icd_id,
      status: charge.status,
      // owner_id : charge.owner_id,
      // patient : charge.patient_id,
      // visit_date : charge.visit_date,
      // rounding_sheet_id : charge.rounding_sheet_id,
      measure: charge.measure,
      // billing_note : charge.billing_note,
      note: charge.note,
      _id: charge._id,
      parent: 'biller-dashboard'
    }
    this.commonService.setDraftedCharge(charge);
    this._router.navigate(['/patient-charge'], {
      queryParams: {
        patient_id: charge.patient_id,
        rs_id: charge?.rounding_sheet_id?._id,
        visit_date: charge.visit_date.toString(),
        patient_name: charge.patient_first_name + ' ' + charge.patient_last_name,
        dob: charge.patient_dob,
        // facility_id: patient.facility_id
        facility_id: charge.facility_id_ref._id,
        charge_id: charge._id,
        date_obj,
        backCtrl: charge.status == 'submit'? this.canEdit : false
      }
    });
  }

  handleNextClick() {
    this.chargesIDs = [];
    this.getPatientCharges(this.pagination.pageNo + 1, this.firstDoctor);
  }

  handlePrevClick() {
    this.chargesIDs = [];
    this.getPatientCharges(this.pagination.pageNo - 1, this.firstDoctor);
  }
  getcountofPatients() {
    return ((this.pagination.pageNo * 10) - 9) + ' - ' + (((this.pagination.pageNo * 10) - 10) + Number(this.allPatients.length)) + " out of " + (this.pagination.totalCount);
  }
  initPagination() {
    this.pagination.totalPages = Math.ceil(this.pagination.totalCount / 10);
    // console.log("this.pagination.pageNo: ", this.pagination.pageNo);
    // console.log("this.pagination.totalPages: ", this.pagination.totalPages);
    if (this.allPatients) {
      this.pagination.hasNextPage = this.pagination.pageNo < this.pagination.totalPages;
    }
    this.pagination.hasPrevPage = this.pagination.pageNo > 1;
    // this.loader.stopLoader('loader-01');
    this.loading = false;
  }

  async getPatientCharges(page, fDoctor = null) {
    // this.toaster.info('Fetching Charges...')
    this.loading = true;
    // this.loader.startLoader('loader')
    // await this.getBillingComapnyAssociation(this.firstDoctor)
    if (fDoctor) {
      this.firstDoctor = fDoctor;
    }
    else if (this.admin) {
      this.firstDoctor = "";
      fDoctor = this.current_provider_id;
    }
    // if(this.filters.chargeType == 'archive'){
    //   this.filters.is_archived = true;
    // }
    let response:any = await lastValueFrom(this.billerDashboardService.getPatientsCharges(page, fDoctor, this.companyId, this.filters) as any)
    // .subscribe((response: any) => { 
      if (response.status == 200) {
        this.selectedpatients = response.data;
        this.allPatients = response.data;
        if(this.currentUser.company_type === global.PORTAL_TYPE.NON_WC) {
          const charges = response.data;
          if (Array.isArray(charges) && charges.length > 0) {
            this.allPatients = charges.map(charge => {
              if (charge.cpt_id.length > 1 && !this.canSubmitChargeWithMupltipleCpts)
                charge.disabled = true;
              else
                charge.disabled = false;
              return charge;
            });
          }
        }
        this.completedCharges = response.data;
        // this.dataSource = response.data; 
        if (response.data.length <= 0) {
          this.toaster.info('No data to show...')
          // this.loader.stopLoader('loader')
        }
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        // this.pagination.totalCount = Number(response.totalCount);
        this.pagination.pageNo = page;
        this.initPagination();
        // this.loader.stopLoader('loader')
        this.dataLoaded = true;
        // console.log('this.faceSheetStatus', this.faceSheetFilter);
        // this.applyFaceSheetFilter(this.faceSheetFilter);
      }
    // })
  }

  async deleteChargeClicked(element) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: 'Do you want to delete the charge?'
      }
    });
    let confirmed = "false";
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        this.loading = true
        let chargeID = element._id;
        const response: any = await this.billerDashboardService.deleteChargeClicked(chargeID).toPromise();
        if (response.status == 200) {
          this.toaster.success("Charge deleted...")
          this.getCount();
          let index = this.selectedpatients.findIndex(a => a._id == response.data._id);
          if (index > -1) {
            this.selectedpatients.splice(index, 1)
            this.dataSource = new MatTableDataSource(this.selectedpatients);
          }
          this.loading = false;
          // this.selectedpatients.map(a =>)
        }
      }
    })

  }

  async changeCheckBox(element, event) {
    let chargesID = element._id;
    element.biller_status = event.checked;

    const updateStatus = await this.billerDashboardService.updatePatientCharges(chargesID, event.checked).subscribe((response: any) => {
      if (response.status == 200) {
      }
    })
  }
  checkBoxValue(element) {
    if (element && element.biller_status && element.biller_status == 'completed') {
      return true;
    }
    else {
      return false
    }
  }
  selectCharge(element, event) {
    if (event.checked) {
      // if ((element.is_approved == 'false' || element.is_approved == false)) {
        // if(element.cpt_id.length == 0 || element.icd_id.length == 0 || !this.checkVoidCensus(element?.rounding_sheet_id) || (element.note_id && element.note_id.is_signed !== 'true')){
        if(element.note_id && element.note_id.is_signed !== 'true'){
          this.selectedChargesWithUnsignedNote.push(element._id)
        }
        else if(element.cpt_id.length == 0 || element.icd_id.length == 0){
          this.selectedChargesMissingCPTICD.push(element._id)
        }
        else if(!this.checkVoidCensus(element?.rounding_sheet_id)){
          this.selectedChargesOfVoidCensus.push(element._id)
        }
        else if(!element.note_id){
          this.selectedChargesWithoutNote.push(element._id)
        }
        else if(element.cpt_id.length > 1 && !this.canSubmitChargeWithMupltipleCpts && this.currentUser.portal_type === global.PORTAL_TYPE.NON_WC){
          this.toaster.info('Cannot submit charges with multiple cpts')
        }
        else{
          this.selectedChargesToSubmit.push(element._id)
        } 
        this.chargesIDs.push(element._id)
      // }
    }
    if (!event.checked) {
      // this.selectAllCheck = false 
      let index = this.chargesIDs.indexOf(element._id);
      let index1 = this.selectedChargesToSubmit.indexOf(element._id);
      let index2 = this.selectedChargesMissingCPTICD.indexOf(element._id);
      let index3 = this.selectedChargesWithUnsignedNote.indexOf(element._id);
      let index4 = this.selectedChargesOfVoidCensus.indexOf(element._id);
      let index5 = this.selectedChargesWithoutNote.indexOf(element._id);

      // console.log('indexxxxxxxxx 1', indexx);
      // console.log('indexxxxxxxxx 2', index);
      
      if (index > -1) {
        this.chargesIDs.splice(index, 1)
      }
      if (index1 > -1) {
        this.selectedChargesToSubmit.splice(index1, 1)
      }
      if (index2 > -1) {
        this.selectedChargesMissingCPTICD.splice(index2, 1)
      }
      if (index3 > -1) {
        this.selectedChargesWithUnsignedNote.splice(index3, 1)
      }
      if (index4 > -1) {
        this.selectedChargesOfVoidCensus.splice(index4, 1)
      }
      if (index5 > -1) {
        this.selectedChargesWithoutNote.splice(index5, 1)
      }
    }
    
  }
  selectChargeAll(event) {
    if (event.checked) {
      this.chargesIDs = []; 
      this.selectedChargesToSubmit = []
      this.selectedChargesWithUnsignedNote = []
      this.selectedChargesMissingCPTICD = []
      this.selectedChargesOfVoidCensus = []
      this.selectedChargesWithoutNote = []
      this.selectAllCheck = true;
      this.selectedChargesToSubmit = this.allPatients.map(c => {
        // if ((c.is_approved == 'true' || c.is_approved == true || c.cpt_id.length == 0 || c.icd_id.length == 0 || !this.checkVoidCensus(c.rounding_sheet_id) || (c.note_id && c.note_id.is_signed !== 'true'))) {
        if(c._id){
          this.chargesIDs.push(c._id)
          if ((c.is_approved == 'true' || c.is_approved == true || c.cpt_id.length == 0 || c.icd_id.length == 0 || !this.checkVoidCensus(c.rounding_sheet_id) || (c.note_id && c.note_id.is_signed !== 'true'))) {
            if(c.note_id && c.note_id.is_signed !== 'true'){
              this.selectedChargesWithUnsignedNote.push(c._id)
            }
            else if(c.cpt_id.length == 0 || c.icd_id.length == 0){
              this.selectedChargesMissingCPTICD.push(c._id)
            }
            else if(!this.checkVoidCensus(c.rounding_sheet_id)){
              this.selectedChargesOfVoidCensus.push(c._id)
            }else if(!c.note_id){
              this.selectedChargesWithoutNote.push(c._id)
            }
          }
          else {
            return c._id;
          }
        }
      }).filter(item => item); 
      //it will make all charges selected on UI
      let charges = this.allPatients.map(c => {
        // if ((c.is_approved == 'true' || c.is_approved == true || c.cpt_id.length == 0 || c.icd_id.length == 0 || !this.checkVoidCensus(c.rounding_sheet_id))) {

        // } else {
        c['select'] = true
        if (c.cpt_id.length > 1 && !this.canSubmitChargeWithMupltipleCpts && this.currentUser.portal_type === global.PORTAL_TYPE.NON_WC)
          c['select'] = false;
        // }
        return c;
      });
      if (!this.canSubmitChargeWithMupltipleCpts && this.currentUser.portal_type === global.PORTAL_TYPE.NON_WC) {
        this.toaster.info('Company Permissions denied: Charges with multiple CPTs will not be selected');
      }
      this.dataSource = new MatTableDataSource(charges);
    }
    if (!event.checked) {
      this.selectAllCheck = false;
      this.chargesIDs = [];
      this.selectedChargesToSubmit = [];
      this.selectedChargesWithUnsignedNote = []
      this.selectedChargesMissingCPTICD = []
      this.selectedChargesOfVoidCensus = [] 
      this.selectedChargesWithoutNote = []
      let charges = this.allPatients.map(c => {
        c['select'] = false
        return c;
      })
      this.dataSource = new MatTableDataSource(charges);
    }
  }
  selectedCharge(element) {
    if (element.select) {
      return true
    }
    else {
      return false
    }
  }

  isSlected(element) {
    if (element._id == this.selectedProviderRow) {
      return true
    }
    else {
      return false;
    }
  }

  billingCompanyselection(event, element) {
    // console.log("billingCompanyselection: ", event)
    this.selectedChargeToSend = element._id
    if (event == "" || !event) {
      this.selectedBillingCompanies = this.billingComapny.map(a => a.billingCompanyId.name)
    }
    else {
      this.selectedBillingCompanies.push(event.billingCompanyId.name)
    }
    // console.log("this.selectedBillingCompanies: ", this.selectedBillingCompanies)
    this.sendToBilling()
  }
  patientClicked(patient) {
    let dialogSubscription = this.chargesPopupComponent.submitCharge.subscribe(val => {

      this.submitChargeFlag = val;
    })
    this.chargesPopup = this.dialog.open(ChargesPopupComponent, { data: { patient, arrayOfcharges: this.allPatients, companyNoteStatus: this.signedNotesStatus }, width: '80%' })
    this.chargesPopup.afterClosed().subscribe(async result => {
      // let value = this.chargesPopupComponent.submitCharge.getValue()
      // let value = this.chargesPopupComponent.chargeSubmitted;
      // if(this.submitChargeFlag){
      //   
      dialogSubscription.unsubscribe()
      // this.initProviderSide()
      // }
    });
  }
  //filters
  applyFilterPatientName(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      return (data.patient_first_name.toLowerCase().includes(filter) || data.patient_last_name.toLowerCase().includes(filter));
    };
  }

  applyFilter2(filterValue: string) {
    // this.dataSource2.filter = filterValue.trim().toLowerCase(); 
    let filter = filterValue.trim().toLowerCase();
    if (this.providers) {
      if (filter && filter != '') {
        let filterProvider = [...this.providers]
        filterProvider = filterProvider.filter(pro => {
          if (pro.first_name.trim().toLowerCase().includes(filter) || pro.last_name.trim().toLowerCase().includes(filter))
            return pro
        })
        this.dataSource2.data = (filterProvider)
      }
      else {
        this.dataSource2.data = (this.providers);
      }
    }
  }

  async applyFilter() {
    // console.log("filter",this.filters.facility);
    // this.getCount();
    // this.initProviderSide()
    if (this.allPatients) {
      if (this.filters.patient && this.filters.patient != '') {
        this.filterChargesPatient = [...this.allPatients]
        this.filterChargesPatient = this.filterChargesPatient.filter(charge => {
          if (charge.patient_id && charge.patient_first_name.trim().toLowerCase().includes(this.filters.patient) ||
            charge.patient_id && charge.patient_last_name.trim().toLowerCase().includes(this.filters.patient)) {
            return charge;
          }
        })
      }
      else {
        this.filterChargesPatient = [...this.allPatients]
      }
      // if (this.filters.dos && this.filters.dos != null) {
      //   this.filterChargesPatient = this.filterChargesPatient.filter(charge => {
      //     if (new Date(charge.visit_date).toLocaleDateString().trim() == this.filters.dos) {
      //       return charge;
      //     }
      //   })
      // }
      // if (this.filters.facility && this.filters.facility != '') {
      //   this.filterChargesPatient = this.filterChargesPatient.filter(charge => {
      //     if (charge.facility_id_ref?._id.trim().toLowerCase() == this.filters.facility.trim().toLowerCase()) {
      //       return charge;
      //     }
      //   })
      // }
      // else if (this.filters.facility && this.filters.facility === '') {
      //   this.filterChargesPatient = this.filterChargesPatient.filter(charge => {
      //     return charge;
      //   })
      // }
      this.selectedpatients = this.filterChargesPatient;
      this.completedCharges = this.filterChargesPatient;
      this.dataSource = new MatTableDataSource(this.filterChargesPatient)
    }
  }
  async search() {
    if (this.filters.dos || this.filters.patient) {
      await this.getCount()
      this.getPatientCharges(1, this.firstDoctor)
    }
  }
  onDateChange(date: MatDatepickerInputEvent<Date>) {
    this.chargesIDs = []
    if (date.value) {
      this.calenderDate = new Date(date.value);
      // this.filters.dos = new Date(date.value).toLocaleDateString().trim();
      // this.filters.dos = new Date(date.value);
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        await this.getCount();
        this.getPatientCharges(1, this.firstDoctor)
      }, 1000);
    }
    else {
      this.calenderDate = null;
      this.filters.dos = null;
    }
    // this.getCount();
    // this.getPatientCharges(1, this.firstDoctor)
    // this.applyFilter();
  }

  async applyFilterFacility($event) {
    localStorage.setItem("filter_facility_id", $event.value);
    this.chargesIDs = []
    if ($event.value === "") {
      this.filters.facility = "";
    }

    await this.getBillingComapnyAssociation(this.firstDoctor)
    // this.initProviderSide(); 
    await this.getCount(true);

    this.getPatientCharges(1, this.firstDoctor)
  }
 async applyFilterPatient(filterValue: string) {
    if (filterValue === '') {
      let filterExists = localStorage.getItem("filter_patient_name");
      if (filterExists) {
        localStorage.removeItem("filter_patient_name");
      }
    } else {
      localStorage.setItem('filter_patient_name', filterValue)
    }
    this.chargesIDs = [];
    let lastValue = ""
    lastValue = filterValue;
    this.filters.patient = filterValue;
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(async () => {
      await this.getCount(false);
      this.getPatientCharges(1, this.firstDoctor)
    }, 1000);
  }

  async clearFilter() {
    this.chargesIDs = [];
    localStorage.removeItem('filter_patient_name');
    localStorage.removeItem('filter_charges_dos');
    localStorage.removeItem('filter_provider_id');
    localStorage.removeItem('filter_facility_id');
    localStorage.removeItem('filter_facesheet');
    this.calenderDate = '';
    // this.filters.facility = this.allFacilities[0]?._id? this.allFacilities[0]._id: ''; 
    // this.filters.chargeType = 'draft'
    this.filters.chargeType = 'draft';
    this.filters.patient = '';
    this.filters.dos = {
      startDate: moment(moment().subtract(13, 'days').toLocaleString()).format('MM/DD/YYYY'),
      endDate: moment(moment().toLocaleString()).format('MM/DD/YYYY')
    };
    if (this.current_provider_id) {
      this.filter_provider_id = this.current_provider_id;
    } else {
      this.filter_provider_id = this.currentUser._id;
    }
    this.filters.facility = '';
    this.filters.facesheetFilter = 'all';
    this.faceSheetFilter = 'all';
    if (this.current_provider_id) {
      this.firstDoctor = this.current_provider_id;
    } else {
      this.firstDoctor = this.currentUser._id;
    }
    this.chargesIDs = [];
    // await this.getBillingComapnyAssociation(this.firstDoctor)
    await this.getCount();
    this.getPatientCharges(1, this.firstDoctor)
    // this.initProviderSide();
    // this.dataSource = new MatTableDataSource(this.allPatients)
  }


  getVisit(element) {
    if (element.owner_id) {
      let visit = element.owner_id.first_name.slice(0, 1) + element.owner_id.last_name.slice(0, 2);
      if (element.date_obj) {
        let { year, month, day, hour, minute } = element.date_obj;
        if (month < 10) {
          month = '0' + month;
        }
        if (day < 10) {
          day = '0' + day;
        }
        let str = `${month}/${day}/${year}`;
        visit = visit + ' ' + str
      }
      else {
        let timeZone = 'America/New_York'
        if (element.facility_id_ref?.pcc_timeZone) {
          timeZone = element.facility_id_ref.pcc_timeZone
        }
        else if (element.facility_id_ref?.timeZone) {
          timeZone = element.facility_id_ref.timeZone
        }
        let newDate = moment(new Date(element.visit_date).toLocaleString('en-US', { timeZone: timeZone })).format('MM/DD/YYYY')

        // let newDate = new Date(element.visit_date).toLocaleDateString();
        visit = visit + ' ' + newDate;
        // visit = visit + '  ' + moment(element.visit_date).format("hh:mm:ss a");
      }
      return visit;
    }
  }
  prividerClicked(element) {
    this.selectedProvider = element._id;
    this.selectedProviderRow = element._id;
    // this.loader.startLoader('loader');
    // this.getBillingComapnyAssociation(element._id)
    this.getPatientCharges(null, element._id)
    this.getFacilities(element._id)
  }

  getProviderName(element) {
    if (element && element.first_name) {
      return element.first_name + ' ' + element.last_name + ' ' + element.title;
    }
    else {
      return '';
    }
  }
  getPatientName(element) {
    if (element && element.patient_id && element.patient_first_name) {
      return element.patient_first_name+ ' ' + element.patient_last_name;
    }
    else {
      return '';
    }
  }

  getFacilities(provider) {
    this.billerDashboardService.getFacilities(provider).subscribe((response: any) => {
      if (response.status == 200) {
        this.allFacilities = response.data;
        this.facilities = this.allFacilities.map(facility => {
          return facility.title;
        })
      }
    })
  }
  getFacility(element) {
    if (element && element.facility_id_ref) {
      return element.facility_id_ref.title
    }
    else {
      return '';
    }
  }

  async captureCharges() {
    if (this.admin) {
      this.firstDoctor = this.current_provider_id
    }
    this.toaster.info('Generating Report', 'Info');;
    let pat = await this.billerDashboardService.getAllPatientsCharges(null, this.firstDoctor, this.companyId, this.filters).subscribe((response: any) => {
      if (response.status == 200) {
        this.completedCharges = response.AllPatients.filter(charge => {
          return charge.is_approved == 'true'
        })
        if (this.admin) {
          this._router.navigate([`/company/${this.companyId}/dashboard/charge-capture`], { state: { provider: this.selectedProvider, patients: this.completedCharges, facilities: this.allFacilities } });
        }
        else {
          this._router.navigate(['/charge-capture'], { state: { provider: this.selectedProvider, patients: this.completedCharges, facilities: this.allFacilities } });
        }
      }
    })
    this.generateReport = true;
  }

  // async captureCharges() {
  //   // this.toaster.info('Generating Report', 'Info');;
  //   if (this.admin) {
  //     this.firstDoctor = "";
  //   }

  //   this.completedCharges = this.selectedpatients.filter(charge => {
  //     return charge.is_approved == 'true'
  //   })

  //   if (this.admin) {
  //     this._router.navigate([`/company/${this.companyId}/dashboard/charge-capture`], { state: { provider: this.selectedProvider, patients: this.completedCharges, facilities: this.allFacilities } });
  //   }
  //   else {
  //     this._router.navigate(['/charge-capture'], { state: { provider: this.selectedProvider, patients: this.completedCharges, facilities: this.allFacilities } });
  //   }
  // }

  getTime(element) {
    // console.log("getTime visit date: ", moment(element.visit_date).format("hh:mm:ss a") );
    // console.log("visit date", element.visit_date);
    return moment(new Date(element.visit_date).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format('MMM DD, YYYY');;
    // return (moment(element.visit_date).format("hh:mm:ss a"))
  }
  getCreatedTime(element) {
    if (element.createdAt) {
      // console.log("getTime visit date: ", moment(element.visit_date).format("hh:mm:ss a") );
      return moment(new Date(element.createdAt).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format('MMM DD, YYYY h:mm a');
      // return (moment(element.visit_date).format("hh:mm:ss a"))
    }
    else {
      return '-'
    }
  }
  getEndTimeForTimer(charge) {
    if (charge && charge.createdAt) {
      // let time = moment(new Date(charge.createdAt).toLocaleString('en-US', { timeZone: charge.timezone })).add(1, 'days');
      let time = moment(new Date(charge.createdAt)).add(3, 'days');
      return time.toString()
    }
    else {
      return '';
    }
  }
  triggerFunction() {
    this.timeUp = true;
  }

  checkFaceSheet(element) {
    if (element.faceSheet) return 'View';
    else return 'Upload';
  }

  sortData(sort: Sort) {
    // console.log("sort.active : ", sort.active, sort.direction);
    const data = this.selectedpatients.slice();
    this.selectedpatients = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'patient_name':
          if (a.patient_id && a.patient_last_name && b.patient_id && b.patient_last_name) {
            //return compare(a.patient_first_name, b.patient_first_name, isAsc);
            return compare(a.patient_last_name, b.patient_last_name, isAsc);
          }
          else {
            return;
          }
        case 'face_sheet':
          return compare(this.checkFaceSheet(a), this.checkFaceSheet(b), isAsc);

        case 'location':
          if (a.facility_id_ref && a.facility_id_ref.title && b.facility_id_ref && b.facility_id_ref.title) {
            return compare(a.facility_id_ref.title, b.facility_id_ref.title, isAsc);
          }
          else {
            return;
          }
        case 'visit':
          if ((a.visit_date || a.date_obj) && (b.visit_date || b.date_obj)) {
            let dateA = this.getVisitDate(a);
            let dateB = this.getVisitDate(b);

            return compare(dateA, dateB, isAsc);
          }
        case 'time':
          if ((a.visit_date || a.date_obj) && (b.visit_date || b.date_obj)) {
            let dateA = this.getVisitDate(a);
            let dateB = this.getVisitDate(b);

            return compare(dateA, dateB, isAsc);
          }
        case 'create_time':
          if (a.createdAt && b.createdAt) {
            return compare(new Date(a.createdAt).getTime(), new Date(b.createdAt).getTime(), isAsc);
          }
        case 'approve':
          if (a.is_approved && b.is_approved) {
            return compare(a.is_approved, b.is_approved, isAsc);
          }
          else {
            return;
          }

        default: return 0;
      }
    });
    // this.dataSource = this.selectedpatients;
    this.dataSource = new MatTableDataSource(this.selectedpatients);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator
  }



  clearfilterData() {
    this.selectedDOV = null;
    this.Modelfacility = '';
    this.filter = false;
    this.selectedpatients = this.allPatients;
    // this.dataSource = new MatTableDataSource(this.selectedpatients);
    this.dataSource = new MatTableDataSource(this.allPatients);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator
  }
  async approveAll() {
    let is_approved = this.chargesIDs.length > 0 ? 'true' : 'false';
    let response: any = await this.billerDashboardService.approveAll(this.chargesIDs, is_approved).toPromise();
    if (response.status == 200) {
      this.chargesIDs = [];
      this.selectAllCheck = false;
      this.toaster.info("Charge Status updated");
      // this.initProviderSide();
      await this.getCount();
      this.getPatientCharges(1, this.firstDoctor)
    }
  }
  async archivedAll() {
    // console.log(this.chargesIDs);
    let status = 'archive';
    let response: any = await this.billerDashboardService.SubmitAllandApprove(this.chargesIDs, status).toPromise();
    if (response.status == 200) {
      this.chargesIDs = [];
      this.selectAllCheck = false;
      // this.initProviderSide();
      this.getCount();
      this.getPatientCharges(1, this.firstDoctor)
      this.toaster.success("All Charges Status updated");
    } else {
      this.toaster.info(`${this.chargesIDs.length} note/notes are not billed.`);
    }
  }
  //SubmitAllandApprove
  async submitAll() {
    let allSelectedCharges = this.chargesIDs;
    // let note_SignedCharges = this.dataSource.datsa;
    // if (this.signedNotesStatus) {
    if (allSelectedCharges) {
      // note_SignedCharges = note_SignedCharges.filter(f => this.chargesIDs.includes(f._id));
      // note_SignedCharges = note_SignedCharges.filter(element => element.note_id && element.note_id.is_signed === 'true');
      // note_SignedCharges = note_SignedCharges.map(element => element._id);
      let status = this.chargesIDs.length > 0 ? 'submit' : 'draft';

      // let size_difference = this.chargesIDs.length - note_SignedCharges.length;
      let size_difference = this.chargesIDs.length - this.selectedChargesToSubmit.length;
      // if (note_SignedCharges.length > 0) {
      if (this.selectedChargesToSubmit.length > 0) {
        // let response: any = await this.billerDashboardService.SubmitAllandApprove(note_SignedCharges, status).toPromise();
        let infoToast =  this.toastr.info('Submitting Charge/s...', 'Please wait', {
          disableTimeOut: true,
        })
        let response: any = await this.billerDashboardService.SubmitAllandApprove(this.selectedChargesToSubmit, status).toPromise();
        if (response.status == 200) { 
          this.chargesIDs = [];
          this.selectedChargesToSubmit = []; 
          this.selectAllCheck = false;
          // this.initProviderSide();
          this.toastr.clear(infoToast.toastId)
          this.getCount();
          this.getPatientCharges(1, this.firstDoctor)
          if (size_difference == 0) {
            this.toaster.success("Charges submitted successfully");
          } else { 
            let info1 = ''
            let info2 = ''
            let info3 = ''
            let info4 = ''
            if(this.selectedChargesWithUnsignedNote.length > 0){
              info1 = `${this.selectedChargesWithUnsignedNote.length} note(s) are not signed.`
            }
            if(this.selectedChargesMissingCPTICD.length > 0){
              info2 = `${this.selectedChargesMissingCPTICD.length} note(s) are not having ICD10 or CPTs. `
            }
            if(this.selectedChargesOfVoidCensus.length > 0){
              info3 = `${this.selectedChargesOfVoidCensus.length} note(s) are of void census.`
            } 
            if(this.selectedChargesWithoutNote.length > 0){
              info4 = `${this.selectedChargesWithoutNote.length} charges are without notes.`
            }
            info2 = info2 + info3 + info4;
            // this.toaster.info(`${size_difference} note/notes are not signed OR not having ICD10/CPTs.`);
            // console.log(info);
            this.toaster.info(
              `${info1}`,
              `${info2}`,{ closeButton: true, timeOut: 5000,progressBar: false }
            );
            // this.toaster.info(info, '',{ closeButton: true, timeOut: 3000,progressBar: false });
            this.selectedChargesMissingCPTICD = [];
            this.selectedChargesOfVoidCensus = [];
            this.selectedChargesWithUnsignedNote = [];
            this.selectedChargesWithoutNote = [];
          }
        }
      } else {
        // this.toaster.info(`${size_difference} note/notes are not signed OR not having ICD10/CPTs.`);
        let info1 = ''
        let info2 = ''
        let info3 = ''
        let info4 = ''

        if(this.selectedChargesWithUnsignedNote.length > 0){
          info1 = `${this.selectedChargesWithUnsignedNote.length} note(s) are not signed.`
        }
        if(this.selectedChargesMissingCPTICD.length > 0){
          info2 = `${this.selectedChargesMissingCPTICD.length} note(s) are not having ICD10 or CPTs.`
        }
        if(this.selectedChargesOfVoidCensus.length > 0){
          info3 = `${this.selectedChargesOfVoidCensus.length} note(s) are of void census.`
        } 
        if(this.selectedChargesWithoutNote.length > 0){
          info4 = `${this.selectedChargesWithoutNote.length} charges are without notes.`
        }
        info2 = info2 + info3 + info4;
        // this.toaster.info(`${size_difference} note/notes are not signed OR not having ICD10/CPTs.`);
        // console.log(info);
        this.toaster.info(
          `${info1}`,
          `${info2}`,{ closeButton: true, timeOut: 5000,progressBar: false }
        );
      }

    }
    else {
      // note_SignedCharges = note_SignedCharges.filter(f => this.chargesIDs.includes(f._id));
      // note_SignedCharges = note_SignedCharges.map(element => element._id);
      let status = this.chargesIDs.length > 0 ? 'submit' : 'draft';
      // let response: any = await this.billerDashboardService.SubmitAllandApprove(note_SignedCharges, status).toPromise();
      let infoToast =  this.toastr.info('Submitting Charge/s...', 'Please wait', {
        disableTimeOut: true,
      })
      let response: any = await this.billerDashboardService.SubmitAllandApprove(this.selectedChargesToSubmit, status).toPromise();
      if (response.status == 200) {
      // if (true) {
        this.chargesIDs = [];
        this.selectedChargesToSubmit = [];
        this.selectedChargesMissingCPTICD = [];
        this.selectedChargesOfVoidCensus = [];
        this.selectedChargesWithUnsignedNote = [];
        this.selectedChargesWithoutNote = [];
        this.selectAllCheck = false;
        this.toastr.clear(infoToast.toastId)
        // this.initProviderSide();
        this.getCount();
        this.getPatientCharges(1, this.firstDoctor)
        this.toaster.success("Charges submitted successfully");
      }
    }
  }

  async deleteAll() {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: 'Are you sure you want to delete all selected charges?'
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        let response: any = await this.billerDashboardService.deleteAll(this.chargesIDs).toPromise();
        if (response.status == 200) {
          this.chargesIDs.forEach(element => {
            Array.from(this.allPatients, (child, index) => {
              if (element == child['_id']) {
                this.allPatients.splice(index, 1);
              }
            });
          });
          this.dataSource = new MatTableDataSource(this.allPatients);
          if (this.filters.chargeType == 'draft') {
            this.draftCount -= this.chargesIDs.length;
          } else {
            this.submitCount -= this.chargesIDs.length;
          }
          this.chargesIDs = [];
          this.selectedChargesMissingCPTICD = [];
          this.selectedChargesOfVoidCensus = [];
          this.selectedChargesWithUnsignedNote = [];
          this.selectedChargesWithoutNote=[];
          this.selectAllCheck = false;
          this.myCheckbox.checked = false;
          this.toaster.success("Selected Charges Deleted");
        }
      }
    });
  }

  toggleApproveCharge(element) {
    element.is_approved = element.is_approved === 'true' ? 'false' : 'true';

    this.billerDashboardService.toggleApproveCharge(element._id, element.is_approved)
      .subscribe((response: any) => {
      })
  }
  viewNote(ele) {
    let time_zone = 'America/New_York'
    // if (this.census && this.census.facility) {
    //   time_zone = this.census.facility.pcc_timeZone ? this.census.facility.pcc_timeZone : this.census.facility.timeZone
    // }
    if(this.currentUser.portal_type === global.PORTAL_TYPE.NON_WC) {
      if(!this._authService.currentUser.other_settings.preview_note) {
        return this._router.navigate(['/note-editor/', ele.note_id._id]);
      }
    }
    const dialogRef = this.dialog.open(ViewNoteDialog, {
      width: '80%',
      //height:'80%',
      data: {
        note_id: ele.note_id,
        timezone: time_zone,
        void_census: this.checkVoidCensus(ele.rounding_sheet_id)
      }
    });
  }
  viewBillerNote(note) {
    swal({
      title: "Biller Comment",
      text: note,
      dangerMode: true,
      buttons: [, 'Close'],
    })
      .then(() => {
      });
  }
  changeCreationDate(event) {
    if (event.startDate && event.endDate) {
      this.filters.dos = {
        startDate: null,
        endDate: null
      }
      const noOfDays = this.commonService.getDifferenceOfDates(event.startDate, event.endDate,'days');

      if (noOfDays <= 30) {
        this.chargesIDs = [];
        this.filters.dos = {
          startDate: moment(moment(event.startDate.toDate()).toLocaleString()).format('MM/DD/YYYY'),
          endDate: moment(moment(event.endDate.toDate()).toLocaleString()).format('MM/DD/YYYY')
        }
        localStorage.setItem('filter_charges_dos', JSON.stringify(this.filters.dos));
        // console.log("new dateeee : ", moment(moment(event.startDate).toLocaleString()).format('YYYY-MM-DD'));
      }
      else {
        this.filters.dos = {
          startDate: moment(moment(event.startDate.toDate()).toLocaleString()).format('MM/DD/YYYY'),
          endDate: moment(moment(event.startDate.toDate()).add(30, "days").endOf('day').toLocaleString()).format('MM/DD/YYYY')
        }
        this.toastr.error('Cannot select more than 30 days', 'Date Error');
      }

      localStorage.setItem('filter_charges_dos', JSON.stringify(this.filters.dos));

      // console.log("new dateeee : ", moment(moment(event.startDate).toLocaleString()).format('YYYY-MM-DD'));

    } else {
      localStorage.removeItem('filter_charges_dos');
      this.filters.dos = null;
    }
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(async () => {
      await this.getCount();

      if (!this.isBillerDashboard) {
        await this.getCountBiller();
      }
      this.getPatientCharges(1, this.firstDoctor)
    }, 1000);
    // console.log(this.filters);
  }
  checkVoidCensus(patientRoundingSheet) {
    if (patientRoundingSheet) {
      if (patientRoundingSheet?.is_void) {
        if (patientRoundingSheet.is_void === true) return false;
        else return true;
      } else {
        return true
      }
    } else {
      return true;
    }
  }
  checkDuration(element){
    if(element){
      if(element.status == 'draft'){
        return true;
      }
      else if(element.status == 'submit') {
        let startDate = null, editDuration = this.charge_edit_duration;
        if(element.first_submission_date){
          startDate = moment(element.first_submission_date).startOf('day')
        }
        else {
          startDate = moment(element.submit_date).startOf('day');
          editDuration = 5;
        }
        let current = moment().endOf('day') 
        let daysDiffer = current.diff(startDate, 'days') 
        if( daysDiffer < editDuration){
          this.canEdit = true;
          return true;
        }
        else{
          return false;
        }
      }
    }
    else{
      return false
    }
  }
  showPatientDocumentsUploadDialog(index, patientId) {
    const dialogRef = this.dialog.open(
      PatientDocumentsUploadDialog,
      { width: '80%' }
    );
    dialogRef.beforeClosed().subscribe(async (fileInput: any) => {
      if (fileInput) {
        const [file] = fileInput.files;
        const infoToast = this.toaster.info('Uploading Face Sheet...', 'Please wait')
        const company_id = this._authService.currentUser.company_id;
        this._faceSheetService.uploadFaceSheet(file, patientId, company_id).subscribe((response: any) => {
          if (response.status === 200) {
            const { _id, title, aws_path } = response.data;
            const faceSheet = {
              _id,
              title,
              aws_path
            };
            this.completedCharges[index].faceSheet = faceSheet;
            // this.dataSource[index].faceSheet = faceSheet;
            this.toaster.success("Face Sheet Uploaded", 'Success');
          }
          else {
            console.error(response.data.status, response.data.message);
            this.toaster.error(response.data.message, 'Failed');
          }
        });
      }
    });
  }

  applyFaceSheetFilter(filterValue: any) {
    localStorage.setItem("filter_facesheet", filterValue);
    // if (filterValue === "all") {
    //   this.dataSource.data = this.completedCharges;
    // } else if (filterValue === "view") {
    //   this.dataSource.data = this.completedCharges.filter(charge => charge.faceSheet);
    // } else {
    //   this.dataSource.data = this.completedCharges.filter(charge => !charge.faceSheet);
    // }
    this.filters.facesheetFilter = filterValue;
    this.getPatientCharges(this.pagination.pageNo, this.firstDoctor);
    this.getCount(true);
  }
  viewPDF(filePath) {
    this.dialog.open(PdfViewerPopComponent, {
      width: '50vw',
      height: '95vh',
      data: {
        file_path: filePath
      }
    });
  }

  downloadFaceSheetActive(element, index) {
    const charge = element;
    const patient = charge.patient_id;
    if (!patient) return false;

    if (charge.source === 'PointClickCare') return true;
    if (charge.source === 'MatrixCare') return true;
    if (charge.faceSheet) return true;

    return false;
  }

  async handleUploadFacesheetClick(element, index) {
    const charge = element;
    const patient = charge.patient_id;
    this.showPatientDocumentsUploadDialog(index, patient)
  }

  async handleDownloadFacesheetClick(element, index) {
    const charge = element;
    const patient = charge.patient_id;
    // console.log("patient-------: ", patient);

    if (charge.source === 'PointClickCare') {
      let hasPCCFacesheetData = false;
      const details = {
        ...this._authService.authObject,
        patientId: patient,
      }
      const { status, data } = await lastValueFrom(this.pccService.syncCoveragesData({ details })) as any;
      if (status === 200) {
        const _patient = data;
        if ((_patient?.pcc_coverage_data?.Primary || _patient?.pcc_coverage_data?.Secondary || _patient?.pcc_coverage_data?.Tertiary)) {
          hasPCCFacesheetData = true
        }
      }
      if (hasPCCFacesheetData) {
        this.generateFacesheetAndDownloadAsPDF(patient);
        return;
      }
    }
    // if (patient.source === 'MatrixCare' && patient.matrix_payload?.coverages?.length > 0) {
    if (charge.source === 'MatrixCare') {
      this.generateFacesheetForMatrixAndDownloadAsPDF(patient);
      return;
    }
    const infoToast = this.toastr.info('Preparing file to download...', 'Please wait', { disableTimeOut: true });
    try {
      const downloadFileResponse: any = await this.fileExplorerService.downLoadAnyFileWithURL(element.faceSheet.aws_path, element.faceSheet.title, element.patient_id).toPromise();
      var url = window.URL.createObjectURL(downloadFileResponse);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = <string>element.faceSheet.title;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      this.toastr.error('Unable to download file.', 'Failed');
    } finally {
      this.toastr.clear(infoToast.toastId);
    }
  }

  generateFacesheetAndDownloadAsPDF(patient_id) {
    // console.log('patient id', patient_id);
    const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    try {
      const params = {
        patient_id
      }
      return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetAndDownloadAsPDF, params).subscribe((res: any) => {
        if (res instanceof HttpResponse) {

          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'facesheet.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.toastr.clear(infoToastr.toastId);
        } else {
          if (res.type === 0) {
          } else if (res.type === 3) {
            const { loaded, total } = res;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
        }
        if (res.status == 200) {
          return true;
        }
      })
    } catch (error) {
      const { message = 'Something went wrong, please try again' } = error;
      this.toastr.error(message);
    } finally {
      this.toastr.clear(infoToastr.toastId);
    }
  }
  ngOnDestroy() {
    if (!this.editingCharge  && !this.generateReport && !window.location.pathname.includes('patient-detail')) {
      localStorage.removeItem('filter_patient_name');
      localStorage.removeItem('filter_charges_dos');
      localStorage.removeItem('filter_provider_id');
      localStorage.removeItem('filter_facility_id');
      localStorage.removeItem('filter_facesheet');
      localStorage.removeItem('selectedFilter-biller-dashboard')
    }
    if (this.notification_response)
      this.notification_response.unsubscribe();
  }

  generateFacesheetForMatrixAndDownloadAsPDF(patient_id) {
    const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    try {
      const params = {
        source: 'MatrixCare',
        patient_id
      }
      return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetForMatrixAndDownloadAsPDF, params).subscribe((res: any) => {
        if (res instanceof HttpResponse) {

          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'facesheet.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.toastr.clear(infoToastr.toastId);
        } else {
          if (res.type === 0) {
          } else if (res.type === 3) {
            const { loaded, total } = res;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
        }
        if (res.status == 200) {
          return true;
        }
      })
    } catch (error) {
      const { message = 'Something went wrong, please try again' } = error;
      this.toastr.error(message);
    } finally {
      this.toastr.clear(infoToastr.toastId);
    }
  }
   billingComapnies(elemnt){ 
    let coms = []; 
    this.billingComapny.forEach(com => {
    // this.billingComapny.forEach(com => {
      if(com.facilityId == elemnt.facility_id_ref._id && com.providerId == elemnt.owner_id._id){
        coms.push(com)
      }
      else if(com.association_type == 'all'){ 
        let index = coms.findIndex((i => i.billingCompanyId.name === com.billingCompanyId.name))
        if(index < 0){
          coms.push(com)
        } 
      }
    });
    return coms
  }

  goToPatientDetailScreen(patient) {
    return this._router.navigate([`/patient-detail/${patient.patient_id}`]);
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


