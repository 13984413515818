'use strict';

import { environment } from '../../environments/environment';

export const LOCAL = "LOCAL";
export const DEV = "DEV";
export const STAGING = "STAGING";
export const PRE_PROD = "PRE_PROD";
export const PROD = "PROD";
export const WOUNDCARE_DEV = "WOUNDCARE_DEV";
export const WOUNDCARE_PRELIVE = "WOUNDCARE_PRELIVE";
export const WOUNDCARE_LIVE = "WOUNDCARE_LIVE";
export const DEV_APP = "DEV_APP";
export const APP = "APP";

/**
 * For local ENV is set to "LOCAL"
 * For other ENV it's going to be set to "APP"
 */
export var ENVIRONMENT = environment.ENV;

export var socket_io_base_url = '';
export var app_base_url = '';
export var url = '';
export var url_documents = '';
export var url_template = '';
export var url_pcc = '';
export var url_patientroundingsheet = '';
export var url_patientintakeform = '';
export var rrgen = '';
export let base_url = '';
export let pcc_integration_base_url = '';
export let ELASTIC_SEARCH_METHOD = -1;
export let SSO_LOGIN_URL = '';
// export let PORTAL_TYPE = '';

export let LOGOUT_IDLE_TIME = 720; // minutes

socket_io_base_url = environment.socket_io_base_url;
base_url = environment.base_url;
app_base_url = environment.app_base_url;
url = environment.url;
url_documents = environment.url_documents;
url_template = environment.url_template;
url_pcc = environment.url_pcc;
pcc_integration_base_url = environment.pcc_integration_base_url;
url_patientroundingsheet = environment.url_patientroundingsheet;
url_patientintakeform = environment.url_patientintakeform;
rrgen = environment.rrgen;

export const version = '22.2.2';
export const TEXT = "15";
export const date_format = 'MMM dd, yyyy';
export const date_format2 = 'MM/dd/yyyy';
export const date_time_format = 'MMM dd, yyyy h:mm a';
export const moment_date_time_format = 'MMM DD, YYYY h:mm a';
export const moment_date_format = 'MM DD, YYYY';
export const moment_date_format2 = 'MMM DD, YYYY';
export const portal_type: "non_wc" | "wc" | "nursing" = "wc";
export const PORTAL_TYPE = Object.freeze({
    WC: "wc",
    NON_WC: "non_wc"
});

if (ENVIRONMENT === LOCAL) {
    base_url = 'http://localhost:4200';
    socket_io_base_url = 'http://localhost:8086';
    app_base_url = 'http://localhost:8086/api';
    url = 'http://localhost:8086/api';
    url_documents = 'http://localhost:8086/api/document';
    url_template = 'http://localhost:8086/api/template';
    url_pcc = 'http://localhost:8086/api/pcc';
    pcc_integration_base_url = 'https://connectdev.doctornow.io';
    url_patientroundingsheet = 'http://localhost:8086/api/patientroundingsheet';
    url_patientintakeform = 'http://localhost:8086/api/patientintakeform';
    rrgen = 'http://localhost:8086/api/rrgen';
    SSO_LOGIN_URL = `https://dndev-elasticsearch.auth.us-east-1.amazoncognito.com/login?client_id=2bet1r62tnnt23mmebcmdnlmk0&response_type=code&scope=email+openid+phone+profile&redirect_uri=${base_url}/loginAuthRedirect`
}

export const USER_TYPE = Object.freeze({
    ADMIN: '0',
    DOCTOR: '1',
    TRANSCRIBER: '2',
    MANAGER: '3',
    PA: '4',
    NURSE: 'nurse',
    BILLER: '6',
    COMPANY: '1',
    MEDICAL_ASSISTANT: 'medical_assistant',
    BUSINESS_OFFICER: 'business_officer',
    CNP: 'cnp',
    SNF_WC_NURSE: 'snf_wc_nurse',
    WOUND_NURSE: 'wound_nurse',
    VIEW_ONLY_USER: 'view_only_user'
});

export const ADMIN_TYPE = Object.freeze({
    NONE: "none",
    FULL_ADMIN: "full_admin",
    SYSTEM_ADMIN: "system_admin",
    TRANSCRIBER_ADMIN: "transcriber_admin",
    WOUND_NURSE_ADMIN: "wound_nurse_admin"
});

export const COMPANY_TYPE = Object.freeze({
    SNF: 'snf',
    WOUND: 'Wound',
    NURSING: 'Nursing'
});

export const USER_PERMISSIONS = Object.freeze({
    CHARGE_CAPTURE: 'charge_capture',
    TELEMED: 'telemed',
});
//global.user.permission.slice() //includes(global.USER_PERMISSIONS.CHARGE_CAPTURE)


export const ACCOUNT_TYPE = Object.freeze({
    USER: 'user',
    COMPANY: 'company'
});

export const cached = {
    set: (key, value) => {
        sessionStorage.setItem(`cached:${key}`, JSON.stringify(value));
    },
    get: (key, defaultValue) => {
        try {
            const localData = sessionStorage.getItem(`cached:${key}`);
            return localData === null ? defaultValue : JSON.parse(localData);
        } catch (e) {
            return defaultValue;
        }
    }
}

export const DRAGON_SPEECH_CREDIENTIALS = Object.freeze({
    GUID: "dd48b633-0978-41b0-835f-1ae01c96c264",
    Token: "f0a5ccfb-5873-43e5-bf55-eccfbf816a4e"
});
export const NVOQ_CREDS = Object.freeze({
    USERNAME: "dev@doctornow.io",
    // PASSWORD: "WfgxxCS6BB0i3f26rV"
});

// Dev: https://fmmhf6a4fb.execute-api.us-east-1.amazonaws.com/devapi/session
// Prelive: https://egazvv2nbd.execute-api.us-east-1.amazonaws.com/prelive/session
// Demo: https://dkbw4sx52h.execute-api.us-east-1.amazonaws.com/demoapi/session
// Live: https://dvfyv6rttc.execute-api.us-east-1.amazonaws.com/dnlive/session
