import { Injectable } from "@angular/core";
import * as global from "../../global";
import { API_URL } from "src/environments/api-url";
import { HttpService } from "src/app/services/http.service";
import { AuthService } from "src/app/services/auth.service";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import moment from "moment";
import { CommonService } from "src/app/services/common.service";
import _ from "lodash";

@Injectable({
    providedIn: "root"
})
export class WoundCareWidgetService {
    private isStateChanged$ = new BehaviorSubject(false);
    public castState = this.isStateChanged$.asObservable();
    private isTeleHealthReportSubject = new BehaviorSubject<boolean>(false);

    get isTeleHealthReport$(): Observable<boolean> {
        return this.isTeleHealthReportSubject.asObservable();
    }

    setIsTeleHealthReport(value: boolean) {
        this.isTeleHealthReportSubject.next(value);
    }
    constructor(private authService: AuthService, private http: HttpService, private httpclient: HttpClient,    private _commonService: CommonService,
        ) { }
    getAllDataset(company_id, project?:{}, dataSet?, page =null, column_ids = null, userType = "1", header?: string) {
        const data = {
            ...this.authService.authObject,
            company_id,
            dataSet,
            project,
            column_ids,
            page,
            userType,
            ...header && { header: header }
        };
        return this.http.post(global.url + API_URL.WOUND.getAllDataset, data);
    }
    setState(value) {
        this.isStateChanged$.next(value);
    }
    getWounds(patient_id, state, created_userType?,  facility_id?, created_by?, creationDate = null) {
        const data = {
            ...this.authService.authObject,
            patient_id,
            state,
            created_userType,
            ...created_by && { created_by: created_by },
            ...facility_id && { facility_id: facility_id },
            creationDate,
            company_id: this.authService.currentUser.company_id
        };
        const clonedData = _.cloneDeep(data);
        if(creationDate){
            clonedData.creationDate.startDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.startDate.startOf('day'), true);
            clonedData.creationDate.endDate = this._commonService.convertDateToStringforMoment(clonedData.creationDate.endDate.endOf('day'), true);
        }
        return this.http.post(global.url + API_URL.WOUND.getWounds, clonedData).pipe(
            // map((response) => {
            //     if (response.status == 200) {
            //         return {
            //             ...response,
            //             data: response.data.map((wound, i) => ({
            //                 woundNo: i + 1,
            //                 ...wound
            //             }))
            //         };
            //     }
            //     return response;
            // })
        );
    }
    getAllWoundsParentStatesCount(patient_id, created_userType?) {
        var data = {
            ...this.authService.authObject,
            patient_id: patient_id,
            created_userType
        }
        return this.httpclient.post(global.url + API_URL.WOUND.getAllWoundsParentStatesCount, { data: data }).pipe();
    }
    
    getParentWoundsCount(patientIds) {
        var data = {
            patientIds: patientIds,
        }
        return this.httpclient.post(global.url + API_URL.WOUND.getParentWoundsCount, { data: data }).pipe();
    }
    getChildWounds(patient_id, parent_id, dateFrom?, dateTo?, states?) {
        const data: any = {
            ...this.authService.authObject,
            patient_id,
            parent_id,
            dateFrom,
            dateTo,
            checkkey: "web",
            sortCheck: true,
            state: states ? states : 'active'
        };
        if (Array.isArray(states) && states.length > 0) data.state = { $in: states };
        return this.http
            .post(global.url + API_URL.WOUND.getChildWounds, data)
            .pipe(
            // map((response) => {
            //     if (response.status == 200) {
            //         return {
            //             ...response,
            //             data: response.data.map((wound, i) => ({
            //                 woundNo: i + 1,
            //                 ...wound
            //             }))
            //         };
            //     }
            //     return response;
            // })
        );
    }
    updateWoundData(wound_id, updateData, child_wound_exists = true) {
        const woundData = JSON.parse(JSON.stringify(updateData));
        woundData['last_updated_by'] = this.authService.currentUser._id
        const data = {
            ...this.authService.authObject,
            wound_id, woundData,
            child_wound_exists
        };
        console.log({
            wound_id, woundData
        })
        return this.http.post(global.url + API_URL.WOUND.updateWoundData, data);
    }

    uploadWoundImage(imageFile, wound) {

        const payload = {
            auth: JSON.stringify(this.authService.authObject),
            data: JSON.stringify({
                wound_id: wound._id,
                patient_id: wound.patient_id
            })
        };

        const formData: any = new FormData();
        formData.append('file', imageFile);

        for (let key of Object.keys(payload)) {
            formData.append(key, payload[key]);
        }
        return this.httpclient.post(global.url + API_URL.WOUND.uploadWoundImages, formData).pipe();
    }
    deleteWoundOtherImages(wound_id, images) {
        const data = {
            id: wound_id,
            wound_images: images
        };
        return this.httpclient.post(global.url + API_URL.WOUND.deleteWoundOtherImages, { data: data }).pipe();
    }

    getWoundGraphData(wound_id) {
        const data = {
            ...this.authService.authObject,
            wound_id
        };
        return this.http.post(global.url + API_URL.WOUND.getWoundGraphData, data);
    }

    generateReportForAllWounds(patient, state, date_obj=null) {
        let currUser: any = JSON.parse(localStorage.getItem("currentUser"));
        console.log("currUser", currUser);
        const data = {
            ...this.authService.authObject,
            patient_id: patient._id,
            patient,
            date_obj: date_obj,
            timeZone: patient.timeZone,
            state,
            userId: currUser._id,
            company_id: currUser.company_id,
            isDownload: true
        };
        console.log("sending data", data);
        return this.httpclient.post(global.url + API_URL.WOUND.generateReportForAllWounds, data).pipe();
    }
    generateReportandUploadtoPCC(patient, note_id, filename) {
        let currUser: any = JSON.parse(localStorage.getItem("currentUser"));
        let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');
        const data = {
            ...this.authService.authObject,
            patient_id: patient._id,
            patient,
            timeZone: patient.timeZone,
            userId: currUser._id,
            company_id: currUser.company_id,
            note_id,
            currentUserDateTime,
            filename
        }
        return this.httpclient.post(global.url + API_URL.WOUND.generateReportandUploadtoPCC, {details: data}).pipe();
    }
    getAllWoundDataforGroupReport(patient, note_id, state,date_obj = null, facility_id?, provider_id?){
        let currUser: any = JSON.parse(localStorage.getItem("currentUser"));
        const data: any = {
            ...this.authService.authObject,
            patient_id: patient._id,
            patient,
            timeZone: patient.timeZone,
            userId: currUser._id,
            company_id: currUser.company_id,
            date_obj,
            state,
            note_id,
            ...facility_id && {facility_id: facility_id},
            ...provider_id && {provider_id: provider_id},
        }
        // if(facility_id) data.facility_id
        return this.httpclient.post(global.url + API_URL.WOUND.getAllWoundDataforGroupReport, {details: data}).pipe();
    }
    generateSingleReportandUploadtoPCC(wound_id,patient,filename,note_id){
        let currUser: any = JSON.parse(localStorage.getItem("currentUser"));
        const data = {
            ...this.authService.authObject,
            patient_id: patient._id,
            patient,
            timeZone: patient.timeZone,
            userId: currUser._id,
            company_id: currUser.company_id,
            wound_id,
            filename,
            note_id
        }
        return this.httpclient.post(global.url + API_URL.WOUND.generateSingleReportandUploadtoPCC, {details: data}).pipe();

    }
    addNewWoundAssessment(wound_id, census_id){
        const data = {
            ...this.authService.authObject,
            wound_id,
            census_id,
            created_by: this.authService.currentUser._id
        }
        return this.httpclient.post(global.url + API_URL.WOUND.addNewWoundAssessment, {details: data}).pipe();
    }
    downloadWoundImagesZip(wound, uploadToPCC = false) {
        const data={
            ...this.authService.authObject,
            wound,
            uploadToPCC
        }
        const headers = new HttpHeaders();
        let returnType = {};
        if(!uploadToPCC){
            headers.append('Access-Control-Allow-Headers', 'Content-Length');
            headers.append('Access-Control-Expose-Headers', 'Content-Length');
            headers.append('Content-Type', 'application/octet-stream');
            returnType = {
                responseType: "blob",
                reportProgress: true,
                observe: 'events',
                headers
            }
        }
        return this.httpclient.post(global.url + API_URL.WOUND.downloadWoundImagesZip, {details: data}, returnType);
    }
}
