<div style="position: relative;" *ngIf="view !== 'patient-intake-form' && view !== 'patient-document-view'">
    <div style="justify-content: space-between;" >
        <div>
            <button *ngIf="file_path&&(!(patient_side))" [disabled]="file_index<=0" class="dn-button-primary" mat-raised-button style="margin-top:10px; margin-left:20px"
            (click)="handlePreviousClick()" >Previous</button>
            <button *ngIf="file_path&&(!(patient_side))" [disabled]="file_index==(files_length-1)" class="dn-button-primary" mat-raised-button style="margin-top:10px; margin-left:10px"
            (click)="handleNextClick()" >Next</button>
        </div>
        <div>
            <button *ngIf="file_path&&(!(patient_side))" class="dn-button-primary" mat-raised-button
                style="position: absolute;top: 10px;right:170px; margin-right: 15px;"
                (click)="handleGoBackClick()">Back</button>
            <button class="dn-button-primary" mat-raised-button style="position: absolute;top: 10px;right:20px;"
                *ngIf="currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER" [matMenuTriggerFor]="uploadOptionBtn">Upload
                Document</button>
            <mat-menu #uploadOptionBtn="matMenu">
                <button *ngFor="let documentType of documentTypes" mat-menu-item (click)="showPatientsDialog(documentType[0])">{{ documentType[1] }}</button>
            </mat-menu>
        </div>
    </div>
    <div *ngIf="((!file_path)||(patient_side))" class="flex"  [class.table-height]="isTeleHealthReport">
        <table class="flex-grow" mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="document_title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header-container> Document Title</th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex justify-start items-center gap-2">
                        <img [src]="getItemIcon(element.title)" style="width: 18px;"
                            onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
                        <span>
                            {{getDocTitle(element.title) | titlecase}}
                        </span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header-container> Type </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.patient_id">
                        Face Sheet
                    </span>
                    <span *ngIf="!element.patient_id">
                        {{ PATIENT_DOCUMENT_TYPE[element.type] }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="uploaded_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header-container> Uploaded At </th>
                <td mat-cell *matCellDef="let element">
                    <span>
                        {{(element.createdAt || element.created_at) | date: global.date_format}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="operations">
                <th mat-header-cell *matHeaderCellDef mat-sort-header-container></th>
                <td mat-cell *matCellDef="let element; let i=index">
                    <ng-container *ngIf="!element.intakeform">

                        <ng-container *ngIf="!element.intakeform">
                            <div class="flex gap-10"
                                *ngIf="element.fileFormat === 'pdf' || element.fileFormat === 'txt'">
                                <i (click)="viewPDF(element, i)" class="icon icon-view" matTooltip="View"
                                    matTooltipPosition="above"></i>
                                <i (click)="downLoadPdfFiles(element)" class="icon icon-download" matTooltip="Download"
                                    matTooltipPosition="above"></i>
                                <i (click)="deletePatientDoc(i, element)" class="icon icon-delete" *ngIf="currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER"
                                    matTooltip="Delete" matTooltipPosition="above"></i>
                            </div>
                            <div class="flex gap-10"
                                *ngIf="(element.fileFormat !=='pdf' && element.fileFormat !=='txt')">
                                <i (click)="viewImageDoc(element, i)" class="icon icon-view" matTooltip="View"
                                    matTooltipPosition="above"></i>
                                <i (click)="downloadFile(element)" class="icon icon-download" matTooltip="Download"
                                    matTooltipPosition="above"></i>
                                <i (click)="deletePatientDoc(i, element)" class="icon icon-delete" *ngIf="currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER"
                                    matTooltip="Delete" matTooltipPosition="above"></i>
                            </div>
                        </ng-container>

                    </ng-container>

                    <i *ngIf="element.intakeform" (click)="viewPatientIntakeForm(element._id)" class="icon icon-view"
                        matTooltip="View" matTooltipPosition="above"></i>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <p style="margin: 1rem;font-size: 13px;
    color: #7f7e7e;" *ngIf="patientDocuments.length === 0 && !loader.hasRunningTask(true, this.loaderId)">No Patient Documents</p>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

    <div *ngIf="!(patient_side)" style="top: 100px;">    
     
        <div class="flex gap-10"
            *ngIf="selectedFileFormat == 'pdf' || selectedFileFormat == 'txt'">
            <ngx-doc-viewer *ngIf="file_path" [url]="file_path" viewer="google" style="width: 100%; margin-top:5px; height: 75vh; overflow: hidden;">
            </ngx-doc-viewer>
        </div>
        <div *ngIf="(selectedFileFormat != 'pdf' || selectedFileFormat != 'txt')&&file_path">

            <img width="97%" *ngIf="file_path" #image [src]="file_path" cross-origin="use-credentials">
        </div>
    </div>
    <div *ngIf="view === 'patient-intake-form'" style="max-height: 100%;overflow:auto;">
        <i class="icon icon-back-dark" style="min-width: 20px; min-height: 20px;" matTooltip="Go Back"
            matTooltipPosition="above" (click)="handleGoBackClick()"></i>
    </div>
    <div *ngIf="view === 'patient-document-view'">
        <i class="icon icon-back-dark" style="min-width: 20px; min-height: 20px;" matTooltip="Go Back"
            matTooltipPosition="above" (click)="handleGoBackClick()"></i>
        <pdf-viewer [src]="activePayload.aws_path" [render-text]="true" style="display: block;"></pdf-viewer>
    </div>
</div> 
