import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from "../../../environments/api-url";
import * as global from '../../includes/global';

@Injectable({
  providedIn: 'root'
})
export class SnfWcNurseAssociationService {

  constructor(private _httpclient: HttpClient) { }

  getFacilityAssociatedSnfWcNurses(filter: any, nurseProjection: any, facilityProjection: any) {
    return this._httpclient.post(global.url + API_URL.SnfWcNurseFacilities.getAssociatedSnfWcNurseFacilities, { filter: filter, nurseProjection: nurseProjection, facilityProjection: facilityProjection })

  }

  getAssociatedSnfWcNurses(filter: any, projection: any) {
    return this._httpclient.post(global.url + API_URL.SnfWcNurse.getAssociatedSnfWcNurses, { filter: filter, projection: projection })

  }

  createAssociatedSnfWcNursesRelation(filter: any, projection: any) {
    return this._httpclient.post(global.url + API_URL.SnfWcNurse.createSnfWcNurseRelation, { filter: filter, projection: projection })
  }


  createManySnfWcNurseRelations(filter: any) {
    return this._httpclient.post(global.url + API_URL.SnfWcNurse.createManySnfWcNurseRelations, { filter });
  }

  deleteManySnfWcNurseRelations(filter: any) {
    return this._httpclient.post(global.url + API_URL.SnfWcNurse.deleteManySnfWcNurseRelations, { filter });
  }

  deleteAssociatedSnfWcNursesRelation(filter: any, projection: any) {
    return this._httpclient.post(global.url + API_URL.SnfWcNurse.deleteSnfWcNurseRelation, { filter: filter, projection: projection })
  }


}
