import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CensusListService } from 'src/app/includes/census-list/census-list..service'; 

@Component({
  selector: 'app-rehab-docs-popup',
  templateUrl: './rehab-docs-popup.component.html',
  styleUrls: ['./rehab-docs-popup.component.css']
})
export class RehabDocsPopupComponent implements OnInit {

  documents: any = []
  displayedColumns: string[] = [];
  dataSource: any;
  sort: any;
  paginator: any;
  loading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _censusListService:CensusListService,
  ) { 
    this.displayedColumns = ['doc_name','doc_type', 'provider', 'facility', 'status' ];
  }

  async ngOnInit() { 
    await this.loadDocuments()
  }

  loadDocuments(){
    this.loading = true;
    this._censusListService.getRehabDocs(this.data).subscribe((response:any)=>{
      if(response.status ==  200){
        console.log("response in getRehabDocs: ", response);
        this.documents = response.data;
        this.dataSource = new MatTableDataSource(this.documents);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    })
    this.loading = false;
  }
}
