
        <div class="flex flex-row w-full" style="border-bottom: 5px solid #eee;">
            <div class="flex flex-col w-1/2 gap-2" style="padding:15px 15px 15px 15px">
                <mat-card-title>
                    <h2 style="color: black;margin:0">All Permissions </h2>
                </mat-card-title>

                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
              
                  <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%; text-align: start;"> Code </th>
                    <td mat-cell *matCellDef="let element; let i = index;" style="width: 20%; text-align: start;">{{element.code}}
                    </td>
                  </ng-container>
              
              
                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef  style="width: 40%; text-align: start;"> Title </th>
                    <td mat-cell *matCellDef="let element" style="width: 40%; text-align: start;"> {{element.title}} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="operation" style="text-align: center;">
                    <th mat-header-cell *matHeaderCellDef  style="width: 40%; text-align: center;"></th>
                    <td mat-cell *matCellDef="let element" style="width: 40%; text-align: center; "> 
                        <img *ngIf="!checkPermissionCode(element)"  (click)="addPermissionCode(element)" style="width: 26px; cursor: pointer;"  src="../../../../assets/icons/addNewPage.png">
                    </td>
                  </ng-container>

              
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>

              <div class="flex flex-col w-1/2 gap-2" style="padding:15px 15px 15px 15px">
                
                <mat-card-title>
                    <h2 style="color: black;margin:0">Permissions Added </h2>
                </mat-card-title>

                <table mat-table [dataSource]="dataSourceUser" class="mat-elevation-z8" matSort>
              
              
                  <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%; text-align: start;"> Code </th>
                    <td mat-cell *matCellDef="let element; let i = index;" style="width: 20%; text-align: start;">{{element.code}}
                    </td>
                  </ng-container>
              
              
                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef  style="width: 40%; text-align: start;"> Title </th>
                    <td mat-cell *matCellDef="let element" style="width: 40%; text-align: start;"> {{element.title}} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="operation" style="text-align: center;">
                    <th mat-header-cell *matHeaderCellDef  style="width: 40%; text-align: center;"></th>
                    <td mat-cell *matCellDef="let element" style="width: 40%; text-align: center; cursor: pointer;" (click)="removePermissionCode(element)"> 
                        <img  style="width: 24px;"  src="../../../../assets/icons/minus.png">
                    </td>
                  </ng-container>

              
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
        
        </div>

