import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {API_URL} from "../../environments/api-url";
import * as global from '../includes/global';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthenticator2FAService {
  currentUser: any;
  constructor(private httpclient: HttpClient,
    private _authService: AuthService) {
      this.currentUser = this._authService.currentUser;
  }
  enableGoogle2Fa(){
    const data = {
      user_id: this.currentUser._id
    }
    return this.httpclient.post(global.url + API_URL.GoogleAuthenticator.setupGoogle2FA, {details: data}).pipe();
  }
  verifyGoogle2FA(code = "", enableGoogleAuth = false){
    const data = {
      user_id: this.currentUser._id,
      code,
      enableGoogleAuth
    }
    return this.httpclient.post(global.url + API_URL.GoogleAuthenticator.verifyGoogle2FA, {details: data}).pipe();
  }
  disableGoogle2FA(){
    return this.httpclient.post(global.url + API_URL.GoogleAuthenticator.disableGoogle2FA, {}).pipe();

  }
}