import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { ChargesCatagoriesDialogComponent } from 'src/app/shared/popup-dialogs/charges-catagories/charges-catagories-dialog/charges-catagories-dialog.component';
import { ChargesCatagoryService } from 'src/app/shared/popup-dialogs/charges-catagories/charges-catagories-dialog/charges-catagory.service';
import { NewsServiceService } from '../../news-service.service';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.css']
})
export class AddNewsComponent implements OnInit {
  news: any;
  newsModel: any;
  selectedNews: any;
  displayedColumns = ['title', 'operations']
  dataSource: any;  
  loading:Boolean = true;
  update: Boolean = false;
  create: Boolean = true;
  updatingCategory:any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator; 
  visible = true;
  selectable = true;
  removable = true;
  loaderId = 'loader_id';
  separatorKeysCodes: number[] = [ENTER, COMMA]; 
  currentUser:any;
  company_id:any; 
  companyPath:Boolean = false;

  constructor(
    private chargesCatagoryService: ChargesCatagoryService,
    public loader: NgxUiLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private authServise: AuthService,
    public dialogRef: MatDialogRef<ChargesCatagoriesDialogComponent>,
    // public services: UserTypesRelationshipService,
    private _commonService: CommonService,
    private service: NewsServiceService,
    @Inject(MAT_DIALOG_DATA) public company: any
  ) { 
    this.currentUser = this.authServise.currentUser;
  } 
  async ngOnInit() {   
    await this.initNews(); 
  }
  async initNews(){
    this.loader.startLoader('loader_id'); 
    await this.service.getNews().subscribe((response: any)=>{
      if(response.status == 200){
        this.service.setNewsChanged(true);
        this.news = response.news;
        this.dataSource = new MatTableDataSource(this.news)
        // this.news = response
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      this.loader.stopLoader('loader_id'); 
    })
  }  

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
        return data.news.toLowerCase().includes(filter) ;
      };
    }
    else {
      this.initNews();
    }
  }
  async addNews() {
    const result = await this.service.addNews(this.newsModel).subscribe((response: any) => {
      if (response.status == 200) {  
        this.newsModel = '';
        this.initNews();
      }
    })
  }
  async onDeleteClick(element) {
    await this.service.deleteNews(element._id).subscribe((response: any) => {
      if (response.status == 200) {  
        this.initNews();
      }
    })
  }
  async updateNews(){
    await this.service.updateNews(this.newsModel, this.selectedNews).subscribe((response: any) => {
      if (response.status == 200) {  
        this.newsModel = '';
        this.initNews();
      }
    }) 
  }
  onEditClick(element) { 
    this.newsModel = element.news;
    this.selectedNews = element._id; 
    this.update = true;
    this.create = false;
  }   

   
}
