<div *ngIf="patient" class="flex flex-row justify-between items-center gap-1">
    <div class="flex flex-row gap-1 items-center">
        <img src="../../../assets/icons/pcc-icon.svg" *ngIf="isPCCPatient(patient)" class="pcc-icon" alt="">    
        <img src="../../../assets/icons/matrix.png" *ngIf="patient?.source == 'MatrixCare'" alt=""
        style="height: 12px;
        width: 20px;
        margin-right: 5px;
        vertical-align: middle;">
        <img src="../../../assets/img/DN.png" *ngIf="isDNPatient(patient)" class="dn-icon" alt="">
        <span class="title">{{formatPatientName(patient)}}</span>
    </div>
    <span *ngIf="isDateOfBirthVisible">{{ patient.date_of_birth | date: 'MM/dd/yyyy'}}</span>
</div>