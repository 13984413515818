import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.css']
})
export class ImageCarouselComponent implements OnInit {
  images: any = [];
  title = ''
  currentIndex: number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    let images = this.data.images;
    this.title = this.data.title
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.images.push(e.target.result);
        };
        reader.readAsDataURL(images[i]);
      }
    }
  }
  ngOnInit(): void {
  }
  previousImage(): void {
    this.currentIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : this.images.length - 1;
  }
  nextImage(): void {
    this.currentIndex = (this.currentIndex < this.images.length - 1) ? this.currentIndex + 1 : 0;
  }
}
