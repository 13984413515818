import { ChangeDetectorRef, Component, OnInit, SimpleChanges } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-picker-widget',
  templateUrl: './date-picker-widget.component.html',
  styleUrls: ['./date-picker-widget.component.css']
})
export class DatePickerWidgetComponent implements OnInit {
  searchYear: any;
  selectedYear: any = "1950";
  selectedMonth: any = "";
  selectedDay: any = "";
  yearsOption: String[];
  monthOptions: String[];
  daysOption: String[];
  isRequired: boolean = true;
  @Input() defaultYear = '1950'
  @Input() date = '';
  @Input() placeholder = '';
  @Input() is_Required = true;
  @Input() minYear = '';
  @Input() isDisabled: boolean = false;
  @Output() dateEvent = new EventEmitter<any>();

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes.placeholder){
      this.placeholder = changes.placeholder.currentValue;
    }
    if(changes.date){
      this.date=changes.date.currentValue;
    }
    this.initDate();
    this.cdr.detectChanges();

  }

  // ngOnChanges(changes: SimpleChanges) {
  //   this.date=changes.date.currentValue;
  //   this.initDate();
  // }
  ngOnInit(): void {
    if (this.is_Required) {
      this.isRequired = true;
    } else {
      this.isRequired = false;
    }
    this.initDate();
  }
  initDate(){
    if(this.date == null) {
      this.selectedMonth = '';
      this.selectedYear = this.defaultYear;
    }
    this.monthOptions = ["01", "02", "03", "04",
     "05", "06", "07", "08", "09",
      "10", "11", "12"];
    this.yearsOption = [];
    this.daysOption = [];
    let todayYear = new Date().getFullYear();
    if (this.minYear == '') {
      this.minYear = "1900"
    }
    if (new Date().getMonth() >= 11) {
      this.yearsOption.push((new Date().getFullYear()+1).toString());
    }
    for (let i = todayYear; i >= parseInt(this.minYear); i--) {
      this.yearsOption.push(i.toString());
    }
    if (this.date != '') {
      if (this.date?.includes("-")) {
        let arry = this.date.split("-");
        this.selectedYear = arry[0];
        this.selectedMonth = arry[1];
        this.OnMonthChange(this.selectedMonth);
        this.selectedDay = arry[2];
      }
    } else {
      if(this.selectedYear === '') {
        this.selectedYear = '1950';
      }
      // this.selectedMonth = '';
      // this.selectedDay = '';
      if (this.selectedMonth) {
        this.OnMonthChange(this.selectedMonth);
      }
    }
  }

  OnYearChange(year) {
    this.OnMonthChange(this.selectedMonth);
    if (this.selectedYear != "" && this.selectedMonth != "" && this.selectedDay != "") {
      let date = this.selectedYear + "-" + this.selectedMonth + "-" + this.selectedDay;
      this.dateEvent.emit(date);
    }
    else {
      this.dateEvent.emit(false);
    }
  }
  OnMonthChange(month, emitValue = false) {
    this.daysOption = [];
    let numDays = 0;
    if (month == "01" || month == "03" || month == "05" ||
      month == "07" || month == "08" || month == "10" || month == "12") {
      numDays = 31;
    }
    else if (month == "04" || month == "06" || month == "09" || month == "11") {
      numDays = 30;
    } else {
      numDays = this.isLeapYear(this.selectedYear) ? 29 : 28;
    }
    let opt;
    for (let i = 1; i <= numDays; i++) {
      opt = "";
      if (i < 10) {
        opt = "0" + i.toString();
      } else {
        opt = i.toString();
      }
      this.daysOption.push(opt);
    }
    this.selectedDay = "";
    if (emitValue) {
      this.dateEvent.emit(false);
    }
  }
  isLeapYear(year) {
    year = Number(year);
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
  }

  OnDayChange(day) {
    if (this.selectedYear != "" && this.selectedMonth != "" && this.selectedDay != "") {
      let date = this.selectedYear + "-" + this.selectedMonth + "-" + this.selectedDay;
      this.dateEvent.emit(date);
    }
    else {
      this.dateEvent.emit(false);
    }
  }
}
