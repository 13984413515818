import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

// CREATE NEW / RENAME FOLDER DIALOG
export interface CreateNewFolderDialogData {
    action:String // create, rename 
    title:String
  }
  @Component({
    selector: 'create-folder-new-dialog',
    templateUrl: './create-new-folder-dialog.html',
    styleUrls: ['./drive.component.css']
  })
  export class CreateNewFolderDialog {
    title:String="";
    constructor(
      public dialogRef: MatDialogRef<CreateNewFolderDialog>,
      @Inject(MAT_DIALOG_DATA) public data: CreateNewFolderDialogData) {
        if(data && data.title) {
          this.title = data.title;
        }      
      }
  }
