import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from "../../../environments/api-url";
import * as global from '../../includes/global';

@Injectable({
  providedIn: 'root'
})
export class ViewOnlyUsersService {

  constructor(private _httpclient: HttpClient) { }

  getViewOnlyUsersProviderRelation(filter: any) {
    return this._httpclient.post(global.url + API_URL.viewOnlyUser.getViewOnlyUsersProviderRelation, { filter: filter })
  }

  updateViewOnlyUsersProviderRelation(filter: any, data: any, isRemoving?: boolean) {
    return this._httpclient.post(global.url + API_URL.viewOnlyUser.updateViewOnlyUsersProviderRelation, { filter: filter, associated_provider_ids: data, isRemoving: isRemoving })
  }

  getAssociatedProvidersListForViewOnlUsers(filter) {
    return this._httpclient.post(global.url + API_URL.viewOnlyUser.getAssociatedProvidersListForViewOnlUsers, filter)
  }
}
