<div *ngIf="false" class="flex gap-2 justify-end items-baseline" style="padding: 6px;">
  <button mat-raised-button class="dn-button-primary" *ngIf="report.showDownloadButton"
    style="font-size: 11px;position: absolute;top: 10px;z-index: 999;right: 40px;" (click)="downloadReport()">Download
    Report </button>
</div>
<div style="overflow-y: auto;">
  <ckeditor name="summary_text" id="editor" 
  style="line-height: 10px;line-height: 20px; font-family: serif;font-size: 13px;"
  [ngModel]="summaryData" (ngModelChange)="summaryDataChanged($event)"
    [config]="config" [editor]="Editor"></ckeditor>

</div>

<mat-card *ngIf="false">
  <div *ngIf="insurance_company === 'demarco' || !insurance_company" style="overflow: auto;">
<div class="patient-attribute flex gap-2 justify-between items-baseline">
      <strong>RECORD OUTLINE : </strong>
      <input style="display: inline-block;width:auto;" matInput type="text" placeholder="Enter claimant Name"
        [value]="claiment_name" [(ngModel)]="claiment_name" />
    </div>
<div class="patient-attribute flex gap-2 justify-between items-baseline">
      <strong>AIS: </strong>
      <input style="display: inline-block;width:auto;" matInput type="text" placeholder="Enter AIS"
        [value]="claiment_id" [(ngModel)]="claiment_id" />
    </div>
<div class="patient-attribute flex gap-2 justify-between items-baseline">
      <strong>DOI: </strong>
      <input style="display: inline-block;width:auto;" matInput type="text" placeholder="Enter DOI"
        [value]="changeDate(claiment_doi)" [(ngModel)]="claiment_doi" />
    </div>
    <br /><br />
    <div *ngFor="let group of groupedData | keyvalue">
      <input matInput type="text" [value]="group.key" />
      <div *ngFor="let row of group.value">
        <input matInput type="text"
          [value]="(row.date | date:'shortDate') + ' - ' + row.note_type + ' '+ (!row.exam || row.exam ===undefined? '':row.exam) +' pg. ' + row.page_no " />
        <mat-form-field *ngIf="!isEmpty(row.impression)" style="width: 100%;">
          <textarea matInput rows="4" [value]="'Impression: ' + (row.impression | lowercase)"></textarea>
        </mat-form-field>
      </div>

      <br /><br />
    </div>
  </div>

  <div *ngIf="insurance_company !== 'demarco'" style="overflow: auto;">
<div class="patient-attribute flex gap-2 justify-between items-baseline">
      <strong>Claim Type: </strong>
      <input style="display: inline-block;width:auto;" matInput type="text" placeholder="Enter claima Type"
        [value]="claim_type" [(ngModel)]="claim_type" />
    </div>
<div class="patient-attribute flex gap-2 justify-between items-baseline">
      <strong>Carrier: </strong>
      <input style="display: inline-block;width:auto;" matInput type="text" placeholder="Carrier" [value]="carrier"
        [(ngModel)]="carrier" />
    </div>
<div class="patient-attribute flex gap-2 justify-between items-baseline">
      <strong>Claimant: </strong>
      <input style="display: inline-block;width:auto;" matInput type="text" placeholder="Enter claimant Name"
        [value]="claiment_name" [(ngModel)]="claiment_name" />
    </div>
<div class="patient-attribute flex gap-2 justify-between items-baseline">
      <strong>DOB: </strong>
      <input style="display: inline-block;width:auto;" matInput type="text" placeholder="Enter DOB"
        [value]="claiment_dob" [(ngModel)]="claiment_dob" />
    </div>
<div class="patient-attribute flex gap-2 justify-between items-baseline">
      <strong>Client File#: </strong>
      <input style="display: inline-block;width:auto;" matInput type="text" placeholder="Enter Client File#"
        [value]="client_file" [(ngModel)]="client_file" />
    </div>
<div class="patient-attribute flex gap-2 justify-between items-baseline">
      <strong>UniMex File#: </strong>
      <input style="display: inline-block;width:auto;" matInput type="text" placeholder="Enter UniMex File#"
        [value]="UniMex_file" [(ngModel)]="UniMex_file" />
    </div>
<div class="patient-attribute flex gap-2 justify-between items-baseline">
      <strong>DOA: </strong>
      <input style="display: inline-block;width:auto;" matInput type="text" placeholder="Enter DOA"
        [value]="changeDate(claiment_doa)" [(ngModel)]="claiment_doa" />
    </div>
<div class="patient-attribute flex gap-2 justify-between items-baseline">
      <strong>Employer: </strong>
      <input style="display: inline-block;width:auto;" matInput type="text" placeholder="Enter Employer"
        [value]="employer" [(ngModel)]="employer" />
    </div>
<div class="patient-attribute flex gap-2 justify-between items-baseline">
      <strong>WCB File#: </strong>
      <input style="display: inline-block;width:auto;" matInput type="text" placeholder="Enter WCB File#"
        [value]="wcb_file" [(ngModel)]="wcb_file" />
    </div>
    <br /><br />
    <div *ngFor="let group of groupedData | keyvalue">
      <div *ngFor="let row of group.value">
        <input matInput type="text" [value]="value(row)" />
        <mat-form-field *ngIf="!isEmpty(row.impression)" style="width: 100%;">
          <textarea matInput rows="4" [value]="'Impression: ' + (row.impression | lowercase)"></textarea>
        </mat-form-field>
      </div>
      <br /><br />
    </div>
  </div>

</mat-card>