import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PccWidgetService {
  
    saveNoteStatus = new BehaviorSubject<boolean>(false);
    castSaveNoteStatus = this.saveNoteStatus.asObservable();

    note = new BehaviorSubject<any>({});
    castNote = this.note.asObservable();


  currentUser: any;
  constructor(private httpclient: HttpClient,
              private authService: AuthService) {
      this.currentUser = this.authService.currentUser;
  }

  changeSaveNoteStatus(status) {
    this.saveNoteStatus.next(status);
}

changeNote(note) {
  this.note.next(note);
}

}
