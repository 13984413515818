<div class="flex items-center justify-center">
    <h1 mat-dialog-title style="color: black">Add New Attribute </h1>
</div>
<div mat-dialog-content>
    <form (ngSubmit)="addAttribute(form.value)" #form="ngForm">
        <div class="flex flex-col">
            <mat-form-field>
                <mat-label>Type</mat-label>
                <mat-select [(value)]="isNew">
                    <mat-option value="true">New</mat-option>
                    <mat-option value="false">Existing</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="isNew === 'true'">
                <input matInput
                       name="title"
                       required
                       #attribute_title="ngModel"
                       [(ngModel)]="title"
                       placeholder="Title">
                <mat-error *ngIf="attribute_title.invalid  && (attribute_title.dirty || attribute_title.touched)">
                    Please enter title
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="isNew === 'false'">
                <mat-label>Select Attribute</mat-label>
                <mat-select name="attribute"
                            required
                            #attribute="ngModel"
                            [(ngModel)]="attribute_id">
                    <mat-option *ngFor="let attr of attributes"
                                value="{{attr._id}}">{{attr.title}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="attribute.invalid  && (attribute.dirty || attribute.touched)">
                    Please select attribute
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Select Facility</mat-label>
                <mat-select name="facility"
                            required
                            #facility="ngModel"
                            [(ngModel)]="facility_id">
                    <mat-option *ngFor="let f of facilities"
                                value="{{f._id}}">{{f.title}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="facility.invalid  && (facility.dirty || facility.touched)">
                    Please select facility
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex items-center justify-center gap-2">
            <button mat-raised-button
                    class="dn-button-secondary"
                    mat-dialog-close
                    type="button">Cancel</button>
            <button mat-raised-button
                    [disabled]="!form.form.valid"
                    type="submit"                    
                    class="dn-button-primary">Save</button>
        </div>
    </form>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
