import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';
import { API_URL } from 'src/environments/api-url';
import * as global from '../../includes/global';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ICompany } from 'src/app/classes/company';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private company = new BehaviorSubject<ICompany>(null);
  private userRoleType = new BehaviorSubject<string>(null);
  castUserRoleType = this.userRoleType.asObservable();
  castCompany = this.company.asObservable();
  noteUploadingMethods = [
    {
      value: 'progress_note',
      label: 'Progress Note'
    },
    {
      value: 'encounter_note',
      label: 'Encounter Note'
    },
    {
      value: 'pdf_document',
      label: 'PDF Document'
    }
  ];

  constructor(
    private http: HttpService,
    private authService: AuthService,
    private httpclient: HttpClient,
  ) {
    if (this.authService?.currentUser?.account_type === 'company') {
      this.getAssociatedCompanyId(this.authService?.authObject?.id, null).subscribe((response: any) => {
        this.company.next(response.data)
      })
    }
  }

  getCompanyUploadingMethods(company_type: string, source?: 'PointClickCare' | 'MatrixCare') {
    let result = this.noteUploadingMethods;
    if (company_type.toLowerCase() != 'nursing') {
      result = result.filter((method) => {
        return method.value != 'pdf_document';
      });
    }

    if (source === 'MatrixCare') {
      // remove enounter_note from result
      result = result.filter((method) => {
        return method.value != 'encounter_note';
      });

    }
    return result;
  }
  changeUserRoleType(role) {
    this.userRoleType.next(role);
  }

  getCompanyName(companyId = null) {
    const details = {
      companyId,
    }
    return this.http.post(global.url + API_URL.COMPANY.getCompanyName, details);
  }

  getSignature(payload) {
    const details = {
      ...this.authService.authObject,
      payload
    }
    return this.http.post(global.url + API_URL.COMPANY.getSignature, details);
  }
  generateMeetingLink(data) {
    const details = {
      ...this.authService.authObject,
      ...data
    }
    return this.http.post(global.url + API_URL.COMPANY.generateMeetingLink, details);
  }
  getAppointmentColumnSetting(companyId = null) {
    const details = {
      companyId,
    }
    return this.http.post(global.url + API_URL.COMPANY.getAppointmentColumnSetting, details);
  }
  updateAppointmentColumnSetting(companyId = null,appointment_column_setting= []) {
    const details = {
      companyId,
      appointment_column_setting
    }
    return this.http.post(global.url + API_URL.COMPANY.updateAppointmentColumnSetting, details);
  }
  getCompany(filter, projection) {
    return this.http.post(global.url + API_URL.COMPANY.getCompany, {filter, projection});
  }
  saveCompanyReportSettings(company_id,report_settings) {
    const details = {
      company_id,
      report_settings
    }
    return this.http
      .post(global.url + API_URL.COMPANY.saveCompanyReportSettings, details);
  }
  saveCompanyAppointmentTypes(company_id,appointment_types) {
    const details = {
      company_id,
      appointment_types
    }
    return this.http
      .post(global.url + API_URL.COMPANY.saveCompanyAppointmentTypes, details);
  }
  saveVisitStatus(company_id,appointment_status_visit) {
    const details = {
      company_id,
      appointment_status_visit
    }
    return this.http
      .post(global.url + API_URL.COMPANY.saveCompanyAppointmentStatus, details);
  }
  updateCompanyAppointmentType(company_id,appointment_type_id,duration) {
    const details = {
      company_id,
      appointment_type_id,
      duration
    }
    return this.http
      .post(global.url + API_URL.COMPANY.updateCompanyAppointmentType, details);
  }
  saveChargeSetting(company_id,charge_edit_duration) {
    const details = {
      company_id,
      charge_edit_duration
    }
    return this.http
      .post(global.url + API_URL.COMPANY.saveChargeSetting, details);
  }
  getChargeSetting(company_id) {
    const details = {
      company_id,
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getChargeSetting, details);
  }
  getCompanyReportSettings(company_id) {

    const details = {
      company_id
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getCompanyReportSettings, details);
  }
  getCompanyAppointmentTypes(company_id) {

    const details = {
      company_id
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getCompanyAppointmentTypes, details);
  }
  getCompanyAppointmentVisitStatus(company_id: string) {

    const details = {
      company_id
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getCompanyAppointmentVisitStatus, details);
  }
  
  getCompanyUsers(company_id, user_type) {
    const details = {
      company_id,
      user_type
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getCompanyUsers, details);
  }
  getCensusColumnSetting(company_id) {
    const details = {
      company_id}
    return this.http
      .post(global.url + API_URL.COMPANY.getCensusColumnSetting, details);
  }
  updateCensusColumnSetting(census_columns, company_id) {
    let data = {
      census_columns, 
      company_id,
      userId: this.authService.currentUser._id
    }
    return this.http
      .post(global.url + API_URL.COMPANY.updateCensusColumnSetting, data);
  }
  getCompanyFacilities(company_id, provider_id = null) {
    const details = {
      company_id,
      provider_id
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getCompanyFacilities, details);
  }

  getSpellCheckOption(user_id) {
    const details = {
      user_id,
    }
    return this.http.post(global.url + API_URL.COMPANY.getSpellCheckOption, details).pipe();
  }

  setSpellCheckOption(company_id, user_id, status) {
    const details = {
      company_id,
      user_id,
      status
    }
    return this.http.post(global.url + API_URL.COMPANY.setSpellCheckOption, details);
  }

  getAssociatedCompanyId(user_id, _id) {
    const details = {
      user_id,
      _id
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getAssociatedCompanyId, details);
  }

  getLegalAuthenticator(data){
    return this.http
      .post(global.url + API_URL.COMPANY.getLegalAuthenticator, data);
  }
  addUser(data) { 

    return this.http
      .post(global.url + API_URL.COMPANY.adduser, data);
  }
  addFacility(data) {
    data = {
      ...data,
      userId: this.authService.currentUser._id
    }
    return this.http
      .post(global.url + API_URL.COMPANY.addFacility, data);
  }

  updateFacility(data) {
    data = {
      ...data,
      userId: this.authService.currentUser._id
    }
    return this.http
      .post(global.url + API_URL.COMPANY.updateFacility, data);
  }
  updateInActiveFacility(data) {
    
    return this.http.post(global.url + API_URL.COMPANY.updateInActiveFacility, data).pipe();
  }

  updatePayorUsers(payor_id, user_ids) {
    const details = {
      payor_id,
      user_ids
    }
    return this.http
      .post(global.url + API_URL.COMPANY.updatePayorUsers, details);
  }

  deleteFacility(facility_id) {
    const details = {
      facility_id
    }
    return this.http
      .post(global.url + API_URL.COMPANY.deleteFacility, details);
  }
  getAllCompanies(query?, company_id?) {
    let details: any = {
    }
    if (query) {
      details.query = query;
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getAllCompanies, details);
  }

  getCPTPOSRelations(filter) {
    const details = {
      filter: filter
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.getCPTPOSRelations, details).pipe();
  }
  
  getCompanyAssociation(company_id) {
    let details: any = {
      company_id
    }
  
    return this.http
      .post(global.url + API_URL.COMPANY.getCompanyAssociation, details);
  }
  matrixPatientExtract(facility_id, external_id) {
    let details: any = {
      external_id,
      facility_id
    }
  
    return this.http
      .post(global.url + API_URL.MATRIXCARE_MIRTH.matrixPatientExtract, details);
  }

  saveCompanyCompanyRelation(query?) {
    let details: any = {
      ...query
    }
    details.is_active = true;
    return this.http
      .post(global.url + API_URL.COMPANYASSOSIATION.saveCompanyCompanyRelation, details);
  }

  updateCompanyAssociation(filter, updateQuery) {
    return this.http
      .post(global.url + API_URL.COMPANYASSOSIATION.updateCompanyAssociation, { filter, updateQuery });
  }

  getAllCompaniesWithCompanyAssosiation(query?) {
    let details: any = {
      ...query
    }
    details.is_active = true;
    return this.http
      .post(global.url + API_URL.COMPANYASSOSIATION.getAllCompaniesWithCompanyAssosiation, details);
  }
  getCompaniesFacilityAssociation(filter) {
    const details: any = {
      filter : filter
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getCompaniesFacilityAssociation, details);
  }
  exportCompaniesFacilityAssociationAsXLSX(filter) {
    let details: any = {
      filter
    }
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.COMPANY.exportCompaniesFacilityAssociationAsXLSX, details,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  getAllCompaniesWithCount() {
    const details = {
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getAllCompaniesWithCount, details);
  }
  getAllAssosiatedCompaniesWithCount(query){
    const details = {
      ...query
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getAllAssosiatedCompaniesWithCount, details);
  }
  changeCompanyCarryForwardtatus(companyId, status, type){
    const payload = {
      companyId,
      userId:this.authService.currentUser._id,
      is_active: status,
      type
    }
    console.log(payload)
    return this.http
      .post(global.url + API_URL.COMPANY.changeCompanyCarryForwardStatus, { details: payload });
  }
  changeInactiveReasonStatus(companyId, status){
    const payload = {
      companyId,
      userId:this.authService.currentUser._id,
      is_active: status
    }
    console.log(payload)
    return this.http
      .post(global.url + API_URL.COMPANY.changeInactiveReasonStatus, { details: payload });
  }
  viewEligibilityStatus(companyId, status){
    const payload = {
      companyId,
      userId:this.authService.currentUser._id,
      is_active: status
    }
    return this.http
      .post(global.url + API_URL.COMPANY.viewEligibilityStatus, { details: payload });
  }
  getEligiblityStatus(companyId){
    const payload = {
      companyId,
      userId:this.authService.currentUser._id,
    }
    return this.http
    .post(global.url + API_URL.COMPANY.getEligibilityStatus, { details: payload });
  }
  changeCompanyActiveStatus(companyId: string, status) {
    const payload = {
      companyId,
      is_active: status,
      userId: this.authService.currentUser._id
    }
    console.log(payload)
    return this.http
      .post(global.url + API_URL.COMPANY.changeCompanyActiveStatus, { details: payload });
  }
  getCompanyChargeandSignNoteStatus(companyId: string) {
    const payload = {
      companyId
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getCompanyChargeandSignNoteStatus, { details: payload });
  }
  getCompanyPermissionForTimeLogs(companyId: string) {
    const payload = {
      companyId
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getCompanyPermissionForTimeLogs, { details: payload });
  }
  companyChangeForUser(user_id, company_id, currentCompany_id?) {
    let details: any = {
      user_id,
      company_id,
      userId:this.authService.currentUser._id
    }
    if (currentCompany_id) details.currentCompany_id = currentCompany_id;
    console.log('details........',details);

    return this.http
      .post(global.url + API_URL.COMPANY.companyChangeForUser, details);
  }
  companyChangeForFacility(facility_id, company_id) {
    const details = {
      facility_id,
      company_id,
    }
    return this.http
      .post(global.url + API_URL.COMPANY.companyChangeForFacility, details);
  }

  deletePayor(payor_id) {
    const details = {
      payor_id
    }
    return this.http
      .post(global.url + API_URL.COMPANY.deletePayor, details);
  }

  addFacilityContact(data) {
    return this.http
      .post(global.url + API_URL.COMPANY.addFacilityContact, data);
  }

  updateFacilityContact(data) {
    return this.http
      .post(global.url + API_URL.COMPANY.updateFacilityContact, data);
  }

  getFacilityContacts(data, associated_option?) {
    data = {
      ...data,
      associated_option: associated_option
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getFacilityContacts, data);
  }

  updateCompany(data) {
    
    data = {
      ...data,
      userId: this.authService.currentUser._id
    }
    // const observable = this.http
    //   .post(global.url + API_URL.COMPANY.updateCompany, data);

    // observable.subscribe((response: any) => {
    //   this.company.next(response.data);
    // })
    // return observable;
    return this.http.post(global.url + API_URL.COMPANY.updateCompany, data);
  }

  updateFacesheetSetting(data) {
    data = {
      ...data,
    }
    return this.http.post(global.url + API_URL.COMPANY.updateFacesheetSetting, data);
  }

  updateIPASetting(data) {
    data = {
      ...data,
    }
    return this.http.post(global.url + API_URL.COMPANY.updateIPASetting, data);
  }

  updateFacesheetExtactionSetting(data) {
    data = {
      ...data,
    }
    return this.http.post(global.url + API_URL.COMPANY.updateFacesheetExtactionSetting, data);
  }

  updateNoteUploadingMethodSetting(data) {
    data = {
      ...data,
    }
    return this.http.post(global.url + API_URL.COMPANY.updateNoteUploadingMethodSetting, data);
  }

  userCreatePassword(data) {
    return this.http.post(global.url + API_URL.COMPANY.userCreatePassword, data);
  }
  createStrongPassword(data) {
    return this.http.post(global.url + API_URL.COMPANY.createStrongPassword, data);
  }
  verifyCode(data) {
    return this.http.post(global.url + API_URL.COMPANY.verifyCode, data);
  }
  approveFromAdmin(data) {
    return this.http.post(global.url + API_URL.COMPANY.approveFromAdmin, data);
  }
  resendConfirmationCode(data) {
    return this.http.post(global.url + API_URL.COMPANY.resendConfirmationCode, data);
  }
  ResendEmail(userId) {
    const details = {
      ...this.authService.authObject,
      userId
    }
    return this.http
      .post(global.url + API_URL.COMPANY.ResendEmail, { details });
  }
  getPayors(filter, projection = {}) {
    const details = {
      filter, projection
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getPayors, details);
  }

  get2FacAuth(userId) {
    const details = {
      ...this.authService.authObject,
      userId
    }
    return this.http
      .post(global.url + API_URL.COMPANY.get2FacAuth, { details });
  }

  verify2FacAuth(userId, authCode) {
    const details = {
      ...this.authService.authObject,
      userId,
      authCode
    }
    return this.http
      .post(global.url + API_URL.COMPANY.verify2FacAuth, { details });
  }


  approveUserActivation(userId, status) {
    const details = {
      ...this.authService.authObject,
      userId,
      status
    }
    return this.http
      .post(global.url + API_URL.COMPANY.approveUserActivation, { details });
  }


  downloadAsXLSX(data) {
    const details = {
      // ...this.authService.authObject,
      data
    }
    // return this.http
    //     .post(global.url + API_URL.COMPANY.downloadAsXLSX, details);

    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.COMPANY.downloadAsXLSX, details,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  exportUsersAsXLSX(company_id, user_type?, user_status?, addionalUserRole = null,role?) {
    let details;
    if (addionalUserRole) {
      details = {
        ...this.authService.authObject,
        company_id,
        user_type,
        user_status,
        addionalUserRole,
        role
      }
    } else {
      details = {
        ...this.authService.authObject,
        company_id,
        user_type,
        user_status,
        role
      }
    }

    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');

    return this.httpclient.post(global.url + API_URL.COMPANY.exportUsersAsXLSX, details,
      {
        responseType: "blob",
        reportProgress: true,
        observe: 'events',
        headers
      });
  }
  getPccFacilityActivationNote(company_id) {
    const details = {
      ...this.authService.authObject,
      company_id
    }
    return this.http
      .post(global.url + API_URL.COMPANY.getPccFacilityActivationNote, details);
  }
  setPccFacilityActivationNote(company_id, note) {
    const details = {
      ...this.authService.authObject,
      company_id,
      note
    }
    return this.http
      .post(global.url + API_URL.COMPANY.setPccFacilityActivationNote, details);
  }

  saveCompanyImages(data: FormData) {
    data.append('userId', this.authService.currentUser._id);
    return this.httpclient
      .post(global.url + API_URL.COMPANY.saveCompanyImages, data);
  }

  saveCompanyModifier(data) {

    let detail = {
      ...data,
      userId: this.authService.currentUser._id
    }
    console.log("detail : ", data, detail);
    return this.httpclient
      .post(global.url + API_URL.COMPANY.saveCompanyModifier, detail);
  }
  checkPatientSearchCompanyAcrosss() {
    const details = {
      ...this.authService.authObject,
      companyId: this.authService.currentUser.company_id
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.checkPatientSearchCompanyAcrosss, details).pipe();
  }

  updateCptRelation(companyId,cptId,isCreating) {
    const details = {
      ...this.authService.authObject,
      companyId,
      cptId,
      isCreating
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.updateCptRelation, details).pipe();
  }

  updateCompanyCptRelationForModifier(companyId,cptId,isCreating) {
    const details = {
      ...this.authService.authObject,
      companyId,
      cptId,
      isCreating
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.updateCompanyCptRelationForModifier, details).pipe();
  }
  
  getCompanyCPTRelations() {
    const details = {
      ...this.authService.authObject,
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.getCompanyCPTRelations, details).pipe();
  }
  getDataSetColumnsSetting(dataSet = null, company_id, user_type = "")
  {
    const data = {
      ...this.authService.authObject,
      dataSet,
      company_id,
      user_type
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.getDataSetColumnsSetting, {data: data}).pipe();
  }
  updateDataSetColumnsSettings(dataSet, company_id, data_set_columns, user_type)
  {
    const data = {
      ...this.authService.authObject,
      dataSet,
      company_id,
      data_set_columns,
      user_type
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.updateDataSetColumnsSettings, {data: data}).pipe();
  }
  getProcedureNoteDataSetColumnsSetting(company_id, user_type = "", procedure_type_id = "")
  {
    const data = {
      ...this.authService.authObject,
      company_id,
      user_type,
      procedure_type_id
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.getProcedureNoteDataSetColumnsSetting, {data: data}).pipe();
  }
  updateProcedureNoteDataSetColumnsSettings(company_id, data_set_columns, user_type, procedure_type_id)
  {
    const data = {
      ...this.authService.authObject,
      company_id,
      data_set_columns,
      user_type,
      procedure_type_id
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.updateProcedureNoteDataSetColumnsSettings, {data: data}).pipe();
  }

  async checkCompanyPermission(keys: string | string[], companyId: string) :Promise<any> {
    const filter = {
      _id: companyId
    };
    const project = {
      name: 1,
      permissions: 1
    };
    const populate = {
      permissions: "key value"
    };
    const response: any = await lastValueFrom(this.getCompany(filter, project));
    if (response.status === 200) {
      const permissions = response.data.permissions;
      if (Array.isArray(permissions) && permissions.length > 0) {
        if(typeof keys === 'string')
        return permissions.some(permission => permission.key === keys);
        if(Array.isArray(keys) && keys.length > 0) {
          return Promise.resolve(this.validateCompanyPermission(permissions, keys));
        }
      }
      
    }
    return Promise.resolve(false);
  }

  validateCompanyPermission(permissions: any[], keys: string[]) {
    let privileges = [];
    privileges = keys.map(key => permissions.some(permission => permission.key === key));
    return privileges;
  }
  getAllPOS(filter, projection) {
    return this.httpclient.post(global.url + API_URL.COMPANY.getAllPOS, {filter: filter, projection: projection}).pipe();
  }

  updatePOSRelation(companyId, cptId, posID, isCreating, isMultiplePos = false) {
    let filter: any, updateQuery: any;
    if (isMultiplePos) {     // when jsonData.posID is an array of ids
      filter = { company_id: companyId, cpt_id: cptId };
      updateQuery = { pos_ids: posID };
    }
    else if (isCreating) {
      filter = { company_id: companyId, cpt_id: cptId };
      updateQuery = { $addToSet: { pos_ids: posID }, };
    } else {
      if (posID.length == 0) {
        filter = { company_id: companyId, cpt_id: cptId };
        updateQuery = { $set: { pos_ids: posID } };
      } else {
        filter = { company_id: companyId, cpt_id: cptId };
        updateQuery = { $pull: { pos_ids: { $in: posID } } };

      }
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.updatePOSRelation, {filter: filter, updateQuery: updateQuery}).pipe();
  }

  updateDefaultPOS(defaultPOS, updateFilter) {
    return this.httpclient.post(global.url + API_URL.COMPANY.updateDefaultPOS, {defaultPOS: defaultPOS, filter: updateFilter}).pipe();
  }

  getIEFUCodesMapping(filter, projection = {}, populate = null){
    return this.httpclient.post(global.url + API_URL.COMPANY.getIEFUCodesMapping, {filter: filter, projection: projection, populate: populate}).pipe();
  }
  updateIEFUCodesMapping(filter, updateQuery){
    return this.httpclient.post(global.url + API_URL.COMPANY.updateIEFUCodesMapping, {filter: filter, updateQuery: updateQuery}).pipe();
  }
  getAllPermissions(filter, project) {
    return this.httpclient.post(global.url + API_URL.COMPANY.getAllPermissions, { filter, project });
  }
  modifyCompany(filter, updateQuery, options?) {
    return this.httpclient.post(global.url + API_URL.COMPANY.modifyCompany, { filter, updateQuery, options });
  }
  
  copyDataSetfromOtherCompany(dataObj){
    const data = {
      ...this.authService.authObject,
      ...dataObj
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.copyDataSetfromOtherCompany, {details: data}).pipe();
  }
  getDefaultTabOrders(company_id) {
    const data = {
      company_id
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.getDefaultTabOrders, { details: data }).pipe();
  }
  updateDefaultTabOrders(company_id, updatedOrder) {
    const data = {
      updatedOrder,
      company_id
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.updateDefaultTabOrders, { details: data }).pipe();
  }
}