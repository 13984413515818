import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { API_URL } from 'src/environments/api-url';
import * as global from '../../includes/global';
import { HttpService } from '../http.service';
import {IUser} from "../../classes/user.model";
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpService: HttpService,
    private _httpclient: HttpClient,
    private _authService: AuthService
  ) { }

  updateUser(datas = {}) {
    let details = {
      ...this._authService.authObject,
      ...datas
    }

    return this.httpService.post(global.url + API_URL.USER.updateUser, details);
  }
  
  updateUserPhone(datas = {}) {
    let details = {
      ...this._authService.authObject,
      ...datas
    }

    return this.httpService.post(global.url + API_URL.USER.updateUserPhone, details);
  }

  updateUIPreferences(ui_preferences: IUser['other_settings']['ui_preferences']) {
    const currentUser = {
      ...this._authService.currentUser,
    }
    currentUser.other_settings['ui_preferences'] = ui_preferences;
    this._authService.setCurrentUser(currentUser);

    return this.httpService.post(global.url + API_URL.USER.updateUIPreferences, { details: ui_preferences }).pipe();
  }
  
  getSignatureSetting(datas = {}) {
    let details = {
      ...this._authService.authObject,
      ...datas
    }

    return this.httpService.post(global.url + API_URL.USER.getSignatureSetting, details);
  }

  saveSignatureSettings(datas = {}) {
    let details = {
      ...this._authService.authObject,
      ...datas
    }


    return this.httpService.post(global.url + API_URL.USER.saveSignatureSettings, details);
  }
  saveNoteEmailSettings(notes_email_facilities) {
    let details = {
      ...this._authService.authObject,
      notes_email_facilities
    }


    return this.httpService.post(global.url + API_URL.USER.saveNoteEmailSettings, details);
  }
  getUser(filter, projection) {
    const details = {
      ...this._authService.authObject,
      filter,
      projection
    }
    return this._httpclient
      .post(global.url + API_URL.USER.getUser, { details }).pipe();
  }
  getUsers(filter, projection, populate?) {
    const details = {
      filter,
      projection,
      populate
    }
    return this._httpclient
      .post(global.url + API_URL.USER.getUsers, { details }).pipe();
  }
  getUsersWithBusinessHour(filter, projection, populate?) {
    const details = {
      ...this._authService.authObject,
      filter,
      projection,
      populate
    }
    return this._httpclient
      .post(global.url + API_URL.USER.getUsersWithBusinessHour, { details }).pipe();
  }
  getUsersByFacility(facilityId) {
    const details = {
      facilityId
      };
    return this._httpclient
      .post(global.url + API_URL.USER.getUsersByFacility, { details }).pipe();
  }
  verifyUserCompany(companyId) {
    const details = {
      ...this._authService.authObject,
      companyId
    }
    return this._httpclient
      .post(global.url + API_URL.USER.verifyUserCompany, { details }).pipe();
  }
  deleteUser(user_id) {
    const details = {
      ...this._authService.authObject,
      user_id
    }
    return this._httpclient
      .post(global.url + API_URL.USER.deleteUser, { details }).pipe();
  }
  setUserPermission(filter) {
    const details = {
      ...this._authService.authObject,
      filter,
    }
    return this._httpclient
      .post(global.url + API_URL.USER.setUserPermission, { details }).pipe();
  }

  getUserPermission( company_id ) {
    const details = { company_id };

    return this._httpclient
      .post( global.url + API_URL.USER.getUserPermission, { details }).pipe();
  }
  getNoteUploadingAndDestMethod(user_id) {
    const details = {
      ...this._authService.authObject,
      user_id
    }
    return this._httpclient
      .post(global.url + API_URL.USER.getNoteUploadingAndDestMethod, { details }).pipe();
  }
  updatePccNoteUploadingMethod(user_id, pcc_note_uploading_method_per_facility) {
    const details = {
      ...this._authService.authObject,
      user_id,
      pcc_note_uploading_method_per_facility,
    }
    return this._httpclient
      .post(global.url + API_URL.USER.updatePccNoteUploadingMethod, { details }).pipe();
  }
  updateMatrixNoteUploadingMethod(user_id, matrix_note_uploading_method_per_facility) {
    const details = {
      ...this._authService.authObject,
      user_id,
      matrix_note_uploading_method_per_facility,
    } 
    return this._httpclient
      .post(global.url + API_URL.USER.updateMatrixNoteUploadingMethod, { details }).pipe();
  }
  saveMatrixPDFDesination(user_id, facility_id, matrix_pdf_destination) {
    const details = {
      ...this._authService.authObject,
      user_id,
      facility_id,
      matrix_pdf_destination,
    }
    return this._httpclient
      .post(global.url + API_URL.USER.saveMatrixPDFDesination, { details }).pipe();
  }
  isOptionVisibleForCompany( option, companyId ) {
    const optionsToCheck = [ 'business_officer', 'cnp' ];
    if ( optionsToCheck.includes( option ) === false ) {
        return true;
    }
    if ( !companyId ) {
        return true;
    }
    return [ '5f69d0651b1eba8bee2d3ac4', '61fd23fe85650433b5ba4280' ].includes( companyId );
  }
}
