import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AppointmentDataService } from '../../appointment/service/data.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { ESignService } from '../e-sign.service';
import { AddPayorComponent } from '../../appointment';
import { AddSignatureComponent } from '../add-signature/add-signature/add-signature.component';

@Component({
  selector: 'app-e-sign',
  templateUrl: './e-sign.component.html',
  styleUrls: ['./e-sign.component.css']
})
export class ESignComponent implements OnInit {
  displayedColumns: string[] = [];
  currentUser: any;
  loading: Boolean = false;
  dataSource: any;
  companies: any;
  public addInsuranceCompanyDialogRef: any;
  public addEsignDialogRef: any;
  forms: any = ["IME-3", "IME-4"]
  signatures: any = []
  selectedProvider: any;
  selectedFormType: any;
  selectedProviderAdd: any;

  selectedFiles: FileList;
  progressInfos = []

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
  @ViewChild('file_uploader') file_uploader: ElementRef;
  constructor(
    public appointmentDataService: AppointmentDataService,
    public eSignService: ESignService,
    // private addPayorComponent: AddPayorComponent,
    public _authService: AuthService,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    this.displayedColumns = ['name', 'email', 'operations'];
  }

  ngOnInit() {
    this.initData();
  }
  applyFilter(filterValue: string) {
    if (filterValue) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
        return (data.abbreviation.toLowerCase().includes(filter) || data.title.toLowerCase().includes(filter) || data.email.toLowerCase().includes(filter));
      };
    }
    else {
      this.initData();
    }
  }
  onAddNewClick() {
    this.addEsignDialogRef = this.dialog.open(AddSignatureComponent, { width: '500px' });
    // console.log("this.addInsuranceCompanyDialogRef: ", this.addInsuranceCompanyDialogRef);
    this.addEsignDialogRef.afterClosed().subscribe((signature) => {
      if (signature) {
        this.initData();
      }
    });
  }
  onEditClick(payor) {
    //  this.addInsuranceCompanyDialogRef = this.dialog.open(AddPayorComponent,{data: payor, width: '500px'}); 
  }
  onDeleteClick(signature) { 
    if (!confirm('Are you sure you want to delete?')) {
      return;
    } 
    this.eSignService.deleteSignature(signature._id).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success("Signature has been deleted", 'success');
        this.initData();
      }
      else {
        this.toastr.error("Something went wrong", 'error');
      }
    })
  }
  upload(document) { 
    // const documentFile = document.files; 
    // const [documentFile] = document.files; 
    // const file = documentFile;
    // const form = this.selectedFormType;
    const provider = this.selectedProvider._id;
    // this.file_uploader.nativeElement.value = '';
    const files = Array.from(document.files).map(file => ({
      file,
      fileData: {
        type: 'Sign Form',
        sign: true
      }
    }))
    this.eSignService.uploadFiletoSign(provider, files).then((response: any) => {
      if (response.status == 200) {
        this.file_uploader.nativeElement.value = '';
        this.initData();
      }
    })
  }

  selectFiles(event) {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;   
  }
  fileName(){
    if(this.selectedFiles){
      if(this.selectedFiles.length == 1){
        return this.selectedFiles[0].name;
      }
      else if(this.selectedFiles.length > 1){
        return this.selectedFiles[0].name + ' +' + (Number(this.selectedFiles.length)-1) + ' more' ; 
      }
    }
  }

  initData() {
    this.loading = true;
    this.eSignService.getSignatures().subscribe((response: any) => {
      if (response.status == 200) { 
        this.signatures = [...response.data]; 
        // response.data.map(async provider => {
        //   await this.signatures.push(provider)
        // })
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      } 
      this.loading = false;
    }) 
  }

}
