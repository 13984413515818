import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import { IFacility } from '../shared/popup-dialogs/facility-add-dialog/facility-add.dialog';
import * as global from '../includes/global';
import { AuthService } from './auth.service';
import { API_URL } from "../../environments/api-url";
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { facilityPopulationInterface } from '../facilityPopulationInterface';

@Injectable({
    providedIn: 'root',
})
export class FacilityService {
    currentUser: any;
    private facilities: BehaviorSubject<any> = new BehaviorSubject(null);
    private assocFacilities: BehaviorSubject<any> = new BehaviorSubject(null);
    castFacilities = this.facilities.asObservable();
    castAssocFacilities = this.assocFacilities.asObservable();

    constructor(private httpclient: HttpClient,
        private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
    }

    getMyFacilities(doctor_id = '', provider_id?, show_all = false, company_id = null) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            doctor_id: doctor_id || this.currentUser._id,
            company_id: company_id ? company_id : this.currentUser.company_id
        };
        if (provider_id) {
            details.doctor_id = provider_id;
        }
        if(show_all){
            details['show_all'] = true;
        }
        return this.httpclient.post(global.url + API_URL.FACILITY.getMyAddress, { address: details }).pipe(tap((response: any) => {
            if (response.status === 200) {
                this.facilities.next(response.data.array)
            }
        }));

    }

    getCompanyFacilities(company_id, show_all=false) {
        const details = {
            company_id: company_id
        };
        if(show_all){
            details['show_all'] = true;
        }
        return this.httpclient.post(global.url + API_URL.FACILITY.getCompanyFacilities, { address: details }).pipe(tap((response: any) => {
            if (response.status === 200) {
                this.facilities.next(response.data.array)
            }
        }));
    }
    getAllFacilities(filter?, projection?, population?) {
        if(!filter.is_active) {
            filter.all = 'all';
        }
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            filter,
            projection,
            population: population
        };
        console.log('details in service', details);
        return this.httpclient.post(global.url + API_URL.FACILITY.getAllFacilities, { address: details });
    }


    getAllProviderAssociatedFacilities(doctor_id = '') {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            doctor_id
        };
        return this.httpclient.post(global.url + API_URL.FACILITY.getAllProviderAssociatedFacilities, { details }).pipe(tap((response: any) => {
            if (response.status === 200) {
                this.assocFacilities.next(response.data.array)
            }
        }));

    }
    getAllFacilitiesForMerging(filter?, projection?, population?) {
        if(!filter.is_active) {
            filter.all = 'all';
        }
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            filter,
            projection,
            population: population
        };
        console.log('details in service', details);
        return this.httpclient.post(global.url + API_URL.FACILITY.getAllFacilitiesForMerging, { address: details });
    }
    getFacilities(filter, projection, population?: facilityPopulationInterface[]) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            filter,
            projection,
            population
        };
        console.log('details in service', details);
        return this.httpclient.post(global.url + API_URL.FACILITY.getFacilities, { address: details });
    }
    getAllFacilitiesProviderTemplateAssoc(filter?, projection?) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            filter,
            projection
        };
        return this.httpclient.post(global.url + API_URL.FACILITY.getAllFacilitiesProviderTemplateAssoc, { address: details });
    }
    updateFacilityProviderTempalteAssoc(facility, providers = [], templates = [], template_id, provider_id) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            facility,
            providers,
            templates,
            template_id,
            provider_id
        };
        return this.httpclient.post(global.url + API_URL.FACILITY.updateFacilityProviderTempalteAssoc, { address: details });
    }

    addNewFacility(faculty: IFacility) {
        faculty.title = faculty.title.toUpperCase()
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            ...faculty
        };

        return this.httpclient.post(global.url + API_URL.FACILITY.addAddress, { address: details }).pipe();
    }

    updateFacility(faculty: IFacility) {
        faculty.title = faculty.title.toUpperCase()

        const { _id, ...facultyData } = faculty;

        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            address_id: _id,
            ...facultyData,
        };

        return this.httpclient.post(global.url + API_URL.FACILITY.updateAddress, { address: details }).pipe();
    }

    deleteFacility(faculty: IFacility) {
        const { _id } = faculty;

        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            address_id: _id,
        };

        return this.httpclient.post(global.url + API_URL.FACILITY.deleteAddress, { address: details }).pipe();
    }

    getFacility(facility_id) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            address_id: facility_id
        };

        return this.httpclient.post(global.url + API_URL.FACILITY.getAddress, { address: details }).pipe();
    }
    refreshDocumentCategories(facility_id) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            facility_id: facility_id
        };

        return this.httpclient.post(global.url + API_URL.FACILITY.refreshDocumentCategories, { details }).pipe();
    }

    updateFacilityProviders(facility_id, provider_ids, isRelationCreating) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            facility_id,
            provider_ids,
            isRelationCreating
        };
        return this.httpclient.post(global.url + API_URL.FACILITY.updateFacilityProviders, { details }).pipe();
    }

    updateFacilityTemplates(facility_id, template_ids, isRelationCreating) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            facility_id,
            template_ids,
            isRelationCreating
        };
        return this.httpclient.post(global.url + API_URL.FACILITY.updateFacilityTemplates, { details }).pipe();
    }
    updtaeFacilityNurseAssociation(facility_id, nurse_ids) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            facility_id,
            nurse_ids
        };
        return this.httpclient.post(global.url + API_URL.FACILITY.updtaeFacilityNurseAssociation, { details }).pipe();
    }

    updateFacilityCompany(facility_id, company_ids, company?, isCreatingRelation?, dateObj?) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            facility_id,
            company_ids,
            company,
            isCreatingRelation,
            dateObj
        };
        return this.httpclient.post(global.url + API_URL.FACILITY.updateFacilityCompany, { details }).pipe();
    }

    getFilteredFacilities(company_id, orgUuid?) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            company_id,
            orgUuid
        };
        return this.httpclient.post(global.url + API_URL.FACILITY.getFilteredFacilities, { details }).pipe();
    }

}
