import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse,} from '@angular/common/http';
import {Subscription, Observable, Subject} from 'rxjs';
import * as global from '../global';
import {API_URL} from "../../../environments/api-url";
import { Console } from 'console';
import { AuthService } from 'src/app/services/auth.service';


@Injectable({
    providedIn: 'root',
})
export class LoginService {
    user$: Observable<any>;
    key :string = '!1@4#7$2%5^8&0*3(6)9';
    platform : string = 'WEB';
    private currentUser = new Subject<any>();
    public constructor(private httpclient: HttpClient, private authService: AuthService) {
        this.user$ = this.currentUser.asObservable();
    }

    getLoginInfo(email: string, password: string) {
        const item = {
            email: <string> null,
            password: <string> null,
            logintype: <string> null,
            key: <string> null,
            platform: <string> null
        };
        item.email = email;
        item.password = password;
        item.key = this.key;
        item.platform=this.platform;
        return this.httpclient
            .post(global.url + API_URL.AUTH.login, {details: item}).pipe();
    }
    loginWithCognito(email: string, password: string, timezone = null, browserFingerprint = null, loginURL = null, auth = "cognito") {
        const details = {
            email,
            password,
            platform: 'WEB',
            timezone: timezone,
            browserFingerprint: browserFingerprint,
            loginURL: loginURL,
            auth: auth
        }
        return this.httpclient
            .post(global.url + API_URL.AUTH.loginWithCognito, {details}, {withCredentials: true}).pipe();
    }
    getAuthenticationDetails(email: string){
        const details = { email };
        return this.httpclient.post(global.url + API_URL.AUTH.getAuthenticationDetails, {details}, {withCredentials: true}).pipe();
    }
    getEncryptPassword(password) {
        return this.httpclient
            .post(global.url + API_URL.AUTH.webEncryptPass, {details: {password: password, key: this.key}}).pipe();
    }
    send2FactorVerificationCode(user, auth_key = user.auth_key){
        
        if(!auth_key.includes('.WEB')) {
            auth_key = `${auth_key}.WEB`;
        }
        const item = {
            email: <string> null,
            usertype: <string> null,
            auth_key: <string> null,
            id:<string> null,
            mobile:<string> null
        };
        item.email = user.email;
        item.auth_key = auth_key;
        item.usertype = user.user_type;
        item.id = user._id;
        item.mobile = user.mobile;
        return this.httpclient
            .post(global.url + API_URL.AUTH.send2FactorVerificationCode, {details: item}).pipe();
    }
    check2FactorVerificationCode(user,code, email, mfaSession, remember_device_check?, authMethod = "cognito"){
        const item = {
            email: <string> null,
            usertype: <string> null,
            auth_key: <string> null,
            id:<string> null,
            code:<string> null,
            mfaSession:<string> null,
            // two_factor_auth_method:<string> null,
            platform:<string> 'WEB',
            remember_device_check,
            authMethod
        };
        if(user) {
            item.email = user.email;
            item.auth_key = user.auth_key;
            item.usertype = user.user_type;
            item.id = user._id;
        }
        if(email) {
            item.email=email;
        }
        item.code = code;
        // item.two_factor_auth_method = user.two_factor_auth_method;
        item.mfaSession = mfaSession;
        item.remember_device_check = remember_device_check;
        console.log(item.mfaSession)
        return this.httpclient
            .post(global.url + API_URL.AUTH.check2FactorVerificationCode, {details: item}, {withCredentials: true}).pipe();
    }
    
    getCompanyLogo(code = null ,company_id = null){
        const details : any = {
           
        };
        if(code){
            details.code = code;
        }
        if(company_id){
            details.company_id = company_id;
        }
        return this.httpclient
            .post(global.url + API_URL.AUTH.getCompanyLogo, {details}).pipe();
    }

    sendContactUsEmail(filter){

        return this.httpclient
            .post(global.url + API_URL.AUTH.sendContactUsEmail, filter).pipe();
    }

    
    validateLogin(code = null ,company_id = null){
        const details : any = {
           ...this.authService.authObject
        };
        return this.httpclient
            .post(global.url + API_URL.AUTH.validateLogin, {details}).pipe();
    }

    resendAuthCode(email){
        return this.httpclient
        .post(global.url + API_URL.AUTH.resendAuthCode, {email}, {withCredentials: true}).pipe();
    }
}
