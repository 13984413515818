import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { ReportGenerateService } from '../report-generate.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { API_URL } from 'src/environments/api-url';
import * as global from '../../../includes/global';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'; 
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";
// import {AngularEditorConfig} from '@kolkov/angular-editor';
// import Font from '@ckeditor/ckeditor5-font/src/font';

@Component({
    selector: 'app-report-view',
    templateUrl: './report-view.component.html',
    styleUrls: ['./report-view.component.css']
})

export class ReportViewComponent implements OnInit {
    groupedData: any;
    @Input() report;
    @Input() savedReport;
    @Output() onClickSaveSummary: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("editor") editor: ElementRef;
    claiment_name: any;
    claiment_doi: any;
    claiment_dob: any;
    claiment_id: any;
    document_id: any;
    insurance_company: any = '';
    //for CMC
    claim_type: any;
    carrier: any;
    client_file: any;
    UniMex_file: any;
    claiment_doa: any;
    employer: any;
    wcb_file: any;
    summaryData: any;
    Editor = ClassicEditor;
    config = {
        placeholder: 'Type the content here!'  ,       
        toolbar: [ 'heading', '|', 'bold', 'italic' ,'|','NumberedList', 'BulletedList','fontSize','blockQuote',
        'undo','redo'] ,
    };

    // config: AngularEditorConfig = {
    //     editable: false,
    //     spellcheck: true,
    //     height: '100%',
    //     placeholder: 'Enter text here...',
    //     translate: 'no',
    //     customClasses: [
    //         {
    //             name: 'quote',
    //             class: 'quote',
    //         },
    //         {
    //             name: 'redText',
    //             class: 'redText'
    //         },
    //         {
    //             name: 'titleText',
    //             class: 'titleText',
    //             tag: 'h1',
    //         },
    //     ]
    // };

    // @ViewChild('editor') editorComponent: CKEditorComponent;

    constructor(
        private reportGenerateService: ReportGenerateService,
        private dashboardService: DashboardService,
        private http: HttpClient,
        private toastr: ToastrService,
        private httpclient: HttpClient,
    ) { }


    async ngOnInit() { 
        if (this.savedReport) {  
            this.summaryData = this.savedReport.summary;
            this.document_id = this.savedReport._id;
            // this.document_id = this.savedReport.document_id;
        }
        else if (this.report) { 
            this.document_id = this.report.cover._id;
            this.claiment_doi = new Date(this.report.cover.claiment_doi).toLocaleDateString();
            this.claiment_dob = new Date(this.report.cover.claiment_dob).toLocaleDateString();
            this.claiment_doa = new Date(this.report.cover.claiment_doa).toLocaleDateString();
            this.claiment_name = this.report.cover.claiment_name;
            this.claiment_id = this.report.cover.claiment_id;
            this.claim_type = this.report.cover.claim_type;
            this.carrier = this.report.cover.carrier;
            this.client_file = this.report.cover.client_file;
            this.UniMex_file = this.report.cover.UniMex_file;
            this.employer = this.report.cover.employer;
            this.wcb_file = this.report.cover.wcb_file;


            // console.log("group.key: ", group.key);

            // this.insurance_company = this.report.cover.insurance_comapny;
            // this is for testing phase as we have uploaded documents that dont have insurance copmanies 
            // uncomment the 1st line and comment 1st if-else block of this function
            if (this.report.cover.insurance_comapny) {
                this.insurance_company = this.report.cover.insurance_comapny.trim();
            }
            else {
                this.insurance_company = "demarco";
            }
            if (this.report.cover.insurance_comapny === 'demarco' || !this.report.cover.insurance_comapny) {
                
                this.groupedData = await this.reportGenerateService.summaryReport(this.report.array);
                this.summaryData = await this.reportGenerateService.generateSummaryDemarcoFormat(this.groupedData);
            } else { 
                this.groupedData = await this.reportGenerateService.summaryReportCMC(this.report.array);
                this.summaryData = await this.reportGenerateService.generateSummaryCMCFormat(this.groupedData, this.report.cover);
                if (this.summaryData) {
                    // this.editor.getData()
                    this.saveSummary(this.summaryData, this.document_id);
                    //     console.log("calling save summary:");

                    this.onClickSaveSummary.emit(this.summaryData);
                }
            }
        }
    }

    summaryDataChanged(changedSummary) { 
        this.summaryData = changedSummary;
        this.saveSummary(changedSummary, this.document_id)

    }
    saveSummary(summary, document_id) { 
        let saveReport = this.reportGenerateService.saveOrUpadateSummary(summary, document_id).subscribe((response: any) => {
            if (response.status = 200) {  
                // this.summaryData = response.data.summary;
                return;
            }
        });
    }

    isEmpty(impression) {
        if (typeof impression === 'string') {
            return impression.trim() === '-' || impression.trim() === '';
        }
        return false;
    }

    // ali
    // async __downloadReport(){
    //     const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    //     try {
    //         const params = { 
    //             document_id : this.document_id
    //         }
    //         this.reportGenerateService.generatePDFAndDownload(params).subscribe((res: any) => {
    //             if (res instanceof HttpResponse) {
    //                 var url = window.URL.createObjectURL(res.body);
    //                 var a = document.createElement('a');
    //                 document.body.appendChild(a);
    //                 a.setAttribute('style', 'display: none');
    //                 a.href = url;
    //                 a.download = 'summary.pdf';
    //                 a.click();
    //                 window.URL.revokeObjectURL(url);
    //                 a.remove(); // remove the element
    //             } else {
    //                 if (res.type === 0) {
    //                 } else if (res.type === 3) {
    //                     const { loaded, total } = res;
    //                     const progress = Math.round(loaded / total * 100);
    //                     if (progress > 0) {
    //                     }
    //                 }
    //             }
    //         })
    //         this.toastr.clear(infoToastr.toastId);
    //     } catch (error) {
    //         const { message = 'Something went wrong, please try again' } = error;
    //         this.toastr.error(message);
    //     } finally {
    //         this.toastr.clear(infoToastr.toastId);
    //     }
    // }

    // ali orignal
    // async _downloadReport() {
    //     if (this.insurance_company) {
    //         this.insurance_company = this.insurance_company.trim();
    //     }
    //     if (this.insurance_company === 'demarco' || !this.insurance_company) {
    //         const coverData = {
    //             insurance_comapny: this.insurance_company,
    //             claiment_name: this.claiment_name,
    //             claiment_id: this.claiment_id,
    //             claiment_doi: this.claiment_doi,
    //             claiment_dob: this.claiment_dob,
    //         }
    //         const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    //         try {
    //             const params = {
    //                 coverData,
    //                 groupData: this.groupedData
    //             }
    //             this.reportGenerateService.generatePDFAndDownload(params).subscribe((res: any) => {
    //                 if (res instanceof HttpResponse) {
    //                     var url = window.URL.createObjectURL(res.body);
    //                     var a = document.createElement('a');
    //                     document.body.appendChild(a);
    //                     a.setAttribute('style', 'display: none');
    //                     a.href = url;
    //                     a.download = 'summary.pdf';
    //                     a.click();
    //                     window.URL.revokeObjectURL(url);
    //                     a.remove(); // remove the element
    //                 } else {
    //                     if (res.type === 0) {
    //                     } else if (res.type === 3) {
    //                         const { loaded, total } = res;
    //                         const progress = Math.round(loaded / total * 100);
    //                         if (progress > 0) {
    //                         }
    //                     }
    //                 }
    //             })
    //             this.toastr.clear(infoToastr.toastId);
    //         } catch (error) {
    //             const { message = 'Something went wrong, please try again' } = error;
    //             this.toastr.error(message);
    //         } finally {
    //             this.toastr.clear(infoToastr.toastId);
    //         }
    //     }
    //     //for cmc
    //     else {
    //         const coverData = {
    //             insurance_comapny: this.insurance_company,
    //             claim_type: this.claim_type,
    //             carrier: this.carrier,
    //             claiment_name: this.claiment_name,
    //             claiment_dob: this.claiment_dob,
    //             client_file: this.client_file,
    //             UniMex_file: this.UniMex_file,
    //             claiment_doa: this.claiment_doa,
    //             employer: this.employer,
    //             wcb_file: this.wcb_file,
    //         }
    //         const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    //         try {
    //             const params = {
    //                 coverData,
    //                 groupData: this.groupedData
    //             }
    //             this.reportGenerateService.generatePDFAndDownload(params).subscribe((res: any) => {
    //                 if (res instanceof HttpResponse) {
    //                     var url = window.URL.createObjectURL(res.body);
    //                     var a = document.createElement('a');
    //                     document.body.appendChild(a);
    //                     a.setAttribute('style', 'display: none');
    //                     a.href = url;
    //                     a.download = 'summary.pdf';
    //                     a.click();
    //                     window.URL.revokeObjectURL(url);
    //                     a.remove(); // remove the element
    //                 } else {
    //                     if (res.type === 0) {
    //                     } else if (res.type === 3) {
    //                         const { loaded, total } = res;
    //                         const progress = Math.round(loaded / total * 100);
    //                         if (progress > 0) {

    //                         }
    //                     }
    //                 }
    //             })
    //             this.toastr.clear(infoToastr.toastId);
    //         } catch (error) {
    //             const { message = 'Something went wrong, please try again' } = error;
    //             this.toastr.error(message);
    //         } finally {
    //             this.toastr.clear(infoToastr.toastId);
    //         }
    //     }
    // }

    changeDate(date) {
        if (date) {
            let d = new Date(date).toLocaleDateString();
            return d;
        }
    }

    value(row) {
        let groupName;
        let dateFrom = "";
        let dateTo = "";
        let result = "";
        if (row.note_type) {
            groupName = row.note_type.toLowerCase().trim();
        }

        if (row.date) {
            let allDates = row.date.split(", ");
            let dates = [];
            // allDates.forEach(date => {
            //     if (allDates.indexOf(date) !== -1) {
            //         dates.push(date)
            //     }
            // });
            if (allDates.length > 1) {
                for (let i = 1; i <= allDates.length; i++) {
                    if (allDates[i - 1] != allDates[i]) {
                        dates.push(allDates[i - 1])
                    }
                }
            }
            else {
                dates = allDates;
            }
            dateFrom = dates[0];
            if (dates.length > 1) {
                dateTo = dates[dates.length - 1];
            }
        }
        if (groupName) {
            if (groupName.indexOf("form") !== -1) {
                result = row.note_type;
                if (row.page_no.length > 1) {
                    result = result + "s";
                }
                return result;
            }
        }
        if (row.exam) {
            result = row.note_type + " " + row.exam;
        }
        else {
            result = row.note_type;
        }
        if (groupName) {
            if (groupName.indexOf("mri") !== -1 || groupName.indexOf("x-ray") !== -1 || groupName.indexOf("ct-scan") !== -1) {
                // result = row.note_type; 
                if (row.provider) {
                    return result + " dated " + dateFrom + " by " + row.provider
                }
                else {
                    return result + " dated " + dateFrom
                }
            }
        }

        if (dateFrom && dateTo) {
            result = result + " dated " + dateFrom + " through " + dateTo;
        }
        else if (dateFrom && !dateTo) {
            result = result + " dated " + dateFrom;
        }
        else if (!dateFrom && !dateTo) {
            result = result;
        }

        if (row.provider) {
            result = result + " by " + row.provider;
        }
        return result;

    }

}
