<h3 style="font-weight: bold;text-align: center;">Audit History</h3>
 <span *ngIf="auditData">
    <div class="audit-data" *ngIf="!loading && auditData"> 
        <div class="flex gap-2" >
            <mat-list class="flex-grow audit-column">
                <ng-container *ngFor="let audit of operations">
                    <mat-list-item class="auditory-history-item" >
                        <div  mat-line >
                            <div class="flex justify-start items-center gap-2">
                                <p class="flex justify-start items-center">
                                    <ng-container>
                                        {{getAuditData(audit)}}
                                    </ng-container>
                                </p> 
                                <p class="auditory-history-date">
                                    {{getAuditDate(audit)}}
                                </p>
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container> 
            </mat-list>
        </div>  
    </div>
</span>
<span *ngIf="!hasData">
    <div style="width: 100%; max-height: 30% ; background: white; overflow: auto;">
    No Data to show
    </div> 
</span>