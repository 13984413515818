<div class="flex notifications-wrapper">
    <button mat-icon-button  class="notifications-bell" 
    [matMenuTriggerFor]="notificationMenu"
    (menuOpened)="menuOpened()"
    (menuClosed)="menuClosed()"
    >
        <mat-icon [class.active]="this.isOpened">notifications</mat-icon>
        <span class="badge" *ngIf="unseenNotificationsCount > 0">{{unseenNotificationsCount <= 99 ? unseenNotificationsCount : '99+'}}</span>
    </button>
    <mat-menu #notificationMenu="matMenu" class="notifications-panel">
      
      <div class="flex justify-center" *ngIf="notificationsLoaded">
        <mat-progress-spinner diameter="45" mode="indeterminate">
        </mat-progress-spinner>
      </div>

      <div (click)="$event.stopPropagation();">
        <div class="notifications-list"  *ngIf="notifications.length > 0" style="height: 5rem;">
          <div class="notifcation-item" *ngFor="let notification of notifications">
            <div class="notifcation-msg" (click)="openURL(notification)">
              <div><span *ngIf="notification.createdById">{{notification.createdById['first_name']}} {{notification.createdById['last_name']}}</span></div>
              <div>{{notification.recipient_type == 'biller_addendum_req' ? "Charge status changed to Addendum Req. Click here to View the charge(s)" : 
                notification.type == "info" ? notification.description : "Click here to view the notification"}}</div>
            </div>
            <div class="notifcation-date">{{ notification.createdAt | date: 'MM/dd/yyyy' }}</div>
          </div>
        </div>

        <div class="notification-bottom">
          <ng-container  *ngIf="notifications.length > 0">
            <a [routerLink]="['/notifications']">  See all notifications </a>
          </ng-container>
        </div>

      </div>
    </mat-menu>
</div>