import {Component, OnInit} from '@angular/core';
import {Payor} from '../../../../classes/payor';
import {AppointmentDataService} from '../../service/data.service';
import {ToastrService} from 'ngx-toastr';
import {Appointment} from '../../../../classes/appointment';
import {FacilityService} from '../../../../services/facility.service';
import {PatientListService} from '../../../../includes/patient-list/patient-list-service';
import {AuthService} from '../../../../services/auth.service';
import moment from 'moment'
import {MatDialogRef} from "@angular/material/dialog";
import {NotifyType} from "../../../../classes/notify-type";

@Component({
    selector: 'app-add-appointment',
    templateUrl: './add-appointment.template.html',
    styleUrls: ['./style.css']
})
export class AddAppointmentComponent implements OnInit {
    model = new Appointment();
    facilities = [];
    patients = [];
    payors = [];
    provider = undefined;

    constructor(public dialogRef: MatDialogRef<AddAppointmentComponent>,
                public service: AppointmentDataService,
                public facilityService: FacilityService,
                public patientService: PatientListService,
                public authService: AuthService,
                private toastr: ToastrService) {
    }

    async addAppointment(appointment) {
        //
        await this.processNotifyType(appointment);
        await this.processDateTime(appointment);
        this.dialogRef.close();
        this.model.provider_id = this.authService.authObject.id;
        this.service.addAppointment(this.model).subscribe(data => {
                
                this.toastr.success('Appointment added successfully', 'Success');
                this.service.updateData = true;
            }, error => {
                
                this.toastr.error('something went wrong', 'Error');
            });
    }

    async processNotifyType(appointment) {
        //
        let notify_obj = new NotifyType();
        for (let i = 0; i < appointment.notify_type.length; i++) {
            notify_obj[appointment.notify_type[i]] = true;
        }
        this.model.notify_type = notify_obj;
    }

    async processDateTime(appointment) {
        
        let time = appointment.tos.split(' ');
        const meredium = time[1];
        time = time[0].split(':');
        if (meredium === 'pm') {
            // tslint:disable-next-line:radix
            time[0] = parseInt(time[0]) + 12;
            // tslint:disable-next-line:radix
            time[1] = parseInt(time[1]);
        }
        // tslint:disable-next-line:radix
        time[0] = parseInt(time[0]);
        // tslint:disable-next-line:radix
        time[1] = parseInt(time[1]);
        const dos = new Date(appointment.dos);
        dos.setHours(time[0]);
        dos.setMinutes(time[1]);
        this.model.dos = dos;
        this.model.tos = new Date();
        this.model.tos.setHours(time[0]);
        this.model.tos.setMinutes(time[1]);
    }

    ngOnInit() {
        this.provider = this.authService.currentUser.first_name + ' ' +
            this.authService.currentUser.last_name + ', ' + this.authService.currentUser.title;
        this.loadData();
    }

    loadData() {
        
        this.service.getPayors().subscribe(data => {
            this.payors = data.data.array;
        }, error => {
            this.toastr.error('something went wrong', 'Error');
        });
        this.facilityService.getMyFacilities().subscribe(data => {
            
            // @ts-ignore
            this.facilities = data.data.array;
        }, error => {
            this.toastr.error('something went wrong', 'Error');
        });
        this.patientService.getPatients(this.authService.authObject).subscribe(data => {
            
            // @ts-ignore
            this.patients = data.data.array;
        }, error => {
            this.toastr.error('something went wrong', 'Error');
        });
    }
}
