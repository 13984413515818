import { Component, Inject, OnInit } from '@angular/core';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { getMaxListeners } from 'process';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';

@Component({
  selector: 'app-export-notes-dialog',
  templateUrl: './export-notes-dialog.component.html',
  styleUrls: ['./export-notes-dialog.component.css']
})
export class ExportNotesDialogComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  emails:any = []
  currentUser: any;
  isInputFocused: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA)public data: any,
    public dialogRef: MatDialogRef<ExportNotesDialogComponent>,
    private _dashboardService: DashboardService,
    private companyService: CompanyService,
    private _authService: AuthService
  ) { 
    this.currentUser = this._authService.currentUser;
  }

  ngOnInit() {
    console.log("this.data in ExportNotesDialog : ", this.data);
    if(this.data?.facility_id){
      this.getFacilityContactEmails();
    }else{
      this.getEmails();
    }
  }

  // addEmail(value){
  //   console.log("mail: ", value);
    
  // }

  getEmails() {
    this._dashboardService.getEmails(this.currentUser._id).subscribe((res:any)=>{
      if(res.status == 200){
        this.emails = res.data.email_ids;
      }
    });
  }
  toggleFocus(focused: boolean) {
    this.isInputFocused = focused;
  }

  async getFacilityContactEmails() {
    await this.companyService.getFacilityContacts({facility: this.data.facility_id})
    .subscribe( (res:any) => {
      const contacts=res.data.array;
      let emailContacts=[];
      if(contacts){
        emailContacts=contacts.map((e)=>{return e.email;})
        this.emails=emailContacts;
      }
      console.log("emailContacts : ", emailContacts);
    });
  }

  addEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.emails.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  removeMail(email): void { 
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }
  sendEmail(){
    this.dialogRef.close({emails:this.emails});
    // return this.emails;
  }
}
