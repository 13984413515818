<div *ngFor="let page of pages" class="flex flex-col" class="div-style"
  style="font-size: 11px !important; height: -webkit-fill-available !important; width: 100%; overflow: auto;">

<div style="background-color: #f3f3f3;" *ngIf="carry" >
  <div class="flex justify-start items-baseline flex gap-2"
    style="display: flex;width: 100%;margin-bottom: 5px; height: 40px;">
    <mat-form-field class="flex justify-start items-baseline" style="display: flex;width: 10%; " appearance="fill">
      <mat-label>Date</mat-label>
      <input style="display: inline-block;width:auto;" matInput type="text" [(ngModel)]="carriedDate"   />
    </mat-form-field>

    <mat-form-field class="flex justify-start items-baseline" style="display: flex;width: 30%; " appearance="fill"> 
      <mat-label>Note Type</mat-label>
      <input style="display: inline-block;width:auto;" matInput type="text" [(ngModel)]="carriedNoteType"  />
    </mat-form-field>

    <mat-form-field class="flex justify-start items-baseline" style="display: flex;width: 60%; " appearance="fill"> 
      <mat-label>Exam</mat-label>
      <input style="display: inline-block;width:auto;" matInput type="text" [(ngModel)]="carriedExam"  />
    </mat-form-field>
  </div>
  
  <div *ngIf="isCarriedImpression" class="flex justify-start items-baseline flex gap-2" 
    style="display: flex;width: 100%; height: 40px;">
    <mat-form-field class="flex justify-start items-baseline" style="display: flex;width: 100%; " appearance="fill">
      <mat-label>Exam</mat-label>
      <input style="display: inline-block;width:auto;" matInput type="text" [(ngModel)]="carriedImpression"   />
    </mat-form-field> 
  </div>
</div>

  <div
    style="display: flex; width: 100%; margin-bottom: 0px;margin-top: 10px;  place-content: flex-start space-between;">
    <div class="flex justify-start items-baseline" style="display: flex;width: 50%; ">
      <mat-form-field class="flex justify-start items-baseline" *ngIf="addNewDate" style="width: 100%;">
        <mat-label>Select Date</mat-label>
        <mat-select aria-required="true" [(ngModel)]="pageItem.selected.date">
          <mat-option disabled (click)="onSelectDate('Add New', '')" value="">Add New</mat-option>
          <mat-option *ngFor="let e of pageItem.date" [value]="e" (click)="onSelectDate(e, pageItem)">
            {{e | date: 'MM/dd/yyyy'}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="flex flex-wrap gap-2 items-start justify-start" *ngIf="!addNewDate " style="width: 100%;">
        <mat-form-field style="width: 100%;">
          <mat-label>Select Date</mat-label>
          <input matInput [matDatepicker]="pick" name="selected.date" (dateChange)="addEvent(pageItem , $event)"
            [(ngModel)]="pageItem.selected.date" />
          <mat-datepicker-toggle matSuffix [for]="pick"></mat-datepicker-toggle>
          <mat-datepicker #pick></mat-datepicker>
        </mat-form-field>
        <a href="#" (click)="$event.preventDefault();addNewDate=true;pageItem.selected.date=''">X</a>
      </div>
    </div>

    <button style="margin-bottom: 3px; background: none;text-decoration: underline; color: black;" mat-raised-button
      class="dn-button-primary" (click)="carryOver(pageItem)">Carry Over</button>
    <button *ngIf="carry" style="margin-bottom: 3px; background: none;text-decoration: underline; color: black;" mat-raised-button
      class="dn-button-primary" (click)="pasteCarryOver(pageItem)">Paste</button>
    <i class=" icon icon-addNewPage" matTooltip="Add New Page" matTooltipPosition="above"
      (click)="addNewPage(pageItem)"></i>
  </div>
 

  <div class="flex justify-start items-baseline gap-3"
    style="display: flex;width: 100%;margin-bottom: 10px;margin-top: 8px;">
    <mat-form-field style="width: 50%; " *ngIf="!isTherapy()">
      <input type="text" placeholder="Provider" aria-label="Number" matInput [(ngModel)]="pageItem.selected.provider"
        [formControl]="providerControl" [value]="pageItem.selected.provider" [matAutocomplete]="providerAutoComplete">
      <mat-autocomplete #providerAutoComplete="matAutocomplete" autoActiveFirstOption>
        <mat-option *ngFor="let e of filteredProvider | async" [value]="e" (click)="onSelectProvider(e, pageItem);">
          {{e}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field style="width: 50%; ">
      <input type="text" placeholder="Note Type" aria-label="note_type" matInput
        [(ngModel)]="pageItem.selected.note_type" [formControl]="noteTypeControl" [value]="pageItem.selected.note_type"
        [matAutocomplete]="noteTypeAutoControl">
      <mat-autocomplete #noteTypeAutoControl="matAutocomplete" autoActiveFirstOption>
        <mat-option *ngFor="let e of filteredNoteType | async" [value]="e" (click)="onSelectNote(e, pageItem);">
          {{e}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>


  <div class="flex justify-start items-baseline ap-10" style="display: flex;width: 100%;margin-bottom: 12px;margin-top: 8px;"
> 

    <div class="flex justify-start items-baseline" style="display: flex;width: 50%; ">
      <div class="flex justify-start items-baseline" style="margin-right: 10px;">
        <mat-checkbox style="align-items: flex-end;" (change)="changeCheckBox(pageItem, $event);"
          [checked]="pageItem.is_checked">
        </mat-checkbox>
      </div>
      <mat-label>Include in Report Generation</mat-label>
    </div>

    <mat-form-field *ngIf="isNoteTypeImaging(pageItem.selected.note_type)" style="width: 50%; ">
      <input type="text" placeholder="Exam" aria-label="exam" matInput [(ngModel)]="pageItem.selected.exam"
        [formControl]="examControl" [value]="pageItem.selected.exam" [matAutocomplete]="examAutoComplete">
      <mat-autocomplete #examAutoComplete="matAutocomplete" autoActiveFirstOption>
        <mat-option *ngFor="let e of filteredExam | async" [value]="e=='Add New'? '':e"
          (click)="onSelectExam(e, pageItem);">
          {{e}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div *ngIf="isNoteTypeforImpression(pageItem.selected.note_type)" style="margin-bottom: 3px;margin-top: 8px;">
    <mat-form-field class="textArea" style="margin-bottom: 0px !important;">
      <mat-label>Impression</mat-label>
      <textarea rows="3" matInput type="text" [(ngModel)]="pageItem.impression" name="impression"></textarea>
    </mat-form-field>
  </div>
  <div *ngIf="isNoteTypeImaging(pageItem.selected.note_type)" style="margin-bottom: 8px;margin-top: 6px;display: contents;">
    <mat-form-field class="textArea" style="margin-bottom: 0px !important;">
      <mat-label>Findings</mat-label>
      <textarea rows="1" matInput type="text" (change)="pageItem.findings = $event.target.value"
        [value]="pageItem.findings" name="findings"></textarea>
    </mat-form-field>
  </div>
  <div 
    style="margin-bottom: 8px;margin-top: 8px; display: flex; flex-direction: column-reverse; overflow: auto;height: 65px;">
 
    <div style="overflow-y: auto; max-height: -webkit-fill-available;">
      <ckeditor name="summary_text" #editor
        style="line-height: 10px;line-height: 20px; font-family: serif;font-size: 13px;" [ngModel]="savedReport.summary"
        (ngModelChange)="summaryDataChanged($event)" [config]="config" [editor]="Editor">

      </ckeditor>
    </div>
  </div>

</div>