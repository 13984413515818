import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FacilityService } from 'src/app/services/facility.service';
import { PatientRoundingSheetService } from '../patient-rounding-sheet.service';
import * as global from '../../global'

import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { PatientListService } from '../../patient-list/patient-list-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export interface MultiplePatientsAddDialogData {
  patientRoundingSheet: any
}


@Component({
  selector: 'app-multiple-patients-add-dialog',
  templateUrl: './multiple-patients-add-dialog.html',
  styleUrls: ['./multiple-patients-add-dialog.css']
})
export class MultiplePatientsAddDialog
  implements OnInit {
  global = global;
  currentUser: any;
  currentUserDetails;

  patientRoundingSheet: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  patientCtrl = new FormControl();
  filteredPatients: Observable<any[]>;
  patients = [];
  allPatients = [];

  loaderId = 'multiple-patients-add-loader';
  
  constructor(
    public dialogRef: MatDialogRef<MultiplePatientsAddDialog>,
    @Inject(MAT_DIALOG_DATA) public data: MultiplePatientsAddDialogData,
    private _authService: AuthService,
    private _patientRoundingSheetService: PatientRoundingSheetService,
    private toastr: ToastrService,
    private patientsService: PatientListService,
    private loader: NgxUiLoaderService

  ) {
    this.currentUser = this._authService.currentUser;
    this.currentUserDetails = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }
    if (data) {
      this.patientRoundingSheet = data.patientRoundingSheet;
    }

  }

  ngOnInit() {
    this.initPatients();
    this.filteredPatients = this.patientCtrl.valueChanges.pipe(
      startWith(''),
      map((filterValue: string | null) => filterValue ? this._filterPatient(filterValue) : this.allPatients.slice()));
  }

  handlePatientInputFocus(event) {
    this.patientCtrl.setValue(event.target.value);

  }

  addPatientAsChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value: any = event.value;

    this.patients.push(value);
    
    const details = {
      ...this._authService.authObject,
      patientRoundingSheetId:this.patientRoundingSheet._id,
      patientId: value._id
    }
    this._patientRoundingSheetService.addPatientToPatientRoundingSheet(details).subscribe((response:any) => {
      console.log(response);
    })

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.patientCtrl.setValue(null);
  }

  removePatientAsChip(patient: any): void {
    const index = this.patients.indexOf(patient);

    if (index >= 0) {
      this.patients.splice(index, 1);
      const details = {
        ...this._authService.authObject,
        patientRoundingSheetId:this.patientRoundingSheet._id,
        patientId: patient._id
      }
      this._patientRoundingSheetService.removePatientFromPatientRoundingSheet(details).subscribe((response:any) => {
        console.log(response);
        
      })
    }
  }

  selectedPatientAsChip(event: MatAutocompleteSelectedEvent): void {
    this.patients.push(event.option.value);
    
    const details = {
      ...this._authService.authObject,
      patientRoundingSheetId:this.patientRoundingSheet._id,
      patientId: event.option.value._id
    }
    this._patientRoundingSheetService.addPatientToPatientRoundingSheet(details).subscribe((response:any) => {
      console.log(response);
    })

    this.patientCtrl.setValue(null);
  }

  private _filterPatient(value: any): any[] {
 
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value;

    return this.allPatients.filter((patient: any) => patient.name.toLowerCase().indexOf(filterValue) === 0);

  }

  async initPatients() {
    this.loader.startLoader(this.loaderId);

    const patientsResponse: any = await this.patientsService.getPatients(this._authService.authObject).toPromise();

    if (patientsResponse.status === 200) {
      this.allPatients = patientsResponse.data.array;
      
      this.initPatientList();

      this.loader.stopLoader(this.loaderId);
    }
  }

  initPatientList() {
    const patientIds = this.patientRoundingSheet.patientList.map(p => p._id);
    console.log(this.patientRoundingSheet.patientList);
    
    this.patients = this.allPatients.filter(p => patientIds.indexOf(p._id) >= 0)
  }

}
