<div class="flex flex-col gap-2">
  <br />
  <div class="flex justify-between items-start gap-2">
    <mat-form-field class="flex-1">
      <input type="text" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter facility by name" />
            <mat-icon matSuffix color="primary">search</mat-icon>
          </mat-form-field>
          <button class="dn-button-primary" mat-raised-button (click)="onAddNewClick()">Add New Company</button>
    </div>
     

    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Name </th>
        <td mat-cell *matCellDef="let element">{{element.title | slice: 0:40}}{{element.title && element.title.length > 40 ? ' . . .' : ''}} </td>
      </ng-container>
        <ng-container matColumnDef="abbreviation">
          <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Abbreviation  </th> 
          <td mat-cell *matCellDef="let element">{{element.abbreviation | slice: 0:8}}{{element.abbreviation && element.abbreviation.length > 8 ? ' . . .' : ''}} </td> 
        </ng-container>
        <ng-container matColumnDef="email">
          <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Emails </th>
          <td mat-cell *matCellDef="let element"> {{element.email | slice: 0:30}}{{element.email && element.email.length > 30 ? ' . . .' : ''}} </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Description </th>
          <td mat-cell *matCellDef="let element"> {{element.description | slice: 0:60}}{{element.description && element.description.length > 60 ? ' . . .' : ''}} </td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Address </th>
          <td mat-cell *matCellDef="let element"> {{element.address | slice: 0:60}}{{element.address && element.address.length > 60 ? ' . . .' : ''}} </td>
        </ng-container>
        <ng-container matColumnDef="operations">
            <th class="header-data" mat-header-cell *matHeaderCellDef></th>
            <ng-container *matCellDef="let element">
                <td mat-cell style="max-width: 120px" class="buttons-div" >
                  <div class="operation-buttons buttons-div flex flex-row items-center gap-5">
                    <i class="icon icon-edit" 
                    matTooltip="Edit" matTooltipPosition="above" 
                    (click)="onEditClick(element)"></i>

                    <i class="icon icon-delete" 
                    matTooltip="Delete" matTooltipPosition="above" 
                    (click)="onDeleteClick(element)"></i>
                  </div>
                </td>
            </ng-container>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
</div>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close ><mat-icon >cancel</mat-icon></button>
  </div>