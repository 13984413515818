import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, Input, OnChanges, ElementRef, Output, EventEmitter } from '@angular/core';
import * as global from '../global'
import { PatientListService } from '../patient-list/patient-list-service';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { MatDialog } from '@angular/material/dialog';
import { PatientRoundingSheetDialog } from '../patient-rounding-sheets/create-patient-rounding-sheet-dialog/create-patient-rounding-sheet-dialog';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FacilityService } from 'src/app/services/facility.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MultiplePatientsAddDialog } from '../patient-rounding-sheets/multiple-patients-add-dialog/multiple-patients-add-dialog';
import moment from 'moment'
import { CensusPatientListService } from '../census-patient-list/census-patient-list.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { timingSafeEqual } from 'crypto';
import { CensusListService } from './census-list..service';
import { RehabDocsPopupComponent } from 'src/app/shared/popup-dialogs/rehab-docs-popup/rehab-docs-popup/rehab-docs-popup.component';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { debug } from 'console';
import { PCCService } from 'src/app/services/pcc.service';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { UpdatePatientRoundingSheetDialog } from '../patient-rounding-sheets/update-patient-rounding-sheet-dialog/update-patient-rounding-sheet-dialog';
import { GeneralConfirmationDialogComponent } from '../general-confirmation-dialog/general-confirmation-dialog.component';
import { PatientRoundingSheetService } from '../patient-rounding-sheets/patient-rounding-sheet.service';
import { C } from '@angular/cdk/keycodes';
import { WatchHelpVideoDialog } from '../drive/watch-help-video-dialog.component';
import { NoteEditorService } from '../note-editor/note-editor.service';
import { HttpResponse } from '@angular/common/http';
import { title } from 'process';
import { BillerDashboardService } from '../biller-dashboard/biller-dashboard.service';
import { ExportNotesDialogComponent } from "../../shared/popup-dialogs/export-notes-dialog/export-notes-dialog.component";
import { lastValueFrom } from 'rxjs';
import { SnfWcNurseAssociationService } from '../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { CommonService } from 'src/app/services/common.service';
import { WoundService } from 'src/app/services/wound.service';
import { FacilitySettingsService } from 'src/app/components/company/company-facilities/facility-settings/facility-settings.service';
import { ViewOnlyUsersService } from 'src/app/services/Modules/view-only-users.service';


@Component({
  selector: 'app-census-list',
  templateUrl: './census-list.component.html',
  styleUrls: ['./census-list.component.css']
})
export class CensusListComponent implements OnInit, OnDestroy, AfterViewInit {
  global = global;
  currentUser;
  currentUserDetails;
  admin: Boolean = false;
  company: Boolean = false;
  rouding_sheet_Ids: any = [];
  chargesCountData: any = [];
  notesCountData: any = [];
  pagination:any={};
  patients = [];
  patientRoundingSheets: any = [];
  total: number = 0;
  censusListFetchedOnce: boolean = true;
  facility_charge_capture_enabled = false;
  user_charge_capture_enabled = false;
  displayedColumns: string[] = [
    'status',
    'dateOfService',
    'name',
    'facility',
    'patients_count_total',
    'saved_signed_notes',
    // 'remaining_notes',
    'draft_submitted_charges',
    // 'remaining_charges',
    'operations'
  ];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @Output() mipsUpdate = new EventEmitter<any>();
  facilities = [];
  providers = [];
  selectedFacility;
  filter_facility_id = "";
  filter_provider_id = "";
  selected = '';
  mipsData: any = [];

  private showPatientList: Boolean = false;

  loaderId = 'patient-rounding-sheets-loader';
  inprogress = false;

  showLimit = 7;

  providerIdParam: string;

  pccDefaultFac;
  public pccUserInfo;
  p2pSubscription: any;
  addressSubscription: any;
  FacilitiesSubscription: any;
  clickedRows = new Set<any>();
  isAuthorized = true;
  searchText: any;
  searchTextProvider: any;
  @Input() detect_refresh_child: string
  censusDate: string;
  censusStatus: string = "true";
  chargesResponse: any;
  draftCount: any;
  missingFaceSheetsCount: any;
  @Output() refreshCounts = new EventEmitter<any>();
  totalCensus: any;
  configuration: any = {
    wound_assessment_report_category: "",
    progress_note_submission_type: "",
    wound_assessment_report_submission_type: "",
    wound_assessment_report_name: ""
  };
  selectedUser: any;
  // oldCensus: boolean = false;

  // error2LeggedAuth;

  constructor(
    private _patientListService: PatientListService,
    private _authService: AuthService,
    private _censusListService: CensusListService,
    private censusPatientListServie: CensusPatientListService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private facilityService: FacilityService,
    private commonService: CommonService,
    private loader: NgxUiLoaderService,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    private _transcriptionCreateService: TranscriptionCreateService,
    private pccService: PCCService,
    private _patientRoundingSheetService: PatientRoundingSheetService,
    private noteEditorService:NoteEditorService,
    private billerDashboardService: BillerDashboardService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _woundService: WoundService,
    private _facilitySettingsService: FacilitySettingsService,
    private viewOnlyUsersService: ViewOnlyUsersService,
    private _commonService: CommonService

  ) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUserDetails = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }
    this.user_charge_capture_enabled = this.currentUser.charge_status;
    this.pagination={
      page:1,
      skip:0,
      noOfensus:0,
      hasNext:false,
      hasBack:false,
      limit:this.showLimit
    }
  }
  ngOnDestroy(): void {
    if (window.location.pathname.includes('census') || window.location.pathname.includes('note-editor') || window.location.pathname.includes('settings') || window.location.pathname.includes("list")) {
      const censusFilter = {
        providerKey: this.filter_provider_id,
        facilityKey: this.filter_facility_id,
      }
      localStorage.setItem("censusFilter", JSON.stringify(censusFilter));
      localStorage.setItem("patient_list_provider",censusFilter.providerKey);
      localStorage.setItem("patient_list_facility",censusFilter.facilityKey);
    }
    else {
      localStorage.removeItem("censusFilter");
      localStorage.removeItem("paginationFilter");
    }
  }
  async ngOnInit() {
    if (window.location.pathname.includes('admin')) {
      this.admin = true;
    }
    else if (window.location.pathname.includes('company')) {
      this.company = true;
    }
    else {
      this.admin = false;
    }

    const censusFilterResult = JSON.parse(localStorage.getItem("censusFilter"));
    this.p2pSubscription = this._censusListService.castFilterProviderId.subscribe(filterProviderId => {
      this.filter_provider_id = filterProviderId;
      this.refreshCounts.emit(this.filter_provider_id);
      if (censusFilterResult && censusFilterResult.providerKey) {
        this.filter_provider_id = censusFilterResult.providerKey;
      }
    });
    // if(!this.p2pSubscription){
    //   this.filter_provider_id = this.currentUser._id; 
    // }

    // if (this.p2pSubscription) {
      this.initProviders();      
      await this.initFacilities(null, this.filter_provider_id);


    // }

    // if (this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
    //   this.initProviders();
    // }

    // if (this.currentUser.user_type == global.USER_TYPE.WOUND_NURSE) {
    //   this.initProviders();
    // }

    this.FacilitiesSubscription = this._censusListService.castFilterFacilityId.subscribe(filterFacilityId => {
      if (localStorage.getItem("paginationFilter")) {
        this.pagination = JSON.parse(localStorage.getItem("paginationFilter"));
      } else {
        this.resetPagination();
      }
      this.filter_facility_id = filterFacilityId;
      if (censusFilterResult && censusFilterResult.facilityKey) {
        this.filter_facility_id = censusFilterResult.facilityKey;
      }
    });
    // if (this.FacilitiesSubscription) {
    //   this.initFacilities(null, this.filter_provider_id);
    // }
    this.pccService.defaultFac$.subscribe(fac => {
      this.pccDefaultFac = fac;
    });

    this.pccService.pccUserinfo$.subscribe(value => {
      this.pccUserInfo = value;
      // this.checkPCC2LeggedAuthentication();
    });
    if (this.filter_provider_id && this.filter_facility_id) {

      if (this.route.snapshot.params.id) {
        let sanitized_filter_facility_id = this.commonService.sanitizeInput(this.filter_facility_id);
        let sanitized_filter_provider_id = this.commonService.sanitizeInput(this.filter_provider_id);
        this._censusListService.getCensusList(sanitized_filter_facility_id, sanitized_filter_provider_id, this.showLimit).subscribe(async (response: any) => {
          if (response.status === 200) {
            this.patientRoundingSheets = response.data.array;

            this.total = response.data.total;
            let row = response.data.array.filter(item => {
              return item._id == this.route.snapshot.params.id
            })
            this.show_selected(row[0])
            this.dataSource = new MatTableDataSource(this.patientRoundingSheets);
          }
        })
      }
      else {
        setTimeout(() => {
          this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
        }, 1000);
      }
    }
  }
  viewRehabDocs(census_id) {
    this.dialog.open(RehabDocsPopupComponent, { data: census_id, width: '80%', height: '100vh' })

  }
  changeLimit(value){
    this.showLimit=Number(value);
    this.handleFilterFacilityChange();
  }
  resetPagination(){
    this.pagination={
      page:1,
      skip:0,
      noOfensus:0,
      hasNext:false,
      hasBack:false,
      limit:this.showLimit
    }
  }
  initPatients() {
    const details = {
      ...this.currentUserDetails
    }
    this._patientListService.getPatients(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.patients = response.data.array
          .filter(patient => patient.is_active === 'true')
      }
    })
  }

  applyRowColors(row) {
    if (row.date_obj) {
      const censusDate = new Date(`${row.date_obj.year}-${row.date_obj.month}-${row.date_obj.day}`).getTime();
      const currentDate = new Date();
      let day = currentDate.getDate();
      let month = currentDate.getMonth();
      let year = currentDate.getFullYear();
      const currentDateFormat = new Date(`${year}-${month + 1}-${day}`).getTime();
      if (censusDate < currentDateFormat) {
        return 'row-color-past';
      }
      else if (censusDate == currentDateFormat) {
        return 'row-color-present'
      } else if (censusDate > currentDateFormat) {
        return 'row-color-future'
      }
    }
    if (row.date && !row.date_obj) {
      let currentDate = new Date().toISOString();
      if (row.date < currentDate) {
        return 'row-color-past';
      }
    }
  }

  handlePagination(){
    this.pagination.noOfensus=(this.pagination.skip)+this.patientRoundingSheets?.length;
    localStorage.setItem("paginationFilter", JSON.stringify(this.pagination))
    if(this.pagination.noOfensus<this.totalCensus){
      this.pagination.hasNext=true;
    }else{
      this.pagination.hasNext=false;
    }
    if(this.pagination.noOfensus>this.showLimit){
      this.pagination.hasBack=true;
    }else{
      this.pagination.hasBack=false;
    }
  }

  handleBackClick(){
    this.pagination.page--;
    this.pagination.skip=(this.pagination.page-1)*this.pagination.limit;
    this.pagination.limit=this.showLimit;
    this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
  }
  handleNextClick(){
    this.pagination.page++;
    this.pagination.skip=(this.pagination.page-1)*this.pagination.limit;
    this.pagination.limit=this.showLimit;
    this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
  }
  initPatientRoundingSheets(facility_id?, provider_id?, limit?) {
    // if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT && this.facilities?.length < 1) return;
    this.loader.startLoader(this.loaderId);
    let sanitized_facility_id = this.commonService.sanitizeInput(facility_id);
    let sanitized_provider_id = this.commonService.sanitizeInput(provider_id);
      this._censusListService.getCensusListWithNotesAndChargesCount(sanitized_facility_id, sanitized_provider_id, limit, false, this.censusStatus, this.pagination.skip).subscribe(async (response: any) => {
        if (response.status === 200) {
          this.patientRoundingSheets = response.data;
          this.totalCensus=response.total;
          this.mipsData = response.copyCensus;
          this.mipsUpdate.emit(this.mipsData)
          this.handlePagination();
          this.sortData({ active: 'dateOfService', direction: 'desc' });
          this.dataSource = new MatTableDataSource(this.patientRoundingSheets);
        }
      this.loader.stopLoader(this.loaderId);
    })
    }
  initializeActiveCensus(facility_id) {
    this.loader.startLoader(this.loaderId);

    this.censusPatientListServie.initializeActiveCensus(facility_id).subscribe((response: any) => {
      if (response.status === 200) {
        this.loader.stopLoader(this.loaderId);
        this.toastr.success('Census has been initialized successfully')
      }
    })
  }
  async initFacilities(selectedProvider = null, provider_id?) {
    if (!this.filter_facility_id && this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER
      || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      if (this.providers?.length > 0) {
        selectedProvider = this.providers[0]._id;
      }
      else return;
    }
    if (provider_id) selectedProvider = provider_id;
    this.facilities = []
    let sanitizedselectedProvider = this.commonService.sanitizeInput(selectedProvider);
    let sanitized_company_id = this.commonService.sanitizeInput(this._authService.currentUser.company_id);
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      pcc_2_legged_authentication: 1,
      source: 1,
      pcc_orgUuid: 1
    };
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE || this.currentUser.company_type === global.COMPANY_TYPE.SNF) {
      const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses({ associated_snf_wc_nurse_ids: sanitizedselectedProvider, company_id: sanitized_company_id }, nurseProjection, facilityProjection));
      if (response.status === 200 && response.data.length > 0) {
        this.facilities = response.data;
        this.facilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        this.selectedFacility = this.facilities.find(facility => facility._id === this.filter_facility_id);
        this.populateFacilities();
      }
    }
    else {
      this.addressSubscription = await this.facilityService.getMyFacilities(null, selectedProvider).subscribe((response: any) => {;
        if (response.status == 200) {
          this.facilities = response.data.array;
          this.facilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
          this.selectedFacility = this.facilities.find(facility => facility._id === this.filter_facility_id);
          this.populateFacilities();
          if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT)
            this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
        }
      });
    }
  }

  populateFacilities() {
    if (!this.selectedFacility) {
      this.pccService.pccUserinfo$.subscribe(u => {
        if (u) {
          const selectedFacility = this.facilities.find(f => this.isDefaultLoggedInFacility(f));
          if (selectedFacility && this.selectedFacility?._id !== selectedFacility._id) {
            this.selectedFacility = selectedFacility;
            this.filter_facility_id = this.selectedFacility._id;
            this.handleFilterFacilityChange();
          }
        } else {
          this.selectedFacility = this.facilities[0];
          this.filter_facility_id = this.facilities[0]?._id;
          // this.selectedFacility = 'All';
          // this.filter_facility_id = '';
          this.handleFilterFacilityChange();
        }
      });
    } else {
      this.facility_charge_capture_enabled = this.selectedFacility.enabled_charge_capture;
      if (this.currentUser.user_type === global.USER_TYPE.DOCTOR && (!this.facility_charge_capture_enabled || !this.user_charge_capture_enabled)) {
        this.displayedColumns.splice(this.displayedColumns.indexOf('draft_submitted_charges'), 1);
        this.displayedColumns.splice(this.displayedColumns.indexOf('status'), 1);
      }
      else if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
        // this.displayedColumns = this.displayedColumns.filter(column => column !== 'draft_submitted_charges' && column !== 'status');
        this.displayedColumns = this.displayedColumns.filter(column => column !== 'status');
      } else if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE || this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER) {
        this.displayedColumns = this.displayedColumns.filter(f => f != 'draft_submitted_charges');
      }
    }
  }

  isPCCFacility(facility_id) {
    for (const facility of this.facilities) {
      if (facility_id === facility._id) {
        if (facility.pcc_facId) {
          return true;
        }
      }
    }
  }
  isMatrixFacility(facility_id){
    for(const facility of this.facilities) {
      if(facility_id === facility._id) {
        if(facility.source == 'MatrixCare') {
          return true;
        }
      }
    }
  } 

  isDefaultLoggedInFacility(f) {
    return this.pccService.isDefaultLoggedInFacility(f);
  }
  isLoggedInFacility(f) {
    return this.pccService.isLoggedInFacility(f);
  }
  async initProviders() {
    // this.providerIdParam = this.route.snapshot.params.provider_id;
    // if (this.providerIdParam) {
    //   this.filter_provider_id = this.providerIdParam;
    // }
    if (this.currentUser.user_type == global.USER_TYPE.DOCTOR) {
      let response: any = await this.dashboardService.getP2PRelation(null, this.currentUser._id).toPromise()
      if (response.status == 200) {
        this.populateProviders(response.data.assoc_provider_id);
      }
    }

    else if (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
      this.initAssociatedProvidersListForMA();
    }
    else if (this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
      this.initAssociatedProvidersListForBO();
    }
    else if (this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
      this.initAssociatedNurseList();
    }
    else if (this.currentUser.user_type == global.USER_TYPE.WOUND_NURSE) {
      this.initAssociatedWoundNurseList();
    }else if (this.currentUser.user_type == global.USER_TYPE.VIEW_ONLY_USER) {
      this.getAssociatedProvidersListForViewOnlUsers();
    }
    else {
      this.initAssociatedProvidersList();
    }
  }

  async getAssociatedProvidersListForViewOnlUsers() {
    const doctorsResponse : any = await lastValueFrom(this.viewOnlyUsersService.getAssociatedProvidersListForViewOnlUsers({user_id : this.currentUser._id, company_id : this.currentUser.company_id}));
    this.populateProviders(doctorsResponse.data || []);
    this.initFacilities(null, this.filter_provider_id);
  }
  initAssociatedNurseList() {
    const filter = {
      snf_wc_nurse_id: this.currentUser._id,
      company_id: this.currentUser.company_id
    };
    const project = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    this._snfWcNurseAssociationService.getAssociatedSnfWcNurses(filter, project)
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.populateProviders(response.data.associated_snf_wc_nurse_ids || []);
          this.initFacilities(null, this.filter_provider_id);
        }
      });
  }

  async initAssociatedWoundNurseList() {
    const associatedProviders : any = await lastValueFrom(this._transcriptionCreateService.getAssociatedDoctorsListForWoundNurse(this.currentUser._id,this.currentUser.company_id));
    if (associatedProviders.status == 200) {
      this.populateProviders(associatedProviders.data || []);
      this.initFacilities(null, this.filter_provider_id);
    }
  }
  
  populateProviders(assoc_provider_id) {
    if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE || this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER) {
      this.providers = assoc_provider_id;
    }else {
      this.providers = [this.currentUser].concat(assoc_provider_id);
    }

    this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
    if (this.filter_provider_id) {
      let filtered_provider = this.providers.filter(item => item._id == this.filter_provider_id)
      if (filtered_provider.length > 0) {
        this.filter_provider_id = filtered_provider[0]._id;
        this.selectedUser = filtered_provider[0];
      }

    }
    if (!this.filter_provider_id) {
      this.filter_provider_id = this.providers[0]._id;
      this.selectedUser = this.providers[0];
    }
  }

  initAssociatedProvidersList() {
    this.dashboardService
      .getAssociatedDoctorsList(this.currentUser.company_id)
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          if (this.filter_provider_id) {
            this.initFacilities(null, this.filter_provider_id);
          }
          else {
            this.initFacilities();
          }
          this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
          if (!this.filter_provider_id) {
            this.filter_provider_id = this.providers[0]._id;
          }
        }
      });
  }

  initAssociatedProvidersListForMA() {
    this._transcriptionCreateService
      .getAssociatedDoctorsListForMA()
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          if (this.providers?.length > 0) {
            const providerId = this.filter_provider_id || this.providers[0]._id;
            this.initFacilities(null, providerId);
          }
          if (this.providers?.length > 0) {
            this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
            if (!this.filter_provider_id) {
              this.filter_provider_id = this.providers[0]._id;
            }
          }
        }
      });
  }

  initAssociatedProvidersListForBO() {
    this._transcriptionCreateService
      .getAssociatedDoctorsListForBO(this.currentUser._id, this.currentUser.company_id, this.currentUser.user_type)
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          if (this.providers?.length > 0) {
            const providerId = this.filter_provider_id || this.providers[0]._id;
            this.initFacilities(null, providerId);
          }
          if (this.providers?.length > 0) {
            this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
            if (!this.filter_provider_id) {
              this.filter_provider_id = this.providers[0]._id;
            }
          }
        }
      });
  }

  getPatientsCounts(census, type) {
    const { patientList } = census;
    switch (type) {
      case 'total':
        return patientList.length
      case 'new':
        {
          let count = 0;
          for (const patient of patientList) {
            if (!this.censusPatientListServie.isPatientFollowUp(census, patient)) {
              count++;
            }
          }
          return count;
        }

      case 'followUp':
        {
          let count = 0;
          for (const patient of patientList) {
            if (this.censusPatientListServie.isPatientFollowUp(census, patient)) {
              count++;
            }
          }
          return count;
        }
      case 'discharge':
        {
          let count = 0;
          for (const patient of patientList) {
            if (patient.pcc_payload?.admissionDate) {
              count++;
            }
          }
          return count;
        }
    }
  }
  onPatientSelect($event, patientRoundingSheet) {
    this.addPatientToPatientRoundingSheet(patientRoundingSheet._id, $event._id);

  }
  onSelectAllPatients($event, patientRoundingSheet) {

  }
  onPatientDeSelect($event, patientRoundingSheet) {
    this.removePatientFromPatientRoundingSheet(patientRoundingSheet._id, $event._id);
  }
  showPatientRoundingSheetDialog() {
    const obj = {
      filter_facility_id: this.filter_facility_id,
      filter_provider_id: this.filter_provider_id,
    }
    const dialogRef = this.dialog.open(PatientRoundingSheetDialog, {
      data: obj,
      width: '50%'
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      this.handleFilterFacilityChange();
    });
  }
  showUpdatePatientRoundingSheetDialog(rounding_sheet_id, name, date, date_obj, saved, signed, draft, submit, time_zone, provider_id, organization) {
    // console.log("This.facility: ", this.filter_facility_id);
    // let fac = this.facilities.filter(fac => fac._id == this.filter_facility_id)
    // let time_zone = fac[0]?.pcc_timeZone ? fac[0].pcc_timeZone : fac[0]?.timeZone;
    const obj : any = {
      rounding_sheet_id: rounding_sheet_id,
      name,
      date,
      time_zone,
      date_obj,
      saved,
      signed,
      draft,
      submit,
      provider_id
    }
    if(organization) {
      obj.organization = organization;
    }
    const dialogRef = this.dialog.open(UpdatePatientRoundingSheetDialog, {
      data: obj,
      width: '50%'
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      this.handleFilterFacilityChange();

      if (data)
        this.handleFilterFacilityChange();
    });
  }
  showMultiplePatientsAddDialog(patientRoundingSheet) {
    const dialogRef = this.dialog.open(MultiplePatientsAddDialog, { width: '50%', data: { patientRoundingSheet } });
    dialogRef.afterClosed().subscribe(async (patients) => {

      if (Array.isArray(patients)) {
        patientRoundingSheet.patientList = patients;
      }
    });
  }
  addPatientToPatientRoundingSheet(patientRoundingSheetId, patientId) {
    const details = {
      ...this.currentUserDetails,
      patientRoundingSheetId: patientRoundingSheetId,
      patientId: patientId,
    }
    const infoToast = this.toastr.info('Adding', 'Please wait', { disableTimeOut: true });

    this._censusListService.addPatientToPatientRoundingSheet(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success('Patient added Successfully', 'Success');
      }
    },
      () => this.toastr.error('Something went wrong adding patient', 'Failed'),
      () => this.toastr.clear(infoToast.toastId))
  }

  removePatientFromPatientRoundingSheet(patientRoundingSheetId, patientId) {
    const details = {
      ...this.currentUserDetails,
      patientRoundingSheetId: patientRoundingSheetId,
      patientId: patientId,
    }
    const infoToast = this.toastr.info('Removing', 'Please wait', { disableTimeOut: true });

    this._censusListService.removePatientFromPatientRoundingSheet(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success('Patient removed Successfully', 'Success');
      }
    },
      () => this.toastr.error('Something went wrong removing patient', 'Failed'),
      () => this.toastr.clear(infoToast.toastId))
  }


  deletePatientRoundingSheet(patientRoundingSheet) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: 'Are you sure you want to delete this census?'
      }
    });
    let confirmed = "false";
    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        if (patientRoundingSheet?.savedNotesCount || patientRoundingSheet?.signedNotesCount || patientRoundingSheet?.draftChargesCount || patientRoundingSheet?.submittedChargesCount) {
          this.toastr.warning('There is Note/Charge against this census', 'Cannot Delete');
        } else {
          const details = {
            ...this.currentUserDetails,
            sheetid: patientRoundingSheet._id
          }
          const infoToast = this.toastr.info('Deleting Patient Census', 'Please wait', { disableTimeOut: true });
          this._censusListService.deletePatientRoundingSheet(details).subscribe((response: any) => {
            if (response.status === 200) {
              this.toastr.clear(infoToast.toastId);
              this.toastr.success('Patient Census removed Successfully', 'Success');
              this.handleFilterFacilityChange();
            }
          })
        }
      }
    }),
      () => this.toastr.error('Something went wrong removing Patient Census', 'Failed')
  }

  sortData(sort: Sort) {
    const data = this.patientRoundingSheets.slice();
    data.forEach(element => {
      let day = element.date_obj.day.toString();
      if (day.length == 1) {
        day = '0' + day;
      }
      let month = element.date_obj.month.toString();

      if (month.length == 1) {
        month = '0' + month;
      }
      let year = element.date_obj.year.toString();
      let newDate = `${year}-${month}-${day}`;
      element.newDate = newDate;
    });
    this.patientRoundingSheets = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dateOfService':
          return compare(new Date(a.newDate).getTime(), new Date(b.newDate).getTime(), isAsc);
        // return compare(momentDateA.isValid() ? momentDateA.get('milliseconds') : a.date, momentDateB.isValid() ? momentDateB.get('milliseconds') : b.date, isAsc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource(this.patientRoundingSheets);
  }

  handleFilterFacilityChange() {
    this.displayedColumns = [
      'status',
      'dateOfService',
      'name',
      'facility',
      'patients_count_total',
      'saved_signed_notes',
      // 'remaining_notes',
      'draft_submitted_charges',
      // 'remaining_charges',
      'operations'
    ];
    this.resetPagination();

    if(this.currentUser?.additional_user_role === global.USER_TYPE.CNP
      || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE 
      || this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER) {
      this.displayedColumns = this.displayedColumns.filter(f =>f != 'draft_submitted_charges');
      // this.displayedColumns = this.displayedColumns.filter(f =>f != 'remaining_charges');
    }
    this.censusListFetchedOnce = true;
    this.initPatientRoundingSheets(this.filter_facility_id && this.filter_facility_id, this.filter_provider_id, this.showLimit);
    this.selectedFacility = this.facilities.find(f => f._id === this.filter_facility_id);
    this.initFacilitySettings(this.selectedFacility?._id);
    this.facility_charge_capture_enabled = this.selectedFacility?.enabled_charge_capture;
    // if (!this.facility_charge_capture_enabled || !this.user_charge_capture_enabled) {
    //   this.displayedColumns.splice(this.displayedColumns.indexOf('draft_submitted_charges'), 1);
    //   // this.displayedColumns.splice(this.displayedColumns.indexOf('remaining_charges'), 1);
    //   this.displayedColumns.splice(this.displayedColumns.indexOf('status'), 1);
    // }
  }
  is2LeggedAuthRequired(facilityId) {
    const facility = this.facilities.find(f => f._id === facilityId);
    if (facility && facility.pcc_facId && !facility.pcc_2_legged_authentication) {
      if (!this.isLoggedIntoPCC()) return true;
      if (!this.pccService.isLoggedInFacility(facility)) return true;
    }

    return false;
  }
  async handleUserChange($event, user) {
    this.filter_provider_id = $event.source.value;
    if ($event.isUserInput) {
      this.selectedUser = user;
      if (this.currentUser.user_type !== global.USER_TYPE.SNF_WC_NURSE && this.currentUser.user_type !== global.USER_TYPE.WOUND_NURSE) {
        this.refreshCounts.emit(this.filter_provider_id);
      }
      this.censusListFetchedOnce = true;
      await this.initFacilities(null, this.filter_provider_id);
      localStorage.removeItem("censusFilter");
      localStorage.removeItem("paginationFilter");
      this.handleFilterFacilityChange();
    }
  }

  isOwner(census) {
    return this.currentUser._id == census.createdBy
  }
  showCreateButton() {
    if (this.currentUser.user_type === global.USER_TYPE.DOCTOR) {
      return this.currentUser.user_type === global.USER_TYPE.DOCTOR
    }
    else if (this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) {
      return this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER
    }
    else if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
      return this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT
    }
    else if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      return this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE
    }

  }

  //navigation
  async viewClicked(census_id) {
    // if (census.date_obj) {
    //   const censusDate = new Date(`${census.date_obj.year}-${census.date_obj.month}-${census.date_obj.day}`).getTime();
    //   const currentDate = new Date();
    //   let day = currentDate.getDate();
    //   let month = currentDate.getMonth();
    //   let year = currentDate.getFullYear();
    //   const currentDateFormat = new Date(`${year}-${month + 1}-${day}`).getTime();
    //   if (censusDate < currentDateFormat) {
    //     this.oldCensus = true;
    //     const dialogRef = this.dialog.open(GeneralConfirmationDialogComponent, {
    //       width: '500px',
    //       data: { title: "Outdated Census", message: "Do you want to view or make any change to this census?" }
    //     });
    //     dialogRef.afterClosed().subscribe(async (result) => {
    //       console.log('result', result);
    //       if (!result) {
    //         this.oldCensus = false;
    //       }
    //       else if (this.oldCensus = true) {
    //         console.log('result', result);
    //         this.router.navigateByUrl(`/census/${census._id};telemedicine=${telemedicine}`);
    //       }
    //     });
    //   }
    // } else if (census.date && !census.date_obj) {
    //   let currentDate = new Date().toISOString();
    //   if (census.date < currentDate) {
    //     this.oldCensus = true
    //     const dialogRef = this.dialog.open(GeneralConfirmationDialogComponent, {
    //       width: '500px',
    //       data: { title: "Outdated Census", message: "Do you want to view or make any change in this census?" }
    //     });
    //     dialogRef.afterClosed().subscribe(async (result) => {
    //       console.log('ressss', result);
    //       if (!result) {
    //         this.oldCensus = false;
    //         return;
    //       }
    //       else if (this.oldCensus = true) {
    //         this.router.navigateByUrl(`/census/${census._id};telemedicine=${telemedicine}`);
    //       }
    //     });
    //   }
    // }
    localStorage.setItem('hide_census', 'false')
    let pro;

    if (this.providerIdParam) {
      let sanitizedproviderIdParam = this.commonService.sanitizeInput(this.providerIdParam);
      const userDetailResponse: any = await this.dashboardService.getUserDetail(sanitizedproviderIdParam).toPromise();
      if (userDetailResponse.status == 200) {
        pro = userDetailResponse.data;
      }
  } else {
      pro = this.providers.filter(p => p._id == this.filter_provider_id);
    }
    console.log('pro',pro);
    
    let telemedicine = pro[0]?.other_settings?.show_telemedicine || 'false';
    if (this.admin) {
      this.router.navigateByUrl(`/admin/census/${census_id};telemedicine=${telemedicine}`);
      this.showPatientList = true;
    }
    if (this.company) {
      this.router.navigateByUrl(`/company/${this.currentUser.company_id}/census/${census_id};telemedicine=${telemedicine}`);
      this.showPatientList = true;
    }
    else {
      this.showPatientList = false;
      this.router.navigateByUrl(`/census/${census_id};telemedicine=${telemedicine}`);
    }
  }
  ngAfterViewInit() {
    if (this.dataSource?.sort) {
      this.dataSource.sort = this.sort;
    }
  }
  // ngOnChanges(changes) {
  //   if (changes.detect_refresh_child.previousValue != undefined) {
  //     if (changes.detect_refresh_child.currentValue != changes.detect_refresh_child.previousValue)
  //       this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
  //   }
  // }
  isLoggedIntoPCC() {
    return !!this.pccUserInfo;
  }
  show_selected(row) {
    this.clickedRows.clear()
    this.clickedRows.add(row)
  }
  async getChargesCountData() {
    let details = {
      rounding_sheet_id: this.rouding_sheet_Ids,
    };
    const res: any = await this._censusListService.getChargeCountData(details).toPromise();
    if (res["status"] == 200) {
      this.chargesCountData = res.data
    }
  }
  async getNotesCounttData() {
    let details = {
      rounding_sheet_id: this.rouding_sheet_Ids,
    };
    const res: any = await this._censusListService.getsavedSignedCount(details).toPromise();
    if (res["status"] == 200) {
      this.notesCountData = res.data
    }
  }
  getChargecount(id: any): any {
    let index = this.chargesCountData.findIndex(x => x._id === id);
    return this.chargesCountData[index].draftCharges + " / " + this.chargesCountData[index].submitCharges;
  }
  getNoteCount(id: any): any {
    let index = this.chargesCountData.findIndex(x => x._id === id);
    return this.notesCountData[index].unSignedNotes + " / " + this.notesCountData[index].SignedNotes;
  }
  getStatus(id: any): any {
    let index = this.chargesCountData.findIndex(x => x._id === id);
    let index2 = this.notesCountData.findIndex(x => x._id === id);
    if (this.chargesCountData[index].submitCharges === this.notesCountData[index2].SignedNotes) {
      return 'green';
    }
    else if (this.chargesCountData[index].submitCharges < this.notesCountData[index2].SignedNotes) {
      return 'red';
    }
    else if (this.chargesCountData[index].submitCharges > this.notesCountData[index2].SignedNotes) {
      return 'yellow';
    }
  }
  getCensusNameDate(patientRoundingSheet: any) {
    let newName = null;
    if (patientRoundingSheet.facility.pcc_timeZone) {
      newName = moment(new Date(patientRoundingSheet.name).toLocaleString('en-US', { timeZone: patientRoundingSheet.facility.pcc_timeZone })).format("MMM D, YYYY");
    }
    else if (patientRoundingSheet.facility.timeZone) {
      newName = moment(new Date(patientRoundingSheet.name).toLocaleString('en-US', { timeZone: patientRoundingSheet.facility.timeZone })).format("MMM D, YYYY");
    }
    else {
      newName = moment(new Date(patientRoundingSheet.name).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format("MMM D, YYYY");
    }
    if (!newName.includes('Invalid date')) {
      return newName;
    } else {
      return patientRoundingSheet.name;
    }
  }
  getDOS(patientRoundingSheet) {

    if (patientRoundingSheet.date_obj) {
      let { year, month, day } = patientRoundingSheet.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return `${month}/${day}/${year}`;
    }
    if (!('pcc_timeZone' in patientRoundingSheet.facility || 'timeZone' in patientRoundingSheet.facility)) {
      patientRoundingSheet.facility.timeZone = 'America/New_York';
    }
    let timezone = patientRoundingSheet.facility.pcc_timeZone ? patientRoundingSheet.facility.pcc_timeZone : patientRoundingSheet.facility.timeZone;
    let serviceDate = moment(patientRoundingSheet.date).utc().tz(timezone).format('MM/DD/YYYY')
    return serviceDate;
  }
  checkVoidCensus(patientRoundingSheet) {
    if (patientRoundingSheet) {
      if (patientRoundingSheet?.is_void) {
        if (patientRoundingSheet.is_void === true) return false;
        else return true;
      } else {
        return true
      }
    } else {
      return true;
    }
  }
  getCensusListByStatus(status) {
    this.censusStatus = status;
    this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
  }
  restoreInactiveCensus(census, index) {
    const details = {
      ...this.currentUserDetails,
      _id: census._id,
      name: census.name,
      date: census.date,
      date_obj: census.date_obj,
      is_active: true
    }
    this._patientRoundingSheetService.updatePatientRoundingSheet(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.dataSource.data = [];
        this.patientRoundingSheets.splice(index, 1);
        this.dataSource.data = this.patientRoundingSheets;
      }
    });

  }
  cutCensusShort() {
    this.dataSource.data = this.patientRoundingSheets.slice(0, 4);
    this.censusListFetchedOnce = true;
  }

  wait(ms:any) { return new Promise((resolve) => setTimeout(resolve, ms))};
  //census_id -> getnotebycensusid -> loop note_id -> generatePDFNote
  async downloadAll(censusId) {
    const response: any = await this.censusPatientListServie.getRecentNoteByCensusId(censusId).toPromise();
    var notes = [];
    if (response.status === 200) {
      for (let note of response.data) {
        if (note.is_signed === "true") {
          let obj = {
            _id:note._id,
            title: note.transcribtion_title,
          }
          notes.push(obj)
        }
      }
      if (notes.length !== 0) {
        const infoToast = this.toastr.info('Downloading Notes...', 'Please wait');
        this.noteEditorService.downloadBatchNotes(notes).subscribe((res: any) => {
          if (res instanceof HttpResponse) {
            var url = window.URL.createObjectURL(res.body);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = "All Notes.zip";
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
            this.toastr.success('Census notes has been downloaded successfully', "Success")
          }
        })
      } else if (notes.length === 0) {
        this.toastr.error('There Are No Signed Notes In Census', 'Failed')
      }
    }
  }
  async exportNote(roundingSheet) {
    var notesIds = [];
    const response: any = await this.censusPatientListServie.getRecentNoteByCensusId(roundingSheet._id).toPromise();
    if (response.status == 200) {
      for (let note of response.data) {
        if (note.is_signed === "true") {
          notesIds.push(note._id)
        }
      }
    } 
    if (notesIds.length !== 0) {
      const dialogRef = this.dialog.open(ExportNotesDialogComponent, { width: '40%', data: { facility_id: roundingSheet.facility?._id } });
      dialogRef.afterClosed().subscribe(async result => {
        if (result.emails?.length > 0) {
          this.toastr.info('Sending emails', 'Sending...');
          let provider_name = roundingSheet.createdBy;
          let facility_name = roundingSheet.facility.title;
          // @ts-ignore 
          this.noteEditorService.generatePDFsLinksAndEmail(result.emails, notesIds, provider_name, facility_name, true)
            .subscribe((response: any) => {
              if (response.status == 200) {
                this.toastr.info('Emails sent');
              }
            }, () => {
            }, () => {
              // this.multiSelectedTranscriptions = []
              // this.multiSelectedTranscriptions.length = 0;
            })
        }
      })
    } else if (notesIds.length === 0) {
      this.toastr.error('There Are No Signed Notes In Census', 'Failed')
    }
  }
  getActivePatientsCount(census){
    if(census?.patientList){
      if((census?.patientList?.length - census?.patientsInactive?.length) > 0){
        return (census?.patientList?.length - census?.patientsInactive?.length)
      }else{
        return 0
      }
    }
  }

  watchHelpVideo( videoId ) {
    const dialogRef = this.dialog.open(WatchHelpVideoDialog, {
      data: { videoId }
    });

    dialogRef.afterClosed().subscribe( console.log );
  }
  async uploadWoundReportsinBulk(census){
    if(this.isLoggedIntoPCC()){
      const payload = {
        date_obj: census.date_obj,
        patientList: census.patientList,
        timeZone: census.facility.pcc_facId ? census.facility.pcc_timeZone : census.facility.timeZone,
        census_id: census._id,
        facility_id: census.facility._id
      }
      const response:any = await this._woundService.uploadWoundReportsinBulk(payload).toPromise();
      if(response.status == 200){
        this.toastr.success(response.message,"Succes");
      }else{
        this.toastr.error("Something went wrong");
      }
    }else{
      this.toastr.warning("You are not Logged in to PCC", "Warning");
    }
  }
  downloadGroupReport(census, isZip) {
    console.log("census data", census)
    if(census.patientList.length > 0) {
      let dateAssessment='';
      if(census.date_obj){
        let day=(census.date_obj.day<10)?('0'+(census.date_obj.day).toString()):(census.date_obj.day).toString();
        let month=(census.date_obj.month<10)?('0'+(census.date_obj.month).toString()):(census.date_obj.month).toString();
        let year=(census.date_obj.year);
        dateAssessment=month+'/'+day+'/'+year;
      }
      this.toastr.info(`Generating PDF of Wound Report`, 'Please Wait');
      this._censusListService.downloadGroupWoundReport(census, isZip).subscribe((response: any) => {
        if (response.status === 200) {
          let title = "";
          if (isZip) {
            title = `Wound Assessment_${dateAssessment}`;
          }
          else {
            title = `Wound Assessment_${dateAssessment}`;
          }
          this._commonService.convertToBlobAndDownload(response.data, title, isZip ? "zip" : "pdf");
        }
        else {
          if(response.status === 404) {
            return this.toastr.warning(`No wound found assessed on ${dateAssessment}`, '');
          }
          if (response.type === 0) {
          } else if (response.type === 3) {
            const { loaded, total } = response;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
          if (response.status == 500) {
            console.log("in no wound")
            this.toastr.warning(`No wound found assessed on ${dateAssessment}`, '');
            return false;
          }
        }
        if (response.status == 500) {
          console.log("in no wound")
          this.toastr.warning(`No wound found assessed on ${dateAssessment}`, '');
          return false;
        }
        else if (response.status == 200) {
          console.log("in status")
          return true;
        }
        
      })
    }
    else {
      this.toastr.warning(`No Patient exists in current census`, '');
    }
    
  }
  async downloadAllCensusData(census){
    if(census._id && census.patientList.length > 0){
      this._censusListService.downloadAllCensusData(census._id, census.date_obj, census.patientList, census.facility.timeZone).subscribe((res: any) => {
        if (res instanceof HttpResponse) {
          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = "AllCensusData";
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.toastr.success('All Census data has been downloaded successfully', "Success")
        }
      });
    }else{
      this.toastr.info("No Patient Found in Census...");
    }
  }
  async initFacilitySettings(facility_id) {
    let sanitizedfacility_id = this.commonService.sanitizeInput(facility_id);
    let sanitizedcompany_id = this.commonService.sanitizeInput(this.currentUser.company_id);
    let response: any = await this._facilitySettingsService.getFacilitySettings(sanitizedfacility_id ,sanitizedcompany_id).toPromise();
    if (response.status === 200) {
      this.configuration = response.data.configuration;
      if (!this.configuration.wound_assessment_report_name || this.configuration.wound_assessment_report_name === "") {
        this.configuration.wound_assessment_report_name = "Wound Assessment Report";
      }
    }
  }
}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


