import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
// import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import * as global from '../../../../includes/global';
import { API_URL } from 'src/environments/api-url';

@Injectable({
  providedIn: 'root'
})
export class ChargesCatagoryService {
  private _currentUser;
  constructor(
    private httpclient: HttpClient,
    // private http: HttpClient,
    private _authService: AuthService,
    // private dashboardService: DashboardService,
    private toastr: ToastrService) {
    this._currentUser = this._authService.currentUser;
  }

  createCategory(category=null, type = null, company_id = null){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
      categoryText: category,
      category_type:  type,
      company_id
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.createCategory, { details: details }).pipe();
  }
  updateCategory(category=null, type=null, cr_id){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
      categoryText: category,
      category_type : type,
      cr_id
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.updateCategory, { details: details }).pipe();
  }
  updateCategoryRealtion(provider, cr_id, add){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
      provider,
      cr_id,
      add
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.updateCategoryRealtion, { details: details }).pipe();
  }
  getCategoryRealtion(created_by = null, company_id = null){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type, 
      created_by,
      company_id
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.getCategoryRealtion, { details: details }).pipe();
  }
  deleteCategoryRealtion(cr_id){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type, 
      cr_id
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.deleteCategoryRealtion, { details: details }).pipe();
  }
  getAllProviders(){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type, 
    };
    return this.httpclient.post(global.url + API_URL.USER.getAllProviders, { details: details }).pipe();
  }
  getCategories(category_type = null,   user=null, company_id= null){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type, 
      category_type,
      // created_by,
      user,
      company_id,
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.getCategories, { details: details }).pipe();
  }
  setICDCategorisedCharge(charge, cr_id){
    const details = {
      user_id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type, 
      ...charge,
      cr_id, 
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.setICDCategorisedCharge, { details: details }).pipe();
  }
  setCPTCategorisedCharge(charge, cr_id){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
      ...charge,
      cr_id, 
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.setCPTCategorisedCharge, { details: details }).pipe();
  }
  getICDCategorisedCharges(cr_id){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type, 
      cr_id
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.getICDCategorisedCharges, { details: details }).pipe();
  }
  getCPTCategorisedCharges(cr_id){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type, 
      cr_id
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.getCPTCategorisedCharges, { details: details }).pipe();
  }
  removeCPTCategorisedCharge(chargeID, cr_id){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type, 
      chargeID,
      cr_id
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.removeCPTCategorisedCharge, { details: details }).pipe();
  }
  removeICDCategorisedCharge(chargeID, cr_id){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type, 
      chargeID,
      cr_id
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.removeICDCategorisedCharge, { details: details }).pipe();
  }
}
