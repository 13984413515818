import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpService} from '../../../services/http.service';
import * as global from '../../../includes/global';
import {BehaviorSubject} from 'rxjs';
import {API_URL} from "../../../../environments/api-url";

@Injectable({
    providedIn: 'root'
})
export class AppointmentDataService {

    private filterSubject = new BehaviorSubject({
        admin_id: undefined,
        facility_id: undefined,
        patient_id: undefined,
        payor_id: undefined,
        provider_id: undefined
    });
    public filters = this.filterSubject.asObservable();
    private updateSubject = new BehaviorSubject({is_updated: false});
    public updates = this.updateSubject.asObservable();
    
    private notesUpdateSubject = new BehaviorSubject({is_notes_updated: false});
    public notesUpdates = this.notesUpdateSubject.asObservable();

    
    private scheduleBtnSubject = new BehaviorSubject({is_add_view: false});
    public scheduleBtn = this.scheduleBtnSubject.asObservable();

    private routerSubject = new BehaviorSubject({provider: false, user: false});
    public routerObs = this.routerSubject.asObservable();

    public facility_id = '5e01e87c7988015334066ad7';

    constructor(private http: HttpService) {
    }

    /* payor apis */
    addPayor(payor) { 
        return this.http.post(global.app_base_url + '/appointment/addPayor', payor);
    }
    getPayorContacts(payor = {}) {
        const details = {
            ...payor
        };
        return this.http.post(global.app_base_url + '/appointment/getPayorContacts', details);
    }
    getPayor(payor_id) {
        const filter = {
            _id: payor_id
        }
        const details = {
            filter
        }
         return this.http.post(global.app_base_url + '/appointment/getPayor', {details});
     }
    getPayors() {
        return this.http.post(global.app_base_url + '/appointment/getPayors', {});
    }

    updatePayors(payor){
        return this.http.post(global.app_base_url + '/appointment/updatePayor', payor)
    }
    deletePayor(payor){ 
        return this.http.post(global.app_base_url + '/appointment/deletePayor', payor)
    }
    addPayorContact(contact) {
        const details = {
            ...contact
        };
        return this.http.post(global.app_base_url + '/appointment/addPayorContact', details);
    }
    updatePayorContact(contact) {
        const details = {
            ...contact
        };
        return this.http.post(global.app_base_url + '/appointment/updatePayorContact', details);
    }

    /* appointment apis */
    addAppointment(appointment) {
        return this.http.post(global.app_base_url + '/appointment/addAppointment', appointment);
    }

    getAppointments(query = {}) {
        return this.http.post(global.app_base_url + '/appointment/getAppointments', query);
    }

    getAppointmentsByAdminId(query = {}) {
        return this.http.post(global.app_base_url + '/appointment/getAppointmentsByAdminId', query);
    }

    getAppointmentById(query = {}) {
        return this.http.post(global.app_base_url + '/appointment/getAppointmentById', query);
    }

    updateAppointment(data = {}) {
        //
        return this.http.post(global.app_base_url + '/appointment/updateAppointment', data);
    }

    /**
     * appointment notes apis
     */
    createNote(data = {}) {
        return this.http.post(global.app_base_url + '/appointment/createNote', data);
    }
    updateNote(data = {}) {
        return this.http.post(global.app_base_url + '/appointment/updateNote', data);
    }
    getNotes(data = {}) {
        return this.http.post(global.app_base_url + '/appointment/getNotes', data);
    }
    /* filters apis */
    public setFilter(value) {
        console.log(value);
        
        this.filterSubject.next(value);
    }

    /* attribute apis */
    addAppointmentAttribute(attribute) {
        return this.http.post(global.app_base_url + '/appointment/addAppointmentAttribute', attribute);
    }

    assignFacilityAttribute(attribute) {
        return this.http.post(global.app_base_url + '/appointment/assignFacilityAttribute', attribute);
    }

    getUserAttributes() {
        return this.http.post(global.app_base_url + '/appointment/getUserAttributes', {});
    }

    getFacilityAttributes(data = {}) {
        return this.http.post(global.app_base_url + '/appointment/getFacilityAttributes', data);
    }

    /* update methods */
    goToParent(provider = true, user = true) {
        this.routerSubject.next({provider: provider, user: user});
    }
    set updateData(value) {
        this.updateSubject.next({is_updated: value});
    }

    set notesUpdateData(value) {
        this.notesUpdateSubject.next({is_notes_updated: value});
    }

    set sheduleBtnClick(value) {
        this.scheduleBtnSubject.next(value);
    }

    public testService() {
    }

    /* transcriber methods */
    getProviderPatients(data = {}) {
        return this.http.post(global.app_base_url + '/appointment/getProviderPatients', data);
    }

    /**
     * Twilio methods
     */
    getTwilioToken(data = {}) {
        return this.http.post(global.app_base_url + API_URL.VC.generateToken, data);
    }
}
