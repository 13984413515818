import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { FacilityService } from 'src/app/services/facility.service';

@Component({
  selector: 'app-patient-name',
  templateUrl: './patient-name.component.html',
  styleUrls: ['./patient-name.component.css']
})
export class PatientNameComponent implements OnInit {
  @Input('patient') patient;
  @Input('isDateOfBirthVisible') isDateOfBirthVisible:boolean = false;
  @Input('defaultSource') defaultSource:string;
  constructor(private commonService:CommonService) {}

  ngOnInit(): void {
  }

  isPCCPatient(patient) {
      return patient.source === 'PointClickCare' || patient.pcc_patientId || this.defaultSource === 'PointClickCare';
  }
  isDNPatient(patient) {
    return patient?.source != 'MatrixCare' && patient?.source != 'PointClickCare' && !patient.pcc_patientId && this.defaultSource != 'PointClickCare';
  }

  formatPatientName(patient) {
    return patient._id ? this.commonService.getPatientFullNameFormat2(patient) : patient.name;
  }
}
