import { Component, OnInit, Inject, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FacilityService } from 'src/app/services/facility.service';
import { PatientRoundingSheetService } from '../patient-rounding-sheets/patient-rounding-sheet.service';
import * as global from '.././global'
import moment from 'moment'
import { DashboardService } from '.././dashboard/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { CensusRecurrentService } from 'src/app/services/censusRecurrent.service';
@Component({
  selector: 'app-recurrent-option-dialog',
  templateUrl: './recurrent-option-dialog.component.html',
  styleUrls: ['./recurrent-option-dialog.component.css']
})
export class RecurrentOptionDialogComponent implements OnInit {
  currentUser: any;
  currentUserDetails: { id: any; auth_key: any; usertype: any; };
  selectedWeeks: 4;
  weeks: number[];
  workingDays: { day: string; is_active: boolean; selected: boolean; date_obj: any; }[];
  from_patient:Boolean=false;
  from_pcc_patient:Boolean=false;
  census_name:String='Daily Census';
  isRecurrentCensus: Boolean = true;
  date_objects: any;
  recurrenceMap: any;
  date_obj: any;
  default_active: number=4;
  @Output() outputData = new EventEmitter<any>();

  constructor(

    @Inject(MAT_DIALOG_DATA) public data: any,

    public dialogRef: MatDialogRef<RecurrentOptionDialogComponent>,

    private _authService: AuthService,
    private facilityService: FacilityService,
    private _patientRoundingSheetService: PatientRoundingSheetService,
    private toastr: ToastrService,
    private _dashboardService: DashboardService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _CensusRecurrentService: CensusRecurrentService,
    private _company: CompanyService

  ) {
    this.currentUser = this._authService.currentUser;
    this.currentUserDetails = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }

    console.log("this.date_obj : ", this.data);
    this.date_obj = this.data.date_obj;
    this.from_patient=this.data.from_patient;
    this.from_pcc_patient=this.data.from_pcc_patient;
  }

  async ngOnInit() {
    this.initScheduleDays();
    await this.handleCensusRecurrence(this.default_active)
    console.log("recurrenceMap :", this.default_active);
  }

  initScheduleDays() {
    this.weeks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    this.workingDays = [
        { day: 'S', is_active: true, selected: false, date_obj: null }, 
        { day: 'M', is_active: true, selected: false, date_obj: null }, 
        { day: 'T', is_active: true, selected: false, date_obj: null }, 
        { day: 'W', is_active: true, selected: false, date_obj: null }, 
        { day: 'T', is_active: true, selected: false, date_obj: null }, 
        { day: 'F', is_active: true, selected: false, date_obj: null }, 
        { day: 'S', is_active: true, selected: false, date_obj: null }];
  }

  mapDateObject() {
    let array = [];

    let mappingObject = {
      'week': JSON.parse(JSON.stringify(this.workingDays)),
      'status': 'active'
    }

    return mappingObject;
  }

  
  isSelectedDay(day) {
    return (day?.year==this.date_obj?.year&&day?.month==this.date_obj?.month&&day?.day==this.date_obj?.day)
  
  }

  isPastorCurrentDay(day) {
    if(!(this.date_obj)) return false;
    let today = (new Date(this.date_obj.year + '-' + this.date_obj.month + '-' + this.date_obj.day)).getDay();
    // console.log("today, day", today, day)
    if (day > today) return true;
    else return false;
  }

  async updateCurrentWeek() {

    for (let days in this.recurrenceMap[0].week) {
      let previousStatus = this.recurrenceMap[0].week[days].is_active;
      let currentStatus = this.isPastorCurrentDay(days);
      if (previousStatus == true) {
        this.recurrenceMap[0].week[days].is_active = currentStatus;
      }
    }

  }

  getDate() {
    /////   date from calender
    return this.date_obj;
  }

  getCensusDate(census) {
    if (census.date_obj) {
      let { year, month, day } = census.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return `${month}/${day}/${year}`;
    } else {
      return moment(census.date).format('MM/DD/YYYY');
    }
  }


  async getSelectedDateObjects() {
    this.date_objects = [];
    if (!(this.recurrenceMap?.length)) return [];

    for (let weeks of this.recurrenceMap) {
      for (let days of weeks.week) {

        if (days.selected) {

          this.date_objects.push(days.date_obj);

        }
      }
    }
    console.log("date_objects : ", this.date_objects, this.recurrenceMap); // Apr 30 2020

  }


  getAllDateObjects() {
    let num = 0;
    if (!(this.recurrenceMap?.length)) return [];
    let Selected_Date = moment(new Date(this.date_obj.year + '-' + this.date_obj.month + '-' + this.date_obj.day)).startOf('week').toDate();
    console.log("finall : ", this.date_obj, Selected_Date);
    let i = 0;
    const currentDate = new Date();

    for (let weeks of this.recurrenceMap) {
      let j = 0;

      for (let days of weeks.week) {


        var nextDay = new Date(Selected_Date);
        nextDay.setDate(Selected_Date.getDate() + num);
        let date_object = {
          day: nextDay.getDate(),
          month: nextDay.getMonth() + 1,
          year: nextDay.getFullYear(),
          hour: currentDate.getHours(),
          minute: currentDate.getMinutes()
        }

        this.recurrenceMap[i].week[j].date_obj = date_object

        // if(i<this.default_active){
        //   if(this.recurrenceMap[i].week[j].is_active)
        //   this.recurrenceMap[i].week[j].selected = true;
        // }

        num++;
        j++;
      }
      i++;
    }
    console.log("date_objects : ", this.recurrenceMap); // Apr 30 2020

  }


  dayofWeekSelected(weekIndex, dayIndex) {
    let previousStatus = this.recurrenceMap[weekIndex].week[dayIndex].selected;

    this.recurrenceMap[weekIndex].week[dayIndex].selected = !previousStatus;

  }

  async handleCensusRecurrence(number) {
    console.log("recurrence number of week triggered", number, this.isRecurrentCensus);

    if (number) {
      if (this.isRecurrentCensus) {
        this.recurrenceMap = [];
        for (let i = 1; i <= number; i++) {
          this.recurrenceMap.push(this.mapDateObject());
        }
        await this.updateCurrentWeek();
        this.getAllDateObjects();
      }
    }
  }

  async handleSubmit(e, emit=false) {
    console.log("ee : ", e, this.recurrenceMap);
    await this.getSelectedDateObjects();
    let output={ recurrenceMap: this.recurrenceMap, date_objects:this.date_objects, census_name:this.census_name };
    if(emit){
      this.outputData.emit(output)
    }
    this.dialogRef.close(output);

  }

  handleCancel(e) {
    console.log("eeo : ", e);
    this.dialogRef.close(null);

  }

}
