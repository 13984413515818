import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../includes/global';
import { API_URL } from './../../../environments/api-url';


@Injectable({
  providedIn: 'root'
})
export class OranizationService {

  company_id: any;

  constructor(private httpclient: HttpClient, private _authService: AuthService) {
    this.company_id = _authService.currentUser.company_id;
  }

  getAllCompanyOrganizations(company_id) {
    const details: any = {
      company_id: company_id,
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.getAllCompanyOrganizations, { details: details }).pipe();
  }

  saveOrganization(data) {
    const details: any = {
      data: data,
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.saveOrganization, { details: details }).pipe();
  }

  deleteOrganization(org_id, status) {
    const details: any = {
      org_id: org_id,
      status : status
    }
    return this.httpclient.post(global.url + API_URL.COMPANY.deleteOrganization, { details: details }).pipe();
  }

  getOrganizationUserRelation(filter: any, populate = {}) {
    return this.httpclient.post(global.url + API_URL.COMPANY.getOrganizationUserRelation, { filter: filter, populate: populate })
  }

  updateOrganizationUserRelation(filter: any, data: any, isRemoving?: boolean) {
    return this.httpclient.post(global.url + API_URL.COMPANY.updateOrganizationUserRelation, { filter: filter, user_ids: data, isRemoving: isRemoving })
  }
}

