<div class="flex flex-col gap-2">
    <br />
    <div class="flex justify-between items-start gap-2">


        <mat-form-field class="flex-1" appearance="fill">
            <mat-label>Select Provider</mat-label>
            <mat-select required [(ngModel)]="selectedProvider">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let provider of signatures" [value]="provider">
                    {{provider.provider_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <input (change)="selectFiles($event)" hidden accept=".pdf" type="file" id="file_uploader"
            #file_uploader multiple />
        <button #file_uploader_btn class="dn-button-primary" mat-raised-button (click)="file_uploader.click()">Add
            Document</button>
        {{fileName()}}
        <span style="margin-left: 10px;"></span>

        <button style="margin-left: 10px;" class="dn-button-primary" mat-raised-button (click)="upload(file_uploader)"  [disabled]="!selectedFiles">Uplaod</button>
    </div>
    <div class="flex justify-between items-start gap-2">
        <mat-form-field class="flex-1">
            <input type="text" matInput (keyup)="applyFilter($event.target.value)"
                placeholder="Filter Provider by name" />
            <mat-icon matSuffix color="primary">search</mat-icon>
        </mat-form-field>
        <button class="dn-button-primary" mat-raised-button (click)="onAddNewClick()">Add New Signature</button>
    </div>

    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Name </th>
            <td mat-cell *matCellDef="let element">{{element.provider_name}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Emails </th>
            <td mat-cell *matCellDef="let element"> {{element.provider_email}} </td>
        </ng-container>
        <ng-container matColumnDef="operations">
            <th class="header-data" mat-header-cell *matHeaderCellDef></th>
            <ng-container *matCellDef="let element">
                <td mat-cell style="max-width: 120px" class="buttons-div">
                    <div class="operation-buttons buttons-div flex flex-row items-center gap-5">
 

                        <i class="icon icon-delete" matTooltip="Delete" matTooltipPosition="above"
                            (click)="onDeleteClick(element)"></i>
                    </div>
                </td>
            </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
</div>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>