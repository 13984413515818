import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpErrorResponse
} from '@angular/common/http';
import * as global from '../global';
import moment from 'moment'
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";
import { DashboardService } from '../dashboard/dashboard.service';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FacilitySettingsService } from 'src/app/components/company/company-facilities/facility-settings/facility-settings.service';

@Injectable({
  providedIn: 'root',
})
export class NoteEditorService {
  data: any;

  private noteEditorTemplateCPTS$ = new BehaviorSubject(null);
  public castnoteEditorTemplateCPTS = this.noteEditorTemplateCPTS$.asObservable();
  public constructor(
    private httpclient: HttpClient,
    private _authService: AuthService,
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private _facilitySettingsService: FacilitySettingsService,
  ) {
    this.data = this._authService.currentUser;
  }
  setnoteEditorTemplateCPTS(cpts) {
    this.noteEditorTemplateCPTS$.next(cpts);
  }
  create(transcribt, category) {
    return this.httpclient
      .post(global.url + API_URL.NOTE.addTranscription, { transcribtion: transcribt }).pipe();
  }
  saveNote(note, censusIdForPatientSeen?, is_active?) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');

    const _note = { ...note };
    //_note.htmlText=_note.htmlText.replace(/\n/g, '');

    if (_note.audio) {
      _note.audio_id = _note.audio._id;
      delete _note.audio;
    }
    _note.provider = {
      _id: _note.provider._id
    };
    _note.patient = {
      _id: _note.patient._id
    };
    const details = {
      ...this._authService.authObject,
      note: _note,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      censusIdForPatientSeen,
      is_active
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.saveNote, { details: details }).pipe();
  }
  saveDraftNote(note, censusIdForPatientSeen?) {
    const _note = { ...note };
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');

    if (_note.audio) {
      _note.audio_id = _note.audio._id;
      delete _note.audio;
    }
    const details = {
      ...this._authService.authObject,
      note: _note,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      censusIdForPatientSeen
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.saveDraftNote, { details: details }).pipe();
  }
  saveMobileNote(note) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');

    const _note = { ...note };
    if (_note.audio) {
      _note.audio_id = _note.audio._id;
      delete _note.audio;
    }
    const details = {
      note: _note,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.saveMobileNote, { details: details }).pipe();
  }
  submitForReviewNote(noteId) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const details = {
      ...this._authService.authObject,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      noteId
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.submitForReviewNote, { details: details }).pipe();
  }
  coSignNote(note, cosigned_to = null, cosigned_by = null, status = false) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const details = {
      ...this._authService.authObject,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      note,
      cosigned_to,
      cosigned_by,
      status
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.coSignNote, { details: details }).pipe();
  }

  revertNote(noteId) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const details = {
      ...this._authService.authObject,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      noteId
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.revertNote, { details: details }).pipe();
  }

  // signNote(noteId, hasPCCPatientAndLoggedInToPCC) {
  signNote(noteId, hasLoggedInToPCC, emailIds = []) {
    let currentUserDate = moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime = moment(new Date()).format('hh:mm A');
    let currentUserDateTime = moment(new Date()).format('MM/DD/YYYY hh:mm A');

    const details = {
      ...this._authService.authObject,
      currentUserDate: currentUserDate.toString(),
      currentUserTime: currentUserTime.toString(),
      currentUserDateTime: currentUserDateTime.toString(),
      noteId,
      emailIds,
      hasLoggedInToPCC
      // hasPCCPatientAndLoggedInToPCC
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.signNote, { details: details }).pipe();
  }
  
  signNoteV2(noteId, hasLoggedInToPCC, emailIds = []) {
    let currentUserDate = moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime = moment(new Date()).format('hh:mm A');
    let currentUserDateTime = moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const details = {
      ...this._authService.authObject,
      currentUserDate: currentUserDate.toString(),
      currentUserTime: currentUserTime.toString(),
      currentUserDateTime: currentUserDateTime.toString(),
      noteId,
      emailIds,
      hasLoggedInToPCC,
      // hasPCCPatientAndLoggedInToPCC,
      tz
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.signNoteV2, { details: details }).pipe();
  }

  uploadNote(noteId, hasLoggedInToPCC, emailIds = []) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const details = {
      ...this._authService.authObject,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      hasLoggedInToPCC,
      emailIds,
      noteId,
      tz
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.uploadNote, { details: details }).pipe();
  }
  uploadNoteV2(noteId, hasLoggedInToPCC, emailIds = []) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const details = {
      ...this._authService.authObject,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      hasLoggedInToPCC,
      emailIds,
      noteId,
      tz
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.uploadNoteV2, { details: details }).pipe();
  }
  strikeOutNote(noteId) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const details = {
      ...this._authService.authObject,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      noteId,
      tz
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.strikeOutNote, { details: details }).pipe();
  }

  updateExisting(transcribt, category) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const details = {
      ...this._authService.authObject,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      ...transcribt
    };
    return this.httpclient
      .post(global.url + API_URL.NOTE.updateTranscriptionText, { transcribtion: details }).pipe();
  }

  signTranscription(transcribt, category) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      transcribtion_id: transcribt.transcribtion_id,
      is_signed: transcribt.is_signed
    };
    return this.httpclient
      .post(global.url + API_URL.NOTE.updateTranscriptionStatus, { transcribtion: details }).pipe();
  }
  signAndUploadTranscription(transcribt, category) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      transcribtion_id: transcribt.transcribtion_id,
      is_signed: transcribt.is_signed,
      is_completed: transcribt.is_completed,
    };
    return this.httpclient
      .post(global.url + API_URL.NOTE.updateTranscriptionStatus, { transcribtion: details }).pipe();
  }

  unSignTranscription(transcribt, category) {
    transcribt.is_signed = 'false';

    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_signed: transcribt.is_signed
    };
    return this.httpclient
      .post(global.url + API_URL.NOTE.updateTranscriptionStatus, { transcribtion: details }).pipe();
  }

  completeTranscription(transcribt) {

    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_completed: transcribt.is_completed
    };
    return this.httpclient
      .post(global.url + API_URL.NOTE.updateTranscriptionStatus, { transcribtion: details }).pipe();
  }

  publishTranscription(transcribt, category) {

    const details = {
      ...this._authService.authObject,

      ...transcribt
    };
    return this.httpclient
      .post(global.url + API_URL.NOTE.updateTranscriptionStatus, { transcribtion: details }).pipe();

  }

  /*
  * Publishing Transcription in database directly #mohsin-dev
  */
  unpublishTranscription(transcribt, category) {
    console.log('Unublishing transcription', category, transcribt);
    transcribt.is_published = 'false';
    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_published: transcribt.is_published
    };
    return this.httpclient.post(global.url + '/updateTranscriptionStatus', { transcribtion: details }).pipe();
  }


  deleteTranscription(transcribt, category) {
    transcribt.is_signed = 'false';

    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_active: transcribt.is_active
    };
    return this.httpclient
      .post(global.url + API_URL.NOTE.deleteTranscription, { transcribtion: details }).pipe();
  }


  createAndUploadDocs(detail) {
    var user = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type
    }
    return this.httpclient.post(global.url + '/createAndUploadDocs', { details: detail, user: user }).pipe();
  }

  getDocFileContent(path) {
    var user = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type
    }
    return this.httpclient.post(global.url + '/getDocFileContent', { path: path, user: user }).pipe();
  }

  getDoctorsList() {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    return this.httpclient
      .post(global.url + API_URL.USER.getDoctorsList, { details: item }).pipe();
  }

  getAllProviders() {
    let item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    return this.httpclient.post(global.url + API_URL.USER.getAllProviders, { details: item });
  }

  getAssociatedDoctorsList(company = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      company_id: <string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    item.company_id = this.data.company_id;
    return this.httpclient
      .post(global.url + API_URL.USER.getAssociatedDoctorsList, { details: item }).pipe();
  }

  getPatients(item) {
    return this.httpclient
      .post(global.url + API_URL.PATIENT.getPatients, { patient: item }).pipe();
  }

  getPatientsV2(item) {
    return this.httpclient
      .post(global.url + API_URL.PATIENT.getPatientsV2, { details: item }).pipe();
  }
  getPatient(item) {
    return this.httpclient
      .post(global.url + API_URL.PATIENT.getPatient, { patient: item }).pipe();
  }

  /*
  * Used to initialize facility select input #mohsin-dev
  */
  getFacilities(doctor_id = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      doctor_id: <string>null,
      company_id: <string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    item.doctor_id = doctor_id;
    item.company_id = this.data.company_id
    return this.httpclient
      .post(global.url + API_URL.FACILITY.getMyAddress, { address: item }).pipe();
  }

  addendumNote(data) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const details = {
      ...this._authService.authObject,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      ...data
    };
    return this.httpclient
      .post(global.url + API_URL.NOTE.addendumNote, { details }).pipe();
  }

  getNoteAddendums(data) {

    const details = {
      ...this._authService.authObject,

      ...data
    };
    return this.httpclient
      .post(global.url + API_URL.NOTE.getNoteAddendums, { details }).pipe();
  }
  previewNoteAsPCC(data) {

    const details = {
      ...this._authService.authObject,

      ...data
    };
    return this.httpclient
      .post(global.url + API_URL.NOTE.previewNoteAsPCC, { details }).pipe();
  }
  getFacilityPatients(facility_id, patientName = null) {
    const patient = {
      ...this._authService.authObject,
      facility_id,
      patientName
    }

    return this.httpclient
      .post(global.url + API_URL.PATIENT.getFacilityPatients, { patient }).pipe();
  }
  getFacilityPatientsByProviderId(provider_id, facility_id) {
    const patient = {
      ...this._authService.authObject,
      provider_id,
      facility_id,
    }

    return this.httpclient
      .post(global.url + API_URL.PATIENT.getFacilityPatientsByProviderId, { patient }).pipe();
  }
  getNoteById(_id) {
    const details = {
      ...this._authService.authObject,
      _id: _id
    };
    console.log(details);
    return this.httpclient.post(global.url + API_URL.NOTE.getNoteById, { details: details }).pipe();
  }
  setNoteLock(_id, userId) {
    const details = {
      ...this._authService.authObject,
      id: _id,
      userId: userId
    };
    return this.httpclient.post(global.url + API_URL.NOTE.setNoteLock, { details: details }).pipe();
  }

  generatePDFNote(noteId) {
    var details = {
      ...this._authService.authObject,
      noteId: noteId.toString()
    };
    return this.dashboardService.downloadFile(global.url + API_URL.NOTE.generatePDFNote, details).pipe();
  }
  downloadBatchNotes(notes) {
    var details = {
      notes:notes
    };
    return this.dashboardService.downloadFile(global.url + API_URL.NOTE.downloadBatchNotes, details).pipe();
  }
  generatePDFAndDownload(transcriptionData, note_id?) {
    var details = {
      ...this._authService.authObject,
      transText: transcriptionData.toString(),
      note_id: note_id
    };
    return this.dashboardService.downloadFile(global.url + API_URL.NOTE.generatePDFAndDownload, details).pipe();
  }
  generatePDFsAndEmail(emails, noteIDs, provider, facility) {
    var details = {
      // ...this._authService.authObject,
      ...this._authService.authObject,
      emails,
      noteIDs,
      provider,
      facility,
      // transText: transcriptionData.toString()
    };
    return this.httpclient.post(global.url + API_URL.NOTE.generatePDFsAndEmail, { details: details }).pipe();
  }
  generateEncryptedPDFsAndEmail(emails, noteIDs, provider, facility) {
    var details = {
      // ...this._authService.authObject,
      ...this._authService.authObject,
      emails,
      noteIDs,
      provider,
      facility,
      // transText: transcriptionData.toString()
    };
    return this.httpclient.post(global.url + API_URL.NOTE.generateEncryptedPDFsAndEmail, { details: details }).pipe();
  }
  generatePDFsLinksAndEmail(emails, noteIDs, provider_name?, facility_title?, addAddendum?) {
    var details = {
      // ...this._authService.authObject,
      ...this._authService.authObject,
      emails,
      noteIDs,
      provider_name,
      facility_title,
      addAddendum
      // transText: transcriptionData.toString()
    };
    return this.httpclient.post(global.url + API_URL.NOTE.generatePDFsLinksAndEmail, { details: details }).pipe();
  }
  getMobileTranscription(note_id) {
    const details = {
      note_id: note_id
    };
    return this.httpclient.post(global.url + API_URL.NOTE.getMobileTranscription, { details: details }).pipe();
  }
  getDraftNoteByUserId(facility_id, provider_id, patient_id, rounding_sheet_id?) {
    const details = {
      ...this._authService.authObject,
      facility_id,
      provider_id,
      patient_id,
      rounding_sheet_id
    };
    return this.httpclient.post(global.url + API_URL.NOTE.getDraftNoteByUserId, { details: details }).pipe();
  }

  getCC(ccName) {
    const data = {
      'cc1': {
        "@assessment": ['Assessment 1', 'Assessment 2'],
        "@hpi": ['Hpi 1', 'Hpi 2'],
        "@pe": ['Pe 1', 'Pe 2'],
        "@ros": ['Ros 1', 'Ros 2'],
      },
      'cc2': {
        "@assessment": ['Assessment 2.1', 'Assessment 2.2'],
        "@hpi": ['Hpi 2.1', 'Hpi 2.2'],
        "@pe": ['Pe 2.1', 'Pe 2.2'],
        "@ros": ['Ros 2.1', 'Ros 2.2'],
      }
    };
    return data[ccName];
  }

  applyDateTimezone(note, date) {
    return moment(new Date(date).toLocaleString('en-US', { timeZone: note.dates.timezone })).toDate() //.format('MMM DD, YYYY h:mm a');
  }

  getFollowUpNoteAsJson(patientId, providerId, excludeNoteId?) {
    const details = {
      ...this._authService.authObject,
      patientId, providerId, excludeNoteId
    };
    return this.httpclient.post(global.url + API_URL.NOTE.getFollowUpNoteAsJson, { details: details }).pipe();
  }
  getFollowUpWoundsAsJson(patientId, providerId, excludeNoteId?) {
    const details = {
      ...this._authService.authObject,
      patientId, providerId, excludeNoteId
    };
    return this.httpclient.post(global.url + API_URL.NOTE.getFollowUpWoundsAsJson, { details: details }).pipe();
  }
  updateNoteEditLock(note_id) {
    const details = {
      ...this._authService.authObject,
      note_id
    };
    return this.httpclient.post(global.url + API_URL.NOTE.updateNoteEditLock, { details: details }).pipe();
  }
  updateNoteTemplateId(note_id, template_id_ref) {
    const details = {
      ...this._authService.authObject,
      note_id,
      template_id_ref
    };
    return this.httpclient.post(global.url + API_URL.NOTE.updateNoteTemplateId, { details: details }).pipe();
  }

  cleanTagsInNoteHTMLText(htmlText) {
    let cleanedHTMLText = htmlText.replace(/&nbsp;/g, ' ');

    const $tmp = document.createElement("div");
    $tmp.innerHTML = cleanedHTMLText;



    // remove empty span tags
    while (true) {
      const $spans = Array.from($tmp.querySelectorAll('span'));
      let isChanged = false;
      for (const $span of $spans) {
        if ($span.innerText.trim().length == 0 && $span.getAttribute('data-phrase') !== 'signature@dn') {
          $span.remove();
          isChanged = true;
        }
      }
      if (!isChanged) {
        break;
      }
    }

    // remove unnecessary span tags
    while (true) {
      const $spans = Array.from($tmp.querySelectorAll('span'));
      let isChanged = false;
      for (const $span of $spans) {
        // spans with no attributes
        const attributes = Array.from($span.attributes)
        if (attributes.length === 0) {
          $span.outerHTML = $span.innerHTML;
          isChanged = true;
        }
      }
      if (!isChanged) {
        break;
      }
    }


    cleanedHTMLText = $tmp.innerHTML;
    return cleanedHTMLText;
  }

  initFacilitySettings(facilityId) {
    return new Promise<any>((resolve) => {
      this._facilitySettingsService.getFacilitySettings(facilityId, this._authService.currentUser.company_id).subscribe({
        next: (response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          }
        }
      });
    });
  }
  openEmailSendingDialog(DialogEmailSendingComponentComponent, data) {
    return new Promise<any>((resolve) => {
      this.dialog.open(DialogEmailSendingComponentComponent, {
        data
      }).afterClosed().subscribe((result: any) => {
        resolve(result);
      }
      )
    });
  }
  saveTimeLogs(note_id, time_logs) {
    const details = {
      ...this._authService.authObject,
      note_id,
      time_logs
    };
    return this.httpclient.post(global.url + API_URL.NOTE.saveTimeLogs, { details: details }).pipe();
  }
}

