<div class="flex gap-1">
    <mat-form-field class="w-1/3" style="color: black !important;">
        <mat-label>Select {{placeholder}} Year</mat-label>
        <mat-select [(ngModel)]="selectedYear" name="year" required="{{isRequired}}" [disabled]="isDisabled">
            <input placeholder="Search" [(ngModel)]="searchYear"
            style="height: 40px;padding-left: 5px; border: none; width: 100%;" (keydown)="$event.stopPropagation()">
            <mat-option value="" disabled selected>
                -- {{placeholder.toUpperCase()}} YEAR --
            </mat-option>
            <mat-option *ngFor="let year of yearsOption | searchYear: searchYear" [value]="year" (click)="OnYearChange(year);">
                {{year}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="!selectedYear">Please select year</mat-error>
    </mat-form-field>
    <mat-form-field class="w-1/3" style="color: black !important;">
        <mat-label>Select {{placeholder}} Month</mat-label>
        <mat-select [(ngModel)]="selectedMonth" name="month" required="{{isRequired}}" [disabled]="isDisabled">
            <mat-option value="" disabled selected>
                -- {{placeholder.toUpperCase()}} MONTH --
            </mat-option>
            <mat-option *ngFor="let month of monthOptions" [value]="month" (click)="OnMonthChange(month,true);">
                {{month}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="!selectedMonth">Please select month</mat-error>
    </mat-form-field>
    <mat-form-field class="w-1/3" style="color: black !important;">
        <mat-label>Select {{placeholder}} Day</mat-label>
        <mat-select [(ngModel)]="selectedDay" name="day" required="{{isRequired}}" [disabled]="isDisabled">
            <mat-option value="" disabled selected>
                -- {{placeholder.toUpperCase()}} DAY --
            </mat-option>
            <mat-option *ngFor="let day of daysOption" [value]="day" (click)="OnDayChange(day);">
                {{day}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="!selectedDay">Please select day</mat-error>
    </mat-form-field>
</div>