<div class="site-header flex flex-col">

  <mat-toolbar class="flex" *ngIf="!isCurrentUserManager() && !isCurrentUseraNurse()">

    <div class="flex items-center justify-start w-full" style="padding-left: 10px;">
      <a [routerLink]="isCompanyRoute() ? ['/company', this.company_id] : ['/dashboard']">
        <img [src]="companylogoPath ? companylogoPath : defaultImg">
      </a>
      
      <ul class="top-nav flex items-center">
        <ng-container *ngIf="frontEndMenu">
          <ng-container *ngFor="let menu of menus">
            <li style="cursor: pointer;" *ngIf="isShowMenu(menu)" routerLinkActive="active"
              [ngClass]="{active: isActive(menu)}"><a (click)="onClick(menu)">{{menu.title}}</a></li>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!frontEndMenu">
          <ng-container *ngFor="let menu of menus.slice(0, numberOfMenuItems); let i = index">
            <li style="cursor: pointer;" *ngIf="isShowMenu(menu)" routerLinkActive="active"
            [ngClass]="{active: isActive(menu)}"><a (click)="onClick(menu)">{{menu.title}}</a></li>
          </ng-container>

          <li *ngIf="menus.length > numberOfMenuItems">
            <button mat-icon-button [matMenuTriggerFor]="menuu">
              <span class="material-symbols-outlined" matTooltip="More Menu items">
                apps
              </span>
            </button>
            <mat-menu #menuu="matMenu">
              <ng-container *ngFor="let menu of menus.slice(numberOfMenuItems)">
                <button mat-menu-item  (click)="onClick(menu)">{{menu.title}}</button>
              </ng-container>
            </mat-menu>
          </li>
        </ng-container>
      </ul>
    </div>

    <div class="flex items-end justify-center">
      <ng-container *ngIf="!isAdminRoute() && !isCompanyRoute()" style="display: flex;">
        <app-pcc-widget *ngIf="currentUser.has_pcc_account === 'true'"></app-pcc-widget>
        <div class="alignment-profile">
          <app-notifications></app-notifications>
          <span matTooltip="Settings" style="cursor: pointer;"
            *ngIf="(currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER && currentUser.user_type != global.USER_TYPE.WOUND_NURSE)"
            (click)="notificationSettings()">
            <img src="../../../assets/img/settings.png" class="image"
              [routerLink]="currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER ? ['/user-settings/census'] : ['/user-settings']" />
          </span>
        </div>
      </ng-container>
      <div class="profile-alignment">
        <span *ngIf="!companyPath" class="profile_pic"
        [style.backgroundImage]="'url(' + (currentUser.profile_pic || 'assets/img/default-avatar.png') + ')'"></span>
        <button class="welcome" mat-button [matMenuTriggerFor]="menu">

          {{companyPath ? ('Welcome ' + this.currentCompanyName) : user_welcome_str}}
        </button>
      </div>
      <div class="items-end justify-center">
        <mat-menu #menu="matMenu" [class]="'menus-panel'">

            <button *ngIf="!companyPath" class="grid" mat-menu-item [routerLink]="['/profile']">
              <div class="alignment-profile">
                  <i class="material-icons" style="margin-right: 10px !important;">group</i>
                  <span class="tag-style">User Profile</span>
              </div>
            </button>
          
          <button class="grid" mat-menu-item *ngIf="companyPath"
            (click)="onCompanyProfileClick()">
            <div class="alignment-profile">
              <i class="material-icons"  style="margin-right: 10px !important;">group</i>
              <span class="tag-style">Company Profile</span>
            </div>
          </button>


          <button class="grid" mat-menu-item target="_blank"
          [routerLink]="['/company/' + currentUser.company_id + '/error-logs']" *ngIf="adminRoute?.length || companyPath">
          <div class="alignment-profile">
            <i class="image-icon facility-icon" style="background-size: contain; height: 20px; margin-right: 10px;"></i>
            <span class="tag-style">Error Logs</span>
          </div>
          </button>
          <button class="flex justify-center items-center gap-2" mat-menu-item *ngIf="companyPath"
            (click)="onSettingClick()">
            <i class="image-icon setting-icon" style="background-size: contain; height: 20px;"></i>
            <span class="tag-style">Settings</span>
          </button>
          <button class="grid" mat-menu-item
            (click)="onInsuranceCompanyClick()"
            *ngIf="currentUser.user_type == (global.USER_TYPE.DOCTOR || currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) && !companyPath && currentUser.other_settings.show_ime_vendor">
            <div class="alignment-profile">
              <i class="image-icon facility-icon" style="background-size: contain; height: 20px; margin-right: 10px;"></i>
              <span class="tag-style">IME Vendors</span>
            </div>
          </button>
          <button class="grid" mat-menu-item (click)="onRRGenClick()"
            *ngIf="!companyPath && currentUser.other_settings.show_rrgen">
            <div class="alignment-profile">
              <i class="image-icon facility-icon" style="background-size: contain; height: 20px; margin-right: 10px;"></i>
              <span class="tag-style">RR-Gen</span>
            </div>
          </button>
          <button class="grid" mat-menu-item (click)="eSignClick()"
          *ngIf="(currentUser.user_type == global.USER_TYPE.DOCTOR || currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) && currentUser.other_settings.show_esign">
          <div class="alignment-profile">
            <i class="image-icon facility-icon" style="background-size: contain; height: 20px; margin-right: 10px;"></i>
            <span class="tag-style">e-Sign</span>
          </div>
          </button>

          <ng-container
            *ngIf="(currentUser.admin_type == 'full_admin' || currentUser.admin_type == 'system_admin' || currentUser.admin_type == 'transcriber_admin' || currentUser.admin_type == global.ADMIN_TYPE.WOUND_NURSE_ADMIN) && !hasCompany">
            <a class="grid" mat-menu-item target="_blank"
              [routerLink]="['/dashboard']" *ngIf="companyPath">
              <div class="alignment-profile">
                <i class="material-icons"  style="margin-right: 10px !important;">compare_arrows</i>
                <span class="tag-style">Switch to Dashboard</span>
              </div>
            </a>
            <a class="grid" mat-menu-item target="_blank"
              (click)=" checkAuthForAdmin(currentUser.admin_type == 'wound_nurse_admin' ? 'relationship': null); setCompany(currentUser.company_id)" *ngIf="!companyPath">
              <div class="alignment-profile">
                <i class="material-icons"  style="margin-right: 10px !important;">compare_arrows</i>
                <span class="tag-style">Manage Portal</span>
              </div>
            </a>
          </ng-container>


          <ng-container
            *ngIf="((currentUser.admin_type == 'full_admin' || currentUser.admin_type == 'system_admin') && hasCompany ) || (currentUser.account_type === global.ACCOUNT_TYPE.COMPANY) ">
            <a class="grid" mat-menu-item target="_blank"
              [routerLink]="['/dashboard']" *ngIf="companyPath">
              <div class="alignment-profile">
                <i class="material-icons"  style="margin-right: 10px !important;">compare_arrows</i>
                <span class="tag-style">Switch to Dashboard</span>
              </div>
            </a>
            <a class="grid" mat-menu-item target="_blank"
              [routerLink]="['/company/' + currentUser.company_id]" *ngIf="hasCompany && !companyPath"
              style="color: green;">
              <div class="alignment-profile">
                <i class="material-icons"  style="margin-right: 10px !important;">compare_arrows</i>
                <span class="tag-style">Manage {{companyData?.name}}</span>
              </div>
            </a>
          </ng-container>

          <ng-container *ngIf="currentUser.product_owner">
            <a class="grid" mat-menu-item
              (click)="checkAuthForAdmin('manage-companies');" style="color: rgb(13, 36, 163);">
              <div class="alignment-profile">
                <i class="material-icons"  style="margin-right: 10px !important;">compare_arrows</i>
                <span class="tag-style">Manage Companies</span>
              </div>
            </a>
          </ng-container>
          <a target="_blank" *ngIf="!companyPath" class="grid" style="color: black;"
            mat-menu-item [routerLink]="['/user-tickets']">
            <div class="alignment-profile">
              <mat-icon  style="margin-right: 10px !important;">subject</mat-icon>
              <span class="tag-style">My Tickets</span>
            </div>
          </a>

          <ng-container *ngIf="(currentUser.admin_type == 'full_admin' || currentUser.admin_type == 'system_admin')">
            <a class="grid" mat-menu-item target="_blank"
              [routerLink]="['/company/' + currentUser.company_id + '/app-release']" *ngIf="companyPath">
              <div class="alignment-profile">
                <i class="material-icons">notes</i>
                <span class="tag-style">Manage Release Notes</span>
              </div>
            </a>
          </ng-container>
          <ng-container *ngIf="(currentUser.admin_type == 'full_admin' || currentUser.admin_type == 'system_admin')">
            <a class="grid" mat-menu-item target="_blank"
              [routerLink]="['/company/' + currentUser.company_id + '/tickets']" *ngIf="companyPath">
              <div class="alignment-profile">
                <i class="material-icons">comment</i>
                <span class="tag-style">View tickets</span>
              </div>
            </a>
          </ng-container>
          <ng-container *ngIf="(currentUser.admin_type == 'full_admin' || currentUser.admin_type == 'system_admin')">
            <a class="grid" mat-menu-item target="_blank"
              [routerLink]="['/company/' + currentUser.company_id + '/reports']" *ngIf="companyPath">
              <div class="alignment-profile">
                <i class="material-icons">summarize</i>
                <span class="tag-style">Reports</span>
              </div>
            </a>
          </ng-container>

          <button *ngIf="(currentUser.user_type == global.USER_TYPE.DOCTOR || currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) && !companyPath"
            class="grid" mat-menu-item (click)="addNews()">
            <div class="alignment-profile">
              <i class="material-icons"  style="margin-right: 10px !important;">add</i>
              <span class="tag-style">News</span>
            </div>
          </button>

          <button *ngIf="(currentUser.admin_type == 'full_admin' || currentUser.admin_type == 'system_admin') && companyPath" class="grid"
            mat-menu-item (click)="addNews()">
            <div class="alignment-profile">
              <i class="material-icons"  style="margin-right: 10px !important;">add</i>
              <span class="tag-style">Add News</span>
            </div>
          </button>
          <button class="grid" mat-menu-item (click)="logout()">
            <div class="alignment-profile">
              <i class="material-icons"  style="margin-right: 10px !important;">exit_to_app</i>
              <span class="tag-style">Logout</span>
            </div>
          </button>
        </mat-menu>
      </div>
    </div>

  </mat-toolbar>
  <mat-toolbar class="flex" *ngIf="isCurrentUseraNurse()">
    <div class="flex items-start justify-center w-full">
      <a [routerLink]="['/nursing']">
        <img [src]="companylogoPath ? companylogoPath : defaultImg">
      </a>
      <ul class="top-nav">
        <ng-container *ngFor="let menu of menus">
          <li style="cursor: pointer;" *ngIf="isShowMenu(menu)" routerLinkActive="active"
            [ngClass]="{active: isActive(menu)}"><a (click)="onClick(menu)">{{menu.title}}</a></li>
        </ng-container>
      </ul>
    </div>
    <div class="items-end justify-center">
      <span *ngIf="!companyPath" class="profile_pic"
        [ngStyle]="{'background-image': 'url(' + (currentUser.profile_pic || 'assets/img/default-avatar.png') + ')'}"></span>
      <button class="welcome" mat-button [matMenuTriggerFor]="menu">
        {{companyPath ? ('Welcome ' + this.currentCompanyName) : user_welcome_str}}
      </button>
      <mat-menu #menu="matMenu" [class]="'menus-panel'">
        <button *ngIf="!companyPath" class="flex items-start justify-center gap-2" mat-menu-item
          [routerLink]="['/profile']">
          <i class="material-icons">group</i>
          <span class="tag-style">User Profile</span>
                  </button>
        <button class="flex items-start justify-center gap-2" mat-menu-item (click)="logout()">
          <i class="material-icons">exit_to_app</i>
          <span class="tag-style">Logout</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>

  <mat-toolbar class="flex" *ngIf="isCurrentUserManager()">

    <div class="flex items-start justify-center w-full">
      <a *ngIf="!isCurrentUserBiller()" [routerLink]="['/appointment']">
        <img [src]="companylogoPath ? companylogoPath : defaultImg">
      </a>
      <a *ngIf="isCurrentUserBiller()" [routerLink]="['/biller-charges']">
        <img [src]="companylogoPath ? companylogoPath : defaultImg">
      </a>

      <ul class="top-nav" *ngIf="!companyPath">
        <li><a routerLink="/appointment">Appointment</a></li>
        <li><a routerLink="/appointment">My Patients</a></li>
        <li><a routerLink="/appointment">My Drive</a></li>
        <li class="active"><a routerLink="/appointment">Schedule</a></li>
        <li><a routerLink="/appointment">Training System</a></li>
      </ul>
      <ul class="top-nav" *ngIf="companyPath">
        <ng-container *ngFor="let menu of menus">
          <li style="cursor: pointer;" *ngIf="isShowMenu(menu)" routerLinkActive="active"
            [ngClass]="{active: isActive(menu)}"><a (click)="onClick(menu)">{{menu.title}}</a></li>
        </ng-container>
      </ul>
    </div>

    <div class="flex items-end justify-center gap-2">

      <div class="items-end justify-center">
        <span class="profile_pic"
          [ngStyle]="{'background-image': 'url(' + (currentUser.profile_pic || 'assets/img/default-avatar.png') + ')'}"></span>
        <button class="welcome" mat-button [matMenuTriggerFor]="menu">

          {{user_welcome_str}}
        </button>
        <mat-menu #menu="matMenu" [class]="'menus-panel'">
          <button class="flex items-start justify-center gap-2" mat-menu-item [routerLink]="['/profile']">
            <i class="material-icons">group</i>
            <span class="tag-style">Profile
            </span>
          </button>
          <ng-container
            *ngIf="(currentUser.admin_type == 'full_admin' || currentUser.admin_type == 'system_admin' || currentUser.admin_type == 'transcriber_admin' || currentUser.admin_type == global.ADMIN_TYPE.WOUND_NURSE_ADMIN) && !hasCompany">
            <a class="flex items-start justify-center gap-2" mat-menu-item target="_blank" [routerLink]="['/dashboard']"
              *ngIf="companyPath&&!isCurrentUserBiller()">
              <i class="material-icons">compare_arrows</i>
              <span class="tag-style">Switch to Dashboard</span>
            </a>
            <a class="flex items-start justify-center gap-2" mat-menu-item target="_blank"
              [routerLink]="['/biller-charges']" *ngIf="companyPath&&isCurrentUserBiller()">
              <i class="material-icons">compare_arrows</i>
              <span class="tag-style">Switch to Dashboard</span>
            </a>
            <a class="flex items-start justify-center gap-2" mat-menu-item target="_blank"
              (click)="checkAuthForAdmin(currentUser.admin_type == global.ADMIN_TYPE.WOUND_NURSE_ADMIN ? 'relationship': null); setCompany(currentUser.company_id)" *ngIf="!companyPath">
              <i class="material-icons">compare_arrows</i>
              <span class="tag-style">Manage Portal</span>
            </a>
          </ng-container>

          <button class="flex items-start justify-center gap-2" mat-menu-item (click)="logout()">
            <i class="material-icons">exit_to_app</i>
            <span class="tag-style">Logout</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar>
  <div *ngIf="patientList && patientList.length > 0" class="flex items-start justify-baseline gap-2 w-full" style="margin-left: 1rem; list-style: none;">
    <ng-container *ngFor="let p of patientList;">
        <div class="patient-item"   [routerLink]="['/patient-detail', p._id]" [routerLinkActive]="'active'">
            <a class="patient-name" (click)="onRowClick(p._id)"> {{p.name}}</a>
            <button class="close" mat-button (click)="removePatientFromHeader(p._id)"><strong>x</strong></button>
        </div>
    </ng-container>
</div>
</div>

