import {Component, OnInit, ViewChild} from '@angular/core';
import {AppointmentDataService} from '../../service/data.service';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';
import {FacilityService} from '../../../../services/facility.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TranscriptionCreateService} from '../../../../includes/transcription-create/transcription-create.service';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import {EventInput} from "@fullcalendar/core";
import {Appointment} from "../../../../classes/appointment";
import {NotifyType} from "../../../../classes/notify-type";
import {AuthService} from "../../../../services/auth.service";
import {PatientListService} from "../../../../includes/patient-list/patient-list-service";
import { FullCalendarComponent } from '@fullcalendar/angular';

@Component({
    selector: 'app-transcriber-appointment',
    templateUrl: './transcriber-appointment.template.html',
    styleUrls: ['./transcriber-appointment.css']
})
export class TranscriberAppointmentComponent implements OnInit {
    displayedColumns: string[] = ['id', 'payor', 'facility', 'patient', 'date', 'time'];
    fixedColumns: string[] = ['id', 'payor', 'facility', 'patient', 'date', 'time'];
    dataSource: MatTableDataSource<any>;
    provider = '';
    facility = '';
    appointments = [];
    facility_attributes = [];
    providers = [];
    attribute_values = [{key: '', value: ''}];
    content_editable = false;
    property_testing = '';
    rowId = 0;
    loading = false;
    selectedProvider = undefined;
    selectedFacility = '';
    editing = false;
    filters = {
        facility_id: undefined,
        patient_id: undefined,
        payor_id: undefined,
        provider_id: undefined
    };
    loaderId = 'loader-01';

    // full calendar work
    @ViewChild('calendar') calendarComponent: FullCalendarComponent;
    calendarPlugins = [dayGridPlugin, timeGridPlugin,listPlugin, interactionPlugin];
    header = {
        left: 'today prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    };
    titleFormat = {year: 'numeric', month: 'long'};
    calendarWeekends = true;
    calendarEvents: EventInput[];

    // add appointment form
    model = new Appointment();
    facilities = [];
    patients = [];
    payors = [];
    doctor = undefined;

    is_add_view = false;
    is_detail_view = false;

    appointmentId = '';


    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(private service: AppointmentDataService,
                private toastr: ToastrService,
                private facilityService: FacilityService,
                private authService: AuthService,
                private transcriptionService: TranscriptionCreateService,
                private patientService: PatientListService,
                private loader: NgxUiLoaderService) {
    }

    handleDateClick(arg) {
        this.is_add_view = true;
    }

    handleEventClick(arg) {
        this.appointmentId = arg.event.extendedProps._id;
        this.is_detail_view = true;
    }

    /**
     * add appointment related methods
     */
    async addAppointment(appointment) {
        //
        await this.processNotifyType(appointment);
        await this.processDateTime(appointment);
        this.model.admin_id = this.authService.authObject.id;
        this.service.addAppointment(this.model).subscribe(data => {

            this.toastr.success('Appointment added successfully', 'Success');
            this.service.updateData = true;
            this.is_add_view = false;
            this.getAppointments({provider_id: this.authService.currentUser._id});
        }, error => {

            this.toastr.error('something went wrong', 'Error');
        });
    }

    async processNotifyType(appointment) {
        //
        let notify_obj = new NotifyType();
        for (let i = 0; i < appointment.notify_type.length; i++) {
            notify_obj[appointment.notify_type[i]] = true;
        }
        this.model.notify_type = notify_obj;
    }

    async processDateTime(appointment) {

        this.model.startTime = appointment.tos;
        let time = appointment.tos.split(' ');
        const meredium = time[1];
        time = time[0].split(':');
        if (meredium === 'pm') {
            // tslint:disable-next-line:radix
            time[0] = parseInt(time[0]) + 12;
            // tslint:disable-next-line:radix
            time[1] = parseInt(time[1]);
        }
        // tslint:disable-next-line:radix
        time[0] = parseInt(time[0]);
        // tslint:disable-next-line:radix
        time[1] = parseInt(time[1]);
        const dos = new Date(appointment.dos);
        dos.setHours(time[0]);
        dos.setMinutes(time[1]);
        this.model.start = dos;
        this.model.dos = dos;
        this.model.tos = new Date();
        this.model.tos.setHours(time[0]);
        this.model.tos.setMinutes(time[1]);
    }

    async ngOnInit() {
        try {
            await this.loadData();
            this.service.updates.subscribe(async data => {
                await this.loadData();
            }, error => {
                this.toastr.error('something went wrong in detecting updates', 'Error');
            });
            this.service.filters.subscribe(async data => {
                
                if (data.facility_id !== null && data.facility_id !== undefined) {
                    this.selectedFacility = data.facility_id;
                    this.filters = data;
                    await this.loadData();
                }
            }, error => {
                this.toastr.error('something went wrong in filters', 'Error');
            });
        } catch (e) {
            this.toastr.error('something went wrong in reading data');
        }
    }

    async loadData() {
        try {
            this.service.getPayors().subscribe(data => {
                this.payors = data.data.array;
            }, error => {
                this.toastr.error('something went wrong', 'Error');
            });
            this.transcriptionService.getAllProviders().subscribe( data => {
                // @ts-ignore
                this.providers = data.data.array;
            }, error => {
                this.toastr.error('something went wrong', 'Error');
            });
            // this.facilityService.getFacilities().subscribe( data => {
            //     // @ts-ignore
            //     this.facilities = data.data.array;
            // }, error =>  {
            //     this.toastr.error('something went wrong', 'Error');
            // });
            this.patientService.getAllPatients(this.authService.authObject).subscribe( data => {
                // @ts-ignore
                this.patients = data.data.array;
            }, error => {
               this.toastr.error('something went wrong', 'Error');
            });
            await this.getAppointments(this.filters);
        } catch (e) {
            this.toastr.error('something went wrong in loading data', 'Error');
        }
    }

    getFacilities() {
        return new Promise((resolve, reject) => {
            this.facilityService.getMyFacilities(this.filters.provider_id).subscribe(data => {

                // @ts-ignore
                const facilities = data.data.array;
                this.selectedFacility = facilities[0]._id;
                this.filters.facility_id = facilities[0]._id;
                resolve(null);
            }, error => {
                reject('error => ' + error.toString());
            });
        });
    }

    getAppointments(filters = {}) {
        return new Promise((resolve, reject) => {
            // @ts-ignore
            this.service.getAppointments({admin_id: this.authService.authObject.id}).subscribe(data => {
                this.appointments = data.data.array;
                this.calendarEvents = data.data.array.map(e => {
                    e.title += ` (${e.patient_id.name})`;
                    return e;
                })
                resolve(null);
            }, error => {
                reject('error => ' + error.toString());
            });
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    
    navigateCalenderToDate(date) {
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.changeView('dayGridMonth', date);

    }
    handleExit() {
        this.is_detail_view=false;
        this.loadData()
    }
}
