<h1 mat-dialog-title>{{data.action === 'create' ? 'Create New Folder' : 'Rename'}} </h1>
<form (submit)="title.length > 0 ? dialogRef.close(title) : false">
    <div mat-dialog-content>
        <mat-form-field class="full-width">
            <input matInput placeholder="Folder Title" name="title"  [(ngModel)]="title" required>
        </mat-form-field>
    </div>
    <div mat-dialog-actions class="flex gap-5" style="justify-content: space-between;">
      <button mat-raised-button class="dn-button-primary" type="submit">{{data.action === 'create' ? 'Create' : 'Rename'}}</button>
      <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="reset">Cancel</button>
    </div>
</form>