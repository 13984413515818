import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.css']
})
export class DialogConfirmationComponent implements OnInit {
  title:String
  showCheckBox: Boolean = false;
  checked: Boolean = false;;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any) { 
  }

  ngOnInit(): void {
    this.title = this.data.message
    if(this.data.showCheckBox){
      this.showCheckBox = this.data.showCheckBox
    }
  }
  checkDisable() {
    if(this.showCheckBox && !this.checked){
      return true;
    }else{
      return false;
    }
  }
}
