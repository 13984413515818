<div class="child-vertical-height flex gap-5">
  <form class="theme-form patient-add-form scroll scrolling-section flex flex-col w-1/2 gap-5"
    style="margin:auto; overflow-x: hidden;" (submit)="onSaveClick.emit($event)">

    <div class="flex flex-wrap items-between justify-center gap-5">
      <mat-form-field class="flex-grow">
        <input appCapitalizeFirst matInput type="text" name="pFirstName" placeholder="First Name"
          [(ngModel)]="first_name" required (keypress)="allowOnlyText($event)" [disabled]="isDisabledEditing">
        <mat-error *ngIf="!first_name">Please enter first name</mat-error>
      </mat-form-field>
      <mat-form-field class="flex-grow">
        <input appCapitalizeFirst matInput type="text" name="pMiddleName" placeholder="Middle Name (optional)"
          [(ngModel)]="middle_name" (keypress)="allowOnlyText($event)" [disabled]="isDisabledEditing">
      </mat-form-field>
      <mat-form-field class="flex-grow">
        <input appCapitalizeFirst matInput type="text" name="pLastName" placeholder="Last Name" [(ngModel)]="last_name"
          required (keypress)="allowOnlyText($event)" [disabled]="isDisabledEditing">
        <mat-error *ngIf="!last_name">Please enter last name</mat-error>
      </mat-form-field>
    </div>
    <mat-form-field *ngIf="showEmailField">
      <input matInput type="email" name="email" placeholder="Email" [(ngModel)]="email">
    </mat-form-field>
    <mat-form-field *ngIf="showPhoneField">
      <input matInput type="text" #mobile="ngModel" name="phone" placeholder="Phone" [(ngModel)]="phone"
        (ngModelChange)="handlePhoneModelChange($event,'phone')" pattern="\(\d{3}\)\s\d{3}\-\d{4}">
    </mat-form-field>
    <div class="flex justify-between items-center">
      <mat-form-field style="width: 28%;">
        <mat-label>Gender</mat-label>
        <mat-select [(ngModel)]="gender" name="pGender" required [disabled]="isDisabledEditing">
          <mat-option value="" disabled selected>
            -- SELECT --
          </mat-option>
          <mat-option value="Male">
            Male
          </mat-option>
          <mat-option value="Female">
            Female
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!gender">Please select gender</mat-error>
      </mat-form-field>
      <app-date-picker-widget [placeholder]="'Birth'" [date]="date_of_birth" (dateEvent)="dateOfBirth($event)"
        style="width: 70%;" [isDisabled]="isDisabledEditing">
      </app-date-picker-widget>
    </div>
    <div class="flex justify-between items-center gap-1">
      <mat-form-field class="flex-grow">
        <mat-label>Race</mat-label>
        <mat-select [(ngModel)]="race" name="race" [disabled]="isDisabledEditing">
          <mat-option value="" disabled selected>
            -- SELECT --
          </mat-option>
          <mat-option *ngFor="let race of races" [value]="race.value">
            {{race.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-grow">
        <input matInput readonly type="text" name="mrn" placeholder="MRN" [(ngModel)]="mrn" [disabled]="isDisabledEditing">
      </mat-form-field>
      <mat-form-field *ngIf="isPccMRN" class="flex-grow">
        <input matInput readonly type="text" name="pccMRN" placeholder="pccMRN" [(ngModel)]="pccMRN" [disabled]="isDisabledEditing">
      </mat-form-field>
      <mat-form-field class="flex-grow">
        <input appCapitalizeFirst matInput type="text" name="pSSN" placeholder="SSN" [(ngModel)]="patient_ssn" [disabled]="isDisabledEditing">
      </mat-form-field>
    </div>
    <div class="flex justify-between items-center gap-2">
      <mat-form-field style="width: 60%;" *ngIf="!(currentUser.user_type == 2 || currentUser.user_type == 'medical_assistant')">
        <mat-label>Facility</mat-label>
        <mat-select [(ngModel)]="selectedFacilityId" name="pFacility" required (selectionChange)="getPatientRoundingSheets()"
          [disabled]="facilityDisable()">
          <input matInput placeholder="Search" [(ngModel)]="searchFacilityText" [ngModelOptions]="{standalone: true}" 
            style="color:#000;height: 40px;padding-left: 5px;" />
          <mat-option *ngFor="let facility of facilities | searchFacility : searchFacilityText" [value]="facility._id">
            <app-facility-title [facility]="facility"></app-facility-title>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!selectedFacilityId">Please select facility</mat-error>
      </mat-form-field>
      <mat-form-field class="flex-grow" *ngIf="!isDisabledEditing">
        <input matInput type="text" name="fpm" placeholder="Facility Patient MRN" [(ngModel)]="facility_patient_mrn" (keypress)="keyPressAlphanumeric($event)">
      </mat-form-field>

      <mat-form-field *ngIf="currentUser.user_type == 2 || currentUser.user_type == 'medical_assistant'" class="flex-grow">
        <mat-label>Provider</mat-label>
        <mat-select [(ngModel)]="selectedDoctorId" name="pDoctor" required (selectionChange)="handleDoctorChange($event)" [disabled]="isDisabledEditing">
          <mat-option value="" disabled selected>
            -- SELECT --
          </mat-option>
          <ng-container *ngIf="doctors">
            <mat-option *ngFor="let doctor of doctors" [value]="doctor._id">
              {{doctor.first_name}} {{doctor.last_name}}, {{doctor.title}}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-error *ngIf="!selectedDoctorId">Please select Provider</mat-error>
      </mat-form-field>
    </div>
    <div class="flex justify-between items-center gap-2">
      <app-date-picker-widget [placeholder]="'facility admission'" [date]="last_admit_date" [is_Required]='false'
        (dateEvent)="admissionDate($event)" style="width: 100%;" [isDisabled]="isDisabledEditing">
      </app-date-picker-widget>
    </div>
    <div class="flex justify-between items-center gap-2">
      <app-date-picker-widget [placeholder]="'intake date'" [date]="inTake_date" [is_Required]='false'
        (dateEvent)="intakeDate($event)" style="width: 100%;" [isDisabled]="isDisabledEditing">
      </app-date-picker-widget>
    </div>
    <mat-form-field *ngIf="currentUser.user_type == 2 || currentUser.user_type == 'medical_assistant'">
      <mat-label>Facility</mat-label>
      <mat-select [(ngModel)]="selectedFacilityId" name="pFacility" required
        (selectionChange)="getPatientRoundingSheets()">
        <input placeholder="Search" [(ngModel)]="searchFacilityText" [ngModelOptions]="{standalone: true}"
          style="color:#000;height: 40px;padding-left: 5px; border: none; width: 100%;" />
        <mat-option *ngFor="let facility of facilities | searchFacility : searchFacilityText" [value]="facility._id">
          <app-facility-title [facility]="facility"></app-facility-title>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!selectedFacilityId">Please select facility</mat-error>
    </mat-form-field>


    <div *ngIf="selectedFacilityId" class="flex justify-center items-center gap-5">
      <mat-form-field style="width: 100%;">
        <mat-label>Add to census</mat-label>
        <mat-select [(ngModel)]="census_id" name="pcensus" [disabled]="isDisabledEditing">
          <mat-option value="" disabled selected>
            -- SELECT --
          </mat-option>
          <mat-option *ngFor="let census of patientRoundingSheets" [value]="census._id">
            {{census.name}} {{getCensusDate(census)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-divider style="background-color: #44597d; margin: 0px;"></mat-divider>
    <div class="flex justify-start items-start" style="margin: 0px;">
      <p style="margin: 0px;">Primary Care Practitioner</p>
    </div>
    <div class="flex justify-between items-center">
      <mat-form-field style="width: 48%;">
        <input appCapitalizeFirst matInput type="text" name="pcpFirstName"
          placeholder="First name" [(ngModel)]="pcpFirstName" [disabled]="isDisabledEditing">
      </mat-form-field>
      <mat-form-field style="width: 48%;">
        <input appCapitalizeFirst matInput type="text" name="pcpLastName"
          placeholder="Last name" [(ngModel)]="pcpLastName" [disabled]="isDisabledEditing">
      </mat-form-field>
    </div>
    <div class="flex justify-between items-center">
      <mat-form-field style="width: 48%;">
        <input matInput type="text" name="pcpPhoneNumber"
          placeholder="Phone Number" [(ngModel)]="pcpPhoneNumber" [disabled]="isDisabledEditing">
      </mat-form-field>
      <mat-form-field style="width: 48%;">
        <input matInput type="text" name="pcpFaxNumber"
          placeholder="Fax Number" [(ngModel)]="pcpFaxNumber" [disabled]="isDisabledEditing">
      </mat-form-field>
    </div>
    <div class="flex justify-between items-center">
      <mat-form-field style="width: 48%;">
        <mat-label>Title</mat-label>
        <mat-select [(ngModel)]="pcpTitle" name="pcpTitle" [disabled]="isDisabledEditing">
          <mat-option value="Mr.">
            Mr.
          </mat-option>
          <mat-option value="Ms.">
            Ms.
          </mat-option>
          <mat-option value="Dr.">
            Dr.
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="width: 48%;">
        <mat-label>Credentials</mat-label>
        <mat-select [(ngModel)]="pcpCredentials" name="pcpCredentials" [disabled]="isDisabledEditing">
          <mat-option value="MD">
            MD
          </mat-option>
          <mat-option value="DO">
            DO
          </mat-option>
          <mat-option value="ARNP">
            ARNP
          </mat-option>
          <mat-option value="PA-C">
            PA-C
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-divider style="background-color: #44597d; margin: 0px;"></mat-divider>
    <div class="flex items-start" style="margin: 0px;">
      <p style="margin: 0px;"> Referring Provider/Location</p>
    </div>
    <div class="flex justify-between items-center gap-5">
      <mat-form-field style="width: 48%;">
      <input matInput type="text" name="refer_provider_first_name" placeholder="First Name" [(ngModel)]="refer_provider_first_name" 
        (keypress)="allowOnlyText($event)" appCapitalizeFirst>
    </mat-form-field>
    <mat-form-field  style="width: 48%;">
      <input matInput type="text" name="refer_provider_last_name" placeholder="Last Name" [(ngModel)]="refer_provider_last_name" 
        (keypress)="allowOnlyText($event)" appCapitalizeFirst>
    </mat-form-field>
    </div>
    <div class="flex justify-between items-center gap-5">
      <mat-form-field style="width: 48%;">
      <input matInput type="text" #mobile="ngModel" name="refer_provider_phone_number" placeholder="Phone" [(ngModel)]="refer_provider_phone_number"
        (ngModelChange)="handlePhoneModelChange($event,'refer_provider_phone_number')" pattern="\(\d{3}\)\s\d{3}\-\d{4}">
    </mat-form-field>
    <mat-form-field style="width: 48%;">
      <input matInput type="text" #mobile="ngModel" name="refer_provider_fax_number" placeholder="Fax Number" [(ngModel)]="refer_provider_fax_number">
    </mat-form-field>
    </div>
    <div class="flex justify-between items-center gap-5">
      <mat-form-field style="width: 48%;">
      <input  matInput type="text" name="refer_provider_address" placeholder="Address" [(ngModel)]="refer_provider_address">
    </mat-form-field>
    <mat-form-field  style="width: 48%;">
      <input matInput type="text" name="refer_provider_location" placeholder="Location" [(ngModel)]="refer_provider_location" >
    </mat-form-field>
    </div>
    <mat-divider style="background-color: #44597d; margin: 0px;"></mat-divider>
    <div style="margin: 0px;">
      <p style="margin: 0px;">Patient Insurance Info</p>
    </div>
    <table mat-table [dataSource]="coveragesList" cdkDropList (cdkDropListDropped)="drop($event)">
      <ng-container matColumnDef="ins_order">
        <th mat-header-cell *matHeaderCellDef>Ins Order</th>
        <td mat-cell *matCellDef="let element">{{ element.ins_order }}</td>
      </ng-container>
      <ng-container matColumnDef="insurance_type">
        <th mat-header-cell *matHeaderCellDef>Insurance Type</th>
        <td mat-cell *matCellDef="let element">{{ getInsuranceType(element.ins_order) }}</td>
      </ng-container>
      <ng-container matColumnDef="tin">
        <th mat-header-cell *matHeaderCellDef>TIN</th>
        <td mat-cell *matCellDef="let element">{{ element.tin }}</td>
      </ng-container>
    
      <ng-container matColumnDef="insurance_plan_id">
        <th mat-header-cell *matHeaderCellDef>Insurance Plan ID</th>
        <td mat-cell *matCellDef="let element">{{ element.insurance_plan_id }}</td>
      </ng-container>
    
      <ng-container matColumnDef="insured_group_employer_id">
        <th mat-header-cell *matHeaderCellDef>Insured Group Employer ID</th>
        <td mat-cell *matCellDef="let element">{{ element.insured_group_employer_id }}</td>
      </ng-container>
      <ng-container matColumnDef="remove_ins">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <button mat-icon-button (click)="removeCoverage(i)" type="button">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <!-- Define table header row -->
      <tr mat-header-row *matHeaderRowDef="displayedColumnsInurance"></tr>
    
      <!-- Define table row -->
      <tr mat-row *matRowDef="let row; columns: displayedColumnsInurance;" cdkDrag (click)="selectCoverage(row.ins_order)">
      </tr>
    </table>
    
    <!-- Form for Adding/Editing Coverages -->
    <div>
      <div fxLayoutAlign="start start" style="margin: 10px;">
        <p style="margin: 0px;">{{addInsuranceLabel}} Insurance</p>
      </div>
      <!-- TIN and Ins Order-->
      <div class="flex justify-between items-center gap-5">
        <mat-form-field style="width: 48%;">
          <mat-select [(ngModel)]="newCoverage.ins_order" name="newCoverage.ins_order"
            placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insurance Order" [disabled]="true">
            <mat-option *ngFor="let order of insurance_order" [value]="order.id">{{ order.value }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }}  TIN"
            [(ngModel)]="newCoverage.tin" name="tin" (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
      </div>
      <!-- Insured Id and Insured Name -->
      <div class="flex justify-between items-center gap-5">
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insured ID"
            name="insured_id" [(ngModel)]="newCoverage.insured_id">
        </mat-form-field>
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insured Name"
            name="insured_name" [(ngModel)]="newCoverage.insured_name">
        </mat-form-field>
      </div>
      <!-- Insured Address and Insured Relation -->
      <div class="flex justify-between items-center gap-5">
        <mat-form-field style="width: 48%;">
          <mat-select [(ngModel)]="newCoverage.insured_relation_to_patient" name="newCoverage.insured_relation_to_patient"
            placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insured Relation To Patient">
            <mat-option *ngFor="let relation of insured_patient_relation" [value]="relation.id">{{ relation.value
              }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insured Address"
            name="insured_address" [(ngModel)]="newCoverage.insured_address">
        </mat-form-field>
      </div>
      <!-- Insured DOB -->
      <div class="flex justify-between items-center gap-5">
        <app-date-picker-widget style="width: 100%;" [defaultYear]="''"
          placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insured DOB" [minYear]="''" [is_Required]='false'
          [date]="newCoverage.insured_dob" (dateEvent)="insuredDateOfBirth($event)">
        </app-date-picker-widget>
      </div>
      <!-- Insurance Company ID and Insurance Company Name -->
      <div  class="flex justify-between items-center gap-5">
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insurance Company ID"
            name="insurance_company_id" [(ngModel)]="newCoverage.insurance_company_id"
            (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insurance Company Name"
            name="insurance_company_name" [(ngModel)]="newCoverage.insurance_company_name"
            (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
      </div>
      <!-- Insurance Company Address and Insurance Phone Number -->
      <div class="flex justify-between items-center gap-5">
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insurance Company Address"
            name="insurance_company_address" [(ngModel)]="newCoverage.insurance_company_address"
            >
        </mat-form-field>
        <mat-form-field style="width: 48%;">
          <input matInput type="text"
            placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insurance Company Phone Number"
            name="insurance_company_phone_number" [(ngModel)]="newCoverage.insurance_company_phone_number"
            (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
      </div>
      <!-- Group Number and Group Name -->
      <div class="flex justify-between items-center gap-5">
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Group Name"
            name="group_name" [(ngModel)]="newCoverage.group_name" (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Group Number"
            name="group_number" [(ngModel)]="newCoverage.group_number" (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
      </div>
      <!-- Insured Group Employer ID and Insured Group Employer Name -->
      <div class="flex justify-between items-center gap-5">
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insured Group Employer ID"
            name="insured_group_employer_id" [(ngModel)]="newCoverage.insured_group_employer_id"
            (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
        <mat-form-field style="width: 48%;">
          <input matInput type="text"
            placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insured Group Employer Name"
            name="insured_group_employer_name" [(ngModel)]="newCoverage.insured_group_employer_name"
            (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
      </div>
      <!-- Insurance Carrier and Insurance Carrier -->
      <div class="flex justify-between items-center gap-5">
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insurance Carrier"
            name="insurance_carrier" [(ngModel)]="newCoverage.insurance_carrier" (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
        <mat-form-field style="width: 48%;">
          <input matInput type="text"
            placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insurance Holder Name"
            name="insurance_holder_name" [(ngModel)]="newCoverage.insurance_holder_name"
            (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
      </div>
      <!-- Policy Number -->
      <div class="flex justify-between items-center gap-5">
        <mat-form-field style="width: 100%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Insurance Policy Number"
            name="insurance_policy_number" [(ngModel)]="newCoverage.insurance_policy_number"
            (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
      </div>
      <!-- Plan ID & Plan Type -->
      <div class="flex justify-between items-center gap-5">
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Plan ID"
            name="insurance_plan_id" [(ngModel)]="newCoverage.insurance_plan_id" (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Plan Type" name="plan_type"
            [(ngModel)]="newCoverage.plan_type" (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
      </div>
      <!-- Plan Effective Date and Plan Expiration Date -->
      <div  class="flex justify-between items-center gap-5">
        <mat-form-field style="width: 48%;">
          <input matInput [matDatepicker]="effectiveDatePicker"
            placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Plan Effective Date"
            (dateChange)="setDateObjCoverage('plan_effective_date')" [(ngModel)]="plan_effective_date" name="effectiveDate">
          <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #effectiveDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="width: 48%;">
          <input matInput [matDatepicker]="expirationDatePicker"
            placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Plan Expiration Date"
            (dateChange)="setDateObjCoverage('plan_expiration_date')" [(ngModel)]="plan_expiration_date"
            name="expirationDate">
          <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #expirationDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <!-- Identifier & AlternateID-->
      <div fxLayout="row"  class="flex justify-between items-center gap-5">
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Identifier"
            name="identifier" [(ngModel)]="newCoverage.identifier" (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
        <mat-form-field style="width: 48%;">
          <input matInput type="text" placeholder="{{ getInsuranceType(newCoverage.ins_order.valueOf()) }} Alternate ID"
            name="alternate_id" [(ngModel)]="newCoverage.alternate_id" (keypress)="keyPressAlphanumeric($event)">
        </mat-form-field>
      </div>
      <!-- Button to Add Insurance -->
      <button mat-raised-button class="dn-button-primary" type="button"
        (click)="addCoverage()">{{addInsuranceLabel}}</button>
    </div>

    <mat-divider style="background-color: #44597d; margin: 0px;"></mat-divider>

    <div class="flex justify-start items-start" style="margin: 0px;">
      <p style="margin: 0px;">Patient Contact Info</p>
    </div>
    <div class="flex justify-between items-center">
      <mat-form-field style="width: 48%;">
        <input appCapitalizeFirst matInput type="text" name="pCity" placeholder="City"
          (keypress)="allowOnlyText($event)" [(ngModel)]="city" [disabled]="isDisabledEditing">
      </mat-form-field>
      <mat-form-field style="width: 48%;">
        <input matInput type="text" name="zipCode" placeholder="Zip code"
          [(ngModel)]="zip_code" [disabled]="isDisabledEditing">
      </mat-form-field>
    </div>
    <div class="flex justify-between items-center">
      <mat-form-field style="width: 48%;">
        <input matInput type="text" #mobile="ngModel" name="phone" placeholder="Phone" [(ngModel)]="phone"
          (ngModelChange)="handlePhoneModelChange($event,'phone')" pattern="\(\d{3}\)\s\d{3}\-\d{4}" [disabled]="isDisabledEditing">
      </mat-form-field>
      <mat-form-field style="width: 48%;">
        <input appCapitalizeFirst matInput type="email" name="email" placeholder="Email" [(ngModel)]="email" [disabled]="isDisabledEditing" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$">
      </mat-form-field>
    </div>
    <div class="flex justify-between items-center">
      <mat-form-field style="width: 48%;">
        <input appCapitalizeFirst matInput type="text" name="Address" placeholder="Address"
          (keypress)="keyPressAlphanumeric($event)" [(ngModel)]="address" [disabled]="isDisabledEditing">
      </mat-form-field>
      <mat-form-field style="width: 48%;">
        <mat-label>State</mat-label>
        <mat-select name="states" [(ngModel)]="state" [disabled]="isDisabledEditing">
          <input matInput placeholder="Search" [(ngModel)]="searchState" [ngModelOptions]="{standalone: true}"
            style="color:#000;height: 40px;padding-left: 5px;" />
          <mat-option *ngFor="let state of usaStates | searchCompany : searchState" [value]="state.abbreviation">
            {{state.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex justify-between items-center">
      <mat-form-field style="width: 48%;">
        <input matInput type="text" name="responsibleParty" placeholder="Responsible Party"
          [(ngModel)]="responsible_party" [disabled]="isDisabledEditing">
      </mat-form-field>
      <mat-form-field style="width: 48%;">
        <input appCapitalizeFirst matInput type="text" name="responsiblePartyContact"
          placeholder="Responsible Party Contact Info" [(ngModel)]="responsible_party_contact" [disabled]="isDisabledEditing">
      </mat-form-field>
    </div>
    <br>

    <div class="add-patient-action fixed flex gap-5 justify-center items-center">
      <div class="flex flex-grow justify-between items-center" style="width: 100%;">
        <div>
          <input name="faceSheet" (change)="onSelectFile($event)" hidden accept=".pdf" type="file" id="faceSheet"
            #faceSheet />
          <small>{{fileName}}</small> &nbsp;
          <button *ngIf="!showRemoveFileButton" #file_uploader_btn class="dn-button-primary" mat-raised-button
            type="button" (click)="faceSheet.click()">Upload
            Face Sheet</button>
            <small *ngIf="uploadedFacesheetTitle && !showRemoveFileButton">{{uploadedFacesheetTitle}}</small>
            <small *ngIf="uploadedFacesheetTitle && !showRemoveFileButton" style="color: rgb(83, 83, 201); margin-left: 5px; cursor: pointer;" (click)="openFacesheet()">View</small>
          <small *ngIf="showRemoveFileButton" #file_remove_btn (click)="onDeselectFile(faceSheet)"
            style="cursor: pointer; color: red;">Remove
            File</small>
            <button mat-icon-button class="text" aria-label="Add Image"
            [matMenuTriggerFor]="addImageContextMenu" (click)="$event.preventDefault();$event.stopPropagation();">
            <mat-icon>add_a_photo</mat-icon>
          </button>
          <button mat-icon-button style="color: #1f98d7;" aria-label="Show" (click)="openImageCarousel($event);" >
            <mat-icon style="font-size: 19px;"
                matTooltip="View Image">
                photo_library</mat-icon>
        </button>
          <mat-menu #addImageContextMenu="matMenu">
            <button mat-menu-item (click)="openCameraCapture()">Open Camera</button>
            <button mat-menu-item (click)="faceSheetImages.click()"> From PC</button>
            <input name="faceSheetImages" (change)="selectImagesForFacesheetFromPC($event)" hidden type="file" 
            id="faceSheetImages" 
            #faceSheetImages 
            multiple  />
          </mat-menu>
        </div>
      </div>
      <button class="theme-btn" type="submit"
        [disabled]="!first_name || !last_name || !gender || !date_of_birth || !selectedFacilityId || !inTake_date || !last_admit_date"
        mat-raised-button class="dn-button-primary" (click)="sendEmailForBoUser()">Save</button>
      <button mat-raised-button class="dn-button-secondary" type="reset" mat-stroked-button
        (click)="onCancelClick.emit($event)">Cancel</button>
    </div>

  </form>
  <div style="width: 100%;" *ngIf="patientexists">
    <h1 style="margin-top: 0px;">Duplicate Patients</h1>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="first_name">
        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
          First Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.first_name}}
        </td>
      </ng-container>
      <ng-container matColumnDef="last_name">
        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
          Last Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.last_name}}
        </td>
      </ng-container>
      <ng-container matColumnDef="date_of_birth">
        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
          DOB
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.date_of_birth}}
        </td>
      </ng-container>
      <ng-container matColumnDef="facility">
        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
          Facility
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.facility_id_ref?.title}}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>