import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from './../../../environments/api-url';
import * as global from '../../includes/global';
import { AuthService } from 'src/app/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private httpClient: HttpClient,private _authService: AuthService) { }

  getData(data) {
    const details = {
      ...this._authService.authObject,
      data : data
  }
    return this.httpClient.post(global.url + API_URL.FEEDBACK.getFeedback,{details: details}).pipe();
  }

  insertData(data) {
    return this.httpClient.post(global.url + API_URL.FEEDBACK.addFeedback,data);
  }

  updateData(data) {
    const details = {
      ...this._authService.authObject,
      data : data
  }
    return this.httpClient.post(global.url + API_URL.FEEDBACK.updateFeedback,{details: details}).pipe();
  }
  deleteData(data) {
    const details = {
      ...this._authService.authObject,
      data : data
  }
    return this.httpClient.post(global.url + API_URL.FEEDBACK.deleteFeedback,{details: details}).pipe();
    
  }
  updateStatus(data) {
    return this.httpClient.post(global.url + API_URL.FEEDBACK.acceptanceStatus,data);
  }
}
