import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../global';
import { Subscription, Observable, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  data: any;


  private checkedNotes: BehaviorSubject<any> = new BehaviorSubject([]);
  castCheckedNotes = this.checkedNotes.asObservable();

  private notes: BehaviorSubject<any> = new BehaviorSubject(null);
  castNotes = this.notes.asObservable();

  private audios: BehaviorSubject<any> = new BehaviorSubject(null);
  castAudios = this.audios.asObservable();


  public constructor(
    private httpclient: HttpClient,
    private _router: Router,
    private _authService: AuthService,
    private dialog: MatDialog
    ) {
    this.data = this._authService.currentUser;
  }


  toggleCheckedNotes(note) {
    const checkedNotes = this.checkedNotes.getValue() as any[];
    const index = checkedNotes.findIndex(n => n._id === note._id);
    if(index === -1) {
      checkedNotes.push(note);
    } else {
      checkedNotes.splice(index, 1);
    }
    this.checkedNotes.next(checkedNotes);
  }

  isCheckedNote(note) {
    const checkedNotes = this.checkedNotes.getValue() as any[];
    const index = checkedNotes.findIndex(n => n._id === note._id);
    return index !== -1;
  } 

  discardCheckedNotes() {
    this.checkedNotes.next([]);
  }

  publishNotes(notes: any[]) {
    this.notes.next(notes);
  }
  publishAudios(audios: any[]) {
    this.audios.next(audios);
  }
  getAudiosinfo(
    // status : string = null,
    surpassedTAT = null, filter, id: string, auth_key: string,
    user_type?: string, from_date?, to_date?) {

    let filters = { ...filter }
    if (filters && filters.facility) {
      filters.facility = { _id: filters.facility._id }
    }
    if (filters && filters.facility) {
      filters.provider = { _id: filters.provider._id }
    }

    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      scope: <string>null,
      from_date: <string>null,
      to_date: <string>null,
      filters,
      surpassedTAT
      // status,
    };

    item.id = id;
    item.auth_key = auth_key;
    item.usertype = user_type;
    item.scope = '16';
    item.from_date = from_date;
    item.to_date = to_date;
    item.filters = filters;
    item.surpassedTAT = surpassedTAT;

    return this.httpclient
      .post(global.url + API_URL.AUDIO.getAudios, { details: item }).pipe();
  }

  checkAudioNote(audio_id) {
    let item = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type,
      audio_id: audio_id
    };

    return this.httpclient
      .post(global.url + API_URL.AUDIO.checkAudioNote, { details: item }).pipe();
  }

  getAudioDetailById(audio_id: string, id: string, auth_key: string, user_type: string) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,

      scope: <string>null,
      audio_id: audio_id
    };
    item.id = id, item.auth_key = auth_key, item.usertype = user_type, item.scope = '16';
    return this.httpclient
      .post(global.url + API_URL.AUDIO.getAudioDetail, { details: item }).pipe(map((response: any) => {
        if (response.status === 200 && response.data) {
          let date = response.data.date_time;
          response.data.date = date;
          if (response.data.progress_status === '18') {
            response.data.status = 'PENDING';
          }
          if (response.data.progress_status === '19') {
            response.data.status = 'IN PROGRESS';
          }
          if (response.data.progress_status === '20') {
            response.data.status = 'COMPLETED';
          }
          if (response.data.progress_status === '21') {
            response.data.status = 'DRAFT';
          }
          if (response.data.progress_status === '23') {
            response.data.status = 'TRASHED';
          }
        }
        return response;
      }));
  }

  generatePDF(transcription) {
    var item = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type,
      transcription: transcription
    }
    return this.httpclient
      .post(global.url + API_URL.ROUTES.generatePDF, { details: item }).pipe();
  }

  getEmails(id) {
    var item = {
      user_id: id,
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.getEmails, { details: item }).pipe();
  }

  convertMp4ToMp3Anddownload(recording) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      recording: <any>null
    };
    item.id = this.data._id,
      item.auth_key = this.data.auth_key,
      item.usertype = this.data.user_type,
      item.recording = recording;
    return this.httpclient
      .post(global.url + API_URL.ROUTES.convertMp4ToMp3Anddownload, { details: item }, {withCredentials: true}).pipe();
  }

  getAssociatedDoctorsList(company = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      company_id: <string>null
    };
    item.id = this.data._id,
      item.auth_key = this.data.auth_key;
      item.usertype = this.data.user_type;
      item.company_id = this.data.company_id;
    return this.httpclient
      .post(global.url + API_URL.USER.getAssociatedDoctorsList, { details: item }, {withCredentials: true}).pipe();
  }

  getFacilities(doctor_id) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      doctor_id: <string>null
    };
    if (!this.data) this.data
    item.id = this.data._id,
      item.auth_key = this.data.auth_key,
      item.usertype = this.data.user_type,
      item.doctor_id = doctor_id;
    return this.httpclient
      .post(global.url + API_URL.AUDIO.getMyTitles, { title: item }, {withCredentials: true}).pipe();
  }

  getAssociatedFacilityOfTranscriber(userId = undefined) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      userId: <string>undefined,
    };

    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    item.userId = userId;
    return this.httpclient
      .post(global.url + API_URL.FACILITY.getAssociatedFacilityOfTranscriber, { details: item }, {withCredentials: true}).pipe();
  }

  getAssociatedFacilityOfMedicalAssistant(userId = undefined) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      userId: <string>undefined,
    };

    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    item.userId = userId;
    return this.httpclient
      .post(global.url + API_URL.FACILITY.getAssociatedFacilityOfMedicalAssistant, { details: item }, {withCredentials: true}).pipe();
  }
  getAssociatedFacilityOfBusinessOfficer(id, usertype, userId = undefined) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      userId: <string>undefined,
    };

    item.id = id;
    item.auth_key = this.data.auth_key;
    item.usertype = usertype;
    item.userId = userId;
    return this.httpclient
      .post(global.url + API_URL.FACILITY.getAssociatedFacilityOfBusinessOfficer, { details: item }, {withCredentials: true}).pipe();
  }
  sendNewDictaionRequestEmail(detail) {
    return this.httpclient
      .post(global.url + API_URL.AUDIO.sendNewDictaionRequestEmail, { details: detail }, {withCredentials: true}).pipe();
  }

  getUserDetail(userId) {
    var item = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type,
      user_id: userId
    };
    return this.httpclient
      .post(global.url + API_URL.USER.getUserDetail, { details: item }, {withCredentials: true}).pipe();
  }
  getUserVerificationStatus(user_id){
    var item = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type,
      user_id: user_id
    };
    return this.httpclient
      .post(global.url + API_URL.AUTH.isUserVerified, { details: item }).pipe();
  }
  isUserVerified(userId) {
    var item = {
      // id: this.data._id,
      // auth_key: this.data.auth_key,
      // usertype: this.data.user_type,
      isCreatingPassword: true,
      user_id: userId
    };
    return this.httpclient
      .post(global.url + API_URL.AUTH.isUserVerified, { details: item }).pipe();
  }
  updateAgreement(hipaa_agreement_accepted) {
    var item = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type,
      hipaa_agreement_accepted
    };
    return this.httpclient
      .post(global.url + API_URL.USER.updateAgreement, { details: item }, {withCredentials: true}).pipe();
  }
   getAgreement() {
    var item = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type,
    };
    return this.httpclient
      .post(global.url + API_URL.USER.getAgreement, { details: item }, {withCredentials: true}).pipe();
  }
  loadEmailSuggestions(email) {
    let item = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type,
      email: email
    };
    return this.httpclient
      .post(global.url + API_URL.USER.loadEmailSuggestions, { details: item }).pipe();
  }


  getAllAssociatedTranscriptions(creation_date?: string, end_creation_date?: string) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      creation_date: <string>null,
      end_creation_date: <string>null
    };
    if (!this.data) {

      this.data = this._authService.currentUser;
      // this.data  = JSON.parse( this.data );
    }
    item.id = this.data._id,
      item.auth_key = this.data.auth_key,
      item.usertype = this.data.user_type;
    item.creation_date = creation_date;
    item.end_creation_date = end_creation_date;
    // return this.httpclient.post(global.url + '/get_all_combined_transcriptions', {details: item}).pipe();
    return this.httpclient
      .post(global.url + API_URL.NOTE.getAllAssociatedTranscriptions, { details: item }, {withCredentials: true}).pipe();
  }

  getTeamNotesForTranscribers(filter,type,from_date?, to_date?,patient?) {
    this.data = this._authService.currentUser;
    // this.data  = JSON.parse( this.data ); 
    // console.log("patient",patientId);
    let filters = { ...filter }
    if (filters && filters.facility) {
      filters.facility = { _id: filters.facility._id }
    }
    if (filters && filters.facility) {
      filters.provider = { _id: filters.provider._id }
    }
    if (patient) {
      filters.patient = { _id: patient._id }
    }
    

    // console.log("filters",filters);

    let details = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type,
      qa_type:type,
      from_date,
      to_date,
      filters
    }; 
    return this.httpclient
      .post(global.url + API_URL.NOTE.getTeamNotesForTranscribers, { details }, {withCredentials: true});
  }

  getTeamNotes(filter, from_date?, to_date?,patient?) {
    this.data = this._authService.currentUser;
    // this.data  = JSON.parse( this.data ); 
    // console.log("patient",patientId);
    let filters = { ...filter }
    if (filters && filters.facility) {
      filters.facility = { _id: filters.facility._id }
    }
    if (filters && filters.facility) {
      filters.provider = { _id: filters.provider._id }
    }
    if (patient) {
      filters.patient = { _id: patient._id }
    }
    

    // console.log("filters",filters);

    let details = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type,

      from_date,
      to_date,
      filters
    }; 
    return this.httpclient
      .post(global.url + API_URL.NOTE.getTeamNotes, { details }, {withCredentials: true});
  }
  getDictationList(from_date?: string, to_date?: string, filters = {}) {
    this.data = this._authService.currentUser;
    // this.data  = JSON.parse( this.data );
    let details = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type,

      from_date,
      to_date,
      filters
    };
    return this.httpclient
      .post(global.url + API_URL.AUDIO.getDictationList, { details }, {withCredentials: true});
  }

  changeFileSignStatus(transcription, audioFlag) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      is_signed: <string>null,
      transcribtion_id: <string>null,
      path: <string>null,
      transcribtion_title: <string>null
    };
    item.id = this.data._id,
      item.auth_key = this.data.auth_key,
      item.usertype = this.data.user_type,
      item.transcribtion_id = transcription.id,
      item.is_signed = transcription.is_signed,
      item.transcribtion_title = transcription.transcribtion_title,
      item.path = transcription.path;
    var apiPath = (audioFlag) ? global.url + '/updateTranscribtionsStatus' : global.url + '/update_D_TranscribtionsStatus'
    return this.httpclient.post(apiPath, { transcribtion: item }, {withCredentials: true}).pipe();
  }

  /*
  * Export as PDF #mohsin-dev
  */
  deleteAudio(audio_id, shouldDelete = true) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      audio_id: <string>null,
      is_trashed: <string>null
    };
    item.id = this.data._id,
      item.auth_key = this.data.auth_key,
      item.usertype = this.data.user_type;
    item.audio_id = audio_id;
    item.is_trashed = shouldDelete ? 'true' : 'false';

    return this.httpclient
      .post(global.url + API_URL.AUDIO.deleteRestoreAudio, { details: item }).pipe();
  }

  deleteMultipleTrashedNotes(notes) {
    const details = {
      notes
    };
    return this.httpclient
      .post(global.url + API_URL.NOTE.deleteMultipleTrashedNotes, { details: details }).pipe();
  }
  deleteMultipleUnsignedNotes(notes) {
    const details = {
      notes
    };
    return this.httpclient
      .post(global.url + API_URL.NOTE.deleteMultipleUnsignedNotes, { details: details }).pipe();
  }

  public multiDownloadDictationsByIds(downloadType: 'mp3' | 'mp4', dictations: Array<any>): Observable<any> {
    var details = {
      downloadType: <string>null,
      dictations: <Array<any>>null
    };
    details.downloadType = downloadType;
    details.dictations = dictations;

    return this.downloadFile(global.url + API_URL.ROUTES.multiDownloadDictationsByIds, details);
  } 
  generateEncryptedPDFsAndEmail( noteIDs ){
    var details = { 
      // ...this._authService.authObject,
      ...this._authService.authObject, 
      noteIDs, 
      // transText: transcriptionData.toString()
    };
    return this.httpclient.post(global.url + API_URL.NOTE.generateEncryptedPDFsAndEmail,   {details: details}, {withCredentials: true} ).pipe();
  }
  encrypted_zip_file( noteIDs ){
    var details = { 
      // ...this._authService.authObject,
      ...this._authService.authObject, 
      noteIDs, 
      // transText: transcriptionData.toString()
    };
    return this.httpclient.post(global.url + API_URL.NOTE.encrypted_zip_file,   {details: details}, {withCredentials: true} ).pipe();
  }
  generateMultiplePDF( noteIDs ){
    var details = { 
      // ...this._authService.authObject,
      ...this._authService.authObject, 
      noteIDs, 
      // transText: transcriptionData.toString()
    };
    return this.httpclient.post(global.url + API_URL.NOTE.generateMultiplePDF,   {details: details}, {withCredentials: true} ).pipe();
  }

  public downloadFile(url, details:any) {
    let auth_details={
      id : this.data._id,
      email:this.data?.email,
        auth_key : this.data.auth_key,
        usertype : this.data.user_type
    };


    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    return this.httpclient.post(url, { details: details, auth_details:auth_details },  {
      responseType: "blob",
      reportProgress: true,
      observe: 'events',
      headers
    });
  }

  multiSelectChangeTranscriptionStatus(statusKey, statusValue, transcriptions) {
    const details = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type,
      statusKey: statusKey,
      statusValue: statusValue,
      transcriptionsIds: transcriptions.map(t => t._id)
    };

    return this.httpclient
      .post(global.url + API_URL.NOTE.multiSelectChangeTranscriptionStatus, { details: details }, {withCredentials: true}).pipe();
  }

  multiSelectChangeDictationStatus(status_code, dictations) {
    const details = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type,
      status_code: status_code,
      dictationsIds: dictations.map(d => d._id)
    };

    return this.httpclient
      .post(global.url + API_URL.AUDIO.multiSelectChangeDictationStatus, { details: details }, {withCredentials: true}).pipe();
  }

  notesCount() {
    const details = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type
    };
    return this.httpclient.post(global.url + API_URL.NOTE.noteCounts, { details: details }, {withCredentials: true});
  }
  dictationCount() {
    const details = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type
    };
    return this.httpclient.post(global.url + API_URL.AUDIO.dictationCount, { details: details }, {withCredentials: true});
  }
  getCountGroup(id, user_type, from_date, to_date, checked, signedStatus) {
    const detail = {
      id,
      user_type,
      from_date,
      to_date,
      checked,
      signedStatus
    };
    return this.httpclient.post(global.url + API_URL.NOTE.getCountGroup,
      { details: detail }, {withCredentials: true}).pipe();
  }
  //ali
  getP2PRelation(provider = null,doctor_id?) {
    var details:any = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null
    };
    details.id = this.data._id;
    details.auth_key = this.data.auth_key;
    details.usertype = this.data.user_type;
    if(doctor_id){
      details.doctor_id = doctor_id;
    }
    // details.provider
    // console.log("details in service for p2p: ", doctor_id);
    
    return this.httpclient
        .post(global.url + API_URL.USER.getP2PRelation, {details: details}, {withCredentials: true}).pipe();
  }
  getAssociatedProvidersForTemplates(provider_id, facility_id) {
    var details:any = {
      ...this._authService.authObject,
      provider_id,
      facility_id
    };
    // details.provider
    // console.log("details in service for p2p: ", doctor_id);
    
    return this.httpclient
        .post(global.url + API_URL.USER.getAssociatedProvidersForTemplates, {details: details}, {withCredentials: true}).pipe();
  }
  assocProviders(providerID = null, facilityID = null) {
    var details = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      providerID: <string>null,
      facilityID: <string>null,
    };
    details.id = this.data._id;
    details.auth_key = this.data.auth_key;
    details.usertype = this.data.user_type;
    details.providerID = providerID;
    details.facilityID = facilityID;
    // details.provider
    console.log("details in assocProviders dashboard.service: ", details);
    
    return this.httpclient
        .post(global.url + API_URL.USER.assocProviders, {details: details}, {withCredentials: true}).pipe();
  }

  getGoogleToken(data) {    
    return this.httpclient.
        post(global.url + API_URL.ROUTES.getGoogleToken, data ).pipe();
  }

  upload(data) {    
    return this.httpclient.
        post(global.url + API_URL.ROUTES.googleDrive, data ).pipe();
  }
 
  getFiles(token) {    
    return this.httpclient.
        post(global.url + API_URL.ROUTES.getFiles, {token : token}).pipe();
  }

  downloadGoogleFile(id, name ,type) {
    var details = {
      id : id,
      name : name,
      type : type
    }
    return this.httpclient.
        post(global.url + API_URL.ROUTES.downloadGoogleFile, {details} ).pipe();
  }
  async DeleteConfirmationDialog(message) {
    return new Promise<string>((resolve,reject)=> {
      const dialogRef = this.dialog.open(DialogConfirmationComponent, {
        data: {
          message: message
        }
      });
      dialogRef.afterClosed().subscribe(async(result) => {
        resolve(result);
      });
    }
  )}
}
