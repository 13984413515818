import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../includes/global';
import { API_URL } from 'src/environments/api-url';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsServiceService {
  private newsChanged = new BehaviorSubject(null);
  public castNewsChanged = this.newsChanged.asObservable();

  private _currentUser: any;
  constructor(
    private authService: AuthService,
    private httpclient: HttpClient,
  ) {
    this._currentUser = this.authService.currentUser;
   }
   setNewsChanged(news) {
    this.newsChanged.next(news);
    
  }

  getNews() {
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
    };
    return this.httpclient.post(global.url + API_URL.NEWS.getNews, { details: details }).pipe();
  }
  addNews(news = null ) {
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
      news, 
    };
    return this.httpclient.post(global.url + API_URL.NEWS.addNews, { details: details }).pipe();
  }
  updateNews(news = null, news_id = null) {
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
      news,
      news_id,
    };
    return this.httpclient.post(global.url + API_URL.NEWS.updateNews, { details: details }).pipe();
  }
  deleteNews(news_id = null) {
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type, 
      news_id,
    };
    return this.httpclient.post(global.url + API_URL.NEWS.deleteNews, { details: details }).pipe();
    // return this.httpclient.post(global.rrgen + '/getAllDocuments', {details: details}).pipe();
  }
}
